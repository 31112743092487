import {
  Button,
  Container,
  Grid,
  Typography,
  Divider,
  AccordionDetails,
  useMediaQuery,
  Box,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import makeStyles from "@mui/styles/makeStyles";
import OtherInterests from "../../components/OtherInterests";
import ProductGalleryPreview from "../../components/ProductGalleryPreview";
import PurchaseView from "../../components/PurchaseView";
import RelatedCategoriesAndSearches from "../../components/RelatedCategoriesAndSearches";
import RelatedPurchases from "../../components/RelatedPurchases";
import Reviews from "../../components/Reviews";
import { MasterLayout, PurposeCategoryMasterLayout } from "../../layouts";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import WeThinkYoullLoveThese from "../../components/WeThinkYoullLoveThese";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import {
  getProductById,
  getListOfProductsById,
} from "../../util/getProductsById";
import { getPurposeCategoryById } from "../../util/purposeCategories";

//JSON filler ----------------
import wineTumblerLayoutData from "../../data/wine_tumbler_product_layout.json";
import RecentlyViewed from "../../components/RecentlyViewed";
import CarouselProductView from "../../components/CarouselProductView";
import ExploreShops from "../../components/ExploreShops";
import { withStyles } from "@mui/styles";
import { getListingsBySeller } from "../../util/listingFunctions";
import { findSmallestPriceItemInArray, getUserIdFromAuth, round } from "../../util/utilities";
import { PRODUCT_TYPE } from "../../util/constants";
import BubbleCategoryView from "../../components/BubbleCategoryView";
import TrendingProductsLayout from "../../components/TrendingProductsLayout";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useDocumentDescription from "../../hooks/useDocumentDescription";
//----------------------------

const useStyles = makeStyles({
  divider: {
    height: "var(--dividerHeight)",
  },
  textMargins: {
    marginTop: 30,
  },
});

const AboutShopText = styled(Typography)({
  marginBottom: 25,
});

const SoldOnPurposerWrapper = styled("div")({
  marginBottom: 25,
});

const LearnMoreButton = styled(Button)({
  borderColor: "#CDCDCD",
  borderWidth: 2,
  borderRadius: 12,
  color: "#000000",
  paddingLeft: 25,
  paddingRight: 25,
  "&:hover": {
    borderColor: "#CDCDCD",
    borderWidth: 2,
    backgroundColor: "#FFFFFF",
  },
});

const DetailsText = styled(Typography)({
  marginTop: 0,
  marginBottom: 0,
});

const RelatedPurchasesDiv = styled("div")({
  backgroundColor: "var(--lightPastelGreen)",
});

const ReviewsDiv = styled("div")({
  backgroundColor: "var(--pastelBeige)",
});

const Accordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    paddingLeft: 0,
    marginBottom: -1,
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const StyledAccordianDetails = styled(AccordionDetails)({
  paddingBottom: 8,
  paddingLeft: 0,
});

const VerticallyCenterDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  color: "var(--mintGreenSolid)",
});

const ProductLayout = () => {
  const classes = useStyles();
  const { id = "" } = useParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [productData, setProductData] = React.useState({});
  const [selectedSku, setSelectedSku] = React.useState("");
  const [relatedPurchases, setRelatedPurchases] = React.useState([]);
  const [moreFromShop, setMoreFromShop] = React.useState([]);
  const [similarItems, setSimilarItems] = React.useState([]);
  const [exploreShops, setExploreShops] = React.useState({});
  const [recentlyViewed, setRecentlyViewed] = React.useState([]);
  const [currUserId, setCurrUserId] = React.useState(null);

  const history = useHistory();

  React.useEffect(() => {
    getProductById(id, (data) => {
      if ((!data.hasOwnProperty("error") && !data.hasOwnProperty("message"))) {
        const product = data[0];
        if (!product.active) {
          history.push(`/`);
          return;
        }
        getListingsBySeller(product.seller.sellerID, false, (data) => {
          setMoreFromShop(data);
        });
        setProductData(product);
        const firstActiveSku = product?.options?.find(o => o.active)?.skuID;
        const lowestPriceActiveSku = findSmallestPriceItemInArray(product?.options)?.skuID;
        if (lowestPriceActiveSku) {
          setSelectedSku(lowestPriceActiveSku);
        } else if (firstActiveSku) {
          setSelectedSku(firstActiveSku);
        }
      } else {
        // Use json data as default
        // setProductData(wineTumblerLayoutData.productData);
      }
    });
  }, [id]);

  React.useEffect(() => {
    getPurposeCategoryById(
      wineTumblerLayoutData.exploreShops,
      (data) => {
        if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
          setExploreShops(data);
        }
      },
      true
    );

    getListOfProductsById(wineTumblerLayoutData.recentlyViewed, (data) => {
      if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
        setRecentlyViewed(data);
      }
    });
  }, []);

  React.useEffect(() => {
    if (currUserId == null) {
      getUserIdFromAuth((userId) => {
        if (userId) {
          setCurrUserId(userId);
        }
      })
    }
  }, []);

  const productDict = {
    0: wineTumblerLayoutData,
  };

  const layoutData =
    id in productDict ? productDict[id] : wineTumblerLayoutData;

  const {
    reviewData,
    weThinkYoullLoveThese,
    relatedCategories,
    otherInterests,
  } = layoutData;

  const [expanded, setExpanded] = React.useState("Product Details");
  const handleChange = (category) => (event, newExpanded) => {
    setExpanded(newExpanded ? category : false);
  };

  useDocumentTitle(
    productData.hasOwnProperty("productName")
      ? productData.productName.replace(/^(.{60}[^\s]*).*/, "$1")
      : "Purposer"
  );
  useDocumentDescription(productData.hasOwnProperty("productName")
    ? "Shop " + productData.productName.replace(/\W+/g, " ").replace(/^(.{160}[^\s]*).*/, "$1") + " " + productData.categories[0].categoryName + "," + " at Purposer.com"
    : "Purposer"
  );

  return (
    <MasterLayout>
      <Divider />
      <div className={classes.divider} />
      <Container maxWidth="xl">
        {/* NON-MOBILE PAGE: */}
        {!matches ? (
          <Grid container columnSpacing={6}>
            <Grid item xs={6}>
              <ProductGalleryPreview
                data={
                  productData && Array.isArray(productData["productImageURL"])
                    ? productData["productImageURL"]
                    : productData
                      ? [
                        productData["productImageURL"],
                        productData["productImageURL"],
                        productData["productImageURL"],
                      ]
                      : []
                }
              />
              {/* <Button
                disabled={true}
                style={{
                  backgroundColor: "var(--mintGreenSolid)",
                  padding: "12px 24px 12px 24px",
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "16px",
                  marginBottom: "16px",
                  '&.Mui-disabled': {
                    cursor: 'not-allowed',
                  },
                }}
              >
                This Product's Purpose
              </Button>
              <AboutShopText
                gutterBottom
                variant="body1"
                className={"roboto-regular"}
              >
                {productData.purposeDesc
                  ? productData.purposeDesc
                  : ""}
              </AboutShopText> */}
              <AboutShopText
                className={clsx("roboto-bold", classes.textMargins)}
                sx={{ color: "var(--mintGreenSolid)" }}
                variant="h6"
              >

                <b>{`About the Shop: ${productData.seller?.sellerName ? productData.seller?.sellerName : ""}`}</b>
              </AboutShopText>
              <AboutShopText
                gutterBottom
                variant="body1"
                className={"roboto-regular"}
              >
                {productData.seller?.inAction?.text.length > 0
                  ? productData.seller?.inAction?.text[0]
                  : ""}
              </AboutShopText>
              <LearnMoreButton target="_blank" variant="outlined" href={"/shop/seller/" + productData.seller?.sellerID + "/" + productData.seller?.sellerName?.replace(/\W+/g, "-")}>
                <Typography className={"roboto-medium"}>LEARN MORE</Typography>
              </LearnMoreButton>
            </Grid>
            <Grid item xs={6}>
              <PurchaseView data={productData} selectedSku={selectedSku} setSelectedSku={setSelectedSku} />
              <Accordion
                square
                expanded={expanded === "Product Details"}
                onChange={handleChange("Product Details")}
              >
                <AccordionSummary>
                  <VerticallyCenterDiv>
                    <Typography variant="h6" className={"roboto-bold"}>
                      {"Product Details"}
                    </Typography>
                    {expanded === "Product Details" ? (
                      <ExpandLess fontSize={"large"} />
                    ) : (
                      <ExpandMore fontSize={"large"} />
                    )}
                  </VerticallyCenterDiv>
                </AccordionSummary>
                <StyledAccordianDetails>
                  {/* <TextField 
                    multiline
                    fullWidth
                    value={productData.details ?? ''}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    variant="standard"
                  /> */}
                  <Typography variant="h2" sx={{ fontSize: "1.1rem", whiteSpace: "pre-line" }}>
                    {productData.details}
                  </Typography>
                </StyledAccordianDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "Purpose Description"}
                onChange={handleChange("Purpose Description")}
              >
                <AccordionSummary>
                  <VerticallyCenterDiv>
                    <Typography variant="h6" className={"roboto-bold"}>
                      {"This Product’s Purpose"}
                    </Typography>
                    {expanded === "Purpose Description" ? (
                      <ExpandLess fontSize={"large"} />
                    ) : (
                      <ExpandMore fontSize={"large"} />
                    )}
                  </VerticallyCenterDiv>
                </AccordionSummary>
                <StyledAccordianDetails>
                  {/* <TextField 
                    multiline
                    fullWidth
                    value={productData.details ?? ''}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    variant="standard"
                  /> */}
                  <Typography variant="h2" sx={{ fontSize: "1.1rem", whiteSpace: "pre-line" }}>
                    {productData.purposeDesc
                    ? productData.purposeDesc
                    : ""}
                  </Typography>
                </StyledAccordianDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "Type"}
                onChange={handleChange("Type")}
              >
                <AccordionSummary>
                  <VerticallyCenterDiv>
                    <Typography variant="h6" className={"roboto-bold"}>
                      {"Type of Product"}
                    </Typography>
                    {expanded === "Type" ? (
                      <ExpandLess fontSize={"large"} />
                    ) : (
                      <ExpandMore fontSize={"large"} />
                    )}
                  </VerticallyCenterDiv>
                </AccordionSummary>
                <StyledAccordianDetails>
                  <DetailsText variant="body1">
                    <div style={{ display: "flex" }}>
                      {productData.typeOfProduct == PRODUCT_TYPE.SERVICE ? productData.typeOfProduct : productData.typeOfProduct + ' Product'}
                    </div>
                  </DetailsText>
                </StyledAccordianDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "Shipping"}
                onChange={handleChange("Shipping")}
              >
                <AccordionSummary>
                  <VerticallyCenterDiv>
                    <Typography variant="h6" className={"roboto-bold"}>
                      {"Shipping Policy"}
                    </Typography>
                    {expanded === "Shipping" ? (
                      <ExpandLess fontSize={"large"} />
                    ) : (
                      <ExpandMore fontSize={"large"} />
                    )}
                  </VerticallyCenterDiv>
                </AccordionSummary>
                <StyledAccordianDetails>
                  <DetailsText variant="body1">
                    <>
                      <div style={{ display: "flex" }}>
                        {`${productData.shippingService} $${round(productData?.shippingOneItemPrice, 2)}`}
                      </div>
                    </>
                    {productData.shippingUpgrades?.map((policy, i) => {
                      return (
                        <>
                          <div style={{ display: "flex" }}>
                            {`${policy.service} $${round(productData?.shippingOneItemPrice + policy?.oneItemPrice, 2)}`}
                          </div>
                        </>
                      );
                    })
                    }
                  </DetailsText>
                </StyledAccordianDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "Returns"}
                onChange={handleChange("Returns")}
              >
                <AccordionSummary>
                  <VerticallyCenterDiv>
                    <Typography variant="h6" className={"roboto-bold"}>
                      {"Return Policy"}
                    </Typography>
                    {expanded === "Returns" ? (
                      <ExpandLess fontSize={"large"} />
                    ) : (
                      <ExpandMore fontSize={"large"} />
                    )}
                  </VerticallyCenterDiv>
                </AccordionSummary>
                <StyledAccordianDetails>
                  <DetailsText variant="body1">
                    <div style={{ display: "flex" }}>
                      {`Return Policy: ${productData.seller?.returnPolicy?.policy}`}
                    </div>
                    <div style={{ display: "flex" }}>
                      {productData.seller?.returnPolicy?.maxDays > 0 ? `Returns accepted within ${productData.seller?.returnPolicy?.maxDays} days` : ``}
                    </div>
                  </DetailsText>
                </StyledAccordianDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "Contact"}
                onChange={handleChange("Contact")}
              >
                <AccordionSummary>
                  <VerticallyCenterDiv>
                    <Typography variant="h6" className={"roboto-bold"}>
                      {"Contact Shop"}
                    </Typography>
                    {expanded === "Contact" ? (
                      <ExpandLess fontSize={"large"} />
                    ) : (
                      <ExpandMore fontSize={"large"} />
                    )}
                  </VerticallyCenterDiv>
                </AccordionSummary>
                <StyledAccordianDetails>
                  <DetailsText variant="body1">
                    <div style={{ display: "flex" }}>
                      {productData.seller?.contactEmail}
                    </div>
                    <div style={{ display: "flex" }}>
                      {productData.seller?.contactPhone}
                    </div>
                  </DetailsText>
                </StyledAccordianDetails>
              </Accordion>
            </Grid>
          </Grid>
        ) : (
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <ProductGalleryPreview
                data={
                  productData && Array.isArray(productData["productImageURL"])
                    ? productData["productImageURL"]
                    : productData
                      ? [
                        productData["productImageURL"],
                        productData["productImageURL"],
                        productData["productImageURL"],
                      ]
                      : []
                }
              />
            </Grid>
            <Grid item xs={12}>
              <PurchaseView data={productData} selectedSku={selectedSku} setSelectedSku={setSelectedSku} />
              <Accordion
                square
                expanded={expanded === "Product Details"}
                onChange={handleChange("Product Details")}
              >
                <AccordionSummary>
                  <VerticallyCenterDiv>
                    <Typography variant="h6" className={"roboto-bold"}>
                      {"Product Details"}
                    </Typography>
                    {expanded === "Product Details" ? (
                      <ExpandLess fontSize={"large"} />
                    ) : (
                      <ExpandMore fontSize={"large"} />
                    )}
                  </VerticallyCenterDiv>
                </AccordionSummary>
                <StyledAccordianDetails>
                  <TextField
                    multiline
                    fullWidth
                    value={productData.details ?? ''}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    variant="standard"
                  />
                </StyledAccordianDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "Type"}
                onChange={handleChange("Type")}
              >
                <AccordionSummary>
                  <VerticallyCenterDiv>
                    <Typography variant="h6" className={"roboto-bold"}>
                      {"Type of Product"}
                    </Typography>
                    {expanded === "Type" ? (
                      <ExpandLess fontSize={"large"} />
                    ) : (
                      <ExpandMore fontSize={"large"} />
                    )}
                  </VerticallyCenterDiv>
                </AccordionSummary>
                <StyledAccordianDetails>
                  <DetailsText variant="body1">
                    <div style={{ display: "flex" }}>
                      {productData.typeOfProduct == PRODUCT_TYPE.SERVICE ? productData.typeOfProduct : productData.typeOfProduct + ' Product'}
                    </div>
                  </DetailsText>
                </StyledAccordianDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "Shipping"}
                onChange={handleChange("Shipping")}
              >
                <AccordionSummary>
                  <VerticallyCenterDiv>
                    <Typography variant="h6" className={"roboto-bold"}>
                      {"Shipping Policy"}
                    </Typography>
                    {expanded === "Shipping" ? (
                      <ExpandLess fontSize={"large"} />
                    ) : (
                      <ExpandMore fontSize={"large"} />
                    )}
                  </VerticallyCenterDiv>
                </AccordionSummary>
                <StyledAccordianDetails>
                  <DetailsText variant="body1">
                    <>
                      <div style={{ display: "flex" }}>
                        {`${productData.shippingService} $${round(productData?.shippingOneItemPrice, 2)}`}
                      </div>
                    </>
                    {productData.shippingUpgrades?.map((policy, i) => {
                      return (
                        <>
                          <div style={{ display: "flex" }}>
                            {`${policy.service} $${round(productData?.shippingOneItemPrice + policy?.oneItemPrice, 2)}`}
                          </div>
                        </>
                      );
                    })
                    }
                  </DetailsText>
                </StyledAccordianDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "Returns"}
                onChange={handleChange("Returns")}
              >
                <AccordionSummary>
                  <VerticallyCenterDiv>
                    <Typography variant="h6" className={"roboto-bold"}>
                      {"Return Policy"}
                    </Typography>
                    {expanded === "Returns" ? (
                      <ExpandLess fontSize={"large"} />
                    ) : (
                      <ExpandMore fontSize={"large"} />
                    )}
                  </VerticallyCenterDiv>
                </AccordionSummary>
                <StyledAccordianDetails>
                  <DetailsText variant="body1">
                    <div style={{ display: "flex" }}>
                      {`Return Policy: ${productData.seller?.returnPolicy?.policy}`}
                    </div>
                    <div style={{ display: "flex" }}>
                      {productData.seller?.returnPolicy?.maxDays > 0 ? `Returns accepted within ${productData.seller?.returnPolicy?.maxDays} days` : ``}
                    </div>
                  </DetailsText>
                </StyledAccordianDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === "Contact"}
                onChange={handleChange("Contact")}
              >
                <AccordionSummary>
                  <VerticallyCenterDiv>
                    <Typography variant="h6" className={"roboto-bold"}>
                      {"Contact Shop"}
                    </Typography>
                    {expanded === "Contact" ? (
                      <ExpandLess fontSize={"large"} />
                    ) : (
                      <ExpandMore fontSize={"large"} />
                    )}
                  </VerticallyCenterDiv>
                </AccordionSummary>
                <StyledAccordianDetails>
                  <DetailsText variant="body1">
                    <div style={{ display: "flex" }}>
                      {productData.seller?.contactEmail}
                    </div>
                    <div style={{ display: "flex" }}>
                      {productData.seller?.contactPhone}
                    </div>
                  </DetailsText>
                </StyledAccordianDetails>
              </Accordion>
              <Button
                disabled={true}
                style={{
                  backgroundColor: "var(--mintGreenSolid)",
                  padding: "12px 24px 12px 24px",
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "16px",
                  marginBottom: "16px",
                  '&.Mui-disabled': {
                    cursor: 'not-allowed',
                  },
                }}
              >
                This Product's Purpose
              </Button>
              <AboutShopText
                gutterBottom
                variant="body1"
                className={"roboto-regular"}
              >
                {productData.purposeDesc
                  ? productData.purposeDesc
                  : ""}
              </AboutShopText>
              <AboutShopText
                className={clsx("roboto-bold", classes.textMargins)}
                sx={{ color: "var(--mintGreenSolid)" }}
                variant="h6"
              >

                <b>{`About the Shop: ${productData.seller?.sellerName ? productData.seller?.sellerName : ""}`}</b>
              </AboutShopText>
              <AboutShopText
                gutterBottom
                variant="body1"
                className={"roboto-regular"}
              >
                {productData.seller?.inAction?.text.length > 0
                  ? productData.seller?.inAction?.text[0]
                  : ""}
              </AboutShopText>
              <LearnMoreButton target="_blank" variant="outlined" href={"/shop/seller/" + productData.seller?.sellerID + "/" + productData.seller?.sellerName?.replace(/\W+/g, "-")}>
                <Typography className={"roboto-medium"}>LEARN MORE</Typography>
              </LearnMoreButton>
            </Grid>
          </Grid>
        )}
      </Container>
      <div className={classes.divider} />
      <ReviewsDiv>
        <Reviews reviews={reviewData} sellerID={productData?.seller?.sellerID} seller={productData?.seller} admin={productData.seller?.adminUserID?.toLowerCase() == currUserId?.toLowerCase()} />
      </ReviewsDiv>
      <Container maxWidth="xl">
        <div className={classes.divider} />
        <Typography variant="h4" gutterBottom className={"roboto-medium"}>
          More from this seller
        </Typography>
      </Container>
      <CarouselProductView data={moreFromShop} />
      <Container maxWidth="xl">
        <div className={classes.divider} />
        <Typography variant="h4" gutterBottom className={"roboto-medium"}>
          BEST SELLERS
        </Typography>
      </Container>
      <CarouselProductView data={moreFromShop} />
      <div className={classes.divider} />
      <BubbleCategoryView />
      <div className={classes.divider} />
      <RelatedCategoriesAndSearches data={relatedCategories} />
      <div className={classes.divider} />
      <ExploreShops data={exploreShops} />
      <div className={classes.divider} />
      <OtherInterests otherInterests={otherInterests} />
      <div className={classes.divider} />
      <TrendingProductsLayout />
    </MasterLayout>
  );
};

export default ProductLayout;
