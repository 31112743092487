import { getSessionJwt, tryParseJSONObject } from "./utilities";
const {
  NODE_API_ENDPOINT
} = require("./constants");

async function getUserByID(id, callback) {
  const jwtToken = await getSessionJwt();

  fetch(NODE_API_ENDPOINT + "/get-user-by-id?id=" + id, {
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
    }
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then((data) => {
      //console.log(data)
      callback(data)
    })
    .catch((error) => {
      console.log(error)
      callback(null)
    });
}

async function updateUser(userInfo, callback) {
  const jwtToken = await getSessionJwt();

  fetch(NODE_API_ENDPOINT + "/update-user", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
      //"Access-Control-Allow-Origin":'*',
      //"Access-Control-Allow-Methods":'GET, POST, PUT, DELETE, OPTIONS'
    },
    body: JSON.stringify(userInfo),
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function updateFirstTimeUserAsync(userInfo) {
  const jwtToken = await getSessionJwt();
  let requestURL = NODE_API_ENDPOINT + "/update-first-time-user";

  const response = await fetch(requestURL, {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userInfo),
  })
  const data = await response.json();
  return data;
}

async function deactivateBuyer(callback) {
  const jwtToken = await getSessionJwt();
  fetch(NODE_API_ENDPOINT + "/deactivate-seller", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  })
  .then((response) => {
    if (response.status === 200) return response.json();
    return null;
  })
  .then(
    (data) => {callback(data)},
    (error) => console.log(error)
  );
}

export { getUserByID, updateUser, updateFirstTimeUserAsync, deactivateBuyer };
