import {
  Grid,
  Typography,
  Link,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";

const useStyles = makeStyles({
  legalFooterDiv: {
    padding: "8px 0",
    backgroundColor: "var(--mintGreenSolid)",
  },
  legalFooterText: {
    fontSize: "1.05rem",
    color: "#FFFFFF",
  },
});

function LegalFooter() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const mobile = useMobileMediaQuery();

  return (
    <div className={classes.legalFooterDiv}>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {/* <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Typography className={classes.legalFooterText}>
                  English
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.legalFooterText}>|</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.legalFooterText}>
                  $ (USD)
                </Typography>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item>
            <Typography className={classes.legalFooterText}>
              2024, Purposer®
            </Typography>
          </Grid>
          <Grid item>
            {!mobile ? (
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <Typography className={classes.legalFooterText}>
                    <Link href="/termsofuse" color="inherit" underline="none">
                      Terms of Use
                    </Link>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.legalFooterText}>|</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.legalFooterText}>
                    <Link href="/privacypolicy" color="inherit" underline="none">
                      Privacy Policy
                    </Link>
                  </Typography>
                </Grid>
                {/* <Grid item>
                  <Typography className={classes.legalFooterText}>|</Typography>
                </Grid> */}
                {/* <Grid item>
                  <Typography className={classes.legalFooterText}>
                    <Link href="/internetbasedads" color="inherit" underline="none">
                      Internet-Based Ads
                    </Link>
                  </Typography>
                </Grid> */}
              </Grid>
            ) : (
              <IconButton
                id="menu-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{ color: "white" }}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
            )}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "menu-button",
              }}
            >
              <MenuItem
                component={Link}
                href="/termsofuse"
                color="inherit"
                underline="none"
                onClick={handleClose}
              >
                Terms of Use
              </MenuItem>
              <Divider sx={{ borderColor: "var(--mintGreenSolid)" }} />
              <MenuItem
                href="/privacypolicy"
                color="inherit"
                underline="none"
                component={Link}
                onClick={handleClose}
              >
                Privacy Policy
              </MenuItem>
              {/* <Divider sx={{ borderColor: "var(--mintGreenSolid)" }} />
              <MenuItem
                href="/internetbasedads"
                color="inherit"
                underline="none"
                component={Link}
                onClick={handleClose}
              >
                Internet-Based Ads
              </MenuItem> */}
            </Menu>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default LegalFooter;
