import React from "react";
import { ManagementMasterLayout } from "../../layouts";
import sellerDashboardJsonData from "../../json/seller_dashboard.json";
import {
  ButtonBase,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { getReviewsBySeller } from "../../util/products";
import Review from "../../components/Review";
import Carousel from "react-multi-carousel";
import { useParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";

const useStyles = makeStyles({
  galleryDiv: {
    paddingBottom: 10,
    overflowX: "hidden",
    display: "relative",
  },
  galleryContainer: {
    display: "relative",
    //marginBottom: "-16px"
  },
  galleryGrid: {
    padding: 40,
    // margin: "auto",
    marginBottom: "32px"
  },
  galleryItem: {
    paddingTop: 15,
    paddingBottom: 15,
    maxWidth: "100%",
  },
  galleryButton: {
    width: "35px",
    height: "35px",
    borderRadius: "25px",
    border: "none",
    backgroundColor: "#EAF1EF",
    margin: 7,
  },
  rightItemMargin: {
    margin: "auto 50px",
  },
  rightGridItem: {
    paddingLeft: 50,
  },
  gridItems: {
    marginTop: "var(--dividerHeight)",
  },
  reviewsCarousel: {
    position: "relative",
    width: "93%",
    paddingBottom: 100,
    marginTop: 10,
    margin: "auto",
    overflow: "hidden",
  },
  reviewsNavButtons: {
    position: "absolute",
    top: "29%",
    border: "1px solid black",
    borderRadius: 20,
    backgroundColor: "#FFFFFF",
  },
  reviewsPrevButton: {
    left: -10,
    margin: 10,
  },
  reviewsNextButton: {
    right: -10,
    margin: 10,
  },
  borderSection: {
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius:  5,
    padding: 5
  },
  sectionColor: {
    color: "#EE907B"
  },
  buttonStyling: {
    backgroundColor: "#EE907B",
    border: "none",
    color: "white",
    padding: "5px 35px",
    borderRadius: "3px",
    marginLeft: "20px"
  },
  birthdayMargins: {
    margin: "auto 20px"
  },
});
const maxNumPages = 5;

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  return (
    <>
      <ButtonBase
        sx={{
          position: "absolute",
          width: "35px",
          height: "35px",
          borderRadius: "25px",
          border: "none",
          left: { xs: "2%", sm: "18%", md: "23%", lg: "30%", xl: "30%" },
          bottom: 7,
          backgroundColor: "#EAF1EF",
          zIndex: 50,
        }}
        onClick={previous}
      >
        <ArrowBack sx={{}} />
      </ButtonBase>
      <ButtonBase
        sx={{
          position: "absolute",
          width: "35px",
          height: "35px",
          borderRadius: "25px",
          border: "none",
          right: { xs: "2%", sm: "18%", md: "23%", lg: "30%", xl: "30%" },
          bottom: 7,
          zIndex: 50,
          backgroundColor: "#EAF1EF",
        }}
        onClick={next}
      >
        <ArrowForward sx={{}} />
      </ButtonBase>
    </>
  );
};

const CustomDots = ({ index, onClick }) => {
  const classes = useStyles();

  if (index < maxNumPages) {
    const NumberButton = (
      <ButtonBase
        onClick={(e) => {
          onClick();
          e.preventDefault();
        }}
        className={classes.galleryButton}
      >
        <Typography>{index + 1}</Typography>
      </ButtonBase>
    );

    if (index === maxNumPages - 1) {
      return (
        <>
          {NumberButton}
          <ButtonBase className={classes.galleryButton}>
            <Typography>...</Typography>
          </ButtonBase>
        </>
      );
    } else {
      return <>{NumberButton}</>;
    }
  } else {
    return <></>;
  }
};

const responsive = {
  break1: {
    breakpoint: { max: 4000, min: 1100 },
    items: 2,
  },
  break2: {
    breakpoint: { max: 1100, min: 0 },
    items: 1,
  },
};


const ShopReviewsLayout = () => {
  const sellerDashboardData = sellerDashboardJsonData;


  const [reviewData, setReviewData] = React.useState({});
  const classes = useStyles();
  const { id: sellerIDparam } = useParams();

  React.useEffect(() => {

    getReviewsBySeller(sellerIDparam, (data) => {
      setReviewData(data);
    })
  }, []);

  return (
    <ManagementMasterLayout
      title={"Shop Reviews"}
      sellerName={sellerDashboardData.name}
      sellerPicture={sellerDashboardData.pic}
    >
      <div style={{padding: "36px"}}>
        <Typography variant="h3" style={{textAlign: "center", color: "var(--coral)", fontWeight: "bold"}}>All Product Reviews</Typography>
        { reviewData && reviewData.results && reviewData.results.length > 0? (
                  <>
                    <Carousel
                      responsive={responsive}
                      infinite={false}
                      arrows={false}
                      draggable={false}
                      className={classes.reviewsCarousel}
                      showDots={true}
                      customDot={<CustomDots />}
                      customButtonGroup={<CustomButtonGroupAsArrows />}
                    >
                      {reviewData.results.map((review, index) => {
                        return <Review review={review} key={index} sellerID={sellerIDparam} admin={true} />;
                      })}
                    </Carousel>
                  </>
                ) : ( (
                  <Typography
                    align="center"
                    className={clsx(classes.noReviewsText, "roboto-regular")}
                  >
                    No Reviews
                  </Typography>
                ))}
      </div>
    </ManagementMasterLayout>
  )
}

export default ShopReviewsLayout;
