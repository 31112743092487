import { Divider } from "@mui/material";
import OpenAShopStepper from "../../components/OpenAShopStepper";
import PurposeCategoryMasterLayout from "../MasterLayouts/PurposeCategoryMasterLayout";
import SellerDealBanner from "../../components/SellerDealBanner";

function SellerSignUp() {
  return (
    <PurposeCategoryMasterLayout>
      <Divider />
      <SellerDealBanner/>
      <OpenAShopStepper />
    </PurposeCategoryMasterLayout>
  );
}

export default SellerSignUp;
