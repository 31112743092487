import { TextField, Typography, MenuItem } from "@mui/material";
import React from "react";
import contactTopicsDefault from "../json/contact_topics.json";
import { styled } from "@mui/material/styles";

const TextTopMargin = styled(Typography)({
  marginTop: 20,
});

const SingleLine = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height: 40,
  },
});

const SingleLineTextField = styled(SingleLine)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 7,
    borderStyle: "none",
    backgroundColor: "var(--textfieldMintGreen)",
    color: "#000000",
    borderColor: "var(--textfieldMintGreen)",
    "& fieldset": {
      borderColor: "var(--textfieldMintGreen)",
    },
    "&:hover fieldset": {
      borderColor: "var(--textfieldMintGreen)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--textfieldMintGreen)",
    },
  },
});

const MultiLineTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 7,
    backgroundColor: "var(--textfieldMintGreen)",
    color: "#000000",
    borderColor: "var(--textfieldMintGreen)",
    "& fieldset": {
      borderColor: "var(--textfieldMintGreen)",
    },
    "&:hover fieldset": {
      borderColor: "var(--textfieldMintGreen)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--textfieldMintGreen)",
    },
  },
});

function HelpSubmission(props) {
  const { topics = contactTopicsDefault } = props;

  const [topic, setTopic] = React.useState("");

  const handleTopicChange = (event) => {
    setTopic(event.target.value);
  };

  return (
    <>
      <Typography variant="h6" gutterBottom className={"roboto-medium"}>
        Topic
      </Typography>
      <SingleLineTextField
        fullWidth
        select
        variant="outlined"
        value={topic}
        onChange={handleTopicChange}
      >
        {topics &&
          topics.map((topic, index) => {
            return (
              <MenuItem key={index} value={topic}>
                <Typography className={"roboto-regular"}>{topic}</Typography>
              </MenuItem>
            );
          })}
      </SingleLineTextField>
      <TextTopMargin variant="h6" gutterBottom className={"roboto-medium"}>
        Name
      </TextTopMargin>
      <SingleLineTextField variant="outlined" fullWidth />
      <TextTopMargin variant="h6" gutterBottom className={"roboto-medium"}>
        Email
      </TextTopMargin>
      <SingleLineTextField variant="outlined" fullWidth />
      <TextTopMargin variant="h6" gutterBottom className={"roboto-medium"}>
        Message
      </TextTopMargin>
      <MultiLineTextField variant="outlined" fullWidth multiline rows={5} />
    </>
  );
}

export default HelpSubmission;
