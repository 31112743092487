import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ManagementMasterLayout } from "..";
import TrackingandShippingView from "../../components/TrackingandShippingView";
import TrackingandShippingCompletedView from "../../components/TrackingandShippingCompletedView";
import sellerDashboardJsonData from "../../json/seller_dashboard.json";
import { getOrderById, getOrdersBySellerId, markOrderAsSubmitted, markOrderAsCompleted, addOrderShippingDetails } from "../../util/orders";
import { shortenUUID } from "../../util/utilities";
import toast, { Toaster } from 'react-hot-toast';
import { ORDER_STATUS } from "../../util/constants";

const OrdersAndShippingLayout = () => {
  const sellerDashboardData = sellerDashboardJsonData;
  const [currentOrders, setCurrentOrders] = useState(null);
  const [completedOrders, setCompletedOrders] = useState(null);
  const [loadingGetOrders, setLoadingGetOrders] = useState(false);
  const [filterCurrentOrders, setFilterCurrentOrders] = useState('Newest First');
  const [filterCompletedOrders, setFilterCompletedOrders] = useState('Newest First');


  const { id: sellerIDparam } = useParams();

  useEffect(() => {
    if (!loadingGetOrders && !currentOrders && !completedOrders) {
      setLoadingGetOrders(true)
      getOrdersBySellerId(sellerIDparam, (data) => {
        if (data) {
          sortOrders(data)
        } else {
          console.log("Can't find orders!")
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!currentOrders || !completedOrders) return;
    sortOrders([...currentOrders, ...completedOrders]);
  }, [filterCurrentOrders, filterCompletedOrders]);

  function handleMarkOrderAsSubmitted(orderID) {
    console.log('Marking order as submitted: ', orderID)
    markOrderAsSubmitted(sellerIDparam, orderID, (data) => {
      let newOrders = [...currentOrders, ...completedOrders];
      const changedOrder = newOrders.find(o => o.orderID.toLowerCase() == orderID.toLowerCase())
      changedOrder.status = ORDER_STATUS.SUBMITTED;
      sortOrders(newOrders)
      toast.success(`Marked order ${shortenUUID(orderID)} as uncomplete!`);
    });
  }

  function handleMarkOrderAsCompleted(orderID) {
    console.log('Marking order as completed: ', orderID)
    markOrderAsCompleted(sellerIDparam, orderID, (data) => {
      let newOrders = [...currentOrders, ...completedOrders];
      const changedOrder = newOrders.find(o => o.orderID.toLowerCase() == orderID.toLowerCase())
      changedOrder.status = ORDER_STATUS.COMPLETED;
      sortOrders(newOrders)
      toast.success(`Marked order ${shortenUUID(orderID)} as complete!`);
    });
  }

  function handleChangeShippingInfo(orderID, courier, trackingNum) {
    let newOrders = [...currentOrders, ...completedOrders];
    const changedOrder = newOrders.find(o => o.orderID.toLowerCase() == orderID.toLowerCase())
    changedOrder.shippingTrackingCarrier = courier;
    changedOrder.shippingTrackingNumber = trackingNum;
    changedOrder.unsaved = true;
    sortOrders(newOrders)
  }

  function handleSaveShippingInfo(orderID) {
    console.log('Saving shipping info for: ', orderID)
    let orders = [...currentOrders, ...completedOrders];
    const currOrder = orders.find(o => o.orderID.toLowerCase() == orderID.toLowerCase())
    addOrderShippingDetails(sellerIDparam, orderID, currOrder.shippingTrackingCarrier, currOrder.shippingTrackingNumber, (data) => {
      let newOrders = [...currentOrders, ...completedOrders];
      const changedOrder = newOrders.find(o => o.orderID.toLowerCase() == orderID.toLowerCase())
      changedOrder.unsaved = false;
      sortOrders(newOrders)
      toast.success(`Updated shipping info for order ${shortenUUID(orderID)}!`);
    });
  }

  function sortArrayWithFilter(orders, filter) {
    if (filter == 'Newest First') {
      orders.sort((a, b) =>  { return new Date(b.orderDateTime) - new Date(a.orderDateTime) });
    } else if (filter == 'Oldest First') {
      orders.sort((b, a) => { return new Date(b.orderDateTime) - new Date(a.orderDateTime) });
    }
  }

  function sortOrders(orders) {
    const currOrders = orders.filter(o => o.status == ORDER_STATUS.SUBMITTED);
    const completeOrders = orders.filter(o => o.status == ORDER_STATUS.COMPLETED);
    sortArrayWithFilter(currOrders, filterCurrentOrders);
    sortArrayWithFilter(completeOrders, filterCompletedOrders);
    setCurrentOrders(currOrders);
    setCompletedOrders(completeOrders);
  }

  return (
    <ManagementMasterLayout
      title="Shop Orders"
      sellerName={sellerDashboardData.name}
      sellerPicture={sellerDashboardData.pic}
    >
      <Toaster 
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <Box sx={{ my: 5, mx: 2 }}>
        <TrackingandShippingView
          trackShipData={currentOrders}
          filter={filterCurrentOrders}
          setFilter={setFilterCurrentOrders}
          handleMarkOrderAsCompleted={handleMarkOrderAsCompleted}
          title="Current Orders"
        />
      </Box>
      <Box sx={{ mb: 5, mx: 2 }}>
        <TrackingandShippingCompletedView
          trackShipData={completedOrders}
          filter={filterCompletedOrders}
          setFilter={setFilterCompletedOrders}
          handleMarkOrderAsSubmitted={handleMarkOrderAsSubmitted}
          handleChangeShippingInfo={handleChangeShippingInfo}
          handleSaveShippingInfo={handleSaveShippingInfo}
          title="Completed Orders"
        />
      </Box>
    </ManagementMasterLayout>
  );
};

export default OrdersAndShippingLayout;
