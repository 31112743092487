import { createSlice } from "@reduxjs/toolkit";

export const userDataSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
  },
  reducers: {
    loginUser(state, action) {
      state.user = action.payload;
    },
    deleteUser(state) {
      state.user = null;
    },
  },
});

//const { actions, reducer } = userDataSlice;

// Action creators are generated for each case reducer function
export const { loginUser, deleteUser } = userDataSlice.actions;

export const selectUser = (state) => state.user.user;

export default userDataSlice.reducer;
