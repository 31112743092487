import React from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  Divider,
  Snackbar,
  Alert,
  AlertTitle,
  Box,
  Fade,
  Modal,
  CircularProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import KeepUp from "../components/KeepUp";
import SignIn from "../components/SignIn";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { PurposeCategoryMasterLayout } from "../layouts";
import clsx from "clsx";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
//JSON filler ----------------
import mockBlogData from "../json/blog_posts.json";
import FederatedButtons from "../components/FederatedButtons";
import { useLocation } from "react-router-dom";
//----------------------------

const useStyles = makeStyles({
  header: {
    color: "var(--mintGreenSolid)",
    marginTop: 25,
    marginBottom: 20,
  },
  signInDiv: {
    marginTop: 40,
  },
  accountDiv: {
    backgroundImage:
      'url("https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Sign+In+and+Sign+Out+Page/sign_in_background.png")',
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    textAlign: "center",
    paddingTop: 30,
    paddingBottom: 70,
  },
  accountButton: {
    textTransform: "none",
    width: "80%",
    borderRadius: 10,
  },
  createAccountButton: {
    backgroundColor: "var(--coral)",
    color: "#FFFFFF",
    marginBottom: 40,
    "&:hover": {
      backgroundColor: "var(--coral)",
    },
  },
  continueAccountButtonText: {
    width: "100%",
  },
  keepUpDiv: {
    marginTop: 200,
    marginBottom: 70,
  },
  purposerIcon: {
    maxHeight: 100,
    display: "block",
    margin: "auto",
    marginBottom: 50,
  },
});

function useQuery() {
  const { search } = useLocation();
  let urlItems = `${search}`.split("?");
  urlItems.splice(0,1);
  const itemObject = {}
  for (const item of urlItems) {
    itemObject[item.split('=')[0]] = JSON.parse(item.split('=')[1].toLowerCase());
  }
  return itemObject;
}

function SignInLayout(props) {
  const classes = useStyles();
  useDocumentTitle("Login");
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [fromSellerSignOn, setFromSellerSignOn] = React.useState(false);
  const [afterPasswordReset, setAfterPasswordReset] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  let query = useQuery();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [usernamePassword, setUsernamePassword] = React.useState({
    username: "",
    password: "",
  });

  const handleFormValues = (e) => {
    setUsernamePassword((preValues) => {
      return { ...preValues, [e.target.name]: e.target.value };
    });
  };

  React.useEffect(() => {
    if (query["creation"]) {
      setOpen(true);
    }
    if (query["redirectToCreateShop"]) {
      setFromSellerSignOn(true);
    }
    if (query["afterPasswordReset"]) {
      setAfterPasswordReset(true);
    }
  }, [query]);

  const signIn = async () => {
    if (!loading) {
      setLoading(true)
      try {
        await Auth.signIn(
          usernamePassword.username,
          usernamePassword.password
        ).then(() =>  {
          if (fromSellerSignOn) {
            history.push("/seller/signup");
          } else {
            history.push("/");
          }
        });
      } catch (error) {
        console.log("error signing in", error);
        window.alert(`${error}`);
      }
      setLoading(false)
    }
  };

  const googleSignIn = async () => {
    try {
      Auth.federatedSignIn({ provider: "Google" });
    } catch (error) {
      console.log("error signing in", error);
    }
  };

  const facebookSignIn = async () => {
    try {
      Auth.federatedSignIn({ provider: "Facebook" });
    } catch (error) {
      console.log("error signing in", error);
    }
  };

  const appleSignIn = async () => {
    try {
      Auth.federatedSignIn({ provider: "SignInWithApple" });
    } catch (error) {
      console.log("error signing in", error);
    }
  };

  return (
    <PurposeCategoryMasterLayout>
      <Divider />
      <Container maxWidth="xl">
        <Modal open={loading} sx={{ overflow: "scroll" }}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: 2,
              bgcolor: "background.paper",
              p: 4,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
          </Box>
        </Modal>
        <Typography
          align="center"
          variant="h2"
          className={clsx(classes.header, "roboto-medium")}
        >
          Welcome!
        </Typography>
        {afterPasswordReset &&
        <Typography
          align="center"
          variant="h4"
          className={clsx(classes.header, "roboto-medium")}
        >
          {`Awesome! You just reset your password successfully. Please re-login below.`}
        </Typography>
        }
        <Grid
          container
          direction="row"
          rowSpacing={6}
          justifyContent="space-between"
        >
          <Grid item xs={12} sm={12} md={5} lg={6}>
            <div className={classes.signInDiv}>
              <Box sx={{ my: 2 }}>
                <Fade in={open}>
                  <Box sx={{}}>
                    <Alert severity="success">
                      <AlertTitle>Account Successfully Created!</AlertTitle>
                      Login to access your account!
                    </Alert>
                  </Box>
                </Fade>
              </Box>
              <SignIn
                handleFormValues={handleFormValues}
                handleSignIn={signIn}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={4}>
            <div className={classes.accountDiv}>
              <img
                src={
                  "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/logos/PurposerIcon-WhiteP-P486-01.png"
                }
                alt=""
                className={classes.purposerIcon}
              />
              <Button
                variant="contained"
                disableElevation
                className={clsx(
                  classes.accountButton,
                  classes.createAccountButton
                )}
                onClick={() => history.push("/signup")}
              >
                <Typography variant="h5" className={"roboto-medium"}>
                  Create an Account
                </Typography>
              </Button>
              <FederatedButtons variant="Google" handler={googleSignIn} />
              <FederatedButtons variant="Facebook" handler={facebookSignIn} />
              <FederatedButtons variant="Apple" handler={appleSignIn} />
              <div style={{padding: "16px"}}>
              <Typography variant="caption">
                By signing up for an account, through our Website, Google, Facebook or Apple, you accept our <a href="/termsofuse" style={{textDecoration: "underline"}}>Terms of Use</a> &amp; <a href="/privacy" style={{textDecoration: "underline"}}>Privacy Policy</a>. By submitting your information, you agree to receive emails and text marketing notifications from us. You may opt out at any time. Consent to receive messages is not required as a condition of any purchase. For SMS, reply STOP to cancel. Message and data rates may apply. This discount code will expire after 3 months and may only be used once. View Full <a href="/termsofuse" style={{textDecoration: "underline"}}>Terms of Use</a> &amp; <a href="/privacy" style={{textDecoration: "underline"}}>Privacy Policy</a>.
              </Typography>
              </div>
            </div>
            
          </Grid>
        </Grid>
      </Container>
      <div style={{height: "var(--dividerHeight)"}}/>
    </PurposeCategoryMasterLayout>
  );
}

export default SignInLayout;
