import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import CarouselProductView from "./CarouselProductView";
import clsx from "clsx";
import { getProductCategoryById } from "../util/productCategories";

const useStyles = makeStyles({
  backgroundDiv: {
    backgroundColor: "var(--lightPastelMintGreen)",
    overflow: "hidden",
    paddingBottom: "var(--dividerHeight)",
  },
  text: {
    margin: "20px 0 40px 0",
  },
});

const TrendingProductsLayout = ({ data }) => {
  const classes = useStyles();

  const [loadingProducts, setLoadingProducts] = useState(true);
  const [trendingProducts, setTrendingProducts] = useState([]);

  useEffect(() => {
    //Trending Products
    getProductCategoryById('0', (data) => {
      setTrendingProducts(data);
      setLoadingProducts(false);
    });

  }, []);
  return (
    <div className={classes.backgroundDiv}>
      <Typography
        align="center"
        variant="h4"
        className={clsx(classes.text, "roboto-medium")}
      >
        {/* RECENTLY VIEWED */}
        TRENDING
      </Typography>
      <CarouselProductView data={trendingProducts} />
    </div>
  );
};

export default TrendingProductsLayout;
