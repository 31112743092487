import Footer from "../../components/Footer";
import LegalFooter from "../../components/LegalFooter";
import SiteHeader from "../../components/SiteHeader";

const MasterLayout = (props) => {
  return (
    <>
      <SiteHeader />
      {props.children ? (
        props.children
      ) : (
        <h1>Something Has gone really wrong :o</h1>
      )}
      <Footer />
      <LegalFooter />
    </>
  );
};

export default MasterLayout;
