import {
  ButtonBase,
  Container,
  Divider,
  Grid,
  Typography,
  Box,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import { PurposeCategoryMasterLayout } from "../../layouts";
import SocialMedia from "../../components/SocialMedia";
import Blog from "../../components/Blog";
import NewsletterSignUp from "../../components/NewsletterSignUp";
import QuotePurposerDefinition from "../../components/QuotePurposerDefinition";
import MeetTheTeam from "../../components/MeetTheTeam";
import clsx from "clsx";
import footerJsonData from "../../json/footer.json";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useDocumentDescription from "../../hooks/useDocumentDescription";
import useMobileMediaQuery from "../../hooks/useMobileMediaQuery";


const SellButton = styled(Button)({
  textTransform: "none",
  marginTop: 12,
  borderRadius: 10,
  color: "#FFFFFF",
  paddingLeft: 45,
  paddingRight: 45,
  paddingTop: 4,
  paddingBottom: 4,
  background: "var(--coral)",
  "&:hover": {
    background: "var(--coral)",
  },
  "&:visited": {
    color: "#FFFFFF",
  },
});

const useStyles = makeStyles({
  headerDiv: {
    backgroundColor: "var(--coral)",
    color: "white",
    overflow: "hidden",
    paddingBottom: 15,
  },
  headerButton: {
    color: "var(--coral)",
    backgroundColor: "white",
    padding: "10px 10px",
    width: 150,
    fontSize: "1.1rem",
    margin: "20px 0 30px 0",
  },
  headerBulbImg: {
    maxWidth: "100%",
    height: "150%",
  },
  bulbImgDiv: {
    height: 300,
    marginTop: -150,
  },
  dividerHeight: {
    height: "var(--dividerHeight)",
  },
  pressBlogImg: {
    width: "90%",
    height: "auto",
    objectFit: "cover",
    borderRadius: 10,
  },
  pressText: {
    lineHeight: 1.8,
    padding: "16px",
    fontSize: "1.2rem",
  },
  pressBlogButton: {
    padding: "10px 10px",
    width: 150,
    fontSize: "1.3rem",
    border: "1px solid black",
    borderRadius: 10,
  },
  footerSocialMediaGridItem: {
    paddingRight: 15,
  },
  footerSocialMediaButtonGridItem: {
    padding: "0px 16px",
    display: "flex",
    alignItems: "center",
  },
  footerSocialMediaButtonIcon: {
    width: "64px",
    height: "64px"
  },
});

const CONSTANTS = {
  aboutUsFacts: [
    {
      header:
        "our polka dot office in San Diego",
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/About+Us/group_pic_about_us.jpg",
      body: "Purposer is based out of the beach town La Jolla, CA. We are proud to call this vibrant community home. Inspired by the natural beauty and sustainable lifestyle of our surroundings, we are constantly inspired to promote sustainable living and ethical consumerism, offering a curated selection of products that align with those values.",
    },
    {
      header:
        "we love people with Purpose",
      body: "Every brand featured on Purposer has been carefully chosen for it’s dedication to eco-friendly, ethical and fair trade practices. We are inspired by and dedicated to supporting the amazing brands that sell on our marketplace. We meet all of the founders of our brands in person or virtually. It’s important for us to take the time to do this in order to assure that our shoppers are being shown high-quality products.",
      img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/About+Us/team_photo_about_us.jpg",
    },
    {
      header:
        "our partners change lives",
      body: "Part of our mission is to make conscious consumerism easy. By supporting our community of ethical brands, we inspire consumers to make better choices that align with their values. Join us in our mission to make a positive impact, one purchase at a time.",
      img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/About+Us/group_with_glasses_about_us.jpg",
    },
  ],
  values: [
    {
      title: "Vision",
      body: "Our vision is to provide conscious consumers with a rewarding online shopping experience where they know that every purchase aligns with their values and makes a positive impact on the world, no matter how small.",
      img: "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/About+Us/vision_logo.png",
    },
    {
      title: "Mission",
      body: "Our mission is to help entrepreneurs with a purpose build a flourishing e-commerce business and connect them with loyal shoppers that want to support brands that place purpose over profit.",
      img: "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/About+Us/mission_logo.png",

    },
    {
      title: "Values",
      body: "Our company values are steeped in the Franciscan traditions of Christianity, most especially devotion to the poor, dignity of the human person and care for creation.",
      img: "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/About+Us/values_logo.png",
    },
  ],
};

const SocialMediaButton = (props) => {
  const { link, icon, classes } = props;
  return (
    <Grid item className={classes.footerSocialMediaButtonGridItem}>
      <a href={link}>
        <img
          src={icon}
          alt=""
          className={classes.footerSocialMediaButtonIcon}
        />
      </a>
    </Grid>
  );
};

const SocialMediaLinks = (props) => {
  const { classes } = props;
  return (
    <Container maxWidth="xl">
      <div style={{ height: "var(--dividerHeight)" }} />
      <Typography className={clsx("roboto-bold")} variant="h2" align="center">
        Keep In Touch
      </Typography>
      <Typography className={clsx("roboto-light")} variant="h4" align="center">
        Connect with Purposer
      </Typography>
      <div style={{ height: "16px" }} />
      <Grid container direction="row" justifyContent="center" rowSpacing={2}>
        {footerJsonData.socialMediaButtons &&
          footerJsonData.socialMediaButtons.length > 0 &&
          footerJsonData.socialMediaButtons.map((button, index) => {
            return (
              <SocialMediaButton
                key={index}
                link={button.link}
                icon={button.icon}
                classes={classes}
              />
            );
          })}
      </Grid>
    </Container>
  )
}

const AboutUsFactsViewer = ({ index, header, body, img_url, includes, mobile }) => (
  <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    align="center"
    columnSpacing={3}
    rowSpacing={3}
    sx={{ mb: 4 }}
  >
    {index % 2 == 1 && !mobile ? (
      <>
        <Grid item md={6} sm={12}>
          <Box
            sx={{
              backgroundColor: "var(--lightBeige)",
              borderRadius: 2.5,
              display: "flex",
              width: "80%",
              marginRight: "auto",
            }}
          >
            <img
              src={img_url}
              alt={header}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "top",
                borderRadius: 10,
              }}
            />
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          {/* <Typography align="left" variant="h3" sx={{ mb: 3 }} style={{ fontSize: "24px" }}> */}
          <Typography align="right" className={"roboto-light"} sx={{ mb: 3 }} style={{ fontSize: "31px", color: "var(--mintGreen" }}>
            {header?.toUpperCase()}
          </Typography>
          <Typography align="left" variant="h6" sx={{ fontWeight: "300" }}>{body}</Typography>
          {includes && (
            <>
              {/* <Typography align="left" variant="h6">
                <b>Includes</b>
              </Typography> */}
              {includes.map((item, i) => {
                return (
                  <Typography align="left" key={i} variant="h6" sx={{ fontWeight: "300" }}>
                    • {item}
                  </Typography>
                );
              })}
            </>
          )}
        </Grid>
      </>
    ) :
      (
        <>
          <Grid item md={6} sm={12}>
            <Typography align="left" className={"roboto-light"} sx={{ mb: 3 }} style={{ fontSize: "31px", color: "var(--mintGreen" }}>
              {header?.toUpperCase()}
            </Typography>
            <Typography align="left" variant="h6" sx={{ fontWeight: "300" }}>{body}</Typography>
            {includes && (
              <>
                {/* <Typography align="left" variant="h6">
                  <b>Includes</b>
                </Typography> */}
                {includes.map((item, i) => {
                  return (
                    <Typography align="left" key={i} variant="h6" sx={{ fontWeight: "300" }}>
                      • {item}
                    </Typography>
                  );
                })}
              </>
            )}
          </Grid>
          <Grid item md={6} sm={12}>
            <Box
              sx={{
                backgroundColor: "var(--lightBeige)",
                borderRadius: 2.5,
                display: "flex",
                width: "80%",
                marginLeft: !mobile && "auto",
              }}
            >
              <img
                src={img_url}
                alt={header}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "top",
                  borderRadius: 10,
                }}
              />
            </Box>
          </Grid>
        </>
      )
    }
  </Grid>
);

const AboutUsLogoText = ({ title, body, img, mobile }) => {
  return (
    <Grid container direction="row" sx={{pb:"15px"}}>
      <Grid item xs={3} align="center">
        <Grid container
          direction="column"
        >
          <Grid item xs={3} align="center"
          justifyContent="center"
          alignItems="center"

          >
            <Typography className={"roboto-regular"} sx={{ fontSize: "23px", color: "white", pt:"5px" }}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={9} align="center"
          justifyContent="center"
          alignItems="center"

          >
            <img
              src={img}
              alt="Logo"
              style={{ height: mobile ? "75px" : "100px" }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={9} align="left">
        <Typography className={"roboto-regular"} sx={{ fontSize: "18px", color: "white", pt: mobile ? "5px" : "55px" }}>
          {body}
        </Typography>
      </Grid>
    </Grid>
  );
}

const AboutUsLayout = () => {
  const classes = useStyles();
  const mobile = useMobileMediaQuery();

  useDocumentTitle("The Purposer team is passionate about putting purpose over profit.");
  useDocumentDescription("Read about how the team at Purposer makes a difference in the lives of people in communities across the world. Learn about the founder, Joline, and why she started Purposer.com");

  return (
    <PurposeCategoryMasterLayout>
      <Divider />

      <Box sx={{ backgroundColor: "#E5D1C3", pt: "50px" }}>
        <Typography
          align="center"
          sx={{ mb: 2 }}
          style={{ fontSize: "29px" }}
          variant="h1"
          className="roboto-medium"
        >
          Why we work hard.
        </Typography>
        <Container maxWidth="md">
          <Typography align="center" variant="h2" style={{ fontSize: "30px" }}

            className="roboto-light"
          >
            The Purposer team is giving a voice to brands with purposeful missions so that you can shop guilt free.
          </Typography>
        </Container>
        <br />
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Grid container sx={{ px: "10vh" }}>
            <Grid item xs={12} sm={6} md={4} align="center">
              <SellButton
                target="_blank"
                rel="noopener noreferrer"
                href="/blog"
              >
                <Typography className={"roboto-regular"} sx={{ fontSize: "23px" }}>
                  Blog
                </Typography>
              </SellButton>
            </Grid>
            <Grid item xs={12} sm={6} md={4} align="center">
              <SellButton
                target="_blank"
                rel="noopener noreferrer"
                href="/impact"
              >
                <Typography className={"roboto-regular"} sx={{ fontSize: "23px" }}>
                  Our Impact
                </Typography>
              </SellButton>
            </Grid>
            <Grid item xs={12} sm={6} md={4} align="center">
              <SellButton
                target="_blank"
                rel="noopener noreferrer"
                href="/press"
              >
                <Typography className={"roboto-regular"} sx={{ fontSize: "23px" }}>
                  Press
                </Typography>
              </SellButton>
            </Grid>
          </Grid>
        </div>
        <br />
        <br />
      </Box>
      <br />
      <br />
      <Grid container direction="row" alignItems={"center"}>
        <Grid item xs={12} align="center">
          <img style={{ width: "50vh", borderRadius: 10 }} src={"https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/About+Us/joline_about_us.jpg"} />
        </Grid>
        <Grid item xs={12}>
          <Container maxWidth="lg">
            <Typography align="left" sx={{ mb: 1, mt: "20px" }}>
              {`A few years after graduating college and working at several jobs, I knew that I wanted to start my own business. It couldn’t just be any venture though. If I was going to devote all my time, energy and passion into something, the business would have to support the dignity of people in our communities worldwide and preserve our awesome planet. Combing my love for online shopping and this internal calling, my original idea was to create a brand of jewelry to help poverty stricken families in Ecuador and India, where my parents are from. Upon market research in preparation for my brand, I remarkably discovered so many other companies that had similar, honorable missions. However, I had never heard about them before! In fact, they were almost non existent on mainstream shopping platforms. At this moment, I knew that I had to pivot my business idea from creating one singular brand to instead creating an online marketplace where we could support all these amazing brands with purpose and give online shoppers everywhere an opportunity to discover products that change the world!`}
            </Typography>
            <Typography align="left" variant="h6"
              style={{ fontFamily: 'Allura, cursive' }}

              sx={{ fontWeight: "300", fontSize: "25px", paddingLeft: mobile ? "15vh" : "85vh", paddingTop: "30px" }}>
              {"With gratitude,"}
            </Typography>
            <Typography
              align="left" variant="h6"
              style={{ fontFamily: 'Allura, cursive' }}
              sx={{ fontWeight: "300", fontSize: "25px", paddingLeft: mobile ? "15vh" : "85vh" }}
            >
              {"Joline Mann, Founder"}
            </Typography>
          </Container>
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
      <br />
      <Container maxWidth="lg">
        <Grid
          container
          columnSpacing={mobile ? 0 : 12}
          rowSpacing={mobile ? 0 : 4}
          justifyContent="center"
          alignContent="center"
          direction="row"
          sx={{ pb: 7, backgroundColor: "#f5f5f5" }}
        >
          <Grid item sx xs={12} md={6}>
            <Grid container
            >
              <Grid item xs={3}>
                <Box sx={{ml: mobile && "10px", mt: mobile && "10px" }}>

                <img src={"https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/About+Us/book_search_logo.png"}
                  style={{ width: mobile ? "75px" : "100px" }}
                  />
                  </Box>
              </Grid>
              <Grid item xs={9}>
                <Typography align="left" variant="h5" sx={{ mb: 1 }}>
                  <b>
                    {'What does "purposer" mean?'}
                  </b>
                </Typography>
                <Typography align="left">
                  {'brands that have a “purpose” or mission to make a positive impact on communities and our planet are purposers.'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx xs={12} md={6}>
            <Grid container>
              <Grid item xs={3}>
                <Box sx={{ml: mobile && "10px", mt: mobile && "10px" }}>
                <img src={"https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/About+Us/quotes_logo.png"}
                  style={{ width: mobile ? "75px" : "100px" }}
                />
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Typography align="left" variant="h5" sx={{ mb: 1 }}>
                  <b>
                    {'Our favorite quote.'}
                  </b>
                </Typography>
                <Typography align="left">
                  {'"All the darkness in the world cannot extinguish the light from a single candle."'}
                </Typography>
                <Typography align="right" sx={{ pr: "30px" }}>
                  {'-St. Francis Of Assisi'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Container maxWidth="xl" sx={{ marginBottom: "64px" }}>
        {CONSTANTS.aboutUsFacts.map((val, index) => {
          return (
            <AboutUsFactsViewer
              index={index}
              header={val.header}
              body={val.body}
              img_url={val.img_url}
              includes={val.includes}
              mobile={mobile}
            />
          );
        })}
      </Container>
      {/* <Container maxWidth="xl">
        <Grid container direction="column" alignItems="center" spacing={8}>
          <Grid container item alignItems="center">
            <Grid item sm={12} md={7} align="center">
              <img
                src={
                  "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/About+Us/IMG_2571.JPG"
                }
                alt="Purposer Team Picture"
                className={classes.pressBlogImg}
              />
            </Grid>
            <Grid item sm={12} md={5}>
              <Typography
                className={clsx("roboto-light", classes.pressText)}
                variant="h1"
              >
                The idea for Purposer was sparked in 2020, when our founder, Joline Mann, spent increasingly more time shopping online for her favorite products and gifts.

              </Typography>
              <Typography
                className={clsx("roboto-light", classes.pressText)}
                variant="h2"
              >
                During this time, she discovered many brands that aligned with her values but she had never heard of the brands before.
              </Typography>
              <Typography
                className={clsx("roboto-light", classes.pressText)}
              >
                These brands didn’t have a voice in mainstream marketplaces and more profoundly, the story of their charitable, life changing works were not being told!
                Joline has always admired businesses that prioritize purpose over profit, but realized that many of these purposeful businesses have been undiscovered.
                Her dream is to bring purposeful brands to the global stage with an e-commerce marketplace, combining her love for shopping and her desire to make a positive impact on others, and the planet.
              </Typography>
            </Grid>
          </Grid>
          <Grid container item alignItems="center">
            <Grid item sm={12} md={7} align="center">
              <img
                src={
                  "https://d2eudfgh8kpk8j.cloudfront.net/common/thumbs/1d6a7294-786c-4866-b63f-94c5b71ae1f4_thumb.jpg"
                }
                alt="group2"
                className={classes.pressBlogImg}
              />
            </Grid>
            <Grid item sm={12} md={5}>
              <Typography
                className={clsx("roboto-light", classes.pressText)}
                variant="h6"
              >
                Purposer is a marketplace platform that gives a voice to purpose-driven brands.
                Our vision is to provide conscious consumers with a rewarding online shopping experience where they know that every purchase aligns with their values and makes a positive impact on the world, no matter how small.
                Purpose-driven brands have a business model that goes beyond profit and gives back to the world through good deeds, charitable contributions, a buy one-give-one model and more!
                Our mission is to help entrepreneurs with a purpose build a flourishing e-commerce business and connect them with loyal shoppers that want to support brands that place purpose over profit.
                Our company values are steeped in the Franciscan traditions of Christianity, most especially devotion to the poor, dignity of the human person and care for creation. Join our team of Purposers in building a better community for our brothers and sisters in need, protecting our planet for future generations and changing the world, one purchase at a time.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.dividerHeight} />
        <Container maxWidth="xl" align="center">
          <Grid container justifyContent="center" spacing={6}>
            <Grid item>
              <ButtonBase className={classes.pressBlogButton} href="/press">
                <b>PRESS</b>
              </ButtonBase>
            </Grid>
            <Grid item>
              <ButtonBase className={classes.pressBlogButton} href="/blog">
                <b>BLOG</b>
              </ButtonBase>
            </Grid>
          </Grid>
        </Container>
      </Container> */}
      {/* <div className={classes.dividerHeight} />
      <QuotePurposerDefinition /> */}
      {/* <div className={classes.dividerHeight} />
      <NewsletterSignUp /> */}
      <div className={classes.dividerHeight} />
      <MeetTheTeam />
      <div className={classes.dividerHeight} />
      <Box sx={{ backgroundColor: "var(--mintGreenSolid)", py: "30px" }}>
        {CONSTANTS.values.map((val, index) => {
          return (
            <AboutUsLogoText
              title={val.title}
              body={val.body}
              img={val.img}
              mobile={mobile}
            />
          );
        })}
      </Box>
      <div className={classes.dividerHeight} />
      <SocialMediaLinks classes={classes} />
      <div className={classes.dividerHeight} />
      <Blog />
      <div className={classes.dividerHeight} />

    </PurposeCategoryMasterLayout>
  );
};

export default AboutUsLayout;
