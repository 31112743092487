import React from "react";
import Button from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { styled } from "@mui/material/styles";

const Root = styled("div")({
  display: "flex",
});

const StyledButton = styled(Button)({
  fontSize: 14,
  fontFamily: "Roboto",
  color: "#000000",
  textTransform: "none",
  fontWeight: 400,
  "&:hover": {
    backgroundColor: "#FFFFFF",
  },
});

const StyledMenuItem = styled(MenuItem)({
  fontSize: 14,
  fontFamily: "Roboto",
  color: "#000000",
  textTransform: "none",
  fontWeight: 400,
});

export default function DropDownButton(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const openMenu = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Root onMouseEnter={openMenu} onMouseLeave={closeMenu}>
      <StyledButton
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        href={
          props.categoryData.hasOwnProperty("alternateCategoryLink")
            ? props.categoryData.alternateCategoryLink
            : "/shop/category/" + props.categoryData.id + "/" + props.categoryData.name.replace(/\s/g, '-')
        }
        disableRipple
      >
        {props.categoryData.name}
      </StyledButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                {populateSubCategories(
                  props.categoryData,
                  props.categoryData.subcategories
                )}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Root>
  );
}

function populateSubCategories(category, subcategories) {
  const elements = subcategories;
  const items = [];

  if (elements && elements.length > 0) {
    for (const [index, value] of elements.entries()) {
      items.push(
        <a
          href={
            value.hasOwnProperty("alternateSubcategoryLink")
              ? value.alternateSubcategoryLink
              : "/shop/category/" + category.id + "/" + category.name.replace(/\s/g, '-') + "/" + value.id + "/" + value.name.replace(/\s/g, '-')
          }
          key={index}
        >
          <StyledMenuItem>{value.name}</StyledMenuItem>
        </a>
      );
    }
  }

  return <div>{items}</div>;
}
