import React, { useEffect, useRef, useState } from "react";
import ManagementMasterLayout from "../MasterLayouts/ManagementMasterLayout";
import sellerDashboardJsonData from "../../json/seller_dashboard.json";
import {
  Box,
  ButtonBase,
  ButtonGroup,
  Button,
  TextField,
  Container,
  Divider,
  FormControl,
  MenuItem,
  menuItemClasses,
  outlinedInputClasses,
  Select,
  selectClasses,
  Stack,
  styled,
  svgIconClasses,
  Typography,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ColorCircle from "../../components/ColorCircle";
import { getListingsBySeller } from "../../util/listingFunctions";
import { changeInventory } from "../../util/products";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import clsx from "clsx";
import { outputViewProductVariationSize } from "../../util/utilities";


const styles = {
  skuTypography: {
    color: "black",
    fontSize: 10
  },
  rowHeaderText: {
    fontSize: "1.5rem"
  }
};

const StyledMenuItem = styled(MenuItem)({
  backgroundColor: "#FFFFFF",
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
});

const QuantityButtonContainer = styled(Stack)({
  height: 36,
});

const CustomPaper = styled(Paper)({
  padding: 0,
});

const GreyLabel = styled(Button)({
  border: "none",
  color: "#000000",
  borderRadius: 12,
  backgroundColor: "var(--lightGrayTransparent)",
  marginRight: 8,
  height: "100%",
  textTransform: "none",
  "&:hover": {
    border: "none",
    backgroundColor: "var(--lightGrayTransparent)",
  },
});

const QuantityCounterButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "position",
})(({ position }) => ({
  border: "none",
  color: "#000000",
  paddingLeft: 0,
  paddingRight: 0,
  borderTopLeftRadius: position === "left" ? 12 : 0,
  borderBottomLeftRadius: position === "left" ? 12 : 0,
  borderTopRightRadius: position === "right" ? 12 : 0,
  borderBottomRightRadius: position === "right" ? 12 : 0,
  backgroundColor: "var(--lightGrayTransparent)",
  "&:hover": {
    border: "none",
    backgroundColor: "var(--lightGrayTransparent)",
  },
}));

const QuantityButtonGroup = styled(ButtonGroup)({
  height: "100%",
});

const QuantityCounter = (props) => {
  const { productID, option, updateSkuInventory } = props;

  return (
    <QuantityButtonGroup variant="outlined">
      <QuantityCounterButton
        position={"left"}
        onClick={() => {
          if (option.inventory > 1) {
            updateSkuInventory(productID, option.skuID, option.inventory - 1);
          }
        }}
      >
        <Remove fontSize="small" />
      </QuantityCounterButton>
      <QuantityCounterButton
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        <TextField
          type="number"
          defaultValue={option.inventory}
          style = {{width: 64}}
          sx={{input: {textAlign: "center", fontWeight: "bold"}}}
          variant="standard"
          value={option.inventory}
          InputProps={{
            disableUnderline: true
        }}
          onChange={(e) => {
            const onlyNums = parseInt(e.target.value.replace(/[^0-9]/g, ''));
            if (Number.isInteger(onlyNums)) {
              updateSkuInventory(productID, option.skuID, onlyNums)
            }

          }}
        />
        {/* <Typography>{option.inventory}</Typography> */}
      </QuantityCounterButton>
      <QuantityCounterButton
        position={"right"}
        onClick={() => {
          updateSkuInventory(productID, option.skuID, option.inventory + 1);
        }}
      >
        <Add fontSize="small" />
      </QuantityCounterButton>
    </QuantityButtonGroup>
  );
};

const StyledFormControl = styled(FormControl)({
  [`& .${outlinedInputClasses.root}`]: {
    height: 40,
    borderRadius: 7,
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "#000000",
    },
    [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderWidth: 1,
      },
    [`& .${selectClasses.select}`]: {
      paddingRight: 0,
      paddingLeft: 27,
    },
  },
  [`& .${svgIconClasses.root}`]: {
    paddingRight: 0,
  },
});

const DropdownButton = (props) => {
  // Structure of options is [{label: "", value: ""}, {label: "", value: ""}]
  const { label, options, selected, handleClick } = props;

  return (
    <StyledFormControl>
      <Select
        value={selected}
        onChange={handleClick}
        renderValue={() => (
          <Typography align="center" variant="h6" className={"roboto-thin"}>
            {label}
          </Typography>
        )}
        displayEmpty
        IconComponent={ExpandMore}
        sx={{
          ".MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input":
            {
              pl: 2,
              pr: 4.5,
            },
        }}
      >
        {options &&
          options.map((option, index) => {
            return (
              <StyledMenuItem key={index} value={option.value}>
                <Typography className={"roboto-regular"}>
                  {option.label}
                </Typography>
              </StyledMenuItem>
            );
          })}
      </Select>
    </StyledFormControl>
  );
};

const CustomTableRow = (props) => {
  const { productID, option, updateSkuInventory } = props;
  return (
    <>
      <Container>
        <Box sx={{ mx: 0.5, my: 2.4 }}>
          <Stack direction={"row"} justifyContent="space-between">
            <Box
              sx={{
                py: 2,
                px: 1,
                border: "gray solid 1px",
                borderRadius: 2,
              }}
            >
              <Typography
                sx={styles.skuTypography}
              >
                <b>{option.skuID}</b>
              </Typography>
            </Box>
            <Box>
              <ColorCircle color={option.color} selected={false} />
            </Box>
            <Box
              sx={{
                py: 1.5,
                px: 1.7,
                border: "gray solid 1px",
                borderRadius: 2,
              }}
            >
              <Typography>
                <b>{outputViewProductVariationSize(option.size)}</b>
              </Typography>
            </Box>
            <Box
              sx={{
                py: 1.5,
                px: 4,
                border: "gray solid 1px",
                borderRadius: 2,
              }}
            >
              <QuantityButtonContainer direction="row">
                <GreyLabel>
                  <Typography className={"roboto-regular"}>QTY.</Typography>
                </GreyLabel>
                <QuantityCounter
                  productID={productID}
                  option={option}
                  updateSkuInventory={updateSkuInventory}
                />
              </QuantityButtonContainer>
              {option.inventory - option.startingInventory > 0 && 
                <Typography sx={{color:"Green"}} className={"roboto-regular"}>{`Addition of ${option.inventory - option.startingInventory}`}</Typography>
              }
              {option.inventory - option.startingInventory < 0 && 
                <Typography sx={{color:"Red"}} className={"roboto-regular"}>{`Removal of ${option.startingInventory - option.inventory}`}</Typography>
              }
            </Box>
          </Stack>
        </Box>
      </Container>
      <Divider />
    </>
  );
};

const ShopInventoryLayout = () => {
  const { id: sellerIDparam } = useParams();
  const sellerDashboardData = sellerDashboardJsonData;
  const [noListings, setNoListings] = React.useState(false);
  const [quantityCount, setQuantityCount] = useState(1);
  const [productList, setProductList] = useState([
    // {
    //   productID: "",
    //   productName: "",
    //   productPrice: 20,
    //   moreColors: false,
    //   colorOptions: null,
    //   freeShipping: false,
    //   customizable: false,
    //   otherAttributes: false,
    //   hardcodeExists: false,
    //   productImageID_legacy: null,
    //   active: true,
    //   deleted: false,
    //   oneMore: false,
    //   productImageURL: [],
    //   colors: [],
    //   typeOfProduct: "",
    //   tags: [],
    //   inventory: 35,
    //   anotherEverywherePrice: 0,
    //   anotherUSprice: 0,
    //   itselfEverywherePrice: 0,
    //   itselfUSprice: 0,
    //   details: "",
    //   sizes: [],
    //   options: []
    // }
  ]);

  function updateSkuInventory(productID, skuID, amt) {

    const newProductList = [...productList];
    const currOption = newProductList.find(p => p.productID == productID)?.options?.find(o => o.skuID == skuID);
    currOption.inventory = amt;
    setProductList(newProductList);

  }


  useEffect(() => {
    getListingsBySeller(sellerIDparam, true, (data) => {
      if (!data.length > 0) setNoListings(true);
      data.forEach(function (product) {
        product.options.forEach(function (obj) {
          if (obj.inventory != null) {
            obj.startingInventory = obj.inventory;
          }
        });
      });
      setProductList(data);
    });
  }, [sellerIDparam]);


  return (
    <ManagementMasterLayout
      title={"Shop Inventory"}
      sellerName={sellerDashboardData.name}
      sellerPicture={sellerDashboardData.pic}
    >
      {noListings ? (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h5" align="center">
              <b>No Listings</b>
            </Typography>
          </Box>
        ) : (
          productList.map((p) => {
            return (
              <Box sx={{ bgcolor: "#F3F3F3", mx: 0.5, my: 3, py: 2, borderRadius: 6, marginLeft: 4 }}>
                <Container>
                  <Box sx={{ mx: 0.5, my: 3, py: 2 }}>
                    <Stack direction={"column"} spacing = {0} justifyContent="space-between">
                    <Typography variant="h7">
                      {'Product Name'}
                    </Typography>
                    <Typography variant="h6">
                      <b>{p.productName}</b>
                    </Typography>
                    <div style={{height: "16px"}}/>
                    <Typography variant="h7">
                      {'Product Description' }
                    </Typography>
                    <Typography variant="h6">
                      <b>{p.details}</b>
                    </Typography>
                      {/* <DropdownButton
                        label={"Filter"}
                        options={[
                          { label: "Option1", value: "Option1" },
                          { label: "Option2", value: "Option2" },
                          { label: "Option3", value: "Option3" },
                          { label: "Option4", value: "Option4" },
                        ]}
                      /> */}
                    </Stack>
                    
                    <div style={{height: "32px"}}/>
                  
                    <Typography variant="h6">
                      {`${p.options?.length} Item${p.options?.length > 1 ? 's' : ''}`}
                    </Typography>

                    <div style={{height: "8px"}}/>
                  <TableContainer >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                      <TableHead>
                        <TableRow style={{borderBottomWidth: "32px"}}>
                          <TableCell ><Typography className={clsx("roboto-bold")}>SKU</Typography></TableCell>
                          <TableCell align="center"><Typography className={clsx("roboto-bold")} >COLOR</Typography></TableCell>
                          <TableCell align="center"><Typography className={clsx("roboto-bold")} >SIZE</Typography></TableCell>
                          <TableCell align="center"><Typography className={clsx("roboto-bold")} >INVENTORY</Typography></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        
                        
                        {p.options.map((option, index) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              <Typography className={clsx("roboto")} >
                              {option.skuID}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Box>
                                <ColorCircle color={option.color} selected={false} />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              <Typography className={clsx("roboto")} >
                                {outputViewProductVariationSize(option.size)}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                            
                            <QuantityButtonContainer direction="row" justifyContent="center">
                              <GreyLabel>
                                <Typography className={"roboto-regular"}>QTY.</Typography>
                              </GreyLabel>
                              <QuantityCounter
                                productID={p.productID}
                                option={option}
                                updateSkuInventory={updateSkuInventory}
                              />
                            </QuantityButtonContainer>
                            {option.inventory - option.startingInventory > 0 && 
                              <Typography sx={{color:"Green"}} className={"roboto-regular"}>{`Addition of ${option.inventory - option.startingInventory}`}</Typography>
                            }
                            {option.inventory - option.startingInventory < 0 && 
                              <Typography sx={{color:"Red"}} className={"roboto-regular"}>{`Removal of ${option.startingInventory - option.inventory}`}</Typography>
                            }

                            </TableCell>
                          </TableRow>
                        ))}
                        
                      </TableBody>
                    </Table>
                  </TableContainer>
                  </Box>
                </Container>
                
                {/* <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                  <ButtonBase sx={{ borderRadius: 20, p: 2 }}>
                    <KeyboardArrowDownIcon fontSize="large" />
                  </ButtonBase>
                </Box> */}
                <Box sx={{ display: "flex", justifyContent: "center", my: 1 }}>
                  <ButtonBase
                    sx={{ py: 1, px: 2, border: "gray solid 1px", borderRadius: 3 }}
                    onClick={() => {
                      const changedOptions = p.options.filter(o => o.inventory - o.startingInventory != 0);
                      if (changedOptions.length > 0) {
                        changeInventory(sellerIDparam, p.productID, changedOptions, () => {
                          window.location.reload(false);
                        });
                      }
                    }}
                  >
                    <Typography variant="h6">
                      <b>Update Inventory</b>
                    </Typography>
                  </ButtonBase>
                </Box>
                
              </Box>
              
            )
          }
        )
      )
      }
      {/* <Box sx={{ display: "flex", justifyContent: "center", my: 10 }}>
        <ButtonBase
          sx={{ py: 1, px: 2, border: "gray solid 1px", borderRadius: 3 }}
        >
          <Typography variant="h6">
            <b>Update Information</b>
          </Typography>
        </ButtonBase>
      </Box> */}
    </ManagementMasterLayout>
  );
};

const productTemps = [
  {
    productName: "Product 1",
    description: "Description 1",
    productSKU: "SKU 1",
    color: "#000000",
    status: "In Stock",
    quantity: "10",
  },
  {
    productName: "Product 2",
    description: "Description 2",
    productSKU: "SKU 2",
    color: "#000000",
    status: "In Stock",
    quantity: "10",
  },
  {
    productName: "Product 3",
    description: "Description 3",
    productSKU: "SKU 3",
    color: "#000000",
    status: "In Stock",
    quantity: "10",
  },
  {
    productName: "Product 4",
    description: "Description 4",
    productSKU: "SKU 4",
    color: "#000000",
    status: "In Stock",
    quantity: "10",
  },
];

export default ShopInventoryLayout;
