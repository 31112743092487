import {
  Grid,
  Typography,
  Button,
  Container,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ProductView from "./ProductView";
import AddIcon from "@mui/icons-material/Add";
import clsx from "clsx";
import React from "react";
import mockRelatedPurchasesData from "../json/related_purchases.json";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { getListOfProductsById } from "../util/getProductsById";

const useStyles = makeStyles({
  relatedDiv: {
    backgroundColor: "inherit",
    paddingTop: 20,
    paddingBottom: 20,
  },
  relatedHeader: {
    paddingBottom: 20,
  },
  relatedPlusSign: {
    marginTop: 92.5,
  },
  relatedAddButton: {
    borderRadius: 50,
    textTransform: "none",
    minWidth: 200,
  },
  relatedAddCartButton: {
    backgroundColor: "var(--mintGreen)",
    "&:hover": {
      backgroundColor: "var(--mintGreen)",
    },
  },
  relatedAddWishListButton: {
    backgroundColor: "#000000",
    "&:hover": {
      backgroundColor: "#000000",
    },
  },
  relatedAddButtonText: {
    color: "#FFFFFFFF",
  },
});

const AddButton = styled(Button)({
  textTransform: "none",
  color: "#000000",
  borderColor: "#000000",
  borderRadius: 10,
  minWidth: 178,
  "&:hover": {
    borderColor: "#000000",
    backgroundColor: "inherit",
  },
});

function RelatedPurchases(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  let { related } = props;
  let customButtonText = related.length === 2 ? "both " : "";

  const [relatedPurchases, setRelatedPurchases] = React.useState([]);
  React.useEffect(() => {
    if (!related || related.length <= 0) {
      getListOfProductsById(mockRelatedPurchasesData, (data) => {
        if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
          setRelatedPurchases(data);
        }
      });
    } else {
      setRelatedPurchases(related);
    }
  }, [related]);

  return (
    <div className={classes.relatedDiv}>
      <Container maxWidth="xl">
        <Typography
          variant="h5"
          className={clsx(classes.relatedHeader, "roboto-medium")}
        >
          Customers Also Purchased These. Want to do the same?
        </Typography>
        <Grid
          container
          direction="row"
          columnSpacing={!matches ? 2 : 0}
          justifyContent={!matches ? "flex-start" : "center"}
          alignItems="flex-start"
        >
          {relatedPurchases.length > 0 &&
            relatedPurchases.map((product, index) => {
              if (index > 0) {
                return (
                  <React.Fragment key={index+'relatedpurchases'}>
                    <Grid item>
                      <AddIcon
                        fontSize="large"
                        className={classes.relatedPlusSign}
                      />
                    </Grid>
                    <Grid item>
                      <ProductView info={product} hideSeller hidePurpose />
                    </Grid>
                  </React.Fragment>
                );
              } else {
                return (
                  <Grid item key={index}>
                    <ProductView info={product} hideSeller hidePurpose />
                  </Grid>
                );
              }
            })}
          {relatedPurchases.length > 0 ? (
            <Grid item xs={12} md>
              <Grid
                container
                columnSpacing={2}
                rowSpacing={5}
                justifyContent={"center"}
                direction={!matches ? "column" : "row"}
                sx={{ mt: 2 }}
              >
                <Grid item>
                  <AddButton variant="outlined">
                    <Typography variant="h6" className={"roboto-medium"}>
                      Add {customButtonText}to cart
                    </Typography>
                  </AddButton>
                </Grid>
                <Grid item>
                  <AddButton variant="outlined">
                    <Typography variant="h6" className={"roboto-medium"}>
                      Add to Wish List
                    </Typography>
                  </AddButton>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <Typography className={"roboto-regular"}>
                No related purchases
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
}

export default RelatedPurchases;
