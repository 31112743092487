import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    Button
  } from "@mui/material";
  import { useTheme } from "@mui/material/styles";
  import makeStyles from "@mui/styles/makeStyles";
  import {
    FirstPage,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage,
  } from "@mui/icons-material";
  import PropTypes from "prop-types";
  import React, { useState } from "react";
  import clsx from "clsx";
  import FinancesRecentActivityPopup from "./FinancesRecentActivityPopup";
import { formatDate, round, shortenUUID } from "../util/utilities";
  
  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    noOrdersCell: {
      border: "none",
    },
    tableRows: {
      backgroundColor: "",
    },
    rowHeaderText: {
        fontSize: "1.15rem"
    }
  }));
  
  const TablePaginationActions = (props) => {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
          size="large"
        >
          {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
          size="large"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          size="large"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
          size="large"
        >
          {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
        </IconButton>
      </div>
    );
  };
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };
  
  const RecentActivity = (props) => {
    const { trackShipData } = props;
    const classes = useStyles1();
    const [page, setPage] = useState(0);
    const [state, setState] = useState({
      filter: "",
    });
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChange = (event) => {
      const name = event.target.name;
      setState({
        ...state,
        [name]: event.target.value,
      });
    };
    const [popupView, setPopupView] = useState(-1);
  
    const emptyRows = trackShipData
      ? rowsPerPage -
        Math.min(rowsPerPage, trackShipData.length - page * rowsPerPage)
      : 4;
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    return (
      <>
        {popupView !== -1 ? <FinancesRecentActivityPopup row={popupView} setPopupView={setPopupView} /> : <></>}
        {/* <FormControl
          variant="outlined"
          size="small"
          sx={{
            minWidth: 120,
            float: "right",
            marginBottom: "10px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "7px",
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--mintGreen)",
                color: "var(--mintGreen)",
              },
            },
          }}
        >
          <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
          <Select
            value={state.filter}
            onChange={handleChange}
            label="Filter"
            inputProps={{
              name: "filter",
              id: "outlined-age-native-simple",
            }}
          >
            <MenuItem value="Date Desc">Date Desc</MenuItem>
            <MenuItem value="Date Asc">Date Asc</MenuItem>
            <MenuItem value="Order Number Desc">Order Number Desc</MenuItem>
            <MenuItem value="Order Number Asc">Order Number Asc</MenuItem>
          </Select>
        </FormControl> */}
        {/* <Typography variant="h6">
          <b>{title}</b>
        </Typography> */}
        <TableContainer component={Paper}>
          <Table className={"string"} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography className={clsx("roboto-medium", classes.rowHeaderText)}>
                    Date
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className={clsx("roboto-medium", classes.rowHeaderText)}>
                    ORDER NO.
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className={clsx("roboto-medium", classes.rowHeaderText)}>
                    Grand Total
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className={clsx("roboto-medium", classes.rowHeaderText)}>
                    Discounts
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className={clsx("roboto-medium", classes.rowHeaderText)}>
                    Shipping
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className={clsx("roboto-medium", classes.rowHeaderText)}>
                    Refunds
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className={clsx("roboto-medium", classes.rowHeaderText)}>
                    Tax
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className={clsx("roboto-medium", classes.rowHeaderText)}>
                    Fees
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className={clsx("roboto-medium", classes.rowHeaderText)}>
                    Net
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trackShipData && trackShipData.length > 0 ? (
                (rowsPerPage > 0
                  ? trackShipData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : trackShipData
                ).map((row, index) => (
                  <TableRow key={index} className={classes.tableRows}>
                    <TableCell component="th" scope="row" align="center">
                      <Typography>
                        {formatDate(row.orderDateTime)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={() => setPopupView(row)}
                      >
                        <Typography>
                          {shortenUUID(row.orderID)}
                        </Typography>
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        ${round(row.netTotalAmt, 2)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        {`${row.purposerCreatedCouponApplied ? '*' : ''}$${round(row.totalDiscountAmt, 2)}`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        ${round(row.totalShippingAmt, 2)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        ${round(row.totalRefundedAmt, 2)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        ${round(row.totalTaxAmt, 2)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        ${round(row.totalFeeAmt, 2)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        ${round(row.netTotalAfterTaxAndFeesAmt, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow className={classes.tableRows}>
                  <TableCell
                    align="center"
                    colSpan={7}
                    className={classes.noOrdersCell}
                  >
                    <Typography>
                      <b>No Recent Activity</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{ height: 53 * emptyRows }}
                  className={classes.tableRows}
                >
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow className={classes.tableRows}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={7}
                  count={trackShipData ? trackShipData.length : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </>
    );
  };
  
  export default RecentActivity;
  