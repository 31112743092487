import { Container, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
//import bulb from "../img/coral_bulb.png";
import bulb from "../img/LightBulb_Outline.png";

//Default Prop Values
import mapDataDefault from "../json/map_data.json";

const useStyles = makeStyles({
  map: {
    maxWidth: 1024,
    height: 600,
    margin: "auto",
    //backgroundColor: "#82aa9f",
  },
  divContainer: {
    backgroundColor: "#82aa9f",
    paddingBottom: 20,
    overflow: "hidden",
  },
});

const bulbIcon = new L.Icon({
  iconUrl: bulb,
  iconSize: [20, 30],
});

const Markers = ({ data }) => (
  <Marker icon={bulbIcon} position={[data.lat, data.lng]}>
    <Popup>
      {/* <Typography align="center">{data.country.toUpperCase()}</Typography>
      <Divider />
      {data.sellers.map((item, i) => {
        return (
          <>
            <a href={item.seller_url}>
              <i>{item.seller}</i>
            </a>
            <br />
          </>
        );
      })} */}
      <Typography>
        We are supporting artisans in Ecuador and helping them maintain their
        own business, to elevate themselves and future generations from poverty.
      </Typography>
      <a href="/">
        <u>
          <i>Read More</i>
        </u>
      </a>
    </Popup>
  </Marker>
);

const OurGlobalImpactMap = ({ mapData = mapDataDefault }) => {
  const classes = useStyles();
  return (
    <div className={classes.divContainer}>
      <Container maxWidth="xl">
        <MapContainer
          tap={false}
          center={[30, 0]}
          zoom={0}
          scrollWheelZoom={true}
          maxBounds={[
            [-90, -180],
            [90, 180],
          ]}
          className={classes.map}
          minZoom={2}
        >
          <TileLayer
            attribution='&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.thunderforest.com/mobile-atlas/{z}/{x}/{y}.png?apikey={apikey}"
            noWrap="true"
            apikey="b4253345883a4819ae18fbf3ca4daef3"
          />
          {mapData
            ? mapData.map((item, i) => {
                return <Markers data={item} key={i} />;
              })
            : null}
        </MapContainer>
      </Container>
    </div>
  );
};

export default OurGlobalImpactMap;
