import {
  Container,
  Divider,
  Grid,
  Typography,
  Button,
  Avatar,
  Tooltip,
  TextField,
  InputAdornment,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LegalFooter from "../../components/LegalFooter";
import StatementHeader from "../../components/StatementHeader";
import headerlogo from "../../img/headerlogo.png";
import clsx from "clsx";
import { useSelector } from "react-redux";
import signOut from "../../util/signOut";
import { EmailOutlined } from "@mui/icons-material";
import ManagementMenu from "../../components/ManagementMenu";
import ManagementMenuMobile from "../../components/ManagementMenuMobile";
import { useEffect, useState } from "react";
import { getSellerById } from "../../util/sellers";
import { getUserIdFromAuth, isPurposerUserAdmin } from "../../util/utilities";
import { useHistory, useParams } from "react-router-dom";

const useStyles = makeStyles({
  logo: {
    maxHeight: 70,
    paddingTop: 10,
    paddingBottom: 10,
  },
  verticalCenter: {
    display: "flex",
    alignItems: "center",
  },
  horizontalCenter: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    textTransform: "none",
    borderRadius: 13,
    marginRight: 15,
    minHeight: 34,
    color: "#000000",
    borderColor: "#000000",
    "&:hover": {
      borderColor: "#000000",
      backgroundColor: "#FFFFFF",
    },
  },
  buttonText: {
    textAlign: "center"
  },
  headerAvatar: {
    minWidth: 90,
    minHeight: 90,
  },
  verticallyCenter: {
    display: "flex",
    alignItems: "center",
  },
  headerGroup: {
    flexFlow: "column",
  },
  headerNameText: {
    paddingTop: 15,
  },
  header: {
    fontSize: "2.35rem",
    paddingBottom: 10,
  },
  menuDiv: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "16px"
  },
  menuText: {
    fontSize: "1.3rem",
    paddingLeft: 10,
    paddingRight: 5,
  },
  leftMenuSection: {
    backgroundColor: "#F0F6F4",
    paddingTop: 30,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 80
   
  },
  searchTextfield: {
    paddingTop: 12,
    paddingLeft: 16,
    paddingRight: 16,
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      backgroundColor: "#D0DDD6",
      borderStyle: "none",
      height: 35,
      "& fieldset": {
        borderStyle: "none",
      },
    },
  },
});

function ManagementMasterLayout(props) {
  const { children, title = "Shop Manager" } = props;
  const classes = useStyles();
  const userAttributes = useSelector((state) => state.user.user);
  const [sellerData, setSellerData] = useState({});
  const [requestFailed, setRequestFailed] = useState(false);
  // Data will need to be based on the seller id in the future
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    getSellerById(id, (data) => {
      if (data === "failed") {
        setRequestFailed(true);
        return;
      } else if (data && !data.hasOwnProperty("error")) {
        getUserIdFromAuth((userId) => {
          if(!data?.adminUserID?.toLowerCase() || !userId?.toLowerCase()) {
            history.push(`/`);
            return;
          }
          if (data?.adminUserID?.toLowerCase() == userId?.toLowerCase() || isPurposerUserAdmin(userId)) {
            //Valid user on page
          } else {
            history.push(`/`);
            return;
          }
        })
        if (data.hasOwnProperty("purposes") && !Array.isArray(data.purposes)) {
          data.purposes = [{ id: 0, value: 0, name: "" }];
        } else if (!data.hasOwnProperty("purposes")) {
          data.purposes = [{ id: 0, value: 0, name: "" }];
        }
        setSellerData(data);
        setRequestFailed(false);
      } else {
        console.log("oops");
      }
    });
  }, [id]);

  // console.log(sellerData);

  return (
    <>
      <StatementHeader userAttributes={userAttributes} signOut={signOut} />
      <Container maxWidth="xl">
        <Grid container direction="row">
          <Grid item xs={12} md={3} align="center">
            <a href="/">
              <img src={headerlogo} alt="" className={classes.logo} />
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            className={clsx(classes.verticalCenter, classes.horizontalCenter)}
          >
          <Button variant="outlined" href={`/shop/seller/${id}`} target="_blank" className={classes.button}>
            <Typography className={classes.buttonText}>
              <b>View My Store</b>
            </Typography>
          </Button>
            <Button variant="outlined" href="/" className={classes.button}>
              <Typography className={classes.buttonText}>
                <b>Back to Purposer</b>
              </Typography>
            </Button>
            {/* <Button variant="outlined" className={classes.button}>
              <Typography className={classes.buttonText}>
                <b>Preview Shop Page</b>
              </Typography>
            </Button> */}
            <Button
              variant="outlined"
              href="/support/seller"
              className={classes.button}
              target="_blank"
            >
              <Typography className={classes.buttonText}>
                <b>Get Help</b>
              </Typography>
            </Button>
            <Button 
              variant="outlined" 
              className={classes.button}
              onClick={signOut}
            >
              <Typography className={classes.buttonText}>
                <b>Log Out</b>
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Divider sx={{marginTop: {xs: "32px", md: "0px"}}}/>
      <Container maxWidth="xl">
        <Grid container direction="row">
          <Grid item xs={3} align="center">
            <Avatar
              src={sellerData.profile_pic}
              className={classes.headerAvatar}
            />
          </Grid>
          <Grid
            item
            xs={9}
            className={clsx(classes.verticallyCenter, classes.headerGroup)}
          >
            <Typography align="center" className={classes.headerNameText}>
              {sellerData.seller_name}
            </Typography>
            <Typography align="center" className={classes.header}>
              <b>{title}</b>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Container maxWidth="xl">
        <Grid container direction="row">
          <Grid item xs={3} className={classes.leftMenuSection} sx={{ display: { xs: "none", md: "block" }}}>
            <ManagementMenu />
            {/* I removed Messages from the Management Menus to add this tooltip for now, feel free to restore once it's created */}
              <div className={classes.menuDiv}>
                  <EmailOutlined fontSize="large" />
                  <Tooltip title="Coming soon!" placement="bottom" arrow>
                    <Typography className={classes.menuText}>
                      <b>Messaging</b>
                    </Typography>
                  </Tooltip>
              </div>
            {/* <TextField
              variant="outlined"
              placeholder="Search"
              className={classes.searchTextfield}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <a href="#test">
                      <Search />
                    </a>
                  </InputAdornment>
                ),
              }}
            /> */}
          </Grid>
          <Grid item sx={{display: { xs: "flex", md: "none" }, marginLeft: "-16px"}}>
            <ManagementMenuMobile/>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            {children ? children : <></>}
            <Grid container direction="row" sx={{ mb: 3 }}>
              <Grid
                item
                xs={12}
                className={clsx(
                  classes.verticalCenter,
                  classes.horizontalCenter
                )}
              >
                <Button variant="outlined" href="/" className={classes.button}>
                  <Typography>
                    <b>Back to Purposer</b>
                  </Typography>
                </Button>
                {/* <Button variant="outlined" className={classes.button}>
                  <Typography>
                    <b>Preview Shop Page</b>
                  </Typography>
                </Button> */}
                <Button
                  variant="outlined"
                  href="/support/seller"
                  className={classes.button}
                  target="_blank"
                >
                  <Typography>
                    <b>Get Help</b>
                  </Typography>
                </Button>
                <Button 
                  variant="outlined" 
                  className={classes.button}
                  onClick={signOut}
                >
                  <Typography>
                    <b>Log Out</b>
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <LegalFooter />
    </>
  );
}

export default ManagementMasterLayout;
