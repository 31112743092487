const {
  NODE_API_ENDPOINT
} = require("./constants");

async function getListOfProductsById(idList, callback) {
    if (idList && idList.length > 0) {
      let requestURL = NODE_API_ENDPOINT + "/get-products-by-id";
      for (let i = 0; i < idList.length; i++) {
        if (i === 0) {
          requestURL += "?id=" + idList[i];
        } else {
          requestURL += "&id=" + idList[i];
        }
      }
  
      fetch(requestURL)
        .then((response) => response.json())
        .then(
          (data) => callback(data),
          (error) => console.log(error)
        );
    }
}

async function getProductById(id, callback) {
  fetch(NODE_API_ENDPOINT + "/get-products-by-id?id=" + id)
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function getProductsBySellerId(id, callback) {
  fetch(NODE_API_ENDPOINT + "/get-products-by-seller-id?id=" + id)
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function getListOfProductsBySkuId(idList, callback) {
  if (idList && idList.length > 0) {
    let requestURL = NODE_API_ENDPOINT + "/get-products-by-sku-id";
    for (let i = 0; i < idList.length; i++) {
      if (i === 0) {
        requestURL += "?id=" + idList[i];
      } else {
        requestURL += "&id=" + idList[i];
      }
    }

    fetch(requestURL)
      .then((response) => response.json())
      .then(
        (data) => callback(data),
        (error) => console.log(error)
      );
  }
}

async function getRandomProducts(callback) {
  fetch(NODE_API_ENDPOINT + "/get-random-products")
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}


export { getListOfProductsById, getProductById, getProductsBySellerId, getListOfProductsBySkuId, getRandomProducts };
