import {
  ButtonBase,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/material/styles";

const MainDiv = styled("div")({
  backgroundColor: "var(--pastelGreen)",
  borderRadius: 10,
  paddingTop: 15,
  paddingBottom: 35,
})

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height: "100%",
    borderRadius: 10,
    "& fieldset": {
      borderColor: "#000000",
      borderWidth: 1,
    },
    "&:hover fieldset": {
      borderWidth: 1,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000000",
      borderWidth: 1,
    },
  },
});

const TrackButton = styled(ButtonBase)({
  backgroundColor: "var(--mintGreenSolid)",
  padding: "12px 30px",
  borderRadius: 10,
  width: 170,
})

const StyledStack = styled(Stack)({
  marginTop: 30,
  width: "87%",
});

const TrackOrderShipment = (props) => {
  const { header = "", subheader = "" } = props;
  const [orderNumber, setOrderNumber] = useState("");

  const handleFormValues = (e) => {
    setOrderNumber(e.target.value);
  };

  const placeholderStyle = {
    style: {
      color: "#000000",
      textAlign: "center",
      fontSize: 20,
      fontStyle: "italic",
      fontFamily: '"Roboto", "sans-serif"',
      fontWeight: 300,
    },
  };

  const inputStyle = {
    style: {
      color: "#000000",
      textAlign: "center",
      fontSize: 20,
      fontFamily: '"Roboto", "sans-serif"',
      fontWeight: 400,
    },
  };

  return (
    <Container maxWidth="xl">
      <MainDiv>
        <Typography align="center" variant="h5" className={"roboto-regular"}>
          {header}
        </Typography>
        <Typography align="center" variant="h4" className={"roboto-light"}>
          {subheader}
        </Typography>
        <Container maxWidth="xl" align="center">
          <StyledStack direction="row" spacing={2}>
            <StyledTextField
              name="orderNumber"
              onChange={handleFormValues}
              defaultValue={orderNumber}
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Enter Order Number...."
              inputProps={orderNumber === "" ? placeholderStyle : inputStyle}
            />
            <TrackButton>
              <Typography variant="h6" className={"roboto-medium"}>
                Track
              </Typography>
            </TrackButton>
          </StyledStack>
        </Container>
      </MainDiv>
    </Container>
  );
};

export default TrackOrderShipment;
