import { Button, ButtonBase, Grid, Typography, Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import { Edit } from "@mui/icons-material";
import clsx from "clsx";
import ColorCircle from "./ColorCircle";
import AddIcon from "@mui/icons-material/Add";
import { useParams } from "react-router-dom";
import { PRODUCT_SIZES, PRODUCT_COLORS, PRODUCT_MATERIALS } from "../util/constants";
import { findSmallestPriceItemInArray, round } from "../util/utilities";

const useStyles = makeStyles({
  productImage: {
    height: 220,
    objectFit: "cover",
    borderRadius: 20,
  },
  prodDiv: {
    margin: "auto",
  },
  relatedPurchasesDiv: {
    minHeight: "auto",
  },
  buttonDefaults: {
    width: 100,
    display: "block",
    borderRadius: 10,
    margin: "5px 0px",
    fontSize: 12,
    padding: [[2, 2, 2, 2]],
  },
  more: {
    color: "white",
    background: "gray",
  },
  ship: {
    color: "white",
    background: "var(--mintGreenSolid)",
  },
  inactive: {
    color: "red",
    fontSize: 14,
    textAlign: "left",
    display: "block",
    padding: [[0, 0, 0, 0]],
  },
  purposeText: {
    display: "block",
  },
  editButton: {
    color: "#000000",
    padding: 0,
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
});

const NameText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "supportPurpose",
})(({ supportPurpose }) => ({
  margin: "8px 0px",
  fontSize: supportPurpose ? 11.26 : 16,
  textOverflow: "ellipsis",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical"
  // maxHeight: "3.6em",
  // lineHeight: "1.8em"
  // whiteSpace: "nowrap",
}));

const PriceText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "supportPurpose",
})(({ supportPurpose }) => ({
  fontSize: supportPurpose ? 11.26 : 16,
}));

const SellerName = styled(Typography)({
  fontSize: 15,
});

const PurposeText = styled(Typography)({
  fontSize: 14,
});

const MoreColorsGridContainer = styled(Grid)({
  width: "70%",
});

const MoreColorsGriditem = styled(Grid)({
  display: "flex",
  alignItems: "center",
});

const MoreColorsAddAvatar = styled(Avatar)({
  backgroundColor: "#FFFFFF",
  borderStyle: "solid",
  borderWidth: 1.5,
  borderColor: "#000000",
  maxWidth: "100%",
});

const MoreColorsAddIcon = styled(AddIcon)({
  color: "#000000",
  fontSize: 21,
  "&:hover": {
    cursor: "pointer",
  },
});

function convertLink(link) {

  try {
    const url = new URL(link);
    const pathSegments = url.pathname.split('/');

    // Add 'thumb' after 'product'
    pathSegments.splice(3, 0, 'thumb');

    // Add '_thumb' before the file extension
    const filename = pathSegments[pathSegments.length - 1];
    const fileExtensionIndex = filename.lastIndexOf('.');
    const newFilename = filename.slice(0, fileExtensionIndex) + '_thumb' + filename.slice(fileExtensionIndex);
    pathSegments[pathSegments.length - 1] = newFilename;

    // Update the pathname and return the new URL
    url.pathname = pathSegments.join('/');
    return url.href;
  } catch (e) {
    //console.log(e)
    return link;
  }

}

const ProductView = ({
  info,
  admin = false,
  supportPurpose = false,
  hideSeller = false,
  hidePurpose = false,
  mobile = false,
  moveLeft = () => { },
  moveRight = () => { },
  index = null,
  lengthOfProductArray = null,
}) => {
  const classes = useStyles();
  // Seller id should come from the actual product data in the future
  const { id } = useParams();

  // Get the first two sub-purposes the seller chooses
  const subpurposes = info.purposes && info.purposes.filter((p) => p.higherPurposeID !== -1);
  const uniqueSubpurposes = [...new Set(subpurposes.map(obj => obj.higherPurposeID))];
  if (uniqueSubpurposes.length >= 2) { uniqueSubpurposes.splice(2, 1) };
  let filtered_subpurposes = [];
  uniqueSubpurposes.map((id) => filtered_subpurposes.push(subpurposes.find(obj => obj.higherPurposeID === id)));

  return info ? (
    <div
      key={info.productID}
      className={clsx(classes.prodDiv, [
        !info.seller && classes.relatedPurchasesDiv,
      ])}
      style={{ maxWidth: mobile ? 166 : 185 }}
    >
      <div>

        {admin && index !== null ? (
          <div
            style={{
              position: "relative",
              display: "inline-block",
            }}
          >
            {(index) > 0 &&
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)", // Add this line to center the button vertically
                  textAlign: "center",
                  zIndex: 1000,
                  left: "0px",
                }}
              >
                <Button
                  onClick={() => moveLeft(index)}
                  color="primary"
                  variant="contained"
                  style={{
                    minWidth: "auto",
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    paddingTop: "4px",
                    paddingBottom: "4px"
                  }}
                >
                  {`<`}
                </Button>
              </div>
            }
            <a href={"/shop/product/" + info.productID + "/" + info.productName?.replace(/\W+/g, "-")}>
              <img
                src={info.productImageURL ? convertLink(info.productImageURL?.[0]) : null}
                className={classes.productImage}
                alt={info.productName}
                style={{
                  width: mobile ? 166 : 185,
                  // objectFit: "contain",            
                }}
              />
            </a>
            {(index) < lengthOfProductArray - 1 &&
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)", // Add this line to center the button vertically
                  textAlign: "center",
                  zIndex: 1000,
                  right: "0px",
                }}
              >
                <Button
                  onClick={() => moveRight(index)}
                  color="primary"
                  variant="contained"
                  style={{
                    minWidth: "auto",
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    paddingTop: "4px",
                    paddingBottom: "4px"
                  }}
                >
                  {`>`}
                </Button>
              </div>
            }
          </div>)
          : (
            <a href={"/shop/product/" + info.productID + "/" + info.productName?.replace(/\W+/g, "-")}>
              <img
                src={info.productImageURL ? convertLink(info.productImageURL?.[0]) : null}
                className={classes.productImage}
                alt={info.productName}
                style={{
                  width: mobile ? 166 : 185,
                  // objectFit: "contain",            
                }}
              />
            </a>)}



        <Grid container direction="row">
          <Grid item xs={admin ? 9 : 12}>
            <NameText
              variant="h3"
              className={"roboto-regular"}
              supportPurpose={supportPurpose}
            >
              <a href={"/shop/product/" + info.productID + "/" + info.productName?.replace(/\W+/g, "-")}>{info.productName}</a>
            </NameText>
          </Grid>
          {admin && (
            <Grid item xs={3}>
              <Button
                href={"/seller/" + id + "/" + info.productID + "/edit"}
                disableRipple
                className={classes.editButton}
              >
                <Edit />
              </Button>
            </Grid>
          )}
        </Grid>
        {info?.options?.length > 0 && findSmallestPriceItemInArray(info.options)?.price != null && (
          <PriceText
            gutterBottom
            className={"roboto-bold"}
            supportPurpose={supportPurpose}
          >
            {(findSmallestPriceItemInArray(info.options))?.saleText ?
              <>
                <div style={{ color: "red" }}>
                  {`$${round((findSmallestPriceItemInArray(info.options))?.price, 2)}`} {findSmallestPriceItemInArray(info.options)?.saleText}
                </div>
                <div className="top-element-formatting" />
                <strike>
                  ${findSmallestPriceItemInArray(info.options)?.originalPrice}
                </strike>
              </>
              : <div>{`$${round((findSmallestPriceItemInArray(info.options))?.price, 2)}`}</div>
            }
          </PriceText>
        )}
        {!hideSeller && info?.seller?.sellerName && (
          <SellerName
            variant="caption"
            className={clsx(classes.purposeText, "roboto-light-italic")}
          >
            <a href={"/shop/seller/" + info?.seller.sellerID + "/" + info?.seller?.sellerName?.replace(/\W+/g, "-")}>{info?.seller?.sellerName}</a>
          </SellerName>
        )}
        {!hidePurpose && info.purposes && (
          <PurposeText
            variant="caption"
            className={clsx(classes.purposeText, "roboto-light")}
            gutterBottom
          >
            <b>Purpose:</b> {filtered_subpurposes && filtered_subpurposes.map((purpose, index) => <a key={index + 'purposetext'} href={"/shop/purpose/" + purpose.higherPurposeID + "/" + purpose.purposeName.replace(/\s/g, '-') + "/"  + purpose.productPurposeID}>{purpose.purposeName}{(index < filtered_subpurposes.length - 1) && ", "}</a>)}
          </PurposeText>
        )}
        {info.oneMore && (
          <ButtonBase className={clsx(classes.buttonDefaults, classes.more)}>
            1 MORE
          </ButtonBase>
        )}
        {/* If there is more than one color, show them under the product */}
        {info?.colors?.length > 1 && (
          <MoreColorsGridContainer
            container
            direction="row"
            columnSpacing={1}
            columns={info.colors.length + 1}
          >
            {info.colors.filter(c => c !== "N/A").slice(0, 4).map((color, index) => {
              const currColor = PRODUCT_COLORS.find(c => c.name == color)?.color;
              return (
                <Grid item key={index + 'colorCircle'}>
                  <a href={"/shop/product/" + info.productID + "/" + info.productName?.replace(/\W+/g, "-")}>
                  {/* Show first three colors */}
                  {index < 3 && currColor &&
                    <ColorCircle color={currColor} sizePercentage={'16px'} selected={false} />
                  }
                  {/* On last circle, show a '+' icon */}
                  {index > 2 &&
                    <div style={{ color: "darkblue", fontWeight: "500" }}>+{info.colors.length - index}</div>
                  }
                  </a>
                </Grid>
              )
            })}
            {/* <MoreColorsGriditem item xs={1}>
            <MoreColorsAddAvatar
              style={{
                height: "90%",
              }}
            >
              <MoreColorsAddIcon />
            </MoreColorsAddAvatar>
          </MoreColorsGriditem> */}
          </MoreColorsGridContainer>
        )}
        {/* {info.moreColors && (
          <MoreColorsGridContainer
            container
            direction="row"
            columnSpacing={1.5}
            columns={PRODUCT_COLORS.length + 1}
          >
            {PRODUCT_COLORS.map((color, index) => {
              return (
                <Grid item xs={1} key={index}>
                  <ColorCircle color={color} selected={false} />
                </Grid>
              );
            })}
            <MoreColorsGriditem item xs={1}>
              <MoreColorsAddAvatar
                style={{
                  height: "90%",
                }}
              >
                <MoreColorsAddIcon />
              </MoreColorsAddAvatar>
            </MoreColorsGriditem>
          </MoreColorsGridContainer>
        )} */}
        {info.freeShipping && (
          <ButtonBase className={clsx(classes.buttonDefaults, classes.ship)}>
            FREE SHIPPING
          </ButtonBase>
        )}
        {info.active === false && (
          <ButtonBase className={clsx(classes.inactive)}>
            <b>DEACTIVATED</b>
          </ButtonBase>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ProductView;
