import { Container, Grid, Typography, Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState, useEffect } from "react";
import CarouselProductView from "../components/CarouselProductView";
import CarouselView from "../components/CarouselView";
import ShopByCategoryView from "../components/ShopByCategoryView";
import SupportYourView from "../components/SupportYourView";
import BubbleCategoryView from "../components/BubbleCategoryView";
import OtherCategories from "../components/OtherCategories";
import SocialMedia from "../components/SocialMedia";
import Blog from "../components/Blog";
import PurposeCategoryView from "../components/PurposeCategoryView";
import ThePurposer from "../components/ThePurposer";
import FeaturedSellers from "../components/FeaturedSellers";
import CountryShopByMap from "../components/CountryShopByMap";
import Popup from "../components/Popup"
import PopupMobile from "../components/PopupMobile"
import { getListOfProductsById, getRandomProducts } from "../util/getProductsById";
import { Link } from "react-router-dom";

//JSON filler ----------------
// import homepageCarouselHeaderData from "../json/homepage_carousel.json";
import homepageCarouselHeaderData from "../json/homepage_carousel_mothersday.json";
import otherCategories from "../json/other_categories.json";
import blogPosts from "../json/blog_posts.json";
import featuredSellers from "../json/featured_sellers.json";
import mapData from "../json/map_data.json";
import oceanSupportsCarouselData from "../json/ocean_supports_carousel.json";
import povertySupportsCarouselData from "../json/povery_supports_carousel.json";
import { MasterLayout } from "../layouts";
import GalleryView from "../components/GalleryView";
import { getPurposeCategoryById } from "../util/purposeCategories";
import BrandSupportsHeader from "../components/BrandSupportsHeader";
import { shuffle, splitArray } from "../util/utilities";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";
import { styled } from '@mui/material/styles';
import CategoryHomepageView from "../components/CategoryHomepageView";
import PurposeHomepageView from "../components/PurposeHomepageView";

const SellButton = styled(Button)({
  backgroundColor: '#EE907B',  // This is a sample teal color, adjust as needed
  color: 'white',
  fontSize: '24px',
  fontWeight: 'bold',
  width: '280px',
  height: '60px',
  textShadow: "2px 3px 5px rgba(0,0,0,0.2);",
  textTransform: 'none', // This prevents the text from being capitalized
  '&:hover': {
    backgroundColor: '#D6816E',  // Darken on hover
  },
});

const ShopNowButton = styled(Button)({
  backgroundColor: '#85AF9A',  // This is a sample pink color, adjust as needed
  color: 'white',
  fontSize: '24px',
  fontWeight: 'bold',
  width: '280px',
  height: '60px',
  marginRight: '40px',
  textShadow: "2px 3px 5px rgba(0,0,0,0.2);",
  textTransform: 'none', // This prevents the text from being capitalized
  '&:hover': {
    backgroundColor: '#779D8A',  // Darken on hover
  },
});

const SellButtonMobile = styled(Button)({
  backgroundColor: '#EE907B',  // This is a sample teal color, adjust as needed
  color: 'white',
  fontSize: '16px',
  fontWeight: 'bold',
  width: '12rem',
  height: '2.25rem',
  textShadow: "2px 3px 5px rgba(0,0,0,0.2);",
  textTransform: 'none', // This prevents the text from being capitalized
  '&:hover': {
    backgroundColor: '#D6816E',  // Darken on hover
  },
});

const ShopNowButtonMobile = styled(Button)({
  backgroundColor: '#85AF9A',  // This is a sample pink color, adjust as needed
  color: 'white',
  fontSize: '16px',
  fontWeight: 'bold',
  width: '12rem',
  height: '2.25rem',
  // marginRight: '40px',
  textShadow: "2px 3px 5px rgba(0,0,0,0.2);",
  textTransform: 'none', // This prevents the text from being capitalized
  '&:hover': {
    backgroundColor: '#779D8A',  // Darken on hover
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
    marginTop: theme.spacing(1),
  },
  containerMobile: {
    position: 'relative',
    overflow: 'hidden',
    marginTop: theme.spacing(-0.5),
  },
  divMarginBottom: { height: "var(--dividerHeight)" },
  divMarginSubtitles: { marginTop: theme.spacing(1), },
  mintGreenBackground: {
    backgroundColor: "var(--mintGreen)",
    marginTop: theme.spacing(-0.5),
    overflow: "hidden",
    padding: 10,
    paddingBottom: 75,
    paddingTop: 30,
  },
  mintGreenBackgroundMobile: {
    backgroundColor: "var(--mintGreen)",
    marginTop: theme.spacing(-0.5),
    overflow: "hidden",
    padding: 10,
    paddingBottom: 50,
    paddingTop: 30,
  },
  coralBackground: {
    height: "var(--dividerHeight)",
    backgroundColor: "var(--coral)",
  },
  headerImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
  },
  headerImageMobile: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    textShadow: "2px 3px 5px rgba(0,0,0,0.2)",
    padding: theme.spacing(2),
  },
  overlayMobile: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "center",
    color: "black",
    textShadow: "2px 3px 5px rgba(0,0,0,0.2)",
    padding: theme.spacing(2),
  },
  title: {
    fontSize: "3em",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5em",
    },
  },
  subtitle: {
    fontSize: "1.5em",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4em",
    },
  },
  purposeMenuTitle: {
    fontSize: "2.5em",
    color: "black",
    textShadow: "2px 3px 5px rgba(0,0,0,0.2)",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(5),

    [theme.breakpoints.down("sm")]: {
      fontSize: "1.25em",
    },
  },
  buttons: {
    marginTop: theme.spacing(2),
  },

  titleMobile: {
    fontSize: "1.8em",
    marginBottom: theme.spacing(0.25),
    marginTop: theme.spacing(0.45),
    textAlign: "center",
  },
  subtitleMobile: {
    fontSize: "1.3em",
    marginBottom: theme.spacing(0.35),
    textAlign: "center",
  },
  purposeMenuTitleMobile: {
    fontSize: "5em",
    color: "black",
    textShadow: "2px 3px 5px rgba(0,0,0,0.2)",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8em",
    },
  },
  buttonsMobile: {
    marginTop: theme.spacing(0),
    justifyContent: "center",
    alignItems: "center",
  },
  smallTopMargin: {
    marginTop: theme.spacing(1),
  },
}));

const HomepageLayout = () => {
  const classes = useStyles();
  const mobile = useMobileMediaQuery();

  const [randomProducts, setRandomProducts] = useState([[]]);
  const [sustainabilityProducts, setSustainabilityProducts] = useState([[]]);
  const [communitySupportProducts, setCommunitySupportProducts] = useState([[]]);
  const [oceanSupportsData, setOceanSupportsData] = useState([]);
  const [povertySupportsData, setPovertySupportsData] = useState([]);
  const [loadingRandomProducts, setLoadingRandomProducts] = useState(true);
  const [loadingSustainabilityProducts, setLoadingSustainabilityProducts] = useState(true);
  const [loadingCommunitySupportProducts, setLoadingCommunitySupportProducts] = useState(true);

  useEffect(() => {
    getRandomProducts((data) => {
      if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
        let mainProducts = shuffle(data.mainProducts);
        let extraProducts = shuffle(data.extraProducts);
        const resultingProducts = [...mainProducts, ...extraProducts];
        setRandomProducts(splitArray(resultingProducts));
        setLoadingRandomProducts(false);
      }
    });

    //Sustainability Products
    getPurposeCategoryById('60', (data) => {
      setSustainabilityProducts(splitArray(data));
      setLoadingSustainabilityProducts(false);
    });

    //Community Support Products
    getPurposeCategoryById('100', (data) => {
      setCommunitySupportProducts(splitArray(data));
      setLoadingCommunitySupportProducts(false);
    });

    getListOfProductsById(oceanSupportsCarouselData.products, (data) => {
      if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
        setOceanSupportsData(data);
      }
    });

    getListOfProductsById(povertySupportsCarouselData.products, (data) => {
      if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
        setPovertySupportsData(data);
      }
    });
  }, []);

  return (
    <MasterLayout>
      {/* {!mobile ? <Popup /> : <PopupMobile />} */}
      {!mobile ?
        (<div className={classes.container}>
          <img
            src={"https://d2eudfgh8kpk8j.cloudfront.net/common/home+page+FINAL-02.png"}
            alt="Our Impact"
            className={classes.headerImage}
          />
          <div className={classes.overlay}>
            <Typography variant="h1" className={classes.title}>
              Every brand has a unique purpose
            </Typography>
            <Typography variant="h2" className={classes.subtitle}>
              Shop for ethically produced products across 100+ categories.
            </Typography>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              className={classes.buttons}
            >
              <Grid item>
                <a href="/shop/category/0/Best-Sellers">
                  <ShopNowButton>
                    Shop Now
                  </ShopNowButton>
                </a>
              </Grid>
              <Grid item>
                <a href="/seller/signup">
                  <SellButton>
                    Sell on Purposer
                  </SellButton>
                </a>
              </Grid>
            </Grid>
          </div>
        </div>) :
        (<div className={classes.containerMobile}>
          <img
            src={"https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/mobile_homepage_picture_v2.png"}
            alt="Our Impact"
            className={classes.headerImageMobile}
          />
          <div className={classes.overlayMobile}>
            <Grid
              container
              spacing={0}
              justifyContent="center"
              direction="column"
              //cetnered
              alignItems="center"
            >
              <Grid item xs={4} sm={4} md={4} lg={4}>
                {/* <div className={classes.smallTopMargin} /> */}
                <Typography variant="h1" className={classes.titleMobile}>
                  Every brand has a
                </Typography>
                <Typography variant="h1" className={classes.titleMobile}>
                  unique purpose
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <div className={classes.divMarginSubtitles} />

                <Typography variant="h2" className={classes.subtitleMobile}>
                  Shop ethical products
                </Typography>
                <Typography variant="h2" className={classes.subtitleMobile}>
                  across 100+ categories.
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <a href="/shop/category/0/Best-Sellers">
                  <ShopNowButtonMobile>
                    Shop Now
                  </ShopNowButtonMobile>
                </a>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <a href="/seller/signup">
                  <SellButtonMobile>
                    Sell on Purposer
                  </SellButtonMobile>
                </a>
              </Grid>
            </Grid>
          </div>
        </div>)
      }
      <div className={mobile ? classes.mintGreenBackgroundMobile : classes.mintGreenBackground}>
        <div>
          <CategoryHomepageView />
        </div>
        <Typography
          variant="h1"
          align="center"
          gutterBottom
          className={mobile ? classes.purposeMenuTitleMobile : classes.purposeMenuTitle}
        >
          {mobile ? "Shop by purpose." : "Shop by purpose or cause."}
        </Typography>
        <div>
          <PurposeHomepageView />
        </div>
      </div>
      <div style={{ height: "25px" }} />
      <GalleryView
        productPages={randomProducts}
        itemsPerRow={6}
        extraPadding={true}
        showLoadingIcon={loadingRandomProducts}
      />
      <div className={classes.divMarginBottom} />
      <BubbleCategoryView />
      <div className={classes.divMarginBottom} />
      <ShopByCategoryView />
      <div className={classes.divMarginBottom} />
      <OtherCategories otherCategories={otherCategories} />
      <div className={classes.divMarginBottom} />
      <BrandSupportsHeader
        title={'Our Common Home, Planet, Trees, and Oceans'}
        title_url={'/shop/purpose/60'}
      />
      <GalleryView
        productPages={sustainabilityProducts}
        itemsPerRow={6}
        extraPadding={true}
        showLoadingIcon={loadingSustainabilityProducts}
      />
      <div className={classes.divMarginBottom} />
      <BrandSupportsHeader
        title={'Our Community, Veterans, and More'}
        title_url={'/shop/purpose/100'}
      />
      <GalleryView
        productPages={communitySupportProducts}
        itemsPerRow={6}
        extraPadding={true}
        showLoadingIcon={loadingCommunitySupportProducts}
      />
      <div className={classes.divMarginBottom} />
      <SupportYourView />
      <ThePurposer />
      <FeaturedSellers featuredSellers={featuredSellers} />
      <CountryShopByMap mapData={mapData} isHomePage={true} />
      <SocialMedia />
      <div className={classes.divMarginBottom} />
      {/* <div className={classes.coralBackground} /> */}
      {/* <div className={classes.divMarginBottom} /> */}
      <Blog blogPosts={blogPosts} />
      <div className={classes.divMarginBottom} />
    </MasterLayout>
  );
};

export default HomepageLayout;
