import {
  Container,
  Typography,
  Grid,
  Button,
  ButtonBase,
  Dialog,
  styled,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Stack,
  Rating,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import clsx from "clsx";
import bulb from "../img/PurposerIcon.png";
import Review from "./Review";

import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
  Edit,
} from "@mui/icons-material";
import Carousel from "react-multi-carousel";
import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getSellerById, getSellerReviews } from "../util/sellers";
import { getUserIdFromAuth } from "../util/utilities";
import ExpandableQuestionsView from "./ExpandableQuestionsView";
import PurposeCarousel from "./PurposeCarousel";
import { Box } from "@mui/system";

const maxNumPages = 9;



// const CustomDots = ({ index, onClick }) => {
//   const classes = useStyles();

//   if (index < maxNumPages) {
//     const NumberButton = (
//       <ButtonBase
//         onClick={(e) => {
//           onClick();
//           e.preventDefault();
//         }}
//         className={classes.galleryButton}
//       >
//         <Typography>{index + 1}</Typography>
//       </ButtonBase>
//     );

//     if (index === maxNumPages - 1) {
//       return (
//         <>
//           {NumberButton}
//           <ButtonBase className={classes.galleryButton}>
//             <Typography>...</Typography>
//           </ButtonBase>
//         </>
//       );
//     } else {
//       return <>{NumberButton}</>;
//     }
//   } else {
//     return <></>;
//   }
// };

const useStyles = makeStyles({
  reviewsDiv: {
    backgroundColor: "#F8F5F2",
    // overflow: "hidden",
    padding: "80px",

  },
  background: {
    // backgroundColor: "#82aa9f",
    backgroundColor: "var(--mintGreenTransparent)",
    // opacity: 0.8
  },
  backgroundDiv: {
    paddingTop: "64px",
    paddingBottom: "64px",
  },
  mapHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mapHeader: {
    color: "#FFFFFF",
  },
  mapGridItem: {
    paddingTop: 6,
  },
  map: {
    maxWidth: 1024,
    height: 400,
    margin: "auto",
  },
  paragraph: {
    marginBottom: 20,
  },
  image: {
    width: "100%",
    maxHeight: 675,
    objectFit: "cover",
  },
  backToTopButton: {
    width: "100%",
    borderRadius: 0,
    textTransform: "none",
    paddingTop: 5,
    paddingBottom: 5,
    "&:hover": {
      backgroundColor: "#82aa9f",
    },
  },
  backToTopText: {
    paddingTop: 7,
    paddingBottom: 7,
    fontSize: "1.15rem",
    color: "#000000",
  },
  center: {
    textAlign: "center",
  },
  bulb: {
    maxHeight: 35,
    marginBottom: 5,
  },
  contactSellerIcon: {
    maxWidth: 55,
    // marginTop: 30,
    cursor: "pointer",
  },
  shopPoliciesButton: {
    paddingLeft: 40,
    paddingRight: 40,
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "var(--coral)",
    borderRadius: 7,
    // marginTop: 30,
    // marginBottom: 50,
    minWidth: 160,
    maxHeight: 35,
    "&:hover": {
      backgroundColor: "var(--coral)",
    },
  },
  carouselDivContainer: {
    position: "relative",
  },
  carouselContainer: {
    width: "100%",
  },
  navButtons: {
    position: "absolute",
    top: "45%",
    height: 75,
    width: 27,
    border: "1px solid var(--mintGreen)",
    borderRadius: 20,
    backgroundColor: "#FFFFFF",
  },
  prevButton: {
    left: 10,
  },
  nextButton: {
    right: 10,
  },
  arrows: {
    color: "var(--mintGreen)",
    fontSize: 40,
  },
  closeButton: {
    textTransform: "none",
  },
  editButtonDiv: {
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    bottom: 30,
  },
  editButton: {
    color: "#FFFFFF",
    backgroundColor: "#000000",
    textTransform: "none",
    paddingLeft: 15,
    paddingRight: 15,
    "&:hover": {
      backgroundColor: "#000000",
    },
  },
  editButtonText: {
    marginLeft: 10,
  },
  reviewsCarousel: {
    position: "relative",
    width: "105%",
    paddingBottom: 100,
    marginTop: 10,
    margin: "auto",
    // overflow: "hidden",
  },
  reviewsNavButtons: {
    position: "absolute",
    top: "29%",
    border: "1px solid black",
    borderRadius: 20,
    backgroundColor: "#FFFFFF",
  },
  reviewsPrevButton: {
    left: 0,
  },
  reviewsNextButton: {
    right: 0,
  },
  galleryButton: {
    width: "35px",
    height: "35px",
    borderRadius: "25px",
    backgroundColor: "white",
    border: "1px solid black",
    margin: "7px 7px 20px 7px",
  },
});

const ReviewButtonGroup = ({ next, previous }) => {
  const classes = useStyles();
  return (
    <>
      <ButtonBase
        className={clsx(classes.reviewsNavButtons, classes.reviewsPrevButton)}
        onClick={previous}
      >
        <ArrowBack fontSize="large" />
      </ButtonBase>
      <ButtonBase
        className={clsx(classes.reviewsNavButtons, classes.reviewsNextButton)}
        onClick={next}
      >
        <ArrowForward fontSize="large" />
      </ButtonBase>
    </>
  );
};

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  return (
    <>
      <ButtonBase
        sx={{
          position: "absolute",
          width: "35px",
          height: "35px",
          borderRadius: "25px",
          border: "none",
          left: { xs: "2%", sm: "18%", md: "23%", lg: "30%", xl: "30%" },
          bottom: 7,
          backgroundColor: "#EAF1EF",
          zIndex: 50,
        }}
        onClick={previous}
      >
        <ArrowBack sx={{}} />
      </ButtonBase>
      <ButtonBase
        sx={{
          position: "absolute",
          width: "35px",
          height: "35px",
          borderRadius: "25px",
          border: "none",
          right: { xs: "2%", sm: "18%", md: "23%", lg: "30%", xl: "30%" },
          bottom: 7,
          zIndex: 50,
          backgroundColor: "#EAF1EF",
        }}
        onClick={next}
      >
        <ArrowForward sx={{}} />
      </ButtonBase>
    </>
  );
};

const CustomDots = ({ index, onClick }) => {
  const classes = useStyles();

  if (index < maxNumPages) {
    const NumberButton = (
      <ButtonBase
        onClick={(e) => {
          onClick();
          e.preventDefault();
        }}
        className={classes.galleryButton}
      >
        <Typography>{index + 1}</Typography>
      </ButtonBase>
    );

    if (index === maxNumPages - 1) {
      return (
        <>
          {NumberButton}
          <ButtonBase className={classes.galleryButton}>
            <Typography>...</Typography>
          </ButtonBase>
        </>
      );
    } else {
      return <>{NumberButton}</>;
    }
  } else {
    return <></>;
  }
};

const responsive = {
  break1: {
    breakpoint: { max: 4000, min: 1100 },
    items: 2,
  },
  break2: {
    breakpoint: { max: 1100, min: 0 },
    items: 1,
  },
}

const bulbIcon = new L.Icon({
  iconUrl: bulb,
  iconSize: [23, 35],
});

const Markers = ({ data }) => (
  <Marker icon={bulbIcon} position={[data.lat, data.lng]}>
    <Popup>
      <Typography align="center">{data.country}</Typography>
    </Popup>
  </Marker>
);

const AchievementCarousel = (props) => {
  const classes = useStyles();
  const { achievements } = props;

  return (
    <Grid container direction="row">
      {achievements &&
        achievements.map((description, index) => {
          return (
            <Grid item sm={4} key={index} className={classes.center} sx={{ display: { xs: "none", sm: "block" } }}>
              <img
                src={
                  index % 3 === 0
                    ? "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Seller+Store+Page/Green+lightbulb.png"
                    : index % 2 === 0
                      ? "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Seller+Store+Page/Coral+lightbulb+Icon-14-01.png"
                      : "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Seller+Store+Page/lightbulb+Icon+Black-01.png"
                }
                alt=""
                className={classes.bulb}
              />
              <Typography
                align="center"
                variant="subtitle1"
                className={clsx(classes.achievementText, "roboto-medium")}
              >
                <b>{description}</b>
              </Typography>
            </Grid>
          );
        })}
        <Grid item sx={{ display: { xs: "block", sm: "none" } }} xs={12}>
          <PurposeCarousel purposeCarouselData={achievements}>

          </PurposeCarousel>
        </Grid>
    </Grid>
  );
};

// const CustomButtonGroupAsArrows = ({ next, previous }) => {
//   const classes = useStyles();
//   return (
//     <>
//       <ButtonBase
//         className={clsx(classes.navButtons, classes.prevButton)}
//         onClick={previous}
//       >
//         <ArrowBack className={classes.arrows} />
//       </ButtonBase>
//       <ButtonBase
//         className={clsx(classes.navButtons, classes.nextButton)}
//         onClick={next}
//       >
//         <ArrowForward className={classes.arrows} />
//       </ButtonBase>
//     </>
//   );
// };

const ShopPolicyDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ShopPolicyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
};

function InAction(props) {
  const classes = useStyles();
  const { inActionData, sellerData, admin = false } = props;
  const { id } = useParams();
  const DEFAULT_ID = "22963625-1ae2-41d1-9041-97a8111c74a0";
  const [avg, setAvg] = React.useState(0);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleClickDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const [actionData, setActionData] = React.useState({});
  const [sellerPageData, setSellerPageData] = React.useState({});
  const [reviewData, setReviewData] = React.useState({});
  const [currUserId, setCurrUserId] = React.useState(null);
  const [contactSellerOpen, setContactSellerOpen] = React.useState(false);

  const handleContactSellerOpen = () => {
    setContactSellerOpen(false);
  };

  React.useEffect(() => {
    if (!inActionData || !sellerData) {
      getSellerById(id, (data) => {
        if (
          data &&
          !data.hasOwnProperty("error") &&
          data.hasOwnProperty("legacyLayoutData")
        ) {
          setActionData(data.legacyLayoutData.inAction);
        } else {
          // Fetch default
          getSellerById(DEFAULT_ID, (data) => {
            if (
              data &&
              !data.hasOwnProperty("error") &&
              data.hasOwnProperty("legacyLayoutData")
            ) {
              setActionData(data.legacyLayoutData.inAction);
            }
          });
        }
      });
    } else {
      setActionData(inActionData);
      setSellerPageData(sellerData);
    }
    getSellerReviews(id, (data) => {
      setReviewData(data);

    })
  }, [id, inActionData, sellerData]);

  React.useEffect(() => {
    let sum = 0;
    if (reviewData && reviewData.hasOwnProperty("results")) {
      for (let i = 0; i < reviewData.results.length; i++) {
        sum += reviewData.results[i].rating;
      }
      const avg = sum / reviewData.results.length;
      setAvg(avg);
    }
  }, [reviewData]);

  React.useEffect(() => {
    if (currUserId == null) {
      getUserIdFromAuth((userId) => {
        if (userId) {
          setCurrUserId(userId);
        }
      })
    }
  }, []);

  return (
    <>
      <div className={clsx(classes.background, classes.backgroundDiv)}>
        <Container maxWidth="xl">
          <div style={{ textAlign: "center", marginBottom: admin ? -6 : 30 }}>
            <Typography
              variant="h3"
              className={"roboto-bold"}
              display="inline"
            >
              {sellerData.hasOwnProperty("sellerName") ? sellerData.sellerName : ""}
            </Typography>
            {/* <Typography
              variant="h4"
              display="inline"
              className={"roboto-medium"}
            >
              {" "}
              in Action!
            </Typography> */}
          </div>
          {admin && (
            <div className={classes.editButtonDiv}>
              <Button className={classes.editButton}>
                <Edit />
                <Typography className={classes.editButtonText}>
                  <b>
                    Edit <i>In Action</i>
                  </b>
                </Typography>
              </Button>
            </div>
          )}
          <Grid container justifyContent="space-evenly">
            <Grid item xs={0} md={3}></Grid>
            <Grid item xs={12} md={6} sx={{ border: "1px solid", display: 'flex', justifyContent: "center", alignItems: "center" }} >
              <AchievementCarousel achievements={actionData.achievements} />
              {/* <Typography>hi</Typography> */}
            </Grid>
            {/* <div sx={{height: "64px", display: {xs: "block", sm: "none"}}}/> */}
            <Grid item xs={12} md={3} sx={{marginTop: {xs: "32px", md: "0px"}}}>
              <a>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={
                      "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Seller+Store+Page/Contact+Us+Icon-01.png"
                    }
                    alt=""
                    className={classes.contactSellerIcon}
                    onClick={() => {setContactSellerOpen(true)}}
                    // sx={{cursor: "pointer"}}
                  />
                </div>
                <Typography
                  align="center"
                  variant="h6"
                  className={"roboto-regular"}
                  onClick={() => {setContactSellerOpen(true)}}
                  sx={{cursor: "pointer"}}
                >
                  Contact Seller
                </Typography>
              </a>
              <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
                <Button

                  className={classes.shopPoliciesButton}
                  onClick={handleClickDialogOpen}
                >
                  <Typography
                    align="center"
                    className={"roboto-medium"}
                    variant="h6"
                  >
                    Shop Policies
                  </Typography>
                </Button>
              </div>
            </Grid>
          </Grid>
          <Dialog
            onClose={handleContactSellerOpen}
            aria-labelledby="customized-dialog-title"
            open={contactSellerOpen}
            maxWidth="md"
            fullWidth

          >
            <DialogTitle id="customized-dialog-title" onClose={handleContactSellerOpen}>
              <Typography>
                <b>Contact Seller Info</b>
              </Typography>
              <Typography>
                <b>Seller Contact Email: </b>
                {sellerPageData?.contactEmail || "N/A"}
              </Typography>
              <Typography>
                <b>Seller Contact Phone Number: </b>
                {sellerPageData?.contactPhone || "N/A"}
              </Typography>
            </DialogTitle>
          </Dialog>

          <Grid container direction="row" columnSpacing={12} alignItems="center">
            <Grid item xs={12} md={6} >
              <Typography variant="h3" style={{ fontWeight: "bold", marginTop: "32px" }}>ABOUT US</Typography>
              {actionData.text &&
                actionData.text.map((text, index) => {
                  return (
                    <Typography
                      variant="h6"
                      className={clsx(classes.paragraph, "roboto-regular")}
                      key={index}
                    >
                      {text}
                    </Typography>
                  );
                })}
              <div
                style={{
                  marginBottom: admin ? -21 : 15,
                  marginTop: 50,
                }}
              >
                <Typography
                  display="inline"
                  variant="h4"
                  className={clsx(classes.faqHeader, "roboto-thin")}
                >
                  {sellerData.sellerName}
                </Typography>
                <Typography
                  display="inline"
                  variant="h4"
                  className={"roboto-medium"}
                >
                  {" "}
                  FAQ
                </Typography>
              </div>
              {admin && (
                <div className={classes.faqEditButtonDiv}>
                  <Button className={classes.faqEditButton}>
                    <Edit />
                    <Typography className={classes.faqEditButtonText}>
                      <b>Edit FAQs</b>
                    </Typography>
                  </Button>
                </div>
              )}
              <ExpandableQuestionsView
                questions={sellerData.FAQ ? sellerData.FAQ : []}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className={clsx(classes.center, classes.mapGridItem)}
            >
              <div className={classes.mapHeaderContainer}>
                <Typography
                  align="center"
                  variant="h4"
                  // style={{ color: "black" }}
                  className={"roboto-medium"}
                >
                  COUNTRIES WE SUPPORT
                </Typography>
              </div>
              <MapContainer
                tap={false}
                center={[10, 0]}
                zoom={2}
                scrollWheelZoom={true}
                maxBounds={[
                  [-90, -180],
                  [90, 180],
                ]}
                className={classes.map}
                minZoom={2}
              >
                <TileLayer
                  attribution='&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.thunderforest.com/mobile-atlas/{z}/{x}/{y}.png?apikey={apikey}"
                  ext="png"
                  noWrap="true"
                  apikey="b4253345883a4819ae18fbf3ca4daef3"
                // style={{background: "var(--mintGreen)"}}
                />
                {actionData.mapData
                  ? actionData.mapData.map((item, index) => {
                    return <Markers data={item} key={index} />;
                  })
                  : null}
              </MapContainer>

              <ShopPolicyDialog onClose={handleDialogClose} open={dialogOpen}>
                <ShopPolicyDialogTitle onClose={handleDialogClose}>
                  <Typography>
                    <b>Shop Return Policies</b>
                  </Typography>
                </ShopPolicyDialogTitle>
                {/* <DialogContent dividers>
                  {sellerPageData.shippingPolicy &&
                    sellerPageData.shippingPolicy.map((policy, index) => {
                      return (
                        <>
                          <div style={{ display: "flex" }}>
                            <Typography gutterBottom key={index}>
                              {policy.shipping + " -"}
                            </Typography>
                            <Typography gutterBottom key={index}>
                              {'- Free if over $' + policy.shippingOverFreeAmt}
                            </Typography>
                          </div>
                        </>
                      );
                    })}
                </DialogContent> */}
                <DialogContent dividers>
                  {sellerPageData?.returnPolicy?.policy &&
                    <>
                      <div>
                        <Typography gutterBottom key={'returnPolicy'}>
                          {sellerPageData.returnPolicy.policy}
                        </Typography>
                        <Typography gutterBottom key={'returnPolicy'}>
                          {sellerPageData.returnPolicy.fee != null ? `\n` : ''}
                          {sellerPageData.returnPolicy.fee != null ? 'Fee: ' + sellerPageData.returnPolicy.fee : ''}
                        </Typography>
                      </div>
                    </>
                  }
                </DialogContent>
                <DialogActions>
                  <Button
                    autoFocus
                    onClick={handleDialogClose}
                    className={classes.closeButton}
                  >
                    Close
                  </Button>
                </DialogActions>
              </ShopPolicyDialog>
            </Grid>
          </Grid>
          <br/>
          <br/>
          <div className={classes.carouselDivContainer}>
            {(sellerPageData.footerImages && sellerPageData.footerImages.length > 0) && (!actionData.imgs || actionData.imgs.length == 0) && (
              <Carousel
                responsive={responsive}
                infinite={false}
                showDots={false}
                arrows={false}
                renderButtonGroupOutside
                customButtonGroup={
                  sellerPageData.footerImages && sellerPageData.footerImages.length > 0 ? (
                    <CustomButtonGroupAsArrows />
                  ) : (
                    <></>
                  )
                }
                containerClass={classes.carouselContainer}
              >
                {sellerPageData.footerImages &&
                  sellerPageData.footerImages.map((img, index) => {
                    return (
                      <img
                        src={img}
                        alt=""
                        className={classes.image}
                        key={index}
                      />
                    );
                  })}
              </Carousel>
            )}
            {/* Can remove this section once all items have their footer images in the non-inAction column */}
            {/* {(actionData.imgs && actionData.imgs.length > 0) && (!sellerPageData.footerImages || sellerPageData.footerImages.length == 0) && (
              <Carousel
                responsive={responsive}
                infinite={false}
                showDots={false}
                arrows={false}
                renderButtonGroupOutside
                customButtonGroup={
                  actionData.imgs && actionData.imgs.length > 0 ? (
                    <CustomButtonGroupAsArrows />
                  ) : (
                    <></>
                  )
                }
                containerClass={classes.carouselContainer}
              >
                {actionData.imgs &&
                  actionData.imgs.map((img, index) => {
                    return (
                      <img
                        src={img}
                        alt=""
                        className={classes.image}
                        key={index}
                      />
                    );
                  })}
              </Carousel>
            )} */}
          </div>
        </Container>
      </div>
      {/* <Button
        disableRipple
        onClick={() => {
          window.scroll({
            top: 0,
            behavior: "smooth",
          });
        }}
        className={clsx(classes.background, classes.backToTopButton)}
      >
        <Typography
          align="center"
          className={clsx(classes.backToTopText, "roboto-medium")}
        >
          Back to Top
        </Typography>
      </Button> */}

      <div className={classes.reviewsDiv}>
        <Container maxWidth="xl">

          <Grid item xs={12} sm order={{ xs: 1, sm: 2 }} justifyContent="center">
            <Typography variant="h4" className={"roboto-medium"} align="center">
              {sellerPageData.seller_name?.trim()}'s Product Reviews
            </Typography>
            <Divider sx={{ marginTop: "16px", marginBottom: "16px", borderBottomWidth: 3, backgroundColor: "black" }} />
            <Stack
              direction="row"
              align="center"
              sx={{ justifyContent: "center", marginBottom: "8px" }}
            >
              <Rating
                sx={{ color: "var(--coral)" }}
                readOnly
                value={avg}
                precision={0.5}
              />
              <Typography className={"roboto-medium"} sx={{ marginBottom: "8px" }}>
                ({reviewData.results ? reviewData.results.length : 0})
              </Typography>
            </Stack>
            <Typography className={"roboto-medium"} align="center">
              Overall {avg ? (Math.round(avg * 100) / 100) : 0} out of 5
            </Typography>
          </Grid>
          <div className={classes.divContainer}>
            {reviewData && reviewData.results ? (
              <>

                <Carousel
                  responsive={responsive}
                  infinite={false}
                  showDots={true}
                  arrows={false}
                  draggable={false}
                  className={classes.reviewsCarousel}
                  // renderButtonGroupOutside
                  customButtonGroup={<ReviewButtonGroup />}
                // customDot={<CustomDots />}
                >
                  {reviewData.results.map((review, index) => {
                    return <Review review={review} sellerID={sellerData?.sellerID} key={index} admin={sellerData?.adminUserID?.toLowerCase() == currUserId?.toLowerCase()} />;
                  })}
                </Carousel>
              </>
            ) : (
              <Typography
                align="center"
                className={clsx(classes.noReviewsText, "roboto-regular")}
              >
                No Reviews
              </Typography>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}

export default InAction;
export { Markers, AchievementCarousel };
