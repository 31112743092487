import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Rating,
  Modal,
  Box,
  Fade,
  Backdrop,
  ButtonBase,
  IconButton,
  CircularProgress,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";

import { Edit } from "@mui/icons-material";
import React, { useRef, useState } from "react";
import clsx from "clsx";
import useElementWidth from "../hooks/useElementWidth";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";
import bulb from "../img/grey_bulb.png";
import CloseIcon from "@mui/icons-material/Close";
import toast, { Toaster } from 'react-hot-toast';
import { updateSellerResponseProductReview, updateProductReview, deleteProductReview } from "../util/products";
import FileUpload, {
  UPLOAD_ERROR,
  triggerUpload,
  onDropHandler,
  onDragOverHandler,
} from "./FileUpload";
import { MAX_FILE_SIZE, FILETYPES, MAX_NUM_FILES, MIN_REVIEW_LENGTH, MAX_REVIEW_LENGTH } from "../util/constants"


import ClearIcon from '@mui/icons-material/Clear';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
const InputRow = (props) => {
  const { label, description, classes } = props;

  return (
    <Grid container item direction="row" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography>
          <b>{label}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {props.children}
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.descriptionText}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  reviewDiv: {
    maxWidth: "90%",
    margin: "auto",
    // minHeight: "100%",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "space-evenly",
  },
  reviewReviewer: {
    paddingRight: 25,
  },
  reviewDate: {
    fontStyle: "italic",
    paddingBottom: 10,
  },
  reviewText: {
    height: "96px",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  reviewImageContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 30,
  },
  reviewImage: {
    width: "85%",
    borderRadius: 20,
    objectFit: "cover",
  },
  responseHeader: {
    marginTop: 25,
  },
  responseText: {
    minHeight: 72,
    maxHeight: 72,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  editButtonDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    position: "absolute",
    bottom: 0
  },
  editButton: {
    color: "#FFFFFF",
    backgroundColor: "var(--coral)",
    textTransform: "none",
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 25,
    "&:hover": {
      backgroundColor: "var(--coral)",
    },
  },
  editButtonText: {
    marginLeft: 10,
  },
  carouselContainer: {
    width: "100%",
    height: "100%",
    margin: "auto",
  },
  navButtons: {
    position: "absolute",
    // top: "29%",
    border: "1px solid black",
    borderRadius: 20,
    backgroundColor: "#FFFFFF",
    bottom: 0,
    zIndex: 50,
  },
  prevButton: {
    left: 0,
  },
  nextButton: {
    right: 0,
  },
  carouselItem: {
    // maxWidth: 400,
    // maxHeight: 500
  },
  deleteAReviewButton: {
    backgroundColor: "white",
    color: "red",
    padding: "5px 15px",
    borderRadius: 8,
  },
  img: {
    //maxWidth: "400px"
    // maxHeight: "200px"
    height: "200px",
    width: "200px",
    objectFit: "cover"
  },
  uploadIconDiv: {
    width: "100px",
    height: "100px",
    backgroundColor: "lightgray",
    borderRadius: "8px",
    opacity: "0.4",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  uploadIconDivVer2: {
    width: "200px",
    height: "200px",
    backgroundColor: "lightgray",
    borderRadius: "8px",
    opacity: "0.4",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

const responsive = {
  break: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "var(--lightBeige)",
  border: "1px solid #000",
  borderRadius: 2,
  boxShadow: 24,
  pb: 1,
  pt: 4.5,
  px: 2,
  overflowY: "auto",
};

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  const classes = useStyles();
  return (
    <>
      <ButtonBase
        className={clsx(classes.navButtons, classes.prevButton)}
        onClick={previous}
      >
        <ArrowBack className={classes.arrows} />
      </ButtonBase>
      <ButtonBase
        className={clsx(classes.navButtons, classes.nextButton)}
        onClick={next}
      >
        <ArrowForward className={classes.arrows} />
      </ButtonBase>
    </>
  );
};

const CustomDot = ({ onClick, active, carouselState }) => {
  const { totalItems, currentSlide } = carouselState;

  return (
    <ButtonBase
      onClick={(e) => {
        onClick();
        e.preventDefault();
      }}
      sx={{ mx: 0.5, borderRadius: 5, mb: 1 }}
    >
      <Box
        sx={{
          width: 10,
          height: 10,
          borderRadius: 5,
          backgroundColor: [active ? "var(--coral)" : "var(--beige)"],
        }}
      />
    </ButtonBase>
  );
};

const ModalImages = ({ open, handleClose, classes, review }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="image-modal"
      aria-describedby="expand-image-modal"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            ...style,
            width: { xs: "90%", md: "70%" },
            height: { xs: "80%", md: "auto" },
          }}
        >
          <Grid container direction={"row"} rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{ display: "relative", height: { xs: 300, md: "100%" } }}
              >
                <Carousel
                  responsive={responsive}
                  infinite={false}
                  showDots={true}
                  arrows={true}
                  customDot={<CustomDot />}
                  containerClass={classes.carouselContainer}
                  itemClass={classes.carouselItem}
                  draggable={false}
                >
                  {review.pictures.map((picture, index) => {
                    return (
                      <img
                        src={picture}
                        alt=""
                        key={index}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          objectPosition: "center center",
                        }}
                      />
                    );
                  })}
                </Carousel>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item className={classes.reviewReviewer}>
                  <Typography variant="subtitle1" className={"roboto-medium"}>
                    {review.reviewer}
                  </Typography>
                </Grid>
                <Grid item>
                  <Rating
                    sx={{ color: "var(--coral)" }}
                    readOnly
                    value={review.rating}
                    precision={0.5}
                  />
                </Grid>
              </Grid>
              {review.productName ? (
                <Typography className={"roboto-medium"}>
                  Product: {review.productName}
                </Typography>) : ""}
              <Typography
                variant="subtitle2"
                className={clsx(classes.reviewDate, "roboto-regular")}
              >
                {`Reviewed on ${review.reviewDate ? new Date(review.reviewDate).toLocaleDateString() : ''}`}
              </Typography>
              <Typography
                variant="body1"
                className={clsx(classes.reviewText, "roboto-regular")}
              >
                {review.review}
              </Typography>
              {review.sellerResponse !== "" && (
                <>
                  <Typography
                    variant="subtitle1"
                    className={clsx(classes.responseHeader, "roboto-medium")}
                  >
                    Seller Response:
                  </Typography>
                  <Typography
                    variant="body1"
                    className={clsx(classes.responseText, "roboto-regular")}
                  >
                    {review.sellerResponse}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          <IconButton
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              display: "flex",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Fade>
    </Modal>
  );
};

const ResponseDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },


}));

const ResponseDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
};

function Review(props) {

  let coverPhotoRef = useRef(null);
  const [coverPhotoError, setCoverPhotoError] = useState(null);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let { review, admin = false, sellerID, deleted, setOpenConfirmDelete, productPage = false, onAdminScreen = false } = props;

  const reviewImageGridItemRef = useRef(null);
  const reviewImageGridItemWidth = useElementWidth(reviewImageGridItemRef);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [stars, setStarValue] = React.useState(review.rating);


  const [reviewText, setReviewValue] = React.useState(review.review);
  const [reviewStarted, setReviewStartedValue] = React.useState(false);


  const handleClickDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleResponseDialogClose = () => {
    setDialogOpen(false);
  };

  const [formValues, setFormValues] = useState({
    images: review.pictures
  });

  const handleFormValues = (name, value) => {
    setFormValues((preValues) => {
      return { ...preValues, [name]: value };
    });
  };

  const handleRemoveClick = (index, section) => {
    const list = [...formValues[section]];
    list.splice(index, 1);
    handleFormValues(section, list);
  };

  const [responseText, setResponseValue] = useState(review.sellerResponse);

  const handleReviewSubmit = () => {
    setLoading(true)
    var swearjar = require('swearjar');
    if (swearjar.profane(review.review)) {
      setLoading(false)
      const errorText = "Check your typed content. You are using a word or phrase from a Prohibited List!";
      toast((t) => (
        <span>
          {errorText}
          {'\n'}
          <Grid container justifyContent="center">
            <Button onClick={() => toast.dismiss(t.id)}>
              Dismiss
            </Button>
          </Grid>
        </span>
      ));
    }
    else {
      updateProductReview(review.reviewID, reviewText, formValues.images, parseFloat(stars), (data) => {
        setLoading(false)
        setDialogOpen(false);
        window.location.reload(false);
      })
    }
  };

  const handleReviewDelete = () => {
    setLoading(true)
    deleteProductReview(review.reviewID, (data) => {
      setLoading(false)
      setDialogOpen(false);
      window.location.reload(false);
    })
  }

  const handleResponseSubmit = () => {
    setLoading(true)
    var swearjar = require('swearjar');
    if (swearjar.profane(responseText)) {
      setLoading(false)
      const errorText = "Check your typed content. You are using a word or phrase from a Prohibited List!";
      toast((t) => (
        <span>
          {errorText}
          {'\n'}
          <Grid container justifyContent="center">
            <Button onClick={() => toast.dismiss(t.id)}>
              Dismiss
            </Button>
          </Grid>
        </span>
      ));
    }
    else {
      if (!sellerID || !review.reviewID || !responseText) return;
      updateSellerResponseProductReview(sellerID, review.reviewID, responseText, (data) => {
        setLoading(false)
        setDialogOpen(false);
        window.location.reload(false);
      })
    }
  };
  const handleResponseDelete = () => {
    if (!sellerID || !review.reviewID) return;
    setLoading(true)
    updateSellerResponseProductReview(sellerID, review.reviewID, "", (data) => {
      setLoading(false)
      setDialogOpen(false);
      window.location.reload(false);
    })
  };

  if (!review) {
    return <></>;
  }

  return (
    <div className={classes.reviewDiv}>
      <Toaster
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <Modal open={loading} sx={{ overflow: "scroll" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 2,
            bgcolor: "background.paper",
            p: 4,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
        </Box>
      </Modal>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item className={classes.reviewReviewer}>
              <Typography variant="subtitle1" className={"roboto-medium"}>
                {review.reviewer}
              </Typography>
            </Grid>
            <Grid item>
              <Rating
                sx={{ color: "var(--coral)" }}
                readOnly
                value={review.rating}
                precision={0.5}
              />
            </Grid>
          </Grid>
        </Grid>
        {admin && onAdminScreen && !deleted &&
          <Grid item>
            <Button variant="contained" sx={{
              backgroundColor: "#D8000C", color: "white", '&:hover': {
                backgroundColor: 'red',
              },
            }} onClick={setOpenConfirmDelete}>Delete Review</Button>
          </Grid>}
        {admin && onAdminScreen && deleted &&
          <Grid item>
            <Typography sx={{ color: "red" }}> {review.reviewDate ? "Deleted " + new Date(review.reviewDate).toLocaleDateString() : ''}</Typography>
          </Grid>}
      </Grid>
      {review.productName ? (
        <Typography className={"roboto-medium"}>
          Product: {review.productName}
        </Typography>) : ""}
      <Typography
        variant="subtitle2"
        className={clsx(classes.reviewDate, "roboto-regular")}
      >
        {review.reviewDate ? "Reviewed on " + new Date(review.reviewDate).toLocaleDateString() : ''}
      </Typography>
      <Typography
        variant="body1"
        className={clsx(classes.reviewText, "roboto-regular")}
      >
        {review.review}
      </Typography>
      <Grid container direction="row" className={classes.reviewImageContainer}>
        {formValues.images.map((picture, index) => {
          return (
            <Grid item xs={3} key={index} ref={reviewImageGridItemRef}>
              <img
                src={picture}
                onClick={handleOpen}
                alt=""
                style={{
                  height: 0.85 * reviewImageGridItemWidth,
                  cursor: "pointer",
                }}
                className={classes.reviewImage}
              />
            </Grid>
          );
        })}
      </Grid>
      {review.sellerResponse != "" && (
        <>
          <Typography
            variant="subtitle1"
            className={clsx(classes.responseHeader, "roboto-medium")}
          >
            Seller Response:
          </Typography>
          <Typography
            varaint="body1"
            className={clsx(classes.responseText, "roboto-regular")}
          >
            {review.sellerResponse}
          </Typography>
        </>
      )}
      {/* THIS IS THE CASE FOR THE SELLER RESPONSE */}
      {admin && !onAdminScreen && !productPage && (<Grid item xs={6} sm order={{ xs: 2, sm: 1 }}>
        <div className={classes.editButtonDiv}>
          <Button className={classes.editButton} onClick={handleClickDialogOpen}>
            <Edit />
            <Typography className={classes.editButtonText}>
              {review.sellerResponse != "" ? <b>Edit Response</b> : <b>Add Response</b>}
            </Typography>
          </Button>
        </div>
        <ResponseDialog maxWidth="md" fullWidth onClose={handleResponseDialogClose} open={dialogOpen}>
          <ResponseDialogTitle onClose={handleResponseDialogClose}>
            <Typography>
              <b>Overall Rating</b>
            </Typography>
          </ResponseDialogTitle>
          <DialogContent dividers>
            <Rating
              sx={{ color: "var(--coral)" }}
              value={review.rating}
              precision={0.5}
              disabled
            />
          </DialogContent>
          <DialogContent dividers>
            <InputRow label="Write a Review" classes={classes}>
              <TextField
                name="review"
                disabled={true}
                multiline
                defaultValue={review.review}
                variant="outlined"
                size="small"
                className={classes.inputOutline}
                fullWidth
                inputProps={{ maxLength: 90 }}
              />
            </InputRow>
            <InputRow label="Seller Response" classes={classes}>
              <TextField
                name="review"
                error={responseText.length < 15 && (responseText.length > 0 || reviewStarted)}
                onChange={(e) => {
                  setResponseValue(e.target.value)
                  setReviewStartedValue(true);
                }}
                placeholder="What did you want to reply to this review? Is there anything you want to clarify about the product?"
                multiline
                defaultValue={responseText}
                helperText={`${responseText.length}/350`}
                variant="outlined"
                size="small"
                className={classes.inputOutline}
                fullWidth
                inputProps={{ maxLength: 350 }}
              />
            </InputRow>
            <Grid item xs={12} md={6}>
              <Typography className={classes.headers}><b>Product Photos</b></Typography>
              <div
                className={clsx(
                  !formValues.images.length && classes.border,
                  classes.uploadDivContainer,
                  classes.coverPhotoDiv
                )}
              >
                {formValues.images.length > 0 ? (
                  <Carousel
                    responsive={responsive}
                    draggable={false}
                    infinite={true}
                    showDots={false}
                    arrows={false}
                    containerClass={clsx(classes.carouselContainer, classes.border)}
                    slidesToSlide={1}
                    autoPlay={formValues.images.length > 1}
                    autoPlaySpeed={8000}
                    customButtonGroup={<CustomButtonGroupAsArrows />}
                  >
                    {formValues.images.map((image, index) => {
                      return (
                        <img
                          src={image}
                          alt=""
                          key={index}
                          className={classes.img}
                        />
                      );
                    })}
                  </Carousel>
                ) : (
                  <img
                    src={bulb}
                    alt=""
                    className={clsx(
                      classes.img,
                      !formValues.images.length && classes.uploadLogo,
                      formValues.images.length && classes.uploadedImg,
                      formValues.images.length && classes.uploadedImages,
                      formValues.images.length && classes.border
                    )}
                  />
                )}
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleResponseSubmit}
              className={classes.writeAReviewButton}
              disabled={responseText.length < 15}
            >
              Submit
            </Button>
            {review.sellerResponse ? (<Button
              autoFocus
              onClick={handleResponseDelete}
              className={classes.deleteAReviewButton}
            >
              Delete Response
            </Button>) : ""}
            <Button
              autoFocus
              onClick={handleResponseDialogClose}
              className={classes.closeButton}
            >
              Close
            </Button>
          </DialogActions>
        </ResponseDialog>
      </Grid>)
      }


      {/* THIS IS THE CASE FOR THE SELLER RESPONSE */}
      {!admin && !productPage && !sellerID && (<Grid item xs={6} sm order={{ xs: 2, sm: 1 }}>
        <div className={classes.editButtonDiv}>
          <Button className={classes.editButton} onClick={handleClickDialogOpen}>
            <Edit />
            <Typography className={classes.editButtonText}>
              <b>Edit Review</b>
            </Typography>
          </Button>
        </div>
        <ResponseDialog maxWidth="md" fullWidth onClose={handleResponseDialogClose} open={dialogOpen}>
          <ResponseDialogTitle onClose={handleResponseDialogClose}>
            <Typography>
              <b>Overall Rating</b>
            </Typography>
          </ResponseDialogTitle>
          <DialogContent dividers>
            <Rating
              sx={{ color: "var(--coral)" }}
              onChange={(e) => setStarValue(e.target.value)}
              value={stars}
              precision={0.5}
            />
          </DialogContent>
          <DialogContent dividers>
            <InputRow label="Write a Review" classes={classes}>
              <TextField
                name="review"
                error={reviewText.length < MIN_REVIEW_LENGTH && (reviewText.length > 0 || reviewStarted)}
                onChange={(e) => {
                  setReviewValue(e.target.value)
                  setReviewStartedValue(true)
                }}
                placeholder="What did you like or dislike? What did you use this product for?"
                multiline
                defaultValue={reviewText}
                helperText={`${reviewText.length}/550`}
                variant="outlined"
                size="small"
                className={classes.inputOutline}
                fullWidth
                inputProps={{ maxLength: MAX_REVIEW_LENGTH }}
              />
            </InputRow>
            <Grid item xs={12} md={12}>
              <Typography className={classes.headers}><b>Product Photos</b></Typography>
              <div
                className={clsx(
                  !formValues.images.length && classes.border,
                  classes.uploadDivContainer,
                  classes.coverPhotoDiv
                )}
              >
                {formValues.images.length > 0 ? (
                  <Grid container columnSpacing={2} rowSpacing={2}>
                    {formValues.images.map((image, index) => {
                      return (
                        <Grid item>
                          <ClearIcon onClick={() => handleRemoveClick(index, "images")} style={{ position: 'absolute', color: 'black', padding: "8px", cursor: "pointer" }}></ClearIcon>
                          <img
                            src={image}
                            alt=""
                            key={index}
                            className={classes.img}
                          />
                        </Grid>
                      );
                    })}
                    {formValues.images.length < 4 && <Grid item >
                      <div style={{ cursor: "pointer" }}
                        onClick={() => {
                          triggerUpload(coverPhotoRef);
                        }}
                        onDrop={(e) =>
                          onDropHandler(
                            e,
                            setCoverPhotoError,
                            "images",
                            MAX_NUM_FILES,
                            FILETYPES,
                            MAX_FILE_SIZE,
                            handleFormValues,
                            true,
                            formValues.images
                          )
                        }
                        onDragOver={onDragOverHandler} className={classes.uploadIconDivVer2}>
                        <AddCircleOutlineIcon fontSize="medium" />
                      </div>
                    </Grid>}
                  </Grid>
                ) : (
                  <div style={{ cursor: "pointer" }}
                    onClick={() => {
                      triggerUpload(coverPhotoRef);
                    }}
                    onDrop={(e) =>
                      onDropHandler(
                        e,
                        setCoverPhotoError,
                        "images",
                        MAX_NUM_FILES,
                        FILETYPES,
                        MAX_FILE_SIZE,
                        handleFormValues,
                        true,
                        formValues.images
                      )
                    }
                    onDragOver={onDragOverHandler} className={classes.uploadIconDiv}>
                    <AddCircleOutlineIcon fontSize="medium" />
                  </div>
                )}


                <FileUpload
                  setRef={(inputRef) => {
                    coverPhotoRef = inputRef;
                  }}
                  multiple
                  setError={setCoverPhotoError}
                  propertyName={"images"}
                  maxFiles={MAX_NUM_FILES}
                  accept={"image/*"}
                  filetypes={FILETYPES}
                  maxFileSize={MAX_FILE_SIZE}
                  updateState={handleFormValues}
                  coverPhotos={formValues.images}
                  style={{ cursor: "pointer" }}
                />
              </div>
              {coverPhotoError && (
                <Typography>
                  {coverPhotoError.type === UPLOAD_ERROR.FILESIZE_TOO_LARGE
                    ? "Max file size exceeded"
                    : ""}
                  {coverPhotoError.type === UPLOAD_ERROR.TOTALFILESIZE_TOO_LARGE
                    ? "Your total files size exceeds the maximum. Upload the images individually."
                    : ""}
                  {coverPhotoError.type === UPLOAD_ERROR.NOT_SUPPORTED_EXTENSION
                    ? "Illegal file type"
                    : ""}
                </Typography>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleReviewSubmit}
              className={classes.writeAReviewButton}
              disabled={reviewText.length < MIN_REVIEW_LENGTH}
            >
              Submit
            </Button>
            <Button
              autoFocus
              onClick={handleReviewDelete}
              className={classes.deleteAReviewButton}
            >
              Delete Review
            </Button>
            <Button
              autoFocus
              onClick={handleResponseDialogClose}
              className={classes.closeButton}
            >
              Close
            </Button>
          </DialogActions>
        </ResponseDialog>
      </Grid>)
      }

      <ModalImages
        open={open}
        handleClose={handleClose}
        classes={classes}
        review={review}
      />
    </div>
  );
}

export default Review;
