import {
  Typography,
  Grid,
  MenuItem,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  ButtonBase,
  FormGroup,
  Checkbox,
  FormControl,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { useRef, useState, useEffect, useCallback } from "react";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";
import MuiPhoneNumber from 'material-ui-phone-number';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FileUpload, {
  onDragOverHandler,
  onDropHandler,
  triggerUpload,
  UPLOAD_ERROR,
} from "./FileUpload";
import StripeSaveCardLayout from "../layouts/ShopperLayouts/StripeSaveCardLayout";
import AddressInput from "./AddressInput";
import { BUSINESS_TYPE, COMPANY_TYPE, MAX_FILE_SIZE } from "../util/constants"


const useStyles = makeStyles({
  sectionHeader: {
    color: "var(--mintGreenSolid)",
    fontSize: "1.4rem",
    marginBottom: 8,
    fontWeight: "bold"
  },
  paymentHeader: {
    marginTop: 60,
  },
  descriptionText: {
    fontSize: "0.9rem",
  },
  fileSelectedText: {
    fontSize: "0.9rem",
    color: "#00008B",
  },
  dropdownInput: {
    "& .MuiSelect-root": {
      paddingTop: 7,
      paddingBottom: 7,
    },
  },
  textInput: {
    "& .MuiInputBase-input": {
      paddingTop: 7,
      paddingBottom: 7,
    },
  },
  inputOutline: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 5,
      "&:hover fieldset": {
        borderColor: "var(--mintGreen)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--mintGreen)",
      },
    },
  },
  uploadButton: {
    textTransform: "none",
  },
  paymentMethodGrid: {
    marginTop: 15,
  },
});


const InputRow = (props) => {
  const { label, subHeader, description, classes } = props;

  return (
    <Grid container item direction="row" alignItems="center">
      <Grid item xs={12}>
        <Typography className={classes.label}>
          <b>{label}</b>
        </Typography>
      </Grid>
      {subHeader &&
      <Grid item xs={12}>
        <Typography className={classes.descriptionText}>
          {subHeader}
        </Typography>
      </Grid>
      }
      <Grid item xs={12}>
        {props.children}
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.descriptionText}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

const SectionHeaderRow = (props) => {
  const { label, subHeader, classes } = props;

  return (
    <Grid container item direction="row" alignItems="center">
      <Grid item xs={12}>
        <Typography className={classes.sectionHeader}>
          {label}
        </Typography>
      </Grid>
      {subHeader &&
      <Grid item xs={12}>
        <Typography className={classes.descriptionText}>
          {subHeader}
        </Typography>
      </Grid>
      }
    </Grid>
  );
};
function formatSSN(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const ssn = value.replace(/[^\d]/g, '');

  // ssnLength is used to know when to apply our formatting for the ssn
  const ssnLength = ssn.length;

  // we need to return the value with no formatting if its less then four digits
  if (ssnLength < 4) return ssn;

  // if ssnLength is greater than 4 and less the 6 we start to return
  // the formatted number
  if (ssnLength < 6) {
    return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
  }

  // finally, if the ssnLength is greater then 6, we add the last
  // bit of formatting and return it.
  return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
}

function formatLast4SSN(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const ssn = value.replace(/[^\d]/g, '');

  // ssnLength is used to know when to apply our formatting for the ssn
  const ssnLength = ssn.length;

  // we need to return the value with no formatting if its less then four digits
  if (ssnLength <= 4) return ssn;

  // if ssnLength is greater than 4 we cut it and return
  // the formatted number
  if (ssnLength > 4) {
    return `${ssn.slice(0, 4)}`;
  }
}

const FullSsnInput = (props) => {
  const { formValues, handleFormValues, fieldName, classes } = props;
  const handleFullInput = (e) => {
    // this is where we'll call the formatSSN function
    const formattedSSN = formatSSN(e.target.value);
    // we'll set the input value using our setInputValue
    handleFormValues(fieldName, formattedSSN);
  };

  return (
    <TextField
      name="fullSSN"
      onChange={(e) => handleFullInput(e)}
      value={formValues[fieldName]}
      variant="outlined"
      size="small"
      fullWidth
      className={classes.inputOutline}
    />
  )
}

const Last4SsnInput = (props) => {
  const { formValues, handleFormValues, fieldName, classes } = props;

  const handleLast4Input = (e) => {
    // this is where we'll call the formatSSN function
    const formattedSSN = formatLast4SSN(e.target.value);
    // we'll set the input value using our setInputValue
    handleFormValues(fieldName, formattedSSN);
  };

  return (
    <TextField
      name="last4SSN"
      onChange={(e) => handleLast4Input(e)}
      value={formValues[fieldName]}
      variant="outlined"
      size="small"
      fullWidth
      className={classes.inputOutline}
    />
  )
}

function extractNumbers(str) {
  // Use a regular expression to match only digits and decimal points
  const regex = /[0-9.]+/g;
  const matches = str.match(regex);
  
  // Join the matched numbers and decimals into a new string
  const extracted = matches ? matches.join('') : '';
  
  return extracted;
}

function formatPercentage(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const perc = extractNumbers(value);

  if (Number(perc) > 100) {
    return '100';
  }
  // ssnLength is used to know when to apply our formatting for the ssn
  const percLength = perc.length;

  // we need to return the value with no formatting if its less then four digits
  if (percLength <= 5) return perc;

  // if ssnLength is greater than 4 we cut it and return
  // the formatted number
  if (percLength > 5) {
    return `${perc.slice(0, 5)}`;
  }
}

const PercentageOwnedInput = (props) => {
  const { formValues, handleFormValues, fieldName, classes } = props;

  const handlePercentageInput = (e) => {
    // this is where we'll call the formatPercentage function
    const formattedPercentage = formatPercentage(e.target.value);
    // we'll set the input value using our setInputValue
    handleFormValues(fieldName, formattedPercentage);
  };

  return (
    <TextField
      name="percentageOwned"
      onChange={(e) => handlePercentageInput(e)}
      value={formValues[fieldName]}
      variant="outlined"
      // type="number"
      // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 5 }}
      size="small"
      fullWidth
      className={classes.inputOutline}
    />
  )
}

const PercentageOwnedWithinArrayInput = (props) => {
  const { formValues, index, handleIndexedFormValues, objectName, fieldName, classes } = props;

  const handlePercentageInput = (e) => {
    // this is where we'll call the formatSSN function
    const formattedSSN = formatPercentage(e.target.value);
    // we'll set the input value using our setInputValue
    handleIndexedFormValues(index, fieldName, formattedSSN);
  };

  return (
    <TextField
      name={"percentageOwned"+index}
      onChange={(e) => handlePercentageInput(e)}
      value={formValues[objectName][index][fieldName]}
      variant="outlined"
      // type="number"
      // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 5 }}
      size="small"
      fullWidth
      className={classes.inputOutline}
    />
  )
}

function formatTaxId(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const taxId = value.replace(/[^\d]/g, '');

  // taxIdLength is used to know when to apply our formatting for the taxId
  const taxIdLength = taxId.length;

  // we need to return the value with no formatting if its less then three digits
  if (taxIdLength < 3) return taxId;

  // if taxIdLength is greater than 4 and less the 6 we start to return
  // the formatted number
  if (taxIdLength < 3) {
    return `${taxId.slice(0, 2)}-${taxId.slice(2)}`;
  }

  return `${taxId.slice(0, 2)}-${taxId.slice(2, 9)}`;
}

const TaxIdInput = (props) => {
  const { formValues, handleFormValues, classes } = props;
  const handleInput = (e) => {
    // this is where we'll call the formatTaxId function
    const formattedTaxId = formatTaxId(e.target.value);
    // we'll set the input value using our setInputValue
    handleFormValues('companyTaxId', formattedTaxId);
  };

  return (
    <TextField
      name="companyTaxId"
      onChange={(e) => handleInput(e)}
      value={formValues.companyTaxId}
      variant="outlined"
      size="small"
      fullWidth
      className={classes.inputOutline}
    />
  )
}

function OpenAShopPayments(props) {
  const classes = useStyles();
  const { handleFormValues, formValues, setFormValues } = props;
  let verificationFrontRef = useRef(null);
  let verificationBackRef = useRef(null);
  const [verificationFrontError, setVerificationFrontError] = useState(null);
  const [verificationBackError, setVerificationBackError] = useState(null);
  let verificationCompanyFrontRef = useRef(null);
  let verificationCompanyBackRef = useRef(null);
  const [verificationCompanyFrontError, setVerificationCompanyFrontError] = useState(null);
  const [verificationCompanyBackError, setVerificationCompanyBackError] = useState(null);

  const handleOwnersChange = (index, fieldname, value) => {
    const newValues = { ...formValues };
    if (newValues?.owners?.[index]) {
      newValues.owners[index][fieldname] = value;
      setFormValues(newValues);
    }
  }

  const handleAddOwner = () => {
    const newValues = { ...formValues };
    if (!newValues.owners || !Array.isArray(newValues.owners)) {
      newValues.owners = []
    }
    newValues.owners.push({
      firstName: '',
      lastName: '',
      email: '',
      percentageOwned: '',
    });
    setFormValues(newValues);
  }

  const handleRemoveOwner = (index) => {
    const newValues = { ...formValues };
    if (newValues?.owners?.[index]) {
      newValues.owners.splice(index, 1);
      setFormValues(newValues);
    }
  }

  const mobile = useMobileMediaQuery();
  const BUSINESS_TYPES = [
    { 
      name: "Individual", 
      value: "individual",
    },
    { 
      name: "Company", 
      value: "company",
    },
  ];
  const COMPANY_TYPES =[
    { 
      name: "Sole Proprietorship", 
      value: "sole_proprietorship",
    },
    { 
      name: "Single Member LLC", 
      value: "single_member_llc",
    },
    { 
      name: "Multi Member LLC", 
      value: "multi_member_llc",
    },
    { 
      name: "Private Corporation", 
      value: "private_corporation",
    },
  ];

  const resetPaymentsFormValues = (oldFormValues) => {
    const newValues = { ...oldFormValues}
    newValues.companyType = undefined;
    newValues.companyTaxId = '';
    newValues.companyName = '';
    newValues.percentageOwned = '';
    newValues.representative = false;
    newValues.companyPhoneNumber = '';

    newValues.addressCompanyLine1 = '';
    newValues.addressCompanyLine2 = '';
    newValues.addressCompanyCity = '';
    newValues.addressCompanyCountry = '';
    newValues.addressCompanyState = '';
    newValues.addressCompanyPostalCode = '';

    newValues.ownerPhoneNumber = '';
    newValues.addressOwnerLine1 = '';
    newValues.addressOwnerLine2 = '';
    newValues.addressOwnerCity = '';
    newValues.addressOwnerCountry = '';
    newValues.addressOwnerPostalCode = '';

    // newValues.verificationCompanyFront = '';
    // newValues.verificationCompanyBack = '';

    newValues.firstName = '';
    newValues.lastName = '';
    // newValues.dobDay = '';
    // newValues.dobMonth = '';
    // newValues.dobYear = '';
    newValues.email = '';
    newValues.phoneNumber = '';
    newValues.addressLine1 = '';
    newValues.addressLine2 = '';
    newValues.addressCity = '';
    newValues.addressCountry = '';
    newValues.addressState = '';
    newValues.addressPostalCode = '';

    newValues.fullSSN = '';
    newValues.last4SSN = '';
    newValues.position = '';
    newValues.title = '';

    delete newValues.owners;
    return newValues;
  }

  const resetPaymentsFormValuesAndSetBusinessType = (event) => {
    setFormValues((preValues) => {
      const newValues = resetPaymentsFormValues(preValues);
      newValues.businessType = event.target.value;
      return newValues;
    });
  }

  const resetPaymentsFormValuesAndSetCompanyType = (event) => {
    setFormValues((preValues) => {
      const newValues = resetPaymentsFormValues(preValues);
      newValues.companyType = event.target.value;
      return newValues;
    });
  }

  return (
    <div>
      <Typography className={classes.sectionHeader}>
        Account Verification
      </Typography>

      <Grid container direction="row" alignItems="center" spacing={3}>
        <InputRow
          label={"Type of Seller"}
          description={"For Tax purposes only. Choose from Individual or Company."}
          classes={classes}
        >
          <TextField
            select
            fullWidth
            variant="outlined"
            size="small"
            value={formValues.businessType}
            onChange={(event) => {
              resetPaymentsFormValuesAndSetBusinessType(event);
            }}
            className={clsx(classes.dropdownInput, classes.inputOutline)}
          >
            {BUSINESS_TYPES.map((values, index) => {
                return (
                  <MenuItem key={index} value={values.value}>
                    <Typography>{values.name}</Typography>
                  </MenuItem>
                );
              })}
          </TextField>
        </InputRow>
        {formValues.businessType == BUSINESS_TYPE.COMPANY &&
        <InputRow
          label={"Type of Company"}
          description={""}
          classes={classes}
        >
          <TextField
            select
            fullWidth
            variant="outlined"
            size="small"
            value={formValues.companyType}
            onChange={(event) => {
              resetPaymentsFormValuesAndSetCompanyType(event);
            }}
            className={clsx(classes.dropdownInput, classes.inputOutline)}
          >
            {COMPANY_TYPES.map((values, index) => {
                return (
                  <MenuItem key={index} value={values.value}>
                    <Typography>{values.name}</Typography>
                  </MenuItem>
                );
              })}
          </TextField>
        </InputRow>
        }
        {formValues.businessType == BUSINESS_TYPE.INDIVIDUAL ?
        <>
          <InputRow label="First Name" classes={classes}>
            <TextField
              name="firstName"
              onChange={(e) => handleFormValues("firstName", e.target.value)}
              placeholder="First"
              value={formValues.firstName}
              variant="outlined"
              size="small"
              className={classes.inputOutline}
              fullWidth
            />
          </InputRow>
          <InputRow label="Last Name" classes={classes}>
            <TextField
              name="lastName"
              onChange={(e) => handleFormValues("lastName", e.target.value)}
              placeholder="Last"
              value={formValues.lastName}
              variant="outlined"
              size="small"
              className={classes.inputOutline}
              fullWidth
            />
          </InputRow>
          <InputRow
            label="Date of Birth"
            classes={classes}
            description="This is for Stripe verification purposes only. It will not be visible to shoppers."
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={'datePicker'}
                label="Select a date"
                disableFuture
                value={`${formValues.dobMonth}/${formValues.dobDay}/${formValues.dobYear}`}
                onChange={(value => {
                  // const formattedDate = `${new String(value.$M+1).padStart(2, '0')}/${new String(value.$D).padStart(2, '0')}/${new String(value.$y).padStart(4, '0')}`;
                  // setNewBirthday(formattedDate);
                  handleFormValues("dobDay", new String(value.$D).padStart(2, '0'))
                  handleFormValues("dobMonth", new String(value.$M + 1).padStart(2, '0'))
                  handleFormValues("dobYear", new String(value.$y).padStart(4, '0'))
                })}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Typography className={classes.descriptionText}>
              You must be 18 years or older to make an account
            </Typography>
          </InputRow>
          <InputRow
            label="Email"
            classes={classes}
            description="This email is for verification purposes only. It will not be visible to shoppers."
          >
            <TextField
              name="email"
              onChange={(e) => handleFormValues("email", e.target.value)}
              value={formValues.email}
              variant="outlined"
              size="small"
              fullWidth
              className={classes.inputOutline}
            />
          </InputRow>
          <InputRow
            label="Phone"
            classes={classes}
            description="Must be a U.S. phone number. This phone number is for verification purposes only. It will not be visible to shoppers."
          >
            <MuiPhoneNumber disableAreaCodes fullWidth defaultCountry={'us'}
              onChange={(value) => {
                handleFormValues("phoneNumber", value);
              }
              }
              value={formValues.phoneNumber}
            />
          </InputRow>
          <InputRow 
            label="Address" 
            classes={classes} 
            description="Must be a U.S. address. PO Boxes are not allowed."
          >
            <AddressInput
              formValues={formValues}
              handleFormValues={handleFormValues}
              addressLine1={'addressLine1'}
              addressLine2={'addressLine2'}
              addressCity={'addressCity'}
              addressCountry={'addressCountry'}
              addressState={'addressState'}
              addressPostalCode={'addressPostalCode'}
            />
          </InputRow>
          <InputRow
            label="Social Security Number"
            classes={classes}
            description={"Please enter your full Social Security Number, or any other Taxpayer Identification Number. This is for Stripe verification and tax purposes only. It will not be stored in our databases. Your data is secure!"}
          >
            <FullSsnInput formValues={formValues} handleFormValues={handleFormValues} fieldName={"fullSSN"} classes={classes} />
          </InputRow>
        </>
        : formValues.businessType == BUSINESS_TYPE.COMPANY && formValues.companyType ?
        <>
        {/* Company Info */}
          <SectionHeaderRow 
            label={`Company Information`} 
            classes={classes}
          />
          <InputRow label="Company Legal Name" description="This legal name must match the name associated with your Tax ID or EIN number." classes={classes}>
            <TextField
              name="companyName"
              onChange={(e) => handleFormValues("companyName", e.target.value)}
              value={formValues.companyName}
              variant="outlined"
              size="small"
              className={classes.inputOutline}
              fullWidth
            />
          </InputRow>
          <InputRow 
            label="Company Address" 
            classes={classes} 
            description="Must be a U.S. address. PO Boxes are not allowed. Please use the address associated with your Tax ID/EIN number. This address is for verification purposes only. It will not be visible to shoppers."
          >
            <AddressInput
              formValues={formValues}
              handleFormValues={handleFormValues}
              addressLine1={'addressCompanyLine1'}
              addressLine2={'addressCompanyLine2'}
              addressCity={'addressCompanyCity'}
              addressCountry={'addressCompanyCountry'}
              addressState={'addressCompanyState'}
              addressPostalCode={'addressCompanyPostalCode'}
            />
          </InputRow>
          {(formValues.companyType === COMPANY_TYPE.PRIVATE_CORPORATION || formValues.companyType === COMPANY_TYPE.MULTI_MEMBER_LLC) &&
          <InputRow
            label="Company Phone"
            classes={classes}
            description="Must be a U.S. phone number. This phone number is for verification purposes only. It will not be visible to shoppers."
          >
            <MuiPhoneNumber disableAreaCodes fullWidth defaultCountry={'us'}
              onChange={(value) => {
                handleFormValues("companyPhoneNumber", value);
              }
              }
              value={formValues.companyPhoneNumber}
            />
          </InputRow>
          }
          <InputRow
            label="Tax ID / EIN"
            classes={classes}
            description="Please enter your full Tax ID or EIN number. This is for Stripe verification and tax purposes only. It will not be stored in our databases. Your data is secure!"
          >
            <TaxIdInput formValues={formValues} handleFormValues={handleFormValues} classes={classes} />
          </InputRow>
          {/* Representative info */}
          <SectionHeaderRow 
            label={`Representative Information`} 
            subHeader="A representative is an individual with significant responsibility to control, manage, or direct the organization."
            classes={classes}
          />
          <InputRow label="First Name" classes={classes}>
            <TextField
              name="firstName"
              onChange={(e) => handleFormValues("firstName", e.target.value)}
              placeholder="First"
              value={formValues.firstName}
              variant="outlined"
              size="small"
              className={classes.inputOutline}
              fullWidth
            />
          </InputRow>
          <InputRow label="Last Name" classes={classes}>
            <TextField
              name="lastName"
              onChange={(e) => handleFormValues("lastName", e.target.value)}
              placeholder="Last"
              value={formValues.lastName}
              variant="outlined"
              size="small"
              className={classes.inputOutline}
              fullWidth
            />
          </InputRow>
          <InputRow label="Relationship with company" classes={classes}>
            <FormControl component="fieldset">
              <RadioGroup
                row={!mobile}
                aria-label="position"
                name="position"
                onChange={(e) => {
                  const newPosition = e.target.value;
                  if (newPosition === "Owner") {
                    handleFormValues("position", newPosition)
                    handleFormValues("title", newPosition)
                  } else {
                    handleFormValues("position", newPosition)
                    handleFormValues("title", '')
                  }
                }}
                value={formValues.position}
              >
                <FormControlLabel
                  value="Owner"
                  control={
                    <Radio
                      style={{
                        color: "var(--mintGreen)",
                        backgroundColor: "transparent",
                      }}
                    />
                  }
                  label="Owner"
                />
                <FormControlLabel
                  value="Representative"
                  control={
                    <Radio
                      style={{
                        color: "var(--mintGreen)",
                        backgroundColor: "transparent",
                      }}
                    />
                  }
                  label="Representative"
                />
              </RadioGroup>
            </FormControl>
          </InputRow>
          {formValues.position === "Representative" &&
          <InputRow
            label="Representative Relationship Title"
            classes={classes}
            description=""
          >
            <TextField
              name="title"
              onChange={(e) => handleFormValues("title", e.target.value)}
              value={formValues.title}
              variant="outlined"
              size="small"
              fullWidth
              className={classes.inputOutline}
            />
          </InputRow>
          }
          {(formValues.companyType === COMPANY_TYPE.MULTI_MEMBER_LLC || formValues.companyType == COMPANY_TYPE.PRIVATE_CORPORATION) &&
          <InputRow
            label="Percentage of Company Owned"
            classes={classes}
            description="How much of an ownership percentage do you have?"
          >
            <PercentageOwnedInput
              formValues={formValues} 
              handleFormValues={handleFormValues} 
              fieldName={"percentageOwned"} 
              classes={classes}
            >
            </PercentageOwnedInput>
          </InputRow>
          }
          <InputRow
            label="Date of Birth"
            classes={classes}
            description="This is for Stripe verification purposes only. It will not be visible to shoppers."
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={'datePicker'}
                label="Select a date"
                disableFuture
                value={`${formValues.dobMonth}/${formValues.dobDay}/${formValues.dobYear}`}
                onChange={(value => {
                  // const formattedDate = `${new String(value.$M+1).padStart(2, '0')}/${new String(value.$D).padStart(2, '0')}/${new String(value.$y).padStart(4, '0')}`;
                  // setNewBirthday(formattedDate);
                  handleFormValues("dobDay", new String(value.$D).padStart(2, '0'))
                  handleFormValues("dobMonth", new String(value.$M + 1).padStart(2, '0'))
                  handleFormValues("dobYear", new String(value.$y).padStart(4, '0'))
                })}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Typography className={classes.descriptionText}>
              You must be 18 years or older to make an account
            </Typography>
          </InputRow>
          <InputRow
            label="Email"
            classes={classes}
            description="This email is for verification purposes only. It will not be visible to shoppers."
          >
            <TextField
              name="email"
              onChange={(e) => handleFormValues("email", e.target.value)}
              value={formValues.email}
              variant="outlined"
              size="small"
              fullWidth
              className={classes.inputOutline}
            />
          </InputRow>
          <InputRow
            label="Phone"
            classes={classes}
            description="Must be a U.S. phone number. This phone number is for verification purposes only. It will not be visible to shoppers."
          >
            <MuiPhoneNumber disableAreaCodes fullWidth defaultCountry={'us'}
              onChange={(value) => {
                handleFormValues("phoneNumber", value);
              }
              }
              value={formValues.phoneNumber}
            />
          </InputRow>
          <InputRow 
            label="Address" 
            classes={classes} 
            description="Must be a U.S. address. PO Boxes are not allowed. This address is for verification purposes only. It will not be visible to shoppers."
          >
            <AddressInput
              formValues={formValues}
              handleFormValues={handleFormValues}
              addressLine1={'addressLine1'}
              addressLine2={'addressLine2'}
              addressCity={'addressCity'}
              addressCountry={'addressCountry'}
              addressState={'addressState'}
              addressPostalCode={'addressPostalCode'}
            />
          </InputRow>
          <InputRow
            label="TIN / SSN"
            classes={classes}
            description={"Please enter your full Social Security Number, or any other Taxpayer Identification Number for your Representative. This is for Stripe verification and tax purposes only. It will not be stored in our databases. Your data is secure!"}
          >
            {/* <Last4SsnInput formValues={formValues} handleFormValues={handleFormValues} fieldName={"last4SSN"} classes={classes} /> */}
            <FullSsnInput formValues={formValues} handleFormValues={handleFormValues} fieldName={"fullSSN"} classes={classes} />
          </InputRow>
          {formValues.owners?.length > 0 && (formValues.companyType === COMPANY_TYPE.MULTI_MEMBER_LLC || formValues.companyType == COMPANY_TYPE.PRIVATE_CORPORATION) && 
            formValues.owners.map((owner, index) => {
              return (
                <>
                  <SectionHeaderRow label={"Owner #"+(index+1)} classes={classes}/>
                  <InputRow label="First Name" classes={classes}>
                    <TextField
                      name={"firstName"+index}
                      onChange={(e) => handleOwnersChange(index, "firstName", e.target.value)}
                      placeholder="First"
                      value={owner.firstName}
                      variant="outlined"
                      size="small"
                      className={classes.inputOutline}
                      fullWidth
                    />
                  </InputRow>
                  <InputRow label="Last Name" classes={classes}>
                    <TextField
                      name={"lastName"+index}
                      onChange={(e) => handleOwnersChange(index, "lastName", e.target.value)}
                      placeholder="Last"
                      value={owner.lastName}
                      variant="outlined"
                      size="small"
                      className={classes.inputOutline}
                      fullWidth
                    />
                  </InputRow>
                  <InputRow
                    label="Email"
                    classes={classes}
                    description="This email is for verification purposes only. It will not be visible to shoppers."
                  >
                    <TextField
                      name={"email"+index}
                      onChange={(e) => handleOwnersChange(index, "email", e.target.value)}
                      value={owner.email}
                      variant="outlined"
                      size="small"
                      fullWidth
                      className={classes.inputOutline}
                    />
                  </InputRow>
                  <InputRow
                    label="Percentage of Company Owned"
                    classes={classes}
                    description="How much of an ownership percentage do you have?"
                  >
                    <PercentageOwnedWithinArrayInput
                      formValues={formValues} 
                      index={index}
                      objectName={"owners"}
                      handleIndexedFormValues={handleOwnersChange} 
                      fieldName={"percentageOwned"} 
                      classes={classes}
                    >
                    </PercentageOwnedWithinArrayInput>
                  </InputRow>
                  <InputRow classes={classes}>
                    <Button variant="contained" sx={{ backgroundColor: "var(--red)" }} onClick={() => handleRemoveOwner(index)}>
                      Remove
                    </Button>
                  </InputRow>
                </>
              );
            })
          }
          {(formValues.companyType === COMPANY_TYPE.MULTI_MEMBER_LLC || formValues.companyType == COMPANY_TYPE.PRIVATE_CORPORATION) && 
          <InputRow classes={classes}>
            <Button variant="contained" sx={{ backgroundColor: "var(--mintGreenSolid)" }} onClick={() => handleAddOwner()}>
              Add another owner
            </Button>
          </InputRow>
          }

        </>
        :
        <>
        </>

      }
      </Grid>
      {/* <Typography>
        <b><u>ENDS HERE</u></b>
      </Typography>
      {formValues?.businessType == 'company' &&
        <Grid container direction="column" spacing={3}>
          <InputRow
            label="Company Tax ID"
            classes={classes}
            description="This is your company's Tax ID. It is required when setting up a shop of this type."
          >
            <TaxIdInput formValues={formValues} handleFormValues={handleFormValues} classes={classes} />
          </InputRow>
          <InputRow
            label="Percentage of Company Owned"
            classes={classes}
            description="How much of an ownership percentage do you have?"
          >
            <TextField
              name="percentageOwned"
              onChange={(e) => handleFormValues("percentageOwned", e.target.value)}
              value={formValues.percentageOwned}
              variant="outlined"
              type="number"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 5 }}
              size="small"
              fullWidth
              className={classes.inputOutline}
            />
          </InputRow>
          <InputRow
            label="Representative"
            classes={classes}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox defaultChecked={formValues.representative ? true : false} onChange={e => {
                    handleFormValues("representative", e.target.value == 'on' ? true : false);
                  }}
                  />
                } label="Are you authorized as the primary representative of the account?"
              />
            </FormGroup>
          </InputRow>
          <InputRow
            label="Owner Phone No."
            classes={classes}
          >
            <MuiPhoneNumber disableAreaCodes fullWidth defaultCountry={'us'}
              onChange={(value) => {
                handleFormValues("ownerPhoneNumber", value);
              }
              }
              value={formValues.ownerPhoneNumber}
            />
          </InputRow>
          <InputRow label="Owner Address" classes={classes} description="">
            <Grid container direction="row" columnSpacing={2} rowSpacing={1}> */}
              {/* <Grid item xs={12} sm={6}>
              <TextField
                label={"First Name"}
                variant="filled" 
                onChange={(e) => {handleAddressInput("firstName", e.target.value);}} 
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={"Last Name"}
                variant="filled" 
                onChange={(e) => {handleAddressInput("lastName", e.target.value);}} 
                fullWidth
              />
            </Grid> */}
              {/* <Grid item xs={12}>
                <TextField
                  label={"Address"}
                  name="address"
                  onChange={(e) => { handleFormValues("addressOwnerLine1", e.target.value); }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={formValues.addressOwnerLine1}
                  className={classes.inputOutline}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={"Apt, Suite, etc."}
                  name="apartment"
                  onChange={(e) => { handleFormValues("addressOwnerLine2", e.target.value); }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={formValues.addressOwnerLine2}
                  className={classes.inputOutline}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={"City"}
                  name="city"
                  onChange={(e) => {
                    handleFormValues("addressOwnerCity", e.target.value);
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={formValues.addressOwnerCity}
                  className={classes.inputOutline}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  label={"Country"}
                  name="country"
                  disabled={true}
                  onChange={(e) => { handleFormValues("addressOwnerCountry", e.target.value); }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  // defaultValue={formValues.addressOwnerCountry}
                  value={'United States'}
                  className={classes.inputOutline}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  label={"State (2 letters)"}
                  name="state"
                  onChange={(e) => { handleFormValues("addressOwnerState", e.target.value); }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={formValues.addressOwnerState}
                  className={classes.inputOutline}
                />
              </Grid>
              <Grid item xs={8} sm={4}>
                <TextField
                  label={"ZIP Code"}
                  name="postcode"
                  onChange={(e) => { handleFormValues("addressOwnerPostalCode", e.target.value); }}
                  variant="outlined"
                  type="number"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10 }}
                  size="small"
                  fullWidth
                  value={formValues.addressOwnerPostalCode}
                  className={classes.inputOutline}
                />
              </Grid>
            </Grid>
          </InputRow>
          <InputRow
            label={"Company Verification"}
            description=""
            classes={classes}
          >
            <div style={{ height: "12px" }} />
            <Grid container direction="row">
              <Grid item xs={12}>
                <Typography className={classes.descriptionText}>
                  <i>{`For companies such as LLCs and Corps, please upload formation documents, ie) Articles of Incorporation.`}</i>
                </Typography>
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.descriptionText}>
                  <b>Front</b>
                </Typography>
                <Button
                  variant="outlined"
                  className={classes.uploadButton}
                  onClick={() => {
                    triggerUpload(verificationCompanyFrontRef);
                  }}
                >
                  <Typography>upload: pdf, jpg, png</Typography>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.descriptionText}>
                  Drag and drop a file. Or, browse your computer. (10 MB Maximum total of all images uploaded)
                </Typography>
                <Typography className={classes.fileSelectedText}>
                  {formValues?.verificationCompanyFront &&
                    <b>{`File Selected: ${formValues?.verificationCompanyFront[0]?.split('name=')[1]?.split(';')[0]}`}</b>
                  }
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {verificationCompanyFrontError && (
                  <Typography>
                    {verificationCompanyFrontError.type === UPLOAD_ERROR.FILESIZE_TOO_LARGE
                      ? "Max file size exceeded"
                      : "Illegal file type"}
                  </Typography>
                )}
              </Grid>
              <FileUpload
                setRef={(input) => (verificationCompanyFrontRef = input)}
                setError={setVerificationCompanyFrontError}
                propertyName={"verificationCompanyFront"}
                maxFiles={1}
                accept={".pdf,image/*"}
                filetypes={[".pdf", ".jpg", ".jpeg", ".png"]}
                maxFileSize={MAX_FILE_SIZE}
                updateState={handleFormValues}
              />
            </Grid>
            <div style={{ height: "12px" }} />
            <Grid container direction="row">
              <Typography className={classes.descriptionText}>
                <b>{`Back (If no back page, upload the same documents twice)`}</b>
              </Typography>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  className={classes.uploadButton}
                  onClick={() => {
                    triggerUpload(verificationCompanyBackRef);
                  }}
                >
                  <Typography>upload: pdf, jpg, png</Typography>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.descriptionText}>
                  Drag and drop a file. Or, browse your computer. (10 MB Maximum total of all images uploaded)
                </Typography>
                <Typography className={classes.fileSelectedText}>
                  {formValues?.verificationCompanyBack &&
                    <b>{`File Selected: ${formValues?.verificationCompanyBack[0]?.split('name=')[1]?.split(';')[0]}`}</b>
                  }
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {verificationCompanyBackError && (
                  <Typography>
                    {verificationCompanyBackError.type === UPLOAD_ERROR.FILESIZE_TOO_LARGE
                      ? "Max file size exceeded"
                      : "Illegal file type"}
                  </Typography>
                )}
              </Grid>
              <FileUpload
                setRef={(input) => (verificationCompanyBackRef = input)}
                setError={setVerificationCompanyBackError}
                propertyName={"verificationCompanyBack"}
                maxFiles={1}
                accept={".pdf,image/*"}
                filetypes={[".pdf", ".jpg", ".jpeg", ".png"]}
                maxFileSize={MAX_FILE_SIZE}
                updateState={handleFormValues}
              />
            </Grid>
          </InputRow>
        </Grid>
      } */}
    </div>
  );
}



// ID verification code if we want to add it back
{/* <InputRow
            label={"Verification"}
            // description={
            //   "Please upload ID Card for individual or owner."
            // }
            classes={classes}
          >
            <Typography>Please upload ID Card for individual or owner.</Typography>
            <div style={{height: "12px"}}/>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Typography className={classes.descriptionText}>
                <b>Front</b>
                </Typography>
                <Button
                  variant="outlined"
                  className={classes.uploadButton}
                  onClick={() => {
                    triggerUpload(verificationFrontRef);
                  }}
                >
                  <Typography>upload: pdf, jpg, png</Typography>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.descriptionText}>
                  Drag and drop a file. Or, browse your computer. (10 MB Maximum total of all images uploaded)
                </Typography>
                <Typography className={classes.fileSelectedText}>
                  {formValues?.verificationFront &&
                  <b>{`ID File Selected: ${formValues?.verificationFront[0]?.split('name=')[1]?.split(';')[0]}`}</b>
                  }
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {verificationFrontError && (
                  <Typography>
                    {verificationFrontError.type === UPLOAD_ERROR.FILESIZE_TOO_LARGE
                      ? "Max file size exceeded"
                      : "Illegal file type"}
                  </Typography>
                )}
              </Grid>
              <FileUpload
                setRef={(input) => (verificationFrontRef = input)}
                setError={setVerificationFrontError}
                propertyName={"verificationFront"}
                maxFiles={1}
                accept={".pdf,image/*"}
                filetypes={[".pdf", ".jpg", ".jpeg", ".png"]}
                maxFileSize={MAX_FILE_SIZE}
                updateState={handleFormValues}
              />
            </Grid>
            <div style={{height: "12px"}}/>
            <Grid container direction="row">
              <Typography className={classes.descriptionText}>
                <b>Back</b>
              </Typography>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  className={classes.uploadButton}
                  onClick={() => {
                    triggerUpload(verificationBackRef);
                  }}
                >
                  <Typography>upload: pdf, jpg, png</Typography>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.descriptionText}>
                  Drag and drop a file. Or, browse your computer. (10 MB Maximum total of all images uploaded)
                </Typography>
                <Typography className={classes.fileSelectedText}>
                  {formValues?.verificationBack &&
                  <b>{`ID File Selected: ${formValues?.verificationBack[0]?.split('name=')[1]?.split(';')[0]}`}</b>
                  }
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {verificationBackError && (
                  <Typography>
                    {verificationBackError.type === UPLOAD_ERROR.FILESIZE_TOO_LARGE
                      ? "Max file size exceeded"
                      : "Illegal file type"}
                  </Typography>
                )}
              </Grid>
              <FileUpload
                setRef={(input) => (verificationBackRef = input)}
                setError={setVerificationBackError}
                propertyName={"verificationBack"}
                maxFiles={1}
                accept={".pdf,image/*"}
                filetypes={[".pdf", ".jpg", ".jpeg", ".png"]}
                maxFileSize={MAX_FILE_SIZE}
                updateState={handleFormValues}
              />
            </Grid>
          </InputRow> */}

export default OpenAShopPayments;
