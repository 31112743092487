import { AccordionDetails, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import { ExpandLess, ExpandMore, Edit } from "@mui/icons-material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/styles";
import { getListOfProductCategoriesById } from "../util/productCategories";
import { Grid } from "@mui/material";

//Default Prop Values
import categoryMenuDataDefault from "../json/category_menu.json";
import colors from "../json/colors.json";

const useStyles = makeStyles({
  subcategoryLink: {
    textDecoration: "none",
    color: "black",
  },
  editButton: {
    color: "#FFFFFF",
    backgroundColor: "var(--coral)",
    textTransform: "none",
    paddingLeft: 15,
    paddingRight: 15,
    width: "100%",
    marginTop: 25,
    "&:hover": {
      backgroundColor: "var(--coral)",
    },
  },
  editButtonText: {
    marginLeft: 10,
  },
});

const Accordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    paddingLeft: 0,
    marginBottom: -1,
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const StyledAccordianDetails = styled(AccordionDetails)({
  paddingBottom: 8,
  paddingLeft: 0,
});

const BrowseByText = styled(Typography)({
  minHeight: 40,
});

const CategoryText = styled(Typography)({
  marginRight: 5,
});

const VerticallyCenterDiv = styled("div")({
  display: "flex",
  alignItems: "center",
});

function CategoryMenu(props) {
  let classes = useStyles();

  const { categoryData, expandedCategory = 0, admin = false } = props;

  const [expanded, setExpanded] = React.useState(Number(expandedCategory));
  const [categoryMenuData, setCategoryMenuData] = React.useState([]);

  React.useEffect(() => {
    if (!categoryData || categoryData.length === 0) {
      getListOfProductCategoriesById(categoryMenuDataDefault, (data) => {
        if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
          setCategoryMenuData(data);
          
        }
      });
    } else {
      setCategoryMenuData(categoryData);
    }
  }, [categoryData]);

  const handleChange = (category) => (event, newExpanded) => {
    setExpanded(newExpanded ? category : false);
  };

  return (
    <div>
      <BrowseByText variant="h5" className={"roboto-black"}>
        Browse By
      </BrowseByText>
      {categoryMenuData.map((category, index) => {
        return (
          <Accordion
            square
            expanded={expanded === category.id}
            onChange={handleChange(category.id)}
            key={index}
            className={classes.accordion}
          >
            <AccordionSummary>
              <VerticallyCenterDiv>
                <Typography variant="h6" className={"roboto-bold"}>
                  {category.name}
                </Typography>
                {expanded === category.id ? (
                  <ExpandLess fontSize={"large"} />
                ) : (
                  <ExpandMore fontSize={"large"} />
                )}
              </VerticallyCenterDiv>
            </AccordionSummary>
            {category.hasOwnProperty("subcategories") ? category.subcategories.map((subcategory, index) => {
              return (
                <StyledAccordianDetails key={index}>
                  <a
                    href={
                      "/shop/category/" + category.id + "/" + category.name.replace(/\s/g, '-') + "/" + subcategory.id + "/" + subcategory.name.replace(/\s/g, '-')
                    }
                    className={classes.subcategoryLink}
                  >
                    <Typography variant="h2" className={"roboto-regular"} sx={{fontSize: "1.1rem"}}>
                      {subcategory.name}
                    </Typography>
                  </a>
                </StyledAccordianDetails>
              );
            }) : category.hasOwnProperty("subpurposes") ? category.subpurposes.map((subpurpose, index) => {
              return (
                <StyledAccordianDetails key={index}>
                  <a
                    href={
                      "/shop/purpose/" + category.id + "/" + category.name.replace(/\s/g, '-') + "/" + subpurpose.id + "/" + subpurpose.name.replace(/\s/g, '-')
                    }
                    className={classes.subcategoryLink}
                  >
                    <Typography variant="h2" className={"roboto-regular"} sx={{fontSize: "1.1rem"}}>
                      {subpurpose.name}
                    </Typography>
                  </a>
                </StyledAccordianDetails>
              );
            }) : <></>}
          </Accordion>
        );
      })}
      {/* <Accordion
        square
        expanded={expanded === 'color'}
        onChange={handleChange('color')}
        className={classes.accordion}
      >
        <AccordionSummary>
          <VerticallyCenterDiv>
            <Typography variant="h6" className={"roboto-bold"}>
              Color
            </Typography>
            {expanded === 'color' ? (
              <ExpandLess fontSize={"large"} />
            ) : (
              <ExpandMore fontSize={"large"} />
            )}
          </VerticallyCenterDiv>
        </AccordionSummary>
        {colors.map((elem, index) => {
          return (
            <StyledAccordianDetails>
              <Grid container columnGap={2}>
              <Grid item xs={2}>
              <div style={{width: "24px", height: "24px", background: elem.hex, borderRadius: "50%"}}></div>
              </Grid>
              <Grid item xs={6}>
              <Typography variant="h7" className={"roboto-regular"}>
                {elem.color}
              </Typography>
              </Grid>
              </Grid>
            </StyledAccordianDetails>
          )
        })}
      </Accordion> */}
      {admin && (
        <Button className={classes.editButton}>
          <Edit />
          <Typography className={classes.editButtonText}>
            <b>Edit Categories</b>
          </Typography>
        </Button>
      )}
    </div>
  );
}

export default CategoryMenu;
