import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { round } from "../util/utilities";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  noOrdersCell: {
    border: "none",
  },
  tableRows: {
    backgroundColor: "var(--lightBeige)",
  },
}));

const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        size="large">
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        size="large">
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="large">
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        size="large">
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const AccountReturnsAndRefundsView = (props) => {
  const { returnsAndRefundsData } = props;
  const classes = useStyles1();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows = returnsAndRefundsData
    ? rowsPerPage -
      Math.min(rowsPerPage, returnsAndRefundsData.length - page * rowsPerPage)
    : 4;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={"string"} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography>
                  <b>Order No.</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>
                  <b>Date</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>
                  <b>Status</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>
                  <b>Total $</b>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {returnsAndRefundsData ? (
              (rowsPerPage > 0
                ? returnsAndRefundsData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : returnsAndRefundsData
              ).map((row) => (
                <TableRow key={row.name} className={classes.tableRows}>
                  <TableCell component="th" scope="row" align="center">
                    <Typography>
                      <b>{row.order}</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      <b>{row.date}</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      <b>{row.status}</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      <b>${round(row.total,2)}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow className={classes.tableRows}>
                <TableCell
                  align="center"
                  colSpan={4}
                  className={classes.noOrdersCell}
                >
                  <Typography>
                    <b>No Orders</b>
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{ height: 53 * emptyRows }}
                className={classes.tableRows}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow className={classes.tableRows}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={4}
                count={returnsAndRefundsData ? returnsAndRefundsData.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default AccountReturnsAndRefundsView;
