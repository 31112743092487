import * as React from 'react';
import { Avatar, ButtonBase, Container, Typography, FormHelperText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";
import clsx from "clsx";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

//Default Prop Values
import featuredProductsDefault from "../json/loyalty_program_redeem_products_data.json";

const useStyles = makeStyles({
  rootBackground: {
    marginTop: "12px",
  },
  imagePortrait: {
    height: 360,
    width: "auto",
    marginBottom: 15,
    margin: "auto",
  },
  gutterBottom: { marginBottom: 30 },
  navButtons: {
    position: "absolute",
    top: "39%",
    height: 75,
    border: "1px solid var(--mintArrow)",
    borderRadius: 15,
    backgroundColor: "#FFFFFF",
  },
  prevButton: {
    left: -33,
  },
  nextButton: {
    right: -33,
  },
  divContainer: {
    position: "relative",
    width: "100%",
    margin: "auto",
  },
  arrows: {
    color: "var(--mintArrow)",
    fontSize: 30,
  },
  carouselContainer: {
    width: "100%",
  },
});


const LoyaltyProgramCartRedeemProducts = ({ featuredProducts = featuredProductsDefault }) => {
  const classes = useStyles();
  const [product, setProduct] = React.useState('');
  // const [featuredProducts, setFeaturedProducts] = React.useState(featuredProductsDefault);

  const handleChange = (event) => {
    setProduct(event.target.value);
  };

  return (
    <div className={classes.rootBackground}>
      <Container maxWidth="xl">
        <div className={classes.divContainer}>
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Redeem for Products</InputLabel>
            <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={product}
            label="Redeem for Products"
            onChange={handleChange}
            >
            <MenuItem value="">&nbsp;</MenuItem>
            {featuredProducts.map((item, i) => { 
                return (
                    <MenuItem value={i}> {item.product} - {item.points} points</MenuItem>
                )
            })}
            </Select>
            </FormControl>
        </div>
      </Container>
    </div>
  );
};

export default LoyaltyProgramCartRedeemProducts;
