import { Grid, Typography, Container, Button } from "@mui/material";
import BlogPost from "./BlogPost.js";
import { Edit } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import React from "react";
import { getSellerById } from "../util/sellers.js";

const numBlogPosts = 3;

const EditButtonDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  position: "relative",
  bottom: 77,
});

const EditButton = styled(Button)({
  color: "#FFFFFF",
  backgroundColor: "var(--coral)",
  textTransform: "none",
  paddingLeft: 15,
  paddingRight: 15,
  "&:hover": {
    backgroundColor: "var(--coral)",
  },
});

const EditButtonText = styled(Typography)({
  marginLeft: 10,
});

function Blog(props) {
  const { blogPosts, admin = false } = props;
  const { id } = useParams();
  const DEFAULT_ID = "22963625-1ae2-41d1-9041-97a8111c74a0";

  const [blogData, setBlogData] = React.useState({});

  React.useEffect(() => {
    if (!blogPosts) {
      getSellerById(id, (data) => {
        if (
          data &&
          !data.hasOwnProperty("error") &&
          data.hasOwnProperty("legacyLayoutData")
        ) {
          setBlogData(data.legacyLayoutData.blog);
        } else {
          // Fetch default
          getSellerById(DEFAULT_ID, (data) => {
            if (
              data &&
              !data.hasOwnProperty("error") &&
              data.hasOwnProperty("legacyLayoutData")
            ) {
              setBlogData(data.legacyLayoutData.blog);
            }
          });
        }
      });
    } else {
      setBlogData(blogPosts);
    }
  }, [id, blogPosts]);

  return (
    <Container maxWidth="xl">
      {/* <Typography display="inline" variant="h5" className={"roboto-medium"}>
        {blogPosts.header}
      </Typography>
      <Typography display="inline" variant="h5" className={"roboto-regular"}>
        &nbsp;{blogPosts.subheader}
      </Typography>
      <Typography
        variant="subtitle1"
        className={"roboto-regular"}
        style={{ marginBottom: admin ? 14 : 50 }}
      >
        {blogPosts.subtitle}
      </Typography>
      {admin && (
        <EditButtonDiv>
          <EditButton>
            <Edit />
            <EditButtonText>
              <b>Edit Blogs</b>
            </EditButtonText>
          </EditButton>
        </EditButtonDiv>
      )}
      <Grid container rowSpacing={5} columnSpacing={4} direction="row">
        {blogData &&
          blogData.posts &&
          blogData.posts.map((blogPost, index) => {
            if (index < numBlogPosts) {
              return <BlogPost key={index} blogPost={blogPost} />;
            }
            return <></>;
          })}
      </Grid> */}
    </Container>
  );
}

export default Blog;
