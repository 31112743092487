import { getSessionJwt, getJsonCache, setJsonCache } from "./utilities";

const {
  NODE_API_ENDPOINT
} = require("./constants");

async function requestRefund(orderID, itemsToReturn, callback) {
  const jwtToken = await getSessionJwt();
  const reqBody = {
    orderID: orderID,
    itemsToReturn: itemsToReturn,
  }
  fetch(NODE_API_ENDPOINT + "/request-refund", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function refundByReturnId(sellerID, returnID, callback) {
  const jwtToken = await getSessionJwt();
  const reqBody = {
    sellerID, 
    returnID: returnID
  }
  fetch(NODE_API_ENDPOINT + "/refund-order-by-return-id", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function refundByOrderItems(sellerID, orderID, itemsToReturn, callback) {
  const jwtToken = await getSessionJwt();
  const reqBody = {
    sellerID, 
    orderID: orderID,
    itemsToReturn: itemsToReturn
  }
  fetch(NODE_API_ENDPOINT + "/refund-order-items", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

// async function refundFullOrderByOrderId(sellerID, orderID, callback) {
//   
//   const jwtToken = await getSessionJwt();
//   const reqBody = {
//     sellerID, 
//     orderID: orderID
//   }
//   fetch(NODE_API_ENDPOINT + "/refund-full-order", {
//     method: "POST",
//     headers: {
//       "Authorization": 'Bearer ' + jwtToken,
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(reqBody),
//   })
//     .then((response) => response.json())
//     .then(
//       (data) => callback(data),
//       (error) => {
//         callback("Failed");
//         console.log(error);
//       }
//     );
// }

async function denyRefundRequestByReturnId(sellerID, returnID, denyReason, callback) {
  const jwtToken = await getSessionJwt();
  const reqBody = {
    sellerID, 
    returnID,
    denyReason,
  }
  fetch(NODE_API_ENDPOINT + "/deny-refund-request-by-return-id", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

export { requestRefund, refundByReturnId, refundByOrderItems, denyRefundRequestByReturnId };
