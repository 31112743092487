import { Container, Divider, Grid, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";

//JSON filler ----------------
const quotes = {
  quote:
    "All the darkness in the world cannot extinguish the light from a single candle.",
  author: "St. Francis Of Assisi",
};

const useStyles = makeStyles({
  paper: {
    padding: 15,
    maxWidth: 450,
    backgroundColor: "var(--lightMintGreen)",
    margin: "40px auto",
    minHeight: 168.984,
  },
  paperBeige: {
    display: "flex",
    flexDirection: "column",
    padding: 15,
    maxWidth: 450,
    backgroundColor: "var(--lightBeige)",
    margin: "40px auto",
    overflow: "hidden",
    minHeight: 168.984,
    position: "relative",
    justifyContent: "center",
  },
});

const QuotePurposerDefinition = (props) => {
  const { quote = quotes } = props;
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <div style={{ backgroundColor: "var(--beige)", width: "100%" }}>
            <Container style={{ height: "100%", overflow: "hidden" }}>
              <Paper className={classes.paperBeige}>
                <div style={{ maxWidth: 300, margin: "auto" }}>
                  <Typography gutterBottom align="center">
                    <i>
                      <b>"{quote.quote}"</b>
                    </i>
                  </Typography>
                  <Typography align="center">
                    <i>
                      <b>- {quote.author}</b>
                    </i>
                  </Typography>
                </div>
              </Paper>
            </Container>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div style={{ backgroundColor: "var(--mintGreen)", width: "100%" }}>
            <Container style={{ height: "100%", overflow: "hidden" }}>
              <Paper className={classes.paper}>
                <Typography>
                  <u>Definition:</u> <b>purposer</b>
                </Typography>
                <div style={{ display: "flex" }}>
                  <Typography>
                    [pur{bull}puhs{bull}er]
                  </Typography>
                  <RecordVoiceOverOutlinedIcon className={classes.icon} />
                </div>
                <Divider />
                <Typography>noun</Typography>
                <div>
                  <Typography>
                    1.a. someone who acts with a purpose oriented towards the
                    greater good in life.
                  </Typography>
                  <Typography>b. entrepreneur with purpose</Typography>
                  <Typography>c. someone who brings light to others</Typography>
                </div>
              </Paper>
            </Container>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default QuotePurposerDefinition;
