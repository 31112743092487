import React, { useState } from "react";
import { Typography, AccordionDetails, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { Remove as RemoveIcon, Add as AddIcon } from "@mui/icons-material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { getSellerById } from "../util/sellers";

const useStyles = makeStyles({
  iconsDiv: {
    backgroundColor: "var(--mintGreen)",
    color: "white",
    marginRight: 10,
    borderRadius: 5,
    width: "34px",
    height: "32px",
  },
  icons: {
    margin: [[4, 5, 0, 5]],
  },
  questionText: {
    lineHeight: 2,
    fontSize: "1rem",
  },
  answerText: {
    marginLeft: 44,
  },
});

const Accordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    "&$expanded": {
      margin: "5px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const ExpandableQuestionsView = ({ questions }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState("");
  const { id } = useParams();
  const DEFAULT_ID = "22963625-1ae2-41d1-9041-97a8111c74a0";

  const [questionData, setQuestionData] = React.useState([]);

  React.useEffect(() => {
    if (!questions) {
      getSellerById(id, (data) => {
        if (
          data &&
          !data.hasOwnProperty("error") &&
          data.hasOwnProperty("legacyLayoutData")
        ) {
          setQuestionData(data.legacyLayoutData.faq);
        } else {
          // Fetch default
          getSellerById(DEFAULT_ID, (data) => {
            if (
              data &&
              !data.hasOwnProperty("error") &&
              data.hasOwnProperty("legacyLayoutData")
            ) {
              setQuestionData(data.legacyLayoutData.faq);
            }
          });
        }
      });
    } else {
      setQuestionData(questions);
    }
  }, [id, questions]);

  const handleChange = (category) => (event, newExpanded) => {
    setExpanded(newExpanded ? category : false);
  };

  return (
    <Container maxWidth="xl">
      {questionData &&
        questionData.map((item, index) => {
          return (
            <Accordion
              square
              expanded={expanded === item.question}
              onChange={handleChange(item.question)}
              key={index}
              className={classes.accordion}
            >
              <AccordionSummary>
                <div className={classes.iconsDiv}>
                  {expanded === item.question ? (
                    <RemoveIcon className={classes.icons} />
                  ) : (
                    <AddIcon className={classes.icons} />
                  )}
                </div>
                <Typography
                  variant="h3"
                  className={clsx(classes.questionText, "roboto-bold")}
                >
                  {index + 1}. {item.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Typography
                  variant="body1"
                  className={clsx(classes.answerText, "roboto-regular")}
                >
                  {item.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Container>
  );
};

export default ExpandableQuestionsView;
