import { useRef } from "react";
import { Container, Typography, Divider, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandableQuestionsView from "../../components/ExpandableQuestionsView";
import { PurposeCategoryMasterLayout } from "../../layouts";

import shipReturnBoxes from "../../img/shippingAndReturnsBoxes.png";
import TrackOrderShipment from "../../components/TrackOrderShipment";

import clsx from "clsx"
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useDocumentDescription from "../../hooks/useDocumentDescription";

const useStyles = makeStyles({
  headerImg: {
    width: "100%",
    maxHeight: "100%",
  },
  headerImgDiv: {
    width: 225,
    maxHeight: "100%",
  },
  bodyText1: {
    fontSize: "1.5rem"
  },
  header: {
    width: "100%",
    height: "auto",
    backgroundColor: "var(--mintGreenTransparent)",
    padding: "64px 0px"
  },
  marginBottomBody: {
    marginBottom: "12px"
  },
  link: {
    color: "blue",
    textDecoration: "underline"
  }
});

const Title = (props) => {
  const { title } = props;
  return (
    <div style={{ marginBottom: "24px" }}>
      <Typography 
        variant="h3" 
        gutterBottom
        style={{fontSize: "2.1rem"}}
      >
        <b>{title}</b>
      </Typography>
      <div>
        {props.children}
      </div>
    </div>
  )
}

const TermsOfUseLayout = () => {
  const classes = useStyles();
  const contentStandardsRef = useRef(null);
  const goToContentStandards = (id) => {
    contentStandardsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useDocumentTitle("Terms of Use");
  useDocumentDescription("Shop for ethically produced gifts, holiday gifts, skincare, toys, food, and more.");

  return (
    <PurposeCategoryMasterLayout>
      <Divider />

      <div className={classes.header}>
        <Container maxWidth="xl" align="center">
          <Typography
            variant="h1"
            style={{ fontSize: "4rem" }}
          >
            <b>{`Purposer Terms & Conditions`}</b>
          </Typography>
          <Typography 
            variant="h2"
            style={{ fontSize: "1.75rem", marginTop: "12px" }}
          >
            <i>Last modified: January, 13, 2023.</i>
          </Typography>
        </Container>
      </div>

      <div style={{ height: "var(--dividerHeight)" }} />
      <Container maxWidth="md" >
        <Title title="Acceptance of the Terms of Use">
          <Typography className={classes.marginBottomBody}>
            These terms of use are entered into by and between You and Purposer ("<b>Company</b>," "<b>we</b>," or "<b>us</b>"). The following terms and conditions ("<b>Terms of Use</b>") govern your access to and use our online marketplace platform, including any content, functionality and services offered on or through purposer.com ("<b>Purposer</b>"), whether as a guest or a registered user.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            Please read the Terms of Use carefully before you start to use Purposer. <b>By using Purposer, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at <a href="/privacypolicy" className={classes.link}>www.purposer.com/privacypolicy</a> and <a href="/termsofuse" className={classes.link}>www.purposer.com/termsofuse</a>, incorporated herein by reference.</b> If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use Purposer.
          </Typography>
          <Typography>
            Purposer is offered and available to users who are 18 years of age or older. By using Purposer, you represent and warrant that you are of legal age to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use Purposer.
          </Typography>
        </Title>
        <Title title="Changes to the Terms of Use">
          <Typography className={classes.marginBottomBody}>
            We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of Purposer thereafter.
          </Typography>
          <Typography>
            Your continued use of Purposer following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page each time you access Purposer so you are aware of any changes, as they are binding on you.
          </Typography>
        </Title>
        <Title title="Accessing Purposer and Account Security">
          <Typography className={classes.marginBottomBody}>
            We reserve the right to withdraw or amend Purposer, and any service or material we provide on Purposer, in our sole discretion without notice. We will not be liable if for any reason all or any part of Purposer is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Purposer, or the entire Purposer, to users, including registered users.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            You are responsible for both:
            <ul>
              <li>Making all arrangements necessary for you to have access to Purposer.</li>
              <li>Ensuring that all persons who access Purposer through your internet connection are aware of these Terms of Use and comply with them.</li>
            </ul>
          </Typography>
          <Typography>
            To access Purposer or some of the resources it offers, you will have to create an account (an "<b>Account</b>"). You agree to provide accurate, current and complete information during the registration process and to update such information to keep it accurate, current and complete. You are responsible for safeguarding your account, so use a strong password and limit its use to this account. You accept responsibility for any and all activities or actions that occur under your account, whether or not you have authorized such activities or actions.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to Purposer or portions of it using your user name, password or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.
          </Typography>
        </Title><Title title="Email and Text Notification">

          <Typography className={classes.marginBottomBody}>
          By submitting your information on homepage popups & email or text boxes throughout the Purposer, you agree to receive emails and text marketing notifications from us. You may opt out at any time. Consent to receive messages is not required as a condition of any purchase. For SMS, reply STOP to cancel. Message and data rates may apply. Discount codes may expire after 3 months and may only be used once.          </Typography>
        </Title>
        <Title title="Product Listings for Sellers">
          <Typography className={classes.marginBottomBody}>
            Purposer allows users to list and sell items (each a "<b>Product</b>"). As a seller, you are responsible for the accuracy, content, and legality of the Products you offer. As a seller, you understand that there are certain types of items that are prohibited from being sold on Purposer. Prohibited items include, but are not limited to:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>Alcohol, tobacco, drugs, CBD, and drug paraphernalia</li>
              <li>Dangerous items such as weapons or explosives</li>
              <li>Illegal items, including items that infringe on the intellectual property rights of others</li>
              <li>Items that promote hate or violence towards any individual or group</li>
              <li>Any items that involve lewd or mature content</li>
            </ul>
          </Typography>
          <Typography className={classes.marginBottomBody}>
            As a seller, you agree not to list or sell any of these prohibited items on Purposer and understand that any violation of this rule may result in the termination of your account. The Company reserves the right to remove any prohibited items from Purposer, at any time, without notice.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            By listing a Product, you grant the Company a non-exclusive, worldwide, royalty-free, sublicensable and transferable license to use, copy, modify, distribute, publish and process the listing, as well as all information, data, text, software, music, sound, photographs, graphics, video, messages, tags, or other materials ("<b>Content</b>") associated with the Product, without any further consent, notice and/or compensation to you or to any third parties. Furthermore, you grant the Company the right to use seller information, including name and the images and details of your Products, to market and advertise Purposer on Google, social media, and other third-party sites. This includes the use of your Products and images for promotional materials and campaigns. The Company may also feature your Products in email campaigns and other marketing communications. You understand that you will not receive any additional compensation for the use of your information or Products in these marketing and advertising efforts.
          </Typography>
        </Title>
        <Title title="Purchasing Products for Buyers">
          <Typography className={classes.marginBottomBody}>
            As a buyer, you acknowledge and agree that the Company is not the seller of the Products listed on Purposer. The items are sold directly by individual sellers and the Company may not pre-screen or approve all items. You understand that each seller may have their own processing times, shipping methods, and policies. The Company is not responsible for any issues related to the processing, shipping, or returns of items purchased through Purposer. It is the buyer's responsibility to review the seller's policies before making a purchase and to communicate with the seller directly if there are any issues with the order.
          </Typography>
        </Title>
        <Title title="Ratings and Reviews">
          <Typography className={classes.marginBottomBody}>
            Buyers may leave reviews on Products purchased through Purposer. These reviews must be honest, accurate and respectful. Inappropriate or false reviews, such as those containing hate speech, personal attacks, or discriminatory language, will be removed and may result in suspension or termination of the buyer's account. As a buyer, you agree not to leave fake reviews. Any attempt to manipulate reviews in any way will result in suspension or termination of the buyer's account. The Company reserves the right to remove any reviews that violate these terms, at any time, without notice.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            Sellers are permitted to respond to ratings and reviews left on their Products, but they are prohibited from removing or altering the content of any ratings or reviews (unless buyer violated the <a onClick={goToContentStandards} className={classes.link}>Content Standards</a> set out in these Terms of Use). Sellers may not request or incentivize customers to leave positive ratings or reviews, or to remove or alter negative ratings or reviews. Violation of this policy may result in the suspension or termination of the seller's account.
          </Typography>
        </Title>
        <Title title="Intellectual Property Rights">
          <Typography className={classes.marginBottomBody}>
            Purposer and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by the Company, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.
          </Typography>
        </Title>
        <Title title="Trademarks">
          <Typography className={classes.marginBottomBody}>
            The Company name, the Company logo and all related names, logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs and slogans on Purposer are the trademarks of their respective owners.
          </Typography>
        </Title>
        <Title title="Prohibited Uses">
          <Typography className={classes.marginBottomBody}>
            You may use Purposer only for lawful purposes and in accordance with these Terms of Use. You agree not to use Purposer:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>In any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries). </li>
              <li>For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.</li>
              <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with the <a onClick={goToContentStandards} className={classes.link}>Content Standards</a> set out in these Terms of Use.</li>
              <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation.</li>
              <li>To impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail addresses or screen names associated with any of the foregoing).</li>
              <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of Purposer, or which, as determined by us, may harm the Company or users of Purposer, or expose them to liability.</li>
            </ul>
          </Typography>
          <Typography className={classes.marginBottomBody}>
            Additionally, you agree not to:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>Use Purposer in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of Purposer, including their ability to engage in real time activities through Purposer.</li>
              <li>Use any robot, spider or other automatic device, process or means to access Purposer for any purpose, including monitoring or copying any of the material on Purposer.</li>
              <li>Use any manual process to monitor or copy any of the material on Purposer, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent.</li>
              <li>Use any device, software or routine that interferes with the proper working of Purposer.</li>
              <li>Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.</li>
              <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of Purposer, the server on which Purposer is stored, or any server, computer or database connected to Purposer.</li>
              <li>Attack Purposer via a denial-of-service attack or a distributed denial-of-service attack.</li>
              <li>Otherwise attempt to interfere with the proper working of Purposer.</li>
            </ul>
          </Typography>
        </Title>
        <Title title="User Contributions">
          <Typography className={classes.marginBottomBody}>
            Purposer may contain chat capabilities, personal profiles, forums, and other interactive features (collectively, "<b>Interactive Services</b>") that allow users to post, submit, publish, display or transmit to other users or other persons (hereinafter, "<b>post</b>") content or materials, including product listings and information provided by Buyers, (collectively, "<b>User Contributions</b>") on or through Purposer.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            All User Contributions must comply with the Content Standards set out in these Terms of Use.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            Any User Contribution you post to the site will be considered non-confidential and non-proprietary. By providing any User Contribution on Purposer, you grant us and our licensees, successors and assigns the right to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material for any purpose.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            You represent and warrant that:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our licensees, successors and assigns.</li>
              <li>All of your User Contributions do and will comply with these Terms of Use.</li>
            </ul>
          </Typography>
          <Typography className={classes.marginBottomBody}>
            You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not the Company, have fully responsibility for such content, including its legality, reliability, accuracy and appropriateness.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            We are not responsible, or liable to any third party, for the content or accuracy of any User Contributions posted by you or any other user of Purposer.
          </Typography>
        </Title>
        <Title title="Monitoring and Enforcement; Termination">
          <Typography className={classes.marginBottomBody}>
            We have the right to:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>Remove or refuse to post any User Contributions for any or no reason in our sole discretion.</li>
              <li>Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of Purposer or the public or could create liability for the Company.</li>
              <li>Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.</li>
              <li>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of Purposer.</li>
              <li>Terminate or suspend your access to all or part of Purposer for any or no reason, including without limitation, any violation of these Terms of Use.</li>
            </ul>
          </Typography>
          <Typography className={classes.marginBottomBody}>
            Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through Purposer. YOU WAIVE AND HOLD HARMLESS THE COMPANY FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER THE COMPANY OR LAW ENFORCEMENT AUTHORITIES.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            However, we do not undertake to review material before it is posted on Purposer, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.
          </Typography>
        </Title>
        <div ref={contentStandardsRef} />
        <Title title="Content Standards" >
          <Typography className={classes.marginBottomBody} >
            These content standards apply to any and all User Contributions Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local and international laws and regulations. Without limiting the foregoing, User Contributions must not:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable.</li>
              <li>Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</li>
              <li>Infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person.</li>
              <li>Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our <a href="/privacy" className={classes.link}>Privacy Policy</a>.</li>
              <li>Be likely to deceive any person.</li>
              <li>Promote any illegal activity, or advocate, promote or assist any unlawful act.</li>
              <li>Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person.</li>
              <li>Impersonate any person, or misrepresent your identity or affiliation with any person or organization.</li>
              <li>Involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising.</li>
              <li>Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.</li>
            </ul>
          </Typography>
        </Title>
        <Title title="Copyright Infrigement">
          <Typography className={classes.marginBottomBody}>
            DMCA Compliance: We respect the intellectual property rights of others and require that our users do the same. If you believe that your work has been copied in a way that constitutes copyright infringement, please provide us with a written notice, at <a href="mailto:info@purposer.com" className={classes.link}>info@purposer.com</a>, containing the following information: (i) identification of the copyrighted work claimed to have been infringed; (ii) identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material; and (iii) your contact information, including your telephone number and an email address.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            Upon receipt of a valid DMCA notice, we will take down the infringing content within a reasonable time. We may also, at our discretion, notify the user who posted the allegedly infringing content and provide them with a copy of the DMCA notice. If the user believes that the content was removed in error, they may file a counter-notification with us.
          </Typography>
        </Title>
        <Title title="Reliance on Information Posted">
          <Typography className={classes.marginBottomBody}>
            The information presented on or through Purposer is made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to Purposer, or by anyone who may be informed of any of its contents.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            Purposer may include content provided by third parties, including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Company. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.
          </Typography>
        </Title>
        <Title title="Changes to Purposer">
          <Typography className={classes.marginBottomBody}>
            We may update the content on Purposer from time to time, but its content is not necessarily complete or up-to-date. Any of the material on Purposer may be out of date at any given time, and we are under no obligation to update such material.
          </Typography>
        </Title>
        <Title title="Information About You and Your Visits to Purposer">
          <Typography className={classes.marginBottomBody}>
            All information we collect on Purposer is subject to our <a href="/privacy" className={classes.link}>Privacy Policy</a>. By using Purposer, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.
          </Typography>
        </Title>
        <Title title="Payment Processing, Transaction Fees, and Sales Tax">
          <Typography className={classes.marginBottomBody}>
            Purposer utilizes Stripe to process all transactions between buyers and sellers. By using the Platform, you agree to be bound by Stripe's terms and conditions and privacy policy, as well as any additional terms that may be required by Stripe. The Company is not responsible for any issues or errors related to the processing of transactions through Stripe. You understand that you are responsible for any fees associated with using Stripe and that the Company does not control or have access to any financial information provided to Stripe.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            As a seller, you understand that the Company will charges a seller fee on each transaction made through Purposer. Such fee will be communicated to you via seller dashboard. This fee will be automatically deducted from the sale amount before the seller receives the payment. By listing items for sale on Purposer, you agree to this transaction fee and understand that it is non-negotiable. The Company reserves the right to change the transaction fee at any time by providing you with prior written notice.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            As a seller, you understand that Stripe will be responsible for collecting and remitting any sales tax on your behalf for orders shipped to customers in the United States. By using Purposer, you agree to comply with all applicable sales tax laws and regulations and to provide Stripe with accurate information regarding the location of your business and the goods or services you sell. The Company is not responsible for any errors or issues related to the collection or remittance of sales tax by Stripe. It is the seller's responsibility to ensure that they comply with all legal requirements related to sales tax.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            As a seller, you understand that the Company is required to provide you with a 1099-K form if you reach certain thresholds of transactions or gross income in a calendar year. The 1099-K form will be provided to you by the Company and will include information about the total dollar amount of payments processed for your business. It's your responsibility to review the information and ensure that it's accurate. You also understand that it's your responsibility to report any income earned through Purposer to the appropriate tax authorities.
          </Typography>
        </Title>
        <Title title="Sales and Coupons">
          <Typography className={classes.marginBottomBody}>
            As a seller, you have the option to run sales or offer coupons on your Products. All sales and coupons must be conducted within the guidelines of these terms and conditions. The Company reserves the right to reject or remove any sale or coupon that is deemed to be in violation of these terms. As a seller, you are responsible for the management of your sales and coupons, including setting the terms and conditions and honoring any redemption codes. The Company will not be responsible for any errors or issues related to your sales and coupons. Please note, only one discount code may be used per transaction.
          </Typography>
        </Title>
        <Title title="Links from Purposer">
          <Typography className={classes.marginBottomBody}>
            If Purposer contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third party Purposers linked to Purposer, you do so entirely at your own risk and subject to the terms and conditions of use for such Purposers.
          </Typography>
        </Title>
        <Title title="Disclaimer of Warranties">
          <Typography className={classes.marginBottomBody}>
            You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or Purposer will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF PURPOSER OR ANY SERVICES OR ITEMS OBTAINED THROUGH PURPOSER OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY PURPOSER LINKED TO IT.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            YOUR USE OF PURPOSER, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH PURPOSER IS AT YOUR OWN RISK. PURPOSER, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH PURPOSER ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF PURPOSER. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT PURPOSER, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH PURPOSER WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT PURPOSER OR ANY SERVICES OR ITEMS OBTAINED THROUGH PURPOSER WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </Typography>
        </Title>
        <Title title="Limitation on Liability">
          <Typography className={classes.marginBottomBody}>
            TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, PURPOSER, ANY PURPOSERS LINKED TO IT, ANY CONTENT ON PURPOSER OR SUCH OTHER PURPOSERS OR ANY SERVICES OR ITEMS OBTAINED THROUGH PURPOSER OR SUCH OTHER PURPOSERS, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </Typography>
        </Title>
        <Title title="Indemnification">
          <Typography className={classes.marginBottomBody}>
            You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of Purposer, including, but not limited to, your User Contributions, any use of Purposer's content, services and products other than as expressly authorized in these Terms of Use, or your use of any information obtained from Purposer.
          </Typography>
        </Title>
        <Title title="Governing Law and Jurisdiction">
          <Typography className={classes.marginBottomBody}>
            All matters relating to Purposer and these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of California without giving effect to any choice or conflict of law provision or rule (whether of the State of California or any other jurisdiction).
          </Typography>
          <Typography className={classes.marginBottomBody}>
            Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or Purposer shall be instituted exclusively in the federal courts of the United States or the courts of the State of California although we retain the right to bring any suit, action or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.
          </Typography>
        </Title>
        <Title title="Arbitration">
          <Typography className={classes.marginBottomBody}>
            At Company's sole discretion, it may require you to submit any disputes arising from these Terms of Use or use of Purposer, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying California law.
          </Typography>
        </Title>
        <Title title="Waiver and Severability">
          <Typography className={classes.marginBottomBody}>
            No waiver of by the Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.
          </Typography>
        </Title>
        <Title title="Entire Agreement">
          <Typography className={classes.marginBottomBody}>
            The Terms of Use, our Privacy Policy, and Terms of Sale constitute the sole and entire agreement between you and Purposer with respect to Purposer and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to Purposer.
          </Typography>
        </Title>
        <Title title="Your Comments and Concerns">
          <Typography className={classes.marginBottomBody}>
            Purposer is operated by Purposer, 7634 Girard Avenue, La Jolla, CA 92037.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            All notices of copyright infringement claims should be sent to the copyright agent via email: <a href="mailto:info@purposer.com" className={classes.link}>info@purposer.com</a>.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            All other feedback, comments, requests for technical support and other communications relating to Purposer should be directed to: <a href="mailto:info@purposer.com" className={classes.link}>info@purposer.com</a>.
          </Typography>
        </Title>
      </Container>

      <div style={{ height: "var(--dividerHeight)" }} />
      <div style={{ height: "var(--dividerHeight)" }} />
    </PurposeCategoryMasterLayout>
  );
};

export default TermsOfUseLayout;
