import { Grid, Typography, Button, ButtonBase } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import FileUpload, {
  UPLOAD_ERROR,
  triggerUpload,
  onDropHandler,
  onDragOverHandler,
} from "./FileUpload";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";
import bulb from "../img/grey_bulb.png";
import DeleteIcon from '@mui/icons-material/Delete';
import { MAX_FILE_SIZE, FILETYPES, MAX_NUM_FILES } from "../util/constants"
import FileUploadV2 from "./FileUploadV2";


const useStyles = makeStyles({
  border: {
    borderStyle: "solid",
    borderWidth: 1,
  },
  uploadDivContainer: {
    display: "flex",
    cursor: "pointer",
  },
  headers: {
    color: "var(--mintGreenSolid)",
    marginBottom: 20,
    fontSize: "1.3rem",
  },
  img: {
    width: "100%",
    height: "100%",
    margin: "auto",
  },
  uploadLogo: {
    objectFit: "scale-down",
  },
  uploadedImg: {
    objectFit: "cover",
  },
  uploadedLogo: {
    borderRadius: 10,
  },
  businessLogoDiv: {
    width: 125,
    height: 125,
    borderRadius: "50%",
  },
  coverPhotoDiv: {
    height: 250,
    borderRadius: 30,
  },
  uploadedCoverPhotos: {
    borderRadius: 30,
  },
  center: {
    textAlign: "center",
  },
  noTextTransform: {
    textTransform: "none",
    marginTop: 12,
    marginBottom: 12,
  },
  descriptionText: {
    fontSize: "0.85rem",
    lineHeight: 2,
  },
  coverPhotoGrid: {
    marginTop: 40,
  },
  rightGrid: {
    paddingLeft: 36,
  },
  carouselContainer: {
    width: "100%",
    height: "100%",
    borderRadius: 30,
  },
  navButtons: {
    position: "absolute",
    height: 75,
    borderRadius: 20,
    backgroundColor: "teal",
    opacity: 0.5,
  },
  prevButton: {
    left: 20,
  },
  nextButton: {
    right: 20,
  },
  arrows: {
    color: "white",
    fontSize: 30,
  },
});

const responsive = {
  break1: {
    breakpoint: { max: 3000, min: 0 },
    items: 1,
  },
};

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  const classes = useStyles();
  return (
    <>
      <ButtonBase
        className={clsx(classes.navButtons, classes.prevButton)}
        onClick={(e) => {
          e.stopPropagation();
          previous();
        }}
      >
        <ArrowBack className={classes.arrows} />
      </ButtonBase>
      <ButtonBase
        className={clsx(classes.navButtons, classes.nextButton)}
        onClick={(e) => {
          e.stopPropagation();
          next();
        }}
      >
        <ArrowForward className={classes.arrows} />
      </ButtonBase>
    </>
  );
};

function OpenAShopMedia(props) {
  const classes = useStyles();
  const { handleFormValues, formValues } = props;
  // const { businessLogo, coverPhotos } = formValues;
  // let businessLogoRef = useRef(null);
  // let coverPhotoRef = useRef(null);
  // const [businessLogoError, setBusinessLogoError] = useState(null);
  // const [coverPhotoError, setCoverPhotoError] = useState(null);

  // // handle click event of the Remove button
  // const handleRemoveClick = (index, section) => {
  //   const list = [...formValues[section]];
  //   list.splice(index, 1);
  //   handleFormValues(section, list);
  // };

  return (
    <>
      <Grid container direction="row" alignItems="center" columnSpacing={{ xs: 1, sm: 2, md: 8 }}>
        <Grid item xs={12} md={6}>
          <Typography className={classes.headers}><b>Business Logo</b></Typography>
          <Typography display="inline" className={classes.descriptionText}>
            Browse your computer. (4.5 MB Maximum for each photo)
          </Typography>
          <br/>
          <Typography display="inline" className={classes.descriptionText}>
            Please upload a circular logo image or a logo that will look good in a circle frame
          </Typography>
          <FileUploadV2
            formValueName={"profile_pic"}
            formValueArray={formValues.profile_pic}
            maxFiles={1}
            handleFormValues={handleFormValues}
            buttonText={'upload: jpg, png'}
          />
        </Grid>
        {/* <div
            className={clsx(
              !businessLogo && classes.border,
              classes.uploadDivContainer,
              classes.businessLogoDiv
            )}
            onClick={() => {
              triggerUpload(businessLogoRef);
            }}
            onDrop={(e) =>
              onDropHandler(
                e,
                setBusinessLogoError,
                "businessLogo",
                1,
                FILETYPES,
                MAX_FILE_SIZE,
                handleFormValues
              )
            }
            onDragOver={onDragOverHandler}
          >
            <img
              src={businessLogo ? businessLogo : bulb}
              alt=""
              className={clsx(
                classes.img,
                !businessLogo && classes.uploadLogo,
                businessLogo && classes.uploadedImg,
                businessLogo && classes.uploadedLogo,
                businessLogo && classes.border
              )}
            />
            <FileUpload
              setRef={(inputRef) => {
                businessLogoRef = inputRef;
              }}
              setError={setBusinessLogoError}
              propertyName={"businessLogo"}
              maxFiles={1}
              accept={"image/*"}
              filetypes={FILETYPES}
              maxFileSize={MAX_FILE_SIZE}
              updateState={handleFormValues}
            />
          </div>
          {businessLogoError && (
            <Typography>
              {businessLogoError.type === UPLOAD_ERROR.FILESIZE_TOO_LARGE
                ? "Max file size exceeded"
                : "Illegal file type"}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="outlined"
            onClick={() => {
              triggerUpload(businessLogoRef);
            }}
            disableRipple
            className={classes.noTextTransform}
          >
            
            <Typography className={classes.descriptionText}>
              upload: jpg, png
            </Typography>
            
          </Button>
          <div>
          <Typography display="inline" className={classes.descriptionText}>
            Browse your computer. (10 MB Maximum total of all images uploaded)
          </Typography>
          </div>
        </Grid> */}
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.coverPhotoGrid}
        columnSpacing={{ xs: 1, sm: 2, md: 8 }}
      >
        <Grid item xs={12} md={6}>
          <Typography className={classes.headers}><b>Banner Photos</b></Typography>
          <Typography display="inline" className={classes.descriptionText}>
            Browse your computer. (4.5 MB Maximum for each photo)
            Add a max of 3 banner photos that will rotate in a slideshow.
          </Typography>
          <br/>
          <Typography display="inline" className={classes.descriptionText}>
            Please upload a banner or cover photo. For best results, use a rectangular shaped image.
          </Typography>
          <FileUploadV2
            formValueName={"banner_images"}
            formValueArray={formValues.banner_images}
            maxFiles={3}
            handleFormValues={handleFormValues}
            buttonText={'upload: jpg, png'}
            aspectRatio={2000/500}
            // requireImageCrop={true}
          />
          {/* <div
            className={clsx(
              !coverPhotos.length && classes.border,
              classes.uploadDivContainer,
              classes.coverPhotoDiv
            )}
            onClick={() => {
              triggerUpload(coverPhotoRef);
            }}
            onDrop={(e) =>
              onDropHandler(
                e,
                setCoverPhotoError,
                "coverPhotos",
                MAX_NUM_FILES,
                FILETYPES,
                MAX_FILE_SIZE,
                handleFormValues,
                true,
                formValues.coverPhotos
              )
            }
            onDragOver={onDragOverHandler}
          >
            {coverPhotos.length > 0 ? (
              <Carousel
                responsive={responsive}
                draggable={false}
                infinite={true}
                showDots={false}
                arrows={false}
                containerClass={clsx(classes.carouselContainer, classes.border)}
                slidesToSlide={1}
                autoPlay={coverPhotos.length > 1}
                autoPlaySpeed={8000}
                customButtonGroup={<CustomButtonGroupAsArrows />}
              >
                {coverPhotos.map((coverPhoto, index) => {
                  return (
                    <span key={index}>
                      <Button onClick={(e) => handleRemoveClick(index, "coverPhotos")} style={{position: "absolute", top: "24px", right: "24px"}} variant="contained" color="error">
                    <DeleteIcon fontSize="small"></DeleteIcon>
                    </Button>
                    <img
                      src={coverPhoto}
                      alt=""
                      className={classes.img}
                    />
                    
                    </span >
                  );
                })}
              </Carousel>
            ) : (
              <img
                src={bulb}
                alt=""
                className={clsx(
                  classes.img,
                  !coverPhotos.length && classes.uploadLogo,
                  coverPhotos.length && classes.uploadedImg,
                  coverPhotos.length && classes.uploadedCoverPhotos,
                  coverPhotos.length && classes.border
                )}
              />
            )}
            <FileUpload
              setRef={(inputRef) => {
                coverPhotoRef = inputRef;
              }}
              multiple
              setError={setCoverPhotoError}
              propertyName={"coverPhotos"}
              maxFiles={MAX_NUM_FILES}
              accept={"image/*"}
              filetypes={FILETYPES}
              maxFileSize={MAX_FILE_SIZE}
              updateState={handleFormValues}
              coverPhotos={formValues.coverPhotos}
            />
          </div>
          {coverPhotoError && (
            <Typography>
              {coverPhotoError.type === UPLOAD_ERROR.FILESIZE_TOO_LARGE
                ? "Max file size exceeded"
                : "Illegal file type"}
            </Typography>
          )} */}
        </Grid>
        {/* <Grid item xs={12} md={6}>
          
            <Button
              variant="outlined"
              onClick={() => {
                triggerUpload(coverPhotoRef);
              }}
              disableRipple
              className={classes.noTextTransform}
            >
              <Typography className={classes.descriptionText}>
                upload: jpg, png
              </Typography>
            </Button>
          <div>
            <Typography display="inline" className={classes.descriptionText}>
              Drag and drop a file. Or, browse your computer. (10 MB Maximum total of all images uploaded) .
              Add a max of 5 banner photos that will rotate in a slideshow.
            </Typography>
          </div>
        </Grid> */}
      </Grid>
    </>
  );
}

export default OpenAShopMedia;
