import { Container, Typography, Grid, Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ExpandMore } from "@mui/icons-material";
import sellerSuccessStoriesJsonData from "../json/seller_success_stories.json";
import clsx from "clsx";
import { useRef } from "react";
import useElementWidth from "../hooks/useElementWidth";

const useStyles = makeStyles({
  backgroundDiv: {
    backgroundColor: "#CEBDB3",
    paddingTop: 30,
    paddingBottom: 40,
  },
  header: {
    color: "#FFFFFF",
  },
  storyGrid: {
    paddingTop: 50,
    paddingBottom: 40,
  },
  avatarDiv: {
    marginBottom: 20,
    display: "flex",
    justifyContent: "center",
  },
  imageAvatar: {
    width: "60%",
    maxWidth: 225,
    maxHeight: 225,
  },
  descriptionDiv: {
    margin: "auto",
  },
  rightColumn: {
    //paddingLeft: 50,
  },
  achievementImage: {
    maxWidth: 60,
    maxHeight: 60,
  },
  storyText: {
    marginTop: 30,
    lineHeight: 2,
  },
  center: {
    textAlign: "center",
  },
  moreButton: {
    marginTop: 15,
  },
  moreText: {
    fontSize: "1.2rem",
  },
  flexGridItem: {
    display: "flex",
    flexDirection: "column",
  },
  storyTextContainer: {
    width: "85%",
    margin: "auto",
  },
});

const SuccessStory = (props) => {
  const { storyData, classes } = props;
  const avatarRef = useRef(null);
  const avatarWidth = useElementWidth(avatarRef);

  return (
    <Grid
      container
      direction="row"
      rowSpacing={4}
      className={classes.storyGrid}
    >
      <Grid item xs={12} lg={3} className={classes.flexGridItem}>
        <div className={classes.avatarDiv}>
          <Avatar
            src={storyData.img}
            alt=""
            className={classes.imageAvatar}
            style={{ height: avatarWidth }}
            ref={avatarRef}
          />
        </div>
        <div className={classes.descriptionDiv}>
          <div>
            <Typography
              variant="h6"
              display="inline"
              className={"roboto-regular"}
            >
              Seller Since:&nbsp;
            </Typography>
            <Typography
              variant="h6"
              display="inline"
              className={"roboto-light"}
            >
              {storyData.seller_since}
            </Typography>
          </div>
          <div>
            <Typography
              variant="h6"
              display="inline"
              className={"roboto-regular"}
            >
              Shop:&nbsp;
            </Typography>
            <Typography
              variant="h6"
              display="inline"
              className={"roboto-light"}
            >
              {storyData.shop}
            </Typography>
          </div>
          <div>
            <Typography
              variant="h6"
              display="inline"
              className={"roboto-regular"}
            >
              Purpose:&nbsp;
            </Typography>
            <Typography
              variant="h6"
              display="inline"
              className={"roboto-light"}
            >
              {storyData.purpose}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item sm={12} lg={9} className={classes.rightColumn}>
        <Grid container direction="row" justifyContent="center">
          {storyData.achievements.map((achievement, index) => {
            return (
              <Grid item key={index} className={classes.center} xs={4}>
                <img
                  src={achievement.img}
                  alt=""
                  className={classes.achievementImage}
                />
                <Typography align="center" className={"roboto-medium"}>
                  {achievement.label}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        <div className={classes.storyTextContainer}>
          <Typography
            align="center"
            variant="h6"
            className={clsx(classes.storyText, "roboto-light")}
          >
            {storyData.story}
          </Typography>
          <Typography variant="h6" align="center">
            -{storyData.writer}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

const MoreButton = (classes) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent={"center"}
      className={classes.moreButton}
    >
      <Grid item xs={2} className={classes.center}>
        <a href="/">
          <Typography className={classes.moreText}>
            <i>More</i>
          </Typography>
          <ExpandMore />
        </a>
      </Grid>
    </Grid>
  );
};

function SellerSuccessStories(props) {
  const classes = useStyles();
  const { sellerSuccessStoriesData = sellerSuccessStoriesJsonData } = props;

  return (
    <div className={classes.backgroundDiv}>
      <Container maxWidth="xl">
        <Typography
          variant="h4"
          className={clsx(classes.header, "roboto-medium")}
        >
          Seller Success Stories
        </Typography>
        {sellerSuccessStoriesData.map((story, index) => {
          return (
            <SuccessStory storyData={story} classes={classes} key={index} />
          );
        })}
        <MoreButton {...classes} />
      </Container>
    </div>
  );
}

export default SellerSuccessStories;
