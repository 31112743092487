import { Typography, styled, Container } from "@mui/material";
import defaultData from "../json/brand_supports.json";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";

const ShopSupportTitle = styled(Typography)({
  fontSize: 17,
});

const SupportPurposeTitle = styled(Typography)({
  fontSize: 30,
});

const BrandSupportsHeader = ({
  title = "POVERTY & HOMELESSNESS SOLUTIONS",
  title_url = "#",
}) => {
  const mobile = useMobileMediaQuery();
  return (
    <>
      <Container maxWidth="xl" style={{ margin: "50px auto" }}>
        <ShopSupportTitle
          align={mobile ? "center" : "left"}
          gutterBottom
          className={"roboto-medium"}
        >
          <b>SHOP PRODUCTS THAT SUPPORT</b>
        </ShopSupportTitle>
        <SupportPurposeTitle
          align={mobile ? "center" : "left"}
          gutterBottom
          className={"roboto-medium"}
        >
          <a href={title_url}>
            <b>{title}</b>
          </a>
        </SupportPurposeTitle>
      </Container>
    </>
  );
};

export default BrandSupportsHeader;
