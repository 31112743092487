import FileUpload, {
  triggerUpload,
  onDragOverHandler,
  onDropHandler,
  UPLOAD_ERROR,
} from "./FileUpload";
import { useRef, useState, forwardRef } from "react";
import Carousel from "react-multi-carousel";
import AddIcon from "@mui/icons-material/Add";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { ButtonBase, IconButton, Typography, Grid, Box, Container } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ClearIcon from '@mui/icons-material/Clear';


import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  rectSortingStrategy,
  rectSwappingStrategy,
  horizontalListSortingStrategy
} from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MAX_FILE_SIZE, FILETYPES, MAX_NUM_FILES } from "../util/constants"


const styles = {
  container: {
    width: "100%",
    height: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
  },
  uploadButton: {
    borderStyle: "solid",
    borderWidth: 2,
  },
  img: {
    width: "100%",
    height: "inherit",
    objectFit: "cover",
    margin: "auto",
  },
};

const NavButton = styled(ButtonBase)({
  position: "absolute",
  height: 75,
  borderRadius: 20,
  backgroundColor: "teal",
  opacity: 0.5,
});

const PrevButton = styled(NavButton)({
  left: 20,
});

const NextButton = styled(NavButton)({
  right: 20,
});

const StyledBackArrow = styled(ArrowBack)({
  color: "white",
  fontSize: 30,
});

const StyledForwardArrow = styled(ArrowForward)({
  color: "white",
  fontSize: 30,
});

const useStyles = makeStyles({
  carouselContainer: {
    width: "100%",
    maxHeight: "inherit",
    height: "inherit",
    borderRadius: "inherit",
  },
  carouselItem: {
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
});

const responsive = {
  break1: {
    breakpoint: { max: 3000, min: 0 },
    items: 1,
  },
};

const MAX_NUM_COVER_PHOTO_FILES = 6;

const SortablePhoto = forwardRef((props, imageRef) => {
  const { index, setError, handleFormValues, formValues, value, handleRemoveClick, propertyName, multiple, tempBanner, idx } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: index });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Grid key={index} item xs={12} sm={12} md={8} lg={6}>
      <Box
        sx={{
          width: 400,
          height: 225,
          bgcolor: "var(--halfBeige)",
          p: 1,
          borderRadius: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          position: "relative"
        }}
      >
        {formValues.hasOwnProperty(propertyName) ? (
          <>
            <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
              <img
                src={value}
                alt=""
                style={{
                  objectFit: "contain",
                  height: "100%",
                  width: "100%",
                }}

              />
            </div>
            <ClearIcon onClick={() => handleRemoveClick(index, propertyName)} style={{ position: "absolute", top: "0px", right: "0px", padding: "8px" }}></ClearIcon>
          </>
        ) : (
          <AddCircleOutlineIcon fontSize="medium" />
        )}
        <FileUpload
          setRef={(inputRef) => {
            imageRef = inputRef;
          }}
          multiple={multiple}
          setError={setError}
          propertyName={tempBanner ? tempBanner : propertyName}
          maxFiles={MAX_NUM_FILES}
          accept={"image/*"}
          filetypes={FILETYPES}
          maxFileSize={MAX_FILE_SIZE}
          updateState={handleFormValues}
          coverPhotos={formValues[propertyName]}
        />
      </Box>
      {idx === 0 && <div style={{display: "flex", justifyContent: "center", paddingTop: 15}}><Typography>* This will be the first image to appear.</Typography></div>}
    </Grid>
  );
});

function ImageUploaderCoverPhoto(props) {
  const classes = useStyles();
  const {
    containerStyle = styles.container,
    uploadIcon,
    uploadButtonStyle = styles.uploadButton,
    multiple = false,
    maxFiles = MAX_NUM_COVER_PHOTO_FILES,
    filetypes = FILETYPES,
    maxFileSize = MAX_FILE_SIZE,
    formValues = {},
    propertyName = "",
    setFormValues = () => { },
    imgStyle = styles.img,
    setRef = () => { },
    autoplay = false,
    imgIndex,
    tempBanner,
  } = props;
  const [error, setError] = useState(null);
  let imageRef = useRef(null);

  const handleFormValues = (name, value) => {
    setFormValues((preValues) => {
      return { ...preValues, [name]: value };
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const HandleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setFormValues((preValues, props) => {
        let tmp = arrayMove(preValues[propertyName], active.id, over.id);
        return { ...preValues, [propertyName]: tmp };
      });
    }
  }

  const handleRemoveClick = (index, section) => {
    const list = [...formValues[section]];
    list.splice(index, 1);
    handleFormValues(section, list);
  };

  return (

    <Container maxWidth="xl">
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        rowSpacing={2}
      // columnSpacing={2}
      >
        {formValues.hasOwnProperty(propertyName) && formValues[propertyName] &&
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={HandleDragEnd}
          >
            <SortableContext
              items={formValues[propertyName]}
              strategy={horizontalListSortingStrategy}
            >
              {formValues[propertyName].map((value, index) => {
                return (
                  <SortablePhoto
                    key={index}
                    imageRef={imageRef}
                    index={index}
                    setError={setError}
                    handleFormValues={handleFormValues}
                    formValues={formValues}
                    value={value}
                    handleRemoveClick={handleRemoveClick}
                    propertyName={propertyName}
                    multiple={multiple}
                    tempBanner={tempBanner}
                    //index={index}
                    idx={index}
                  />
                );
              })}
            </SortableContext>
          </DndContext>}
        {(formValues[propertyName] === undefined || formValues[propertyName]?.length < 6) && (
          <Grid item xs={12} sm={12} md={8} lg={6}>
            <Box
              onClick={() => {
                triggerUpload(imageRef);
              }}
              onDrop={(e) =>
                onDropHandler(
                  e,
                  setError,
                  tempBanner ? tempBanner : propertyName,
                  maxFiles,
                  FILETYPES,
                  MAX_FILE_SIZE,
                  handleFormValues,
                  true,
                  formValues[propertyName]
                )
              }
              onDragOver={onDragOverHandler}
              sx={{
                width: 400,
                height: 225,
                bgcolor: "var(--halfBeige)",
                p: 1,
                borderRadius: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <AddCircleOutlineIcon fontSize="medium" />
              <FileUpload
                setRef={(inputRef) => {
                  imageRef = inputRef;
                }}
                multiple={multiple}
                setError={setError}
                propertyName={tempBanner ? tempBanner : propertyName}
                maxFiles={MAX_NUM_FILES}
                accept={"image/*"}
                filetypes={FILETYPES}
                maxFileSize={MAX_FILE_SIZE}
                updateState={handleFormValues}
                coverPhotos={formValues[propertyName]}
              />
            </Box>
          </Grid>
        )}

      </Grid>
      {error && (
        <Typography style={{ color: "red", textAlign: "center" }}>
          {error.type === UPLOAD_ERROR.FILESIZE_TOO_LARGE
            ? "ERROR: Max file size exceeded"
            : "ERROR: Illegal file type"}
        </Typography>
      )}
    </Container>

  )
}

export default ImageUploaderCoverPhoto;
