import {
  Avatar,
  Box,
  ButtonBase,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  menuItemClasses,
  outlinedInputClasses,
  Select,
  selectClasses,
  Stack,
  styled,
  svgIconClasses,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import ManagementMasterLayout from "../MasterLayouts/ManagementMasterLayout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ExpandMore } from "@mui/icons-material";
import clsx from "clsx";
import sellerDashboardJsonData from "../../json/seller_dashboard.json";
import useElementWidth from "../../hooks/useElementWidth";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  horizontalCenter: {
    display: "flex",
    justifyContent: "center",
  },
  verticallyCenter: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    textTransform: "none",
    borderRadius: 13,
    marginRight: 15,
    minHeight: 34,
  },

  rightSectionWidth: {
    paddingLeft: 65,
    paddingRight: 65,
  },
  bubbleStatContainer: {
    paddingTop: 40,
    paddingBottom: 35,
  },
  bubbleStatFilter: {
    fontSize: "1.25rem",
    "& .MuiInput-underline:after": {
      border: "2px solid #000000",
    },
  },
  bubbleAvatar: {
    width: "75%",
    backgroundColor: "#F0F6F4",
    color: "#000000",
  },
  todoBackground: {
    backgroundColor: "var(--beige)",
    paddingTop: 5,
    paddingBottom: 15,
  },
  todoHeader: {
    marginLeft: 10,
    fontSize: "1.4rem",
  },
  todoDivider: {
    backgroundColor: "#000000",
    width: "35%",
    marginTop: 2,
    marginBottom: 15,
  },
  todoLogo: {
    color: "#FFFFFF",
    marginRight: 20,
  },
  rightLowerContainer: {
    paddingTop: 40,
  },
  tableSpacing: {
    marginTop: 80,
    marginBottom: 20,
  },
  bottomButtonGroup: {
    marginTop: 200,
    marginBottom: 200,
  },
});

const StyledMenuItem = styled(MenuItem)({
  backgroundColor: "#FFFFFF",
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
});

const StyledFormControl = styled(FormControl)({
  [`& .${outlinedInputClasses.root}`]: {
    height: 40,
    borderRadius: 7,
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "#000000",
    },
    [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderWidth: 1,
      },
    [`& .${selectClasses.select}`]: {
      paddingRight: 0,
      paddingLeft: 27,
    },
  },
  [`& .${svgIconClasses.root}`]: {
    paddingRight: 0,
  },
});

const DropdownButton = (props) => {
  // Structure of options is [{label: "", value: ""}, {label: "", value: ""}]
  const { label, options, selected, handleClick } = props;

  return (
    <StyledFormControl>
      <Select
        value={selected}
        onChange={handleClick}
        renderValue={() => (
          <Typography align="center" variant="h6" className={"roboto-thin"}>
            {label}
          </Typography>
        )}
        displayEmpty
        IconComponent={ExpandMore}
        sx={{
          ".MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input":
            {
              pl: 2,
              pr: 4.5,
            },
        }}
      >
        {options &&
          options.map((option, index) => {
            return (
              <StyledMenuItem key={index} value={option.value}>
                <Typography className={"roboto-regular"}>
                  {option.label}
                </Typography>
              </StyledMenuItem>
            );
          })}
      </Select>
    </StyledFormControl>
  );
};

const TableRow = (props) => {
  const { listing, adViews, clicks, orders, budget } = props;
  return (
    <>
      <Container>
        <Box sx={{ my: 2.4 }}>
          <Stack direction={"row"} justifyContent="space-between">
            <Box>
              <Typography>
                <b>{listing}</b>
              </Typography>
            </Box>
            <Box
              sx={{
                py: 1.5,
                px: 2.5,
                border: "gray solid 1px",
                borderRadius: 2,
              }}
            >
              <Typography align="center">
                <b>{adViews}</b>
              </Typography>
            </Box>
            <Box
              sx={{
                py: 1.5,
                px: 2.5,
                border: "gray solid 1px",
                borderRadius: 2,
              }}
            >
              <Typography align="center">
                <b>{clicks}</b>
              </Typography>
            </Box>
            <Box
              sx={{
                py: 1.5,
                px: 2.5,
                border: "gray solid 1px",
                borderRadius: 2,
              }}
            >
              <Typography>
                <b>{orders}</b>
              </Typography>
            </Box>
            <Box
              sx={{
                py: 1.5,
                px: 2.5,
                border: "gray solid 1px",
                borderRadius: 2,
              }}
            >
              <Typography>
                <b>{budget}</b>
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Container>
      <Divider />
    </>
  );
};
const Bubble = (props) => {
  const { statBubbleData, avatarRef, avatarWidth, classes } = props;

  // Used to adjust text size to make sure it doesn't overflow if it is
  // too long by default
  const nameRatio = 8;
  const statRatio = 5;

  return (
    <Avatar
      className={classes.bubbleAvatar}
      ref={avatarRef}
      style={{ height: avatarWidth }}
    >
      <div>
        <Typography
          align="center"
          style={{ fontSize: avatarWidth / nameRatio }}
        >
          <i>{statBubbleData.name}</i>
        </Typography>
        <Typography
          align="center"
          style={{ fontSize: avatarWidth / statRatio }}
        >
          <b>{statBubbleData.stat}</b>
        </Typography>
      </div>
    </Avatar>
  );
};

function ShopAdsLayout() {
  const sellerDashboardData = sellerDashboardJsonData;
  const classes = useStyles();
  const bubbleRef = React.useRef(null);
  const [bubbleStatFilter, setBubbleStatFiler] = React.useState("Last 7 Days");
  const bubbleWidth = useElementWidth(bubbleRef);

  const handleBubbleStatFilterChange = (event) => {
    setBubbleStatFiler(event.target.value);
  };
  return (
    <ManagementMasterLayout
      title={"Shop Ads"}
      sellerName={sellerDashboardData.name}
      sellerPicture={sellerDashboardData.pic}
    >
      <div
        className={clsx(classes.bubbleStatContainer, classes.rightSectionWidth)}
      >
        <Stack direction="row" alignItems={"center"} spacing={2} sx={{ mb: 6 }}>
          <Typography variant="h4">
            <b>Ad Stats For</b>
          </Typography>
          <Select
            value={bubbleStatFilter}
            onChange={handleBubbleStatFilterChange}
            className={classes.bubbleStatFilter}
          >
            <MenuItem value={"Last 7 Days"}>
              <b>Last 7 Days</b>
            </MenuItem>
            <MenuItem value={"Last Month"}>
              <b>Last Month</b>
            </MenuItem>
            <MenuItem value={"Last Year"}>
              <b>Last Year</b>
            </MenuItem>
          </Select>
        </Stack>
        <Grid container direction="row">
          {bubbleStats.map((bubbleStat, index) => {
            return (
              <Grid
                item
                xs={4}
                key={index}
                align={index === 0 ? "left" : index === 1 ? "center" : "right"}
              >
                <Bubble
                  statBubbleData={bubbleStat}
                  avatarRef={bubbleRef}
                  avatarWidth={bubbleWidth}
                  classes={classes}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
      <Box sx={{ bgcolor: "var(--halfBeige)", mx: 0.5, my: 3, py: 2, mb: 15 }}>
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <DropdownButton
              label={"Sort By Ad Views"}
              options={[
                { label: "Option1", value: "Option1" },
                { label: "Option2", value: "Option2" },
                { label: "Option3", value: "Option3" },
                { label: "Option4", value: "Option4" },
              ]}
            />
            <DropdownButton
              label={"Filter by Ad Listing"}
              options={[
                { label: "Option1", value: "Option1" },
                { label: "Option2", value: "Option2" },
                { label: "Option3", value: "Option3" },
                { label: "Option4", value: "Option4" },
              ]}
            />
          </Box>
          <Box sx={{ my: 1 }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography sx={{ pl: 1 }}>
                <b>Listing</b>
              </Typography>
              <Typography sx={{ pl: 2 }}>
                <b>Ad Views</b>
              </Typography>
              <Typography sx={{ pl: 2 }}>
                <b>Clicks</b>
              </Typography>
              <Typography sx={{ pl: 2 }}>
                <b>Orders</b>
              </Typography>
              <Typography>
                <b>Budget Spent</b>
              </Typography>
            </Stack>
          </Box>
        </Container>
        <Divider />
        {productTemps.map((product, index) => {
          return (
            <TableRow
              key={index}
              listing={product.listing}
              adViews={product.adViews}
              clicks={product.clicks}
              orders={product.orders}
              budget={product.budget}
            />
          );
        })}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <ButtonBase sx={{ borderRadius: 20, p: 2 }}>
            <KeyboardArrowDownIcon fontSize="large" />
          </ButtonBase>
        </Box>
      </Box>
    </ManagementMasterLayout>
  );
}
const productTemps = [
  {
    listing: "Product 1",
    adViews: 456,
    clicks: 456,
    orders: 6,
    budget: 88,
  },
  {
    listing: "Product 1",
    adViews: 456,
    clicks: 456,
    orders: 6,
    budget: 88,
  },
  {
    listing: "Product 1",
    adViews: 456,
    clicks: 456,
    orders: 6,
    budget: 88,
  },
  {
    listing: "Product 1",
    adViews: 456,
    clicks: 456,
    orders: 6,
    budget: 88,
  },
];

const bubbleStats = [
  { name: "Ad Views", stat: "5,253" },
  { name: "Ad Clicks", stat: "2,080" },
  { name: "Orders From Ad", stat: "498" },
];
export default ShopAdsLayout;
