import React, { useRef, useState, forwardRef } from "react";
import { AdminPortalManagementMaster } from "..";
import sellerDashboardJsonData from "../../json/seller_dashboard.json";
import {
  Container,
  TextField,
  Box,
  ButtonBase,
  Typography,
  Grid,
  Modal,
  CircularProgress,
  Button,
  Stack,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import FileUpload, {
  UPLOAD_ERROR,
  triggerUpload,
  onDropHandler,
  onDragOverHandler,
} from "../../components/FileUpload";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { insertBlog } from "../../util/blogs";
import { useSelector } from "react-redux";
import { useHistory, Link, useParams } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { BLOG_CATEGORIES } from "../../util/constants";
import ClearIcon from '@mui/icons-material/Clear';

import { MAX_FILE_SIZE, FILETYPES, MAX_NUM_FILES } from "../../util/constants"

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  rectSortingStrategy,
  rectSwappingStrategy,
  horizontalListSortingStrategy
} from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import 'react-markdown-editor-lite/lib/index.css';
import MdEditor from 'react-markdown-editor-lite';
import markdownIt from 'markdown-it';
import FileUploadV2 from "../../components/FileUploadV2";
import { uploadImageArray } from "../../util/images";

const ContentRow = ({ title, children }) => {
  return (
    <Grid container style={{ padding: "16px 32px 0px 16px" }}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ color: "var(--coral)" }}>
          <b>{title}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

const style = {
  removeButton: {
    py: 1,
    px: 2,
    color: "red",
    border: "solid 1px red",
    borderRadius: 1,
  },
};

const ConfirmDeleteModal = (props) => {
  const { openConfirmDelete, setOpenConfirmDelete } = props;

  const deleteBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={openConfirmDelete} handleClose={() => setOpenConfirmDelete(false)}>
      <Box sx={deleteBox}>
        <Typography variant="h5" style={{ color: "var(--coral)", fontWeight: "bold" }} textAlign="center">CAREFUL</Typography>
        <div style={{ height: "8px" }} />
        <Typography textAlign="center">You are about to delete this blog. Are you sure you want to do this?</Typography>
        <div style={{ height: "16px" }} />
        <Grid container justifyContent="center" columnGap={2}>
          <Grid item>
            <Button variant="outlined" sx={{ borderColor: "black", color: "black" }} onClick={() => setOpenConfirmDelete(false)}>
              Go Back
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" sx={{ bgcolor: "red", color: "white" }} onClick={() => setOpenConfirmDelete(false)}>
              Delete Blog
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

const SortablePhoto = forwardRef((props, imageRef) => {
  const { index, setImageError, handleFormValues, formValues, value, handleRemoveClick } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: index });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Grid key={index} item xs={12} sm={6} md={4} >
      <Box
        sx={{
          width: 125,
          height: 150,
          bgcolor: "var(--halfBeige)",
          p: 1,
          borderRadius: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          position: "relative"
        }}
      >
        {formValues.pictures ? (
          <>
            <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
              <img
                src={value}
                alt=""
                style={{
                  objectFit: "contain",
                  height: "100%",
                  width: "100%",
                }}

              />
            </div>
            <ClearIcon onClick={() => handleRemoveClick(index, "pictures")} style={{ position: "absolute", top: "0px", right: "0px", padding: "8px" }}></ClearIcon>
          </>
        ) : (
          <AddCircleOutlineIcon fontSize="medium" />
        )}
        <FileUpload
          setRef={(inputRef) => {
            imageRef = inputRef;
          }}
          setError={setImageError}
          propertyName={"pictures"}
          maxFiles={MAX_NUM_FILES}
          accept={"image/*"}
          filetypes={FILETYPES}
          maxFileSize={MAX_FILE_SIZE}
          updateState={handleFormValues}
          coverPhotos={formValues.pictures}
        />
      </Box>

    </Grid>
  );
});

function AddBlogLayout() {
  const sellerDashboardData = sellerDashboardJsonData;
  const { blogID } = useParams();
  const history = useHistory();
  const userAttributes = useSelector((state) => state.user.user);
  // const { username } = useSelector((state) => state.user.user);
  const [formValues, setFormValues] = useState({
    title: "",
    subtitle: "",
    category: "",
    body: "",
    summary: "",
    pictures: [],
    //sellerID: id.id,
  });
  const [imageError, setImageError] = useState(null);
  const [postFailed, setPostFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  let imageRef = useRef(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // handle click event of the Remove button
  const handleRemoveClick = (index, section) => {
    const list = [...formValues[section]];
    list.splice(index, 1);
    handleFormValues(section, list);
  };

  const HandleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setFormValues((preValues) => {
        let tmp = arrayMove(preValues.pictures, active.id, over.id);
        return { ...preValues, "pictures": tmp };
      });
    }
  }

  const handleFormValues = (name, value) => {
    setFormValues((preValues) => {
      return { ...preValues, [name]: value };
    });
  };

  const createBlog = async () => {
    setIsLoading(true);

    //Payments info
    const title = formValues.title;
    const subtitle = formValues.subtitle;
    const category = formValues.category;
    const body = formValues.body;
    const imageUrls = formValues.pictures;

    let errorsInSubmission = []
    if (!title) errorsInSubmission.push("Blog must have a title!");
    if (!category) errorsInSubmission.push("Blog must have a category!");
    if (!body) errorsInSubmission.push("Blog must have a body!");
    if (!imageUrls || imageUrls.length < 3) errorsInSubmission.push("Must have three images!");

    if (errorsInSubmission.length > 0) {
      const allErrorsText = errorsInSubmission.join('\n');
      toast((t) => (
        <span>
          {allErrorsText}
          {'\n'}
          <Grid container justifyContent="center">
            <Button onClick={() => toast.dismiss(t.id)}>
              Dismiss
            </Button>
          </Grid>
        </span>
      ));
      setIsLoading(false);
    }
    else {

      const imgUrlArray = await uploadImageArray(imageUrls);

      insertBlog(formValues.title, formValues.subtitle, formValues.category, formValues.body, formValues.summary, imgUrlArray, (data) => {
        if (data) history.push(`/blog/` + data.results.blogID);
        setPostFailed(true);
        setIsLoading(false);
        // window.location.reload(false);
      });
    }
  };

  const ButtonRow = () => {
    return (
      <Grid
        container
        justifyContent={"center"}
        rowSpacing={2}
        columnSpacing={2}
        sx={{ my: 5 }}
      >
        <Grid item sx>
          <Button color="error" variant="outlined"
            onClick={() => setOpenConfirmDelete(true)}
          >
            <b>Delete Blog</b>
          </Button>
        </Grid>
        <Grid item sx>
          <Button variant="outlined"
            sx={{
              // bgcolor: "var(--coral)",
              // py: 1,
              // px: 2.5,
              // borderRadius: 2.5,
              color: "black",
              borderColor: "black"
            }}
            href="/adminportal/currentblogs"
          >
            <b>Back to Current Blogs</b>
          </Button>
        </Grid>
        <Grid item sx>
          <Button variant="contained"
            sx={{
              bgcolor: "var(--coral)",
              // py: 1,
              // px: 2.5,
              // borderRadius: 2.5,
              // color: "white",
            }}
            onClick={createBlog}
          >
            <b>Publish Blog</b>
          </Button>
        </Grid>
      </Grid>
    )
  }

  const mdParser = new markdownIt();

  return (
    <AdminPortalManagementMaster
      title="Create New Blog Post"
      sellerName={sellerDashboardData.name}
      sellerPicture={sellerDashboardData.pic}
    >
      <Toaster
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <ConfirmDeleteModal openConfirmDelete={openConfirmDelete} setOpenConfirmDelete={setOpenConfirmDelete} />
      <ButtonRow />
      <Container maxWidth={"xl"}>
        <ContentRow title={"Title"}>
          <TextField
            size="small"
            onChange={(e) => handleFormValues("title", e.target.value)}
            fullWidth
          />
        </ContentRow>
        <ContentRow title={"Subtitle"}>
          <TextField
            size="small"
            onChange={(e) => handleFormValues("subtitle", e.target.value)}
            fullWidth
          />
        </ContentRow>
        <ContentRow title={"Category"}>
          {/* <TextField
            size="small"
            onChange={(e) => handleFormValues("category", e.target.value)}
            fullWidth
          /> */}
          <FormControl fullWidth>
            <Select
              value={formValues.category}
              label="Category"
              onChange={(e) => handleFormValues("category", e.target.value)}
              size="small"
            >
              {BLOG_CATEGORIES && BLOG_CATEGORIES.map((blogCategory, index) => {
                return (
                  <MenuItem value={blogCategory} key={index}>{blogCategory}</MenuItem>
                )
              })}
            </Select>
          </FormControl>

        </ContentRow>
        <br />
        {/* <Grid container justifyContent="flex-end"><Button style={{ color: "black" }} onClick={() => handleFormValues("body", formValues.body.concat('\n•'))}><FormatListBulletedIcon /> Add Bullet points</Button></Grid> */}
        <ContentRow title={"Body:"}>
          <MdEditor
            style={{ height: "750px" }}
            value={formValues.body}
            renderHTML={(text) => mdParser.render(text)}
            onChange={({ html, text }) => handleFormValues("body", text)}
          />
        </ContentRow>
        <ContentRow title={"Short Summary:"}>
          <MdEditor
            style={{ height: "500px" }}
            value={formValues.summary}
            renderHTML={(text) => mdParser.render(text)}
            onChange={({ html, text }) => handleFormValues("summary", text)}
          />
        </ContentRow>
        <ContentRow title={"Upload Pictures"}>
          <Container maxWidth="sm">
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              rowSpacing={2}
              columnSpacing={2}
              sx={{ mt: 1 }}
            >
              <FileUploadV2
                formValueName={"pictures"}
                formValueArray={formValues.pictures}
                maxFiles={3}
                columns={3}
                handleFormValues={handleFormValues}
                buttonText={'upload: jpg, png'}
              />
              <Typography variant="caption">
                Upload 3 images, the first being the banner photo and the other 2 being smaller photos at the bottom of the blog.
              </Typography>
            </Grid>
          </Container>
        </ContentRow>
        <ButtonRow />
      </Container>
      {/* Temporary loading screen while listing is being uploaded */}
      <Modal open={isLoading} sx={{ overflow: "scroll" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 2,
            bgcolor: "background.paper",
            p: 4,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
        </Box>
      </Modal>
      <Modal open={postFailed} sx={{ overflow: "scroll" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 2,
            bgcolor: "background.paper",
            p: 4,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Blog has failed to publish.
          </Typography>
          <Stack direction={"row"}>
            <Button component={Link} to={`/adminportal/currentblogs`}>
              Back to admin portal.
            </Button>
            <Button
              to={`/adminportal/currentblogs`}
              onClick={() => setPostFailed(false)}
            >
              Try Again
            </Button>
          </Stack>
        </Box>
      </Modal>
    </AdminPortalManagementMaster>
  );
}

export default AddBlogLayout;
