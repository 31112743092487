import { useState, useEffect } from "react";
import { Typography, Grid, Modal, TextField, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Box from '@mui/material/Box';
import MuiPhoneNumber from 'material-ui-phone-number';
import { addToMarketingList, checkFirstTimeCouponList } from "../util/klaviyo";
import { isValidEmail, isValidPhoneNumber, formatPhoneNumber } from "../util/utilities";
import toast, { Toaster } from 'react-hot-toast';

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  header: {
    color: "white",
    textAlign: "center",
    fontWeight: "400",
    fontFamily: "Roboto",
  },
  subheader: {
    color: "white",
    textAlign: "center",
    fontWeight: "700",
    fontFamily: "Roboto",
    //fontSize: "1.25rem",
  },
  subtitle: {
    //color: "black",
    fontFamily: "Roboto",
    fontSize: "1rem",
    fontWeight: "300"
  },
  disclaimer: {
    fontSize: "0.85rem",
    fontWeight: "300",
    textAlign: "center"
  },
  input: {
    background: "white",
  },
  buttonCustom: {
    backgroundColor: "#E8687F",
    padding: "12px 32px",
    color: "white",
    fontSize: "1.25rem",
    fontWeight: "700",
    fontFamily: "Roboto",
  },
  buttonCustomSkip: {
    // backgroundColor: "white",
    fontFamily: "Roboto",
    textTransform: "none",
    backgroundColor: "none",
    padding: "12px 32px",
    color: "black",
    fontSize: ".85rem",
    fontWeight: "400",
  },
  picture: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
    borderTopLeftRadius: "36px",
    borderTopRightRadius: "36px",
  }
});


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "450px",
  width: { xs: "100%" },
  maxHeight: "90vh",
  paddingLeft: "96px",
  paddingRight: "96px",

  // height: {xs: "100%"},
  overflow: "auto",
  background: 'url(https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/newPopup1.png) center',

};

const Popup = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // If user's first time visiting the site, have the pop-up
  useEffect(() => {
    let firstTimeCoupon = localStorage.getItem("firstTimeCoupon");
    if (firstTimeCoupon) {
      handleClose();
    } else {
      checkFirstTimeCouponList(undefined, undefined, (data) => {
        if (data && data.exists === false) {
          handleOpen();

        } else if (data && data.exists === true) {
          localStorage.setItem("firstTimeCoupon", "true");
          handleClose();
        }
        return;
      })
    }
  }, []);

  const handleAddToMarketingList = () => {
    const phoneNumber = formatPhoneNumber(phone);
    if (!isLoading && phoneNumber && isValidEmail(email)) {
      setIsLoading(true);
      addToMarketingList(email, phoneNumber, (data) => {
        if (data && data.success === true) {
          localStorage.setItem("firstTimeCoupon", "true");
          handleClose();
          setIsLoading(false);
        } else {
          localStorage.setItem("firstTimeCoupon", "true");
          toast((t) => (
            <span>
              {`Looks like you already are signed up!`}
              {'\n'}
              <Grid container justifyContent="center">
                <Button onClick={() => toast.dismiss(t.id)}>
                  Dismiss
                </Button>
              </Grid>
            </span>
          ), {
            duration: 20000,
            position: 'bottom-center',
          });
          handleClose();
          setIsLoading(false);
          return;
        }
      })
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Toaster
          toastOptions={{
            className: '',
            style: {
              border: '1px solid #713200',
              padding: '16px',
              color: '#713200',
            },
          }}
        />
        <div style={{ padding: "32px" }}>
          <Grid container justifyContent='space-between' style={{ marginBottom: "20px" }}>
            <Grid item >
              <Typography id="modal-modal-title" variant="h3" component="h2" className={classes.header}>
                SIGN UP & RECEIVE
              </Typography>
              <Typography id="modal-modal-title" variant="h2" component="h2" className={classes.subheader}>
                15% OFF
              </Typography>
            </Grid>
          </Grid>
          <Typography id="modal-modal-title" className={classes.subtitle}>
            Email Address
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            name="email"
            type="email"
            fullWidth
            variant="outlined"
            placeholder="email@example.com"
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              className: classes.input
            }}
            size="small"
          />
          <Typography id="modal-modal-title" className={classes.subtitle}>
            Phone Number
          </Typography>
          <MuiPhoneNumber disableAreaCodes fullWidth defaultCountry={'us'} variant="outlined" margin="dense" size="small"
            InputProps={{
              className: classes.input
            }}
            onChange={(value) => {
              setPhone(value)
            }
            }
            value={phone}
          />
          <Grid container style={{ padding: "16px 0" }}  >
            <Grid item xs={11}>
              <Typography className={classes.disclaimer}>
                By signing up, you agree to Purposer <a href="/termsofuse" target="_blank" style={{ textDecoration: "underline" }}>Terms of Use</a> &amp; <a href="/privacypolicy" target="_blank" style={{ textDecoration: "underline" }}>Privacy Policy</a>.
              </Typography>
            </Grid>
          </Grid>
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              onClick={handleAddToMarketingList}
              className={classes.buttonCustom}
              disabled={isLoading || !isValidPhoneNumber(phone) || !isValidEmail(email)}
            >
              GET OFFER
            </Button>
          </div>
          <div style={{ paddingTop: "32px", width: "100%", display: "flex", justifyContent: "center" }}>
            <a onClick={handleClose} className={classes.buttonCustomSkip} target="_blank" style={{ textDecoration: "none", cursor: "pointer" }}>No, thanks.</a>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default Popup;
