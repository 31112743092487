import { Container, Box, Typography, Grid, Avatar, Stack, Button, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MasterLayout } from "..";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useDocumentDescription from "../../hooks/useDocumentDescription";
import SellerDealBanner from "../../components/SellerDealBanner";
import clsx from "clsx";
import useMobileMediaQuery from "../../hooks/useMobileMediaQuery";


const BigSellButton = styled(Button)({
  textTransform: "none",
  marginTop: 24,
  borderRadius: 10,
  color: "#FFFFFF",
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 8,
  paddingBottom: 8,
  background: "var(--coral)",
  "&:hover": {
    background: "var(--coral)",
  },
  "&:visited": {
    color: "#FFFFFF",
  },
});

const SellButton = styled(Button)({
  textTransform: "none",
  marginTop: 12,
  borderRadius: 10,
  color: "#FFFFFF",
  paddingLeft: 15,
  paddingRight: 15,
  paddingTop: 4,
  paddingBottom: 4,
  background: "var(--coral)",
  "&:hover": {
    background: "var(--coral)",
  },
  "&:visited": {
    color: "#FFFFFF",
  },
});

const GreenSellButton = styled(Button)({
  textTransform: "none",
  marginTop: 12,
  borderRadius: 10,
  color: "#FFFFFF",
  paddingLeft: 15,
  paddingRight: 15,
  paddingTop: 4,
  paddingBottom: 4,
  background: "var(--mintGreenSolid)",
  "&:hover": {
    background: "var(--mintGreenSolid)",
  },
  "&:visited": {
    color: "#FFFFFF",
  },
});

const constants = {
  howItWorks: [
    {
      title: "List Your Product",
      description:
        "Conveniently list your products on your own customized page within our site.",
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/sell+on+purposer+page/woman_taking_picture.jpg",
    },
    {
      title: "We Promote You",
      description:
        "We will promote your product and create loyal shoppers that love your brand. ",
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/sell+on+purposer+page/two_purposers.jpg",
    },
    {
      title: "Sold & Shipped",
      description:
        "When a shopper makes a purchase, you can ship to them directly.",
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/sell+on+purposer+page/woman_smiling.jpg",
    },
  ],
  threeEasySteps: [
    {
      header: "Create a Store",
      description:
        "Takes 15 minutes. Schedule a free onboarding call for help doing this!",
    },
    {
      header: "List Products",
      description:
        "Our team can make the product listings, saving you valuable time.",
    },
    {
      header: "Ship to Customers",
      description:
        "We send you customer details so that you can dropship nationwide.",
    },
  ],
  whyPurposerDifferent: [
    {
      header:
        "TRANSPARENT PRICING",
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/sell+on+purposer+page/laptop_final.jpg",
      body: "Opening an account and creating a store on Purposer is free. We only charge one all inclusive commission fee.",
      includes: [
        "No listing fee.",
        "No monthly subscription.",
        "No advertising costs.",
        "Flat 20% commission fee when a product is sold.",
        "You get paid immediately.",
        "Payouts are direct deposited into your bank account.",
      ],
    },
    {
      header:
        "WE SHARE YOUR MISSION",
      body: "We want our shoppers to know about your STORY and MISSION because we know the hard work that small business owners put into their brands. People care about the wholesome ingredients, sustainable materials and charitable work that you do. That’s why we feature your “purpose” throughout our marketplace on product listings, description, emails and more so that shoppers can support your cause for many years to come!",
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/sell+on+purposer+page/tablet_final.jpg",
    },
    {
      header:
        "EVERY BRAND HAS PURPOSE",
      body: "All the brands that join our marketplace have to go through a meet and greet with our team, virtually or in-person! We want to ensure that all brands are small business, operating ethically and/or have a mission that aligns with one of our six purposes: Sustainability, Poverty Solutions, Healthy Living, Community Support, Faith Life and Animals & Wildlife.",
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/sell+on+purposer+page/phone_final.jpg",
    },
  ],
  percentagePurposer: [
    {
      percent: "88",
      body1: "of shoppers would rather",
      highlight: "buy",
      body2: "products/services from a purpose-driven shop",
    },
    {
      percent: "79",
      body1: "of shoppers say they are more",
      highlight: "loyal",
      body2: "to purpose-driven shops",
    },
    {
      percent: "66",
      body1: "of shoppers would",
      highlight: "switch",
      body2:
        "from a product they typically buy, to a new product from a purpose-driven shop",
    },
    {
      percent: "57",
      body1: "of shoppers would",
      highlight: "pay more",
      body2: "for a product from a purpose-driven shop",
    },
  ],
  sellers: [
    {
      seller: "Mayra Leano",
      shop: "Stella Myris",
      purpose: "Clean Oceans",
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/common%2Faa4f9959-3fdb-5491-89e2-716d59844c46.jpg",
      description:
        "Mayra joined our team in February 2023 in hopes of bringing her brand, Stella Myris, a line of of beach themed home and woman's accessories to shoppers all around the world. She lives with her husband and son in San Diego. They all adore the ocean and can be see in the water every week. For every product she sells, she donates a portion of proceeds to various ocean conservancy charities.",
    },
    {
      seller: "Stephen Tako",
      shop: "Confident Life",
      purpose: "Anti-Bullying",
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/common%2Fd3218963-fdcc-5b32-ac4d-736b2f0396de.jpg",
      description:
        "Stephen joined our team in January 2023, right when he finished publishing the second book in his series, Junga The Dancing Yeti Meets Heidi. Stephen is an accomplished artist, actor and speaker who wants to help children find ways to reduce bullying and to find courage, empathy and compassion through acceptance and inclusion. His books, videos and school curriculums are used throughout the country.",
    },
    {
      seller: "Jon Schroeder",
      shop: "Equality Bands",
      purpose: "Community Support",
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/common%2F1a4d2e70-ee3f-5c48-8c5e-0845a6f05029.jpg",
      description:
        "Hailing from Omaha, Nebraska, Jon is an avid sports enthusiast and lover of life. He joined up with a couple of friends to create a special line of wrist bands that represent an important fundamental, human equality for all. Wearing a band supports this human truth and shares the message around you. His shop is donation-based, non-profit and and all net-profits are donated to social justice groups.",
    },
    {
      seller: "Dan Versman",
      shop: "MEW",
      purpose: "Domestic Abuse Rehabilitation",
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/common/dc9a9249-cffa-4892-ab82-6147d1b14c89.jpg",
      description:
        "Dan Versman is a passionate surfer and talented musician who brings joy to everyone he encounters. A man of many talents, Dan joined our team in February 2023 because he loved our mission of supporting purpose-driven companies like his own. MEW ethically manufactures chic, cool printed linen and bed sheets. A portion of proceeds  go to domestic violence treatment centers, ensuring that all men and woman have a safe bed to sleep in.",
    },
  ],
  infoPanels: [
    {
      title: "Customized Storefront",
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/common%2F5a6ff8bd-bc11-50e3-9b77-78e71c725ce3.png",
      description:
        "Build your online shop in less than 10 minutes! Use your own banner photo and logo to brand your store. Also access Seller Tools & Tips to boost sales.",
    }
    // {
    //   title: "Grow Globally",
    //   img_url:
    //     "https://d2eudfgh8kpk8j.cloudfront.net/common/edf2927d-6576-4e87-bf43-c5a1fb081986.png",
    //   description:
    //     "We will promote your Purpose-Driven Brands through various channels and create loyal customers for you across the world. ",
    // },
    // {
    //   title: "Shipping Credits",
    //   img_url:
    //     "https://d2eudfgh8kpk8j.cloudfront.net/common%2Fc92c036e-00fb-5146-b4e1-a889ca3d5e88.png",
    //   description:
    //     "We've partnered with USPS to give you discounted shipping rates and help keep your bottom line strong!",
    // },
  ],
  paymentMethods: [
    {
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/common%2Ff58ec587-f79f-578c-9565-025f5e828eac.png",
      alt: "visa",
    },
    {
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/common%2Faedf807d-794b-5cad-a26e-d91c91f26f0a.png",
      alt: "Mastercard",
    },
    {
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/common%2Fdb40e5fd-aa43-5d8c-bc0d-957d34af88ce.png",
      alt: "paypal",
    },
    {
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/common%2F23a4c980-d4a9-5d4b-a568-15e4b5e97d1c.png",
      alt: "discover",
    },
    {
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/common%2F12dddbb8-cc6c-5220-af06-2530e5f1437e.png",
      alt: "apple",
    },
    {
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/common%2F70cdcc58-7277-5ff4-9fa5-231b6d8315ef.png",
      alt: "google",
    },
    {
      img_url:
        "https://d2eudfgh8kpk8j.cloudfront.net/common%2F35f9f327-aac1-5abc-8175-a9eab85a1fa4.png",
      alt: "american express",
    },
  ],
  faqQuestions: [
    {
      question: "Who can sell on Purposer?",
      answer:
        "Any type of business, brand, company, and entrepreneur may sell on our site as long as your products/services are purpose-driven.  Purpose-driven can mean that you donate a portion of proceeds to a certain charity OR your products help support a certain cause OR your products are made ethically.",
    },
    {
      question: "Can International Sellers join Purposer? ",
      answer:
        "For the time being we are only hosting US based sellers. We will open up to international vendors late 2024. Sign up to our email list to get updates.",
    },
    {
      question: "Does Purposer collect a commission?",
      answer:
        "Our main goal is to support our purpose-driven sellers, their missions and help them succeed in their endeavors. Like any traditional marketplace, we do take a commission per sale you make. Unlike our competitors, we do not charge you confusing memberships or redundant fees. We charge a one-time 20% commission per sale, the most competitive in the market.",
    },

    {
      question: "What Makes Purposer Different From Other Marketplaces?",
      answer:
        "It may be daunting to enter the world of marketplaces, but Purposer stands out in many different ways. Purposer sells exclusively brands that are making a positive impact. Shoppers feel good about their purchases on Purposer. It has been found that customers feel a stronger sense of loyalty and connection to purpose-driven brands so it is convenient that Purposer is a hub for those brands! We are dedicated to supporting and uplifting our sellers instead of hiding them and making their products difficult to find. Purposer is also a diverse one-stop-shop for just about everything as we provide our shoppers with positive alternatives to their everyday products.",
    },
    {
      question: "How do I get paid?",
      answer:
        "When one of your products sell, your funds minus the 20% commission will be direct deposited in your bank account of choice within 2-5 business days, but usually faster. Ex) Your product sells for $35. You will get $28.00 credited back to you. (breakdown = $35 Sales - $7 commission)",
    },
    {
      question:
        "Do you promote my products on Google or Social Media? Do I have to pay for this service?",
      answer:
        "As a seller on our site you never have to pay additional fees for marketing. We want your business to succeed, so we do our best to promote across all digital platforms so you pop up above your competitors.",
    },
  ],
};

const ThreeEasyStepsSection = ({ index, header, description, mobile }) => (
  <Grid item sx xs={12} md={4}>
    <Box>
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="h1" className="roboto-regular" sx={{ color: "var(--coral)" }}>
            {index + 1}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography align="left" variant="h5" sx={{ mb: 1 }}>
            {header}
          </Typography>
          <Typography align="left">{description}</Typography>
        </Grid>
      </Grid>
    </Box>
  </Grid>
);

const HowItWorksSection = ({ title, description, img_url }) => (
  <Grid item sx>
    <Box sx={{ width: 250 }}>
      <Typography align="center" variant="h5" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Box sx={{ width: 200, height: 200, margin: "auto" }}>
        <Avatar
          src={img_url}
          alt={title}
          sx={{ width: "100%", height: "100%", mb: 2 }}
        />
      </Box>
      <Typography align="center">{description}</Typography>
    </Box>
  </Grid>
);

const WhyPurposerDifferent = ({ index, header, body, img_url, includes, mobile }) => (
  <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    align="center"
    columnSpacing={3}
    rowSpacing={3}
    sx={{ mb: 4 }}
  >
    {index % 2 == 1 && !mobile ? (
      <>
        <Grid item md={6} sm={12}>
          <Box
            sx={{
              backgroundColor: "var(--lightBeige)",
              borderRadius: 2.5,
              display: "flex",
              width: "80%",
              marginRight: "auto",
            }}
          >
            <img
              src={img_url}
              alt={header}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "top",
                borderRadius: 10,
              }}
            />
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          {/* <Typography align="left" variant="h3" sx={{ mb: 3 }} style={{ fontSize: "24px" }}> */}
          <Typography align="right" className={"roboto-light"} sx={{ mb: 3 }} style={{ fontSize: "31px", color: "var(--mintGreen" }}>
            {header}
          </Typography>
          <Typography align="left" variant="h6" sx={{ fontWeight: "300" }}>{body}</Typography>
          {includes && (
            <>
              {/* <Typography align="left" variant="h6">
                <b>Includes</b>
              </Typography> */}
              {includes.map((item, i) => {
                return (
                  <Typography align="left" key={i} variant="h6" sx={{ fontWeight: "300" }}>
                    • {item}
                  </Typography>
                );
              })}
            </>
          )}
        </Grid>
      </>
    ) :
      (
        <>
          <Grid item md={6} sm={12}>
            <Typography align="left" className={"roboto-light"} sx={{ mb: 3 }} style={{ fontSize: "31px", color: "var(--mintGreen" }}>
              {header}
            </Typography>
            <Typography align="left" variant="h6" sx={{ fontWeight: "300" }}>{body}</Typography>
            {includes && (
              <>
                {/* <Typography align="left" variant="h6">
                  <b>Includes</b>
                </Typography> */}
                {includes.map((item, i) => {
                  return (
                    <Typography align="left" key={i} variant="h6" sx={{ fontWeight: "300" }}>
                      • {item}
                    </Typography>
                  );
                })}
              </>
            )}
          </Grid>
          <Grid item md={6} sm={12}>
            <Box
              sx={{
                backgroundColor: "var(--lightBeige)",
                borderRadius: 2.5,
                display: "flex",
                width: "80%",
                marginLeft: !mobile && "auto",
              }}
            >
              <img
                src={img_url}
                alt={header}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "top",
                  borderRadius: 10,
                }}
              />
            </Box>
          </Grid>
        </>
      )
    }
  </Grid>
);

const PercentagePurposer = ({ percent, body1, highlight, body2 }) => (
  <Stack direction="row" spacing={3} sx={{ alignItems: "center", mb: 2 }}>
    <Typography
      variant="h2"
      sx={{ color: "var(--mintGreenSolid)" }}
      align="left"
    >
      <i>
        {percent}
        <sup>%</sup>
      </i>
    </Typography>
    <Typography variant="h6" sx={{ fontWeight: "400" }}>
      {body1}{" "}
      <span style={{ color: "var(--coral)" }}>
        <b>
          <u>{highlight}</u>
        </b>
      </span>{" "}
      {body2}
    </Typography>
  </Stack>
);

const Sellers = ({ seller, shop, purpose, img_url, description }) => (
  <Grid item md={6} sm={12} xs={12}>
    <Box
      sx={{
        backgroundColor: "var(--mintGreen)",
        p: 1,
        borderRadius: 2.3,
        // width: 435,
        minHeight: 300,
      }}
    >
      <Grid container direction="row" alignItems={"center"}>
        <Grid item xs align="center">
          <Avatar sx={{ width: 125, height: 125, my: 1 }} src={img_url} />
        </Grid>
        <Grid item xs>
          <Typography align="left" sx={{ mb: 1 }}>
            <b>Seller: </b>
            {seller}
          </Typography>
          <Typography align="left" sx={{ mb: 1 }}>
            <b>Shop: </b>
            {shop}
          </Typography>
          <Typography align="left" sx={{ mb: 1 }}>
            <b>Purpose: </b>
            {purpose}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ p: 1 }}>{description}</Typography>
        </Grid>
      </Grid>
    </Box>
  </Grid>
);

const InfoPanels = ({ title, img_url, description }) => (
  // <Grid item xs>
  <Grid container justifyContent="center"
    alignItems="center"
    // rowSpacing={4}
    // maxWidth="lg"
    sx={{ pb: 6, pt: 4 }}>
    <Grid item xs={12} sm={12} md={4}>
      {/* <Box
        sx={{
          width: 275,
          height: 225,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      > */}
      <img
        style={{ maxHeight: "275px", display: "flex", justifyContent: "center" }}
        src={img_url}
        alt={title}
      />
      {/* </Box> */}
    </Grid>
    <Grid item xs={12} sm={12} md={6}>
      <Typography sx={{ color: "var(--coral)" }} align="center" variant="h5">
        <b>{title}</b>
      </Typography>
      <Typography sx={{ fontWeight: 400 }} align="center" variant="h6">
        {description}
      </Typography>
    </Grid>
  </Grid>
  // </Grid>
);

const ContactBanner = ({mobile}) => (
  mobile ? (
  <Box>
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      sx={{ backgroundColor: "#f5f5f5", pt:"20px", paddingLeft: "10px" }}
    >
      <Grid item xs={4} sx={{ paddingBottom: "20px" }}>
        <Avatar
          src={"https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/sell+on+purposer+page/mail_icon.png"}
          sx={{ width: "75px", height: "75px" }}
        />
      </Grid>
      <Grid item sx xs={8}>
        <Typography align="left" className={"roboto-light"} sx={{ fontSize: "25px", paddingTop: "15px" }}>hello@purposer.com</Typography>
      </Grid>
      <Grid item xs={4} sx={{ paddingBottom: "20px" }}>
        <Avatar
          src={"https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/sell+on+purposer+page/call_icon.png"}
          sx={{ width: "75px", height: "75px" }}
        />
      </Grid>
      <Grid item sx xs={8}>
        <Typography align="left" className={"roboto-light"} sx={{ fontSize: "25px", paddingTop: "15px" }}>805-303-7728</Typography>
      </Grid>
    </Grid>
  </Box>
  )
  :
  (
    <Box sx={{ pr: 20, pl: 30, pt: 10 }}>
    <Grid
      container
      columnSpacing={12}
      rowSpacing={2}
      justifyContent="center"
      alignContent="center"
      sx={{ backgroundColor: "#f5f5f5" }}
    >
      <Grid item xs={2} sx={{ paddingBottom: "20px" }}>
        <Avatar
          src={"https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/sell+on+purposer+page/mail_icon.png"}
          sx={{ width: "100px", height: "100px" }}
        />
      </Grid>
      <Grid item sx xs={4}>
        <Typography align="left" className={"roboto-light"} sx={{ fontSize: "30px", paddingTop: "30px" }}>hello@purposer.com</Typography>
      </Grid>
      <Grid item xs={2} sx={{ paddingBottom: "20px" }}>
        <Avatar
          src={"https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/sell+on+purposer+page/call_icon.png"}
          sx={{ width: "100px", height: "100px" }}
        />
      </Grid>
      <Grid item sx xs={4}>
        <Typography align="left" className={"roboto-light"} sx={{ fontSize: "30px", paddingTop: "30px" }}>805-303-7728</Typography>
      </Grid>
    </Grid>
  </Box>
  )
)

const SellOnPurposerLayout = () => {
  const mobile = useMobileMediaQuery();

  useDocumentTitle("Start selling your products on a marketplace with low fees and that supports your mission, Purposer.com");
  useDocumentDescription("Join our marketplace for great product placement, low commission fees and loyal shoppers that value the mission behind your purpose driven brand - Purposer.com");


  return (
    <MasterLayout>
      <main>
        <Box sx={{ backgroundColor: "#E5D1C3" }}>
          <SellerDealBanner />
          <br />
          <br />
          <br />
          <br />
          <Typography
            align="center"
            sx={{ mb: 2 }}
            style={{ fontSize: "29px" }}
            variant="h1"
            className="roboto-medium"
          >
            Sell on Purposer.
          </Typography>
          <Container maxWidth="lg">
            <Typography align="center" variant="h2" style={{ fontSize: "30px" }}

              className="roboto-light"
            >
              Join an ethical online marketplace where every product is purposefully made and every brand makes a positive impact on the world.
            </Typography>
          </Container>
          <br />
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Grid container sx={{px: "10vh"}}>
              <Grid item xs={12} sm={6} md={4} align="center">
                <SellButton
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/seller/signup"
                >
                  <Typography className={"roboto-regular"} sx={{ fontSize: "23px" }}>
                    Start Selling
                  </Typography>
                </SellButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4} align="center">
                <SellButton
                  href="https://calendly.com/purposer/15-minute-call?month=2024-01"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography className={"roboto-regular"} sx={{ fontSize: "23px" }}>
                    Free 15 Min Onboarding Call
                  </Typography>
                </SellButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4} align="center">
                <SellButton
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/contact"
                >
                  <Typography className={"roboto-regular"} sx={{ fontSize: "23px" }}>
                    Contact Us
                  </Typography>
                </SellButton>
              </Grid>
            </Grid>
          </div>
          <br />
          <br />
        </Box>
        <ContactBanner mobile={mobile}/>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            align="center"
            rowSpacing={3}
            sx={{ mb: 4, paddingTop: "15px" }}
          >
            <Grid item md={4} sm={4} xs={12}>
              <img
                src={"https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/sell+on+purposer+page/joline-office.png"}
                alt={"hey"}
                style={{
                  width: "45%",
                  height: "45%",
                  borderRadius: 10,
                  paddingTop: "30px",
                  paddingLeft: "0px",

                }}
              />
            </Grid>
            <Grid item md={7} sm={7} xs={11}>
              <Typography align="center" className={clsx("roboto-regular")} sx={{ mb: 3 }} style={{ fontSize: mobile ? "25px" : "31px", color: "var(--mintGreen" }}>
                <b>{"A NOTE FROM OUR FOUNDER"}</b>
              </Typography>
              <Typography align="left" className={clsx("roboto-light")} sx={{ fontWeight: "300", paddingRight: mobile ? "5px" : "75px" }}>
                {"I’m honored that you’ve discovered Purposer! My team and I have meticulously crafted a unique special shopping site with products that embody beauty, wholesomeness and a commitment to making a positive impact on the world. I can’t wait to learn more about your brand and the unique mission that drives you!"}
              </Typography>
              <Typography align="left" variant="h6"
                style={{ fontFamily: 'Allura, cursive' }}

                sx={{ fontWeight: "300", fontSize: "25px", paddingLeft: mobile ? "15vh" : "45vh", paddingTop: "30px" }}>
                {"With gratitude,"}
              </Typography>
              <Typography
                align="left" variant="h6"
                style={{ fontFamily: 'Allura, cursive' }}
                sx={{ fontWeight: "300", fontSize: "25px", paddingLeft: mobile ? "15vh" : "45vh" }}
              >
                {"Joline Mann, Founder"}
              </Typography>
            </Grid>
            <Grid item md={1} sm={1}/>
          </Grid>
        </div>
        <Box sx={{ backgroundColor: "white" }}>
          <Container maxWidth="xl">
            <Typography align="center" sx={{ py: 5, color: "var(--mintGreen)" }} variant="h4">
              Three Easy Steps to Get Started
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Grid container sx={{px: "10vh"}}>
                <Grid item xs={12} sm={6} md={4} align="center">
                  <GreenSellButton
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/seller/signup"
                  >
                    <Typography className={"roboto-regular"} sx={{ fontSize: "23px" }}>
                      Start Selling
                    </Typography>
                  </GreenSellButton>
                </Grid>
                <Grid item xs={12} sm={6} md={4} align="center">
                  <GreenSellButton
                    href="https://calendly.com/purposer/15-minute-call?month=2024-01"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography className={"roboto-regular"} sx={{ fontSize: "23px" }}>
                      Free 15 Min Onboarding Call
                    </Typography>
                  </GreenSellButton>
                </Grid>
                <Grid item xs={12} sm={6} md={4} align="center">
                  <GreenSellButton
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://drive.google.com/file/d/1c2P8vHIYAwd5Ev9deshD44HzRIEjrrih/view"
                  >
                    <Typography className={"roboto-regular"} sx={{ fontSize: "23px" }}>
                      Guidebook
                    </Typography>
                  </GreenSellButton>
                </Grid>
              </Grid>
            </div>
            <Box sx={{ pr: mobile ? 1 : 10, pl: mobile ? 1 : 20, pt: mobile ? 5 : 10 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                columnSpacing={mobile ? 0 : 12}
                rowSpacing={mobile ? 0 : 4}
                justifyContent="center"
                alignContent="center"
                sx={{ pb: 7, backgroundColor: "#f5f5f5" }}
              >
                {constants.threeEasySteps.map((val, index) => {
                  return (
                    <ThreeEasyStepsSection
                      index={index}
                      header={val.header}
                      description={val.description}
                      mobile={mobile}
                    />
                  );
                })}
              </Grid>
            </Box>
            <Box sx={{ pr: mobile ? 1 : 10, pl: mobile ? 1 : 20 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                columnSpacing={mobile ? 0 : 12}
                rowSpacing={mobile ? 0 : 4}
                justifyContent="center"
                alignContent="center"
                sx={{ pb: 7, backgroundColor: "#f5f5f5" }}
              >
                <Grid item xs={12} sm={12} md={12} align="center">
                  <Avatar
                    src={"https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/sell+on+purposer+page/giving_icon.png"}
                    sx={{ width: "300px", height: "300px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} align="center">
                  <Typography className="roboto-regular" sx={{ fontSize: "23px" }}>
                    <b>
                      {`We're only successful, if you are.`}
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} align="center">
                  <Typography className="roboto-light" sx={{ fontSize: "23px", mx: "35px" }}>
                    {`We are partners with you and want to see you prosper! That's why at no extra charge, we promote you to conscious consumers across the United States with paid ads, organic google searches, influencers, social media, event marketing and much more.`}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <br />
        <br />
        <ContactBanner mobile={mobile}/>
        <br />
        <br />
        <br />
        <br />
        <Container maxWidth="xl" sx={{ marginBottom: "64px" }}>
          {constants.whyPurposerDifferent.map((val, index) => {
            return (
              <WhyPurposerDifferent
                index={index}
                header={val.header}
                body={val.body}
                img_url={val.img_url}
                includes={val.includes}
                mobile={mobile}
              />
            );
          })}
        </Container>
        <ContactBanner mobile={mobile}/>
        <br />
        <br />
        <Box
          sx={{
            display: "flex",
            width: "80%",
            margin: "auto",
            mb: 4,
            justifyContent: "center",
          }}
          >
            <img
              src={"https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/sell+on+purposer+page/2laptops_resized.png"}
              style={{ height: mobile ? "300px" : "500px" }}
            />
          </Box>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Grid container sx={{px: "10vh"}}>
            <Grid item xs={12} sm={6} md={4} align="center">
              <GreenSellButton
                target="_blank"
                rel="noopener noreferrer"
                href="/seller/signup"
              >
                <Typography className={"roboto-regular"} sx={{ fontSize: "23px" }}>
                  Start Selling
                </Typography>
              </GreenSellButton>
            </Grid>
            <Grid item xs={12} sm={6} md={4} align="center">
              <GreenSellButton
                href="https://calendly.com/purposer/15-minute-call?month=2024-01"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography className={"roboto-regular"} sx={{ fontSize: "23px" }}>
                  Free 15 Min Onboarding Call
                </Typography>
              </GreenSellButton>
            </Grid>
            <Grid item xs={12} sm={6} md={4} align="center">
              <GreenSellButton
                target="_blank"
                rel="noopener noreferrer"
                href="https://drive.google.com/file/d/1c2P8vHIYAwd5Ev9deshD44HzRIEjrrih/view"
              >
                <Typography className={"roboto-regular"} sx={{ fontSize: "23px" }}>
                  Guidebook
                </Typography>
              </GreenSellButton>
            </Grid>
          </Grid>
        </div>

        <br/>
        <br/>
        <Container maxWidth="xl" sx={{ mb: 3 }}>
          <Typography
            variant="h5"
            sx={{ my: 3, color: "var(--mintGreenSolid)", textAlign: "center" }}
          >
            FAQS
          </Typography>
          <Grid container direction="row" columnSpacing={5} rowSpacing={5}>
            {constants.faqQuestions.map((item, index) => {
              return (
                <Grid item xs={12} sm={6} key={index}>
                  <Typography
                    sx={{ color: "var(--mintGreenSolid)" }}
                    gutterBottom
                  >
                    {item.question}
                  </Typography>
                  <Typography>{item.answer}</Typography>
                </Grid>
              );
            })}
          </Grid>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: 32, marginTop: 32 }}>
            <BigSellButton href="/seller/signup">
              <Typography className={"roboto-medium"} sx={{ fontSize: "20px" }}>
                Become a Seller
              </Typography>
            </BigSellButton>
          </div>
        </Container>
      </main>
    </MasterLayout>
  );
};

export default SellOnPurposerLayout;
