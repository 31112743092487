import { getSessionJwt, tryParseJSONObject, getJsonCache, setJsonCache } from "./utilities";
const {
  NODE_API_ENDPOINT
} = require("./constants");

async function createNewSeller(sellerInfo, callback) {
  const jwtToken = await getSessionJwt();

  fetch(NODE_API_ENDPOINT + "/create-seller", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(sellerInfo),
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function getSellerByUserId(id, callback) {
  fetch(NODE_API_ENDPOINT + "/get-seller-by-user-id?id=" + id)
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then((data) => {
      data['id'] = data['sellerID'];
      data['seller_name'] = data['sellerName'];

      callback(data)
    })
    .catch((error) => {
      console.log(error)
      callback(null)
    });
}

async function getSellerIdByUserId(id, callback) {
  fetch(NODE_API_ENDPOINT + "/get-seller-id-by-user-id?id=" + id)
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then((data) => {
      callback(data)
    })
    .catch((error) => {
      console.log(error)
      callback(null)
    });
}

async function getSellerById(id, callback) {
  fetch(NODE_API_ENDPOINT + "/get-seller-by-id?id=" + id)
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then((data) => {
      data['id'] = data['sellerID'];
      data['seller_name'] = data['sellerName'];

      callback(data)
    })
    .catch((error) => {
      console.log(error)
      callback(null)
    });
}

async function getSellerByIdExtraInfo(sellerID, callback) {
  const jwtToken = await getSessionJwt();

  fetch(NODE_API_ENDPOINT + "/get-seller-extra-info-by-sellerID?sellerID=" + sellerID, {
    method: 'GET',
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then((data) => {
      callback(data)
    })
    .catch((error) => {
      console.log(error)
      callback(null)
    });
}


async function getSellerReviews(id, callback) {
  fetch(NODE_API_ENDPOINT + "/get-seller-products-reviews?id=" + id)
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function checkDuplicateStoreNames(storeName, callback) {
  fetch(NODE_API_ENDPOINT + "/check-duplicate-store-name?storeName=" + storeName)
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function updateSeller(sellerInfo, callback) {
  const jwtToken = await getSessionJwt();

  fetch(NODE_API_ENDPOINT + "/update-seller", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(sellerInfo),
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function getAllSellersSupportedCountries(callback) {
  const dataName = 'getAllSellersSupportedCountries';
  const cachedData = getJsonCache(dataName);
  if (cachedData) callback(cachedData);

  fetch(NODE_API_ENDPOINT + "/get-sellers-supported-countries")
    .then((response) => response.json())
    .then(
      (data) => {
        setJsonCache(dataName, data);
        callback(data)
      },
      (error) => console.log(error)
    );
}

async function getSellersBySupportedCountry(country, callback) {

  fetch(NODE_API_ENDPOINT + "/get-sellers-by-supported-countries?country=" + country)
    .then((response) => response.json())
    .then(
      (data) => {
        callback(data)
      },
      (error) => console.log(error)
    );
}

async function deactivateSeller(sellerID, callback) {
  const apiBody = {
    sellerID: sellerID
  }
  const jwtToken = await getSessionJwt();
  fetch(NODE_API_ENDPOINT + "/deactivate-seller", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(apiBody),
  })
  .then((response) => {
    if (response.status === 200) return response.json();
    return null;
  })
  .then(
    (data) => {callback(data)},
    (error) => console.log(error)
  );
}

export {
  createNewSeller,
  getSellerById,
  getSellerByUserId,
  getSellerIdByUserId,
  updateSeller,
  getAllSellersSupportedCountries,
  getSellersBySupportedCountry,
  getSellerReviews,
  checkDuplicateStoreNames,
  getSellerByIdExtraInfo,
  deactivateSeller,
};
