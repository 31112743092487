/*
 * Module that contains functions and a component for file uploading
 */
import {
  Typography,
  Button,
  Grid,
  Box,
  Modal,
} from "@mui/material";
import { Fragment, useEffect, useState, useRef } from "react";
import { MAX_FILE_SIZE } from "../util/constants";
import { getBase64FileName, getSizeInMegaBytes, isStringABase64File } from "../util/utilities";
import makeStyles from "@mui/styles/makeStyles";
import toast, { Toaster } from 'react-hot-toast';
import { UPLOAD_ERROR } from "./FileUpload";
import ImageCropTool from "./ImageCropTool.js";


const useStyles = makeStyles({
  border: {
    borderStyle: "solid",
    borderWidth: 1,
  },
  descriptionText: {
    fontSize: "0.7rem",
    lineHeight: 2,
  },
  gridItemContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    // paddingLeft: '35px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px', // You can adjust this value according to your needs
  },
  gridItemContainerSingleItem: {
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingLeft: '35px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px', // You can adjust this value according to your needs
  },
});


function hasExtension(fileName, filetypes) {
  const pattern = "(" + filetypes.join("|").replace(/\./g, "\\.") + ")$";
  return new RegExp(pattern, "i").test(fileName);
}

/*
  Read a file and return a promise that when resolved gives the file itself and
  the data URL
*/
function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Read the image via FileReader API and save image result in state.
    reader.onload = function (e) {
      // Add the file name to the data URL
      let dataURL = e.target.result;
      dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
      resolve({ file, dataURL });
    };

    reader.readAsDataURL(file);
  });
}

function onUploadClick(e) {
  e.target.value = null;
}

function triggerUpload(inputElement) {
  if (inputElement) {
    inputElement.click();
  }
}

function onDragOverHandler(e) {
  e.preventDefault();
}

// https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
function onDropHandler(
  e,
  setError,
  formValueName,
  maxFiles,
  filetypes,
  maxFileSize,
  handleFormValues,
  multiple,
  coverPhotos
) {
  e.preventDefault();
  let files = [];

  for (var i = 0; i < e.dataTransfer.files.length; i++) {
    files.push(e.dataTransfer.files[i]);
  }

  fileChangedHandler(
    files,
    setError,
    formValueName,
    maxFiles,
    filetypes,
    maxFileSize,
    handleFormValues,
    multiple,
    coverPhotos
  );
}

const errorToast = (message) => {
  toast((t) => (
    <span>
      {message}
      {'\n'}
      <Grid container justifyContent="center">
        <Button onClick={() => toast.dismiss(t.id)}>
          Dismiss
        </Button>
      </Grid>
    </span>
  ), {
    duration: 15000,
  });

}

function fileChangedHandler(
  files,
  setError,
  formValueName,
  maxFiles,
  filetypes,
  maxFileSize,
  handleFormValues,
  coverPhotos,
  addFiles,
  photosAwaitingCrop,
  setPhotosAwaitingCrop,
  requireImageCrop,
) {
  const allFilePromises = [];
  setError(null);

  if (coverPhotos != null && coverPhotos.length > maxFiles) {
    return;
  }

  // Iterate over all uploaded files
  var allowableFiles;
  if (maxFiles && coverPhotos && coverPhotos.length) {
    allowableFiles = maxFiles - coverPhotos.length
  } else if (maxFiles) {
    allowableFiles = maxFiles
  } else {
    allowableFiles = 1;
  }
  //Don't need to check for this anymore because we upload 1 by 1
  // var totalSize = 0
  // for (let x = 0; x < files.length; x++) {
  //   totalSize += files[x].size;
  // }
  for (let i = 0; i < files.length && allFilePromises.length < maxFiles; i++) {
    if (i + 1 > allowableFiles) {
      continue;
    }
    let file = files[i];
    let fileError = {
      name: file.name,
    };
    // Check for file extension
    if (!hasExtension(file.name, filetypes)) {
      fileError = Object.assign(fileError, {
        type: UPLOAD_ERROR.NOT_SUPPORTED_EXTENSION,
      });
      setError(fileError);
      errorToast(`ERROR! Uploaded File type is not supported!`);
      return;
    }

    if (file.size > maxFileSize) {
      fileError = Object.assign(fileError, {
        type: UPLOAD_ERROR.FILESIZE_TOO_LARGE,
      });
      setError(fileError);
      errorToast(`ERROR! File size is above the 4.5MB max!`);
      return;
    }
    // Check for total files size
    // if (totalSize > maxFileSize) {
    //   fileError = Object.assign(fileError, {
    //     type: UPLOAD_ERROR.TOTALFILESIZE_TOO_LARGE,
    //   });
    //   setError(fileError);
    //   return;
    // }

    allFilePromises.push(readFile(file));
  }
  Promise.all(allFilePromises).then((newFilesData) => {
    if (newFilesData.length > 0) {
      let dataURLs = [];
      newFilesData.forEach((newFileData) => {
        dataURLs.push(newFileData.dataURL);
      });

      if (requireImageCrop) {
        setPhotosAwaitingCrop([...photosAwaitingCrop, ...dataURLs]);
      } else {
        addFiles(dataURLs);
      }

      // handleFormValues(formValueName, dataURLs);
    }
  });
}

const FileUploader = (props) => {
  const classes = useStyles();

  const {
    setRef,
    multiple,
    formValueName,
    maxFiles,
    accept,
    filetypes,
    maxFileSize,
    handleFormValues,
    coverPhotos,
    columns,
    formValueArray,
    imgMaxHeight,
    imgMaxWidth,
    aspectRatio,
    requireImageCrop,
  } = props;
  const [errorMsg, setErrorMsg] = useState(null);
  const [addedOldFiles, setAddedOldFiles] = useState(false);
  const [addedUploadedPhotos, setAddedUploadedPhotos] = useState(false);
  const [photosAwaitingCrop, setPhotosAwaitingCrop] = useState([]);
  const addFiles = (files) => {
    const oldFiles = formValueArray || [];
    const newFiles = [...oldFiles];
    if (Array.isArray(files)) {
      files.forEach((file) => {
        if (!newFiles.includes(file)) {
          newFiles.push(file);
        } else if (isStringABase64File(file)) {
          if (addedOldFiles) {
            errorToast(`ERROR! File ${getBase64FileName(file)} already uploaded!`);
          }
        }
        setAddedOldFiles(true);
      });
    }

    if (newFiles.length > maxFiles) {
      errorToast(`ERROR! Too Many files! Only upload ${maxFiles} files.`);
      const filesToChangeTo = newFiles.slice(0, maxFiles);
      handleFormValues(formValueName, filesToChangeTo);
    } else {
      handleFormValues(formValueName, newFiles);
    }
  };

  useEffect(() => {
    if (!addedUploadedPhotos) {
      if (formValueArray) {
        setAddedUploadedPhotos(true);
        addFiles(formValueArray);
      }
    }
  }, [formValueArray]);

  function ImageGallery({ files, columns, imgMaxHeight, imgMaxWidth }) {
    const groups = files?.reduce((groups, file, index) => {
      if (index % columns === 0) {
        groups.push([]);
      }
      groups[groups.length - 1].push(file);
      return groups;
    }, []);

    // Move an item in the array to the left
    function moveLeft(imagesArray, index) {
      if (index === 0) {
        console.warn("Can't move the first image to the left");
        return imagesArray;
      }
      const newArray = [...imagesArray];
      const temp = newArray[index - 1];
      newArray[index - 1] = newArray[index];
      newArray[index] = temp;
      handleFormValues(formValueName, newArray);
      return newArray;
    }

    // Move an item in the array to the right
    function moveRight(imagesArray, index) {
      if (index === imagesArray.length - 1) {
        console.warn("Can't move the last image to the right");
        return imagesArray;
      }
      const newArray = [...imagesArray];
      const temp = newArray[index + 1];
      newArray[index + 1] = newArray[index];
      newArray[index] = temp;
      handleFormValues(formValueName, newArray);
      return newArray;
    }


    const handleUpdate = (newFile) => {
      const dataURLs = [newFile];
      const newPhotosAwaitingCrop = photosAwaitingCrop.slice(1);
      setPhotosAwaitingCrop(newPhotosAwaitingCrop);
      addFiles(dataURLs);
    }

    const handleCancel = () => {
      const newPhotosAwaitingCrop = photosAwaitingCrop.slice(1);
      setPhotosAwaitingCrop(newPhotosAwaitingCrop);
    }


    return (
      <div>
        {photosAwaitingCrop?.length > 0 && (
          // modal with crop tool
          <Modal 
            open={true} 
            // sx={{ overflow: "scroll" }} 
            key="photoCropperModal"
          >
            {/* <Box
              sx={{
                // position: "absolute",
                // top: "50%",
                // left: "50%",
                // transform: "translate(-50%, -50%)",
                borderRadius: 1,
                // bgcolor: "background.paper",
                p: 1,
                // display: "flex",
                // justifyContent: "center",
                // flexDirection: "column",
                // alignItems: "center",
              }}
            > */}
              <ImageCropTool
                src={photosAwaitingCrop?.[0]}
                aspectRatio={aspectRatio}
                handleUpdate={handleUpdate}
                handleCancel={handleCancel}
              />
            {/* </Box> */}
          </Modal>
        )}
        <Grid container direction="row">
          <Grid container spacing={3}>
            {groups?.map((group, i) => (
              <Fragment key={i}>
                {group.map((file, j) => (
                  <Grid item key={j} md={files?.length == 2 ? 6 : 12 / columns}>
                    <div className={files?.length === 1 ? classes.gridItemContainerSingleItem : classes.gridItemContainer}>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        {((i * columns) + j) > 0 &&
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              transform: "translateY(-50%)", // Add this line to center the button vertically
                              textAlign: "center",
                              zIndex: 1000,
                              left: "0px",
                            }}
                          >
                            <Button
                              onClick={() => moveLeft(formValueArray, (i * columns) + j)}
                              color="primary"
                              variant="contained"
                              style={{
                                minWidth: "auto",
                                paddingLeft: "4px",
                                paddingRight: "4px",
                                paddingTop: "4px",
                                paddingBottom: "4px"
                              }}
                            >
                              {`<`}
                            </Button>
                          </div>
                        }
                        <img
                          style={{ 
                            maxWidth: imgMaxWidth, 
                            maxHeight: imgMaxHeight,
                            //centered if length = 1
                            // marginLeft: files?.length == 1 ? "auto" : "0px",
                           }}
                          src={typeof file === 'string' ? file : file.dataURL}
                        />
                        {((i * columns) + j) < formValueArray.length - 1 &&
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              transform: "translateY(-50%)", // Add this line to center the button vertically
                              textAlign: "center",
                              zIndex: 1000,
                              right: "0px",
                            }}
                          >
                            <Button
                              onClick={() => moveRight(formValueArray, ((i * columns) + j))}
                              color="primary"
                              variant="contained"
                              style={{
                                minWidth: "auto",
                                paddingLeft: "4px",
                                paddingRight: "4px",
                                paddingTop: "4px",
                                paddingBottom: "4px"
                              }}
                            >
                              {`>`}
                            </Button>
                          </div>
                        }
                      </div>

                      <Typography className={classes.descriptionText} gutterBottom>
                        {isStringABase64File(file) ? getBase64FileName(file) : 'Uploaded Photo'}
                      </Typography>
                      <Button
                        onClick={(e) => {
                          let updatedFiles = files?.filter((f) => f !== file);
                          handleFormValues(formValueName, updatedFiles);
                          setErrorMsg(null);
                        }}
                        color="error"
                        variant="contained"
                      >
                        Remove
                      </Button>
                    </div>
                  </Grid>
                ))}
              </Fragment>
            ))}
          </Grid>
        </Grid>
      </div>
    );
  }



  return (
    <div>
      <Typography>
        {`Upload up to ${maxFiles} file${maxFiles > 1 ? 's' : ''}`}
      </Typography>
      <input
        type="file"
        ref={(input) => setRef(input)}
        multiple={multiple}
        onChange={(e) => {
          fileChangedHandler(
            e.target.files,
            setErrorMsg,
            formValueName,
            maxFiles,
            filetypes,
            maxFileSize,
            handleFormValues,
            coverPhotos,
            addFiles,
            photosAwaitingCrop,
            setPhotosAwaitingCrop,
            requireImageCrop,
          );
        }}
        onClick={onUploadClick}
        accept={accept}
        style={{ opacity: 0, position: "absolute", zIndex: -1 }}
      />
      <ImageGallery files={formValueArray} columns={columns} imgMaxHeight={imgMaxHeight} imgMaxWidth={imgMaxWidth} />
      <Typography>
        {errorMsg &&
          <div>
            <span style={{ color: "red" }}>
              {`${errorMsg.name}${errorMsg.type ? ' - ' + errorMsg.type : ''}`}
            </span>
            <br />
          </div>
        }
      </Typography>
    </div>
  );
}

const FileUploadV2 = (props) => {
  const {
    multiple = true,
    formValueName,
    maxFiles,
    accept = 'image/*',
    filetypes = [".jpg", ".jpeg", ".png"],
    maxFileSize = MAX_FILE_SIZE,
    handleFormValues = () => { },
    coverPhotos,
    columns = 3,
    formValueArray = [],
    buttonText = 'upload: pdf, jpg, png',
    imgMaxWidth = "100px",
    imgMaxHeight = "100px",
    aspectRatio = null,
    requireImageCrop=false,
  } = props;

  let uploadFileRef = useRef(null);


  return (
    <Grid container direction="row">
      <Toaster
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={() => {
            triggerUpload(uploadFileRef);
          }}
        >
          <Typography>
            {buttonText || 'Upload'}
          </Typography>
        </Button>
      </Grid>
      <FileUploader
        multiple={multiple}
        setRef={(input) => (uploadFileRef = input)}
        formValueName={formValueName}
        formValueArray={formValueArray}
        maxFiles={maxFiles}
        accept={accept}
        filetypes={filetypes}
        maxFileSize={maxFileSize}
        handleFormValues={handleFormValues}
        coverPhotos={coverPhotos}
        columns={columns}
        imgMaxWidth={imgMaxWidth}
        imgMaxHeight={imgMaxHeight}
        aspectRatio={aspectRatio}
        requireImageCrop={requireImageCrop}
      />
    </Grid>
  );
}

export default FileUploadV2;