import { Box, ButtonBase, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";
import clsx from "clsx";
import Carousel from "react-multi-carousel";
import ProductView from "./ProductView";
import "react-multi-carousel/lib/styles.css";
import React from "react";
import { getListOfProductsById } from "../util/getProductsById";

import useMobileMediaQuery from "../hooks/useMobileMediaQuery";

//Default Prop Values
import mockDataDefault from "../json/mockAPIdata.json";

const responsive = {
  break1: {
    breakpoint: { max: 4000, min: 1297 },
    items: 6,
  },
  break2: {
    breakpoint: { max: 1297, min: 1103 },
    items: 5,
  },
  break3: {
    breakpoint: { max: 1103, min: 870 },
    items: 4,
  },
  break4: {
    breakpoint: { max: 870, min: 700 },
    items: 3,
  },
  break5: {
    breakpoint: { max: 700, min: 390 },
    items: 2,
  },
  break6: {
    breakpoint: { max: 390, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles({
  navButtons: {
    position: "absolute",
    top: "39%",
    height: 75,
    border: "1px solid var(--mintArrow)",
    borderRadius: 15,
    backgroundColor: "#FFFFFF",
  },
  prevButton: {
    left: -50,
  },
  nextButton: {
    right: -50,
  },
  divContainer: {
    position: "relative",
    margin: "auto",
  },
  arrows: {
    color: "var(--mintArrow)",
    fontSize: 30,
  },
  carouselContainer: {
    width: "100%",
    paddingBottom: 15,
  },
});

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  const classes = useStyles();
  return (
    <>
      <ButtonBase
        className={clsx(classes.navButtons, classes.prevButton)}
        onClick={previous}
      >
        <ArrowBack className={classes.arrows} />
      </ButtonBase>
      <ButtonBase
        className={clsx(classes.navButtons, classes.nextButton)}
        onClick={next}
      >
        <ArrowForward className={classes.arrows} />
      </ButtonBase>
    </>
  );
};

const CustomDot = ({ onClick, active }) => {
  return (
    <ButtonBase
      onClick={(e) => {
        onClick();
        e.preventDefault();
      }}
      sx={{ mx: 0.5, borderRadius: 5 }}
    >
      <Box
        sx={{
          width: 10,
          height: 10,
          borderRadius: 5,
          backgroundColor: [active ? "var(--coral)" : "var(--beige)"],
        }}
      />
    </ButtonBase>
  );
};

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

const CarouselProductView = ({
  data,
  customResponsive,
  supportPurpose = false,
  hideSeller = false,
  hidePurpose = false,
}) => {
  const classes = useStyles();
  const mobile = useMobileMediaQuery();
  const [productData, setProductData] = React.useState([]);
  React.useEffect(() => {
    if (!data) {
      getListOfProductsById(mockDataDefault, (retrievedData) => {
        if (
          !retrievedData.hasOwnProperty("error") &&
          !retrievedData.hasOwnProperty("message")
        ) {
          shuffle(retrievedData)
          setProductData(retrievedData);
        }
      });
    } else {
      setProductData(data);
    }
  }, [data]);

  return (
    <Container maxWidth="xl">
      <div
        className={classes.divContainer}
        style={{ width: mobile ? "100%" : "90%" }}
      >
        {productData && productData.length > 0 && (
          <Carousel
            responsive={customResponsive ? customResponsive : responsive}
            infinite={false}
            showDots={mobile}
            arrows={false}
            renderButtonGroupOutside={!mobile}
            customButtonGroup={<CustomButtonGroupAsArrows />}
            customDot={<CustomDot />}
            containerClass={classes.carouselContainer}
          >
            {productData.map((item, i) => {
              return (
                <ProductView
                  info={item}
                  key={i+'productcarosel'}
                  supportPurpose={supportPurpose}
                  hideSeller={hideSeller}
                  hidePurpose={hidePurpose}
                  mobile={mobile}
                />
              );
            })}
          </Carousel>
        )}
      </div>
    </Container>
  );
};

export default CarouselProductView;
