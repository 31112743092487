import {
  Avatar,
  Box,
  ButtonBase,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { PurposeCategoryMasterLayout } from "..";
import Blog from "../../components/Blog";
import BlogArticle from "../../components/BlogArticle";
import SignUp from "../../components/SignUp";
import { useParams, Link } from "react-router-dom";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import blogPosts from "../../json/blog_posts.json";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useDocumentDescription from "../../hooks/useDocumentDescription";
import { getBlog } from "../../util/blogs";

const classes = {
  coralBackground: {
    height: "var(--dividerHeight)",
    backgroundColor: "var(--coral)",
  },
  margins: {
    my: "var(--dividerHeight)",
  },
};

const BlogArticleLayout = () => {

  let { id } = useParams();

  const [blogData, setBlogData] = React.useState({});

  const [loadingBlog, setLoadingBlog] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (!loadingBlog) {
      setLoadingBlog(true)
      getBlog(id, (data) => {
        setBlogData(data.results[0]);
      })
    }
  }, [blogData]);

  useDocumentTitle(
    blogData.hasOwnProperty("title")
      ? blogData.title.replace(/^(.{60}[^\s]*).*/, "$1")
      : "Purposer"
  );
  useDocumentDescription(blogData.hasOwnProperty("body")
    ? blogData.title.replace(/\W+/g, " ").replace(/^(.{160}[^\s]*).*/, "$1") + " Shop on Purposer.com"
    : "Purposer"
  );

  return (
    <PurposeCategoryMasterLayout>
      <BlogArticle blogData={blogData} />
      <Container maxWidth={"md"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 5,
          }}
        >
          <ButtonBase
            sx={{
              border: "1px solid black",
              borderRadius: 2,
              pl: 1.6,
            }}
            component={Link}
            to={"/blog"}

          >
            <Typography>Back to All Blogs</Typography>
            <ArrowRightIcon sx={{ fontSize: "4.1875rem" }} />
          </ButtonBase>
        </Box>
      </Container>
       {/* This logic to share will be added in MVP 2.0 */}
      {/* <Box sx={{ bgcolor: "var(--lightPastelMintGreen)", py: 3 }}>
        <Container maxWidth={"md"}>
          <Grid container alignItems={"center"}>
            <Grid item xs={12} sm={6}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Typography variant="h6">Blog By:</Typography>
                <Avatar sx={{ width: 130, height: 130 }} />
                <Typography variant="h6">
                  <i>{"Cindy Louwho"}</i>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                gap={3}
              >
                <Typography variant="h4">
                  <b>Share Blog</b>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 5,
                  }}
                >
                  {socialMediaButtons.map((item, index) => {
                    return (
                      <a
                        href={item.link}
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <img src={item.icon} alt="" style={{ height: 45 }} />
                        </Box>
                        <Typography>
                          <b>{item.name}</b>
                        </Typography>
                      </a>
                    );
                  })}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box> */}
      {/* <SignUp /> */}
      <Box sx={classes.coralBackground} />
      <Box sx={classes.margins}>
        <Blog blogPosts={blogPosts} />
      </Box>
    </PurposeCategoryMasterLayout>
  );
};

const socialMediaButtons = [
  {
    link: "#",
    icon: "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/footer/Facebook+icon.png",
    name: "Facebook",
  },
  {
    link: "#",
    icon: "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/footer/Twitter+icon.png",
    name: "Twitter",
  },
  {
    link: "#",
    icon: "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/footer/Pinterest+Icon.+png.png",
    name: "Pinterest",
  },
];

export default BlogArticleLayout;
