import * as React from 'react';
import {
  Container,
  Typography,
  Grid,
  Select,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonBase
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Bar, Pie } from "react-chartjs-2";
import clsx from "clsx";
import MenuIcon from '@mui/icons-material/Menu';

const useStyles = makeStyles({
  title: {
    fontSize: "2.5rem",
    // marginBottom: 20,
    color: "var(--coral)"
  },
  fullBorder: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#E0E0E0",
  },
  middlePieBorder: {
    borderTopStyle: "solid",
    borderBottomStyle: "solid",
    borderWidth: 1,
    borderColor: "#E0E0E0",
  },
  topVerticalBar: {
    marginBottom: 25,
  },
  graphWidth: {
    width: "80%",
    paddingBottom: 15,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  roundedRectangle: {
    backgroundColor: "var(--coral)",
    borderRadius: "20px",
    width: "300px",
    padding: "10px",
    display: "inline-block"
  },
  roundedRectangle2: {
    backgroundColor: "var(--coral)",
    borderRadius: "20px",
    width: "450px",
    padding: "10px",
    display: "inline-block"
  },
  roundedRectangleText: {
    fontSize: "1.25rem",
    // marginBottom: 20,
    color: "white"
  },
  roundedRectangleText2: {
    fontSize: "2.5rem",
    // marginBottom: 20,
    color: "white",
    display: "center"
  },
  formControl: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 7,
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--mintGreen)",
        color: "var(--mintGreen)",
      },
    },
  },
});

const data1 = {
  labels: ["", "", "", "", "", "", "", "", "", "", "", "", ""],
  datasets: [
    {
      data: [5, 2, 6, 7, 8, 4, 5, 6, 7, 2, 7, 3, 5],
      backgroundColor: ["rgba(128, 170, 158, 1)"],
    },
  ],
};

const data2 = {
  datasets: [
    {
      backgroundColor: ["rgba(128, 170, 158, 1)"],
      borderColor: ["rgba(128, 170, 158, 1)"],
      data: [100],
    },
    {
      backgroundColor: ["rgba(255, 255, 255, 1)", "rgba(128, 170, 158, 1)"],
      borderColor: ["rgba(128, 170, 158, 1)"],
      data: [85, 15],
    },
    {
      backgroundColor: ["rgba(128, 170, 158, 1)"],
      borderColor: ["rgba(128, 170, 158, 1)"],
      data: [100],
    },
    {
      backgroundColor: ["rgba(128, 170, 158, 1)"],
      borderColor: ["rgba(128, 170, 158, 1)"],
      data: [100],
    },
    {
      backgroundColor: ["rgba(128, 170, 158, 1)"],
      borderColor: ["rgba(128, 170, 158, 1)"],
      data: [100],
    },
  ],
};

const data3 = {
  datasets: [
    {
      backgroundColor: ["rgba(128, 170, 158, 1)"],
      borderColor: ["rgba(128, 170, 158, 1)"],
      data: [100],
    },
    {
      backgroundColor: ["rgba(255, 255, 255, 1)", "rgba(128, 170, 158, 1)"],
      borderColor: ["rgba(128, 170, 158, 1)"],
      data: [20, 80],
    },
    {
      backgroundColor: ["rgba(255, 255, 255, 1)", "rgba(128, 170, 158, 1)"],
      borderColor: ["rgba(128, 170, 158, 1)"],
      data: [20, 80],
    },
  ],
};

const options1 = {
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      min: 0,
      ticks: {
        display: true,
        precision: 0
      },
      grid: {
        display: false,
      },
    },
    xAxis: {
      display: false,
    },
    yAxis: {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const options2 = {};

const VerticalBar = (props) => (
  <>
    <Bar data={props.data} options={options1} />
  </>
);

const MiniMeter = () => (
  <>
    <Pie data={data2} options={options2} />
  </>
);

const MiniPie = () => (
  <>
    <Pie data={data3} options={options2} />
  </>
);

function SellerStatsChart(props) {
  const { salesData, salesAmount, selectedSalesFilter, orderData, orderCount, selectedOrderFilter } = props;
  const classes = useStyles();

  return (
    <div>
      <Container maxWidth="xl">
        {/* <Grid container justifyContent="space-between">
          <Grid item>
            <div className="header">
              <Typography className={classes.title}>
                <b>{orderData? "Orders" : "Sales Revenue"}</b>
              </Typography>
            </div>
          </Grid>
        </Grid> */}
        <div className={orderData? classes.roundedRectangle : classes.roundedRectangle2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <div className={classes.roundedRectangleText}><b>{orderData? "Total Orders" : "Total Sales Revenue"}</b></div>
            <div className={classes.roundedRectangleText}><i>{orderData? selectedOrderFilter : selectedSalesFilter}</i></div>
          </Grid>
          <Grid item>
            <div className={classes.roundedRectangleText2}><b>{orderData? orderCount : "$"+salesAmount}</b></div>
          </Grid>
        </Grid>
          </div>
        <div style={{height: "var(--dividerHeight)"}}/>
          <Grid
            item
            xs={12}
            className={clsx(classes.fullBorder, classes.topVerticalBar)}
          >
            <VerticalBar data={orderData? orderData : salesData}/>
          </Grid>
      </Container>
    </div>
  );
}

export default SellerStatsChart;
