import React, { useState, useRef } from "react";
import { Typography, Grid, Modal, TextField, Button, FormGroup, FormControlLabel, Checkbox, Select, MenuItem, FormControl, Input, InputLabel, Divider, IconButton, Menu, Icon } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { getOrderById } from "../util/orders";
import { round, shortenUUID, formatDate, outputViewProductVariationSize } from "../util/utilities";
import { useReactToPrint } from 'react-to-print';
import { RETURN_DENY_REASON, RETURN_STATUS } from "../util/constants";
import OrderReturnConfirmPopup from "./OrderReturnConfirmPopup";
import { denyRefundRequestByReturnId } from "../util/returns";
import { useParams } from "react-router-dom";
import OrderReturnDenyPopup from "./OrderReturnDenyPopup";

const useStyles = makeStyles({
    subheader: {
        color: "black",
        //fontSize: "1.25rem",
    },
    tabelLabel: {
        color: "black",
        fontWeight: "500",
        //fontSize: "1.25rem",
    },
    orderLabel: {
        color: "var(--coral)",
        fontSize: "16px"
    }
});

const style = {
    position: 'absolute',
    // top: '50%',
    //left: '50%',
    borderRadius: '16px',
    backgroundColor: "white",
    //transform: 'translate(-50%, -50%)',
    // display: "block",
    minWidth: "40%",
    maxHeight: "90vh",
    overflow: "auto",
    // height: "auto",
    border: "2px solid",
    zIndex: "2",
    // padding: "32px",
    boxShadow: 24,
};

const modalCenter = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

const MoreActions = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { returnID, returnStatus, handlePrint, setDenyPopupView, setDenyReason } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { id: sellerIDparam } = useParams();

    const ITEM_HEIGHT = 48;
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // const handleDenyRefundRequestByReturnId = async (e, denyReason) => {
    //     if (!isLoading && returnID) {
    //         e.preventDefault();
    //         setIsLoading(true);
    //         denyRefundRequestByReturnId(sellerIDparam, returnID, denyReason, (data) => {
    //             if (data.error || data?.results == false || data?.results?.success == false) {
    //                 console.log('Error handling denying of a Refund!')
    //                 setIsLoading(false);
    //                 setAnchorEl(null);
    //             } else {
    //                 console.log('Success handling denying of Refund!')
    //                 setIsLoading(false);
    //                 setAnchorEl(null);
    //                 window.location.reload()
    //             }
    //         });
    //     }
    // }

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        minWidth: '45ch',
                        overflow: "visible"
                    },
                }}
            >
                <MenuItem key={"Print"} onClick={handlePrint}>
                    Print
                </MenuItem>
                {returnStatus == RETURN_STATUS.PENDING &&
                    (
                    <>
                        {/* <MenuItem key={"DenyReturn"} onClick={(e) => {handleDenyRefundRequestByReturnId(e, RETURN_DENY_REASON.NOT_ACCEPTABLE_CONDITION); handleClose();}}>
                            {`Deny Return (${RETURN_DENY_REASON.NOT_ACCEPTABLE_CONDITION})`}
                        </MenuItem> */}
                        <MenuItem key={"DenyReturn"} onClick={(e) => {setDenyPopupView(true); setDenyReason(RETURN_DENY_REASON.NOT_ACCEPTABLE_CONDITION); handleClose();}}>
                            {`Deny Return (${RETURN_DENY_REASON.NOT_ACCEPTABLE_CONDITION})`}
                        </MenuItem>
                        {/* <MenuItem key={"NeverReceived"} onClick={(e) => {handleDenyRefundRequestByReturnId(e, RETURN_DENY_REASON.NEVER_RECEIVED); handleClose();}}>
                            {`Deny Return (${RETURN_DENY_REASON.NEVER_RECEIVED})`}
                        </MenuItem> */}
                        <MenuItem key={"NeverReceived"} onClick={(e) => {setDenyPopupView(true); setDenyReason(RETURN_DENY_REASON.NEVER_RECEIVED); handleClose();}}>
                            {`Deny Return (${RETURN_DENY_REASON.NEVER_RECEIVED})`}
                        </MenuItem>
                        {/* <MenuItem key={"Other"} onClick={(e) => {handleDenyRefundRequestByReturnId(e, RETURN_DENY_REASON.OTHER); handleClose();}}>
                            {`Deny Return (${RETURN_DENY_REASON.OTHER})`}
                        </MenuItem> */}
                        <MenuItem key={"Other"} onClick={(e) => {setDenyPopupView(true); setDenyReason(RETURN_DENY_REASON.OTHER); handleClose();}}>
                            {`Deny Return (${RETURN_DENY_REASON.OTHER})`}
                        </MenuItem>
                    </>
                    )}
            </Menu>
        </div>
    );
}

const OrderReturnPopup = (props) => {
    const { row, setOrderView, handleRefundByReturnId, isLoading, confirmPopupView, setConfirmPopupView, denyPopupView, setDenyPopupView } = props;

    const classes = useStyles();
    const [orderDetails, setOrderDetails] = React.useState(null);
    const [orderReturnDetails, setOrderReturnDetails] = React.useState(null);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({ content: () => componentRef.current, });
    const { id: sellerIDparam } = useParams();

    React.useEffect(() => {
        if (!orderReturnDetails && row) {
            getOrderById(row.orderID, (data) => {
                if (data) {
                    setOrderDetails(data);
                    const currOrderRefund = data.refundRequests.find(r => r.returnID == row.returnID);
                    setOrderReturnDetails(currOrderRefund);
                } else {
                    console.log("Can't find products!")
                }
            });
        }
    }, []);

    const [denyIsLoading, setDenyIsLoading] = React.useState(false);
    const [denyReason, setDenyReason] = React.useState(null);
    const handleDenyRefundRequestByReturnId = async (e, denyReason) => {
      if (!denyIsLoading && row?.returnID) {
          e.preventDefault();
          setDenyIsLoading(true);
          denyRefundRequestByReturnId(sellerIDparam, row?.returnID, denyReason, (data) => {
              if (data.error || data?.results == false || data?.results?.success == false) {
                  console.log('Error handling denying of a Refund!')
                  setDenyIsLoading(false);
                  // setAnchorEl(null);
              } else {
                  console.log('Success handling denying of Refund!')
                  setDenyIsLoading(false);
                  // setAnchorEl(null);
                  window.location.reload()
              }
          });
      }
  }

    return (
        <>
            {confirmPopupView ? <OrderReturnConfirmPopup isLoading={isLoading || denyIsLoading} row={row} confirmPopupView={confirmPopupView} setConfirmPopupView={setConfirmPopupView} handleRefundByReturnId={handleRefundByReturnId} /> : <></>}
            {denyPopupView && <OrderReturnDenyPopup isLoading={isLoading || denyIsLoading} row={row} denyPopupView={denyPopupView} setDenyPopupView={setDenyPopupView} returnID={row?.returnID} returnStatus={row.status} handleDenyReturn={handleDenyRefundRequestByReturnId} denyReason={denyReason}/>}
            <Modal open={true} onClose={() => setOrderView(-1)} sx={modalCenter}>
            <Box sx={style}>
                <div style={{ padding: "32px" }} ref={componentRef}>
                    <Grid container justifyContent='space-between' style={{ marginBottom: "20px" }} >
                        <IconButton onClick={() => setOrderView(-1)}>
                          <CloseIcon/>
                        </IconButton>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {!isLoading && row.status == RETURN_STATUS.PENDING && handleRefundByReturnId !== null && row?.returnID && orderReturnDetails?.products.length > 0 ?
                                <Button onClick={(e) => {
                                    setConfirmPopupView(true);
                                }} variant="contained" style={{ marginRight: "8px", backgroundColor: "var(--mintGreenSolid)" }}
                                >
                                    <b>{`Confirm Return and Refund`}</b>
                                </Button>
                                : <Button disabled>
                                    <b>{row.status == RETURN_STATUS.COMPLETED || row.status == RETURN_STATUS.DENIED ? `` : `Loading...`}</b>
                                </Button>
                            }
                            <MoreActions returnID={row?.returnID} returnStatus={row.status} handlePrint={handlePrint} setDenyPopupView={setDenyPopupView} setDenyReason={setDenyReason}/>
                            {/* <FormControl fullWidth>
                        <InputLabel id="more-actions">More</InputLabel>
                        <Select
                            onChange={(e) => { setMoreAction(e.target.value) }}
                            label="More"
                        >
                            <MenuItem value="print">Print</MenuItem>
                            <MenuItem value="refund">Refund</MenuItem>
                        </Select>
                    </FormControl> */}
                        </div>
                    </Grid>
                    <Typography variant="h6" className={classes.orderLabel}>
                        {`ORDER NO. ${shortenUUID(row.orderID)}`}
                    </Typography>
                    <Typography variant="h6" component="h2" >
                        {formatDate(orderDetails?.orderDateTime)}
                    </Typography>
                    <Typography style={{ margin: "8px 0px" }} variant="h6" className={classes.orderLabel}>
                        ORDER STATUS
                    </Typography>
                    <Typography >
                        {orderDetails?.status}
                    </Typography>
                    <Typography style={{ margin: "8px 0px" }} variant="h6" className={classes.orderLabel}>
                        RETURN ID
                    </Typography>
                    <Typography >
                        {shortenUUID(row.returnID)}
                    </Typography>
                    <Typography style={{ margin: "8px 0px" }} variant="h6" className={classes.orderLabel}>
                        RETURN STATUS
                    </Typography>
                    <Typography >
                        {row.status}
                    </Typography>
                    <Typography style={{ margin: "8px 0px" }} variant="h6" className={classes.orderLabel}>
                        RETURN REQUEST DATE
                    </Typography>
                    <Typography style={{ margin: "0px 0px" }} className={classes.subheader}>
                        {formatDate(row.requestDateTime)}
                    </Typography>
                    <Divider style={{ margin: "16px 0px" }} />
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className={classes.tabelLabel}>Items in Return Request</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.tabelLabel}>Price</Typography>
                        </Grid>
                        <Grid item xs={2} >
                            <Typography className={classes.tabelLabel}>Qty</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.tabelLabel}>Total</Typography>
                        </Grid>
                    </Grid>
                    <Divider style={{ margin: "16px 0px" }} />
                    {orderReturnDetails?.products.length > 0 && orderReturnDetails.products.map((productDetail, index) =>
                        <div key={productDetail.productID + row.orderID}>
                            <Grid container >
                                <Grid item xs={6}>
                                    <Grid container direction="row" justifyContent="flex-start" columnSpacing={2}>
                                        <Grid item>
                                            <img src={productDetail.product.product.productImageURL[0]} style={{ maxWidth: "100px", height: "auto" }} />
                                        </Grid>
                                        <Grid item>
                                            <Typography><b>{productDetail.product.product.productName}</b></Typography>
                                            <Typography>Size/Style: {outputViewProductVariationSize(productDetail.product.product.option.size)}</Typography>
                                            <Typography>Color: {productDetail.product.product.option.color}</Typography>
                                            <br></br>
                                            <Typography>Reason: {productDetail?.returnReason}</Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={2} >
                                    <Typography>${round(productDetail.product.price, 2)}</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <Typography>{productDetail.quantity}</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <Typography>${round((productDetail.product.price * parseInt(productDetail.quantity)), 2)}</Typography>
                                </Grid>
                            </Grid>
                            <Divider style={{ margin: "16px 0px" }} />
                        </div>
                    )}
                    <Grid container>
                        <Grid item xs={6} sm={6}></Grid>
                        <Grid item xs={6}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Typography><b>Items</b></Typography>
                                <Typography>${round(row.totalItemsAmt,2)}</Typography>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Typography><b>Shipping</b></Typography>
                                <Typography>${round(row.totalShippingAmt,2)}</Typography>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Typography><b>Tax</b></Typography>
                                <Typography>${round(row.totalTaxAmt,2)}</Typography>
                            </div>
                            <Divider style={{ margin: "8px 0px" }} />
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Typography><b>GRAND TOTAL</b></Typography>
                                <Typography>${round((row.paymentAmount),2)}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Divider style={{ margin: "32px 0px" }} />
                    <Typography variant="h6" component="h2" style={{ color: "var(--coral)", marginBottom: "12px" }}>
                        {`Buyer Information`}
                    </Typography>
                    {/* PLACEHOLDER BUYER DATA */}
                    {/* <Grid container rowSpacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography><b>Ship to:</b></Typography>
                            <Typography>{`${orderDetails?.shipping?.name}`}</Typography>
                            <Typography>{orderDetails?.shipping?.address?.line1}</Typography>
                            <Typography>{orderDetails?.shipping?.address?.line2}</Typography>
                            <Typography>{`${orderDetails?.shipping?.address?.city} ${orderDetails?.shipping?.address?.state}`}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography><b>Shipping Speed</b></Typography>
                            <Typography>{orderDetails?.shippingName}</Typography>
                            <Typography>{orderDetails?.shippingService}</Typography>
                        </Grid>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography>{`Customer name: ${orderDetails?.shipping?.name}`}</Typography>
                        <br></br>
                        <Typography><b>{`${orderDetails?.seller?.returnPolicy.policy}`}</b></Typography>
                        <br></br>
                        <Typography>{`Email to: ${orderDetails?.shipping?.email}`}</Typography>
                    </Grid>
                </div>
            </Box>
            </Modal>
        </>
    );
};

export default OrderReturnPopup;
