import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CarouselProductView from "./CarouselProductView";
import clsx from "clsx";

const useStyles = makeStyles({
  backgroundDiv: {
    backgroundColor: "var(--lightPastelMintGreen)",
    overflow: "hidden",
    paddingBottom: "var(--dividerHeight)",
  },
  text: {
    margin: "20px 0 40px 0",
  },
});

const RecentlyViewed = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.backgroundDiv}>
      <Typography
        align="center"
        variant="h4"
        className={clsx(classes.text, "roboto-medium")}
      >
        {/* RECENTLY VIEWED */}
        TRENDING
      </Typography>
      <CarouselProductView data={data} />
    </div>
  );
};

export default RecentlyViewed;
