import {
  Grid,
  Typography,
  MenuItem,
  Modal,
  Box,
  Container,
  TextField,
  IconButton,
  Button,
  Menu,
  Link,
} from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatDate } from "../util/utilities";
import { updateCoupon, deleteCoupon } from "../util/coupons";
import { useParams } from "react-router-dom";

const style = {
  position: 'absolute',
  borderRadius: '16px',
  backgroundColor: "white",
  minWidth: "40%",
  maxHeight: "90vh",
  overflow: "auto",
  border: "2px solid",
  zIndex: "2",
  paddingTop: "32px",
  paddingBottom: "32px",
  boxShadow: 24,
};

const styleConfirm = {
  position: 'absolute',
  backgroundColor: "white",
  maxWidth: { xs: "75%", sm: "75%", md: "40%" },
  border: "2px solid",
  zIndex: "2",
  padding: "32px",
  boxShadow: 24,
};

const modalCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

const DeleteConfirmPopup = (props) => {
  const { deletePopup, setDeletePopup, handleDelete, isLoading } = props;
  return (
    <Modal open={deletePopup} onClose={() => setDeletePopup(false)} sx={modalCenter}>
      <Box sx={styleConfirm}>
        <Grid container justifyContent='center' style={{  }}>
          
          <Typography variant="h5" style={{ color: "var(--coral)", fontWeight: 'bold' }} >
            CAUTION!
          </Typography>
          
        </Grid>
        <Typography variant="h6" style={{ fontWeight: "400", textAlign: "center"}}>Are you sure you want to delete and end this SALE? You cannot undo this process.</Typography>
        <div style={{ height: "16px" }} />
        <div style={{ display: "flex", flexDirection: "row", columnGap: "2", justifyContent: "center" }}>
          <Button
            onClick={() => setDeletePopup(false)}
            variant="contained"
            style={{ marginRight: "8px", backgroundColor: "gray" }}
          >
            <b>{`Go Back`}</b>
          </Button>
          <Button
            onClick={(e) => {
              handleDelete(e);
            }}
            disabled={isLoading}
            variant="contained" style={{ marginRight: "8px", backgroundColor: "var(--mintGreenSolid)" }}
          >
            <b>{isLoading ? `Loading` : `Delete Sale`}</b>

          </Button>
        </div>
      </Box>
    </Modal>
  )
}

const MoreActions = (props) => {
  const { deletePopup, setDeletePopup } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);


  const ITEM_HEIGHT = 48;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem key={"Delete"} onClick={() => setDeletePopup(true)}>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}

const SalesView = (props) => {
  const { saleView, setSaleView, sale, productList } = props;
  const [deletePopup, setDeletePopup] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [newSaleName, setNewSaleName] = React.useState('');
  const { id: sellerIDparam } = useParams();

  const handleUpdate = async (e) => {
    if (!isLoading) {
      e.preventDefault();
      setIsLoading(true);

      if (!newSaleName || !sale.couponID || sale.couponCode == newSaleName) {
        console.log('Not a valid change!')
        setIsLoading(false);
      } else {
        //Good to update coupon
        updateCoupon(sellerIDparam, sale.couponID, newSaleName, (data) => {
          if(data.success) {
            console.log('Updated sale!')
            window.location.reload();
          } else {
            console.log('Failed to update sale!')
          }
          setIsLoading(false);
        });
      }
    }
  }

  const handleDelete = async (e) => {
    if (!isLoading) {
      e.preventDefault();
      setIsLoading(true);

      if (!sale.couponID) {
        console.log('Not a valid coupon ID!')
        setIsLoading(false);
      } else {
        //Good to update coupon
        deleteCoupon(sellerIDparam, sale.couponID, (data) => {
          if(data.success) {
            console.log('Deleted sale!')
            window.location.reload();
          } else {
            console.log('Failed to delete sale!')
          }
          setIsLoading(false);
        });
      }
    }
  }

  return (
    <>
      <DeleteConfirmPopup isLoading={isLoading} handleDelete={handleDelete} deletePopup={deletePopup} setDeletePopup={setDeletePopup} />
      <Modal open={saleView} onClose={() => setSaleView(false)} sx={modalCenter}>
        <Box sx={style}>
          <Container>
            <Grid container justifyContent='space-between'>
              <Typography variant="h5" style={{ color: "var(--coral)" }}>
                <b>VIEW SALE</b>
              </Typography>
              <div style={{ display: "flex", flexDirection: "row", columnGap: 4 }}>
                {sale?.active && <MoreActions deletePopup={deletePopup} setDeletePopup={setDeletePopup} />}
                <IconButton onClick={() => setSaleView(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
            </Grid>
            <Box sx={{ pt: 2 }}>
              <Typography variant="h6">Name of Sale</Typography>
              <TextField 
                defaultValue={sale.couponCode}
                onChange={(e) => {
                  setNewSaleName(e.target.value);
                }}

              />
            </Box>
            <Box sx={{ pt: 2 }}>
              <Typography variant="h6">Date Created</Typography>
              <Typography>{formatDate(sale.startDateTime)}</Typography>
            </Box>
            <Box sx={{ pt: 2 }}>
              <Typography variant="h6">Terms of Sale</Typography>
              <Typography>
                {sale.amountOff && !isNaN(sale.amountOff) && Number(sale.amountOff) > 0 ? `$${sale.amountOff} OFF` : `${sale.percentOff}% OFF`}
              </Typography>
            </Box>
            <Box sx={{ pt: 2 }}>
              <Typography variant="h6">Duration</Typography>
              <Typography>{`${formatDate(sale.startDateTime)} - ${formatDate(sale.expirationDateTime) || 'No End Date'}`}</Typography>
            </Box>
            <Box sx={{ pt: 2 }}>
              <Typography variant="h6">Applicable Products</Typography>
              {productList?.map((product, index) => {
                  if(sale?.skuIDs?.includes(product.skuID)) {
                    const MAX_PRODUCT_NAME_CHARACTER_LENGTH = 36;
                    return (
                      <Link href={`/shop/product/${product.productID}/${product.productName?.replace(/\W+/g, "-")}`} color="inherit" variant="h1" fontSize="1rem">
                        {`${product.name?.substring(0,MAX_PRODUCT_NAME_CHARACTER_LENGTH)}${product.name?.length > MAX_PRODUCT_NAME_CHARACTER_LENGTH ? '...' : ''}`}
                        <br></br>
                      </Link>
                    )
                  }
              })}
            </Box>
            <Box sx={{ pt: 2 }}>
              <Typography variant="h6">Maximum Uses</Typography>
              <Typography>{sale.maxRedemptions != null ? `${sale.maxRedemptions} Times` : 'Unlimited'}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", columnGap: 2, mt: 2 }}>
              <Button variant="contained" sx={{ backgroundColor: "darkgray" }} size="large"
                onClick={() => setSaleView(false)}
              >
                <Typography>
                  <b>Cancel</b>
                </Typography>
              </Button>
              <Button variant="contained" sx={{ backgroundColor: "var(--coral)" }} size="large"
                onClick={(e)=> {
                  handleUpdate(e);
                }}
                disabled={isLoading || newSaleName == sale.couponCode || !newSaleName}
              >
                <Typography>
                  <b>{isLoading ? `Loading` : `Save`}</b>
                </Typography>
              </Button>
            </Box>
          </Container>
        </Box>
      </Modal>
    </>
  );
};

export default SalesView;