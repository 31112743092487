import {
  Container,
  Divider,
  Grid,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import otherCategoriesData from "../json/other_categories.json";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";

const useStyles = makeStyles({
  otherCategoriesDiv: {
    backgroundColor: "var(--mintGreenSolid)",
    paddingBottom: 20,
  },
  otherCategoriesImage: {
    maxWidth: "100%",
    borderRadius: 15,
  },
  otherCategoriesTallImage: {
    height: "100%",
    objectFit: "cover",
  },
  otherCategoriesWideImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    marginBottom: 20,
  },
  imageContainer: {
    width: "100%",
    height: 300,
  },
  otherCategoriesHeader: {
    fontSize: "2.25rem",
    marginBottom: 5,
    color: "white",
  },
  otherCategoriesSubtitle: {
    fontSize: "1.2rem",
    marginBottom: 1,
    marginTop: 20,
    color: "white",
  },
  dividers: {
    borderColor: "white",
    marginBottom: 5,
  },
});

function OtherCategories(props) {
  const classes = useStyles();
  const mobile = useMobileMediaQuery();
  let { otherCategories = otherCategoriesData } = props;

  return (
    <div className={classes.otherCategoriesDiv}>
      <Container maxWidth="xl">
        <Grid container direction="row" columnSpacing={11}>
          <Grid item md={6} sm={12}>
            {otherCategories.length >= 1 && (
              <div>
                <Typography className={classes.otherCategoriesSubtitle}>
                  <i>{otherCategories[0].byline}</i>
                </Typography>
                <a
                  href={
                    "/shop/category/" +
                    otherCategories[0].category +
                    "/" +
                    otherCategories[0].subcategory
                  }
                >
                  <Typography className={classes.otherCategoriesHeader}>
                    {otherCategories[0].description}
                  </Typography>
                  <Divider
                    sx={{
                      borderColor: "white",
                      borderWidth: 1,
                      marginBottom: 2,
                    }}
                  />
                </a>
                <Box sx={{ width: "100%", height: [mobile ? 450 : 713] }}>
                  <a
                    href={
                      "/shop/category/" +
                      otherCategories[0].category +
                      "/" +
                      otherCategories[0].subcategory
                    }
                  >
                    <img
                      src={otherCategories[0].img}
                      alt=""
                      className={clsx(
                        classes.otherCategoriesImage,
                        classes.otherCategoriesTallImage
                      )}
                    />
                  </a>
                </Box>
              </div>
            )}
          </Grid>
          <Grid item md={6} sm={12}>
            <Stack>
              {otherCategories.length >= 2 && (
                <>
                  <Typography className={classes.otherCategoriesSubtitle}>
                    <i>{otherCategories[1].byline}</i>
                  </Typography>
                  <a
                    href={
                      "/shop/category/" +
                      otherCategories[1].category +
                      "/" +
                      otherCategories[1].subcategory
                    }
                  >
                    <Typography className={classes.otherCategoriesHeader}>
                      {otherCategories[1].description}
                    </Typography>
                    <Divider
                      sx={{
                        borderColor: "white",
                        borderWidth: 1,
                        marginBottom: 2,
                      }}
                    />
                  </a>
                  <a
                    href={
                      "/shop/category/" +
                      otherCategories[1].category +
                      "/" +
                      otherCategories[1].subcategory
                    }
                  >
                    <Box sx={{ width: "100%", height: [mobile ? 200 : 300] }}>
                      <img
                        src={otherCategories[1].img}
                        alt=""
                        className={clsx(
                          classes.otherCategoriesImage,
                          classes.otherCategoriesWideImage
                        )}
                      />
                    </Box>
                  </a>
                </>
              )}
              {otherCategories.length >= 3 && (
                <>
                  <Typography className={classes.otherCategoriesSubtitle}>
                    <i>{otherCategories[2].byline}</i>
                  </Typography>
                  <a
                    href={
                      "/shop/category/" +
                      otherCategories[2].category +
                      "/" +
                      otherCategories[2].subcategory
                    }
                  >
                    <Typography className={classes.otherCategoriesHeader}>
                      {otherCategories[2].description}
                    </Typography>
                    <Divider
                      sx={{
                        borderColor: "white",
                        borderWidth: 1,
                        marginBottom: 2,
                      }}
                    />
                  </a>
                  <a
                    href={
                      "/shop/category/" +
                      otherCategories[2].category +
                      "/" +
                      otherCategories[2].subcategory
                    }
                  >
                    <Box sx={{ width: "100%", height: [mobile ? 200 : 300] }}>
                      <img
                        src={otherCategories[2].img}
                        alt=""
                        className={clsx(
                          classes.otherCategoriesImage,
                          classes.otherCategoriesWideImage
                        )}
                      />
                    </Box>
                  </a>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default OtherCategories;
