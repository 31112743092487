import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  Menu
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  MoreVert
} from "@mui/icons-material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import OrderReturnPopup from "./OrderReturnPopup";
import { round, shortenUUID, formatDate } from '../util/utilities'
import OrderReturnConfirmPopup from "./OrderReturnConfirmPopup";
import { APPROVAL_STATUS } from "../util/constants";
import { Link } from "aws-amplify-react";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  noOrdersCell: {
    border: "none",
  },
  tableRows: {
    backgroundColor: "",
  },
}));

const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        size="large"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        size="large"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="large"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        size="large"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const StoresAwaitingApproval = (props) => {
  const { handleSubmit, storeData, filter, isLoading, title, setStoreData, setFilter } = props;
  const classes = useStyles1();
  const [orderView, setOrderView] = useState(-1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [confirmPopupView, setConfirmPopupView] = React.useState(false);

  const emptyRows = storeData
    ? rowsPerPage -
    Math.min(rowsPerPage, storeData.length - page * rowsPerPage)
    : 4;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const MoreActions = (props) => {
    const { status, sellerID, handleSubmit } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const ITEM_HEIGHT = 48;
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          {(status == APPROVAL_STATUS.PENDING || status == APPROVAL_STATUS.APPROVED) &&
            <MenuItem
              key={"Disapprove"}
              onClick={(e) => {
                handleSubmit(e, sellerID, APPROVAL_STATUS.DENIED);
              }}
            >
              Disapprove
            </MenuItem>
          }
          {(status == APPROVAL_STATUS.PENDING || status == APPROVAL_STATUS.DENIED) &&
            <MenuItem
              key={"Approve"}
              onClick={(e) => {
                handleSubmit(e, sellerID, APPROVAL_STATUS.APPROVED);
              }}
            >
              Approve
            </MenuItem>
          }
        </Menu>
      </div>
    );
  }

  return (
    <>
      {/* {orderView !== -1 ? <OrderReturnPopup row={orderView} isLoading={isLoading} setOrderView={setOrderView} handleRefundByReturnId={handleRefundByReturnId} confirmPopupView={confirmPopupView} setConfirmPopupView={setConfirmPopupView}/> : <></>}
      {confirmPopupView ? <OrderReturnConfirmPopup isLoading={isLoading} row={orderView} confirmPopupView={confirmPopupView} setConfirmPopupView={setConfirmPopupView} handleRefundByReturnId={handleRefundByReturnId}/> : <></>} */}
      <FormControl
        variant="outlined"
        size="small"
        sx={{
          minWidth: 120,
          float: "right",
          marginBottom: "10px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "7px",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "var(--mintGreen)",
              color: "var(--mintGreen)",
            },
          },
        }}
      >
        <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
        <Select
          onChange={(e) => {
            setFilter(e.target.value)
          }}
          label="Filter"
          inputProps={{
            name: "filter",
            id: "outlined-age-native-simple",
          }}
          value={filter}
        >
          <MenuItem value="Newest First">Newest First</MenuItem>
          <MenuItem value="Oldest First">Oldest First</MenuItem>
          <MenuItem value="AZ">A to Z</MenuItem>
          <MenuItem value="ZA">Z to A</MenuItem>
        </Select>
      </FormControl>
      <Typography variant="h5" style={{ color: "var(--coral)" }}>
        <b>{title}</b>
      </Typography>
      <TableContainer component={Paper}>
        <Table className={"string"} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography>
                  <b>Seller ID</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>
                  <b>Store Name</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>
                  <b>Submitted Date</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>
                  <b>Status</b>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {storeData && storeData.length > 0 ? (
              (rowsPerPage > 0
                ? storeData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : storeData
              ).map((row, index) => (
                <TableRow key={row.sellerID + 'rowrv' + index} className={classes.tableRows}>
                  <TableCell component="th" scope="row" align="center">
                    {/* <Button
                      onClick={() => setOrderView(storeData.find(({orderID}) => orderID === row.orderID))}
                    > */}
                    <Link href={'/shop/seller/' + row.sellerID}>
                      <Typography>
                        <b>{row.sellerID}</b>
                      </Typography>
                    </Link>
                    {/* </Button> */}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {/* <Button
                      onClick={() => setOrderView(storeData.find(({orderID}) => orderID === row.orderID))}
                    > */}
                    <Typography>
                      <b>{row.sellerName}</b>
                    </Typography>
                    {/* </Button> */}
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      <b>{formatDate(row.approvalDate)}</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", columnGap: 4 }}>
                        {row.approvalStatus == APPROVAL_STATUS.PENDING &&
                          <Button
                            onClick={(e) => {
                              handleSubmit(e, row.sellerID, APPROVAL_STATUS.APPROVED);
                            }}
                          >
                            Mark as Approved
                          </Button>
                        }
                        {row.approvalStatus == APPROVAL_STATUS.APPROVED &&
                          <Button disabled>
                            Approved
                          </Button>
                        }
                        {row.approvalStatus == APPROVAL_STATUS.DENIED &&
                          <Button disabled>
                            Disapproved
                          </Button>
                        }
                        <MoreActions status={row.approvalStatus} sellerID={row.sellerID} handleSubmit={handleSubmit} />
                      </div>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow className={classes.tableRows}>
                <TableCell
                  align="center"
                  colSpan={4}
                  className={classes.noOrdersCell}
                >
                  <Typography>
                    {!storeData ? <b>Loading Stores</b> : <b>No Stores Awaiting Approval</b>}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{ height: 53 * emptyRows }}
                className={classes.tableRows}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow className={classes.tableRows}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={4}
                count={storeData ? storeData.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default StoresAwaitingApproval;
