import { ButtonBase, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { outputViewProductVariationSize, round } from "../util/utilities";

const useStyles = makeStyles({
  imageButton: {
    width: 220,
    borderRadius: 20,
    marginRight: 20,
  },
  img: {
    width: 220,
    height: 165,
    objectFit: "cover",
    borderRadius: 20,
  },
  itemContainer: {},
  container: { display: "flex", margin: "20px 30px" },
});

function LandscapeProductView({ item }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.itemContainer}>
        <ButtonBase className={classes.imageButton} href={"/shop/product/" + item.productID + "/" + item.productName?.replace(/\W+/g, "-")}>
          <img
            className={classes.img}
            alt="complex"
            src={item.product.productImageURL ? item.product.productImageURL[0] : ''}
          />
        </ButtonBase>
      </div>
      <div className={classes.itemContainer}>
      <Typography gutterBottom>
          <a href={"/shop/product/" + item.productID + "/" + item.productName?.replace(/\W+/g, "-")}>
            {item.product.customizable
              ? `${item.product.productName} - Customization Available`
              : item.product.productName}
          </a>
        </Typography>
        <Typography gutterBottom>
          {`SIZE/STLYE: ${outputViewProductVariationSize(item.product.option.size)}`}
        </Typography>
        <Typography gutterBottom>
          {`COLOR: ${item.product.option.color}`}
        </Typography>
        <Typography gutterBottom>
          <a href={"/shop/seller/" + item.seller.sellerID + "/" + item?.seller?.sellerName?.replace(/\W+/g, "-")}>{item.seller.sellerName}</a>
        </Typography>
        <Typography>
          <b>${round(item.price, 2)}</b>
        </Typography>
        <Typography>QTY: {item.quantity}</Typography>
        {item.refundedQuantity > 0 && <Typography>QTY Refunded: {item.refundedQuantity}</Typography>}
        {item.refundRequestedQuantity > 0 && <Typography>QTY Requested Refunded: {item.refundRequestedQuantity}</Typography>}
      </div>
    </div>
  );
}

export default LandscapeProductView;
