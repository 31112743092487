import { Container, Typography, Grid, Avatar, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PurposeCategoryMasterLayout } from "../../layouts";
import partnershipJsonData from "../../json/partnership.json";
import { useRef } from "react";
import partnershipLaptop from "../../img/partnership_laptop.png";
import clsx from "clsx";
import useElementWidth from "../../hooks/useElementWidth";
import PartnersAndAmbassadors from "../../components/PartnersAndAmbassadors";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useDocumentDescription from "../../hooks/useDocumentDescription";

const useStyles = makeStyles({
  topGradient: {
    paddingTop: 60,
    backgroundImage: "linear-gradient(var(--pastelGreen), #f6cbc4)",
    paddingBottom: 100,
  },
  bottomGradient: {
    paddingTop: 100,
    backgroundImage: "linear-gradient( #f6cbc4, var(--pastelGreen))",
    paddingBottom: 100,
  },
  header: {
    fontSize: "1.9rem",
    marginBottom: 40,
  },
  bubbleGridItem: {
    maxWidth: "100%",
  },
  topBubbleDiv: {
    width: 160,
    height: 160,
  },
  meetBubbleDiv: {
    width: 124,
    height: 124,
    margin: "auto",
  },
  bubbleAvatar: {
    width: "90%",
    height: "90%",
    maxWidth: "100%",
    backgroundColor: "#FFFFFF",
    borderStyle: "solid",
    borderColor: "var(--coral)",
    borderWidth: 4,
  },
  topBubbleText: {
    fontSize: "inherit",
    color: "#000000",
  },
  meetBubbleText: {
    fontSize: "inherit",
    color: "#000000",
  },
  ambassadorAvatar: {
    width: "100%",
    height: "100%",
    margin: "auto",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  openNowGridItem: {
    display: "flex",
    flexFlow: "column",
  },
  openNowDiv: {
    display: "flex",
    flexFlow: "column",
    flexGrow: 1,
    backgroundColor: "#FFFFFF80",
    paddingTop: 30,
    paddingBottom: 25,
    paddingLeft: 20,
    paddingRight: 20,
  },
  openNowApplyDiv: {
    display: "flex",
    flexGrow: 1,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  laptopImg: {
    width: "100%",
    maxWidth: "800px",
    height: "auto"
  },
  textCenter: {
    textAlign: "center",
  },
  nameText: {
    fontSize: "2.15rem",
  },
  descriptionText: {
    fontSize: "1.3rem",
    width: "70%",
    margin: "auto",
    marginTop: 15,
    marginBottom: 70,
    lineHeight: "1.35",
  },
  topBubbleGrid: {
    marginBottom: 70,
  },
  rounding: {
    borderRadius: 12,
  },
  openNowHeader: {
    fontSize: "1.4rem",
  },
  openNowParagraph: {
    marginTop: 15,
  },
  partnershipSectionGrid: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  applyButton: {
    textTransform: "none",
    borderColor: "var(--coral)",
    borderRadius: 10,
  },
  laptopImgContainer: {
    marginTop: 80,
  },
  ambassadorGrid: {
    marginTop: 20,
    marginBottom: 10,
  },
  applyButtonText: {
    fontSize: "1.15rem",
  },
});

const Bubble = (props) => {
  let {
    bubbleText,
    avatarRef,
    avatarWidth,
    bubbleDivClass,
    bubbleAvatarClass,
  } = props;

  // Used to adjust text size to make sure it doesn't overflow if it is
  // too long by default
  let longestWord = 0;
  bubbleText &&
    bubbleText.split(" ").forEach((word) => {
      if (word.length > longestWord) {
        longestWord = word.length;
      }
    });
  const avatarRatio = longestWord <= 7 ? 6 : 0.5 * (longestWord - 8) + 7;

  return (
    <div className={bubbleDivClass}>
      <Avatar
        className={bubbleAvatarClass}
        ref={avatarRef}
        style={{ fontSize: avatarWidth / avatarRatio }}
      >
        {props.children}
      </Avatar>
    </div>
  );
};

const PartnershipSection = (props) => {
  
  const { sectionData, headerColor, avatarRef, avatarWidth, classes } = props;
  return (
    <>
      <Typography 
        align="center" 
        style={{ color: headerColor }}
      >
        <i>{sectionData.subtitle}</i>
      </Typography>
      <Typography
        align="center"
        className={classes.nameText}
        style={{ color: headerColor }}
        variant="h2"
      >
        <b>{sectionData.name}</b>
      </Typography>
      <Typography 
      align="center" 
      className={classes.descriptionText}
      variant="h3"
      >
        {sectionData.description}
      </Typography>
      <Grid
        container
        direction="row"
        spacing={8}
        className={classes.partnershipSectionGrid}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.openNowGridItem}>
          <div className={clsx(classes.openNowDiv, classes.rounding)}>
            <Typography className={classes.openNowHeader}>
              {sectionData.openNowHeader}
            </Typography>
            {sectionData.openNowText.map((text, index) => {
              return (
                <Typography key={index} className={classes.openNowParagraph}>
                  {text}
                </Typography>
              );
            })}
            <div className={classes.openNowApplyDiv}>
              <Button variant="outlined" className={classes.applyButton}>
                <Typography className={classes.applyButtonText}>
                  Apply Now
                </Typography>
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div style={{width: "100%", height: "100%", borderRadius: "12px"}}>
            <img
              src={sectionData.img}
              alt=""
              style={{objectFit: "cover", width: "100%", height: "100%", borderRadius: "12px"}}
            />
          </div>
        </Grid>
      </Grid>
      <PartnersAndAmbassadors meetBubbleText={sectionData.meetBubbleText} ambassadorImgs={sectionData.ambassadorImgs}/>

    </>
  );
};

function PartnershipLayout() {
  const classes = useStyles();
  const partnershipData = partnershipJsonData;
  const topBubbleAvatarRef = useRef(null);
  const meetBubbleAvatarRef = useRef(null);
  const topBubbleAvatarWidth = useElementWidth(topBubbleAvatarRef);
  const meetBubbleAvatarWidth = useElementWidth(meetBubbleAvatarRef);

  useDocumentTitle("Join the Purposer Team! We are always looking to work with people who put purpose over profit!");
  useDocumentDescription("Join the Purposer Team as a Brand Ambassador or Affiliate Marketer. Our team is dedicated to making the world a better place, one purchase at a time");
  
  return (
    <PurposeCategoryMasterLayout>
      <div className={classes.topGradient}>
        <Container maxWidth="xl">
          <Typography align="center" variant="h1" className={classes.header}>
            <b>Join the Purposer Mission.</b>
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            className={classes.topBubbleGrid}
          >
            {partnershipData.sections.map((section, index) => {
              return (
                <Grid
                  item
                  key={index}
                  className={classes.bubbleGridItem}
                  xs={2}
                  align="center"
                >
                  <Bubble
                    bubbleText={section.bubbleName}
                    avatarRef={topBubbleAvatarRef}
                    avatarWidth={topBubbleAvatarWidth}
                    bubbleDivClass={classes.topBubbleDiv}
                    bubbleAvatarClass={classes.bubbleAvatar}
                  >
                    <Typography
                      className={classes.topBubbleText}
                      align="center"
                    >
                      <b>{section.bubbleName}</b>
                    </Typography>
                  </Bubble>
                </Grid>
              );
            })}
          </Grid>
          <PartnershipSection
            sectionData={partnershipData.sections[0]}
            headerColor={"var(--mintGreenSolid)"}
            avatarRef={meetBubbleAvatarRef}
            avatarWidth={meetBubbleAvatarWidth}
            classes={classes}
          />
        </Container>
      </div>
      <div className={classes.bottomGradient}>
        <Container maxWidth="xl">
          <PartnershipSection
            sectionData={partnershipData.sections[1]}
            headerColor={"var(--coral)"}
            avatarRef={meetBubbleAvatarRef}
            avatarWidth={meetBubbleAvatarWidth}
            classes={classes}
          />
          <div className={clsx(classes.textCenter, classes.laptopImgContainer)}>
            <Typography align="center" className={classes.header}>
              <b>Questions? Reach out at <a href="mailto:hello@purposer.com" style={{color: "var(--coral)"}}>hello@purposer.com</a></b>
            </Typography>
          </div>
        </Container>
      </div>
    </PurposeCategoryMasterLayout>
  );
}

export default PartnershipLayout;
