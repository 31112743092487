import { Edit } from "@mui/icons-material";
import {
  Typography,
  Container,
  IconButton,
  TextField,
  outlinedInputClasses,
  Dialog,
  dialogContentClasses,
  dialogActionsClasses,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Modal,
  Box,
  CircularProgress,
  Autocomplete,
  ButtonBase,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import ManagementMasterLayout from "../MasterLayouts/ManagementMasterLayout";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import React, { useRef, useState, useEffect } from "react";
import { triggerUpload } from "../../components/FileUpload";
import ImageUploader from "../../components/ImageUploader";

import ImageUploaderCoverPhoto from "../../components/ImageUploaderCoverPhoto";
import PurposeCarousel from "../../components/PurposeCarousel";
import FeaturedProductsAndSpecials from "../../components/FeaturedProductsAndSpecials";
import ExpandableQuetionsView from "../../components/ExpandableQuestionsView";
import { MapContainer, TileLayer } from "react-leaflet";
import makeStyles from "@mui/styles/makeStyles";
import goodVibesJsonData from "../../json/good_vibes_store.json";
import countriesList from "../../json/countries_list.json";
import countriesCoords from "../../json/countries_coordinates.json";
import { Markers, AchievementCarousel } from "../../components/InAction";
import { getSellerById, getSellerByIdExtraInfo, updateSeller } from "../../util/sellers";
import { getAllPurposes, getPurposesBySellerId } from "../../util/purposeCategories";
import { useHistory, useParams } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { RETURN_POLICY_LIST } from "../../util/constants";
import AddressInput from "../../components/AddressInput";
import { getUserIdFromAuth, isPurposerUserAdmin } from "../../util/utilities";
import MuiPhoneNumber from "material-ui-phone-number";
import FileUploadV2 from "../../components/FileUploadV2";
import { addImage, uploadImageArray } from "../../util/images";


const useStyles = makeStyles({
  map: {
    maxWidth: 1024,
    height: 400,
    margin: "auto",
  },
  descriptionText: {
    fontSize: "0.9rem",
  },
  //Need help with this
  passwordInputOutline: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 5,
      "&:hover fieldset": {
        borderColor: "var(--mintGreen)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--mintGreen)",
      },
    },
  },
  //------
  inputOutline: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 5,
      "&:hover fieldset": {
        borderColor: "var(--mintGreen)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--mintGreen)",
      },
    },
  },
  sectionHeader: {
    color: "var(--mintGreen)",
    fontSize: "1.4rem",
    marginBottom: 25,
  },
  buttons: {
    border: "1px solid lightGray",
    borderRadius: 5,
    padding: "2px 10px",
  },
  buttonsLarge: {
    border: "1px solid lightGray",
    borderRadius: 10,
    padding: "5px 10px",
    marginTop: 15,
  },
  removeButtons: {
    border: "1px solid red",
    borderRadius: 5,
    padding: "11px 10px",
    color: "red",
  },
  removeButtonsSmall: {
    border: "1px solid red",
    borderRadius: 5,
    padding: "2px 10px",
    color: "red",
  },
  factsInput: { width: "75%", marginBottom: 10 },
});

const EditSection = styled("section")({
  marginTop: 30,
});

const SectionHeader = styled("header")({
  display: "flex",
  alignItems: "center",
  marginBottom: 10,
  color: "var(--coral)"
});

const EditIcon = styled(Edit)({
  color: "#000000",
});

const BorderContainer = styled("div")({
  borderStyle: "solid",
  borderWidth: 1,
  borderRadius: 7,
});

const ContentContainer = styled(BorderContainer)({
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 10,
  paddingBottom: 10,
});

const StyledTextField = styled(TextField)({
  [`& .${outlinedInputClasses.root}`]: {
    padding: 0,
  },
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderStyle: "none",
  },
});

const EditDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogContentClasses.root}`]: {
    padding: theme.spacing(2),
  },
  [`& .${dialogActionsClasses.root}`]: {
    padding: theme.spacing(1),
  },
}));

const EditTextField = styled(TextField)({
  [`& .${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
  {
    borderColor: "var(--mintGreen)",
  },
});

const EditButton = styled(Button)({
  textTransform: "none",
  color: "#000000",
});

const ButtonGridContainer = styled(Grid)({
  width: "100%",
  marginTop: 50,
});

const ButtonGridItem = styled(Grid)({
  display: "flex",
  justifyContent: "center",
});

const StyledButton = styled(Button)({
  textTransform: "none",
  width: "90%",
  margin: "auto",
});

const OutlinedButton = styled(StyledButton)({
  color: "#000000",
  borderColor: "#000000",
  borderRadius: 10,
  "&:hover": {
    backgroundColor: "inherit",
    borderColor: "#000000",
  },
});

const PublishButton = styled(StyledButton)({
  backgroundColor: "var(--coral)",
  color: "#FFFFFF",
  borderRadius: 10,
  "&:hover": {
    backgroundColor: "var(--coral)",
  },
});

const style = {
  removeButton: {
    py: 1,
    px: 2,
    color: "red",
    border: "solid 1px red",
    borderRadius: 1,
  },
};

const styles = {
  coverPhotoUploadContainer: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "var(--lightGray)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 350,
    borderRadius: 15,
    backgroundColor: "#f4f2ed",
    cursor: "pointer",
  },
  storeLogoUploadContainer: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "var(--lightGray)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 300,
    height: 300,
    borderRadius: "100%",
    backgroundColor: "#f4f2ed",
    cursor: "pointer",
  },
  storeLogoImg: {
    width: "100%",
    height: "inherit",
    objectFit: "cover",
    margin: "auto",
    borderRadius: "100%",
  },
};

const shippingList = [
  { shipping: "Standard: 3-5 business days" },
  { shipping: "Express: 1-3 business days" },
  { shipping: "Overnight: 1-2 business days" },
  { shipping: "International: 1-3 business weeks" },
];

const FormEditDialog = (props) => {
  const {
    formData,
    updateFormData,
    propertyName,
    dialogOpen,
    setDialogOpen,
    title,
  } = props;
  const [dialogData, setDialogData] = useState(formData);

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { dialogData, setDialogData });
    }
    return child;
  });

  return (
    <EditDialog
      onClose={() => setDialogOpen(false)}
      open={dialogOpen}
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle onClose={() => setDialogOpen(false)}>{title}</DialogTitle>
      <DialogContent>{childrenWithProps}</DialogContent>
      <DialogActions>
        <EditButton autoFocus onClick={() => setDialogOpen(false)}>
          <Typography className={"roboto-medium"}>Close</Typography>
        </EditButton>
        <EditButton
          autoFocus
          onClick={() => {
            updateFormData(propertyName, dialogData);
            setDialogOpen(false);
          }}
        >
          <Typography className={"roboto-medium"}>Save</Typography>
        </EditButton>
      </DialogActions>
    </EditDialog>
  );
};

const StoreFactsEditDialog = (props) => {
  const { dialogData, setDialogData } = props;

  return (
    <>
      {dialogData.map((fact, index) => {
        return (
          <div key={index} style={{ marginBottom: 15 }}>
            <Typography className={"roboto-regular"}>
              Fact {index + 1}:
            </Typography>
            <EditTextField
              fullWidth
              multiline
              defaultValue={fact}
              onChange={(e) => {
                let newValues = dialogData;
                newValues[index] = e.target.value;
                setDialogData(newValues);
              }}
            />
          </div>
        );
      })}
    </>
  );
};

const FAQEditDialog = (props) => {
  const { dialogData, setDialogData, classes } = props;

  return (
    <>
      {dialogData.map((faq, index) => {
        return (
          <div key={'div' + index} style={{ marginBottom: 30 }}>
            <Typography className={"roboto-regular"}>Question:</Typography>
            <EditTextField
              key={'question' + index}
              fullWidth
              multiline
              value={faq.question}
              onChange={(e) => {
                let newValues = [...dialogData];
                newValues[index].question = e.target.value;
                faq.question = e.target.value;
                setDialogData(newValues);
              }}
              style={{ marginBottom: 10 }}
            />
            <Typography className={"roboto-regular"}>Answer:</Typography>
            <EditTextField
              key={'answer' + index}
              fullWidth
              multiline
              value={faq.answer}
              onChange={(e) => {
                let newValues = [...dialogData];
                newValues[index].answer = e.target.value;
                faq.answer = e.target.value;
                setDialogData(newValues);
              }}
            />
            <div>
              {dialogData?.length > 0 && (
                <ButtonBase
                  key={'remove' + index}
                  className={classes.removeButtonsSmall}
                  onClick={() => {
                    let newValues = [...dialogData];
                    newValues.splice(index, 1);
                    setDialogData(newValues);
                  }}
                >
                  Remove
                </ButtonBase>
              )}
            </div>
          </div>
        );
      })}
      <div style={{ float: "left" }}>
        <ButtonBase
          className={classes.buttonsLarge}
          onClick={() => {
            const list = [
              ...dialogData,
              { question: "", answer: "" },
            ];
            setDialogData(list);
          }}
        >
          Add More
        </ButtonBase>
      </div>
    </>
  );
};

const ShippingPoliciesEditDialog = (props) => {
  const { dialogData, setDialogData } = props;
  return (
    <>
      {dialogData.map((policy, i) => {
        return (
          <>
            <div style={{ display: "flex" }}>
              <Autocomplete
                options={shippingList}
                getOptionLabel={(option) => option.shipping || ""}
                value={policy ? policy : ""}
                onChange={(e, newValue) => {
                  let newValues = dialogData;
                  newValues[i].shipping = newValue.shipping;
                  setDialogData(newValues);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                size="small"
                fullWidth
              />
              <TextField
                name="cost"
                onChange={(e) => {
                  let newValues = dialogData;
                  newValues[i].shippingOverFreeAmt = e.target.value;
                  setDialogData(newValues);
                }}
                defaultValue={policy ? policy.shippingOverFreeAmt : ""}
                variant="outlined"
                size="small"
                placeholder="Free Shipping Over"
                width={12}
              />
            </div>
          </>
        );
      })}
    </>
  );
};

const ReturnPoliciesEditDialog = (props) => {
  const { dialogData, setDialogData } = props;

  const handleReturnMaxDays = (maxDays) => {
    const currReturnPolicy = { ...dialogData };
    currReturnPolicy.maxDays = maxDays;
    setDialogData("returnPolicy", currReturnPolicy);
  };
  const handleEditAddress = (field, value) => {
    const currReturnPolicy = { ...dialogData };
    currReturnPolicy[field] = value;
    setDialogData("returnPolicy", currReturnPolicy);
  };

  return (
    <>
      <Typography variant="h6">Policy</Typography>
      <Typography sx={{ mb: 2 }}>This is the return policy for your entire shop and all your products. It will be visible to shoppers! P.S. We all value your time and talents. It's okay to select a 'no returns allowed' policy.</Typography>
      {dialogData != null &&
        <Autocomplete
          disableClearable
          options={RETURN_POLICY_LIST}
          getOptionLabel={(option) => option.policy || ""}
          defaultValue={dialogData || ""}
          //filterSelectedOptions
          onChange={(e, values) => { setDialogData("returnPolicy", values) }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" />
          )}
          size="small"
          fullWidth
        />
      }
      {dialogData?.policy == RETURN_POLICY_LIST[1].policy &&
        <>
          <Typography variant="h6" sx={{ marginTop: 2 }}>Return Address</Typography>
          <Typography sx={{ mb: 2 }}>This is the address that you would like shoppers to send their returned items to. This option implies that buyers are in charge of boxing the product and paying for shipping labels.</Typography>
          <TextField
            label="Return Name / Business Name"
            fullWidth
            size="small"
            sx={{ mb: 1 }}
            value={dialogData?.addressReturnName || ""}
            onInput={(e) => { handleEditAddress("addressReturnName", e.target.value) }}
          />
          <AddressInput
            formValues={dialogData}
            handleFormValues={handleEditAddress}
            addressLine1={'addressReturnLine1'}
            addressLine2={'addressReturnLine2'}
            addressCity={'addressReturnCity'}
            addressCountry={'addressReturnCountry'}
            addressState={'addressReturnState'}
            addressPostalCode={'addressReturnPostalCode'}
          />
        </>
      }
      {dialogData?.policy != RETURN_POLICY_LIST[0].policy &&
        <>
          <Typography variant="h6" sx={{ marginTop: 2 }}>Returns Accepted Within This Time Frame</Typography>
          <Typography sx={{ mb: 2 }}>This is how long the buyer has to request a return.</Typography>
          <TextField
            name="returnPolicyMaxDays"
            type="number"
            onChange={(e) => { handleReturnMaxDays(e.target.value) }}
            value={dialogData?.maxDays || ""}
            variant="outlined"
            size="small"
            width={12}
            InputProps={{
              //startAdornment: '$',
              inputProps: {
                pattern: '[0-9]*',
              },
            }}
          />
        </>
      }
    </>
  );
};

const AchievementsEditDialog = (props) => {
  const { dialogData, setDialogData, classes } = props;
  return (
    <>
      {dialogData.achievements.map((achievement, i) => {
        return (
          <div key={i + "achiev"}>
            <div style={{ display: "flex" }}>
              <TextField
                name="achievement"
                onChange={(e) => {
                  let newValues = { ...dialogData };
                  newValues.achievements[i] = e.target.value;
                  setDialogData(newValues);
                }}
                value={achievement ? achievement : ""}
                variant="outlined"
                size="medium"
                placeholder="The fact cannot exceed 70 characters."
                fullWidth
                inputProps={{ maxLength: 70 }}
              />
            </div>
            <div>
              {dialogData?.achievements?.length > 0 && (
                <ButtonBase
                  key={'removeAch' + i}
                  className={classes.removeButtonsSmall}
                  onClick={() => {
                    let newValues = { ...dialogData };
                    newValues.achievements.splice(i, 1);
                    setDialogData(newValues);
                  }}
                >
                  Remove
                </ButtonBase>
              )}
            </div>
          </div>
        );
      })}
      <div style={{ float: "left" }}>
        {dialogData?.achievements?.length < 3 && (
          <ButtonBase
            key={'addAch'}
            className={classes.buttonsLarge}
            onClick={() => {
              let newValues = { ...dialogData };
              newValues.achievements = newValues.achievements.concat("");
              setDialogData(newValues);
            }}
          >
            Add More
          </ButtonBase>
        )}
      </div>
    </>
  );
};

const CountriesImpactedEditDialog = (props) => {
  const { dialogData, setDialogData } = props;
  const countriesFormatted = countriesList.map((country) => ({
    code: country.code,
    country: country.label,
    ...countriesCoords.find(c => c.code == country.code)
  }));
  return (
    <>
      <div style={{ display: "flex" }}>
        <Autocomplete
          multiple
          options={countriesFormatted}
          getOptionLabel={(option) => option.country}
          defaultValue={dialogData.mapData ? dialogData.mapData : []}
          filterSelectedOptions
          onChange={(e, values) => {
            let newValues = dialogData;
            newValues.mapData = values;
            setDialogData("inAction", newValues);
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" />
          )}
          size="small"
          fullWidth
        />
      </div>
    </>
  );
};

const AreasSupportedEditDialog = (props) => {
  const { dialogData, setDialogData } = props;
  const countriesFormatted = countriesList.map((country) => ({
    code: country.code,
    country: country.label,
    ...countriesCoords.find(c => c.code == country.code)
  }));
  return (
    <>
      <div style={{ display: "flex" }}>
        <Autocomplete
          multiple
          options={countriesFormatted}
          getOptionLabel={(option) => option.country}
          value={dialogData ? dialogData : []}
          filterSelectedOptions
          onChange={(e, values) => {
            setDialogData(values);
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" />
          )}
          size="small"
          fullWidth
        />
      </div>
    </>
  );
};

const PurposesEditDialog = (props) => {
  const { dialogData, setDialogData, productPurposes, productSubPurposes } = props;

  const handleAutoChanges = (value, index, section) => {
    let newDialogData = { ...dialogData }
    newDialogData[section][index] = value;
    setDialogData(newDialogData);
  };

  const handleRemoveClick = (index, section) => {
    let newDialogData = { ...dialogData }
    newDialogData[section].splice(index, 1);

    setDialogData(newDialogData);
  };

  const handleAddClickBlank = (section, object = {}) => {
    let newDialogData = { ...dialogData }
    newDialogData[section].push(object);
    setDialogData(newDialogData);
  };

  return (

    <div>
      {dialogData.mainPurposes.map((value, i) => {
        return (
          <ContentRow key={"ContentRow" + i} title={`Item Purpose #${i + 1}`}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Autocomplete
                key={"purpose" + i}
                size="small"
                //filter options to not include purposes already in a users mainPurposes
                options={productPurposes.filter((option) => {
                  return !dialogData.mainPurposes.map((purpose) => purpose.productPurposeID).includes(option.productPurposeID)
                })}
                onChange={(e, value) => {
                  handleAutoChanges(value, i, "mainPurposes");
                  handleRemoveClick(i, "subPurposes")
                }}
                defaultValue={dialogData.mainPurposes && dialogData.mainPurposes[i] ? dialogData.mainPurposes[i] : {}}
                getOptionLabel={(option) => option.purposeName}
                disableClearable
                sx={{ width: i === 0 ? "100%" : "50%" }}
                renderInput={(params) => <TextField {...params} />}
              />
              <div>
                {i >= 1 && (
                  <ButtonBase
                    key={"remove" + i}
                    sx={style.removeButton}
                    onClick={() => {
                      handleRemoveClick(i, "mainPurposes")
                      handleRemoveClick(i, "subPurposes")
                    }}
                  >
                    Remove
                  </ButtonBase>
                )}
              </div>
            </Box>
            <Typography sx={{ mt: 1, ml: 5 }}>
              {`Subpurposes #${i + 1}`}
              <Autocomplete
                key={"subpurpose" + i}
                multiple
                value={dialogData.subPurposes && dialogData.subPurposes[i] ? dialogData.subPurposes[i] : []}
                options={productSubPurposes.filter(c => c.higherPurposeID == dialogData.mainPurposes[i].productPurposeID)}
                getOptionLabel={(option) => option.purposeName}
                defaultValue={dialogData.subPurposes && dialogData.subPurposes[i] ? dialogData.subPurposes[i] : []}
                filterSelectedOptions
                onChange={(e, values) => {
                  handleAutoChanges(values, i, "subPurposes");
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                size="small"
                sx={{ width: "80%" }}
              />
            </Typography>
          </ContentRow>
        );
      })}
      <Button variant="contained" sx={{ backgroundColor: "var(--mintGreenSolid)", marginTop: "16px" }} key={"addPurposeBtn"} onClick={() => handleAddClickBlank("mainPurposes", { purposeName: "" })}>
        Add Another Purpose
      </Button>
    </div>
  );
};

const StoreBioEditDialog = (props) => {
  const { dialogData, setDialogData } = props;
  return (
    <>
      <div style={{ display: "flex" }}>
        <ContentContainer>
          <IconButton
            onClick={() => {
              setDialogData(dialogData.concat('\n•'))
            }}
          >
            {`•`}
          </IconButton>
        </ContentContainer>
      </div>
      <ContentContainer>
        <StyledTextField
          id={"storeBioTextField"}
          fullWidth
          multiline
          value={dialogData}
          onChange={(e) => setDialogData(e.target.value)}
        />
      </ContentContainer>
    </>
  );
};

const ContentRow = ({ title, children }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography sx={{ mb: 1, mt: 2 }}>
          <b>{title}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

function SellerShopInformationLayout() {
  const classes = useStyles();
  const { id: sellerIDparam } = useParams();
  const [formData, setFormData] = useState({});
  const [sellerTxnFees, setSellerTxnFees] = useState({});
  const [sellerPurposes, setSellerPurposes] = useState([]);
  const [productPurposes, setProductPurposes] = useState([]);
  const [productSubPurposes, setProductSubPurposes] = useState([]);
  const [storeFactsDialogOpen, setStoreFactsDialogOpen] = useState(false);
  const [faqDialogOpen, setFaqDialogOpen] = useState(false);
  const [shippingPolicyDialogOpen, setShippingPolicyDialogOpen] = useState(false);
  const [returnPolicyDialogOpen, setReturnPolicyDialogOpen] = useState(false);
  const [achievementsDialogOpen, setAchievementsDialogOpen] = useState(false);
  const [countriesImpactedDialogOpen, setCountriesImpactedDialogOpen] = useState(false);
  const [areasSupportedDialogOpen, setAreasSupportedDialogOpen] = useState(false);
  const [purposesDialogOpen, setPurposesDialogOpen] = useState(false);
  const [storeBioDialogOpen, setStoreBioDialogOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [isRequesting, setIsRequesting] = useState(true);
  const [requestFailed, setRequestFailed] = useState(false);
  const [updateFailed, setUpdateFailed] = useState(false);
  const [bannerIndex, setBannerIndex] = useState(0);
  const [footerIndex, setFooterIndex] = useState(0);
  const [index, setIndex] = useState(0);
  const [fIndex, setFIndex] = useState(0);
  const [add, setAdd] = useState(false);
  const history = useHistory();


  // const DEFAULT_ID = "22963625-1ae2-41d1-9041-97a8111c74a0";

  let coverPhotoUploadRef = useRef(null);
  let storeLogoUploadRef = useRef(null);
  let footerPhotoUploadRef = useRef(null);

  const updateFormData = (name, value) => {
    setFormData((preValues) => {
      return { ...preValues, [name]: value };
    });
  };

  const updateInActionArrayFormData = (name, value) => {
    setFormData((preValues) => {
      let preValuesObject = { ...preValues };
      preValuesObject.inAction[name] = [value];
      return preValuesObject;
    });
  };

  const focusTextField = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.focus();
      const length = element.innerHTML.length;
      element.setSelectionRange(length, length);
    }
  };

  const updateShopInfo = async () => {
    setUpdating(true);
    //start of new code

    //Website info
    const sellerName = formData.sellerName;
    const location = formData.location;
    const bio = formData.bio;
    const purposes = [
      ...formData.selectedPurposes.mainPurposes.filter(p => p.purposeName),
      ...formData.selectedPurposes.subPurposes.flat().filter(p => p.purposeName)
    ];
    const banner_images = formData.banner_images;
    const profile_pic = formData.profile_pic;
    const inActionAchievements = formData.inAction.achievements ?? [];
    const inActionMapData = formData.inAction.mapData;
    const inActionText = formData.inAction.text;

    //Shipping Info
    const shippingPolicies = formData.shippingPolicy;
    const shippingToCountries = formData.areaSupport;
    const returnPolicy = formData.returnPolicy;

    const FAQ = formData.FAQ;

    let errorsInSubmission = []

    //check to make sure each main purpose has a subpurpose
    const mainPurposes = formData.selectedPurposes.mainPurposes.filter(p => p.productPurposeID);
    const subPurposes = formData.selectedPurposes.subPurposes.flat().filter(p => p.productPurposeID);
    const mainPurposesWithSubPurposes = mainPurposes.filter(p => subPurposes.find(s => s.higherPurposeID == p.productPurposeID));
    if (mainPurposes.length > 0 && mainPurposes.length != mainPurposesWithSubPurposes.length) errorsInSubmission.push("Must select a subpurpose for each main purpose!");
    
    //faq check
    if (!FAQ || FAQ?.filter(f => !f.question || !f.answer)?.length > 0) errorsInSubmission.push("All FAQ must have a question and answer!");
    if (!sellerName) errorsInSubmission.push("Store Name can't be empty!");
    if (!inActionText) errorsInSubmission.push("Bio can't be empty!");
    if (inActionText.length > 0 && inActionText[0].length < 350) errorsInSubmission.push("Bio must be longer than 350 characters!");
    if (!banner_images || banner_images.length < 1) errorsInSubmission.push("Must have banner images!");
    if (!profile_pic || profile_pic.length < 1) errorsInSubmission.push("Must have a store logo!");
    if (mainPurposes?.length < 1) errorsInSubmission.push("Must select at least one purpose!");
    if (!inActionMapData) errorsInSubmission.push("Must list which countries are impacted!");
    if (!shippingPolicies) errorsInSubmission.push("Must select a shipping policy!");
    if (!shippingToCountries) errorsInSubmission.push("Must select which countries you ship to!");
    if (!returnPolicy) errorsInSubmission.push("Must select your return policy!");
    if (formData?.returnPolicy?.policy == RETURN_POLICY_LIST[1].policy) {
      formData.returnPolicy.addressReturnCountry = 'US';
      if (!formData.returnPolicy.addressReturnName) errorsInSubmission.push("Must have a return address name");
      if (!formData.returnPolicy.addressReturnLine1) errorsInSubmission.push("Must have a return address line");
      if (!formData.returnPolicy.addressReturnCity) errorsInSubmission.push("Must have a return address city");
      if (!formData.returnPolicy.addressReturnCountry || formData.returnPolicy.addressReturnCountry?.length != 2) errorsInSubmission.push("Must have a return address country code that is 2 letters!");
      if (!formData.returnPolicy.addressReturnState || formData.returnPolicy.addressReturnState?.length != 2) errorsInSubmission.push("Must have a return address state that is 2 letters!");
      if (!formData.returnPolicy.addressReturnPostalCode) errorsInSubmission.push("Must have a return address postal code");
    }
    if (errorsInSubmission.length > 0) {
      const allErrorsText = errorsInSubmission.join('\n');
      toast((t) => (
        <span>
          {allErrorsText}
          {'\n'}
          <Grid container justifyContent="center">
            <Button onClick={() => toast.dismiss(t.id)}>
              Dismiss
            </Button>
          </Grid>
        </span>
      ));
      setUpdating(false);
    } else {

      const processed_banner_images = await uploadImagesAndUpdateFormData(formData.banner_images, "banner_images", updateFormData);
      const processed_profile_pic = await uploadImagesAndUpdateFormData(formData.profile_pic, "profile_pic", updateFormData);
      const processed_footerImages = await uploadImagesAndUpdateFormData(formData.footerImages, "footerImages", updateFormData);


      const apiReqBody = {
        ...formData,
        sellerID: sellerIDparam,
        sellerName: sellerName,
        location: location,
        bio: bio,
        purposes: purposes,
        banner_images: processed_banner_images,
        profile_pic: processed_profile_pic,
        footerImages: processed_footerImages,
        shippingPolicy: shippingPolicies,
        areaSupport: shippingToCountries,
        returnPolicy: returnPolicy,
        impact: "Impact Test",
        inAction: {
          mapData: inActionMapData,
          achievements: inActionAchievements,
          text: inActionText,
          imgs: [],
          shopPolicies: []
        },
        FAQ: FAQ,
        blog: formData.blog
      }

      updateSeller(apiReqBody, (data) => {
        if (!(data === "Failed")) {
          // history.go(0);
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
          setUpdating(false);
        } else {
          setUpdating(false);
          setUpdateFailed(true);
        }
      });
    }
  };

  useEffect(() => {
    getSellerById(sellerIDparam, (data) => {
      if (data === "failed") {
        setRequestFailed(true);
        return;
      } else if (data && !data.hasOwnProperty("error")) {
        getUserIdFromAuth((userId) => {
          if (!data?.adminUserID?.toLowerCase() || !userId?.toLowerCase()) return;
          if (data.adminUserID.toLowerCase() == userId?.toLowerCase() || isPurposerUserAdmin(userId)) {
            //Valid user on page
          } else {
            history.push(`/`);
            return;
          }
        })

        data.selectedPurposes = {};
        data.selectedPurposes.mainPurposes = data.purposes.filter(p => p.higherPurposeID == -1);
        data.selectedPurposes.subPurposes = [];// = dialogData.filter(p => p.higherPurposeID != -1);
        for (let i = 0; i < data.selectedPurposes.mainPurposes.length; i++) {
          data.selectedPurposes.subPurposes.push(data.purposes.filter(p => p.higherPurposeID == data.selectedPurposes.mainPurposes[i].productPurposeID));
        }
        if (data?.returnPolicy?.policy == RETURN_POLICY_LIST[1].policy) {
          data.returnPolicy.addressReturnCountry = 'US';
        }
        if (data?.profile_pic) {
          data.profile_pic = [data.profile_pic];
        }
        setFormData(data);
        setIsRequesting(false);
      } else {
        console.log("Error getting seller by id");
      }
    });

    getSellerByIdExtraInfo(sellerIDparam, (data) => {
      if (data === "failed" || !data) {
        return;
      } else if (data && !data.hasOwnProperty("error")) {
        setSellerTxnFees(data);
      } else {
        console.log("Error getting seller extra info by id");
      }
    });
  }, [sellerIDparam]);

  useEffect(() => {
    getAllPurposes((data) => {
      if (data === "failed") {
        setRequestFailed(true);
        return;
      } else if (data && !data.hasOwnProperty("error")) {
        const mainPurposes = data.filter(p => p.higherPurposeID == -1);
        const subPurposes = data.filter(p => p.higherPurposeID != -1);
        setProductPurposes(mainPurposes);
        setProductSubPurposes(subPurposes);
      } else {
        console.log("Failed getting purposes");
      }
    });
  }, []);

  useEffect(() => {
    if (formData.tempBanner && formData.tempBanner.length > 0 && !add) {
      formData.banner_images.splice(bannerIndex, 1, formData.tempBanner[0]);
      setIndex(index + 1);
    } else if (add) {
      formData.tempBanner.map((img, index) => {
        return formData.banner_images.push(img);
      });
      setAdd(false);
    }
  }, [formData.tempBanner]);

  useEffect(() => {
    if (formData.tempFooter && formData.tempFooter.length > 0 && !add) {
      formData.footerImages.splice(footerIndex, 1, formData.tempFooter[0]);
      setFIndex(fIndex + 1);
    } else if (add) {
      formData.tempFooter.map((img, index) => {
        return formData.footerImages.push(img);
      });
      setAdd(false);
    }
  }, [formData.tempFooter]);

  const handleAutoChanges = (value, index, section) => {
    const list = [...formData[section]];
    if (!value) value = { id: 0, value: 0, name: "" };
    list[index]["value"] = value.id;
    list[index]["name"] = value.name;
    updateFormData(section, list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index, section) => {
    const list = [...formData[section]];
    list.splice(index, 1);
    updateFormData(section, list);
  };

  // handle click event of the Add button
  const handleAddClick = (i, section) => {
    updateFormData(section, [
      ...formData[section].map((item, i) => {
        return { id: i, value: item.value, name: item.name };
      }),
      { id: i + 1, value: 0, name: "" },
    ]);
  };

  const handleChangeStoreBio = (e) => {
    updateInActionArrayFormData("text", e.target.value)
  }

  const uploadImagesAndUpdateFormData = async (imageArray, formDataValueName, handleFormData) => {
    const imgUrlArray = await uploadImageArray(imageArray);
    handleFormData(formDataValueName, imgUrlArray);
    return imgUrlArray;
  }

  return (
    <ManagementMasterLayout>
      <Toaster
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      {isRequesting ? (
        <Box sx={{ height: 100 }}></Box>
      ) : (
        <Container sx={{ paddingBottom: 20 }}>
          {Number(sellerTxnFees?.txnPercentFee) * 100 == 3 ? (
          <>
          <EditSection>
          <SectionHeader>
            <Typography
              display="inline"
              variant="h5"
              className={"roboto-medium"}
            >
              Commission Fee
            </Typography>
          </SectionHeader>
          <Typography>This is your commission fee per product sold.</Typography>
          <Button variant="outlined" disableRipple sx={{ padding: "12px 64px", color: "black", borderColor: "black", mt: 1, fontSize: "16px", cursor: "default" }}>
            {`0%`}
          </Button>
        </EditSection>
          <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
                >
                Credit Card Processing Fee
              </Typography>
            </SectionHeader>
            <Typography>This is the fee charged per sale to cover STRIPE credit card and processing charges.</Typography>
            <Button variant="outlined" disableRipple sx={{ padding: "12px 64px", color: "black", borderColor: "black", mt: 1, fontSize: "16px", cursor: "default" }}>
              {sellerTxnFees?.txnPercentFee ? `${Number(sellerTxnFees?.txnPercentFee) * 100}%` : sellerTxnFees?.txnFlatFee ? `$${sellerTxnFees?.txnFlatFee}` : ``}
            </Button>
          </EditSection>
          </>
          ) :
          (
            <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Commission Fee
              </Typography>
            </SectionHeader>
            <Typography>This is your commission fee per product sold. There are no other monthly, transaction or marketing fees.</Typography>
            <Button variant="outlined" disableRipple sx={{ padding: "12px 64px", color: "black", borderColor: "black", mt: 1, fontSize: "16px", cursor: "default" }}>
              {sellerTxnFees?.txnPercentFee ? `${Number(sellerTxnFees?.txnPercentFee) * 100}%` : sellerTxnFees?.txnFlatFee ? `$${sellerTxnFees?.txnFlatFee}` : ``}
            </Button>
          </EditSection>
          )
          }
          <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Banner Photo
              </Typography>
              {/* <IconButton
                onClick={() => {
                  triggerUpload(coverPhotoUploadRef);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  triggerUpload(coverPhotoUploadRef);
                  setAdd(true);
                }}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  formData.banner_images.splice(bannerIndex, 1);
                  setIndex(index + 1);
                }}
              >
                <RemoveIcon />
              </IconButton> */}
            </SectionHeader>
            {/* <div style={{ display: "flex", flexDirection: "row", alignItems: "center", columnGap: "16px", margin: "16px 0px" }}>
              <Button onClick={() => {
                triggerUpload(coverPhotoUploadRef);
                setAdd(true);
              }} variant="outlined" sx={{ color: "black", borderColor: "black" }}>
                Browse your computer
              </Button>
              <Typography>Or, drag and drop files. </Typography>
            </div> */}
            <Typography>
              Pick up to 4 photos. (10 MB Maximum for each photo)
              <br />
              <a href="/support/seller" target="_blank" style={{ textDecoration: "underline" }}>Learn more here!</a></Typography>
            <br />
            {/* <ImageUploaderCoverPhoto
              containerStyle={styles.coverPhotoUploadContainer}
              uploadIcon={<AddIcon fontSize="large" />}
              multiple
              formValues={formData}
              propertyName={"banner_images"}
              setFormValues={setFormData}
              imgIndex={setBannerIndex}
              tempBanner={"tempBanner"}
              setRef={(inputRef) => {
                coverPhotoUploadRef = inputRef;
              }}
            /> */}
            <FileUploadV2
              formValueName={"banner_images"}
              formValueArray={formData.banner_images}
              maxFiles={4}
              handleFormValues={updateFormData}
              buttonText={'Upload: jpg, png'}
              aspectRatio={2000 / 500}
            // requireImageCrop={true}
            />
          </EditSection>
          <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Store Logo
              </Typography>
            </SectionHeader>

            {/* <IconButton
                onClick={() => {
                  triggerUpload(storeLogoUploadRef);
                }}
              >
                <EditIcon />
              </IconButton>
            <ImageUploader
              containerStyle={styles.storeLogoUploadContainer}
              imgStyle={styles.storeLogoImg}
              uploadIcon={<AddIcon fontSize="large" />}
              formValues={formData}
              propertyName={"profile_pic"}
              setFormValues={setFormData}
              setRef={(inputRef) => {
                storeLogoUploadRef = inputRef;
              }}
            /> */}
            <FileUploadV2
              formValueName={"profile_pic"}
              formValueArray={formData.profile_pic}
              maxFiles={1}
              handleFormValues={updateFormData}
              buttonText={'Upload: jpg, png'}
            />
          </EditSection>
          <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Store Name
              </Typography>
              {/* <IconButton onClick={() => focusTextField("sellerName")}>
                <EditIcon />
              </IconButton> */}
            </SectionHeader>
            <Typography sx={{ mb: 2 }}>
              This is the store name that will be visible to shoppers.
            </Typography>
            <ContentContainer>
              <StyledTextField
                id={"sellerName"}
                fullWidth
                multiline
                defaultValue={formData.sellerName}
                onChange={(e) => updateFormData("sellerName", e.target.value)}
              />
            </ContentContainer>
          </EditSection>
          <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                {`Location`}
              </Typography>
              {/* <IconButton onClick={() => focusTextField("storeNameTextField")}>
                <EditIcon />
              </IconButton> */}
            </SectionHeader>
            <Typography sx={{ mb: 2 }}>This will be visible to shoppers. People like to know where you're based out of!</Typography>
            <ContentContainer>
              {/* <StyledTextField
                id={"storeNameTextField"}
                fullWidth
                defaultValue={formData.sellerName}
                InputProps={{
                  inputProps: {
                    style: {
                      color: "var(--mintGreenSolid)",
                      fontSize: 48,
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                    className: "roboto-medium",
                  },
                }}
                onChange={(e) => updateFormData("sellerName", e.target.value)}
              /> */}
              <StyledTextField
                id={"location"}
                fullWidth
                multiline
                defaultValue={formData.location}
                onChange={(e) => updateFormData("location", e.target.value)}
              />
            </ContentContainer>
          </EditSection>
          <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                {`About Your Shop`}
              </Typography>
              {/* <IconButton onClick={() => setStoreBioDialogOpen(true)}>
                <EditIcon />
              </IconButton> */}
            </SectionHeader>
            <Typography sx={{ mb: 2 }}>Our shoppers love hearing about your story of entrepreneurship! Use this space to share details of your business, how you started, your inspiration, your unique production, your mission, vision, and more! This will be visible to buyers on your store page and also under each product listing!</Typography>
            <FormEditDialog
              formData={
                formData?.inAction?.text?.[0] ? formData.inAction.text[0] : ""
              }
              updateFormData={handleChangeStoreBio}
              propertyName={"text"}
              dialogOpen={storeBioDialogOpen}
              setDialogOpen={setStoreBioDialogOpen}
              title={"Edit Store Bio / Impact"}
            >
              <StoreBioEditDialog />
            </FormEditDialog>
            <ContentContainer>
              <StyledTextField
                id={"storeBioTextField"}
                fullWidth
                //disabled={true}
                multiline
                value={formData.inAction.text[0]}
                onChange={handleChangeStoreBio}
              />
            </ContentContainer>
          </EditSection>
          <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Purposes
              </Typography>
              {/* <IconButton onClick={() => setPurposesDialogOpen(true)}>
                <EditIcon />
              </IconButton> */}
            </SectionHeader>
            <Typography>This is one of the most important parts about your business! Tell shoppers about all the PURPOSES that you support and help further! They will appear at the top of your page. </Typography>
            <PurposesEditDialog dialogData={formData.selectedPurposes ? formData.selectedPurposes : {}} setDialogData={updateFormData} productPurposes={productPurposes} productSubPurposes={productSubPurposes} />
          </EditSection>
          {/* Removed because there is only one bio field, the text in inAction */}
          {/* <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Your Impact
              </Typography>
              <IconButton
                onClick={() => focusTextField("storeYourImpactTextField")}
              >
                <EditIcon />
              </IconButton>
            </SectionHeader>
            <ContentContainer>
              <StyledTextField
                id={"storeYourImpactTextField"}
                fullWidth
                multiline
                defaultValue={formData.impact}
                onChange={(e) => updateFormData("impact", e.target.value)}
              />
            </ContentContainer>
          </EditSection> */}
          {/* <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Store Facts
              </Typography>
              <IconButton
              // onClick={() => setStoreFactsDialogOpen(true)}
              >
                <EditIcon />
              </IconButton>
            </SectionHeader>
            <PurposeCarousel purposeCarouselData={formData.storeFacts} />
            <FormEditDialog
              formData={formData.storeFacts}
              updateFormData={updateFormData}
              propertyName={"storeFacts"}
              dialogOpen={storeFactsDialogOpen}
              setDialogOpen={setStoreFactsDialogOpen}
              title={"Edit Store Facts"}
            >
              <StoreFactsEditDialog />
            </FormEditDialog>
          </EditSection> */}
          {/* <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Specials (coming soon)
              </Typography>
              <IconButton>
                <EditIcon />
              </IconButton>
            </SectionHeader>
            <ContentContainer>
              <FeaturedProductsAndSpecials
                featuredProductsAndSpecialsData={formData.specials}
              />
            </ContentContainer>
          </EditSection> */}
          <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Shop Return Policy
              </Typography>
              {/* <IconButton onClick={() => setReturnPolicyDialogOpen(true)}>
                <EditIcon />
              </IconButton> */}
            </SectionHeader>
            {/* <ContentContainer>
              <Typography
                display="inline"
                variant="h4"
                className={"roboto-light"}
                sx={{ textDecoration: "underline" }}
              >
                {formData.storeName}
              </Typography>
              <Typography
                display="inline"
                variant="h4"
                className="roboto-medium"
                sx={{ textDecoration: "underline" }}
              >
                Return Policy
              </Typography>
              {formData?.returnPolicy?.policy &&
                <>
                  <Typography
                    variant="h6"
                    className="roboto-medium"
                  >
                    {formData.returnPolicy.policy}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="roboto-medium"
                  >
                    {formData.returnPolicy.maxDays ? 'Max Days after an order returns can be requested: ' + formData.returnPolicy.maxDays : ''}
                  </Typography>
                </>
              }
            </ContentContainer> */}
            {/* <FormEditDialog
              formData={
                formData.returnPolicy ? formData.returnPolicy : ""
              }
              updateFormData={updateFormData}
              propertyName={"returnPolicy"}
              dialogOpen={returnPolicyDialogOpen}
              setDialogOpen={setReturnPolicyDialogOpen}
              title={"Edit Store Return Policy"}
            > */}
            <ReturnPoliciesEditDialog dialogData={formData.returnPolicy} setDialogData={updateFormData} />
            {/* </FormEditDialog> */}
          </EditSection>
          <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Shop Contact Information
              </Typography>
            </SectionHeader>
            <Typography>Enter your email address and/or phone number so shoppers can contact you directly with questions about your products! This is optional but we highly recommend giving your shoppers at least one way to contact you and ask questions about your products. This will be visible to shoppers on your shop profile and on product listings.</Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>Email Address</Typography>
            <TextField
              id={"storeContactEmailField"}
              fullWidth
              label="Start typing your email address"
              defaultValue={formData.contactEmail}
              onChange={(e) => { updateFormData('contactEmail', e.target.value); }}
            />
            {/* <TextField size="small" fullWidth label="Start typing your email address"></TextField> */}
            <Typography variant="h6" sx={{ mt: 1 }}>Phone Number</Typography>
            <MuiPhoneNumber
              defaultCountry={'us'}
              disableAreaCodes
              fullWidth
              onChange={(value) => { updateFormData('contactPhone', value) }}
              value={formData.contactPhone}
            />
            {/* <StyledTextField
              id={"storeContactPhoneField"}
              fullWidth
              //disabled={true}
              multiline
              label="Start typing your phone"
              value={formData.contactPhone}
              onChange={handleChangeStoreBio}
            /> */}
            {/* <TextField size="small" fullWidth label="Start typing your phone number"></TextField> */}
          </EditSection>
          <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Footer Photo
              </Typography>
              {/* <IconButton
                onClick={() => {
                  triggerUpload(footerPhotoUploadRef);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  triggerUpload(footerPhotoUploadRef);
                  setAdd(true);
                }}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  formData.footerImages.splice(footerIndex, 1);
                  setFIndex(index + 1);
                }}
              >
                <RemoveIcon />
              </IconButton> */}
            </SectionHeader>
            {/* <div style={{ display: "flex", flexDirection: "row", alignItems: "center", columnGap: "16px", margin: "16px 0px" }}>
              <Button onClick={() => {
                triggerUpload(footerPhotoUploadRef);
                setAdd(true);
              }} variant="outlined" sx={{ color: "black", borderColor: "black" }}>
                Browse your computer
              </Button>
            </div> */}
            <Typography>
              {`Pick up to 8 photos. (10 MB Maximum for each photo)`}
              <br />
              <a href="/support/seller" target="_blank" style={{ textDecoration: "underline" }}>Learn more here!</a>
            </Typography>
            <br />
            {/* <ImageUploaderCoverPhoto
              containerStyle={styles.coverPhotoUploadContainer}
              uploadIcon={<AddIcon fontSize="large" />}
              multiple
              formValues={formData}
              propertyName={"footerImages"}
              setFormValues={setFormData}
              imgIndex={setFooterIndex}
              tempBanner={"tempFooter"}
              setRef={(inputRef) => {
                footerPhotoUploadRef = inputRef;
              }}
            /> */}
            <FileUploadV2
              formValueName={"footerImages"}
              formValueArray={formData.footerImages}
              maxFiles={8}
              handleFormValues={updateFormData}
              buttonText={'Upload: jpg, png'}
            />

          </EditSection>
          <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Store Facts
              </Typography>
              <IconButton onClick={() => setAchievementsDialogOpen(true)}>
                <EditIcon />
              </IconButton>
            </SectionHeader>
            <Typography>Choose three purpose-driven facts about your business that you are most proud of. Make each word count, you only have 70 characters per fact.</Typography>
            <Typography><i>i.e. 3000 Blankets Donated to Homeless Shelters</i></Typography>
            <Typography><i>i.e. Over $40k+ given to charities</i></Typography>
            <Typography><i>i.e. Employing 40+ employees with Autism</i></Typography>
            <ContentContainer sx={{ textAlign: "center", mt: 2, cursor: "pointer" }} onClick={() => setAchievementsDialogOpen(true)}>
              <AchievementCarousel
                achievements={formData.inAction.achievements}
              />
            </ContentContainer>
            <FormEditDialog
              formData={
                formData.inAction ? formData.inAction : {}
              }
              updateFormData={updateFormData}
              propertyName={"inAction"}
              dialogOpen={achievementsDialogOpen}
              setDialogOpen={setAchievementsDialogOpen}
              title={"Edit Achievements"}
            >
              <AchievementsEditDialog classes={classes} />
            </FormEditDialog>
          </EditSection>
          {/* <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Shop Shipping Policies
              </Typography>
              <IconButton onClick={() => setShippingPolicyDialogOpen(true)}>
                <EditIcon />
              </IconButton>
            </SectionHeader>
            <ContentContainer>
              <Typography
                display="inline"
                variant="h4"
                className={"roboto-light"}
                sx={{ textDecoration: "underline" }}
              >
                {formData.storeName}
              </Typography>
              <Typography
                display="inline"
                variant="h4"
                className="roboto-medium"
                sx={{ textDecoration: "underline" }}
              >
                Shipping Policy
              </Typography>
              {formData.shippingPolicy.length > 0 && formData.shippingPolicy.map((policy, i) => {
                return (
                  <>
                    <div style={{ display: "flex" }}>
                      <Typography
                        display="inline"
                        variant="h6"
                        className="roboto-medium"
                      >
                        {policy.shipping + " -"}
                      </Typography>
                      <Typography
                        display="inline"
                        variant="h6"
                        className="roboto-medium"
                      >
                        {'- Free if over $' + policy.shippingOverFreeAmt}
                      </Typography>
                    </div>
                  </>
                  );
                })
              }
            </ContentContainer>
            <FormEditDialog
              formData={
                formData.shippingPolicy && formData.shippingPolicy.length > 0 ? formData.shippingPolicy : []
              }
              updateFormData={updateFormData}
              propertyName={"shippingPolicy"}
              dialogOpen={shippingPolicyDialogOpen}
              setDialogOpen={setShippingPolicyDialogOpen}
              title={"Edit Store Shipping Policy"}
            >
              <ShippingPoliciesEditDialog />
            </FormEditDialog>
          </EditSection> */}

          {/* <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Areas Supported to ship to
              </Typography>
              <IconButton onClick={() => setAreasSupportedDialogOpen(true)}>
                <EditIcon />
              </IconButton>
            </SectionHeader>
            <ContentContainer>
              <Typography
                display="inline"
                variant="h4"
                className={"roboto-light"}
                sx={{ textDecoration: "underline" }}
              >
                {formData.storeName}
              </Typography>
              <Typography
                display="inline"
                variant="h4"
                className="roboto-medium"
                sx={{ textDecoration: "underline" }}
              >
                Areas Supported
              </Typography>
              {formData.areaSupport.map((country, i) => {
                return (
                  <>
                    <div style={{ display: "flex" }}>
                      <Typography
                        display="inline"
                        variant="h6"
                        className="roboto-medium"
                      >
                        {country.country}
                      </Typography>
                    </div>
                  </>
                  );
                })
              }
            </ContentContainer>
            <FormEditDialog
              formData={
                formData.areaSupport && formData.areaSupport.length > 0 ? formData.areaSupport : []
              }
              updateFormData={updateFormData}
              propertyName={"areaSupport"}
              dialogOpen={areasSupportedDialogOpen}
              setDialogOpen={setAreasSupportedDialogOpen}
              title={"Edit Areas Supported"}
            >
              <AreasSupportedEditDialog />
            </FormEditDialog>
          </EditSection> */}

          {/* //start
          <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Banner Photo
              </Typography>
              <IconButton
                onClick={() => {
                  triggerUpload(coverPhotoUploadRef);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  triggerUpload(coverPhotoUploadRef);
                  setAdd(true);
                }}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  formData.banner_images.splice(bannerIndex, 1);
                  setIndex(index + 1);
                }}
              >
                <RemoveIcon />
              </IconButton>
            </SectionHeader>
            <ImageUploaderShopInformation
              containerStyle={styles.coverPhotoUploadContainer}
              uploadIcon={<AddIcon fontSize="large" />}
              multiple
              formValues={formData}
              propertyName={"banner_images"}
              setFormValues={setFormData}
              imgIndex={setBannerIndex}
              tempBanner={"tempBanner"}
              setRef={(inputRef) => {
                coverPhotoUploadRef = inputRef;
              }}
            />
            <Typography variant="h6" className={"roboto-light-italic"}>
              add up to 6 photos or videos
            </Typography>          
          end*/}
          <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Countries Impacted
              </Typography>
              {/* <IconButton onClick={() => setCountriesImpactedDialogOpen(true)}>
                <EditIcon />
              </IconButton> */}
            </SectionHeader>
            <Typography sx={{ mb: 2 }}>If your products or services make a difference in communities across the globe, please tell your shoppers! We love knowing about all the great work you're doing worldwide. Shoppers will see a map with pinpoints that show your areas of impact!</Typography>
            <CountriesImpactedEditDialog dialogData={
              formData.inAction ? formData.inAction : {}
            }
              setDialogData={updateFormData}
              propertyName="inAction" />
            <Typography sx={{ mb: 2 }}><i>Pinpoints will appear on the map as you select a country.</i></Typography>
            <BorderContainer>
              <MapContainer
                tap={false}
                center={[30, 0]}
                zoom={2}
                scrollWheelZoom={true}
                maxBounds={[
                  [-90, -180],
                  [90, 180],
                ]}
                className={classes.map}
                minZoom={2}
              >
                <TileLayer
                  attribution='&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.thunderforest.com/mobile-atlas/{z}/{x}/{y}.png?apikey={apikey}"
                  noWrap="true"
                  apikey="b4253345883a4819ae18fbf3ca4daef3"
                />
                {formData.inAction.mapData
                  ? formData.inAction.mapData.map((item, index) => {
                    return <Markers data={item} key={index} />;
                  })
                  : null}
              </MapContainer>
            </BorderContainer>
            {/* <FormEditDialog
              formData={
                formData.inAction ? formData.inAction : {}
              }
              updateFormData={updateFormData}
              propertyName={"inAction"}
              dialogOpen={countriesImpactedDialogOpen}
              setDialogOpen={setCountriesImpactedDialogOpen}
              title={"Edit Countries Impacted"}
            > */}

            {/* </FormEditDialog> */}
          </EditSection>
          <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Store FAQ
              </Typography>
              <IconButton onClick={() => setFaqDialogOpen(true)}>
                <EditIcon />
              </IconButton>
            </SectionHeader>
            <Typography sx={{ mb: 2 }}>This is where you can write our frequently asked questions that buyers may have about your products and write your answers. Think about writing down facts that will help the buyers understand your products better.</Typography>
            <Typography sx={{ mb: 2 }}><i>i.e. Is your product made in the United States? Yes! All of our materials are sourced locally in San Diego, CA and manufactured in Los Angeles, CA.</i></Typography>
            <Typography sx={{ mb: 2 }}><i>i.e. Why do you give back to Cancer Research Groups? Our owners have family members who are cancer survivors and want to honor them!</i></Typography>

            <ContentContainer >
              <Typography
                display="inline"
                variant="h4"
                className={"roboto-light"}
                sx={{ textDecoration: "underline" }}
              >
                {formData.storeName}
              </Typography>
              <Typography
                display="inline"
                variant="h6"
                className="roboto-medium"
                sx={{ cursor: "pointer" }}
                onClick={() => setFaqDialogOpen(true)}
              >
                Click here to add a FAQ. Your current FAQs will show below:
              </Typography>
              <ExpandableQuetionsView
                questions={formData.FAQ ? formData.FAQ : []}
              />
            </ContentContainer>
            <FormEditDialog
              formData={
                formData.FAQ && formData.FAQ.length > 0 ? formData.FAQ : [{ question: "", answer: "" }]
              }
              updateFormData={updateFormData}
              propertyName={"FAQ"}
              dialogOpen={faqDialogOpen}
              setDialogOpen={setFaqDialogOpen}
              title={"Edit Store FAQ (15 max)"}
            >
              <FAQEditDialog classes={classes} />
            </FormEditDialog>
          </EditSection>
          {/* <EditSection>
            <SectionHeader>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
              >
                Purposes
              </Typography>
              <IconButton
              // onClick={() => setFaqDialogOpen(true)}
              >
                <EditIcon />
              </IconButton>
            </SectionHeader>
            {formData.purposes &&
              formData.purposes.map((value, i) => {
                return (
                  <ContentRow key={i} title={`Purpose #${i + 1}`}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Autocomplete
                        size="small"
                        options={allPurposes}
                        onChange={(e, value) => {
                          handleAutoChanges(value, i, "purposes");
                        }}
                        defaultValue={value}
                        getOptionLabel={(option) => option.purposeName}
                        sx={{ width: i === 0 ? "100%" : "50%" }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      <div>
                        {formData.purposes.length >= 1 && i >= 1 && (
                          <ButtonBase
                            sx={style.removeButton}
                            onClick={() => handleRemoveClick(i, "purposes")}
                          >
                            Remove
                          </ButtonBase>
                        )}
                      </div>
                    </Box>
                    <div style={{ float: "left" }}>
                      {formData.purposes.length - 1 === i && (
                        <ButtonBase
                          onClick={() => handleAddClick(i, "purposes")}
                        >
                          <Typography color={"gray"} variant={"body2"}>
                            <i>Add More</i>
                          </Typography>
                        </ButtonBase>
                      )}
                    </div>
                  </ContentRow>
                );
              })}
          </EditSection> */}
          <ButtonGridContainer container columns={1}>
            {/* <ButtonGridItem item xs={1}>
              <OutlinedButton variant="outlined">
                <Typography variant="h6" className={"roboto-medium"}>
                  Save Draft
                </Typography>
              </OutlinedButton>
            </ButtonGridItem>
            <ButtonGridItem item xs={1}>
              <OutlinedButton variant="outlined" onClick={() => {
                console.log(formData);
              }}>
                <Typography variant="h6" className={"roboto-medium"}>
                  Preview Page
                </Typography>
              </OutlinedButton>
            </ButtonGridItem> */}
            <ButtonGridItem item xs={1}>
              <PublishButton onClick={updateShopInfo}>
                <Typography variant="h6" className={"roboto-medium"}>
                  Publish Page
                </Typography>
              </PublishButton>
            </ButtonGridItem>
          </ButtonGridContainer>
          <Modal open={updating} sx={{ overflow: "scroll" }}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "max(75%, 300px)",
                bgcolor: "background.paper",
                border: "1px solid #000",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h3" align="center" sx={{ mb: 5 }}>
                Shop Information is being Updated...
              </Typography>
              <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
            </Box>
          </Modal>
        </Container>
      )}
      <Modal open={isRequesting} sx={{ overflow: "scroll" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 2,
            bgcolor: "background.paper",
            p: 4,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!requestFailed ? (
            <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography>Failed retrieving listing to edit</Typography>
              <Button
                component={Link}
                to={`/seller/${sellerIDparam}/orders`}
                color="error"
              >
                go back to dashboard
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
      <Modal open={updateFailed} sx={{ overflow: "scroll" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 2,
            bgcolor: "background.paper",
            p: 4,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Page has failed to update.
          </Typography>
          <Stack direction={"row"}>
            <Button component={Link} to={`/seller/${sellerIDparam}/orders`}>
              back to dashboard
            </Button>
            <Button onClick={() => setUpdateFailed(false)}>Try again</Button>
          </Stack>
        </Box>
      </Modal>
    </ManagementMasterLayout>
  );
}

export default SellerShopInformationLayout;
