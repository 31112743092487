import React, { useRef, useState } from "react";
import { AdminPortalManagementMaster, ManagementMasterLayout } from "..";
import sellerDashboardJsonData from "../../json/seller_dashboard.json";
import {
  Container,
  TextField,
  Box,
  ButtonBase,
  Typography,
  Grid,
  Modal,
  CircularProgress,
  Button,
  Stack,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import FileUpload, {
  UPLOAD_ERROR,
  triggerUpload,
  onDropHandler,
  onDragOverHandler,
} from "../../components/FileUpload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getBlog, updateBlog, deleteBlog } from "../../util/blogs";
import { useSelector } from "react-redux";
import { useHistory, Link, useParams } from "react-router-dom";
import { BLOG_CATEGORIES } from "../../util/constants";
import toast, { Toaster } from 'react-hot-toast';
import { MAX_FILE_SIZE, FILETYPES, MAX_NUM_FILES } from "../../util/constants"
import 'react-markdown-editor-lite/lib/index.css';
import MdEditor from 'react-markdown-editor-lite';
import markdownIt from 'markdown-it';
import FileUploadV2 from "../../components/FileUploadV2";
import { uploadImageArray } from "../../util/images";

const ContentRow = ({ title, children }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography sx={{ mb: 1, mt: 2 }}>
          <b>{title}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

const style = {
  removeButton: {
    bgcolor: "var(--red)",
    py: 1,
    px: 2.5,
    borderRadius: 2.5,
    color: "white",
  }
};

function EditBlogLayout() {
  const sellerDashboardData = sellerDashboardJsonData;
  const { blogID } = useParams();
  const history = useHistory();
  const userAttributes = useSelector((state) => state.user.user);
  // const { username } = useSelector((state) => state.user.user);
  const [formValues, setFormValues] = useState({
    title: "",
    subtitle: "",
    category: "",
    body: "",
    summary: "",
    pictures: [],
    //sellerID: id.id,
  });
  const [imageError, setImageError] = useState(null);
  const [postFailed, setPostFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let imageRef = useRef(null);

  const [loadingBlog, setLoadingBlog] = React.useState(false);
  const [loadedBlog, setLoadedBlog] = React.useState(false);

  React.useEffect(() => {
    if (!loadingBlog) {
      setLoadingBlog(true)
      getBlog(blogID, (data) => {
        setFormValues(data.results[0])
      })
    }
  }, []);

  const handleFormValues = (name, value) => {
    setFormValues((preValues) => {
      return { ...preValues, [name]: value };
    });
  };

  const changeBlog = async () => {
    setIsLoading(true);

    //Payments info
    const title = formValues.title;
    const category = formValues.category;
    const body = formValues.body;
    const imageUrls = formValues.pictures;

    let errorsInSubmission = []
    if (!title) errorsInSubmission.push("Blog must have a title!");
    if (!category) errorsInSubmission.push("Blog must have a category!");
    if (!body) errorsInSubmission.push("Blog must have a body!");
    if (!imageUrls || imageUrls.length < 3) errorsInSubmission.push("Must have three images!");

    if (errorsInSubmission.length > 0) {
      const allErrorsText = errorsInSubmission.join('\n');
      toast((t) => (
        <span>
          {allErrorsText}
          {'\n'}
          <Grid container justifyContent="center">
            <Button onClick={() => toast.dismiss(t.id)}>
              Dismiss
            </Button>
          </Grid>
        </span>
      ));
      setIsLoading(false);
    }
    else {
      
      const imgUrlArray = await uploadImageArray(imageUrls);

      updateBlog(blogID, formValues.title, formValues.subtitle, formValues.category, formValues.body, formValues.summary, imgUrlArray, (data) => {
        if (data) history.push(`/blog/` + blogID);
        setPostFailed(true);
        setIsLoading(false);
        // window.location.reload(false);
      });
    }
  };

  const removeBlog = () => {
    deleteBlog(blogID, (data) => {
      if (data) history.push(`/blog`);
      setPostFailed(true);
      setIsLoading(false);
    });

  }

  const mdParser = new markdownIt();

  return (
    <AdminPortalManagementMaster
      title="Update Blog Post"
      sellerName={sellerDashboardData.name}
      sellerPicture={sellerDashboardData.pic}
    >
      <Toaster
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <Container maxWidth={"xl"}>
      <ContentRow title={"Title:"}>
          <TextField
            size="small"
            value={formValues.title}
            onChange={(e) => handleFormValues("title", e.target.value)}
            fullWidth
          />
        </ContentRow>
        <ContentRow title={"Subtitle:"}>
          <TextField
            size="small"
            value={formValues.subtitle}
            onChange={(e) => handleFormValues("subtitle", e.target.value)}
            fullWidth
          />
        </ContentRow>
        <ContentRow title={"Category"}>
          <FormControl fullWidth>
            <Select
              value={formValues.category}
              label="Category"
              onChange={(e) => handleFormValues("category", e.target.value)}
              size="small"
            >
              {BLOG_CATEGORIES && BLOG_CATEGORIES.map((blogCategory, index) => {
                return (
                  <MenuItem value={blogCategory} key={index}>{blogCategory}</MenuItem>
                )
              })}
            </Select>
          </FormControl>

        </ContentRow>
        <ContentRow title={"Body:"}>
          <MdEditor
            style={{ height: "750px" }}
            value={formValues.body}
            renderHTML={(text) => mdParser.render(text)}
            onChange={({ html, text }) => handleFormValues("body", text)}
          />
        </ContentRow>
        <ContentRow title={"Short Summary:"}>
          <MdEditor
            style={{ height: "500px" }}
            value={formValues.summary}
            renderHTML={(text) => mdParser.render(text)}
            onChange={({ html, text }) => handleFormValues("summary", text)}
          />
        </ContentRow>
        <ContentRow title={"Upload Pictures"}>
          <Container maxWidth={"sm"}>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              rowSpacing={2}
              columnSpacing={2}
              sx={{ mt: 1 }}
            >
              <FileUploadV2
                formValueName={"pictures"}
                formValueArray={formValues.pictures}
                maxFiles={3}
                columns={3}
                handleFormValues={handleFormValues}
                buttonText={'upload: jpg, png'}
              />
              <Typography variant="caption">
                Upload 3 images, the first being the banner photo and the other 2 being smaller photos at the bottom of the blog.
              </Typography>
            </Grid>
          </Container>
        </ContentRow>
        <Grid
          container
          justifyContent={"center"}
          rowSpacing={2}
          columnSpacing={2}
          sx={{ my: 5 }}
        >
          <Grid item sx>
            <ButtonBase
              sx={{
                bgcolor: "var(--coral)",
                py: 1,
                px: 2.5,
                borderRadius: 2.5,
                color: "white",
              }}
              onClick={changeBlog}
            >
              <b>Update Blog</b>
            </ButtonBase>
            <ButtonBase
              sx={style.removeButton}
              onClick={removeBlog}
            >
              <b>Delete Blog</b>
            </ButtonBase>
          </Grid>
        </Grid>
      </Container>
      {/* Temporary loading screen while listing is being uploaded */}
      <Modal open={isLoading} sx={{ overflow: "scroll" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 2,
            bgcolor: "background.paper",
            p: 4,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
        </Box>
      </Modal>
      <Modal open={postFailed} sx={{ overflow: "scroll" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 2,
            bgcolor: "background.paper",
            p: 4,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Blog has failed to edit.
          </Typography>
          <Stack direction={"row"}>
            <Button component={Link} to={`/adminportal/currentblogs`}>
              Back to admin portal.
            </Button>
            <Button
              to={`/adminportal/currentblogs`}
              onClick={() => setPostFailed(false)}
            >
              Try Again
            </Button>
          </Stack>
        </Box>
      </Modal>
    </AdminPortalManagementMaster>
  );
}

export default EditBlogLayout;
