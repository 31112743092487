import React, { useState } from "react";
import { Typography, Grid, Modal, Button, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

const styleSuccess = {
    position: 'absolute',
    // marginLeft: "auto",
    // marginRight: "auto",
    // left: "0px",
    // right: "0px",
    // borderRadius: '16px',
    // top: "25%",
    // left: { xs: "5%", sm: "5%", md: "30%" },
    backgroundColor: "white",
    //transform: 'translate(-50%, -50%)',
    // display: "block",
    maxWidth: { xs: "75%", sm: "75%", md: "40%" },
    // height: "auto",
    border: "2px solid",
    zIndex: "2",
    padding: "32px",
    boxShadow: 24,
};

const modalCenter = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

const DeactivateConfirmPopup = (props) => {
    const { isLoading, deactivatePopupView, setDeactivatePopup, isSeller, isReactivating, handleSubmit } = props;
    const handleClose = () => {
      setDeactivatePopup(false);
    }
    var title = "CAUTION";
    var colorTitle = "#d32f2f";
    var buttonColor = "#d32f2f";
    var buttonText = "Deactivate Account"
    var text = "Are you sure you want to deactivate your account? This means that you won't have access to important information about orders, products, specials and more.";
    if (isSeller) {
      buttonText = "Deactivate Store"
      text = "Are you sure you want to deactivate your store? This means that you won't have access to important information about your store, order history, shipping, inventory and more.";
    }
    if (isReactivating) {
      buttonText = "Activate Store"
      colorTitle = "var(--coral)";
      buttonColor = "var(--mintGreenSolid)";
      title = "We're so excited to see you again!";
      text = "When you reactivate your store, your store page, products listings and other information will become available again on your seller dashboard and your store will be live to shoppers. You can always edit this information in your seller dashboard. We're glad to have you back as a Purposer Seller! Cheers!";
    }

    return (
        <Modal open={deactivatePopupView} onClose={handleClose} sx={modalCenter}>
            <Box sx={styleSuccess}>
                <Grid container justifyContent='space-between' alignItems="center" style={{ marginBottom: "20px" }}>
                    <div></div>
                    <Typography variant="h5" style={{ color: colorTitle, fontWeight: 'bold'}} >
                        {title}
                    </Typography>
                    <IconButton onClick={() => setDeactivatePopup(false)}>
                      <CloseIcon/>
                    </IconButton>
                </Grid>
                <Typography variant="h6" style={{ fontWeight: "400", textAlign: "center" }}>{text}</Typography>
                <div style={{ height: "24px" }} />
                <div style={{display: "flex", flexDirection: "row", columnGap: "2", justifyContent: "center"}}>
                <Button onClick={() => handleClose()} disabled={isLoading} variant="contained" style={{ marginRight: "8px", backgroundColor: "gray" }}
                >
                    <b>{`Go Back`}</b>
                </Button>
                <Button onClick={(e) => {
                    console.log("Trying to activate/deactivate account!")
                    handleSubmit();
                }} disabled={isLoading} variant="contained" style={{ marginRight: "8px", backgroundColor: buttonColor }}
                >
                    <b>{isLoading ? `Loading...` : buttonText}</b>
                </Button>
                </div>
            </Box>
        </Modal>
    )
}

export default DeactivateConfirmPopup;