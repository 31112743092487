import {
  Button,
  TextField,
  Grid,
  Divider,
  Typography,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Stack,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import makeStyles from "@mui/styles/makeStyles";
import { useState, useEffect } from "react";
import clsx from "clsx";
import PurposerDownloadIcon from "../img/purposer_download_icon.png";
import footerJsonData from "../json/footer.json";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";
import { addEmailToMarketingList } from "../util/klaviyo";
import { isValidEmail } from "../util/utilities";
import toast, { Toaster } from 'react-hot-toast';

const useStyles = makeStyles({
  backToTopButton: {
    backgroundColor: "#EFC4BD",
    width: "100%",
    borderRadius: 0,
    textTransform: "none",
    padding: 0,
    "&:hover": {
      backgroundColor: "#EFC4BD",
    },
  },
  backToTopText: {
    color: "#FFFFFF",
    paddingTop: 7,
    paddingBottom: 7,
    fontSize: "1.15rem",
  },
  footerDiv: {
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: "var(--coral)",
  },
  footerGridContainer: {
    paddingBottom: 20,
  },
  footerHeader: {
    fontSize: "1.4rem",
    color: "#FFFFFF",
  },
  footerContactGridItem: {
    paddingLeft: 10,
  },
  footerSubtext: {
    fontSize: "0.9rem",
    color: "#FFFFFF",
  },
  footerDivider: {
    background: "#FFFFFF",
  },
  footerRoundTextField: {
    "& label": {
      color: "var(--coral)",
    },
    "& label.Mui-focused": {
      color: "var(--coral)",
    },
    "& .MuiOutlinedInput-root": {
      height: 50,
      borderRadius: 50,
      backgroundColor: "#FFFFFF",
      color: "var(--coral)",
      borderColor: "#FFFFFF",
      "& fieldset": {
        borderColor: "#FFFFFF",
      },
      "&:hover fieldset": {
        borderColor: "#FFFFFF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FFFFFF",
      },
    },
  },
  footerButton: {
    textTransform: "none",
  },
  footerSignUpButton: {
    backgroundColor: "#FFFFFF",
    borderRadius: 50,
    minHeight: 50,
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  footerSignUpButtonText: {
    fontSize: "1.1rem",
    color: "var(--coral)",
  },
  footerSocialMediaGridItem: {
    paddingRight: 15,
  },
  footerSocialMediaButtonGridItem: {
    padding: 5,
    display: "flex",
    alignItems: "center",
  },
  footerSocialMediaButtonIcon: {
    height: 45,
  },
  footerDownloadButton: {
    minHeight: 50,
    borderRadius: 20,
    borderColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "var(--coral)",
    },
  },
  footerDownloadButtonIcon: {
    paddingRight: 15,
    maxHeight: 45,
  },
  footerDownloadButtonText: {
    fontSize: "1.1rem",
    color: "#FFFFFF",
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
});

const SocialMediaButton = (props) => {
  const { link, icon, classes } = props;
  return (
    <Grid item className={classes.footerSocialMediaButtonGridItem}>
      {link.length > 0 ? 
      (
        <a href={link} target="_blank">
          <img
            src={icon}
            alt=""
            className={classes.footerSocialMediaButtonIcon}
          />
        </a>
      )
      :
      (
      <Tooltip title="Coming soon!" placement="top" arrow>
        <a>
          <img
            src={icon}
            alt=""
            className={classes.footerSocialMediaButtonIcon}
          />
        </a>
      </Tooltip>
      )}
    </Grid>
  );
};

function Footer() {
  const classes = useStyles();
  const footerData = footerJsonData.columns;
  const contactData = footerJsonData.contactData;
  const media = useMobileMediaQuery();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleClickSignUpEmail = (event) => {
    if (!isLoading && isValidEmail(email)) {
      setIsLoading(true);
      addEmailToMarketingList(email, (data) => {
        if (data && data.success === true) {
          toast.success('Success!')
          setIsLoading(false);
        } else {
          toast.error(`Error! ${data?.note ? data?.note : ''}`);
          setIsLoading(false);
          return;
        }
      })
    } else if (!isValidEmail(email)) {
      toast.error('Invalid email address!');
    }
  };

  return (
    <>
      <Toaster 
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <Button
        disableRipple
        onClick={() => {
          window.scroll({
            top: 0,
            behavior: "smooth",
          });
        }}
        className={classes.backToTopButton}
      >
        <Typography align="center" className={classes.backToTopText}>
          <b>Back to Top</b>
        </Typography>
      </Button>
      <div className={classes.footerDiv}>
        {!media ? (
          <Container maxWidth="xl">
            <Grid
              container
              direction="row"
              className={classes.footerGridContainer}
            >
              <Grid container item xs={7} direction="row">
                {footerData.map((column, index) => {
                  return (
                    <Grid
                      key={index}
                      container
                      item
                      direction="column"
                      xs
                      spacing={1}
                    >
                      <Grid item>
                        <Typography className={classes.footerHeader}>
                          {column.header}
                        </Typography>
                      </Grid>
                      {column.subheadings.map((subheading, index) => {
                        return (
                          <Grid item key={index}>
                            <a href={subheading.link} className={classes.link} target={subheading.link?.startsWith("https://") && "_blank"}>
                              <Typography className={classes.footerSubtext}>
                                {subheading.label}
                              </Typography>
                            </a>
                          </Grid>
                        );
                      })}
                    </Grid>
                  );
                })}
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-evenly"
                xs={5}
              >
                <Grid item>
                  <Divider
                    orientation="vertical"
                    className={classes.footerDivider}
                  />
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  spacing={2}
                  xs={11}
                  className={classes.footerContactGridItem}
                >
                  <Grid item>
                    <Typography className={classes.footerHeader}>
                      PURPOSER UPDATES!
                    </Typography>
                  </Grid>
                  <Grid item container spacing={1} alignItems="center">
                    <Grid item xs>
                      <TextField
                        fullWidth
                        label={email === "" ? "Email Address" : ""}
                        onChange={handleChange}
                        variant="outlined"
                        InputLabelProps={{ shrink: false }}
                        className={classes.footerRoundTextField}
                      ></TextField>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        disableElevation
                        className={clsx(
                          classes.footerButton,
                          classes.footerSignUpButton
                        )}
                        onClick={handleClickSignUpEmail}
                        disabled={isLoading}
                      >
                        <Typography className={classes.footerSignUpButtonText}>
                          <b>SIGN UP</b>
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <a href="/contact" className={classes.link}>
                      <Typography className={classes.footerHeader}>
                        CONTACT US
                      </Typography>
                    </a>
                    <Grid container direction="row">
                      <Grid container item direction="column" xs>
                        {contactData.labels.map((label, index) => {
                          return (
                            <Grid item key={index}>
                              <Typography className={classes.footerSubtext}>
                                {label}
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid container item direction="column" xs>
                        {contactData.contacts.map((contact, index) => {
                          return (
                            <Grid item key={index}>
                              <Typography className={classes.footerSubtext}>
                                {contact}
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center">
              {/* <Grid item className={classes.footerSocialMediaGridItem}>
                <Button
                  href="https://www.purposer.com"
                  variant="outlined"
                  className={clsx(
                    classes.footerButton,
                    classes.footerDownloadButton
                  )}
                >
                  <img
                    src={
                      "https://d2eudfgh8kpk8j.cloudfront.net/common/794796cd-dc99-4f9a-8521-f66be38f3c5a.png"
                    }
                    className={classes.footerDownloadButtonIcon}
                    alt=""
                  />
                  <Typography className={classes.footerDownloadButtonText}>
                    <b>Download the Purposer App</b>
                  </Typography>
                </Button>
              </Grid> */}
              {footerJsonData.socialMediaButtons &&
                footerJsonData.socialMediaButtons.length > 0 &&
                footerJsonData.socialMediaButtons.map((button, index) => {
                  return (
                    <SocialMediaButton
                      key={index}
                      link={button.link}
                      icon={button.icon}
                      classes={classes}
                    />
                  );
                })}
            </Grid>
          </Container>
        ) : (
          <Container>
            <Grid
              item
              container
              direction="column"
              spacing={2}
              xs={11}
              className={classes.footerContactGridItem}
              sx={{ py: 2 }}
            >
              <Grid item>
                <Typography className={classes.footerHeader}>
                  PURPOSER UPDATES!
                </Typography>
              </Grid>
              <Grid item container spacing={1} alignItems="center">
                <Grid item xs>
                  <TextField
                    fullWidth
                    label={email === "" ? "Email Address" : ""}
                    onChange={handleChange}
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    className={classes.footerRoundTextField}
                  ></TextField>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    disableElevation
                    className={clsx(
                      classes.footerButton,
                      classes.footerSignUpButton
                    )}
                  >
                    <Typography className={classes.footerSignUpButtonText}>
                      <b>SIGN UP</b>
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                <a href="/contact" className={classes.link}>
                  <Typography className={classes.footerHeader}>
                    CONTACT US
                  </Typography>
                </a>
                <Grid container direction="row">
                  <Grid container item direction="column" xs>
                    {contactData.labels.map((label, index) => {
                      return (
                        <Grid item key={index}>
                          <Typography className={classes.footerSubtext}>
                            {label}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid container item direction="column" xs>
                    {contactData.contacts.map((contact, index) => {
                      return (
                        <Grid item key={index}>
                          <Typography className={classes.footerSubtext}>
                            {contact}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {footerData.map((column, index) => {
              return (
                <div key={index}>
                  <Accordion
                    sx={{
                      border: "none",
                      boxShadow: "none",
                      "& .MuiAccordionSummary-root.Mui-expanded": {
                        minHeight: 10,
                      },
                      "& .MuiSvgIcon-root": {
                        color: "white",
                        fontSize: "50px",
                      },
                      backgroundColor: "var(--coral)",
                      color: "white",
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        maxHeight: 30,
                        padding: "5px 10px",
                        "& .MuiAccordionSummary-content.Mui-expanded": {
                          margin: 0,
                        },
                      }}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography variant="h6">{column.header}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "5px 16px" }}>
                      {column.subheadings.map((subheading, index) => {
                        return (
                          <a
                            href={subheading.link}
                            className={classes.link}
                            key={index}
                          >
                            <Typography>{subheading.label}</Typography>
                          </a>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                  <Divider sx={{ borderColor: "lightGrey" }} />
                </div>
              );
            })}
            <Stack sx={{ padding: "20px 16px 0px 16px" }}>
              {/* <Button
                href="https://www.purposer.com"
                variant="outlined"
                className={clsx(
                  classes.footerButton,
                  classes.footerDownloadButton
                )}
              >
                <img
                  src={PurposerDownloadIcon}
                  className={classes.footerDownloadButtonIcon}
                  alt=""
                />
                <Typography className={classes.footerDownloadButtonText}>
                  <b>Download the Purposer App</b>
                </Typography>
              </Button> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                {footerJsonData.socialMediaButtons &&
                  footerJsonData.socialMediaButtons.length > 0 &&
                  footerJsonData.socialMediaButtons.map((button, index) => {
                    return (
                      <SocialMediaButton
                        key={index}
                        link={button.link}
                        icon={button.icon}
                        classes={classes}
                      />
                    );
                  })}
              </Box>
            </Stack>
          </Container>
        )}
      </div>
    </>
  );
}

export default Footer;
