import React, { useState } from "react";
import {
  ButtonGroup,
  Typography,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Stack,
  outlinedInputClasses,
  Grid,
  Box,
  Rating,
  Select,
  MenuItem,
  Menu,
} from "@mui/material";
import wineTumblerProductData from "../data/wine_tumbler_product_layout.json";
import { Add, Remove } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { addItemToCart, selectCoupon, selectCartItems, addCouponToCart, removeCouponFromCart } from "../app/features/cart/cartDataSlice";
import ColorCircle from "./ColorCircle";
import { useParams } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { getReviewsByProduct } from "../util/products";
import { getCouponsByCouponCode, getCouponsByCouponCodeAwait } from "../util/coupons";
import { PRODUCT_SIZES, PRODUCT_COLORS, PRODUCT_MATERIALS } from "../util/constants";
import { round, hasDatePassed, outputViewProductVariationSize, sortSizes } from "../util/utilities";


const QuantityButtonContainer = styled(Stack)({
  height: 30,
  paddingTop: 5,
  paddingBottom: 20,
});

const GreyLabel = styled(Button)({
  border: "none",
  color: "#000000",
  borderRadius: 12,
  backgroundColor: "var(--lightGrayTransparent)",
  marginRight: 25,
  height: "100%",
  textTransform: "none",
  "&:hover": {
    border: "none",
    backgroundColor: "var(--lightGrayTransparent)",
  },
});

const QuantityCounterButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "position",
})(({ position }) => ({
  border: "none",
  color: "#000000",
  paddingLeft: 0,
  paddingRight: 0,
  borderTopLeftRadius: position === "left" ? 12 : 0,
  borderBottomLeftRadius: position === "left" ? 12 : 0,
  borderTopRightRadius: position === "right" ? 12 : 0,
  borderBottomRightRadius: position === "right" ? 12 : 0,
  backgroundColor: "var(--lightGrayTransparent)",
  "&:hover": {
    border: "none",
    backgroundColor: "var(--lightGrayTransparent)",
  },
}));

const QuantityButtonGroup = styled(ButtonGroup)({
  height: "100%",
});

const CustomizationForm = styled(FormControl)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "16px"
});

const CustomizationLabel = styled(Typography)({
  color: "#000000",
});

const CustomizationRadio = styled(Radio)({
  marginTop: 5,
  marginBottom: 5,
});

const CustomizationContainer = styled(Stack)({
  height: 30,
});

const CustomizationTextField = styled(TextField)({
  [`& .${outlinedInputClasses.root}`]: {
    height: "100%",
  },
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderRadius: 30,
    height: "100%",
  },
  [`& .${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
  {
    borderColor: "var(--mintGreen) !important",
  },
});

const ColorGridContainer = styled(Grid)({
  maxWidth: 430,
  marginTop: 15,
});

const AddToCartButton = styled(Button)({
  color: "#FFFFFF",
  backgroundColor: "var(--coral)",
  borderRadius: 16,
  paddingTop: 24,
  paddingBottom: 24,
  paddingLeft: 48,
  paddingRight: 48,
  height: 30,
  marginTop: 30,
  marginBottom: 10,
  "&:hover": {
    backgroundColor: "var(--coral)",
  },
});

const QuantityCounter = (props) => {
  const { quantityCount, setQuantityCount, decrementCustom, incrementCustom } = props;

  return (
    <QuantityButtonGroup variant="outlined">
      <QuantityCounterButton
        position={"left"}
        onClick={() => {
          if (quantityCount > 1) {
            setQuantityCount(quantityCount - 1);
            decrementCustom();
          }
        }}
      >
        <Remove fontSize="small" />
      </QuantityCounterButton>
      <QuantityCounterButton
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        <Typography>{quantityCount}</Typography>
      </QuantityCounterButton>
      <QuantityCounterButton
        position={"right"}
        onClick={() => {
          setQuantityCount(quantityCount + 1);
          incrementCustom();
        }}
      >
        <Add fontSize="small" />
      </QuantityCounterButton>
    </QuantityButtonGroup>
  );
};

const CustomizationInput = (props) => {
  const { label = "" } = props;

  return (
    <CustomizationContainer direction="row">
      {label && label.length > 0 && (
        <GreyLabel>
          <Typography className={"roboto-regular"}>{label}</Typography>
        </GreyLabel>
      )}
      <CustomizationTextField variant="outlined" />
    </CustomizationContainer>
  );
};

const PurchaseView = ({ data = wineTumblerProductData.productData, selectedSku, setSelectedSku }) => {
  const [quantityCount, setQuantityCount] = useState(1);
  const [value, setValue] = useState("");
  const [customValue, setCustomValue] = useState([""]);
  let { id } = useParams();
  const [avg, setAvg] = React.useState(0);

  const dispatch = useDispatch();
  const reduxCartItems = useSelector(selectCartItems);
  const cartCoupon = useSelector(selectCoupon);

  const [currentColor, setColor] = useState("");
  const [currentSize, setSize] = useState("");
  const [loadingGetReviews, setLoadingGetReviews] = useState(false);
  const [loadingCartEdit, setLoadingCartEdit] = useState(false);

  const handleCustomChange = (arrayIndex, value) => {
    const newCustomValues = [...customValue];
    newCustomValues[arrayIndex] = value
    setCustomValue(newCustomValues);
  };

  const decrementCustom = () => {
    const newCustomValues = [...customValue].splice(0,-1);
    setCustomValue(newCustomValues);
  };

  const incrementCustom = () => {
    const newCustomValues = [...customValue];
    newCustomValues.push(customValue[customValue.length-1]);
    setCustomValue(newCustomValues);
  };

  const handleSizeChange = (size) => {
    setSize(size);
    handleOptionChange(currentColor, size);
  };

  const handleOptionChange = (color, size) => {
    console.log('Changed SKU to:', data.options?.find(o => o.color == color && o.size == size)?.skuID)
    setSelectedSku(data.options?.find(o => o.color == color && o.size == size)?.skuID);
  };


  const [reviewData, setReviewData] = React.useState({});


  React.useEffect(() => {
    if (!loadingGetReviews && !reviewData?.results) {
      setLoadingGetReviews(true)
      getReviewsByProduct(id, (data) => {
        setReviewData(data);
        let sum = 0;
        if (data && data.hasOwnProperty("results")) {
          for (let i = 0; i < data.results.length; i++) {
            sum += data.results[i].rating;
          }
          const avg = sum / data.results.length;
          setAvg(avg);
        }
      })
    }

  }, [id]);

  React.useEffect(() => {
    const activeOption = data.options?.find(o => o.skuID?.toLowerCase() == selectedSku?.toLowerCase() && o.active);
    if (!currentColor && selectedSku && activeOption) {
      setColor(activeOption.color);
    }

    if (!currentSize && selectedSku && activeOption) {
      setSize(activeOption.size);
    }

  }, [selectedSku, data]);

  const handleColorChange = (color) => {
    const selectedColorName = PRODUCT_COLORS.find(c => c.color == color)?.name
    setColor(selectedColorName);
    handleOptionChange(selectedColorName, currentSize);
  };

  function countCouponAppliedSkuIDsInCart(reduxCartItems, couponObject, incomingSkuID = null) {
    let couponAppliedToItemsInCart = 0;
    const skuIdSet = new Set();
    for (const cartItem of reduxCartItems) {
      const skuId = cartItem.skuId;
      skuIdSet.add(skuId)
      if (couponObject.skuIDs.includes(skuId)) {
        couponAppliedToItemsInCart++;
      }
    }

    if (incomingSkuID && couponObject.skuIDs.includes(incomingSkuID) && !skuIdSet.has(incomingSkuID)) {
      couponAppliedToItemsInCart++;
    }
    return couponAppliedToItemsInCart;
  }

  const addItemToCartCheck = async (product, skuID, quantity) => {
    if (!loadingCartEdit) {
      setLoadingCartEdit(true);
      let currCoupon = cartCoupon;
      let canAddItem = true;
      const incomingCartItemCouponCode = product.options.find(o => o.skuID.toLowerCase() == skuID.toLowerCase())?.couponCode;
      if (currCoupon) {
        const cartCouponResponse = await getCouponsByCouponCodeAwait(currCoupon.couponCode);
        if (cartCouponResponse?.length > 0) {
          if (cartCouponResponse[0].active == false) {
            console.log(`coupon in cart is no longer active!`);
            dispatch(
              removeCouponFromCart()
            );
            currCoupon = null;
          } else {
            //coupon is active
            let couponAppliedToItemsInCart = countCouponAppliedSkuIDsInCart(reduxCartItems, cartCouponResponse[0], skuID);
            if (couponAppliedToItemsInCart == 0) {
              console.log('Coupon not applicable to any items in cart! Removing');
              dispatch(
                removeCouponFromCart()
              );
              currCoupon = null;
            } else {
              console.log(`Coupon in cart now applicable to ${couponAppliedToItemsInCart} items in cart!`);
            }
          }
        }
      }

      if (incomingCartItemCouponCode) {
        console.log('Coupon attached to incoming product, attempting grab...')
        const cartCouponResponse = await getCouponsByCouponCodeAwait(incomingCartItemCouponCode);
        if (cartCouponResponse?.length > 0) {
          if (cartCouponResponse[0].active == false) {
            console.log(`Coupon attached to incoming product is no longer active!`);
          } else {
            //coupon is active
            let couponAppliedToItemsInCart = countCouponAppliedSkuIDsInCart(reduxCartItems, cartCouponResponse[0], skuID);
            if (couponAppliedToItemsInCart == 0) {
              console.log('Coupon attached to incoming product is not applicable to any items in cart or incoming product!');
            } else {
              console.log(`Coupon attached to incoming product is now applicable to ${couponAppliedToItemsInCart} items in cart!`);
              if (!currCoupon) {
                dispatch(
                  addCouponToCart({ couponObject: cartCouponResponse[0] })
                );
                currCoupon = cartCouponResponse[0];
              } else if (currCoupon.couponID == cartCouponResponse[0].couponID) {
                console.log(`Attached product coupon being added is already in cart!`)
              } else {
                console.log(`Conflicting coupons attempted to add, denying add item to cart!`)
                canAddItem = false;
              }
            }
          }
        }
      }

      if (canAddItem) {
        //make sure they can add that quantity
        const productOptionInventory = product?.options?.find(o => o.skuID.toLowerCase() == skuID.toLowerCase())?.inventory;
        //get current cart quantity
        const cartQuantity = reduxCartItems?.find(i => i.skuId.toLowerCase() == skuID.toLowerCase())?.quantity;
        
        if (productOptionInventory < quantity + cartQuantity) {
          toast.error(`Looks like there isn't enough of this product! Try reducing the quantity (or remove from cart!)`, {
            duration: 5000,
          })
          setLoadingCartEdit(false);
          return;
        }
        dispatch(
          addItemToCart({ productId: product.productID, skuId: skuID, addAmount: quantity, customization: product.customizable ? customValue : null })
        );
        toast.success('Added to cart!')
        setLoadingCartEdit(false);
        window.location.href = "/cart";
      } else {
        toast.error('Cannot add item to cart as it conflicts with a sale already in cart!', {
          duration: 5000,
        })
        setLoadingCartEdit(false);
      }
    }

  };

  return (
    <>
      {data && data.seller &&
        <div key={'purchaseviewdiv'+data.productID} >
          <Toaster
            toastOptions={{
              className: '',
              style: {
                border: '1px solid #713200',
                padding: '16px',
                color: '#713200',
              },
            }}
          />
          <Typography variant="h1" style={{fontSize: "28px"}} className={"roboto-regular"}>
            {data.productName}
          </Typography>
          <Typography
            className={"roboto-regular"}
            style={{ textDecoration: "underline" }}
            variant="h6"
          >
            <a href={'/shop/seller/' + data.seller?.sellerID + "/" + data.seller?.sellerName?.replace(/\W+/g, "-")}>{data.seller?.sellerName} </a>
          </Typography>
          <Stack direction="row" sx={{ mb: 1, mt: 1 }}>
            <Rating
              sx={{ color: "var(--coral)" }}
              readOnly
              value={avg}
              precision={0.5}
            />
            <Typography className={"roboto-medium"}>
              ({reviewData.results ? reviewData.results.length : 0})
            </Typography>
          </Stack>
          <Typography variant="h6" gutterBottom className={"roboto-regular"} style={{color: "var(--mintGreenSolid)"}}>
            <b>{"Purpose: " }</b>
            {data.purposes && data.purposes.map((purpose, i) => 
              <a href={'/shop/purpose/' + purpose.productPurposeID + "/" + purpose.purposeName.replace(/\s/g, '-')}>{purpose.purposeName + ((i < data.purposes.length - 1) ? ", " : "")}</a> 
            )}
            
          </Typography>
          {data.options?.find(o => o.skuID == selectedSku && o.active) &&
            <>
              {data.options?.find(o => o.skuID == selectedSku && o.active)?.saleText ?
                <>
                  <Typography variant="h5" className={"roboto-medium"} style={{ color: "red" }}>
                    ${round(data.options?.find(o => o.skuID == selectedSku && o.active)?.price, 2)} {data.options?.find(o => o.skuID == selectedSku && o.active)?.saleText}
                  </Typography>
                  <strike>
                    <Typography variant="h5" >
                      ${round(data.options?.find(o => o.skuID == selectedSku && o.active)?.originalPrice, 2)}
                    </Typography>
                  </strike>
                </> :
                <Typography variant="h5" className={"roboto-medium"}>
                  ${round(data.options?.find(o => o.skuID == selectedSku && o.active)?.price, 2)}
                </Typography>
              }
            </>
          }
          {data.options?.find(o => o.skuID?.toLowerCase() == selectedSku?.toLowerCase() && o.active && o.inventory <= 0) &&
            <Typography variant="h5" gutterBottom className={"roboto-medium"}>
              {"This item is out of stock!"}
            </Typography>
          }
          {!data.options?.find(o => o.skuID?.toLowerCase() == selectedSku?.toLowerCase() && o.active) &&
            <Typography variant="h5" gutterBottom className={"roboto-medium"}>
              {'This product variation is not available. Please try a different size/color combination!'}
            </Typography>
          }
          <QuantityButtonContainer direction="row">
            <GreyLabel>
              <Typography className={"roboto-regular"}>QTY.</Typography>
            </GreyLabel>
            <QuantityCounter
              quantityCount={quantityCount}
              setQuantityCount={setQuantityCount}
              decrementCustom={decrementCustom}
              incrementCustom={incrementCustom}
            />
          </QuantityButtonContainer>
          
          {data?.sizes?.length > 0 && (
            <CustomizationForm size="small">
              <FormLabel component="legend">
                <CustomizationLabel className={"roboto-medium"} variant="h6">
                  {`Size/Style`}
                </CustomizationLabel>
              </FormLabel>
              <Select
                aria-label="sizes"
                value={currentSize}
                onChange={(e) => {
                  const currSize = e.target.value;
                  handleSizeChange(currSize)
                }}
                
              >
              {sortSizes(data.sizes).map((size, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={size}
                      //control={<MenuItem color="default" />}
                      //label={size}
                      //checked={size == currentSize}
                    >
                      {outputViewProductVariationSize(size)}
                      </MenuItem>
                  );
                })}
              </Select>
            </CustomizationForm>
          )}
          {data.customizable && (
            <>
            <CustomizationForm size="small" sx={{mt: 1, mb: 1}}>
              {/* <br></br> */}
              <FormLabel component="legend">
                <CustomizationLabel className={"roboto-medium"} variant="h6">
                  Customization
                </CustomizationLabel>
              </FormLabel>
            </CustomizationForm>
            <CustomizationForm size="small" sx={{mt: 1, mb: 1}}>
              <Typography>
                {data?.customInstructions != null ? data?.customInstructions : ''}
              </Typography>
            </CustomizationForm>
              
              {quantityCount > 0 &&
                [...Array(quantityCount)].map((num, index) => {
                return (
                  <CustomizationForm size="small" sx={{mt: 1, mb: 1}}>
                    <Typography>
                      {`Customization #${index + 1}`}
                    </Typography>
                    <TextField
                      name={"customization"+index}
                      onChange={(e) => {handleCustomChange(index, e.target.value)}}
                      value={customValue[index]}
                      variant="outlined"
                      size="small"
                      inputProps={{minLength: 0, maxLength: 20}}
                    />
                  </CustomizationForm>
                )})
              }
              
            </>
          )}
          {data?.colors?.length > 0 && (
            <>
              {/* <CustomizationLabel className={"roboto-medium"} variant="h6">
                Colors
              </CustomizationLabel> */}
              <ColorGridContainer
                container
                // columns={data.colors.length}
                columnGap={2}
              >
                {data.colors.filter(c => c !== "N/A").map((color, index) => {
                  const currColor = PRODUCT_COLORS.find(c => c.name == color)?.color;
                  return (
                    <Grid item xs={1} key={index}>
                      <ColorCircle
                        color={currColor}
                        selected={color == currentColor}
                        onClick={(e) => {
                          handleColorChange(currColor)
                        }}
                      />
                    </Grid>
                  );
                })}
              </ColorGridContainer>
            </>
          )}
          
          <Box
            sx={{
              display: "flex",
              justifyContent: { md: "left", xs: "center" },
            }}
          >
            <AddToCartButton
              disableRipple
              disabled={loadingCartEdit || !selectedSku}
              onClick={() => {
                if (id !== -1 && data && selectedSku) {
                  addItemToCartCheck(data, selectedSku, quantityCount)
                  // dispatch(
                  //   addItemToCart({ productId: data.productID, skuId: selectedSku, addAmount: quantityCount })
                  // );
                  // toast.success('Added to cart!')
                  // // Redirect to cart
                  // window.location.href = "/cart";
                } else {
                  console.log("Must have a size and color selected!");
                  toast.error("Must have a size and color selected!", {
                    duration: 5000,
                  })
                }
              }}
            >
              <Typography className={"roboto-bold"} variant="h6">
                {loadingCartEdit ? 'LOADING' : `ADD TO CART`}
              </Typography>
            </AddToCartButton>
          </Box>
        </div>
      }
    </>
  );
};

export default PurchaseView;
