import React, { useState } from "react";
import { Typography, Grid, Modal, Button, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

const styleSuccess = {
    position: 'absolute',
    // marginLeft: "auto",
    // marginRight: "auto",
    // left: "0px",
    // right: "0px",
    // borderRadius: '16px',
    // top: "25%",
    // left: { xs: "5%", sm: "5%", md: "30%" },
    backgroundColor: "white",
    //transform: 'translate(-50%, -50%)',
    // display: "block",
    maxWidth: { xs: "75%", sm: "75%", md: "40%" },
    // height: "auto",
    border: "2px solid",
    zIndex: "2",
    padding: "32px",
    boxShadow: 24,
};

const modalCenter = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

const OrderReturnDenyPopup = (props) => {
    const { isLoading, denyPopupView, setDenyPopupView, handleDenyReturn, row, setOrderView, denyReason } = props;
    const handleClose = () => {
        setDenyPopupView(false);
    }

    return (
        <Modal open={denyPopupView} onClose={handleClose} sx={modalCenter}>
            <Box sx={styleSuccess}>
                <Grid container justifyContent='space-between' alignItems="center" style={{ marginBottom: "20px" }}>
                    <div></div>
                    <Typography variant="h5" style={{ color: "var(--coral)", fontWeight: 'bold'}} >
                        CAUTION! 
                    </Typography>
                    <IconButton onClick={() => setDenyPopupView(false)}>
                      <CloseIcon/>
                    </IconButton>
                </Grid>
                <Typography variant="h6" style={{ fontWeight: "400", textAlign: "center" }}>Please do <b>NOT</b> press complete until you are sure that you would like to to Deny This Return.</Typography>
                <div style={{ height: "16px" }} />
                <div style={{display: "flex", flexDirection: "row", columnGap: "2", justifyContent: "center"}}>
                <Button onClick={() => handleClose()} disabled={isLoading} variant="contained" style={{ marginRight: "8px", backgroundColor: "gray" }}
                >
                    <b>{`Go Back`}</b>
                </Button>
                <Button onClick={(e) => {
                    handleDenyReturn(e, denyReason);
                }} disabled={isLoading} variant="contained" style={{ marginRight: "8px", backgroundColor: "var(--mintGreenSolid)" }}
                >
                    <b>{isLoading ? `Loading...` : `Deny Return`}</b>
                </Button>
                </div>
            </Box>
        </Modal>
    )
}

export default OrderReturnDenyPopup;