import { Typography, Container, TextField, Button, Box } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useState, useEffect } from "react";
import { AdminPortalManagementMaster } from "..";
import { useParams } from "react-router-dom";
import { ManagementMasterLayout } from "..";
import TrackingandShippingView from "../../components/TrackingandShippingView";
import TrackingandShippingCompletedView from "../../components/TrackingandShippingCompletedView";
import sellerDashboardJsonData from "../../json/seller_dashboard.json";
import { getOrderById, getOrdersBySellerId, markOrderAsSubmitted, markOrderAsCompleted, addOrderShippingDetails, getOrdersByEmail, getOrdersByUsername, getOrdersByOrderIdShortened } from "../../util/orders";
import { shortenUUID } from "../../util/utilities";
import toast, { Toaster } from 'react-hot-toast';
import { ORDER_STATUS } from "../../util/constants";

function CustomerOrdersLayout() {
  const [formValues, setFormValues] = useState({
    email: '',
    username: '',
    orderID: '',
  })

  const handleFormValues = (name, value) => {
    setFormValues((preValues) => {
      return { ...preValues, [name]: value };
    });
  };

  const [orders, setOrders] = useState([]);
  const [filterOrders, setFilterOrders] = useState('Newest First');
  const [isLoading, setIsLoading] = useState(false);

  //TODO: add this if we want this functionality
  //const sellerIDparam = "01BDF8AE-CA97-4B9F-A5A0-8CEA2D0CFD20";

  useEffect(() => {
    if (!orders) return;
    sortOrders([...orders]);
  }, [filterOrders]);

  function handleMarkOrderAsSubmitted(orderID) {
    // console.log('Marking order as submitted: ', orderID)
    // markOrderAsSubmitted(sellerIDparam, orderID, (data) => {
    //   let newOrders = [...orders];
    //   const changedOrder = newOrders.find(o => o.orderID.toLowerCase() == orderID.toLowerCase())
    //   changedOrder.status = ORDER_STATUS.SUBMITTED;
    //   sortOrders(newOrders)
    //   toast.success(`Marked order ${shortenUUID(orderID)} as uncomplete!`);
    // });
  }

  function handleMarkOrderAsCompleted(orderID) {
    // console.log('Marking order as completed: ', orderID)
    // markOrderAsCompleted(sellerIDparam, orderID, (data) => {
    //   let newOrders = [...orders];
    //   const changedOrder = newOrders.find(o => o.orderID.toLowerCase() == orderID.toLowerCase())
    //   changedOrder.status = ORDER_STATUS.COMPLETED;
    //   sortOrders(newOrders)
    //   toast.success(`Marked order ${shortenUUID(orderID)} as complete!`);
    // });
  }

  function handleChangeShippingInfo(orderID, courier, trackingNum) {
    // let newOrders = [...orders];
    // const changedOrder = newOrders.find(o => o.orderID.toLowerCase() == orderID.toLowerCase())
    // changedOrder.shippingTrackingCarrier = courier;
    // changedOrder.shippingTrackingNumber = trackingNum;
    // changedOrder.unsaved = true;
    // sortOrders(newOrders)
  }

  function handleSaveShippingInfo(orderID) {
    // console.log('Saving shipping info for: ', orderID)
    // let newOrders = [...orders];
    // const currOrder = newOrders.find(o => o.orderID.toLowerCase() == orderID.toLowerCase())
    // addOrderShippingDetails(sellerIDparam, orderID, currOrder.shippingTrackingCarrier, currOrder.shippingTrackingNumber, (data) => {
    //   let newOrders = [...currentOrders, ...completedOrders];
    //   const changedOrder = newOrders.find(o => o.orderID.toLowerCase() == orderID.toLowerCase())
    //   changedOrder.unsaved = false;
    //   sortOrders(newOrders)
    //   toast.success(`Updated shipping info for order ${shortenUUID(orderID)}!`);
    // });
  }

  function sortArrayWithFilter(orders, filter) {
    if (filter == 'Newest First') {
      orders.sort((a, b) =>  { return new Date(b.orderDateTime) - new Date(a.orderDateTime) });
    } else if (filter == 'Oldest First') {
      orders.sort((b, a) => { return new Date(b.orderDateTime) - new Date(a.orderDateTime) });
    }
  }

  function sortOrders(orders) {
    sortArrayWithFilter(orders, filterOrders);
    setOrders(orders);
  }

  const handleSubmit = async (e) => {
    if (!isLoading) {
      e.preventDefault();
      setIsLoading(true);
      if (formValues.email) {
        getOrdersByEmail(formValues.email, (data) => {
          if (data) sortOrders(data);
          else console.log("Can't find orders!");
          setIsLoading(false);
        });
      } else if (formValues.username) {
        getOrdersByUsername(formValues.username, (data) => {
          if (data) sortOrders(data);
          else console.log("Can't find orders!");
          setIsLoading(false);
        });
      } else if (formValues.orderID) {
        getOrdersByOrderIdShortened(formValues.orderID, (data) => {
          if (data) sortOrders(data);
          else console.log("Can't find orders!");
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    }
  }

  return (
    <AdminPortalManagementMaster>
      <Container style={{padding: "36px"}}>
        <Typography variant="h5" style={{color: "var(--coral)", fontWeight: "bold"}}>Customer Orders</Typography>
        <Typography>Search by one.</Typography>
        <div style={{height: "20px"}}/>
        <Typography variant="h6">Email</Typography>
        <TextField 
          value={formValues.email} 
          onChange={(e) => {
            handleFormValues("email", e.target.value)
            handleFormValues("username", '')
            handleFormValues("orderID", '')
          }} 
          size="small"
        />
        <div style={{height: "12px"}}/>
        <Typography variant="h6">Username</Typography>
        <TextField 
          value={formValues.username} 
          onChange={(e) => {
            handleFormValues("email", '')
            handleFormValues("username", e.target.value)
            handleFormValues("orderID", '')
          }} 
          size="small"
        />
        <div style={{height: "12px"}}/>
        <Typography variant="h6">Order No.</Typography>
        <TextField 
          value={formValues.orderID} 
          onChange={(e) => {
            handleFormValues("email", '')
            handleFormValues("username", '')
            handleFormValues("orderID", e.target.value)
          }} 
          size="small"
        />
        <div style={{height: "12px"}}/>
        <Button 
          onClick={handleSubmit}
          variant="contained" 
          sx={{paddingLeft: "24px", paddingRight: "24px", backgroundColor: "var(--coral)", color: "white", '&:hover': {backgroundColor: "var(--mintGreenSolid)"}}}
        >
          <SearchIcon style={{marginRight: "4px"}}/>
          Search
        </Button>
        <div style={{height: "var(--dividerHeight)"}}/>
        <Typography variant="h5" style={{color: "var(--coral)", fontWeight: "bold"}}>Results</Typography>
        <Box sx={{ mb: 5, mx: 2 }}>
        <TrackingandShippingCompletedView
          trackShipData={orders}
          filter={filterOrders}
          setFilter={setFilterOrders}
          handleMarkOrderAsSubmitted={handleMarkOrderAsSubmitted}
          handleChangeShippingInfo={handleChangeShippingInfo}
          handleSaveShippingInfo={handleSaveShippingInfo}
          title="Orders"
        />
      </Box>
      </Container>
    </AdminPortalManagementMaster>
    
  )
}

export default CustomerOrdersLayout;