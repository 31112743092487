import React from "react";

import { Typography, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandableQuestionsView from "../../components/ExpandableQuestionsView";
import BubbleCategoryView from "../../components/BubbleCategoryView";
import ShopByCategoryView from "../../components/ShopByCategoryView";

import FAQData from "../../json/main_faq_data.json";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { PurposeCategoryMasterLayout } from "../../layouts";
import useDocumentDescription from "../../hooks/useDocumentDescription";

const useStyles = makeStyles({
  header: {
    fontSize: "5rem",
    fontStyle: "italic",
    padding: "50px 0",
  },
  headerDiv: {
    color: "var(--mintGreen)",
    backgroundColor: "var(--beige)",
    textAlign: "center",
  },
  questionTopics: {
    textDecoration: "underline",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "1.7rem",
    marginTop: "10px",
  },
  divMarginBottom: { height: "var(--dividerHeight)" },
});

const FAQLayout = () => {
  const { general_questions, shipping, returns, payment_security_more } =
    FAQData;

  const classes = useStyles();
  
  useDocumentTitle("Do you have questions about products, accounts, or shipping? Read our FAQ on Purposer!");
  useDocumentDescription("We want to make shopping on Purposer easier and impactful for you! Learn about our brands, our team and how we can better serve you! Read our FAQ.");

  return (
    <PurposeCategoryMasterLayout>
      <div className={classes.headerDiv}>
        <Typography className={classes.header} variant="h1">FAQ</Typography>
      </div>
      <Container maxWidth="xl">
        <Typography
          className={classes.questionTopics}
          gutterBottom
          id="generalQuestions"
          variant="h2"
        >
          General Questions
        </Typography>
      </Container>
      <ExpandableQuestionsView questions={general_questions} />
      <Container maxWidth="xl">
        <Typography
          className={classes.questionTopics}
          gutterBottom
          id="shipping"
          variant="h2"
        >
          Shipping
        </Typography>
      </Container>
      <ExpandableQuestionsView questions={shipping} />
      <Container maxWidth="xl">
        <Typography
          className={classes.questionTopics}
          gutterBottom
          id="returns"
          variant="h2"
        >
          Returns, Cancellations, & Refunds
        </Typography>
      </Container>
      <ExpandableQuestionsView questions={returns} />
      <Container maxWidth="xl">
        <Typography
          className={classes.questionTopics}
          gutterBottom
          id="paymentSecurityEct"
          variant="h2"
        >
          Payment & Security
        </Typography>
      </Container>
      <ExpandableQuestionsView questions={payment_security_more} />
      <div className={classes.divMarginBottom} />
      <BubbleCategoryView />
      <div className={classes.divMarginBottom} />
      <ShopByCategoryView />
      <div className={classes.divMarginBottom} />
    </PurposeCategoryMasterLayout>
  );
};

export default FAQLayout;
