import { ButtonBase, Typography, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";
import clsx from "clsx";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useRef } from "react";

//Default Prop Values
import defaultData from "../json/homepage_carousel.json";

const responsive = {
  break1: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  break2: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  break3: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles({
  navButtons: {
    position: "absolute",
    bottom: "0%",
    width: "10%",
    // backgroundColor: "var(--coral)",
    backgroundColor: "#ED9C6E",
    height: "100%",
  },
  prevButton: {
    left: 0,
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
  },
  nextButton: {
    right: 0,
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  },
  outerDivContainer: {
    position: "relative",
    maxHeight: "inherit",
    height: "inherit",
  },
  imageCarouselDivContainer: {
    height: "76%",
  },
  textCarouselDivContainer: {
    display: "flex",
    position: "relative",
    marginTop: 12,
    maxHeight: "20%",
  },
  arrows: {
    color: "white",
    fontSize: 60,
  },
  image: {
    width: "100%",
    height: "100%",
    maxHeight: "inherit",
    margin: "auto",
    objectFit: "cover",
    borderRadius: 15,
  },
  carouselContainer: {
    borderRadius: 15,
    maxHeight: "100%",
    width: "100%",
  },
  textCarouselContainer: {
    width: "82%",
    margin: "auto",
    backgroundColor: "#ED9C6E",
    maxHeight: "100%",
    height: "100%",
  },
});

const styles = {
  iconImgs: {
    maxWidth: "100%",
    height: 50,
  },
  boxText: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  divText: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bottom: "5%",
    height: 85,
    backgroundColor: " var(--mintGreen)",
    width: "75%",
    borderRadius: 8,
  },
  typography: {
    color: "white",
    fontSize: "1.15rem"
  },
  iconCarouselView: {
    maxHeight: 56,
    maxWidth: "90%",
  },
  iconGridItem: {
    minHeight: 56,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textGridItem: {
    minHeight: 56,
  },
  textDivContainer: {
    width: "95%",
    margin: "auto",
    color: "#FFFFFF",
  },
};

const CarouselView = ({ data = defaultData, icon = false }) => {
  const classes = useStyles();
  let carouselRef = useRef(null);

  const CustomButtonGroupAsArrows = ({ next, previous }) => {
    return (
      <>
        <ButtonBase
          className={clsx(classes.navButtons, classes.prevButton)}
          onClick={() => {
            carouselRef.previous();
            previous();
          }}
        >
          <ArrowBack className={classes.arrows} />
          {/* <img
            src={
              "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Utility/Second_White_Arrow_Left.png"
            }
            alt="prevButton"
            style={styles.iconImgs}
          />*/}
        </ButtonBase>
        <ButtonBase
          className={clsx(classes.navButtons, classes.nextButton)}
          onClick={() => {
            carouselRef.next();
            next();
          }}
        >
          <ArrowForward className={classes.arrows} />
          {/*<img
            src={
              "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Utility/Second_White_Arrow_Right.png"
            }
            alt="nextButton"
            style={styles.iconImgs}
          />*/}
        </ButtonBase>
      </>
    );
  };

  return (
    <div className={classes.outerDivContainer}>
      <div className={classes.imageCarouselDivContainer}>
        <Carousel
          responsive={responsive}
          infinite={false}
          showDots={false}
          arrows={false}
          containerClass={classes.carouselContainer}
          slidesToSlide={1}
          ref={(ref) => (carouselRef = ref)}
          swipeable={false}
          draggable={false}
        >
          {data.map((item, i) => {
            return (
              <a href={item.link}>
                <img
                  draggable={false}
                  src={item.image}
                  alt={item.header}
                  className={classes.image}
                  key={i}
                />
              </a>
            );
          })}
        </Carousel>
      </div>
      <div className={classes.textCarouselDivContainer}>
        <Carousel
          responsive={responsive}
          infinite={false}
          showDots={false}
          arrows={false}
          renderButtonGroupOutside
          containerClass={classes.textCarouselContainer}
          slidesToSlide={1}
          customButtonGroup={<CustomButtonGroupAsArrows />}
          swipeable={false}
          draggable={false}
        >
          {data.map((item, i) => {
            return (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                key={i}
                columns={100}
                style={{ height: "100%", paddingTop: 5, paddingBottom: 5 }}
              >
                {/* {icon && (
                  <Grid item xs={18} align="center" sx={styles.iconGridItem}>
                    <img
                      src={item.logo}
                      style={styles.iconCarouselView}
                      alt={item.header}
                    />
                  </Grid>
                )} */}
                <Grid item xs={icon ? 82 : 100} sx={styles.textGridItem}>
                  <div style={styles.textDivContainer}>
                    <a href={item.link}>
                      <Typography
                        align="center"
                        sx={styles.typography}
                        className={"roboto-medium"}
                        variant="h2"
                      >
                        {item.header}
                      </Typography>
                    </a>
                    <a href={item.link}>
                      <Typography
                        align="center"
                        variant="h2"
                        sx={{
                          color: "white",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          fontSize: "1.15rem"
                        }}
                        className={"roboto-light-italic"}
                      >
                        {item.description}
                      </Typography>
                    </a>
                  </div>
                </Grid>
              </Grid>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default CarouselView;
