
import { getSessionJwt } from "./utilities";
const {
  NODE_API_ENDPOINT
} = require("./constants");



async function getFavorites(callback) {
  const jwtToken = await getSessionJwt();

  fetch(NODE_API_ENDPOINT + "/get-favorites", {
    method: "GET",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    }
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function insertFavorite(productID, callback) {
  const jwtToken = await getSessionJwt();

  const reqBody = {
    productID: productID
  }
  fetch(NODE_API_ENDPOINT + "/insert-favorite", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return null;
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}


async function deleteFavorite(productID, callback) {
  const jwtToken = await getSessionJwt();

  const reqBody = {
    productID: productID
  }
  fetch(NODE_API_ENDPOINT + "/delete-favorite", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return null;
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

export { getFavorites, insertFavorite, deleteFavorite }