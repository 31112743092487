import { Container, Grid, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { styled } from "@mui/material/styles";

//Default Prop Values
import marketingAndSalesDataDefault from "../json/marketing_and_sales.json";

const useStyles = makeStyles({
  backgroundDiv: {
    paddingTop: 20,
  },
  sectionHeader: {
    marginBottom: 10,
    flexGrow: 1,
  },
  image: {
    maxWidth: "65%",
    borderRadius: 15,
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  gridItem: {
    textAlign: "center",
    display: "flex",
    flexFlow: "column",
  },
  button: {
    backgroundColor: "#000000",
    color: "#FFFFFF",
    width: 190,
    height: 40,
    borderRadius: 10,
    marginTop: 40,
    marginBottom: 40,
    "&:hover": {
      backgroundColor: "#000000",
    },
  },
});

const Subheader = styled(Typography)({
  marginBottom: 40,
});

function MarketingAndSales(props) {
  const classes = useStyles();

  const { marketingAndSalesData = marketingAndSalesDataDefault } = props;

  return (
    <div className={classes.backgroundDiv}>
      <Container maxWidth="xl" align="center">
        <Typography
          align="center"
          variant="h3"
          className={"roboto-medium-italic"}
        >
          Marketing &amp; Sales
        </Typography>
        <Subheader
          align="center"
          variant="h6"
          className={"roboto-light-italic"}
        >
          {marketingAndSalesData.subheader}
        </Subheader>
        <Grid container direction="row" rowSpacing={2}>
          {marketingAndSalesData.sections &&
            marketingAndSalesData.sections.map((data, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={index}
                  className={classes.gridItem}
                >
                  <Typography
                    align="center"
                    variant="h6"
                    className={clsx(
                      classes.sectionHeader,
                      "roboto-light-italic"
                    )}
                  >
                    <a href={data.link} className={classes.link}>
                      {data.header}
                    </a>
                  </Typography>
                  <a href={data.link} className={classes.link}>
                    <img src={data.img} alt="" className={classes.image} />
                  </a>
                </Grid>
              );
            })}
        </Grid>
        <Button
          className={classes.button}
          onClick={() => {
            window.scroll({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <Typography className={"roboto-medium"}>BACK TO TOP</Typography>
        </Button>
      </Container>
    </div>
  );
}

export default MarketingAndSales;
