import { Avatar, Box, ButtonBase, Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";

const useStyles = makeStyles({
  imageAvatar: {
    width: "100%",
    height: "100%",
    maxWidth: "214px",
    maxHeight: "214px"
  },
  container: {
    width: "80%",
    margin: "auto",
    marginTop: 20,
  },
  text: {
    textAlign: "center",
    fontSize: 25,
    marginTop: 10,
  },
  mobileText: {
    textAlign: "center",
    fontSize: 18,
    marginTop: 10,
  },
  title: {
    fontWeight: 600,
  },
  navButtons: {
    position: "absolute",
    top: "39%",
    height: 75,
    border: "1px solid var(--mintArrow)",
    borderRadius: 15,
    backgroundColor: "#FFFFFF",
  },
  mobileNavButtons: {
    position: "absolute",
    top: "39%",
    height: 50,
    width: 25,
    border: "1px solid var(--mintArrow)",
    borderRadius: 15,
    backgroundColor: "#FFFFFF",
  },
  prevButton: {
    left: -33,
  },
  nextButton: {
    right: -33,
  },
  divContainer: {
    position: "relative",
    width: "90%",
    margin: "auto",
    padding: "15px 0",
  },
  arrows: {
    color: "var(--mintArrow)",
    fontSize: 30,
  },
  carouselContainer: {
    width: "100%",
  },
});

const responsive = {
  break1: {
    breakpoint: { max: 4000, min: 1297 },
    items: 5,
  },
  break2: {
    breakpoint: { max: 1297, min: 900 },
    items: 4,
  },
  break3: {
    breakpoint: { max: 900, min: 0 },
    items: 3,
  },
};

const BubbleCategoryView = ({ data }) => {
  const classes = useStyles();
  const mobile = useMobileMediaQuery();

  const CustomButtonGroupAsArrows = ({ next, previous }) => {
    return (

      <>
        {mobile &&
          <>
            <ButtonBase
              className={clsx(
                mobile ? classes.mobileNavButtons : classes.navButtons,
                classes.prevButton
              )}
              onClick={previous}
            >
              <ArrowBack className={classes.arrows} />
            </ButtonBase>
            <ButtonBase
              className={clsx(
                mobile ? classes.mobileNavButtons : classes.navButtons,
                classes.nextButton
              )}
              onClick={next}
            >
              <ArrowForward className={classes.arrows} />
            </ButtonBase>
          </>
        }
      </>
    );
  };

  const Bubble = ({ item }) => (
    <Box>
      <a href={"/shop/categories/" + item.categoryIds?.join('+') + "/" + encodeURI(item.title)}>
        <div className={classes.container}>
          <Avatar
            className={classes.imageAvatar}
            src={item.img}
            alt={item.title}
          />
        </div>
        <Typography
          className={clsx(
            mobile ? classes.mobileText : classes.text,
            "roboto-medium"
          )}
        >
          {item.title}
        </Typography>
      </a>
    </Box>
  );

  const BubbleCarousel = ({ data }) => (
    <div className={classes.divContainer}>
      <Carousel
        responsive={responsive}
        infinite={false}
        arrows={false}
        renderButtonGroupOutside={true}
        customButtonGroup={<CustomButtonGroupAsArrows />}
        containerClass={classes.carouselContainer}
      >
        {data.map((item, i) => {
          return <Bubble key={i} item={item} />;
        })}
      </Carousel>
    </div>
  );

  return (
    <Container maxWidth="xl">
      <Typography
        className={"roboto-medium"}
        sx={{ fontSize: [mobile ? 25 : 37] }}
      >
        Looking for something specific?
      </Typography>
      <Typography
        className={"roboto-medium"}
        sx={{ fontSize: [mobile ? 25 : 37] }}
      >
        We've got you covered.
      </Typography>
      {BUBBLE_CATEGORY_DATA && BUBBLE_CATEGORY_DATA.length > 0 && (
        <>
          <BubbleCarousel data={BUBBLE_CATEGORY_DATA.slice(0, 5)} />
          <BubbleCarousel data={BUBBLE_CATEGORY_DATA.slice(5, 10)} />
        </>
      )}
    </Container>
  );
};

export default BubbleCategoryView;

const BUBBLE_CATEGORY_DATA = [
  {
    title: "Candles",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/common/specific-section/candles.png",
    categoryIds: [67],
  },
  {
    title: "Coffee",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/common/specific-section/Coffee.png",
    categoryIds: [93],
  },
  {
    title: "Teddy Bears",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/common/specific-section/Teddy+Bears.png",
    categoryIds: [77, 75],
  },
  {
    title: "Nuts and Granola",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/common/specific-section/Nuts+%26+Granola.png",
    categoryIds: [99],
  },
  {
    title: "Jewelry and Accessories",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/common/specific-section/Jewelry+%26+Accessories.png",
    categoryIds: [3],
  },
  {
    title: "Self Care Gifts",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/common/specific-section/Self+Care+Gifts.png",
    categoryIds: [67, 72, 35, 32, 43],
  },
  {
    title: "Sunscreen",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/common/specific-section/Sunscreen_new.png",
    categoryIds: [42],
  },
  {
    title: "Organic Soap Bars",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/common/specific-section/Organic+Soap+Bars.png",
    categoryIds: [35],
  },
  {
    title: "Home and Centerpieces",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/common/specific-section/Home+%26+Centerpieces.png",
    categoryIds: [64, 73, 109, 57, 65, 110],
  },
  {
    title: "Prints and Stickers",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/common/specific-section/stickers.png",
    categoryIds: [65, 110],
  },
];
