import { Container, Typography, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SupportInfoView from "./SupportInfoView";
import addingNewProductsData from "../json/adding_new_products_data.json";

const useStyles = makeStyles({
  backgroundDiv: {
    backgroundColor: "inherit",
    paddingTop: 60,
    paddingBottom: 60,
  },
  grid: {
    marginTop: 65,
  },
  leftGridItem: {
    paddingRight: 50,
  },
  centerGridItem: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  rightGridItem: {
    paddingLeft: 50,
  },
});

function SupportInfo(props) {
  const classes = useStyles();

  let {
    header = "Adding New Products",
    supportInfoData = addingNewProductsData,
    boldTitles = false,
  } = props;

  const numItems = supportInfoData.length;

  return (
    <div className={classes.backgroundDiv}>
      <Container maxWidth="xl">
        <Typography align="center" variant="h3" className={"roboto-regular"}>
          {header}
        </Typography>
        <Grid
          container
          direction="row"
          alignItems="stretch"
          rowSpacing={4}
          className={classes.grid}
        >
          {supportInfoData &&
            supportInfoData.map((infoSection, index) => {
              return (
                <Grid item xs={12} sm={6} lg={12 / numItems} key={index}>
                  <SupportInfoView
                    infoSection={infoSection}
                    boldTitles={boldTitles}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </div>
  );
}

export default SupportInfo;
