import { getSessionJwt, getJsonCache, setJsonCache } from "./utilities";
const {
    NODE_API_ENDPOINT
  } = require("./constants");

async function getAllSellersApprovals(callback) {
    const jwtToken = await getSessionJwt();

    fetch(NODE_API_ENDPOINT + "/get-all-sellers-approvals", {
        method: "GET",
        headers: {
            "Authorization": 'Bearer ' + jwtToken,
        },
    })
        .then((response) => {
            if (response.status === 200) return response.json();
            return "failed";
        })
        .then(
            (data) => callback(data),
            (error) => console.log(error)
        );
}

async function updateSellerApprovalStatus(sellerID, approvalStatus, callback) {
    const jwtToken = await getSessionJwt();

    const reqBody = {
        sellerID: sellerID,
        approvalStatus: approvalStatus,
    }

    fetch(NODE_API_ENDPOINT + "/update-seller-approval-status", {
        method: "POST",
        headers: {
            "Authorization": 'Bearer ' + jwtToken,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
    })
        .then((response) => {
            if (response.status === 200) return response.json();
            return "failed";
        })
        .then(
            (data) => callback(data),
            (error) => console.log(error)
        );
}

async function getPressReleases(callback) {
    const jwtToken = await getSessionJwt();

    fetch(NODE_API_ENDPOINT + "/get-press-releases", {
        method: "GET",
        headers: {
            "Authorization": 'Bearer ' + jwtToken,
        },
    })
        .then((response) => {
            if (response.status === 200) return response.json();
            return "failed";
        })
        .then(
            (data) => callback(data),
            (error) => console.log(error)
        );
}

async function deletePressRelease(pressID, callback) {
    const jwtToken = await getSessionJwt();

    const reqBody = {
        pressID: pressID
    }

    fetch(NODE_API_ENDPOINT + "/delete-press-release", {
        method: "POST",
        headers: {
            "Authorization": 'Bearer ' + jwtToken,  
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
    })
        .then((response) => {
            if (response.status === 200) return response.json();
            return "failed";
        })
        .then(
            (data) => callback(data),
            (error) => console.log(error)
        );
}

async function updatePressRelease(pressID, image, logo, title, description, link, press_date, callback) {
    const jwtToken = await getSessionJwt();

    const reqBody = {
        pressID: pressID,
        image: image,
        logo: logo,
        title: title,
        description: description,
        link: link,
        press_date: press_date
    }

    fetch(NODE_API_ENDPOINT + "/update-press-release", {
        method: "POST",
        headers: {
            "Authorization": 'Bearer ' + jwtToken,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
    })
        .then((response) => {
            if (response.status === 200) return response.json();
            return "failed";
        })
        .then(
            (data) => callback(data),
            (error) => console.log(error)
        );
}

async function createPressRelease(image, logo, title, description, link, press_date, callback) {
    const jwtToken = await getSessionJwt();

    const reqBody = {
        image: image,
        logo: logo,
        title: title,
        description: description,
        link: link,
        press_date: press_date
    }

    fetch(NODE_API_ENDPOINT + "/create-press-release", {
        method: "POST",
        headers: {
            "Authorization": 'Bearer ' + jwtToken,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
    })
        .then((response) => {
            if (response.status === 200) return response.json();
            return "failed";
        })
        .then(
            (data) => callback(data),
            (error) => console.log(error)
        );
}

async function getCurrentCarousel(callback) {
    const jwtToken = await getSessionJwt();

    fetch(NODE_API_ENDPOINT + "/get-current-carousel", {
        method: "GET",
        headers: {
            "Authorization": 'Bearer ' + jwtToken,
        },
    })
        .then((response) => {
            if (response.status === 200) return response.json();
            return "failed";
        })
        .then(
            (data) => callback(data),
            (error) => console.log(error)
        );
}

async function deleteCarouselItem(itemId, callback) {
    const jwtToken = await getSessionJwt();

    const reqBody = {
        itemId: itemId
    }

    fetch(NODE_API_ENDPOINT + "/delete-carousel-item", {
        method: "POST",
        headers: {
            "Authorization": 'Bearer ' + jwtToken,  
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
    })
        .then((response) => {
            if (response.status === 200) return response.json();
            return "failed";
        })
        .then(
            (data) => callback(data),
            (error) => console.log(error)
        );
}

async function updateCarouselItem(itemId, image, header, description, link, callback) {
    const jwtToken = await getSessionJwt();

    const reqBody = {
        itemId: itemId,
        image: image,
        header: header,
        description: description,
        link: link
    }

    fetch(NODE_API_ENDPOINT + "/update-carousel-item", {
        method: "POST",
        headers: {
            "Authorization": 'Bearer ' + jwtToken,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
    })
        .then((response) => {
            if (response.status === 200) return response.json();
            return "failed";
        })
        .then(
            (data) => callback(data),
            (error) => console.log(error)
        );
}

async function createCarouselItem(image, header, description, link, callback) {
    const jwtToken = await getSessionJwt();

    const reqBody = {
        image: image,
        header: header,
        description: description,
        link: link
    }

    fetch(NODE_API_ENDPOINT + "/create-carousel-item", {
        method: "POST",
        headers: {
            "Authorization": 'Bearer ' + jwtToken,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
    })
        .then((response) => {
            if (response.status === 200) return response.json();
            return "failed";
        })
        .then(
            (data) => callback(data),
            (error) => console.log(error)
        );
}

export {
    getAllSellersApprovals, updateSellerApprovalStatus, getCurrentCarousel, updateCarouselItem, deleteCarouselItem, createCarouselItem, getPressReleases, createPressRelease, updatePressRelease, deletePressRelease

};