import { Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRef } from "react";
import useElementWidth from "../hooks/useElementWidth";
import { PRODUCT_COLORS } from "../util/constants";

const multiColorImg = 'url(https://d2eudfgh8kpk8j.cloudfront.net/common/multicolor_50_x_50.png)';

function ColorCircle(props) {
  const { color = "", selected = false, sizePercentage='100%', onClick = () => {} } = props;
  const circleRef = useRef(null);
  const circleWidth = useElementWidth(circleRef, false);
  const ColoredCircle = styled(Avatar)({
    borderStyle: "solid",
    borderWidth: 2,
    maxWidth: sizePercentage,
    "&:hover": {
      cursor: "pointer",
    },
  });
  return (
    <ColoredCircle
      ref={circleRef}
      onClick={() => {
        onClick(color);
      }}
      style={{
        backgroundImage: color === 'multi' ? multiColorImg : undefined,
        backgroundColor: color === 'multi' ? undefined : color,

        borderColor: selected ? "var(--coral)" : color == "white" ? "var(--black)" : "var(--colorCircleOutline)",
        height: circleWidth,
      }}
      title={PRODUCT_COLORS.find(c => c.color == color)?.name ?? color}
    >
      {color === 'N/A' ? 'N/A' : ' '}
    </ColoredCircle>
  );
}

export default ColorCircle;
