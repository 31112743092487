import {
  ButtonBase,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Button,
  Checkbox,
  FormGroup,
  Box
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import { useState, useEffect } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import countriesList from "../json/countries_list.json";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";
import { STRIPE_MCC_CODES } from "../util/constants";
import { checkDuplicateStoreNames } from "../util/sellers";


const useStyles = makeStyles({
  descriptionText: {
    fontSize: "0.9rem",
  },
  //Need help with this
  passwordInputOutline: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 5,
      "&:hover fieldset": {
        borderColor: "var(--mintGreen)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--mintGreen)",
      },
    },
  },
  //------
  inputOutline: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 5,
      "&:hover fieldset": {
        borderColor: "var(--mintGreen)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--mintGreen)",
      },
    },
  },
  sectionHeader: {
    color: "var(--mintGreenSolid)",
    fontSize: "1.4rem",
    marginBottom: 12,
    fontWeight: "bold"
  },
  buttons: {
    border: "1px solid lightGray",
    borderRadius: 5,
    padding: "2px 10px",
  },
  removeButtons: {
    border: "1px solid red",
    borderRadius: 5,
    padding: "11px 10px",
    color: "red",
  },
  factsInput: { width: "75%", marginBottom: 10 },
});

//Josh component
const InputRow = (props) => {
  const { label, subHeader, description, classes } = props;

  return (
    <Grid container item direction="row" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography>
          <b>{label}</b>
        </Typography>
      </Grid>
      {subHeader &&
      <Grid item xs={12}>
        <Typography className={classes.descriptionText}>
          {subHeader}
        </Typography>
      </Grid>
      }
      <Grid item xs={12}>
        {props.children}
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.descriptionText}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};



const style = {
  removeButton: {
    py: 1,
    px: 2,
    color: "red",
    border: "solid 1px red",
    borderRadius: 1,
  },
};


const PurposesEditDialog = (props) => {
  const { dialogData, setDialogData, productPurposes, productSubPurposes } = props;

  const handleAutoChanges = (value, index, section) => {
    let newDialogData = { ...dialogData }
    newDialogData[section][index] = value;
    setDialogData(newDialogData);
  };

  const handleRemoveClick = (index, section) => {
    let newDialogData = { ...dialogData }
    newDialogData[section].splice(index, 1);

    setDialogData(newDialogData);
  };

  const handleAddClickBlank = (section, object = {}) => {
    let newDialogData = { ...dialogData }
    newDialogData[section].push(object);
    setDialogData(newDialogData);
  };

  const ContentRow = ({ title, children }) => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ mb: 1, mt: 2 }}>
            <b>{title}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      {dialogData.mainPurposes.length === 0 ?
        <Button variant="contained" sx={{ backgroundColor: "var(--mintGreenSolid)", marginTop: "16px" }} key={"addmore"} onClick={() => handleAddClickBlank("mainPurposes", {purposeName: ""})}>
          Add Another Purpose
        </Button>
        :
        <>
        {dialogData.mainPurposes.map((value, i) => {
          return (
            <ContentRow key={"ContentRow" + i} title={`Item Purpose #${i + 1}`}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Autocomplete
                  key={"purpose" + i}
                  size="small"
                  //filter options to not include purposes already in a users mainPurposes
                  options={productPurposes.filter((option) => {
                    return !dialogData.mainPurposes.map((purpose) => purpose.productPurposeID).includes(option.productPurposeID)
                  })}
                  onChange={(e, value) => {
                    // console.log(value)
                    handleAutoChanges(value, i, "mainPurposes");
                    handleRemoveClick(i, "subPurposes")
                  }}
                  defaultValue={dialogData.mainPurposes && dialogData.mainPurposes[i] ? dialogData.mainPurposes[i] : {}}
                  getOptionLabel={(option) => option.purposeName || ""}
                  disableClearable
                  sx={{ width: i === 0 ? "100%" : "50%" }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <div>
                  {i >= 1 && (
                    <ButtonBase
                      key={"remove" + i}
                      sx={style.removeButton}
                      onClick={() => {
                        handleRemoveClick(i, "mainPurposes")
                        handleRemoveClick(i, "subPurposes")
                      }}
                    >
                      Remove
                    </ButtonBase>
                  )}
                </div>
              </Box>
              <Typography sx={{ mt: 1, ml: 5 }}>
                {`Subpurposes #${i + 1}`}
                <Autocomplete
                  key={"subpurpose" + i}
                  multiple
                  value={dialogData.subPurposes && dialogData.subPurposes[i] ? dialogData.subPurposes[i] : []}
                  options={productSubPurposes.filter(c => c.higherPurposeID == dialogData.mainPurposes[i].productPurposeID)}
                  getOptionLabel={(option) => option.purposeName || ""}
                  defaultValue={dialogData.subPurposes && dialogData.subPurposes[i] ? dialogData.subPurposes[i] : []}
                  filterSelectedOptions
                  onChange={(e, values) => {
                    handleAutoChanges(values, i, "subPurposes");
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                  size="small"
                  sx={{ width: "80%" }}
                />
              </Typography>
              <div style={{ float: "left" }}>
                {dialogData.mainPurposes.length - 1 === i && (
                  <Button variant="contained" sx={{ backgroundColor: "var(--mintGreenSolid)", marginTop: "16px" }} key={"addmore" + i} onClick={() => handleAddClickBlank("mainPurposes", {purposeName: ""})}>
                    Add Another Purpose
                  </Button>
                )}
              </div>
            </ContentRow>
          );
        })
      }
      </>
        }

        
    </div>
  );
};

const AboutYourShop = (props) => {
  const { formValues, handleFormValues, productPurposes, formPurposes, setFormPurposes } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [storeNameChanged, setStoreNameChanged] = useState(false);
  const mobile = useMobileMediaQuery();

  const classes = useStyles();

  const handleDuplicateCheck = (storeName) => {
    if (storeNameChanged && storeName.length > 0) {
      checkDuplicateStoreNames(storeName, (data) => {
        if (data)
          handleFormValues("duplicateStoreName", true)

        else
          handleFormValues("duplicateStoreName", false)

        setStoreNameChanged(false);
      })
    }
  };

  return (
    <>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Typography className={classes.sectionHeader}>
          Store Information
        </Typography>
          {/* <FormControl component="fieldset">
            <RadioGroup
              row={!mobile}
              aria-label="position"
              name="position"
              onChange={(e) => handleFormValues("position", e.target.value)}
              value={formValues.position}
            >
              <FormControlLabel
                value="Founder"
                control={
                  <Radio
                    style={{
                      color: "var(--mintGreen)",
                      backgroundColor: "transparent",
                    }}
                  />
                }
                label="Founder"
              />
              <FormControlLabel
                value="CEO"
                control={
                  <Radio
                    style={{
                      color: "var(--mintGreen)",
                      backgroundColor: "transparent",
                    }}
                  />
                }
                label="CEO"
              />
              <FormControlLabel
                value="Owner"
                control={
                  <Radio
                    style={{
                      color: "var(--mintGreen)",
                      backgroundColor: "transparent",
                    }}
                  />
                }
                label="Owner"
              />
              <FormControlLabel
                value="Marketing Manager"
                control={
                  <Radio
                    style={{
                      color: "var(--mintGreen)",
                      backgroundColor: "transparent",
                    }}
                  />
                }
                label="Marketing Manager"
              />
              <FormControlLabel
                value="Other"
                control={
                  <Radio
                    style={{
                      color: "var(--mintGreen)",
                      backgroundColor: "transparent",
                    }}
                  />
                }
                label="Other"
              />
            </RadioGroup>
          </FormControl> */}
        <InputRow label="Store Name" subHeader="This is your Store Name (or DBA) that is visible to shoppers when they browse for products and view your seller store. This is not your legal name. Your legal name will be entered at a later step (disclaimer: you can have the same store name and legal name if you want)" classes={classes} description="">
          <TextField
            name="storeName"
            onChange={(e) => {
              handleFormValues("storeName", e.target.value)
              setStoreNameChanged(true)
            }}
            defaultValue={formValues.storeName}
            onBlur={(e) => handleDuplicateCheck(e.target.value)}
            variant="outlined"
            size="small"
            error={formValues.duplicateStoreName}
            helperText={formValues.duplicateStoreName ? "Store Name is already is use" : ""}
            className={classes.inputOutline}
            fullWidth
            inputProps={{ minLength: 5, maxLength: 30 }}
          />
        </InputRow>
        <InputRow
          label="Merchant Code"
          classes={classes}
          description="Choose what merchant code your store best fits."
        >
          <Autocomplete
            options={STRIPE_MCC_CODES}
            getOptionLabel={(option) => option.name}
            value={formValues?.businessMCC || { name: '' }}
            filterSelectedOptions
            onChange={(e, values) => {
              handleFormValues("businessMCC", values)
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            size="small"
            className={classes.inputOutline}
            fullWidth
          />
        </InputRow>
        <InputRow label="Founded Date" classes={classes} description="">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              key={'datePicker'}
              label="Select a date"
              disableFuture
              value={formValues.foundedDate}
              onChange={(value => {
                const formattedDate = `${new String(value.$M + 1).padStart(2, '0')}/${new String(value.$D).padStart(2, '0')}/${new String(value.$y).padStart(4, '0')}`;
                handleFormValues("foundedDate", formattedDate);
              })}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </InputRow>
        <InputRow
          label="Purposes"
          subHeader="Please be sure to choose all the “sub purposes” that apply to your brand. This is how shoppers will find you. People want to know about the good work you are doing!"
          classes={classes}
          description="Choose all that apply. Shoppers will find you based off this!"
        >
          <PurposesEditDialog dialogData={formPurposes ? formPurposes : {}} setDialogData={setFormPurposes} productPurposes={productPurposes?.filter(p => p.higherPurposeID == -1)} productSubPurposes={productPurposes?.filter(p => p.higherPurposeID != -1)} />
        </InputRow>
        <InputRow
          label="Countries Impacted"
          classes={classes}
          description="If your brand is not impacting a specific country, please select the country that you’re based out of. ie) United States."
        >
          <Autocomplete
            multiple
            options={countriesList}
            getOptionLabel={(option) => option.label}
            value={formValues.countriesImpacted ? formValues.countriesImpacted : []}
            filterSelectedOptions
            onChange={(e, values) =>
              handleFormValues("countriesImpacted", values)
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            size="small"
            className={classes.inputOutline}
            fullWidth
          />
        </InputRow>
        <InputRow
          label="Short Bio"
          classes={classes}
          description="Max 1,500 characters (with spaces)"
        >
          <TextField
            multiline
            // rows={8}
            onChange={(e) => handleFormValues("shortBio", e.target.value)}
            defaultValue={formValues.shortBio}
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 1500 }}
            className={classes.inputOutline}
          />
        </InputRow>
      </Grid>
    </>
  );
};

export default AboutYourShop;
