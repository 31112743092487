import ManagementMasterLayout from "../MasterLayouts/ManagementMasterLayout";
import NotificationTable from "../../components/NotificationTable";
import OrderNumberTable from "../../components/OrderNumberTable";
import SellerStatsChart from "../../components/SellerStatsChart";
import {
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  Avatar,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useElementWidth from "../../hooks/useElementWidth";
import { Edit } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import sellerDashboardJsonData from "../../json/seller_dashboard.json";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles({
  horizontalCenter: {
    display: "flex",
    justifyContent: "center",
  },
  verticallyCenter: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    textTransform: "none",
    borderRadius: 13,
    marginRight: 15,
    minHeight: 34,
  },

  rightSectionWidth: {
    paddingLeft: 65,
    paddingRight: 65,
  },
  bubbleStatContainer: {
    paddingTop: 40,
    paddingBottom: 35,
  },
  bubbleStatFilter: {
    marginBottom: 30,
    fontSize: "1.25rem",
    "& .MuiInput-underline:after": {
      border: "2px solid #000000",
    },
  },
  bubbleAvatar: {
    width: "75%",
    backgroundColor: "#F0F6F4",
    color: "#000000",
  },
  todoBackground: {
    backgroundColor: "var(--beige)",
    paddingTop: 5,
    paddingBottom: 15,
  },
  todoHeader: {
    marginLeft: 10,
    fontSize: "1.4rem",
  },
  todoDivider: {
    backgroundColor: "#000000",
    width: "35%",
    marginTop: 2,
    marginBottom: 15,
  },
  todoLogo: {
    color: "#FFFFFF",
    marginRight: 20,
  },
  rightLowerContainer: {
    paddingTop: 40,
  },
  tableSpacing: {
    marginTop: 16,
    marginBottom: 36,
  },
  bottomButtonGroup: {
    marginTop: 200,
    marginBottom: 200,
  },
});

const Bubble = (props) => {
  const { statBubbleData, avatarRef, avatarWidth, classes } = props;

  // Used to adjust text size to make sure it doesn't overflow if it is
  // too long by default
  const nameRatio = 8;
  const statRatio = 5;

  return (
    <Avatar
      className={classes.bubbleAvatar}
      ref={avatarRef}
      style={{ height: avatarWidth }}
    >
      <div>
        <Typography
          align="center"
          style={{ fontSize: avatarWidth / nameRatio }}
        >
          <i>{statBubbleData.name}</i>
        </Typography>
        <Typography
          align="center"
          style={{ fontSize: avatarWidth / statRatio }}
        >
          <b>{statBubbleData.stat}</b>
        </Typography>
      </div>
    </Avatar>
  );
};

const ToDos = (props) => {
  const { todoData, classes } = props;
  return (
    <div className={clsx(classes.todoBackground, classes.rightSectionWidth)}>
      <div className={classes.verticallyCenter}>
        <img
          src="https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/logos/bulb.png"
          alt=""
        />
        <Typography display="inline" className={classes.todoHeader}>
          <b>
            <i>Shop To Do's</i>
          </b>
        </Typography>
      </div>
      <Divider className={classes.todoDivider} />
      <Grid container direction="column" spacing={1}>
        {todoData.map((todo, index) => {
          return (
            <Grid item key={index} className={classes.verticallyCenter}>
              <Edit className={classes.todoLogo} />
              <Typography display="inline">{todo}</Typography>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

function SellerDashboardLayout(props) {
  const classes = useStyles();
  const sellerDashboardData = sellerDashboardJsonData;
  const bubbleRef = React.useRef(null);
  const [bubbleStatFilter, setBubbleStatFiler] = React.useState("Last 7 Days");
  const bubbleWidth = useElementWidth(bubbleRef);
  const id = useParams();

  const handleBubbleStatFilterChange = (event) => {
    setBubbleStatFiler(event.target.value);
  };

  return (
    <ManagementMasterLayout
      title={"Shop Manager"}
      sellerName={sellerDashboardData.name}
      sellerPicture={sellerDashboardData.pic}
    >
      <div
        className={clsx(classes.bubbleStatContainer, classes.rightSectionWidth)}
      >
        <Select
          value={bubbleStatFilter}
          onChange={handleBubbleStatFilterChange}
          className={classes.bubbleStatFilter}
        >
          <MenuItem value={"Last 7 Days"}>
            <b>Last 7 Days</b>
          </MenuItem>
          <MenuItem value={"Last Month"}>
            <b>Last Month</b>
          </MenuItem>
          <MenuItem value={"Last Year"}>
            <b>Last Year</b>
          </MenuItem>
        </Select>
        <Grid container direction="row" rowSpacing={4}>
          {sellerDashboardData.bubbleStats &&
            sellerDashboardData.bubbleStats.map((bubbleStat, index) => {
              return (
                <Grid
                  item
                  xs={12} sm={4} md={4} lg={4}
                  key={index}
                  //align={
                  //  index === 0 ? "left" : index === 1 ? "center" : "right"
                  //}
                >
                  <Bubble
                    statBubbleData={bubbleStat}
                    avatarRef={bubbleRef}
                    avatarWidth={bubbleWidth}
                    classes={classes}
                  />
                </Grid>
              );
            })}
        </Grid>
      </div>
      <ToDos todoData={sellerDashboardData.todos} classes={classes} />
      <div
        className={clsx(classes.rightSectionWidth, classes.rightLowerContainer)}
      >
        <div className={classes.tableSpacing}>
        <NotificationTable
          notificationData={sellerDashboardData.notifications}
        /> 
        </div>
        <div className={classes.tableSpacing}>
          <OrderNumberTable purchasesData={sellerDashboardData.orders} />
        </div>
        <div className={classes.tableSpacing}>
          <SellerStatsChart />
        </div>
      </div>
    </ManagementMasterLayout>
  );
}

export default SellerDashboardLayout;
