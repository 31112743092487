import { Typography, Grid, IconButton, Button } from "@mui/material";
import ManagaementMasterLayout from "../MasterLayouts/ManagementMasterLayout";
import { styled } from "@mui/material/styles";
import { ExpandMore } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import React from "react";
import { getSellerById } from "../../util/sellers";
import { getListOfProductsById } from "../../util/getProductsById";
import ImageUploader from "../../components/ImageUploader";
import AddIcon from "@mui/icons-material/Add";

const ContentWrapper = styled("div")({
  paddingTop: 70,
  paddingLeft: 20,
  paddingBottom: 100,
});

const ButtonContainer = styled("div")({
  textAlign: "center",
});

const SaveButton = styled(Button)({
  marginTop: 25,
  color: "#000000",
  borderColor: "#000000",
  textTransform: "none",
  borderRadius: 10,
  paddingLeft: 60,
  paddingRight: 60,
  paddingTop: 0,
  paddingBottom: 0,
  "&:hover": {
    backgroundColor: "inherit",
    color: "#000000",
    borderColor: "#000000",
  },
});

const ImageGrid = styled(Grid)({
  marginTop: 15,
  marginBottom: 35,
});

const GridItemContainer = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const styles = {
  uploadContainer: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "var(--lightGray)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 230,
    borderRadius: 5,
    backgroundColor: "#f4f2ed",
    cursor: "pointer",
  },
  uploadImage: {
    width: "100%",
    height: "inherit",
    objectFit: "cover",
    margin: "auto",
    borderRadius: 15,
  },
};

function ShopGallery() {
  const { id } = useParams();
  const DEFAULT_ID = "22963625-1ae2-41d1-9041-97a8111c74a0";
  const [images, setImages] = React.useState({});

  React.useEffect(() => {
    getSellerById(id, (data) => {
      if (
        !data.hasOwnProperty("error") &&
        !data.hasOwnProperty("message") &&
        data.hasOwnProperty("legacyLayoutData")
      ) {
        getListOfProductsById(data.legacyLayoutData.products, (productData) => {
          let imgSet = new Set();
          for (let i = 0; i < data.banner_images.length; i++) {
            imgSet.add(data.banner_images[i]);
          }
          imgSet.add(data.profile_pic);

          if (
            !productData.hasOwnProperty("error") &&
            !productData.hasOwnProperty("message")
          ) {
            for (let j = 0; j < productData.length; j++) {
              imgSet.add(productData[j].productImageURL);
            }
          }

          let imageData = {};
          let index = 0;
          let values = imgSet.values();
          for (const val of values) {
            imageData[index] = val;
            index++;
          }

          setImages(imageData);
        });
      } else {
        // Fetch default seller
        getSellerById(DEFAULT_ID, (defaultData) => {
          let imgSet = new Set();
          for (let i = 0; i < defaultData.banner_images.length; i++) {
            imgSet.add(defaultData.banner_images[i]);
          }
          imgSet.add(defaultData.profile_pic);

          if (
            !defaultData.hasOwnProperty("error") &&
            !defaultData.hasOwnProperty("message") &&
            defaultData.hasOwnProperty("legacyLayoutData")
          ) {
            getListOfProductsById(
              defaultData.legacyLayoutData.products,
              (productData) => {
                if (
                  !productData.hasOwnProperty("error") &&
                  !productData.hasOwnProperty("message")
                ) {
                  for (let j = 0; j < productData.length; j++) {
                    imgSet.add(productData[j].productImageURL);
                  }
                }

                let imageData = {};
                let index = 0;
                let values = imgSet.values();
                for (const val of values) {
                  imageData[index] = val;
                  index++;
                }

                setImages(imageData);
              }
            );
          }
        });
      }
    });
  }, [id]);

  return (
    <ManagaementMasterLayout>
      <ContentWrapper>
        <Typography variant="h6" className={"roboto-medium"}>
          Upload Media
        </Typography>
        <Typography variant="h6" className={"roboto-light-italic"}>
          photos or videos to use as logo, banner photo, footer photo, listings,
          and more!
        </Typography>
        <Typography variant="h6" className={"roboto-light-italic"}>
          .jpeg, .png, svg
        </Typography>
        <ImageGrid container columnSpacing={4} rowSpacing={4}>
          <GridItemContainer item xs={12} sm={6} md={4}>
            <ImageUploader
              containerStyle={styles.uploadContainer}
              imgStyle={styles.uploadImage}
              uploadIcon={<AddIcon fontSize="medium" />}
              propertyName={Object.keys(images).length}
              setFormValues={setImages}
            />
          </GridItemContainer>
          {images &&
            Object.keys(images).length > 0 &&
            Object.keys(images).map((key, index) => {
              return (
                <GridItemContainer item key={index} xs={12} sm={6} md={4}>
                  <ImageUploader
                    containerStyle={{
                      borderStyle: "none",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    imgStyle={{ maxWidth: "100%", maxHeight: 230 }}
                    uploadIcon={<AddIcon fontSize="medium" />}
                    formValues={images}
                    propertyName={key}
                    setFormValues={setImages}
                  />
                </GridItemContainer>
              );
            })}
        </ImageGrid>
        <ButtonContainer>
          <div>
            <Typography
              variant="h6"
              display="inline"
              className={"roboto-light"}
            >
              More
            </Typography>
            <IconButton disableRipple>
              <ExpandMore fontSize="large" />
            </IconButton>
          </div>
          <SaveButton disableRipple variant="outlined">
            <Typography variant="h6" className="roboto-medium">
              Save
            </Typography>
          </SaveButton>
        </ButtonContainer>
      </ContentWrapper>
    </ManagaementMasterLayout>
  );
}

export default ShopGallery;
