import { useEffect, useRef, useState, forwardRef, createRef } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  TextField,
  Box,
  Button,
  ButtonBase,
  Typography,
  Grid,
  Checkbox,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Chip,
  checkboxClasses,
  OutlinedInput,
  InputAdornment,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel
} from "@mui/material";
import ColorCircle from "../../components/ColorCircle";
import FileUpload, {
  UPLOAD_ERROR,
  triggerUpload,
  onDropHandler,
  onDragOverHandler,
} from "../../components/FileUpload";
import { styled } from "@mui/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { getProductById } from "../../util/getProductsById";
import { getAllPurposes, getAllCategories } from "../../util/purposeCategories";
import ClearIcon from '@mui/icons-material/Clear';
import {
  PRODUCT_SIZES,
  PRODUCT_COLORS,
  PRODUCT_MATERIALS,
  SHIPPING_UPGRADE_TYPES,
  SHIPPING_SERVICES,
  SHIPPING_CHARGE_TYPES,
  STRIPE_TAX_CATEGORIES,
  PRODUCT_LENGTH_UNITS,
  PRODUCT_SIZE_TYPE,
  PRODUCT_TYPE,
  PRODUCT_WEIGHT_UNITS,
} from "../../util/constants";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  rectSortingStrategy,
  rectSwappingStrategy,
  horizontalListSortingStrategy
} from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MAX_FILE_SIZE, MAX_NUM_FILES, FILETYPES } from "../../util/constants"
import FileUploadV2 from "../../components/FileUploadV2";

const ContentRow = ({ title, children }) => {
  return (
    <Grid container style={{ marginBottom: "32px" }}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ color: "var(--coral)" }}>
          <b>{title}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

const SortablePhoto = forwardRef((props, imageRef) => {
  const { index, setImageError, handleFormValues, formValues, value, handleRemoveClick } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: index });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Grid key={index} item xs={12} sm={6} md={4} >
      <Box
        // Removing this so that the file upload does NOT pop up when the user tries to sort the images
        // onClick={() => {
        //   triggerUpload(imageRef);
        // }}
        // onDrop={(e) =>
        //   onDropHandler(
        //     e,
        //     setImageError,
        //     "productImageURL",
        //     1,
        //     FILETYPES,
        //     MAX_FILE_SIZE,
        //     handleFormValues,
        //     true,
        //     formValues.productImageURL
        //   )
        // }
        // onDragOver={onDragOverHandler}
        sx={{
          width: 125,
          height: 150,
          bgcolor: "var(--halfBeige)",
          p: 1,
          borderRadius: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          position: "relative"
        }}
      >
        {formValues.productImageURL ? (
          <>
            <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
              <img
                src={value}
                alt=""
                style={{
                  objectFit: "contain",
                  height: "100%",
                  width: "100%",
                }}

              />
            </div>
            <ClearIcon onClick={() => handleRemoveClick(index, "productImageURL")} style={{ position: "absolute", top: "0px", right: "0px", padding: "8px" }}></ClearIcon>
          </>
        ) : (
          <AddCircleOutlineIcon fontSize="medium" />
        )}
        <FileUpload
          setRef={(inputRef) => {
            imageRef = inputRef;
          }}
          setError={setImageError}
          propertyName={"productImageURL"}
          maxFiles={MAX_NUM_FILES}
          accept={"image/*"}
          filetypes={FILETYPES}
          maxFileSize={MAX_FILE_SIZE}
          updateState={handleFormValues}
          coverPhotos={formValues.productImageURL}
        />
      </Box>

    </Grid>
  );
});

const StyledCheckbox = styled(Checkbox)({
  color: "var(--mintGreenSolid)",
  [`&.${checkboxClasses.checked}`]: {
    color: "var(--mintGreenSolid)",
  },
});

const style = {
  removeButton: {
    py: 0.5,
    px: 2,
    color: "red",
    border: "solid 1px red",
    borderRadius: 1,
    mb: 1,
    mt: -3
  },
};

const ACTIVE_STATUSES = ["Active", "Inactive"];

const ListingLayout = (props) => {
  const { formValues, setFormValues, toast } = props
  const { id, listing } = useParams();

  const [imageError, setImageError] = useState(null);
  const [isRequesting, setIsRequesting] = useState(true);
  const [requestFailed, setRequestFailed] = useState(false);
  let imageRef = useRef(null);

  const [productPurposes, setProductPurposes] = useState([]);
  const [productSubPurposes, setProductSubPurposes] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [productSubCategories, setProductSubCategories] = useState([]);

  const [loadingPurposes, setLoadingPurposes] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );


  const HandleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setFormValues((preValues) => {
        // const oldIndex = preValues.productImageURL.indexOf(active.id);
        // const newIndex = preValues.productImageURL.indexOf(over.id);
        let tmp = arrayMove(preValues.productImageURL, active.id, over.id);
        return { ...preValues, "productImageURL": tmp };
      });
    }
  }

  //Get Product Purposes
  useEffect(() => {
    if (productPurposes.length < 1 && !loadingPurposes) {
      setLoadingPurposes(true);
      getAllPurposes((data) => {
        if (data) {
          const mainPurposes = data.filter(p => p.higherPurposeID == -1);
          const subPurposes = data.filter(p => p.higherPurposeID != -1);
          setProductPurposes(mainPurposes);
          setProductSubPurposes(subPurposes);
        } else {
          console.log("Couldn't get purposes!")
        }
      });
      setLoadingPurposes(false);
    }
  }, []);

  //Get Product Categories
  useEffect(() => {
    if (productCategories.length < 1 && !loadingCategories) {
      setLoadingCategories(true);
      getAllCategories((data) => {
        if (data) {
          const mainCategories = data.filter(c => c.higherCategoryID == -1);
          const mainCategories2 = mainCategories.filter(c => c.productCategoryID !== 0);
          const subCategories = data.filter(c => c.higherCategoryID != -1);
          setProductCategories(mainCategories2); // Remove Purposer Shop from mainCategories
          setProductSubCategories(subCategories);
        } else {
          console.log("Couldn't get categories!")
        }
      });
      setLoadingCategories(false);
    }
  }, []);

  const handleFormValues = (name, value) => {
    setFormValues((preValues) => {
      return { ...preValues, [name]: value };
    });
  };


  const handleAutoChanges = (value, index, section) => {
    const list = [...formValues[section]];
    list[index] = value;
    handleFormValues(section, list);
  };

  const handleObjectArrayChanges = (objectName, propertyName, index, value) => {
    const list = [...formValues[objectName]];
    list[index][propertyName] = value;
    handleFormValues(objectName, list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index, section) => {
    const list = [...formValues[section]];
    list.splice(index, 1);
    handleFormValues(section, list);
  };

  const handleAddClickBlank = (section, object = {}) => {
    const newSection = formValues[section] && formValues[section].length > 0 ? [
      ...formValues[section],
      object,
    ] : [object]
    handleFormValues(section, newSection);
  };

  function extractNumbers(str) {
    // Use a regular expression to match only digits and decimal points
    const regex = /[0-9.]+/g;
    const matches = str.match(regex);

    // Join the matched numbers and decimals into a new string
    const extracted = matches ? matches.join('') : '';

    return extracted;
  }

  function formatInputPrice(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (value == '') return '';
    if (!value) return '0';

    // clean the input for any non-digit values.
    const inputPrice = extractNumbers(value);
    const decimalsCount = (inputPrice.match(/\./g) || []).length;
    const decimalsSplit = inputPrice.split('.');
    const decimals = decimalsSplit?.[1];
    if (decimals && decimals.length > 2) {
      return `${inputPrice.slice(0, -1)}`;
    }
    if (decimalsCount > 1) {
      return `${inputPrice.slice(0, -1)}`;
    }
    if (Number(inputPrice) > 999999) {
      return '999999';
    }
    // ssnLength is used to know when to apply our formatting for the ssn
    const inputPriceLength = inputPrice.length;

    // we need to return the value with no formatting if its less then four digits
    if (inputPriceLength <= 8) return inputPrice;

    // if ssnLength is greater than 4 we cut it and return
    // the formatted number
    if (inputPriceLength > 8) {
      return `${inputPrice.slice(0, 8)}`;
    }
  }

  function formatInputPriceOnBlur(value) {
    if (value.startsWith(".")) {
        return "0" + value;
    }
    return value;
  }


  //Get Product Info (if already created)
  useEffect(() => {
    if (!listing) {
      console.log('New product!')
      setIsRequesting(false);
    } else if (isRequesting && listing) {
      getProductById(listing, (data) => {
        // console.log(data);
        if (data === "failed") {
          setRequestFailed(true);
          return;
        } else {
          const product = data[0];
          if (!Array.isArray(product.productImageURL)) {
            product.productImageURL = [product.productImageURL];
          }
          const mainPurposes = product.purposes.filter(p => p.higherPurposeID == -1);
          const subPurposes = [];
          const mainCategories = product.categories.filter(c => c.higherCategoryID == -1);
          const subCategories = [];
          mainPurposes.forEach(function (p) {
            const subPurposeArray = [...product.purposes.filter(pu => pu.higherPurposeID == p.productPurposeID)];
            subPurposes.push(subPurposeArray)
          });
          mainCategories.forEach(function (c) {
            const subCategoryArray = [...product.categories.filter(ca => ca.higherCategoryID == c.productCategoryID)];
            subCategories.push(subCategoryArray)
          });

          product.itemVariations = product.options;
          product.itemPurpose = mainPurposes;
          product.itemSubPurpose = subPurposes;
          product.itemCategory = mainCategories;
          product.itemSubCategories = subCategories;
          product.taxCategory = product.stripeTaxCode ? STRIPE_TAX_CATEGORIES.find(t => t == product.stripeTaxCode) : null;
          setFormValues(product);
          setIsRequesting(false);
        }
      });
    }
    return () => { };
  }, [isRequesting, listing]);

  return (
    <>
      {!isRequesting && !loadingCategories && !loadingPurposes ? (
        <Container maxWidth={"xl"}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              alignItems: "center",
              my: 3,
            }}
          >
            {formValues.productID &&
              <ButtonBase
                href={"/seller/" + id + "/" + formValues.productID + "/clone"}
                sx={{
                  py: 1,
                  px: 3,
                  borderRadius: 2.5,
                  border: "1px solid black",
                  mr: 1,
                }}
              >
                <b>Clone Listing</b>
              </ButtonBase>
            }
            <ButtonBase
              href={"/seller/" + id + "/listings"}
              sx={{
                py: 1,
                px: 3,
                borderRadius: 2.5,
                border: "1px solid black",
                mr: 1,
              }}
            >
              <b>Back to All Listings</b>
            </ButtonBase>
          </Box>
          <ContentRow key={'activeStatus'} title={`Active Status`}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {formValues.activeStatusString !== undefined &&
                <Autocomplete
                  size="small"
                  options={ACTIVE_STATUSES}
                  value={ACTIVE_STATUSES.find((option) => option == formValues.activeStatusString)}
                  onChange={(e, value) => {
                    handleFormValues("activeStatusString", value);
                  }}
                  getOptionLabel={(option) => option}
                  disableClearable
                  sx={{ width: "25%" }}
                  renderInput={(params) => <TextField {...params} />}
                />
              }
            </Box>
            <br />
            <Typography>
              {`Tip: If you want to “save” your listing , you can choose “inactive” and click the “save for later” at the bottom of the page to save your work before publishing! You must have at least a product title, product variation and tax category filled out to save the product listing.`}
            </Typography>
          </ContentRow>
          {/* <Box>
            <EditProductView info={formValues} admin={true} />
          </Box> */}
          <ContentRow title={"Upload Media (photos/videos)"}>
            {/* <div style={{ display: "flex", flexDirection: "row", alignItems: "center", columnGap: "16px", margin: "16px 0px" }}>
              <Button variant="outlined" sx={{ color: "black", borderColor: "black" }}>
                Browse your computer
              </Button>
              <Typography>Or, drag and drop files. </Typography>
            </div>
            <Typography>Pick up to 10 photos. Min is 2,000 pixels. Max is 5,000 pixels. <a href="/support/seller" target="_blank" style={{ textDecoration: "underline" }}>Learn more here!</a></Typography>
            <br></br>
            <Typography>You can drag the pictures to rearrange the order! The first image will be the thumbnail.</Typography>
            <br></br>

            <Container maxWidth="sm">
              <Grid
                container
                justifyContent={"flex-start"}
                alignItems={"center"}
                rowSpacing={2}
                // columnSpacing={2}
              > */}
            <FileUploadV2
              formValueName={"productImageURL"}
              formValueArray={formValues.productImageURL}
              maxFiles={20}
              handleFormValues={handleFormValues}
              buttonText={'upload: jpg, png'}
            // aspectRatio={1000/750}
            // requireImageCrop={true}
            />

            {/* {formValues.productImageURL &&
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={HandleDragEnd}
                >
                  <SortableContext
                    items={formValues.productImageURL}
                    strategy={horizontalListSortingStrategy}
                  >
                    {formValues?.productImageURL?.map((value, index) => {
                      return (
                        <SortablePhoto
                          imageRef={imageRef}
                          index={index}
                          setImageError={setImageError}
                          handleFormValues={handleFormValues}
                          formValues={formValues}
                          value={value}
                          handleRemoveClick={handleRemoveClick}
                        />
                      );
                    })}
                  </SortableContext>
                </DndContext>}
                {(formValues.productImageURL === undefined || formValues?.productImageURL?.length < 6) && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Box
                      onClick={() => {
                        triggerUpload(imageRef);
                      }}
                      onDrop={(e) =>
                        onDropHandler(
                          e,
                          setImageError,
                          "productImageURL",
                          1,
                          FILETYPES,
                          MAX_FILE_SIZE,
                          handleFormValues,
                          true,
                          formValues.productImageURL
                        )
                      }
                      onDragOver={onDragOverHandler}
                      sx={{
                        width: 125,
                        height: 150,
                        bgcolor: "var(--halfBeige)",
                        p: 1,
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                      }}
                    >
                      <AddCircleOutlineIcon fontSize="medium" />
                      <FileUpload
                        setRef={(inputRef) => {
                          imageRef = inputRef;
                        }}
                        setError={setImageError}
                        propertyName={"productImageURL"}
                        maxFiles={MAX_NUM_FILES}
                        accept={"image/*"}
                        filetypes={FILETYPES}
                        maxFileSize={MAX_FILE_SIZE}
                        updateState={handleFormValues}
                        coverPhotos={formValues.productImageURL}
                      />
                    </Box>
                  </Grid>
                )}

              </Grid>
            </Container>
            {imageError && (
              <Typography style={{ color: "red", textAlign: "center" }}>
                {imageError.type === UPLOAD_ERROR.FILESIZE_TOO_LARGE
                  ? "ERROR: Max file size exceeded"
                  : "ERROR: Illegal file type"}
              </Typography>
            )} */}
          </ContentRow>

          <ContentRow title={"Item Title"}>
            <Typography>Use a clear and concise title with popular keywords.</Typography>
            <br />
            <Typography>
              <i>
                {`Great Title: Beautiful Rose Gold Infinity Necklace.
              14K Gold. Thin and Dainty. Pretty Layering Necklace. Minimalist Jewelry. 16”
              and 18”.`}
                <br />
                {`Weak Title: Rose Gold Necklace in 14K Gold.`}
              </i>
            </Typography>
            <TextField
              size="small"
              defaultValue={formValues.productName}
              value={formValues.productName}
              onChange={(e) => handleFormValues("productName", e.target.value)}
              fullWidth
              error={((formValues.productName.length < 20 && formValues.productName.length > 0) || (formValues.productName.length > 150 && formValues.productName.length > 0))}
              inputProps={{ maxLength: 150 }}
              helperText={`${formValues.productName.length} / 150 characters. Minimum 20 characters, maximum 150 characters.`}
            />
            <Divider style={{ marginTop: "32px" }} />
          </ContentRow>

          {formValues.itemCategory.map((value, i) => {
            if (value.productCategoryID == 0) {
              return <></>
            }
            return (
              <ContentRow key={i} title={`Item Category #${i + 1 - (formValues.itemCategory.find(ic => ic.productCategoryID == 0) ? 1 : 0)}`}>
                {i === 0 && <Typography>This is important. This is how shoppers will find your item!</Typography>}
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Autocomplete
                    key={"category" + i}
                    size="small"
                    //filter options to not include categories already in a users mainPurposes
                    options={productCategories.filter((option) => {
                      return !formValues.itemCategory.map((category) => category.productCategoryID).includes(option.productCategoryID)
                    })}
                    defaultValue={value}
                    onChange={(e, value) => {
                      handleAutoChanges(value, i, "itemCategory");
                      handleRemoveClick(i, "itemSubCategories")
                    }}
                    getOptionLabel={(option) => option.categoryName}
                    disableClearable
                    sx={{ width: i === 0 ? "100%" : "50%" }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <div>
                    {i >= (1 + (formValues.itemCategory.find(ic => ic.productCategoryID == 0) ? 1 : 0)) && (
                      <ButtonBase
                        sx={style.removeButton}
                        onClick={() => {
                          handleRemoveClick(i, "itemCategory")
                          handleRemoveClick(i, "itemSubCategories")
                        }}
                      >
                        Remove
                      </ButtonBase>
                    )}
                  </div>
                </Box>
                <Typography sx={{ mt: 1, ml: 5 }}>
                  {`Subcategories #${i + 1}`}
                  <Autocomplete
                    key={"subcategory" + i}
                    multiple
                    value={formValues.itemSubCategories && formValues.itemSubCategories[i] ? formValues.itemSubCategories[i] : []}
                    options={productSubCategories.length > 0 && formValues?.itemCategory[i]?.productCategoryID != null ? productSubCategories.filter(c => c.higherCategoryID == formValues.itemCategory[i].productCategoryID) : []}
                    getOptionLabel={(option) => option.categoryName}
                    defaultValue={formValues.itemSubCategories && formValues.itemSubCategories[i] ? formValues.itemSubCategories[i] : []}
                    filterSelectedOptions
                    onChange={(e, values) => {
                      handleAutoChanges(values, i, "itemSubCategories");
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                    size="small"
                    sx={{ width: "80%" }}
                  />
                </Typography>
              </ContentRow>
            );
          })}
          <div style={{ float: "left" }}>
            <Button variant="contained" sx={{ backgroundColor: "var(--mintGreenSolid)", marginTop: "16px" }} onClick={() => handleAddClickBlank("itemCategory", { categoryName: "" })}>
              {/* <Typography > */}
              Add Another Category
              {/* </Typography> */}
            </Button>
          </div>
          <Divider style={{ marginBottom: "32px" }} />
          {formValues.itemPurpose.map((value, i) => {
            return (
              <ContentRow key={i} title={`Item Purpose #${i + 1}`}>
                <Typography>The first sub-purpose that you choose will appear on the product listings thumbnail. All of your sub-purposes will show under the main listing page.</Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Autocomplete
                    key={"purpose" + i}
                    size="small"
                    //filter options to not include purposes already in a users mainPurposes
                    options={productPurposes.filter((option) => {
                      return !formValues.itemPurpose.map((purpose) => purpose.productPurposeID).includes(option.productPurposeID)
                    })}
                    defaultValue={value}
                    onChange={(e, value) => {
                      handleAutoChanges(value, i, "itemPurpose");
                      handleRemoveClick(i, "itemSubPurpose")
                    }}
                    getOptionLabel={(option) => option.purposeName}
                    disableClearable
                    sx={{ width: i === 0 ? "100%" : "50%" }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <div>
                    {formValues.itemPurpose.length >= 1 && i >= 1 && (
                      <ButtonBase
                        sx={style.removeButton}
                        onClick={() => {
                          handleRemoveClick(i, "itemPurpose")
                          handleRemoveClick(i, "itemSubPurpose")
                        }}
                      >
                        Remove
                      </ButtonBase>
                    )}
                  </div>
                </Box>
                <Typography sx={{ mt: 1, ml: 5 }}>
                  {`Subpurposes #${i + 1}`}
                  <Autocomplete
                    key={"subpurpose" + i}
                    multiple
                    value={formValues.itemSubPurpose && formValues.itemSubPurpose[i] ? formValues.itemSubPurpose[i] : []}
                    options={productSubPurposes.length > 0 && formValues?.itemPurpose[i]?.productPurposeID != null ? productSubPurposes.filter(c => c.higherPurposeID == formValues.itemPurpose[i].productPurposeID) : []}
                    getOptionLabel={(option) => option.purposeName}
                    defaultValue={formValues.itemSubPurpose && formValues.itemSubPurpose[i] ? formValues.itemSubPurpose[i] : []}
                    filterSelectedOptions
                    onChange={(e, values) => {
                      handleAutoChanges(values, i, "itemSubPurpose");
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                    size="small"
                    sx={{ width: "80%" }}
                  />
                </Typography>
              </ContentRow>
            );
          })}
          <div style={{ float: "left" }}>
            <Button variant="contained" sx={{ backgroundColor: "var(--mintGreenSolid)", marginTop: "16px" }} onClick={() => handleAddClickBlank("itemPurpose", { purposeName: "" })}>
              {/* <Typography color={"gray"} variant={"body2"}> */}
              Add Another Purpose
              {/* </Typography> */}
            </Button>
          </div>
          <Divider style={{ marginBottom: "32px" }} />
          <ContentRow title={"Item Description:"}>
            <Typography>Make sure your description is informative and brief. Our buyers want to read about how the product is made, what materials are used and why it's special! Tip: You can split text into paragraphs by pressing the space bar!</Typography>
            <Grid container justifyContent="flex-end"><Button style={{ color: "black" }} onClick={() => handleFormValues("details", formValues.details.concat('\n•'))}><FormatListBulletedIcon /> Add Bullet points</Button></Grid>
            <TextField
              multiline
              fullWidth
              // rows={6}
              value={formValues.details}
              onChange={(e) => handleFormValues("details", e.target.value)}
              error={formValues.details?.length < 450 && formValues.details?.length > 0}
              inputProps={{ maxLength: 4000 }}
              helperText={`${formValues.details?.length} / 4,000 characters. Minimum 450 characters, maximum 4,000 characters.`}
            />
          </ContentRow>
          <ContentRow title={"This Product's Purpose"}>
            <Typography>Please write a strong 1-3 sentence statement explaining how this specific product makes positive impact on the world. This is what will convince buyers to make a purchase. Show off your impactful work!
              <div style={{ height: "8px" }} /><i>i.e. This  sustainable blanket made from recycled materials, is produced in the United States, and with each purchase, we donate one blanket to a homeless shelter. We have  give away over 8,500 blankets since 2015.</i></Typography>
            <TextField
              multiline
              fullWidth
              // rows={6}
              defaultValue={formValues.purposeDesc}
              onChange={(e) => handleFormValues("purposeDesc", e.target.value)}
              error={formValues.purposeDesc?.length < 80 && formValues.purposeDesc?.length > 0}
              inputProps={{ maxLength: 280 }}
              helperText={`${formValues.purposeDesc?.length} / 280 characters. Minimum 80 characters, maximum 280 characters.`}
            />
          </ContentRow>
          <ContentRow title={"Product Tags, a.k.a. keywords"}>
            <Typography>These are “keywords” aka search terms / related search terms / popular phrases that your buyers will type into the search bar when looking for your product. Use your best judgement when choosing these words and phrases. Choose popular keywords! These are very important because they determine how often your product listing will appear in searches. Choose as many as you can, up to 50 keywords!
              <div style={{ height: "8px" }} /><i>i.e. If you are selling a “mug” then the keywords would include “mug” “insulated mug” “ cute mug” “high quality mug” “coffee mug” “ personalized mug” “ tumbler cups” “starbucks mugs” “reusable coffee mug” “coffee cup” “travel mug” “Christmas mug” “ ceramic mug” “thermal mug” “tea mug.”</i>
            </Typography>
            <Typography>
              <div style={{ height: "8px" }} /><b>TIP: You must PRESS ENTER after typing each keyword in order for the keyword to save.</b>
            </Typography>
            <Autocomplete
              multiple
              // size="small"
              helperText={`${formValues.tags?.length} / 50 keywords`}
              options={[]}
              freeSolo
              defaultValue={formValues.tags}
              onChange={(e, value) => {
                handleFormValues("tags", value);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={index}
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="Type and hit the enter key to add" />
              )}
            />
            {/* helper text */}
            <Typography variant={"body2"} color={"gray"}>
              {`${formValues.tags?.length ?? 0} / 50 keywords. Minimum 10 keywords, maximum 50 keywords.`}
            </Typography>
          </ContentRow>
          <ContentRow title={"Type of Product"}>
            <RadioGroup
              row={true}
              // value={paymentMethod}
              defaultValue={formValues.typeOfProduct}
              onChange={(event) => {
                handleFormValues("typeOfProduct", event.target.value);
              }}
            >
              <FormControlLabel
                value={PRODUCT_TYPE.PHYSICAL}
                control={
                  <Radio
                    style={{
                      color: "var(--mintGreenSolid)",
                      backgroundColor: "transparent",
                    }}
                  />
                }
                label="Physical"
              />
              <FormControlLabel
                value={PRODUCT_TYPE.DIGITAL}
                control={
                  <Radio
                    style={{
                      color: "var(--mintGreenSolid)",
                      backgroundColor: "transparent",
                    }}
                  />
                }
                label="Digital"
              />
              <FormControlLabel
                value={PRODUCT_TYPE.SERVICE}
                control={
                  <Radio
                    style={{
                      color: "var(--mintGreenSolid)",
                      backgroundColor: "transparent",
                    }}
                  />
                }
                label="Service"
              />
            </RadioGroup>
          </ContentRow>
          <Divider style={{ marginBottom: "32px" }} />
          <ContentRow title={"Variations"}>
            <Typography>
                <i>
                  {`Once you create a variation, you cannot delete the variation. If you no longer want a specific variation, please deactivate the variation. If you want to edit a version, please deactivate the undesired variation and create a new one.`}
                </i>
              <div style={{ height: "8px" }}/>
            </Typography>
            {formValues.itemVariations.map((variation, i) => {
              return (
                <div key={'mainContentRow' + i}>
                  <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    <Typography variant="h5" style={{ color: "var(--mintGreenSolid)", fontWeight: "bold" }}>{`Variation #${i + 1}`}</Typography>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      {variation.skuID ?
                        <>
                          <Checkbox
                            sx={{
                              color: "red", '&.Mui-checked': {
                                color: "red",
                              },
                            }}
                            key={"active" + i}
                            checked={variation.active === false}
                            onChange={e => {
                              handleObjectArrayChanges('itemVariations', 'active', i, !e.target.checked);
                            }}
                          />
                          <Typography style={{ color: "red" }}>Deactivate Variation #{i + 1}</Typography>
                        </>
                        :
                        <ButtonBase
                          key={"removeVar" + i}
                          sx={style.removeButton}
                          onClick={() => {
                            handleRemoveClick(i, "itemVariations")
                          }}
                        >
                          Remove Variation #{i + 1}
                        </ButtonBase>
                      }
                    </div>
                  </div>
                  {variation.active !== false &&
                    <>
                      <ContentRow title={"Color"}>
                        <Grid
                          container
                          columns={PRODUCT_COLORS.length}
                          rowSpacing={1}
                          columnSpacing={1}
                        >
                          {PRODUCT_COLORS.map((color, index) => {
                            // console.log(color)
                            return (
                              <Grid item xs key={index} title={color.name}>
                                <ColorCircle
                                  key={"colorVars" + index}
                                  color={color.color}
                                  selected={variation.color == color.name || ""}
                                  onClick={(e) => {
                                    //They can't change the color after the variation has been created!
                                    if (variation.skuID) return;
                                    const selectedColorName = PRODUCT_COLORS.find(c => c.color == e)?.name;
                                    handleObjectArrayChanges('itemVariations', 'color', i, selectedColorName);
                                  }}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </ContentRow>
                      <ContentRow title={"Size/Style"}>
                        <Typography>Choose one type of size/style option per variation.</Typography>
                        {/* <Autocomplete
                          key={"sizeVars" + i}
                          size="small"
                          options={PRODUCT_SIZES}
                          value={variation.size || ""}
                          defaultValue={variation.size || ""}
                          onChange={(e, value) => {
                            handleObjectArrayChanges('itemVariations', 'size', i, value);
                          }}
                          getOptionLabel={(option) => option}
                          fullWidth
                          renderInput={(params) => <TextField {...params} />}
                        /> */}
                        <FormControl disabled={variation.skuID}>
                          <RadioGroup
                            aria-labelledby="sizing"
                            name="sizing"
                            value={variation.sizeType || null}
                            onChange={(e, value) => {
                              handleObjectArrayChanges('itemVariations', 'size', i, '');
                              handleObjectArrayChanges('itemVariations', 'size1', i, '');
                              handleObjectArrayChanges('itemVariations', 'size2', i, '');
                              handleObjectArrayChanges('itemVariations', 'size3', i, '');
                              handleObjectArrayChanges('itemVariations', 'sizeUnit', i, '');
                              handleObjectArrayChanges('itemVariations', 'sizeType', i, value);
                            }}
                          >
                            <FormControlLabel value={PRODUCT_SIZE_TYPE.STANDARD} label="Standard sizing" control={<Radio style={{ color: "var(--mintGreenSolid)", backgroundColor: "transparent", }} />} />
                            {variation.sizeType && variation.sizeType === PRODUCT_SIZE_TYPE.STANDARD &&
                              <Autocomplete
                                key={"sizeVars" + i}
                                size="small"
                                sx={{ width: "100%" }}
                                options={PRODUCT_SIZES}
                                disabled={variation.skuID}
                                value={variation.size || ""}
                                defaultValue={variation.size || ""}
                                onChange={(e, value) => {
                                  handleObjectArrayChanges('itemVariations', 'size', i, value);
                                }}
                                getOptionLabel={(option) => option}
                                // fullWidth
                                renderInput={(params) => <TextField {...params} />}
                              />
                            }
                            <FormControlLabel value={PRODUCT_SIZE_TYPE.DIMENSIONS_L_W_H} label={PRODUCT_SIZE_TYPE.DIMENSIONS_L_W_H} control={<Radio style={{ color: "var(--mintGreenSolid)", backgroundColor: "transparent", }} />} />
                            {variation.sizeType && variation.sizeType === PRODUCT_SIZE_TYPE.DIMENSIONS_L_W_H &&
                              <div style={{ display: "flex", flexDirection: "row", columnGap: 10, alignItems: "center" }}>
                                <TextField
                                  size="small"
                                  disabled={variation.skuID}
                                  value={variation.size1 || ""}
                                  defaultValue={variation.size1 || ""}
                                  onChange={(e) => {
                                    const newAmt = formatInputPrice(e.target.value);
                                    handleObjectArrayChanges('itemVariations', 'size1', i, newAmt);
                                  }}
                                  onBlur={(e) => {
                                    const newAmt = formatInputPriceOnBlur(e.target.value);
                                    handleObjectArrayChanges('itemVariations', 'size1', i, newAmt);
                                }}
                                  
                                >
                                </TextField>
                                <Typography variant="h5">X</Typography>
                                <TextField
                                  size="small"
                                  disabled={variation.skuID}
                                  value={variation.size2 || ""}
                                  defaultValue={variation.size2 || ""}
                                  onChange={(e) => {
                                    const newAmt = formatInputPrice(e.target.value);
                                    handleObjectArrayChanges('itemVariations', 'size2', i, newAmt);
                                  }}
                                  onBlur={(e) => {
                                    const newAmt = formatInputPriceOnBlur(e.target.value);
                                    handleObjectArrayChanges('itemVariations', 'size2', i, newAmt);
                                }}
                                >
                                </TextField>
                                <Typography variant="h5">X</Typography>
                                <TextField
                                  size="small"
                                  disabled={variation.skuID}
                                  value={variation.size3 || ""}
                                  defaultValue={variation.size3 || ""}
                                  onChange={(e) => {
                                    const newAmt = formatInputPrice(e.target.value);
                                    handleObjectArrayChanges('itemVariations', 'size3', i, newAmt);
                                  }}
                                  onBlur={(e) => {
                                    const newAmt = formatInputPriceOnBlur(e.target.value);
                                    handleObjectArrayChanges('itemVariations', 'size3', i, newAmt);
                                }}
                                >
                                </TextField>
                                <Autocomplete
                                  key={"dimensionsUnit" + i}
                                  size="small"
                                  options={PRODUCT_LENGTH_UNITS}
                                  disableClearable
                                  disabled={variation.skuID}
                                  value={variation.sizeUnit || ""}
                                  defaultValue={variation.sizeUnit || ""}
                                  onChange={(e, value) => {
                                    handleObjectArrayChanges('itemVariations', 'sizeUnit', i, value);
                                  }}
                                  sx={{ width: "50%" }}
                                  getOptionLabel={(option) => option}
                                  // fullWidth
                                  renderInput={(params) => <TextField {...params} label="Units" />}
                                />
                              </div>
                            }
                            <FormControlLabel value={PRODUCT_SIZE_TYPE.DIMENSIONS_L_W} label={PRODUCT_SIZE_TYPE.DIMENSIONS_L_W} control={<Radio style={{ color: "var(--mintGreenSolid)", backgroundColor: "transparent", }} />} />
                            {variation.sizeType && variation.sizeType === PRODUCT_SIZE_TYPE.DIMENSIONS_L_W &&
                              <div style={{ display: "flex", flexDirection: "row", columnGap: 10, alignItems: "center" }}>
                                <TextField
                                  size="small"
                                  disabled={variation.skuID}
                                  value={variation.size1 || ""}
                                  defaultValue={variation.size1 || ""}
                                  onChange={(e) => {
                                    const newAmt = formatInputPrice(e.target.value);
                                    handleObjectArrayChanges('itemVariations', 'size1', i, newAmt);
                                  }}
                                  onBlur={(e) => {
                                    const newAmt = formatInputPriceOnBlur(e.target.value);
                                    handleObjectArrayChanges('itemVariations', 'size1', i, newAmt);
                                }}
                                >
                                </TextField>
                                <Typography variant="h5">X</Typography>
                                <TextField
                                  size="small"
                                  disabled={variation.skuID}
                                  value={variation.size2 || ""}
                                  defaultValue={variation.size2 || ""}
                                  onChange={(e) => {
                                    const newAmt = formatInputPrice(e.target.value);
                                    handleObjectArrayChanges('itemVariations', 'size2', i, newAmt);
                                  }}
                                  onBlur={(e) => {
                                    const newAmt = formatInputPriceOnBlur(e.target.value);
                                    handleObjectArrayChanges('itemVariations', 'size2', i, newAmt);
                                }}
                                >
                                </TextField>
                                <Autocomplete
                                  key={"dimensionsUnit" + i}
                                  size="small"
                                  options={PRODUCT_LENGTH_UNITS}
                                  disableClearable
                                  disabled={variation.skuID}
                                  value={variation.sizeUnit || ""}
                                  defaultValue={variation.sizeUnit || ""}
                                  onChange={(e, value) => {
                                    handleObjectArrayChanges('itemVariations', 'sizeUnit', i, value);
                                  }}
                                  sx={{ width: "50%" }}
                                  getOptionLabel={(option) => option}
                                  // fullWidth
                                  renderInput={(params) => <TextField {...params} label="Units" />}
                                />
                              </div>
                            }
                            <FormControlLabel value={PRODUCT_SIZE_TYPE.LENGTH_OR_WIDTH} label="Length or width" control={<Radio style={{ color: "var(--mintGreenSolid)", backgroundColor: "transparent", }} />} />
                            {variation.sizeType && variation.sizeType === PRODUCT_SIZE_TYPE.LENGTH_OR_WIDTH &&
                              <div style={{ display: "flex", flexDirection: "row", columnGap: 10, alignItems: "center" }}>
                                <TextField
                                  size="small"
                                  disabled={variation.skuID}
                                  value={variation.size || ""}
                                  defaultValue={variation.size || ""}
                                  onChange={(e) => {
                                    const newAmt = formatInputPrice(e.target.value);
                                    handleObjectArrayChanges('itemVariations', 'size', i, newAmt);
                                  }}
                                  onBlur={(e) => {
                                    const newAmt = formatInputPriceOnBlur(e.target.value);
                                    handleObjectArrayChanges('itemVariations', 'size', i, newAmt);
                                }}
                                >
                                </TextField>
                                <Autocomplete
                                  key={"dimensionsUnit" + i}
                                  size="small"
                                  options={PRODUCT_LENGTH_UNITS}
                                  disableClearable
                                  disabled={variation.skuID}
                                  value={variation.sizeUnit || ""}
                                  defaultValue={variation.sizeUnit || ""}
                                  onChange={(e, value) => {
                                    handleObjectArrayChanges('itemVariations', 'sizeUnit', i, value);
                                  }}
                                  sx={{ width: "80%" }}
                                  getOptionLabel={(option) => option}
                                  // fullWidth
                                  renderInput={(params) => <TextField {...params} label="Units" />}
                                />
                              </div>
                            }
                            <FormControlLabel value={PRODUCT_SIZE_TYPE.WEIGHT} label={PRODUCT_SIZE_TYPE.WEIGHT} control={<Radio style={{ color: "var(--mintGreenSolid)", backgroundColor: "transparent", }} />} />
                            {variation.sizeType && variation.sizeType === PRODUCT_SIZE_TYPE.WEIGHT &&
                              <div style={{ display: "flex", flexDirection: "row", columnGap: 10, alignItems: "center" }}>
                                <TextField
                                  size="small"
                                  disabled={variation.skuID}
                                  value={variation.size1 || ""}
                                  defaultValue={variation.size1 || ""}
                                  onChange={(e) => {
                                    const newAmt = formatInputPrice(e.target.value);
                                    handleObjectArrayChanges('itemVariations', 'size1', i, newAmt);
                                  }}
                                  onBlur={(e) => {
                                    const newAmt = formatInputPriceOnBlur(e.target.value);
                                    handleObjectArrayChanges('itemVariations', 'size1', i, newAmt);
                                }}
                                >
                                </TextField>
                                <Typography variant="h5">lb</Typography>
                                <TextField
                                  size="small"
                                  disabled={variation.skuID}
                                  value={variation.size2 || ""}
                                  defaultValue={variation.size2 || ""}
                                  onChange={(e) => {
                                    const newAmt = formatInputPrice(e.target.value);
                                    handleObjectArrayChanges('itemVariations', 'size2', i, newAmt);
                                  }}
                                  onBlur={(e) => {
                                    const newAmt = formatInputPriceOnBlur(e.target.value);
                                    handleObjectArrayChanges('itemVariations', 'size2', i, newAmt);
                                }}
                                >
                                </TextField>
                                <Typography variant="h5">oz</Typography>
                              </div>
                            }
                            <FormControlLabel value={PRODUCT_SIZE_TYPE.SET} label="Set of items" control={<Radio style={{ color: "var(--mintGreenSolid)", backgroundColor: "transparent", }} />} />
                            {variation.sizeType && variation.sizeType === PRODUCT_SIZE_TYPE.SET &&
                              <TextField
                                size="small"
                                disabled={variation.skuID}
                                value={variation.size || ""}
                                defaultValue={variation.size || ""}
                                onChange={(e) => {
                                  handleObjectArrayChanges('itemVariations', 'size', i, parseInt(e.target.value));
                                }}
                                label="Set # of items"
                              >
                              </TextField>
                            }
                            <FormControlLabel value={PRODUCT_SIZE_TYPE.CUSTOM} label="Custom" control={<Radio style={{ color: "var(--mintGreenSolid)", backgroundColor: "transparent", }} />} />
                            {variation.sizeType && variation.sizeType === PRODUCT_SIZE_TYPE.CUSTOM &&
                              <TextField
                                size="small"
                                disabled={variation.skuID}
                                value={variation.size || ""}
                                defaultValue={variation.size || ""}
                                onChange={(e) => {
                                  handleObjectArrayChanges('itemVariations', 'size', i, e.target.value);
                                }}
                                label="Custom size/style"
                              >
                              </TextField>
                            }
                          </RadioGroup>
                        </FormControl>
                      </ContentRow>
                      <ContentRow title={"Product SKU"}>
                        <Typography>This section is optional.</Typography>
                        <OutlinedInput
                          key={"customSkuVars" + i}
                          size="small"
                          sx={{ width: "40%" }}
                          value={variation.customSku != null ? variation.customSku : ""}
                          defaultValue={variation.customSku != null ? variation.customSku : ""}
                          onChange={(e) => {
                            const newCustomSku = e.target.value
                            handleObjectArrayChanges('itemVariations', 'customSku', i, newCustomSku);
                          }}
                          // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        />
                      </ContentRow>
                      <ContentRow title={"Price"}>
                        <Typography>This is the price for Variation #{i + 1}. You can factor shipping costs into this price if you plan on offering “free shipping” to customers. You decide! Either way, make sure your pricing is fair and ensures all your costs are covered. Remember, our customers are willing to pay MORE for purpose-driven brands, so don’t be afraid to price your products appropriately. Know your worth! We value you and so do our customers.</Typography>
                        <OutlinedInput
                          key={"priceVars" + i}
                          size="small"
                          sx={{ width: "40%" }}
                          inputProps={{ min: "0", max: "1000000" }}
                          value={variation.price != null ? variation.price : ""}
                          defaultValue={variation.price != null ? variation.price : ""}
                          onChange={(e) => {
                            const newPrice = formatInputPrice(e.target.value);
                            handleObjectArrayChanges('itemVariations', 'price', i, newPrice);
                          }}
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        />
                      </ContentRow>
                      <ContentRow title={"Inventory"}>
                        <TextField
                          key={"invVars" + i}
                          size="small"
                          type={"number"}
                          disabled={variation.skuID != null}
                          sx={{ width: "40%" }}
                          inputProps={{ min: "0", max: "1000000" }}
                          value={variation.inventory ?? ""}
                          defaultValue={variation.inventory ?? ""}
                          onChange={(e) => {
                            handleObjectArrayChanges('itemVariations', 'inventory', i, parseInt(e.target.value));
                          }}
                        />
                      </ContentRow>
                    </>
                  }
                </div>
              )
            })
            }
            <Button variant="contained" sx={{ backgroundColor: "var(--mintGreenSolid)" }} onClick={() => handleAddClickBlank("itemVariations")}>
              Add another variation
            </Button>
          </ContentRow>

          <ContentRow title={"Tax Category"}>
            <Autocomplete
              options={STRIPE_TAX_CATEGORIES}
              getOptionLabel={(option) => option.categoryName}
              disabled={formValues.productID}
              value={formValues?.taxCategory || { categoryName: '' }}
              filterSelectedOptions
              onChange={(e, values) => {
                handleFormValues("taxCategory", values)
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              size="small"
              fullWidth
            />
          </ContentRow>
          <Divider style={{ marginBottom: "32px" }} />
          <ContentRow title={"Customization"}>
            <Typography>You can offer customization for your products. If you select YES, you will type instructions for the buyer. The buyer will see the instructions AND they will be given an empty box to type their “requests”.
              <div style={{ height: "8px" }} /><i>i.e.  Buyer Instructions: Enter the name you want on the necklace. It can be up to 20 characters, spaces are okay, no special characters.</i></Typography>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <StyledCheckbox
                key={"customizableBox"}
                checked={formValues.customizable || false}
                onChange={e => {
                  handleFormValues(
                    "customizable",
                    e.target.checked
                  );
                  if (!e.target.checked) {
                    handleFormValues(
                      "customInstructions",
                      ""
                    );
                  }
                }}
              />
              <Typography>YES, I want to offer customizations.</Typography>
            </div>
          </ContentRow>
          {formValues.customizable &&
            <>
              <ContentRow title={"Instructions for Customizing"}>
                <TextField
                  key={"customInstructions"}
                  size="small"
                  // sx={{ width: "40%" }}
                  value={formValues.customInstructions || ""}
                  defaultValue={formValues.customInstructions || ""}
                  onChange={(e) => {
                    handleFormValues(
                      "customInstructions",
                      e.target.value
                    );
                  }}
                  fullWidth
                  error={formValues.customInstructions?.length < 10 && formValues.customInstructions?.length > 0}
                  inputProps={{ maxLength: 280 }}
                  helperText={`${formValues.customInstructions?.length ?? 0} / 280 characters. Minimum 10 characters, maximum 280 characters.`}
                />
                {/* {formValues.customInstructions && <Typography variant="caption">{formValues.customInstructions.length} / 280 characters maximum.</Typography>} */}
              </ContentRow>

            </>
          }
          <Divider style={{ marginBottom: "32px" }} />
          <ContentRow title={"Standard Shipping Costs"}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ bgcolor: "var(--mintGreen)" }}>
                  <TableRow>
                    <TableCell>
                      <b>Shipping Charge Type</b>
                    </TableCell>
                    <TableCell>
                      <b>Shipping Service</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>One Item</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Cost per each additional item</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <FormControl
                        variant="outlined"
                        size="small"
                        sx={{
                          minWidth: 150,
                          float: "left",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "7px",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "var(--mintGreen)",
                              color: "var(--mintGreen)",
                            },
                          },
                        }}
                      >
                        <InputLabel htmlFor="outlined-age-native-simple">Charge Type</InputLabel>
                        <Select
                          onChange={(e) => {
                            handleFormValues("shippingChargeType", e.target.value);
                            if (e.target.value == 'Free Shipping') {
                              handleFormValues("shippingOneItemPrice", 0);
                              handleFormValues("shippingAddlItemPrice", 0);
                            }
                          }}
                          label="Shipping Charge Type"
                          inputProps={{
                            name: "shippingChargeType",
                            id: "outlined-age-native-simple",
                          }}
                          defaultValue={formValues?.shippingOneItemPrice <= 0 ? SHIPPING_CHARGE_TYPES[1] : SHIPPING_CHARGE_TYPES[0]}
                          value={formValues?.shippingChargeType}
                        >
                          {SHIPPING_CHARGE_TYPES.map((upgradeType, i) => {
                            return <MenuItem value={upgradeType}>{upgradeType}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Autocomplete
                        key={"changeShippingService"}
                        size="small"
                        fullWidth
                        options={SHIPPING_SERVICES}
                        onChange={(e, value) => {
                          handleFormValues("shippingService", value);
                        }}
                        disableClearable
                        defaultValue={formValues?.shippingService}
                        value={formValues?.shippingService}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Shipping Service" />}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <OutlinedInput
                        key={'shippingOneItemPrice'}
                        size="small"
                        inputProps={{ min: "0", max: "1000000" }}
                        defaultValue={formValues.shippingOneItemPrice}
                        value={formValues.shippingOneItemPrice}
                        disabled={formValues?.shippingChargeType == 'Free Shipping'}
                        onChange={(e) => {
                          const newPrice = formatInputPrice(e.target.value);
                          handleFormValues(
                            "shippingOneItemPrice",
                            newPrice
                          );
                          handleFormValues(
                            "freeShipping",
                            false
                          );
                        }}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell align="center">
                      <OutlinedInput
                        key={'shippingAddlItemPrice'}
                        size="small"
                        inputProps={{ min: "0", max: "1000000" }}
                        defaultValue={formValues.shippingAddlItemPrice}
                        value={formValues.shippingAddlItemPrice}
                        disabled={formValues?.shippingChargeType == 'Free Shipping'}
                        onChange={(e) => {
                          const newPrice = formatInputPrice(e.target.value);
                          handleFormValues(
                            "shippingAddlItemPrice",
                            newPrice
                          );
                          handleFormValues(
                            "freeShipping",
                            false
                          );
                        }}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </ContentRow>
          <ContentRow title={"Shipping Upgrade Costs"}>
            <Typography>
              <i>{'* These will be added to standard shipping costs'}</i>
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ bgcolor: "var(--mintGreen)" }}>
                  <TableRow>
                    <TableCell>
                      <b>Shipping Charge Type</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Upgrade Name</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Shipping Service</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>One Item</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Cost per each additional item</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {formValues?.shippingUpgrades?.length > 0 && formValues?.shippingUpgrades.map((upgrade, i) => {
                  return (
                    <TableBody key={'shippingUpgrades' + i}>
                      <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <FormControl
                            variant="outlined"
                            size="small"
                            sx={{
                              minWidth: 150,
                              float: "left",
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "7px",
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "var(--mintGreen)",
                                  color: "var(--mintGreen)",
                                },
                              },
                            }}
                          >
                            <InputLabel htmlFor="outlined-age-native-simple">Charge Type</InputLabel>
                            <Select
                              onChange={(e) => {
                                handleObjectArrayChanges("shippingUpgrades", "chargeType", i, e.target.value);
                                if (e.target.value == 'Free Shipping') {
                                  handleObjectArrayChanges("shippingUpgrades", "oneItemPrice", i, 0);
                                  handleObjectArrayChanges("shippingUpgrades", "addlItemPrice", i, 0);
                                }
                              }}
                              label="Charge Type"
                              inputProps={{
                                name: "chargeType",
                                id: "outlined-age-native-simple",
                              }}
                              defaultValue={upgrade?.oneItemPrice <= 0 ? SHIPPING_CHARGE_TYPES[1] : SHIPPING_CHARGE_TYPES[0]}
                              value={upgrade?.chargeType}
                            >
                              {SHIPPING_CHARGE_TYPES.map((upgradeType, i) => {
                                return <MenuItem value={upgradeType}>{upgradeType}</MenuItem>
                              })}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <FormControl
                            variant="outlined"
                            size="small"
                            sx={{
                              minWidth: 100,
                              float: "left",
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "7px",
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "var(--mintGreen)",
                                  color: "var(--mintGreen)",
                                },
                              },
                            }}
                          >
                            <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>
                            <Select
                              onChange={(e) => {
                                handleObjectArrayChanges("shippingUpgrades", "shippingName", i, e.target.value);
                              }}
                              value={upgrade?.shippingName}
                              label="Type"
                              inputProps={{
                                name: "shippingName",
                                id: "outlined-age-native-simple",
                              }}
                            >
                              {SHIPPING_UPGRADE_TYPES.map((upgradeType, i) => {
                                return <MenuItem value={upgradeType}>{upgradeType}</MenuItem>
                              })}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell align="center">
                          <Autocomplete
                            key={"changeUpgradeService" + i}
                            size="small"
                            fullWidth
                            options={SHIPPING_SERVICES}
                            onChange={(e, value) => {
                              handleObjectArrayChanges("shippingUpgrades", "service", i, value);
                            }}
                            disableClearable
                            sx={{ width: 345 }}
                            defaultValue={upgrade?.service}
                            value={upgrade?.service}
                            renderInput={(params) => <TextField {...params} label="Shipping Service" />}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <OutlinedInput
                            key={'shippingOneItemPrice'}
                            size="small"
                            inputProps={{ min: "0", max: "1000000" }}
                            defaultValue={upgrade?.oneItemPrice}
                            value={upgrade?.oneItemPrice}
                            disabled={upgrade?.chargeType == 'Free Shipping'}
                            onChange={(e) => {
                              const newPrice = formatInputPrice(e.target.value);
                              handleObjectArrayChanges("shippingUpgrades", "oneItemPrice", i, newPrice);
                              // handleFormValues(
                              //   "shippingOneItemPrice",
                              //   parseFloat(e.target.value)
                              // );
                              // handleFormValues(
                              //   "freeShipping",
                              //   false
                              // );
                            }}
                            startAdornment={
                              <InputAdornment position="start">$</InputAdornment>
                            }
                            fullWidth
                          />
                        </TableCell>
                        <TableCell align="center">
                          <OutlinedInput
                            key={'shippingAddlItemPrice'}
                            size="small"
                            inputProps={{ min: "0", max: "1000000" }}
                            defaultValue={upgrade?.addlItemPrice}
                            value={upgrade?.addlItemPrice}
                            disabled={upgrade?.chargeType == 'Free Shipping'}
                            onChange={(e) => {
                              const newPrice = formatInputPrice(e.target.value);
                              handleObjectArrayChanges("shippingUpgrades", "addlItemPrice", i, newPrice);

                              // handleFormValues(
                              //   "shippingAddlItemPrice",
                              //   parseFloat(e.target.value)
                              // );
                              // handleFormValues(
                              //   "freeShipping",
                              //   false
                              // );
                            }}
                            startAdornment={
                              <InputAdornment position="start">$</InputAdornment>
                            }
                            fullWidth
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>

                  )
                })
                }
                {formValues?.shippingUpgrades?.length > 0 && (
                  <ButtonBase
                    key={'removeShippingUpgrade'}
                    sx={style.removeButton}
                    onClick={() => {
                      handleRemoveClick(formValues?.shippingUpgrades?.length - 1, "shippingUpgrades")
                    }}
                  >
                    Remove
                  </ButtonBase>
                )}
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <ButtonBase
                        sx={{ mt: 1 }}
                        onClick={() => {
                          handleAddClickBlank("shippingUpgrades")
                        }}
                      >
                        <Typography color={"gray"} variant={"body2"}>
                          <i>Add Shipping Upgrade</i>
                        </Typography>
                      </ButtonBase>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </ContentRow>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default ListingLayout;
