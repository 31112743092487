import { Container } from "aws-amplify-react";
import React, { useRef, useState, forwardRef } from "react";
import { AdminPortalManagementMaster } from "..";
import BlogPost from "../../components/BlogPost";
import blogLayoutJsonData from "../../json/blog_layout.json";
import { getBlogs } from "../../util/blogs";
import { Grid, Typography, ButtonBase, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Carousel from "react-multi-carousel";

import useGalleryPages from "../../hooks/useGalleryPages";

import makeStyles from "@mui/styles/makeStyles";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";

const maxNumPages = 5;

const responsive = {
  break1: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles({
  galleryDiv: {
    paddingBottom: 10,
    overflowX: "hidden",
    display: "relative",
  },
  // galleryContainer: {
  //   display: "relative",
  // },
  galleryGrid: {
    padding: 40,
    margin: "auto",
    marginBottom: "32px"
  },
  galleryItem: {
    paddingTop: 15,
    paddingBottom: 15,
    maxWidth: "100%",
  },
  galleryButton: {
    width: "35px",
    height: "35px",
    borderRadius: "25px",
    border: "none",
    backgroundColor: "#EAF1EF",
    margin: 7,
  },
});

const CustomDots = ({ index, onClick }) => {
  const classes = useStyles();

  if (index < maxNumPages) {
    const NumberButton = (
      <ButtonBase
        onClick={(e) => {
          onClick();
          e.preventDefault();
        }}
        className={classes.galleryButton}
      >
        <Typography>{index + 1}</Typography>
      </ButtonBase>
    );

    if (index === maxNumPages - 1) {
      return (
        <>
          {NumberButton}
          <ButtonBase className={classes.galleryButton}>
            <Typography>...</Typography>
          </ButtonBase>
        </>
      );
    } else {
      return <>{NumberButton}</>;
    }
  } else {
    return <></>;
  }
};

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  return (
    <>
      <ButtonBase
        sx={{
          position: "absolute",
          width: "35px",
          height: "35px",
          borderRadius: "25px",
          border: "none",
          left: { xs: "2%", sm: "18%", md: "23%", lg: "30%", xl: "30%" },
          bottom: 7,
          backgroundColor: "#EAF1EF",
          zIndex: 50,
        }}
        onClick={previous}
      >
        <ArrowBack sx={{}} />
      </ButtonBase>
      <ButtonBase
        sx={{
          position: "absolute",
          width: "35px",
          height: "35px",
          borderRadius: "25px",
          border: "none",
          right: { xs: "2%", sm: "18%", md: "23%", lg: "30%", xl: "30%" },
          bottom: 7,
          zIndex: 50,
          backgroundColor: "#EAF1EF",
        }}
        onClick={next}
      >
        <ArrowForward sx={{}} />
      </ButtonBase>
    </>
  );
};

function ProductPages(pages, admin, classes, mobile = false) {
  let productPages = [];

  for (let i = 0; i < pages.length && i < maxNumPages; i++) {
    productPages.push(
      <Grid
        container
        direction="row"
        //justifyContent="center"
        className={classes.galleryGrid}
        key={i}
      >
        {pages[i] != 'failed' && pages[i].map((blog, index) => {
          return (
            // <Grid
            //   item
            //   xs={4}
            //   key={blog.blogID} 
            //   // className={classes.galleryItem}
            //   style={{
            //     paddingLeft: mobile ? 7 : 20,
            //     paddingRight: mobile ? 7 : 20,
            //   }}
            // >
              <BlogPost blogPost={blog} />
            // </Grid>
          );
        })}
      </Grid>
    );
  }
  return productPages;
}


function CurrentBlogLayout() {
  let admin = true;
  let mobile = false; // for now
  const [blogData, setBlogData] = useState([]);
  const [loadedBlogs, setLoadedBlogs] = useState(false);
  const [loadingBlog, setLoadingBlog] = useState(false);
  const [blogGallery, setBlogGallery] = useState([[]]);
  const [filter, setFilter] = useState('Newest First');
  const classes = useStyles();

  useGalleryPages(blogData, setBlogGallery, 16);

  React.useEffect(() => {
    if (!loadedBlogs && !loadingBlog) {
      setLoadingBlog(true)
      getBlogs((data) => {
        data?.results?.sort ((a, b) => {
          return new Date(b.createDate) - new Date(a.createDate);
        });
        setBlogData(data.results);
        setLoadedBlogs(true);
      })
    }
  }, [blogData]);

  function handleFilter(filter, setFilter, blogData, setblogData) {
    if (filter && blogData?.length > 0) {
      setFilter(filter);
      const newBlogData = [...blogData];
      if (filter == 'Newest First') {
        newBlogData.sort((a, b) => { return new Date(b.date) - new Date(a.date) });
      } else if (filter == 'Oldest First') {
        newBlogData.sort((b, a) => { return new Date(b.date) - new Date(a.date) });
      } else if (filter == 'AZ') {
        newBlogData.sort((a, b) => {
          if (a.storeName.toLowerCase() < b.storeName.toLowerCase()) {
            return -1;
          }
          if (a.storeName.toLowerCase() > b.storeName.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      } else if (filter == 'ZA') {
        newBlogData.sort((b, a) => {
          if (a.storeName.toLowerCase() < b.storeName.toLowerCase()) {
            return -1;
          }
          if (a.storeName.toLowerCase() > b.storeName.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      }
      setblogData(newBlogData);
    }
  }

  const FilterButton = () => {
    return (
      <FormControl
        variant="outlined"
        size="small"
        sx={{
          minWidth: 120,
          float: "right",
          marginBottom: "10px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "7px",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "var(--mintGreen)",
              color: "var(--mintGreen)",
            },
          },
        }}
      >
        <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
        <Select
          onChange={(e) => { handleFilter(e.target.value, setFilter, blogData, setBlogData) }}
          label="Filter"
          inputProps={{
            name: "filter",
            id: "outlined-age-native-simple",
          }}
          value={filter}
        >
          <MenuItem value="Newest First">Newest First</MenuItem>
          <MenuItem value="Oldest First">Oldest First</MenuItem>
          <MenuItem value="AZ">A to Z</MenuItem>
          <MenuItem value="ZA">Z to A</MenuItem>
        </Select>
      </FormControl>
    )
  }

  return (
    <AdminPortalManagementMaster>
      
      <Container style={{ padding: "32px" }}>
        <Grid container justifyContent="flex-end">
          <FilterButton/>
        </Grid>
        
        {/* {blogData.map((blog, i) => {
          return (
            <BlogPost key={blog.blogID} blogPost={blog} />
          )
        })} */}
      </Container>
      <Carousel
          responsive={responsive}
          infinite={false}
          showDots={true}
          arrows={false}
          // renderButtonGroupOutside={true}
          customDot={<CustomDots />}
          customButtonGroup={<CustomButtonGroupAsArrows />}
          className={classes.galleryContainer}
        >
          {ProductPages(blogGallery, admin, classes, mobile)}
        </Carousel>
        <div style={{height: "var(--dividerHeight)"}}/>
    </AdminPortalManagementMaster>
  )
}

//createDate

export default CurrentBlogLayout;