import React, { useState } from "react";
import { Typography, Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';


const useStyles = makeStyles({
  box: {
    width: "100%",
    height: "300px",
    borderStyle: "solid",
    borderColor: "var(--coral)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  text: {
    fontSize: "2rem",
    textAlign: "center",
  },
  number: {
    fontSize: "5rem",
    textAlign: "center",
    marginRight: "16px",
  },
  pointsBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  labelNumber: {
    color: "black",
  },
  root: {
    backgroundColor: 'var(--lightBeige)',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    borderStyle: "solid",
    borderColor: "var(--coral)",
    borderWidth: "4px"
  },
  active: {
    backgroundColor: 'var(--coral)',
    borderStyle: "solid",
    borderColor: "var(--coral)",
    borderWidth: "4px"
  },
  completed: {
    backgroundColor: 'var(--coral)',
  },
});



const LoyaltyProgramPointsProgress = () => {
  const classes = useStyles();
  const steps = [
    '$0', '$10', '$20', '$30', '$40', '$50',
    '$60', '$70', '$80', '$90', '$100'
  ];

  const CustomStepIcon = (props) => {
    const classes = useStyles();
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
      </div>
    );
  };

  const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: 'var(--coral)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: 'var(--coral)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 2,
      border: 0,
      backgroundColor: "black",
      borderRadius: 1,
    },
  }));

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper alternativeLabel connector={<CustomStepConnector />}>
        {steps.map((label) => (
          <Step key={label} active ={false}>
            <StepLabel StepIconComponent={CustomStepIcon}> 
                <Typography className={clsx("roboto-medium", classes.labelNumber)} variant="h4">
                    {label}
                </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default LoyaltyProgramPointsProgress;
