import {
  Box,
  ButtonBase,
} from "@mui/material";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

const ExportToCSVButton = (props) => {
  const { csvData, fileName = 'exportedData' } = props;

  const downloadCSV = () => {

    // Convert our data to CSV using Papa Parse
    const csv = Papa.unparse(csvData);

    // Create a Blob from our CSV string
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    // Use file-saver to save the file
    saveAs(blob, fileName?.includes('.csv') ? fileName : fileName + ".csv");
  };

  return (
    <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <ButtonBase
      onClick={downloadCSV}
      sx={{
        bgcolor: "var(--coral)",
        py: 1,
        px: 2.5,
        borderRadius: 2.5,
        color: "white",
      }}
    >
      <b>Export to CSV</b>
    </ButtonBase>
  </Box>
  );
};

export default ExportToCSVButton;
