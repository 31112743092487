import { getSessionJwt, getJsonCache, setJsonCache, isStringABase64File, getBase64FileName } from "./utilities";

const {
  NODE_API_ENDPOINT
} = require("./constants");

// Old method
async function uploadImage(imageData) {
  const jwtToken = await getSessionJwt();

  const reqBody = {
    imageData,
  }
  const response = await fetch(NODE_API_ENDPOINT + "/upload-an-image", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  });
  const data = await response.json();
  return data;
}

// function DataURIToBlob(dataURI) {
//   const splitDataURI = dataURI.split(',')
//   const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
//   const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

//   const ia = new Uint8Array(byteString.length)
//   for (let i = 0; i < byteString.length; i++)
//       ia[i] = byteString.charCodeAt(i)

//   return new Blob([ia], { type: mimeString })
// }

// async function uploadImage(imageData) {
//   const jwtToken = await getSessionJwt();
//   const fileName = getBase64FileName(imageData);
//   const file = DataURIToBlob(imageData)
//   const formData = new FormData();
//   formData.append('upload', file, fileName)

//   console.log('file', file)

//   const response = await fetch(NODE_API_ENDPOINT + "/upload-an-image", {
//     method: "POST",
//     headers: {
//       "Authorization": 'Bearer ' + jwtToken,
//       // "Content-Type": "multipart/form-data",
//     },
//     body: formData,
//   });
//   const data = await response.json();
//   return data;
// }

const uploadImageArray = async (imageArray) => {
  if (!imageArray || !Array.isArray(imageArray)) return [];
  const imgUrls = [];
  for (const img of imageArray) {
    if (img.includes('.cloudfront.net')) {
      //Image is already uploaded
      imgUrls.push(img);
      continue;
    }  
    if (!isStringABase64File(img)) continue;
    const imgRes = await uploadImage(img);
    if (imgRes?.results?.filename) imgUrls.push(`https://d2eudfgh8kpk8j.cloudfront.net/dev/product/${imgRes?.results?.filename}`);
  }
  return imgUrls;
}

export {
  uploadImageArray,
 };
