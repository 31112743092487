// Exact same as the Master Layout except with the Purpose category bubbles
import { Container } from "@mui/material";
import MasterLayout from "./MasterLayout";
import { styled } from "@mui/material/styles";
import PurposeCategoryView from "../../components/PurposeCategoryView";
import PurposeCategoryMobileView from "../../components/PurposeCategoryMobileView";
import useMobileMediaQuery from "../../hooks/useMobileMediaQuery";

const PurposeCategoryDiv = styled("div")({
  width: "90%",
  margin: "auto",
  paddingTop: 10,
  paddingBottom: 10,
});

function PurposeCategoryMasterLayout(props) {
  const mobile = useMobileMediaQuery();

  return (
    <MasterLayout>
      {/* <Container maxWidth="false"> */}
        {/* <PurposeCategoryDiv> */}
          {mobile ? (<PurposeCategoryMobileView/>) : (<PurposeCategoryView/>)}
        {/* </PurposeCategoryDiv> */}
      {/* </Container> */}
      {props.children ? props.children : <></>}
    </MasterLayout>
  );
}

export default PurposeCategoryMasterLayout;
