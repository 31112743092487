import {
  Typography,
  ButtonBase,
  Grid,
  Container,
  Divider,
  Rating,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Stack,
  CircularProgress,
  Modal,
  Box,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Carousel from "react-multi-carousel";
import Review from "./Review";
import { useRef, useState } from "react";
import clsx from "clsx";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
  TextFields,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import React from "react";
import { getReviewsByProduct, addReviewToProduct, updateProductReview, deleteProductReview } from "../util/products";
import FileUpload, {
  UPLOAD_ERROR,
  triggerUpload,
  onDropHandler,
  onDragOverHandler,
} from "./FileUpload";
import { Auth } from "aws-amplify";
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import toast, { Toaster } from 'react-hot-toast';
import { MAX_FILE_SIZE, FILETYPES, MAX_NUM_FILES, MIN_REVIEW_LENGTH, MAX_REVIEW_LENGTH } from "../util/constants"
import FileUploadV2 from "./FileUploadV2";
import { uploadImageArray } from "../util/images";




const maxNumPages = 9;

const responsive = {
  break1: {
    breakpoint: { max: 4000, min: 1100 },
    items: 2,
  },
  break2: {
    breakpoint: { max: 1100, min: 0 },
    items: 1,
  },
};

const InputRow = (props) => {
  const { label, description, classes } = props;

  return (
    <Grid container item direction="row" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography>
          <b>{label}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {props.children}
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.descriptionText}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};


const useStyles = makeStyles({
  reviewsDiv: {
    backgroundColor: "inherit",
    overflow: "hidden",
  },
  reviewsCarousel: {
    position: "relative",
    width: "93%",
    paddingBottom: 100,
    margin: "auto",
    overflow: "hidden",
  },
  reviewsNavButtons: {
    position: "absolute",
    top: "29%",
    border: "1px solid black",
    borderRadius: 20,
    backgroundColor: "#FFFFFF",
  },
  reviewsPrevButton: {
    left: -10,
  },
  reviewsNextButton: {
    right: -10,
  },
  reviewsExpandButton: {
    color: "#000000",
    paddingBottom: 20,
  },
  noReviewsText: {
    fontSize: "1.1rem",
    paddingBottom: 40,
  },
  divContainer: {
    position: "relative",
    width: "100%",
    margin: "auto",
  },
  reviewStar: {
    color: "#ee907b",
  },
  divider: {
    backgroundColor: "black",
    margin: "3px auto 10px auto",
    height: 1,
    maxWidth: 320,
  },
  gridContainer: {
    margin: "20px auto",
  },
  writeAReviewButton: {
    backgroundColor: "white",
    border: "2px solid var(--mintGreen)",
    padding: "5px 15px",
    borderRadius: 8,
  },
  deleteAReviewButton: {
    backgroundColor: "white",
    color: "red",
    padding: "5px 15px",
    borderRadius: 8,
  },
  galleryButton: {
    width: "35px",
    height: "35px",
    borderRadius: "25px",
    backgroundColor: "white",
    border: "1px solid black",
    margin: "7px 7px 20px 7px",
  },
  img: {
    //maxWidth: "400px"
    // maxHeight: "200px"
    height: "200px",
    width: "200px",
    objectFit: "cover"
  },
  uploadIconDiv: {
    width: "100px",
    height: "100px",
    backgroundColor: "lightgray",
    borderRadius: "8px",
    opacity: "0.4",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  uploadIconDivVer2: {
    width: "200px",
    height: "200px",
    backgroundColor: "lightgray",
    borderRadius: "8px",
    opacity: "0.4",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  photoError: {
    color: "#d32f2f"
  },
  minCharCount: {
    fontSize: "0.75rem"
  }
});

const ContactSellerIcon = styled("img")({
  maxWidth: 50,
});

const CustomDots = ({ index, onClick }) => {
  const classes = useStyles();

  if (index < maxNumPages) {
    const NumberButton = (
      <ButtonBase
        onClick={(e) => {
          onClick();
          e.preventDefault();
        }}
        className={classes.galleryButton}
      >
        <Typography>{index + 1}</Typography>
      </ButtonBase>
    );

    if (index === maxNumPages - 1) {
      return (
        <>
          {NumberButton}
          <ButtonBase className={classes.galleryButton}>
            <Typography>...</Typography>
          </ButtonBase>
        </>
      );
    } else {
      return <>{NumberButton}</>;
    }
  } else {
    return <></>;
  }
};

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  const classes = useStyles();
  return (
    <>
      <ButtonBase
        className={clsx(classes.navButtons, classes.prevButton)}
        onClick={(e) => {
          e.stopPropagation();
          previous();
        }}
      >
        <ArrowBack className={classes.arrows} />
      </ButtonBase>
      <ButtonBase
        className={clsx(classes.navButtons, classes.nextButton)}
        onClick={(e) => {
          e.stopPropagation();
          next();
        }}
      >
        <ArrowForward className={classes.arrows} />
      </ButtonBase>
    </>
  );
};

const ReviewButtonGroup = ({ next, previous }) => {
  const classes = useStyles();
  return (
    <>
      <ButtonBase
        className={clsx(classes.reviewsNavButtons, classes.reviewsPrevButton)}
        onClick={previous}
      >
        <ArrowBack fontSize="large" />
      </ButtonBase>
      <ButtonBase
        className={clsx(classes.reviewsNavButtons, classes.reviewsNextButton)}
        onClick={next}
      >
        <ArrowForward fontSize="large" />
      </ButtonBase>
    </>
  );
};

const ReviewDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  }
}));

const ReviewDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
};

function Reviews(props) {
  let businessLogoRef = useRef(null);
  let coverPhotoRef = useRef(null);
  const [businessLogoError, setBusinessLogoError] = useState(null);
  const [coverPhotoError, setCoverPhotoError] = useState(null);

  const classes = useStyles();
  let { reviews, businessLogo, admin = false, sellerID, seller } = props;

  const [formValues, setFormValues] = useState({
    images: []
  });

  const handleFormValues = (name, value) => {
    setFormValues((preValues) => {
      return { ...preValues, [name]: value };
    });
  };

  const { id } = useParams();
  const [avg, setAvg] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSubmitReview, setLoadingSubmitReview] = useState(false);

  const [reviewExists, setReviewExists] = React.useState(false);

  const DEFAULT_ID = "22963625-1ae2-41d1-9041-97a8111c74a0";
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [contactSellerOpen, setContactSellerOpen] = React.useState(false);

  const handleClickDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleContactSellerOpen = () => {
    setContactSellerOpen(false);
  };

  const uploadImagesAndUpdateFormData = async (imageArray, formDataValueName, handleFormData) => {
    const imgUrlArray = await uploadImageArray(imageArray);
    handleFormData(formDataValueName, imgUrlArray);
    return imgUrlArray;
  }

  const handleReviewSubmit = async () => {
    setLoading(true)
    setLoadingSubmitReview(true)
    var swearjar = require('swearjar');
    if (swearjar.profane(reviewText)) {
      setLoading(false)
      setLoadingSubmitReview(false)
      const errorText = "Check your typed content. You are using a word or phrase from a Prohibited List!";
      toast((t) => (
        <span>
          {errorText}
          {'\n'}
          <Grid container justifyContent="center">
            <Button onClick={() => toast.dismiss(t.id)}>
              Dismiss
            </Button>
          </Grid>
        </span>
      ));
    }
    else {
      const processedImages = await uploadImagesAndUpdateFormData(formValues.images, "images", handleFormValues);

      if (reviewExists) {
        updateProductReview(reviewID, reviewText, processedImages, parseFloat(stars), (data) => {
          setLoading(false)
          setLoadingSubmitReview(false)
          setDialogOpen(false);
          window.location.reload(false);
        })
      }
      else {
        addReviewToProduct(id, reviewText, processedImages, parseFloat(stars), (data) => {
          setLoading(false)
          setLoadingSubmitReview(false)
          setDialogOpen(false);
          window.location.reload(false);
        })


      }
    }
  };

  const handleReviewDelete = () => {
    setLoading(true)
    deleteProductReview(reviewID, (data) => {
      setLoading(false)
      setDialogOpen(false);
      window.location.reload(false);
    })
  }

  // handle click event of the Remove button
  const handleRemoveClick = (index, section) => {
    const list = [...formValues[section]];
    list.splice(index, 1);
    handleFormValues(section, list);
  };

  const [reviewText, setReviewValue] = React.useState("");
  const [reviewStarted, setReviewStartedValue] = React.useState(false);
  const [stars, setStarValue] = React.useState(0);
  const [reviewID, setReviewIDValue] = React.useState(-1);

  const [reviewData, setReviewData] = React.useState({});

  const [loggedIn, setLoggedIn] = React.useState(false);

  const productPage = true;

  React.useEffect(() => {
    setLoading(true)
    getReviewsByProduct(id, (data) => {
      setLoading(false)
      setReviewData(data);
    })
  }, [id, reviews]);

  React.useEffect(() => {
    let sum = 0;
    if (reviewData && reviewData.hasOwnProperty("results")) {
      for (let i = 0; i < reviewData.results.length; i++) {
        sum += reviewData.results[i].rating;
      }
      const avg = sum / reviewData.results.length;
      setAvg(avg);
    }


    Auth.currentAuthenticatedUser().then((user) => {
      setLoggedIn(true);
      const userID = user.attributes.sub.toUpperCase();
      if (reviewData && reviewData.hasOwnProperty("results")) {
        for (let i = 0; i < reviewData.results.length; i++) {
          if (reviewData.results[i].userID == userID) {
            setReviewExists(true);
            setStarValue(reviewData.results[i].rating);
            setReviewValue(reviewData.results[i].review);
            setReviewIDValue(reviewData.results[i].reviewID);
            handleFormValues("images", reviewData.results[i].pictures);
          }
        }
      }

    })

  }, [reviewData]);

  return (
    <div className={classes.reviewsDiv}>
      <Container >
        <Toaster
          toastOptions={{
            className: '',
            style: {
              border: '1px solid #713200',
              padding: '16px',
              color: '#713200',
            },
          }}
        />
        <Modal open={loading} sx={{ overflow: "scroll" }}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: 2,
              bgcolor: "background.paper",
              p: 4,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
          </Box>
        </Modal>
        <Dialog
          onClose={handleContactSellerOpen}
          aria-labelledby="customized-dialog-title"
          open={contactSellerOpen}
          maxWidth="md"
          fullWidth

        >
          <DialogTitle id="customized-dialog-title" onClose={handleContactSellerOpen}>
            <Typography>
              <b>Contact Seller Info</b>
            </Typography>
            <Typography>
              <b>Seller Contact Email: </b>
              {seller?.contactEmail || "N/A"}
            </Typography>
            <Typography>
              <b>Seller Contact Phone Number: </b>
              {seller?.contactPhone || "N/A"}
            </Typography>
          </DialogTitle>
        </Dialog>
        <Grid
          container
          //direction="row"
          justifyContent="space-around"
          alignItems="center"
          align="center"
          className={classes.gridContainer}
        >
          <Grid item xs={6} sm order={{ xs: 2, sm: 1 }}>
            {loggedIn && !reviewExists && <ButtonBase className={classes.writeAReviewButton}
              onClick={handleClickDialogOpen}>
              <Typography className={"roboto-medium"}>
                {"Write a Review"}
              </Typography>
            </ButtonBase>}
            <ReviewDialog maxWidth="md" fullWidth onClose={handleDialogClose} open={dialogOpen}>
              <ReviewDialogTitle onClose={handleDialogClose}>
                <Typography>
                  <b>Overall Rating</b>
                </Typography>
              </ReviewDialogTitle>
              <DialogContent dividers>
                <Rating
                  sx={{ color: "var(--coral)" }}
                  onChange={(e) => setStarValue(e.target.value)}
                  value={stars.toString()}
                  precision={0.5}
                />
                <Typography className={classes.minCharCount}>
                  {!stars || stars < 0.5 ? `Must select a star rating!` : ""}
                </Typography>
                <br/>
              </DialogContent>
              <DialogContent dividers>
                <InputRow label="Write a Review" classes={classes}>
                  <TextField
                    name="review"
                    error={reviewText.length < MIN_REVIEW_LENGTH && (reviewText.length > 0 || reviewStarted)}
                    onChange={(e) => {
                      setReviewValue(e.target.value)
                      setReviewStartedValue(true)
                    }}
                    placeholder="What did you like or dislike? What did you use this product for?"
                    multiline
                    defaultValue={reviewText}
                    helperText={`${reviewText.length}/550`}
                    variant="outlined"
                    size="small"
                    className={classes.inputOutline}
                    fullWidth
                    inputProps={{ maxLength: MAX_REVIEW_LENGTH }}
                  />
                </InputRow>
                {/* small typography that lets them know the minimum character count */}
                <Typography className={classes.minCharCount}>
                  {reviewText.length < MIN_REVIEW_LENGTH && (reviewText.length > 0 || reviewStarted) ? `Minimum ${MIN_REVIEW_LENGTH} characters` : ""}
                </Typography>
                <br/>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.headers}><b>Product Photos</b></Typography>
                  <Typography>
                    You may only upload up to four photos.
                  </Typography>
                  <FileUploadV2
                    formValueName={"images"}
                    formValueArray={formValues.images}
                    maxFiles={4}
                    columns={4}
                    handleFormValues={handleFormValues}
                    buttonText={'upload: jpg, png'}
                  />
                </Grid>
                  {/* <div
                    className={clsx(
                      !formValues.images.length && classes.border,
                      classes.uploadDivContainer,
                      classes.coverPhotoDiv
                    )}
                  >
                    {formValues.images.length > 0 ? (
                      // <Carousel
                      //   responsive={responsive}
                      //   draggable={false}
                      //   infinite={true}
                      //   showDots={false}
                      //   arrows={false}
                      //   containerClass={clsx(classes.carouselContainer, classes.border)}
                      //   slidesToSlide={1}
                      //   autoPlay={formValues.images.length > 1}
                      //   autoPlaySpeed={8000}
                      //   customButtonGroup={<CustomButtonGroupAsArrows />}
                      // >
                      <Grid container columnSpacing={2} rowSpacing={2}>
                        {formValues.images.map((image, index) => {
                          return (
                            <Grid item>
                              <ClearIcon onClick={() => handleRemoveClick(index, "images")} style={{ position: 'absolute', color: 'black', padding: "8px", cursor: "pointer" }}></ClearIcon>
                              <img
                                src={image}
                                alt=""
                                key={index}
                                className={classes.img}
                              />
                            </Grid>
                          );
                        })}
                        {formValues.images.length < 4 && <Grid item >
                          <div style={{ cursor: "pointer" }}
                            onClick={() => {
                              triggerUpload(coverPhotoRef);
                            }}
                            onDrop={(e) =>
                              onDropHandler(
                                e,
                                setCoverPhotoError,
                                "images",
                                MAX_NUM_FILES,
                                FILETYPES,
                                MAX_FILE_SIZE,
                                handleFormValues,
                                true,
                                formValues.images
                              )
                            }
                            onDragOver={onDragOverHandler} className={classes.uploadIconDivVer2}>
                            <AddCircleOutlineIcon fontSize="medium" />
                          </div>
                        </Grid>}
                      </Grid>
                      // </Carousel>
                    ) : (
                      // <img
                      //   src={bulb}
                      //   alt=""
                      //   className={clsx(
                      //     classes.img,
                      //     !formValues.images.length && classes.uploadLogo,
                      //     formValues.images.length && classes.uploadedImg,
                      //     formValues.images.length && classes.uploadedImages,
                      //     formValues.images.length && classes.border
                      //   )}
                      // />
                      <div style={{ cursor: "pointer" }}
                        onClick={() => {
                          triggerUpload(coverPhotoRef);
                        }}
                        onDrop={(e) =>
                          onDropHandler(
                            e,
                            setCoverPhotoError,
                            "images",
                            MAX_NUM_FILES,
                            FILETYPES,
                            MAX_FILE_SIZE,
                            handleFormValues,
                            true,
                            formValues.images
                          )
                        }
                        onDragOver={onDragOverHandler} className={classes.uploadIconDiv}>
                        <AddCircleOutlineIcon fontSize="medium" />
                      </div>
                    )}


                    <FileUpload
                      setRef={(inputRef) => {
                        coverPhotoRef = inputRef;
                      }}
                      multiple
                      setError={setCoverPhotoError}
                      propertyName={"images"}
                      maxFiles={MAX_NUM_FILES}
                      accept={"image/*"}
                      filetypes={FILETYPES}
                      maxFileSize={MAX_FILE_SIZE}
                      updateState={handleFormValues}
                      coverPhotos={formValues.images}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <Typography>
                    You may only upload up to four photos.
                  </Typography>
                  {coverPhotoError && (
                    <Typography className={classes.photoError}>
                      {coverPhotoError.type === UPLOAD_ERROR.FILESIZE_TOO_LARGE
                        ? "Max file size exceeded"
                        : ""}
                      {coverPhotoError.type === UPLOAD_ERROR.TOTALFILESIZE_TOO_LARGE
                        ? "Your total files size exceeds the maximum. Upload the images individually."
                        : ""}
                      {coverPhotoError.type === UPLOAD_ERROR.NOT_SUPPORTED_EXTENSION
                        ? "Illegal file type"
                        : ""}
                    </Typography>
                  )}
                </Grid> */}
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={handleReviewSubmit}
                  className={classes.writeAReviewButton}
                  disabled={reviewText.length < MIN_REVIEW_LENGTH || !stars || stars < 0.5 || loadingSubmitReview}
                >
                  Submit
                </Button>
                {reviewExists ? (<Button
                  autoFocus
                  onClick={handleReviewDelete}
                  className={classes.deleteAReviewButton}
                >
                  Delete
                </Button>) : ""}

                <Button
                  autoFocus
                  onClick={handleDialogClose}
                  className={classes.closeButton}
                >
                  Close
                </Button>
              </DialogActions>
            </ReviewDialog>
          </Grid>
          <Grid item xs={12} sm order={{ xs: 1, sm: 2 }}>
            <Typography variant="h4" className={"roboto-medium"}>
              Product Reviews
            </Typography>
            <Divider className={classes.divider} />
            <Stack
              direction="row"
              align="center"
              sx={{ justifyContent: "center" }}
            >
              <Rating
                sx={{ color: "var(--coral)" }}
                readOnly
                value={avg}
                precision={0.5}
              />
              <Typography className={"roboto-medium"}>
                ({reviewData.results ? reviewData.results.length : 0})
              </Typography>
            </Stack>
            <Typography className={"roboto-medium"}>
              Overall {avg ? (Math.round(avg * 100) / 100) : 0} out of 5
            </Typography>
          </Grid>
          <Grid item xs={6} sm order={{ xs: 3 }}>
            <a>
              <ContactSellerIcon
                src={
                  "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/reviews/Contact+Us+Icon+Mint+Green.png"
                }
                alt="Contact Seller"
                onClick={() => {setContactSellerOpen(true)}}
              />
              <Typography variant="subtitle1" className={"roboto-regular"}>
                Contact Seller
              </Typography>
            </a>
          </Grid>
        </Grid>
        <div className={classes.divContainer}>
          {reviewData && reviewData.results ? (
            <>
              <Carousel
                responsive={responsive}
                infinite={false}
                showDots={true}
                arrows={false}
                draggable={false}
                className={classes.reviewsCarousel}
                renderButtonGroupOutside
                customButtonGroup={<ReviewButtonGroup />}
                customDot={<CustomDots />}
              >
                {reviewData.results.map((review, index) => {
                  return <Review review={review} sellerID={sellerID} key={index} admin={admin} productPage={productPage} />;
                })}
              </Carousel>
              {/* <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography>
                    <Link
                      href={"/shop/product/" + id + "/reviews/"}
                      color="inherit"
                      underline="none"
                    >
                      <b>all reviews</b>
                    </Link>
                  </Typography>
                </Grid>
                <Grid item>
                  <a href={"/shop/product/" + id + "/reviews/"}>
                    <ExpandMore className={classes.reviewsExpandButton} />
                  </a>
                </Grid>
              </Grid> */}
            </>
          ) : (
            <Typography
              align="center"
              className={clsx(classes.noReviewsText, "roboto-regular")}
            >
              No Reviews
            </Typography>
          )}
        </div>
      </Container>
    </div>
  );
}

export default Reviews;
