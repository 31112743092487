import { Grid, ListItem, ListItemText, Container, Radio, ButtonBase, Select, MenuItem, FormControl, FormLabel, RadioGroup, FormControlLabel, Button, InputLabel, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MuiAccordion from '@mui/material/Accordion';
import withStyles from "@mui/styles/withStyles";

import React, { useState } from "react";
import TrackingandShippingView from "./TrackingandShippingView";
import OrderNumView from "./OrderNumView";
import Favorites from "./Favorites";
import FavoritesSearched from "./FavoritesSearched";
import { getListOfProductsById } from "../util/getProductsById";
import signOut from "../util/signOut";

//JSON filler ---------------------
import mockData from "../json/mockAPIdata.json";
import trackData from "../json/shipping_data.json";
import AccountPurchasesView from "./AccountPurchasesView";
import AccountReturnsAndRefundsView from "./AccountReturnsAndRefundsView";
import MySettings from "./MySettings"
import MyRewards from "./MyRewards";
import Review from "./Review";
import Carousel from "react-multi-carousel";
import clsx from "clsx";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
  TextFields,
} from "@mui/icons-material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link, useLocation } from "react-router-dom";
import SupportedPurposes from "./SupportedPurposes";
import SupportedPurposesOther from "./SupportedPurposesOther";
import { padding, width } from "@mui/system";
//---------------------------------

import { Typography, TextField } from "@mui/material";
// import { Button } from "aws-amplify-react";
import { getUserByID } from "../util/users";
import { getOrderById, getOrdersByUserId, getOrderPurposesByUserId } from "../util/orders";
import { getReviewsByUser } from "../util/products";

import { Auth } from "aws-amplify";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from "@mui/material/styles";

const useStyles = makeStyles({
  galleryDiv: {
    paddingBottom: 10,
    overflowX: "hidden",
    display: "relative",
  },
  galleryContainer: {
    display: "relative",
    //marginBottom: "-16px"
  },
  galleryGrid: {
    padding: 40,
    // margin: "auto",
    marginBottom: "32px"
  },
  galleryItem: {
    paddingTop: 15,
    paddingBottom: 15,
    maxWidth: "100%",
  },
  galleryButton: {
    width: "35px",
    height: "35px",
    borderRadius: "25px",
    border: "none",
    backgroundColor: "#EAF1EF",
    margin: 7,
  },
  rightItemMargin: {
    margin: "auto 50px",
  },
  rightGridItem: {
    paddingLeft: 50,
  },
  gridItems: {
    marginTop: "var(--dividerHeight)",
  },
  reviewsCarousel: {
    position: "relative",
    width: "93%",
    paddingBottom: 100,
    marginTop: 10,
    margin: "auto",
    overflow: "hidden",
  },
  reviewsNavButtons: {
    position: "absolute",
    top: "29%",
    border: "1px solid black",
    borderRadius: 20,
    backgroundColor: "#FFFFFF",
  },
  reviewsPrevButton: {
    left: -10,
    margin: 10,
  },
  reviewsNextButton: {
    right: -10,
    margin: 10,
  },
  borderSection: {
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 5,
    padding: 5
  },
  sectionColor: {
    // textColor: "EE907B"
    color: "#EE907B"
  },
  buttonStyling: {
    backgroundColor: "#EE907B",
    border: "none",
    color: "white",
    padding: "5px 35px",
    borderRadius: "3px",
    marginLeft: "20px"
  },
  birthdayMargins: {
    margin: "auto 20px"
  },
});

const maxNumPages = 5;

const responsive = {
  break1: {
    breakpoint: { max: 4000, min: 1100 },
    items: 2,
  },
  break2: {
    breakpoint: { max: 1100, min: 0 },
    items: 1,
  },
};

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  return (
    <>
      <ButtonBase
        sx={{
          position: "absolute",
          width: "35px",
          height: "35px",
          borderRadius: "25px",
          border: "none",
          left: { xs: "2%", sm: "18%", md: "23%", lg: "30%", xl: "30%" },
          bottom: 7,
          backgroundColor: "#EAF1EF",
          zIndex: 50,
        }}
        onClick={previous}
      >
        <ArrowBack sx={{}} />
      </ButtonBase>
      <ButtonBase
        sx={{
          position: "absolute",
          width: "35px",
          height: "35px",
          borderRadius: "25px",
          border: "none",
          right: { xs: "2%", sm: "18%", md: "23%", lg: "30%", xl: "30%" },
          bottom: 7,
          zIndex: 50,
          backgroundColor: "#EAF1EF",
        }}
        onClick={next}
      >
        <ArrowForward sx={{}} />
      </ButtonBase>
    </>
  );
};

const CustomDots = ({ index, onClick }) => {
  const classes = useStyles();

  if (index < maxNumPages) {
    const NumberButton = (
      <ButtonBase
        onClick={(e) => {
          onClick();
          e.preventDefault();
        }}
        className={classes.galleryButton}
      >
        <Typography>{index + 1}</Typography>
      </ButtonBase>
    );

    if (index === maxNumPages - 1) {
      return (
        <>
          {NumberButton}
          <ButtonBase className={classes.galleryButton}>
            <Typography>...</Typography>
          </ButtonBase>
        </>
      );
    } else {
      return <>{NumberButton}</>;
    }
  } else {
    return <></>;
  }
};

const SaveButton = styled(Button)({
  textTransform: "none",
  borderRadius: 5,
  color: "#FFFFFF",
  paddingLeft: 15,
  paddingRight: 15,
  paddingTop: 4,
  paddingBottom: 4,
  background: "var(--coral)",
  "&:hover": {
    background: "var(--coral)",
  },
  "&:visited": {
    color: "#FFFFFF",
  },
});

const UpdateUserButton = styled(Button)({
  textTransform: "none",
  borderRadius: 5,
  color: "#FFFFFF",
  marginTop: 5,
  paddingLeft: 15,
  paddingRight: 15,
  paddingTop: 4,
  paddingBottom: 4,
  background: "var(--coral)",
  "&:hover": {
    background: "var(--coral)",
  },
  "&:visited": {
    color: "#FFFFFF",
  },
});

const AccountDetailView = (props) => {
  const [userOrderData, setUserOrderData] = useState(null);
  const [userOrderPurposeData, setUserOrderPurposeData] = useState([]);
  const [selectedOrderData, setSelectedOrderData] = useState(null);
  const [loadingGetSelectedOrder, setLoadingGetSelectedOrder] = useState(false);
  const [loadingGetOrders, setLoadingGetOrders] = useState(false);
  const [loadingGetOrderPurposes, setLoadingGetOrderPurposes] = useState(false);
  const [loadedGetOrderPurposes, setLoadedGetOrderPurposes] = useState(false);
  const [showOrderNumView, setShowOrderNumView] = useState(false);

  const {
    favoritesData,
    trackShipData = trackData,
    userGivenName = "Default Value",
    username,
  } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const classes = useStyles();

  const Accordion = withStyles({
    root: {
      border: "none",
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    expanded: {},
  })(MuiAccordion);

  let query = useQuery();
  function useQuery() {
    const { search } = useLocation();
    let urlItems = `${search}`.split('?')
    urlItems.splice(0, 1);
    const itemObject = {}
    for (const item of urlItems) {
      itemObject[item.split('=')[0]] = item.split('=')[1];
    }
    return itemObject;
  }

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  React.useEffect(() => {
    let rawTabQuery = query["tab"];
    if (rawTabQuery == 'settings') {
      setSelectedIndex(0);
    }
    if (rawTabQuery == 'favorites') {
      setSelectedIndex(1);
    }
    if (rawTabQuery == 'purchases') {
      setSelectedIndex(2);
    }
    if (rawTabQuery == 'tracking') {
      setSelectedIndex(3);
    }
    if (rawTabQuery == 'returns') {
      setSelectedIndex(4);
    }
    if (rawTabQuery == 'supported') {
      setSelectedIndex(5);
    }
    if (rawTabQuery == 'rewards') {
      setSelectedIndex(6);
    }
    if (rawTabQuery == 'reviews') {
      setSelectedIndex(7);
    }
  }, []);

  const [favData, setFavData] = React.useState({
    favorites: [],
    favoritesSearched: [],
  });


  const [reviewData, setReviewData] = React.useState({});

  React.useEffect(() => {
    if (!loadingGetOrders && !userOrderData) {
      setLoadingGetOrders(true)
      Auth.currentAuthenticatedUser().then((user) => {
        getOrdersByUserId(user.attributes.sub, (data) => {
          if (data) {
            setUserOrderData(data);
            // if (data.length >= 1) {
            //   handleSelectedOrderData(data[0]?.orderID);
            // }
          } else {
            console.log("Can't find orders!")
          }
        });
      });
    }
  }, []);

  React.useEffect(() => {
    if (!loadingGetOrderPurposes && userOrderPurposeData.length == 0) {
      setLoadingGetOrderPurposes(true)
      Auth.currentAuthenticatedUser().then((user) => {
        getOrderPurposesByUserId(user.attributes.sub, (data) => {
          if (data) {
            setUserOrderPurposeData(data);
            setLoadedGetOrderPurposes(true);
          } else {
            console.log("Can't find order purposes!")
          }
        });
      });
    }
  }, []);

  React.useEffect(() => {

    getReviewsByUser((data) => {
      setReviewData(data);
    })
  }, []);

  const handleSelectedOrderData = (orderID) => {
    if (!loadingGetSelectedOrder && selectedOrderData?.orderID != orderID) {
      setLoadingGetSelectedOrder(true)
      getOrderById(orderID, (data) => {
        if (data) {
          setSelectedOrderData(data);
        } else {
          console.log("Can't find order!")
        }
        setLoadingGetSelectedOrder(false)
      });
      setShowOrderNumView(true);
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="center"
        // alignItems="center"
        // align="center"
        >
          <Grid item xs={0} md={3} display={{ xs: "none", md: "block" }}>
            <ListItem
            // button
            // selected={selectedIndex === 0}
            // onClick={(event) => handleListItemClick(event, 0)}
            >
              <ListItemText
                primaryTypographyProps={{ variant: "h5" }}
                primary={`${userGivenName}'s Account`}
              />
            </ListItem>
            {/* <ListItem
              button
              component={Link}
              to={`/seller/${"460A6E7A-C579-499A-AB7B-8A8A0593A65C"}/orders`}
            >
              <ListItemText primary={"Seller Dashboard"} />
            </ListItem> */}
            <ListItem
              button
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
            >
              <ListItemText primary="My Settings" />
            </ListItem>

            <ListItem
              button
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
            >
              <ListItemText primary="Favorites" />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}
            >
              <ListItemText primary="Purchases" />
            </ListItem>
            {/* <ListItem
              button
              selected={selectedIndex === 3}
              onClick={(event) => handleListItemClick(event, 3)}
            >
              <ListItemText primary="Tracking & Shipping" />
            </ListItem> */}
            {/* <ListItem
              button
              selected={selectedIndex === 4}
              onClick={(event) => handleListItemClick(event, 4)}
            >
              <ListItemText primary="Returns/Refunds" />
            </ListItem> */}
            <ListItem
              button
              selected={selectedIndex === 5}
              onClick={(event) => handleListItemClick(event, 5)}
            >
              <ListItemText primary="Supported Purposes" />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 6}
              onClick={(event) => handleListItemClick(event, 6)}
            >
              <ListItemText primary="My Rewards" />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 7}
              onClick={(event) => handleListItemClick(event, 7)}
            >
              <ListItemText primary="My Reviews" />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 8}
              onClick={signOut}
            >
              <ListItemText primary="Sign-Out" />
            </ListItem>
          </Grid>

          {/* MOBILE VERSION */}
          <Grid item display={{ xs: "block", md: "none" }}>
            <Accordion>
              <AccordionSummary
                // button
                // selected={selectedIndex === 0}
                // onClick={(event) => handleListItemClick(event, 0)}
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
              >
                <ListItemText
                  primaryTypographyProps={{ variant: "h5" }}
                  primary={`${userGivenName}'s Account`}
                /> <KeyboardArrowDownIcon />
              </AccordionSummary>
              {/* <ListItem
              button
              component={Link}
              to={`/seller/${"460A6E7A-C579-499A-AB7B-8A8A0593A65C"}/orders`}
            >
              <ListItemText primary={"Seller Dashboard"} />
            </ListItem> */}
              <AccordionDetails
                button
                selected={selectedIndex === 0}
                onClick={(event) => handleListItemClick(event, 0)}
              >
                <ListItemText primary="My Settings" />
              </AccordionDetails>

              <AccordionDetails
                button
                selected={selectedIndex === 1}
                onClick={(event) => handleListItemClick(event, 1)}
              >
                <ListItemText primary="Favorites" />
              </AccordionDetails>
              <AccordionDetails
                button
                selected={selectedIndex === 2}
                onClick={(event) => handleListItemClick(event, 2)}
              >
                <ListItemText primary="Purchases" />
              </AccordionDetails>
              <AccordionDetails
                button
                selected={selectedIndex === 5}
                onClick={(event) => handleListItemClick(event, 5)}
              >
                <ListItemText primary="Supported Purposes" />
              </AccordionDetails>
              <AccordionDetails
                button
                selected={selectedIndex === 6}
                onClick={(event) => handleListItemClick(event, 6)}
              >
                <ListItemText primary="My Rewards" />
              </AccordionDetails>
              <AccordionDetails
                button
                selected={selectedIndex === 7}
                onClick={(event) => handleListItemClick(event, 7)}
              >
                <ListItemText primary="My Reviews" />
              </AccordionDetails>
              <AccordionDetails
                button
                selected={selectedIndex === 8}
                onClick={signOut}
              >
                <ListItemText primary="Sign-Out" />
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/* Account Settings Grid Item */}
          <Grid item container xs={12} sm={12} md={9}>
            <Grid item xs container>
              <Grid item xs>


                {selectedIndex === 0 &&
                  //Start
                  <Grid item sx={{ margin: { xs: "0px", sm: "0px", md: "50px" }, }}>
                    <MySettings
                    />
                  </Grid>
                  //End point
                }


                {selectedIndex === 1 && (
                  <Grid item sx={{ margin: { xs: "0px", sm: "0px", md: "50px" }, }}>
                    <Favorites />
                  </Grid>
                )}
                {selectedIndex === 2 && (
                  <Grid item sx={{ margin: { xs: "0px", sm: "0px", md: "50px" }, }}>
                    <AccountPurchasesView handleSelectedOrderData={handleSelectedOrderData} purchasesData={userOrderData} />
                  </Grid>
                )}
                {/* {selectedIndex === 3 && (
                  <div className={classes.rightItemMargin}>
                    <TrackingandShippingView trackShipData={trackShipData} />
                  </div>
                )} */}
                {/* {selectedIndex === 4 && (
                  <div className={classes.rightItemMargin}>
                    <AccountReturnsAndRefundsView
                      returnsAndRefundsData={trackShipData}
                    />
                  </div>
                )} */}
                {selectedIndex === 5 &&
                  <Grid item sx={{ margin: { xs: "0px", sm: "0px", md: "50px" }, }}>
                    <SupportedPurposes userOrderPurposeData={userOrderPurposeData} isLoaded={loadedGetOrderPurposes} />
                  </Grid>}
                {selectedIndex === 6 &&
                  <Grid item sx={{ margin: { xs: "0px", sm: "0px", md: "50px" }, }}>
                    <MyRewards />
                  </Grid>}
                {selectedIndex === 7 && reviewData && reviewData.results && reviewData.results.length > 0 ? (
                  <Grid item sx={{ margin: { xs: "0px", sm: "0px", md: "50px" }, }}>
                    <h1 style={{ textAlign: "center" }}>My Reviews</h1>
                    <Carousel
                      responsive={responsive}
                      infinite={false}
                      arrows={false}
                      draggable={false}
                      className={classes.reviewsCarousel}
                      // renderButtonGroupOutside
                      showDots={true}
                      customButtonGroup={<CustomButtonGroupAsArrows />}
                      customDot={<CustomDots />}
                    >
                      {reviewData.results.map((review, index) => {
                        return <Review review={review} key={index} admin={false} />;
                      })}
                    </Carousel>
                  </Grid>
                ) : (selectedIndex === 7 && (
                  <Typography
                    align="center"
                    className={clsx(classes.noReviewsText, "roboto-regular")}
                  >
                    No Reviews
                  </Typography>
                ))}
                {selectedIndex === 8 && <div>Sign-Out</div>}
              </Grid>
            </Grid>
          </Grid>

          {/* ENd point */}

        </Grid>
        {selectedIndex === 2 && (
          // <Grid item xs={9} className={classes.gridItems}>
          <OrderNumView orderDetails={selectedOrderData} showOrderNumView={showOrderNumView} setShowOrderNumView={setShowOrderNumView} />
          // </Grid>
        )}
      </Container>
      {selectedIndex === 1 && (
        <Grid item xs={12} className={classes.gridItems}>
          <FavoritesSearched
            favoritesSearchedData={favData.favoritesSearched}
          />
        </Grid>
      )}
      {/* {selectedIndex === 5 && (
        <Grid item xs={12} className={classes.gridItems}>
          <SupportedPurposesOther />
        </Grid>
      )} */}
    </>
  );
};

export default AccountDetailView;
