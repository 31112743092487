import { Container, Typography, Divider, Button } from "@mui/material";
import ExpandableQuestionsView from "../../components/ExpandableQuestionsView";
import { PurposeCategoryMasterLayout } from "../../layouts";
import TrackOrderShipment from "../../components/TrackOrderShipment";
import { styled } from "@mui/material/styles";
import returnPolicyFAQs from "../../json/return_policy_questions.json";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useDocumentDescription from "../../hooks/useDocumentDescription";

const Spacer = styled("div")({
  height: "var(--dividerHeight)",
});

const HeaderText = styled(Typography)({
  marginTop: 30,
});

const HeaderImg = styled("img")({
  maxWidth: 110,
});

const DescriptionText = styled(Typography)({
  width: "85%",
  marginTop: 40,
});

const ReturnPolicyLayout = () => {

  useDocumentTitle("We have an easy and straightforward return policy on Purposer.");
  useDocumentDescription("Vendors are responsible for return policies. Learn about vendor return policies by visiting their profiles. We will support you and answer all your questions. Contact us with questions!");

  return (
    <PurposeCategoryMasterLayout>
      <Divider />
      <Container maxWidth="xl" align="center">
        <HeaderText
          variant="h1"
          align="center"
          gutterBottom
          className={"roboto-medium"}
          style={{fontSize: "62px"}}
        >
          Returns Policy
        </HeaderText>
        <HeaderImg
          src={
            "https://d2eudfgh8kpk8j.cloudfront.net/common/thumbs/4687ca20-579b-4190-b593-09aa4abc8232_thumb.png"
          }
          alt="returnPolicyLogo"
        />
        <DescriptionText
          align="center"
          variant="h2"
          classname={"roboto-regular"}
          style={{fontSize: "30px", lineHeight: "1.5"}}
        >
          Each seller on Purposer maintains their own personalized returns policies,
          that are easily found on their seller profiles! Seller have to option to offer you free returns,
          returns with a label paid by the buyer, or in some cases no return policy. We want you to be happy and feel fulfilled with your purchases,
          so we work closely with our vendors to make sure that the product you receive meets your expectations. Always reach out to our team if you have any concerns or questions.
        </DescriptionText>
        <br/>
      <Button href={`/acct?tab=purchases`} sx={ { backgroundColor: "var(--mintGreenSolid)", padding: "12px 30px", borderRadius: 10, width: 250,    "&:hover": { backgroundColor: "var(--coral)" }}}>
        <Typography sx={{ color: "white"}} variant="h6" className={"roboto-medium"}>
          Go to my Returns
        </Typography>
      </Button>
      </Container>
      <Spacer />
      {/* <TrackOrderShipment
        header={"Did you already start a return?"}
        subheader={"Track your Return Status here"}
      /> */}
      <Spacer />
      <Container maxWidth="xl">
        <Typography
          gutterBottom
          variant="h4"
          align="center"
          className={"roboto-medium"}
        >
          <u>FAQs</u>
        </Typography>
      </Container>
      <ExpandableQuestionsView questions={returnPolicyFAQs} />
      <Spacer />
    </PurposeCategoryMasterLayout>
  );
};

export default ReturnPolicyLayout;
