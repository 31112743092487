import { findSmallestPriceItemInArray } from "./utilities";

const handleOptionsFilter = (
  newOption,
  originalProductList,
  setCurrentProductList
) => {
  const filteredItems = originalProductList.filter(o => o.options?.find( c => c.color === newOption || c.size === newOption))

  setCurrentProductList(filteredItems);
};

const handleCategoryFilter = (
  newCategory,
  originalProductList,
  setCurrentProductList
) => {

  const filteredItems = originalProductList.filter(o => o.categories?.find( c => c.productCategoryID === newCategory))

  setCurrentProductList(filteredItems);
};

const handlePurposeFilter = (
  newPurpose,
  originalProductList,
  setCurrentProductList
) => {
  const filteredItems = originalProductList.filter(o => o.purposes?.find( c => c.productPurposeID === newPurpose))

  setCurrentProductList(filteredItems);
};

const handleSortFilter = (
  newValue,
  currentProductList,
  setCurrentProductList
) => {
  let newProductList = [];
  // Logic to actually sort
  switch (newValue) {
    case "popular":
      // Logic to select most popular
      break;
    case "highToLow":
      // Logic to sort products by price highest to lowest
      newProductList = [].concat(currentProductList);
      setCurrentProductList(
        newProductList.sort((product1, product2) => {

          const product1LowestPrice = findSmallestPriceItemInArray(product1.options)
          const product2LowestPrice = findSmallestPriceItemInArray(product2.options)
          return product2LowestPrice?.price - product1LowestPrice?.price;
        })
      );
      break;
    case "lowToHigh":
      // Logic to sort products by price lowest to highest
      newProductList = [].concat(currentProductList);
      setCurrentProductList(
        newProductList.sort((product1, product2) => {
          const product1LowestPrice = findSmallestPriceItemInArray(product1.options)
          const product2LowestPrice = findSmallestPriceItemInArray(product2.options)
          return product1LowestPrice?.price - product2LowestPrice?.price;
        })
      );
      break;
    case "sale":
      // Logic to select products on sale
      break;
    default:
  }
};

export { handleCategoryFilter, handleSortFilter, handlePurposeFilter, handleOptionsFilter };
