import { Box } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ManagementMasterLayout } from "..";
import OrderReturnsRequestedView from "../../components/OrderReturnsRequestedView";
import OrderReturnsCompletedView from "../../components/OrderReturnsCompletedView";
import sellerDashboardJsonData from "../../json/seller_dashboard.json";
import {  getOrdersBySellerId } from "../../util/orders";
import { refundByReturnId } from "../../util/returns";
import { RETURN_STATUS } from "../../util/constants";
import toast, { Toaster } from 'react-hot-toast';

const OrderReturnsLayout = () => {
  const sellerDashboardData = sellerDashboardJsonData;

  const [returnRequestedOrders, setReturnRequestedOrders] = React.useState(null);
  const [returnCompletedOrders, setReturnCompletedOrders] = React.useState(null);
  const [returnDeniedOrders, setReturnDeniedOrders] = React.useState(null);

  const [loadingGetOrders, setLoadingGetOrders] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [filterRequested, setFilterRequested] = useState('Newest First');
  const [filterCompleted, setFilterCompleted] = useState('Newest First');
  const [filterDenied, setFilterDenied] = useState('Newest First');

  const { id: sellerIDparam } = useParams();

  function handleSortOrderReturns(orderArrayToSort) {
    const ordersWithReturnRequests = orderArrayToSort.filter(o => o.refundRequests.length > 0);

    const ordersPendingReturn = [];
    const ordersCompletedReturn = [];
    const ordersDeniedReturn = [];
    for (const currOrder of ordersWithReturnRequests) {
      for (const currRefundRequest of currOrder.refundRequests) {
        const currOrderClone = JSON.parse(JSON.stringify(currOrder));
        delete currOrderClone.refundRequests;
        currRefundRequest.order = currOrderClone;
        if (currRefundRequest.status == RETURN_STATUS.PENDING) {
          ordersPendingReturn.push(currRefundRequest);
        } else if (currRefundRequest.status == RETURN_STATUS.COMPLETED) {
          ordersCompletedReturn.push(currRefundRequest);
        } else if (currRefundRequest.status == RETURN_STATUS.DENIED) {
          ordersDeniedReturn.push(currRefundRequest);
        } 
      }
    }

    //Sort newest first by return request datetime
    ordersPendingReturn.sort((a, b) =>  { return new Date(b.requestDateTime) - new Date(a.requestDateTime) });
    ordersCompletedReturn.sort((a, b) =>  { return new Date(b.requestDateTime) - new Date(a.requestDateTime) });
    ordersDeniedReturn.sort((a, b) =>  { return new Date(b.requestDateTime) - new Date(a.requestDateTime) });

    setReturnRequestedOrders(ordersPendingReturn);
    setReturnCompletedOrders(ordersCompletedReturn);
    setReturnDeniedOrders(ordersDeniedReturn);
  }

  function markLocalReturnAsComplete(returnID) {
    const tmpAllReturnOrders = [...returnRequestedOrders, ...returnCompletedOrders];
    const returnRequestChanging = tmpAllReturnOrders.find(r => r.returnID == returnID);
    returnRequestChanging.status = RETURN_STATUS.COMPLETED;
    const newReturnRequestedOrders = tmpAllReturnOrders.filter(r => r.status == RETURN_STATUS.PENDING)
    const newReturnCompletedOrders = tmpAllReturnOrders.filter(r => r.status == RETURN_STATUS.COMPLETED)
    setReturnRequestedOrders(newReturnRequestedOrders);
    setReturnCompletedOrders(newReturnCompletedOrders);

    handleFilterRequested(filterRequested);
    handleFilterCompleted(filterCompleted);
  }

  React.useEffect(() => {
    if (!loadingGetOrders && !returnRequestedOrders && !returnCompletedOrders) {
      setLoadingGetOrders(true)
      getOrdersBySellerId(sellerIDparam, (data) => {
        if (data) {
          handleSortOrderReturns(data);
          handleFilterRequested(filterRequested);
          handleFilterCompleted(filterCompleted);
        } else {
          console.log("Can't find orders!")
        }
      });
    }
  }, []);

  const handleRefundByReturnId = async (e, returnID) => {
    if (!isLoading) {
        e.preventDefault();
        setIsLoading(true);
        console.log('Sending Refund to refund id: ', returnID)
        refundByReturnId(sellerIDparam, returnID, (data) => {
          if (data.error || data?.results == false || data?.results?.success == false) {
              console.log('Error Submitting Refund!')
              setIsLoading(false);
          } else {
              console.log('Success Submitting Refund!')
              markLocalReturnAsComplete(returnID)
              setIsLoading(false);
              window.location.reload()
          }
      });
    }
  }

  function handleFilterRequested(filter) {
    if (filter && returnRequestedOrders?.length > 0) {
      setFilterRequested(filter);
      console.log('Applying new filter to requested returns: ', filter)
      const newReturnRequestedOrders = [...returnRequestedOrders];
      if (filter == 'Newest First') {
        newReturnRequestedOrders.sort((a, b) =>  { return new Date(b.requestDateTime) - new Date(a.requestDateTime) });
      } else if (filter == 'Oldest First') {
        newReturnRequestedOrders.sort((b, a) => { return new Date(b.requestDateTime) - new Date(a.requestDateTime) });
      }
      setReturnRequestedOrders(newReturnRequestedOrders);
    }
  }

  function handleFilterCompleted(filter) {
    if (filter && returnCompletedOrders?.length > 0) {
      setFilterCompleted(filter);
      console.log('Applying new filter to completed returns: ', filter)
      const newReturnCompletedOrders = [...returnCompletedOrders];
      if (filter == 'Newest First') {
        newReturnCompletedOrders.sort((a, b) =>  { return new Date(b.requestDateTime) - new Date(a.requestDateTime) });
      } else if (filter == 'Oldest First') {
        newReturnCompletedOrders.sort((b, a) => { return new Date(b.requestDateTime) - new Date(a.requestDateTime) });
      }
      setReturnCompletedOrders(newReturnCompletedOrders);
    }
  }

  function handleFilterDenied(filter) {
    if (filter && returnDeniedOrders?.length > 0) {
      setFilterDenied(filter);
      console.log('Applying new filter to denied returns: ', filter)
      const newReturnDeniedOrders = [...returnDeniedOrders];
      if (filter == 'Newest First') {
        newReturnDeniedOrders.sort((a, b) =>  { return new Date(b.requestDateTime) - new Date(a.requestDateTime) });
      } else if (filter == 'Oldest First') {
        newReturnDeniedOrders.sort((b, a) => { return new Date(b.requestDateTime) - new Date(a.requestDateTime) });
      }
      setReturnDeniedOrders(newReturnDeniedOrders);
    }
  }

  return (
    <ManagementMasterLayout
      title="Order Returns"
      sellerName={sellerDashboardData.name}
      sellerPicture={sellerDashboardData.pic}
    >
      <Toaster 
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <Box sx={{ my: 5, mx: 2 }}>
        <OrderReturnsRequestedView
          orderData={returnRequestedOrders}
          filter={filterRequested}
          handleFilter={handleFilterRequested}
          isLoading={isLoading}
          handleRefundByReturnId={handleRefundByReturnId}
          title="Requested Returns"
        />
      </Box>
      <Box sx={{ mb: 5, mx: 2 }}>
        <OrderReturnsCompletedView
          orderData={returnCompletedOrders}
          filter={filterCompleted}
          handleFilter={handleFilterCompleted}
          isLoading={isLoading}
          handleRefundByReturnId={handleRefundByReturnId}
          title="Completed Returns"
        />
      </Box>
      <Box sx={{ mb: 5, mx: 2 }}>
        <OrderReturnsCompletedView
          orderData={returnDeniedOrders}
          filter={filterDenied}
          handleFilter={handleFilterDenied}
          isLoading={isLoading}
          // handleRefundByReturnId={handleRefundByReturnId}
          title="Denied Returns"
        />
      </Box>
    </ManagementMasterLayout>
  );
};

export default OrderReturnsLayout;
