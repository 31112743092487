import { useEffect, useState } from "react";
import { ManagementMasterLayout } from "..";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Box,
  ButtonBase,
  Typography,
  Grid,
  Modal,
  CircularProgress,
  Stack,
  Button,
} from "@mui/material";
import { getProductById } from "../../util/getProductsById";
import {
  deactivateProductListing,
  updateProductListing,
} from "../../util/listingFunctions";
import ListingLayout from "./ListingLayout";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { constructProductVariationSize, deconstructProductVariationSize, getSizeInMegaBytes, hasSubstring } from "../../util/utilities";
import { PRODUCT_SIZES, PRODUCT_SIZE_TYPE, PRODUCT_LENGTH_UNITS, SHIPPING_CHARGE_TYPES, STRIPE_TAX_CATEGORIES } from "../../util/constants";
import { uploadImageArray } from "../../util/images";

const EditListingLayout = () => {
  const userAttributes = useSelector((state) => state.user.user);
  const { id: sellerIDparam, listing } = useParams();
  const history = useHistory();
  const [formValues, setFormValues] = useState({
    productID: "",
    productName: "",
    itemCategory: [{ categoryName: "" }],
    itemSubCategories: [],
    itemPurpose: [{ purposeName: "" }],
    itemSubPurpose: [],
    details: "",
    typeOfProduct: "",
    itemVariations:[
      {}
    ],
    tags: [],
    productImageURL: [],
    shippingUpgrades: [], 
    // shippingUpgrades: [{
    //   shippingName: "",
    //   service: "",
    //   oneItemPrice: 0,
    //   addlItemPrice: 0,
    // }],
    shippingChargeType: "",
    shippingService: "",
    shippingOneItemPrice: 0,
    shippingAddlItemPrice: 0,
    customizable: false,
    customInstructions: "",
    freeShipping: false,
    taxCategory: null,
    purposeDesc: "",
    // Seller name should probably be grabbed by login and then assigned here for now this is ok
    seller: {},
  });
  const [isUploading, setIsUploading] = useState(false);
  const [isRequesting, setIsRequesting] = useState(true);
  const [requestFailed, setRequestFailed] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const createToastFromArray = (wordArray) => {
    const allText = wordArray.join('\n');
    toast((t) => (
      <span>
        {allText}
        {'\n'}
        <Grid container justifyContent="center">
          <Button onClick={() => toast.dismiss(t.id)}>
            Dismiss
          </Button>
        </Grid>
      </span>
    ));
  }

  const handleFormValues = (name, value) => {
    setFormValues((preValues) => {
      return { ...preValues, [name]: value };
    });
  };

  const uploadImagesAndUpdateFormData = async (imageArray, formDataValueName, handleFormData) => {
    const imgUrlArray = await uploadImageArray(imageArray);
    handleFormData(formDataValueName, imgUrlArray);
    return imgUrlArray;
  }

  const updateListing = async () => {
    setIsUploading(true);

    //Payments info
    const productName = formValues.productName;
    const imageUrls = formValues.productImageURL;
    const typeOfProduct = formValues.typeOfProduct;
    const shippingChargeType = formValues.shippingChargeType;
    const shippingService = formValues.shippingService;
    const shippingOneItemPrice = formValues.shippingOneItemPrice;
    const shippingAddlItemPrice = formValues.shippingAddlItemPrice;
    const shippingUpgrades = formValues.shippingUpgrades;
    const freeShipping = shippingOneItemPrice <= 0 ? true : false;
    const details = formValues.details;
    const taxCode = formValues.taxCategory?.taxCode;

    let allSubPurposes = [];
    let allSubCategories = [];
    formValues.itemSubPurpose.forEach(function(sp) {
      allSubPurposes = [...allSubPurposes, ...sp.filter(p => p.purposeName) ];
    });
    formValues.itemSubCategories.forEach(function(sc) {
      allSubCategories = [...allSubCategories, ...sc.filter(p => p.categoryName) ];
    });
    const purposes = [...formValues.itemPurpose.filter(p => p.purposeName), ...allSubPurposes];
    const categories = [...formValues.itemCategory.filter(c => c.categoryName), ...allSubCategories];
    let errorsInSubmission = []
    const variations = JSON.parse(JSON.stringify(formValues.itemVariations));
    for (const variation of variations) {
      constructProductVariationSize(variation);
      if (variation.size == null) {
        errorsInSubmission.push("Product Variations must be fully filled out!")
      }
    }
    
    const options = variations.filter(obj => obj.color != null && obj.size != null && obj.price != null && obj.inventory != null);
    const badOptions = variations.filter(obj => obj.color == null || obj.color == '' || obj.size == null | obj.size == '' || obj.price == null || obj.price == '' || isNaN(obj.price) || obj.inventory == null || obj.inventory === '');
    const badInventoryOptions = variations.filter(obj => obj.inventory == null || obj.inventory === '' || obj.inventory > 9999 || obj.inventory < 0);

    //function to make sure each purpose has a subpurpose
    const checkSubPurpose = (purpose) => {
      let hasSubPurpose = false;
      for (const subPurpose of allSubPurposes) {
        if (subPurpose.higherPurposeID === purpose.productPurposeID) {
          hasSubPurpose = true;
          break;
        }
      }
      return hasSubPurpose;
    }

    //function to make sure each category has a subcategory
    const checkSubCategory = (category) => {
      let hasSubCategory = false;
      for (const subCategory of allSubCategories) {
        if (subCategory.higherCategoryID === category.productCategoryID) {
          hasSubCategory = true;
          break;
        }
      }
      return hasSubCategory;
    }

    formValues.itemPurpose.filter(p => p.purposeName).forEach(function(purpose) {
      if (!checkSubPurpose(purpose)) {
        errorsInSubmission.push("Each purpose must have a subpurpose!");
      }
    });

    formValues.itemCategory.filter(c => c.categoryName).forEach(function(category) {
      if (!checkSubCategory(category) && category.productCategoryID != 0) {
        errorsInSubmission.push("Each category must have a subcategory!");
      }
    });

    const productActiveStatus = formValues.activeStatusString === "Active" ? true : false;
    
    //Require the basics for stripe no matter the active status of an item (could be a draft, etc)
    if (!productName) errorsInSubmission.push("Product must have a name!");
    if (formValues.productName.length > 150) errorsInSubmission.push("Product title needs to be between 20-150 characters.");
    if (badOptions.length > 0) errorsInSubmission.push("Product variation(s) were found with incomplete filled out fields!");
    if (badInventoryOptions.length > 0) errorsInSubmission.push("Product variation(s) must have an inventory between 0 and 9999!");
    if (!options || options.length < 1) errorsInSubmission.push("Product must have atleast one variation with a color, size, price, and inventory!");
    if (imageUrls?.length > 20) errorsInSubmission.push("Must have less than 20 product images!");

    //If the product is active, require the rest of the fields
    if(productActiveStatus) {
      if (!imageUrls || imageUrls.length < 1) errorsInSubmission.push("Must have a product image!");
      if (!typeOfProduct) errorsInSubmission.push("Must select a Product Type!");
      if (!details) errorsInSubmission.push("Product must have a Description!");
      if (!purposes || purposes.length < 1) errorsInSubmission.push("Product must have atleast one purpose!");
      if (!categories || categories.length < (1 + categories.find(ic => ic.productCategoryID == 0) ? 1 : 0)) errorsInSubmission.push("Product must have atleast one category!");
      if (!shippingService || !shippingChargeType || shippingOneItemPrice == null || shippingAddlItemPrice == null) errorsInSubmission.push("Product must have all standard shipping information filled!");
      if (shippingUpgrades.length > 0 && shippingUpgrades?.find(u => !u.shippingName || !u.service || u.oneItemPrice == null || u.oneItemPrice === '' || u.addlItemPrice == null || u.addlItemPrice === '')) errorsInSubmission.push("Product upgrades must have all shipping information filled!");
      if (formValues.details.length > 4000 || formValues.details.length < 450) errorsInSubmission.push("Product details need to be between 450 and 4,000 characters.");
      if (!formValues.purposeDesc || formValues.purposeDesc?.length > 280 || formValues.purposeDesc?.length < 80) errorsInSubmission.push("Product purpose description needs to be between 80 and 280 characters.");
      if (formValues.tags?.length > 50) errorsInSubmission.push("Maximum of 50 product tags or keywords allowed.");
      if (!formValues.tags || formValues.tags?.length < 10) errorsInSubmission.push("Minimum of 10 product tags or keywords must be added.");
      if (formValues.customizable && formValues.customInstructions?.length > 280) errorsInSubmission.push("Maximum of 280 characters for custom instructions.")
  }
    
    if (errorsInSubmission.length > 0) {
      createToastFromArray(errorsInSubmission);
      setIsUploading(false);
      return;
    } 
    else {

      let processedProductImageURL;
      try {
        processedProductImageURL = await uploadImagesAndUpdateFormData(formValues.productImageURL, "productImageURL", handleFormValues);
      } catch (error) {
        console.log(error);
      }
      if (!processedProductImageURL || processedProductImageURL.length < 1) {
        //If the product is active, require images, otherwise keep going
        if (productActiveStatus) {
          errorsInSubmission.push('Failed to upload images!');
          createToastFromArray(errorsInSubmission);
          setIsUploading(false);
          return;
        } else {
          processedProductImageURL = [];
        }
      }

      let apiReqBody = formValues;
      apiReqBody.purposes = purposes;
      apiReqBody.categories = categories;
      apiReqBody.productImageURL = processedProductImageURL;
      apiReqBody.options = options;
      apiReqBody.freeShipping = freeShipping;
      apiReqBody.taxCode = taxCode;
      apiReqBody.active = productActiveStatus;

      if (getSizeInMegaBytes(apiReqBody) > 5) {
        const errorMsg = ["Total size of images exceeds 5MB! Try uploading smaller images. (or downscaling and re-uploading the ones you have)."];
        createToastFromArray(errorMsg);
        setIsUploading(false);
        return;
      }

      updateProductListing(apiReqBody, sellerIDparam, (data) => {
        if (data) history.push(`/seller/${formValues.seller.sellerID ? formValues.seller.sellerID : undefined}/listings`);
        setIsUploading(false);
      });
    }
  };

  const deleteListingButton = () => {
    setDeleteModal(true);
  };
  const closeDeleteListing = () => {
    setDeleteModal(false);
  };

  const deleteListing = () => {
    deactivateProductListing(formValues.productID, sellerIDparam, (data) => {
        if (data?.results?.success == true) {
          history.push(`/seller/${sellerIDparam}/listings`);
        }
        setDeleteModal(false);
      }
    );
  };

  useEffect(() => {
    if (isRequesting) {
      getProductById(listing, (data) => {
        // console.log(data);
        if (data === "failed") {
          setRequestFailed(true);
          return;
        } else {
          const product = data[0];
          if (!Array.isArray(product.productImageURL)) {
            product.productImageURL = [product.productImageURL];
          }
          const mainPurposes = product.purposes.filter(p => p.higherPurposeID == -1);
          const subPurposes = [];
          const mainCategories = product.categories.filter(c => c.higherCategoryID == -1);
          const subCategories = [];
          mainPurposes.forEach(function(p) {
            const subPurposeArray = [...product.purposes.filter(pu => pu.higherPurposeID == p.productPurposeID)];
            subPurposes.push(subPurposeArray)
          });
          mainCategories.forEach(function(c) {
            const subCategoryArray = [...product.categories.filter(ca => ca.higherCategoryID == c.productCategoryID)];
            subCategories.push(subCategoryArray)
          });
          if (mainPurposes.length === 0) {
            mainPurposes.push({ purposeName: "" })
          }
          if (mainCategories.length === 0) {
            mainCategories.push({ categoryName: "" })
          }
          product.taxCategory = STRIPE_TAX_CATEGORIES.find(t => t.taxCode == product.stripeTaxCode);
          product.itemVariations = product.options;
          product.itemPurpose = mainPurposes;
          product.itemSubPurpose = subPurposes;
          product.itemCategory = mainCategories;
          product.itemSubCategories = subCategories;
          product.shippingChargeType = product.shippingOneItemPrice == 0 && product.shippingAddlItemPrice == 0 ? SHIPPING_CHARGE_TYPES[1] : SHIPPING_CHARGE_TYPES[0];
          product.activeStatusString = product.active ? "Active" : "Inactive";

          for (const variation of product.itemVariations) {
            variation.price = variation.originalPrice;
            deconstructProductVariationSize(variation);
          }
          setFormValues(product);
          setIsRequesting(false);
        }
      });
    }
    return () => {};
  }, [listing]);

  return (
    <ManagementMasterLayout
      title={"Edit Listing"}
    >
      <Toaster 
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <ListingLayout formValues={formValues} setFormValues={setFormValues} toast={toast} />
      <Grid
        container
        justifyContent={"center"}
        rowSpacing={2}
        columnSpacing={2}
        sx={{ my: 5 }}
      >
        <Grid item>
          <Button
            onClick={updateListing}
            disabled={formValues.activeStatusString !== 'Inactive'} 
            variant="contained" 
            style={{ backgroundColor: "var(--coral)" }}
          >
            <b>Save For Later</b>
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={updateListing}
            disabled={formValues.activeStatusString !== 'Active'} 
            variant="contained" 
            style={{ backgroundColor: "var(--coral)" }}
          >
            <b>Update Listing</b>
          </Button>
        </Grid>
      </Grid>
      <Modal open={isUploading} sx={{ overflow: "scroll" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 2,
            bgcolor: "background.paper",
            p: 4,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography 
            variant="h6" 
            gutterBottom
            sx={{ textAlign: "center" }}
          >
            {`We're editing your product! So exciting! This may take 2-3 minutes.`}
            <br/>
            {`Please DO NOT refresh or leave this page. Thank you.`}
          </Typography>
          <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
        </Box>
      </Modal>
      <Modal
        open={deleteModal}
        onClose={closeDeleteListing}
        sx={{ overflow: "scroll" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "max(75%, 300px)",
            bgcolor: "background.paper",
            border: "1px solid #000",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography align="center" variant="h4">
            Are you sure you want to deactivate this listing?
          </Typography>
          <Typography variant="h6" sx={{ mb: 3 }}>
            <b>You can reactivate anytime.</b>
          </Typography>
          <Stack direction={"row"} spacing={2}>
            <ButtonBase
              sx={{
                bgcolor: "red",
                py: 1,
                px: 2.5,
                borderRadius: 2.5,
                color: "white",
              }}
              onClick={deleteListing}
            >
              <b>Deactivate</b>
            </ButtonBase>
            <ButtonBase
              sx={{
                border: "1px solid black",
                py: 1,
                px: 2.5,
                borderRadius: 2.5,
              }}
              onClick={closeDeleteListing}
            >
              <b>Cancel</b>
            </ButtonBase>
          </Stack>
        </Box>
      </Modal>
      <Modal open={isRequesting} sx={{ overflow: "scroll" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 2,
            bgcolor: "background.paper",
            p: 4,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!requestFailed ? (
            <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography>Failed retrieving listing to edit</Typography>
              <Button
                component={Link}
                to={`/seller/${sellerIDparam}/listings`}
                color="error"
              >
                go back to listings
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </ManagementMasterLayout>
  );
};

export default EditListingLayout;
