import {
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchLogo from "../img/bulb4.png";
import mockToolsAndTipsData from "../json/tools_and_tips.json";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles({
  toolButton: {
    backgroundColor: "#F2E9DA",
    textTransform: "none",
    width: "100%",
    minWidth: 245,
    borderRadius: 15,
    minHeight: 65,
    scrollBehavior: "smooth",
    color: "#000000",
    "&:hover": {
      backgroundColor: "#F2E9DA",
    },
  },
  searchField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 50,
      backgroundColor: "var(--greyTextfield)",
      color: "#000000",
      height: 40,
      borderStyle: "none",
      borderWidth: 0,
      "& fieldset": {
        borderColor: "var(--greyTextfield)",
        borderStyle: "none",
      },
      "&:hover fieldset": {
        borderColor: "var(--greyTextfield)",
        borderStyle: "none",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--greyTextfield)",
        borderStyle: "none",
      },
    },
  },
  searchLogo: {
    paddingTop: 5,
    height: 25,
  },
});

const ContainerWrapper = styled("div")({
  paddingTop: 20,
  paddingBottom: 100,
});

const TopContainer = styled(Container)({
  paddingBottom: 60,
});

const SellerStoreComputer = styled("img")({
  maxWidth: "100%",
  marginTop: 80,
});

const elementIds = [
  "addingNewProducts",
  "shippingToCustomers",
  "marketingAndSales",
  "productPhotos",
  "sellersFees",
  "marketingAndSales",
];

function ToolsAndTips(props) {
  const classes = useStyles();

  let { toolsAndTipsData = mockToolsAndTipsData } = props;
  let tools = toolsAndTipsData.tools;

  return (
    <ContainerWrapper>
      <TopContainer maxWidth="lg">
        <Typography
          align="center"
          variant="h3"
          gutterBottom
          className={"roboto-bold"}
        >
          Tools &amp; Tips
        </Typography>
        <Grid container direction="row" columnSpacing={10} rowSpacing={6}>
          {tools &&
            tools.map((tool, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Button
                    variant="contained"
                    disableElevation
                    className={classes.toolButton}
                    onClick={() => {
                      const element = document.getElementById(
                        elementIds[index]
                      );
                      element.scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    <Typography variant="h6" className={"roboto-light"}>
                      {tool.name}
                    </Typography>
                  </Button>
                </Grid>
              );
            })}
        </Grid>
      </TopContainer>
      <Container maxWidth="lg">
        {/* <Typography
          align="center"
          variant="h6"
          className={"roboto-light-italic"}
        >
          Have another question? Type it here!
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          className={classes.searchField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <a href="https://www.purposer.com">
                  <img src={SearchLogo} alt="" className={classes.searchLogo} />
                </a>
              </InputAdornment>
            ),
          }}
        /> */}
        <SellerStoreComputer
          src={"https://d2eudfgh8kpk8j.cloudfront.net/common%2F75853857.png"}
          alt="seller_store_computer"
        />
      </Container>
    </ContainerWrapper>
  );
}

export default ToolsAndTips;
