import { Avatar, Grid, Typography, Container, Box, ButtonBase } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState, useEffect } from "react";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";
import clsx from "clsx";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  imageAvatar: {
    width: "50%",
    height: "50%",
    margin: "auto",

  },
  imageAvatarHomepage: {
    width: "80%",
    height: "80%",
    margin: "auto",
  },
  container: {
    width: "80%",
    margin: "auto",
    marginTop: 13,
  },
  categoryText: {
    // fontWeight: 400,
    textAlign: "center",
    marginTop: 0,
    fontSize: "16px"
  },
  text: {
    fontWeight: 400,
    fontSize: "20px",
    textShadow: "2px 3px 5px rgba(0,0,0,0.2)",
    textAlign: "center",
  },
  subtext: {
    fontWeight: 400,
    marginTop: 5,
    fontSize: "16px",
    textShadow: "2px 3px 5px rgba(0,0,0,0.2)",
  },
  mintGreenBackground: {
    backgroundColor: "#E8F0EC",
    height: "9rem",
    width: "100%",
    marginTop: theme.spacing(-1),
  },
  centeredText: {
    // textAlign: "center",
    //left centerd
    position: "absolute",
    // top: "50%",
    left: "9%",
    marginTop: theme.spacing(3),
  },
  purposeMargins: {
    marginLeft: theme.spacing(1),
  },
  divider: {
    height: "5px",
  },
  divContainer: {
    marginTop: theme.spacing(0),
  },
}));

const responsive = {
  break1: {
    breakpoint: { max: 4000, min: 1297 },
    items: 5,
  },
  break2: {
    breakpoint: { max: 1297, min: 900 },
    items: 4,
  },
  break3: {
    breakpoint: { max: 900, min: 0 },
    items: 3,
  },
};

const PurposeBubble = ({ classes, item, homePage }) => (
  <Grid item xs>
    <a href={"/shop/purpose/" + item.id + "/" + item.name.replace(/\s/g, '-')}>
      <div className={classes.container}>
        <Avatar
          className={
            homePage ? classes.imageAvatarHomepage : classes.imageAvatar
          }
          src={item.img}
          alt={item.name}
        />
      </div>
      <Typography 
      className={clsx(
        classes.categoryText,
        "roboto-light"
      )}>{item.name}</Typography>
    </a>
  </Grid>
);

function EllipsesGraphic() {
    const size = 5;
    const color = 'white';
  return (
    <Box 
        display="flex" 
        alignItems="center" 
        justifyContent="center" 
        // height="100vh" // assuming you want to center it in the viewport
        gap={1}
    >
          <Avatar sx={{ width: size, height: size }} style={{ backgroundColor: color }}>{''}</Avatar>
          <Avatar sx={{ width: size, height: size }} style={{ backgroundColor: color }}>{''}</Avatar>
          <Avatar sx={{ width: size, height: size }} style={{ backgroundColor: color }}>{''}</Avatar>
      </Box>
  );
}

const PURPOSE_DATA = [
  {
    "id": 60,
    "higherPurposeID": -1,
    "name": "Sustainability",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Eco-Friendly.jpg",
    "description": "Reduce waste, shop sustainably",
  },
  {
    "id": 70,
    "higherPurposeID": -1,
    "name": "End Poverty",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/end-poverty-new.png",
    "description": null,
  },
  {
    "id": 80,
    "higherPurposeID": -1,
    "name": "Healthy Living",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Healthy+Living.jpg",
    "description": null,
  },
  {
    "id": 100,
    "higherPurposeID": -1,
    "name": "Community Support",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Community+Support.jpg",
    "description": null,
  },
  {
    "id": 110,
    "higherPurposeID": -1,
    "name": "Faith Life",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Faith+Life.jpg",
    "description": null,
  },
  {
    "id": 90,
    "higherPurposeID": -1,
    "name": "Animals and Wildlife",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Animals+%26+Wildlife.jpg",
    "description": null,
  },
];

const PURPOSE_MOBILE_DATA = [
  {
    "id": 60,
    "higherPurposeID": -1,
    "name": "Sustainability",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Eco-Friendly.jpg",
    "description": "Reduce waste, shop sustainably",
  },
  {
    "id": 70,
    "higherPurposeID": -1,
    "name": "End Poverty",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/end-poverty-new.png",
    "description": null,
  },
  {
    "id": 80,
    "higherPurposeID": -1,
    "name": "Health",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Healthy+Living.jpg",
    "description": null,
  },
  {
    "id": 100,
    "higherPurposeID": -1,
    "name": "Community",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Community+Support.jpg",
    "description": null,
  },
  {
    "id": 110,
    "higherPurposeID": -1,
    "name": "Faith Life",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Faith+Life.jpg",
    "description": null,
  },
  {
    "id": 90,
    "higherPurposeID": -1,
    "name": "Wildlife",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Animals+%26+Wildlife.jpg",
    "description": null,
  },
];

const PurposeCategoryMobileView = ({ homePage = false }) => {
  const classes = useStyles();
  const mobile = useMobileMediaQuery();

  const CustomButtonGroupAsArrows = ({ next, previous }) => {
    return (
      <>
        {/* <ButtonBase
          className={clsx(
            mobile ? classes.mobileNavButtons : classes.navButtons,
            classes.prevButton
            )}
            onClick={previous}
            >
          <ArrowBack className={classes.arrows} />
        </ButtonBase>
        <ButtonBase
          className={clsx(
            mobile ? classes.mobileNavButtons : classes.navButtons,
            classes.nextButton
            )}
            onClick={next}
            >
          <ArrowForward className={classes.arrows} />
        </ButtonBase> */}
      </>
    );
  };

  const Bubble = ({ item }) => {
    
    const itemNameWithAndReplace = item.name?.replace('and', '&');
    const words = itemNameWithAndReplace?.split(' ');
    const firstTwoWords = words?.slice(0, 2)?.join(' ');
    const restOfWords = words?.slice(2)?.join(' ');

    return (
    <Box>
      <a href={"/shop/category/" + item.id + "/" + encodeURI(item.name)}>
        <Typography
          className={clsx(
            mobile ? classes.mobileText : classes.text,
            "roboto-light"
          )}
          sx={{ marginTop: restOfWords?.length > 0 ? "0px" : "10px" }}
        >
          {firstTwoWords}
        </Typography>
        {restOfWords?.length > 0 && (<Typography
          className={clsx(
            mobile ? classes.mobileText : classes.text,
            "roboto-light"
          )}
          sx={{ marginTop: "-8px"}}
        >
          {restOfWords}
        </Typography>)}
      </a>
    </Box>
  )};

  const BubbleCarousel = ({ data }) => (
    <div className={classes.divContainer}>
      <Carousel
        responsive={responsive}
        infinite={true}
        arrows={false}
        renderButtonGroupOutside={true}
        customButtonGroup={<CustomButtonGroupAsArrows />}
        containerClass={classes.carouselContainer}
      >
        {data.map((item, i) => {
          return <PurposeBubble key={i} item={item} classes={classes} />;
        })}
      </Carousel>
    </div>
  );

  return (
    <div className={classes.mintGreenBackground}>
      <Container maxWidth="xl">
      <div className={classes.divider}/>
      <div className={classes.divider}/>
        <Typography className={classes.text}>
          Shop by purpose.
        </Typography>
        <BubbleCarousel data={PURPOSE_MOBILE_DATA} />
      </Container>
      <div className={classes.divider}/>
      <EllipsesGraphic />
    </div>
  );
};

export default PurposeCategoryMobileView;
