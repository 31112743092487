/*
 * Module that contains functions and a component for file uploading
 */

const UPLOAD_ERROR = {
  NOT_SUPPORTED_EXTENSION: "NOT_SUPPORTED_EXTENSION",
  FILESIZE_TOO_LARGE: "FILESIZE_TOO_LARGE",
  TOTALFILESIZE_TOO_LARGE: "TOTALFILESIZE_TOO_LARGE",
};

function hasExtension(fileName, filetypes) {
  const pattern = "(" + filetypes.join("|").replace(/\./g, "\\.") + ")$";
  return new RegExp(pattern, "i").test(fileName);
}

/*
  Read a file and return a promise that when resolved gives the file itself and
  the data URL
*/
function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Read the image via FileReader API and save image result in state.
    reader.onload = function (e) {
      // Add the file name to the data URL
      let dataURL = e.target.result;
      dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
      resolve({ file, dataURL });
    };

    reader.readAsDataURL(file);
  });
}

function fileChangedHandler(
  files,
  setError,
  property,
  maxFiles,
  filetypes,
  maxFileSize,
  updateState,
  multiple,
  coverPhotos
) {
  const allFilePromises = [];
  setError(null);

  if (coverPhotos != null && coverPhotos.length > maxFiles)
  {
    return;
  }
  
  // Iterate over all uploaded files
  var allowableFiles;
  if (maxFiles && coverPhotos && coverPhotos.length){
    allowableFiles = maxFiles - coverPhotos.length
  } else if (maxFiles) {
    allowableFiles = maxFiles
  } else {
    allowableFiles = 1;
  }
  var totalSize = 0
  for (let x = 0; x < files.length; x++)
  {
    totalSize += files[x].size;
  }
  for (let i = 0; i < files.length && allFilePromises.length < maxFiles; i++) {
    if (i + 1 > allowableFiles)
     {
      continue;
     }
    let file = files[i];
    let fileError = {
      name: file.name,
    };
    // Check for file extension
    if (!hasExtension(file.name, filetypes)) {
      fileError = Object.assign(fileError, {
        type: UPLOAD_ERROR.NOT_SUPPORTED_EXTENSION,
      });
      setError(fileError);
      return;
    }
    
    if (file.size > maxFileSize) {
      fileError = Object.assign(fileError, {
        type: UPLOAD_ERROR.FILESIZE_TOO_LARGE,
      });
      setError(fileError);
      return;
    }
    // Check for total files size
    if (totalSize > maxFileSize) {
      fileError = Object.assign(fileError, {
        type: UPLOAD_ERROR.TOTALFILESIZE_TOO_LARGE,
      });
      setError(fileError);
      return;
    }

    allFilePromises.push(readFile(file));
  }

  Promise.all(allFilePromises).then((newFilesData) => {
    if (newFilesData.length > 1) {
      let dataURLs = coverPhotos ? coverPhotos : [];
      newFilesData.forEach((newFileData) => {
        dataURLs.push(newFileData.dataURL);
      });

      updateState(property, dataURLs);
    } else if (newFilesData.length == 1) {
      let dataURLs = coverPhotos ? coverPhotos : [];
      {coverPhotos ? dataURLs.push(newFilesData[0].dataURL) : dataURLs = [newFilesData[0].dataURL]}
      updateState(
        property,
        multiple ? dataURLs : newFilesData[0].dataURL
      );
    }
  });
}

function onUploadClick(e) {
  e.target.value = null;
}

function triggerUpload(inputElement) {
  if (inputElement) {
    inputElement.click();
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
function onDropHandler(
  e,
  setError,
  property,
  maxFiles,
  filetypes,
  maxFileSize,
  updateState,
  multiple,
  coverPhotos
) {
  e.preventDefault();
  let files = [];

  for (var i = 0; i < e.dataTransfer.files.length; i++) {
    files.push(e.dataTransfer.files[i]);
  }

  fileChangedHandler(
    files,
    setError,
    property,
    maxFiles,
    filetypes,
    maxFileSize,
    updateState,
    multiple,
    coverPhotos
  );
}

function onDragOverHandler(e) {
  e.preventDefault();
}

function FileUpload(props) {
  const {
    setRef = () => {},
    multiple = true,
    setError = () => {},
    propertyName,
    maxFiles,
    accept,
    filetypes,
    maxFileSize,
    updateState = () => {},
    coverPhotos
  } = props;

  return (
    <input
      type="file"
      ref={(input) => setRef(input)}
      multiple={multiple}
      onChange={(e) => {
        fileChangedHandler(
          e.target.files,
          setError,
          propertyName,
          maxFiles,
          filetypes,
          maxFileSize,
          updateState,
          multiple,
          coverPhotos
        );
      }}
      onClick={onUploadClick}
      accept={accept}
      style={{ opacity: 0, position: "absolute", zIndex: -1 }}
    />
  );
}

export default FileUpload;
export { UPLOAD_ERROR, triggerUpload, onDropHandler, onDragOverHandler };
