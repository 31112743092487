import React from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useRef, useState, useEffect, useCallback } from "react";

import SetupForm from './SetupForm';
import { NODE_API_ENDPOINT } from '../../util/constants';
import { createStripeSetupIntent, createStripeCheckoutSession } from '../../util/stripe';

function StripeSaveCardLayout() {

  const [ clientSecret, setClientSecret ] = useState('');
  const [ stripePromise, setStripePromise ] = useState(null);
  const [ loadingPaymentIntent, setLoadingPaymentIntent ] = useState(false);
  const [ loadingPublishableKey, setLoadingPublishableKey ] = useState(false);
  const [ loadingCheckoutSession, setLoadingCheckoutSession ] = useState(false);

  // useEffect(() => {
  //   if (!loadingPaymentIntent) {
  //     setLoadingPaymentIntent(true);
  //     // Create PaymentIntent as soon as the page loads
  //     fetch(NODE_API_ENDPOINT + "/create-stripe-setup-intent", {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //       .then((res) => res.json())
  //       .then(({clientSecret}) => {
  //         setClientSecret(clientSecret) 
  //       });
  //   }
  // }, []);

  useEffect(() => {
    if (!loadingPaymentIntent) {
      setLoadingPaymentIntent(true);
      // Create PaymentIntent as soon as the page loads
      createStripeSetupIntent((data) => {
        if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message") && data?.success == true) {
          setClientSecret(data.clientSecret) 
        } else {
          console.log("Failed to create stripe setup intent!")
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!loadingPublishableKey) {
      setLoadingPublishableKey(true);
      fetch(NODE_API_ENDPOINT + "/get-stripe-pk")
      .then((res) => res.json())
      .then(({publishableKey}) => {
        console.log('publishableKey:',publishableKey)
        setStripePromise(loadStripe(publishableKey));
      });
      //.then(console.log("loaded pk: " + stripePromise));
    }
  }, []);


  // useEffect(() => {
  //   if (!loadingCheckoutSession) {
  //     setLoadingCheckoutSession(true);
  //     // Create PaymentIntent as soon as the page loads
  //     createStripeCheckoutSession((data) => {
  //       if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message") && data?.success == true) {
  //         console.log(data)
  //         window.location.href = data.url;
  //       } else {
  //         console.log("Failed to create stripe checkout session!")
  //       }
  //     });
  //   }
  // }, []);

  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret, }}>
          <SetupForm />
        </Elements>
      )}
    </>
  );
};

export default StripeSaveCardLayout;