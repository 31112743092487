import LandscapeProductView from "./LandscapeProductView";
import {
  Divider,
  Grid,
  List,
  Typography,
  Button,
  Link,
  Modal,
  IconButton,
  Menu,
  MenuItem
} from "@mui/material";
import { useDispatch } from "react-redux";
import { addItemToCart } from "../app/features/cart/cartDataSlice";
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";
import React, { useState } from "react";
import ReturnRequestPopup from "./ReturnRequestPopup";
import { shortenUUID, formatDate, round, outputViewProductVariationSize } from '../util/utilities';
import { RETURN_POLICY_LIST } from '../util/constants';
import MoreVertIcon from '@mui/icons-material/MoreVert';


const useStyles = makeStyles({
  orderDetails: {
    backgroundColor: "var(--halfBeige)",
  },
  orderNumColor: {
    color: "var(--mintGreen)",
    fontWeight: "bold",
  },
  dateText: {
    maxWidth: 150,
    margin: "auto",
  },
  changesDiv: {
    border: "2px solid lightgray",
  },
  imageButton: {
    width: "100%",
    margin: "auto",
    borderRadius: 20,
  },
  img: {
    width: 220,
    height: 165,
    objectFit: "cover",
    display: "block",
    margin: "auto",
    borderRadius: 20,
  },
  textGutterBottom: {
    marginBottom: 20,
  },
  tableGutterBottom: {
    marginBottom: 40,
  },
  noItemsText: {
    marginTop: 20,
    marginBottom: 20,
  },
  orderLabel: {
    color: "var(--coral)",
    fontSize: "16px",
    marginTop: "16px"
  },
});

const style = {
  position: 'absolute',
  borderRadius: '16px',
  backgroundColor: "white",
  //minWidth: "40%",
  maxWidth: "75vw",
  maxHeight: "90vh",
  overflow: "auto",
  border: "2px solid",
  zIndex: "2",
  padding: "32px",
  boxShadow: 24,
};

const modalCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

const ListNoPadding = withStyles({
  root: {
    padding: 0,
  },
})(List);

const MoreActions = (props) => {
  const { orderDetails, handlePrint, setReturnView, handleBuyAgain } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const ITEM_HEIGHT = 48;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem key={"BuyAgain"} onClick={() => { handleBuyAgain(orderDetails) }}>
          Buy it again
        </MenuItem>
        <MenuItem key={"Return"} onClick={() => setReturnView(orderDetails)} disabled={orderDetails?.seller?.returnPolicy?.policy == RETURN_POLICY_LIST[0].policy}>
          {orderDetails?.seller?.returnPolicy?.policy == RETURN_POLICY_LIST[0].policy ? 'This seller does not allow returns' : 'Return Items'}
        </MenuItem>
        <MenuItem key={"Cancel"}>
          Cancel Order
        </MenuItem>
      </Menu>
    </div>
  );
}

function OrderNumView({ orderDetails, showOrderNumView, setShowOrderNumView }) {
  const [returnView, setReturnView] = useState(-1);
  const classes = useStyles();
  const dispatch = useDispatch();

  function handleBuyAgain(orderDetails) {
    for (const product of orderDetails.products) {
      dispatch(
        addItemToCart({ productId: product.productID, skuId: product.skuID, addAmount: parseInt(product.quantity) })
      );
    }
    window.location.href = '/cart';
  }

  function getTrackingNumberLink(orderDetails) {
    let carrier = orderDetails.shippingTrackingCarrier
    let trackingNum = orderDetails.shippingTrackingNumber;
    let link;
    if (carrier && trackingNum) {
      if (carrier == 'UPS') {
        link = `http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNum}`;
      } else if (carrier == 'USPS') {
        link = `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNum}`;
      } else if (carrier == 'FedEx') {
        link = `http://www.fedex.com/Tracking?tracknumbers=${trackingNum}`;
      } else if (carrier == 'DHL') {
        link = `http://track.dhl-usa.com/TrackByNbr.asp?ShipmentNumber=${trackingNum}`;
      } else if (carrier == 'Other') {
        //Do nothing
      }
    }
    return link;
  }

  return (
    <Modal open={showOrderNumView} onClose={() => setShowOrderNumView(false)} sx={modalCenter}>
      <Grid container sx={style}>
        <Grid item xs={12}>
          {returnView !== -1 ? <ReturnRequestPopup orderDetails={orderDetails} setReturnView={setReturnView} /> : <></>}
          <Grid container justifyContent='space-between' style={{ marginBottom: "20px" }}>

            <Typography variant="h4"  >
              Order Details
            </Typography>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <MoreActions orderDetails={orderDetails} setReturnView={setReturnView} handleBuyAgain={handleBuyAgain} />
              <IconButton
                aria-label="more"
                id="close-button"
                aria-controls={showOrderNumView ? 'long-menu' : undefined}
                aria-expanded={showOrderNumView ? 'true' : undefined}
                aria-haspopup="true"
                onClick={() => setShowOrderNumView(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>

          </Grid>

          {/* <Typography
          variant="h6" className={classes.orderLabel}
        >
          ORDER NO. <span style={{color: "black"}}>{orderDetails ? shortenUUID(orderDetails.orderID) : "N/A"}</span>
        </Typography> */}
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="h6" className={classes.orderLabel}>
                ORDER NO.
              </Typography>
              <Typography variant="h6" component="h2"  >
                {orderDetails ? shortenUUID(orderDetails.orderID) : "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="h6" className={classes.orderLabel}>
                STATUS
              </Typography>
              <Typography variant="h6" component="h2">
                {orderDetails ? orderDetails.status : "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>

              <Typography variant="h6" className={classes.orderLabel}>
                PURCHASE DATE
              </Typography>
              <Typography variant="h6" component="h2">
                {orderDetails ? formatDate(orderDetails.orderDateTime) : "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="h6" className={classes.orderLabel}>
                TRACKING NO.
              </Typography>
              <Typography variant="h6" component="h2">
                {orderDetails && orderDetails.shippingTrackingNumber ?
                  <Link target="_blank" rel="noreferrer" href={getTrackingNumberLink(orderDetails)} underline="none">
                    <b>{orderDetails?.shippingTrackingNumber}</b>
                  </Link> : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ margin: "16px 0px" }} />
          <Grid container>
            <Grid item xs={6}>
              <Typography className={classes.tabelLabel}>Items in Order</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.tabelLabel}>Price</Typography>
            </Grid>
            <Grid item xs={2} >
              <Typography className={classes.tabelLabel}>Qty</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.tabelLabel}>Total</Typography>
            </Grid>
          </Grid>
          <Divider style={{ margin: "16px 0px" }} />
          {orderDetails && orderDetails.products.map((productDetail, index) =>
            <div key={productDetail.productID + orderDetails.orderID}>
              <Grid container >
                <Grid item xs={6}>
                  <Grid container direction="row" justifyContent="flex-start" columnSpacing={2}>
                    <Grid item>
                      <img src={productDetail.product.productImageURL[0]} style={{ maxWidth: "100px", height: "auto" }} />
                    </Grid>
                    <Grid item>
                      <Typography><b>{productDetail.product.productName}</b></Typography>
                      <Typography>Store: {productDetail.seller.sellerName}</Typography>
                      <Typography>Size/Style: {outputViewProductVariationSize(productDetail.product.option.size)}</Typography>
                      <Typography>Color: {productDetail.product.option.color}</Typography>
                      {productDetail.customizations?.length > 0 &&
                        productDetail.customizations.map((custom, index) => {
                          return (
                            <Typography className={classes.tabelLabel}>
                              {`Customization #${index + 1}: ${custom}`}
                            </Typography>
                          )
                        })
                      }
                    </Grid>
                  </Grid>

                </Grid>
                <Grid item xs={2} >
                  {(round(orderDetails.products[index].price, 2) == round(orderDetails.products[index].originalPrice, 2)) ?
                    (<Typography>
                      ${round(orderDetails.products[index].price, 2)}
                    </Typography>)
                    :
                    (<>
                      <Typography style={{ color: "red" }}>
                        <b>
                          ${round(orderDetails.products[index].price, 2)} {`($${round(round(orderDetails.products[index].originalPrice, 2) - round(orderDetails.products[index].price, 2), 2)} OFF!)`}
                        </b>
                      </Typography>
                      <strike>
                        <Typography>
                          ${round(orderDetails.products[index].originalPrice, 2)}
                        </Typography>
                      </strike>
                    </>)
                  }
                </Grid>
                <Grid item xs={2} >
                  <Typography>{orderDetails.products[index].quantity}</Typography>
                </Grid>
                <Grid item xs={2} >
                  <Typography>${round((orderDetails.products[index].price * parseInt(orderDetails.products[index].quantity)), 2)}</Typography>
                </Grid>
              </Grid>
              <Divider style={{ margin: "16px 0px" }} />
            </div>
          )}
          {orderDetails &&
            <Grid container>
              <Grid item xs={6} sm={6}></Grid>
              <Grid item xs={6}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Typography><b>Items</b></Typography>
                  <Typography>${round(orderDetails.totalItemsOriginalAmt, 2)}</Typography>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Typography><b>Discounts</b></Typography>
                  <Typography>- ${round(orderDetails.totalDiscountAmt, 2)}</Typography>
                </div>
                {orderDetails.purposerCreatedCouponApplied === true &&
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Typography><i>* Purposer Created Coupon Applied</i></Typography>
                  </div>
                }
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Typography><b>Shipping</b></Typography>
                  <Typography>${round(orderDetails.totalShippingAmt, 2)}</Typography>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Typography><b>Refunds</b></Typography>
                  <Typography>- ${round(orderDetails.totalRefundedAmt, 2)}</Typography>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Typography><b>Tax</b></Typography>
                  <Typography>${round(orderDetails.totalTaxAmt, 2)}</Typography>
                </div>
                <Divider style={{ margin: "8px 0px" }} />
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Typography><b>GRAND TOTAL</b></Typography>
                  <Typography>${round((orderDetails.netTotalAmt), 2)}</Typography>
                </div>
              </Grid>
            </Grid>}
        </Grid>
      </Grid>
    </Modal>
  );
}

export default OrderNumView;
