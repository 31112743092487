import { Box, Container, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import bulb from "../img/PurposerIcon.png";

//Default Prop Values
import mapDataDefault from "../json/map_data.json";
import React from "react";

const useStyles = makeStyles({
  map: {
    maxWidth: 1024,
    height: 600,
    margin: "auto",
  },
});

const Country = styled(Typography)({
  fontSize: 17,
  paddingTop: 5,
  paddingLeft: 19,
  paddingRight: 19,
  paddingBottom: 2,
});

const StyledDivider = styled(Divider)({
  height: 1,
  borderColor: "#000000",
  backgroundColor: "#000000",
});

const Seller = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "firstSeller",
})(({ firstSeller }) => ({
  fontSize: 17,
  color: "#000000",
  paddingLeft: 15,
  paddingRight: 15,
  paddingTop: firstSeller ? 3 : 0,
}));

const bulbIcon = new L.Icon({
  iconUrl: bulb,
  iconSize: [23, 30],
});

const Markers = ({ data }) => (
  <Marker icon={bulbIcon} position={[data.lat, data.lng]}>
    <Popup>
      <Country align="center" className={"roboto-light"}>
        {data.country.toUpperCase()}
      </Country>
      <StyledDivider />
      {data.sellers.map((item, i) => {
        return (
          <React.Fragment key={i}>
            <Seller className={"roboto-thin-italic"} firstSeller={i === 0}>
              <a href={item.seller_url}>
                <i>{item.seller}</i>
              </a>
            </Seller>
          </React.Fragment>
        );
      })}
    </Popup>
  </Marker>
);

const SupportedPurposesMap = ({ mapData = mapDataDefault }) => {
  const classes = useStyles();
  return (
    <>
      <MapContainer
        tap={false}
        center={[30, 0]}
        zoom={2}
        scrollWheelZoom={true}
        maxBounds={[
          [-90, -180],
          [90, 180],
        ]}
        className={classes.map}
        minZoom={2}
      >
        <TileLayer
          attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ"
          url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
          noWrap="true"
        />
        {mapData
          ? mapData.map((item, i) => {
              return <Markers data={item} key={i} />;
            })
          : null}
      </MapContainer>
    </>
  );
};

export default SupportedPurposesMap;
