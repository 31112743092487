import { Typography, Button, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles({
  containerDiv: {
    width: "100%",
  },
  labelText: {
    marginTop: 30,
    marginBottom: 10,
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "var(--discussionForm)",
      color: "#000000",
      borderColor: "var(--discussionForm)0",
      "& fieldset": {
        borderColor: "var(--discussionForm)",
        borderStyle: "none",
      },
      "&:hover fieldset": {
        borderColor: "var(--discussionForm)",
        borderStyle: "none",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--discussionForm)",
        borderStyle: "none",
      },
    },
  },
  singleLineTextfield: {
    "& .MuiOutlinedInput-root": {
      height: 30,
      borderRadius: 50,
    },
  },
  multilineTextfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 30,
    marginBottom: 10,
  },
  button: {
    color: "#000000",
    backgroundColor: "var(--marketingBackground)",
    width: 140,
    borderRadius: 10,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--marketingBackground)",
    },
  },
});

function StartADiscussion() {
  const classes = useStyles();

  return (
    <div className={classes.containerDiv}>
      <Typography
        variant="h5"
        className={clsx("roboto-thin", classes.labelText)}
      >
        Name
      </Typography>
      <TextField
        variant="outlined"
        fullWidth
        className={clsx(classes.textField, classes.singleLineTextfield)}
      />
      <Typography
        variant="h5"
        className={clsx("roboto-thin", classes.labelText)}
      >
        Question or Comments
      </Typography>
      <TextField
        variant="outlined"
        fullWidth
        multiline
        rows={6}
        className={clsx(classes.textField, classes.multilineTextfield)}
      />
      <div className={classes.buttonDiv}>
        <Button className={classes.button}>
          <Typography variant="h6" className={"roboto-thin"}>
            Submit
          </Typography>
        </Button>
      </div>
    </div>
  );
}

export default StartADiscussion;
