import {
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Autocomplete } from "@mui/material";
import countriesList from "../json/countries_list.json";
import { RETURN_POLICY_LIST } from "../util/constants";
import AddressInput from "./AddressInput";

const useStyles = makeStyles({
  descriptionText: {
    fontSize: "0.9rem",
  },
  inputOutline: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 5,
      "&:hover fieldset": {
        borderColor: "var(--mintGreen)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--mintGreen)",
      },
    },
  },
  sectionHeader: {
    color: "var(--mintGreenSolid)",
    fontSize: "1.4rem",
    marginBottom: 0,
    marginTop: 10,
    fontWeight: "bold"
  },
});

//Josh component
const InputRow = (props) => {
  const { label, description, classes } = props;

  return (
    <Grid container item direction="row" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography>
          <b>{label}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {props.children}
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.descriptionText}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ShippingOpenAShop = (props) => {
  const { formValues, handleFormValues } = props;

  const classes = useStyles();

  const handleShippingValues = (field, newValue, index) => {
    // console.log(`${field} values:\n`, newValue, index)
    const list = [...formValues.shippingPolicies];
    if (field == 'shipping' && list.find(policy => policy.shipping == newValue.shipping)) {
      console.log("Can't select two of the same shipping type with different policies!");
    } else {
      list[index][field] = field == 'shipping' ? newValue.shipping : newValue;
      handleFormValues("shippingPolicies", list);
    }
  };

  const handleReturnMaxDays = (maxDays) => {
    const currReturnPolicy = {...formValues.returnPolicy};
    currReturnPolicy.maxDays = maxDays;
    handleFormValues("returnPolicy",currReturnPolicy);
  };

  const handleReturnAddress = (field, value) => {
    const currReturnPolicy = {...formValues.returnPolicy};
    currReturnPolicy[field] = value;
    handleFormValues("returnPolicy",currReturnPolicy);
  };

  return (
    <>
      <Grid container direction="row" alignItems="center" spacing={0}>
        <Typography className={classes.sectionHeader}>Shipping</Typography>
        <InputRow
          classes={classes}
        >
          <Typography>
            {`Your shipping preferences and rates will be determined per product listing. You will be able to customize this in your dashboard after completing your set-up.`}
          </Typography>
          <br></br>
          <Typography>
            {`At this time, we are offering sales and shipping services to customers in the United States only. Other countries coming soon!`}
          </Typography>
          
        </InputRow>
        {/* <InputRow
          label="Shipping Policies"
          classes={classes}
          description="Choose all that apply. Your shoppers will be given the options you choose."
        > */}
          {/* {formValues.shippingPolicies.map((value, i) => {
            return (
              <>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    options={shippingList}
                    getOptionLabel={(option) => option.shipping || ""}
                    value={formValues.shippingPolicies ? formValues.shippingPolicies[i] : ""}
                    onChange={(event, newValue) => {
                      handleShippingValues("shipping", newValue, i)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                    size="small"
                    className={classes.inputOutline}
                    fullWidth
                  />
                  <TextField
                    name="cost"
                    // placeholder={`${i + 1}.`}
                    onChange={(e) => handleShippingValues("shippingCost", e.target.value, i) }
                    value={formValues.shippingPolicies ? formValues.shippingPolicies[i].shippingCost : ""}
                    variant="outlined"
                    size="small"
                    placeholder="Free Shipping Over"
                    width={12}
                  />
                  <Autocomplete
                    options={shippingCostList}
                    getOptionLabel={(option) => option.cost || ""}
                    value={formValues.shippingCost ? formValues.shippingCost : []}
                    onChange={(e, values) => {
                      console.log("shipping cost values:\n", e, value, i)
                      //handleShippingValues(e, value, i)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                    size="small"
                    className={classes.inputOutline}
                    fullWidth
                  /> 
                </div>
              </>
              );
            })} */}
          {/* <Autocomplete
            multiple
            options={shippingList}
            getOptionLabel={(option) => option.shipping}
            value={formValues.shipping ? formValues.shipping : []}
            filterSelectedOptions
            onChange={(e, values) => handleFormValues("shipping", values)}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            size="small"
            className={classes.inputOutline}
            fullWidth
          /> */}
        {/* </InputRow> */}
        {/* <InputRow
          label="Where I'll ship to"
          classes={classes}
          description="Choose all that apply. Domestic or International Zones."
        >
          <Autocomplete
            multiple
            options={countriesList}
            getOptionLabel={(option) => option.label || ""}
            value={
              formValues.shippingToCounties ? formValues.shippingToCounties : []
            }
            filterSelectedOptions
            onChange={(e, values) =>
              handleFormValues("shippingToCounties", values)
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            size="small"
            className={classes.inputOutline}
            fullWidth
          />
        </InputRow> */}
        {/* <InputRow
          label="Shipping Costs"
          classes={classes}
          description="Choose you Buyer shipping cost including Free Shipping, Free Shipping over $10 or Custom. You can always change this later for specific items."
        >
          <Autocomplete
            multiple
            options={shippingCostList}
            getOptionLabel={(option) => option.cost}
            value={formValues.shippingCost ? formValues.shippingCost : []}
            filterSelectedOptions
            onChange={(e, values) => handleFormValues("shippingCost", values)}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            size="small"
            className={classes.inputOutline}
            fullWidth
          />
        </InputRow> */}
        {/* <InputRow
          label="Get Reduced Shipping Labels with Purposer"
          classes={classes}
          description="You can ship items with Purposer Labels from USPS, and get reduced rates. You can choose to always use our labels, sometimes use them or never. You can change this option later."
        >
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="Get Reduced Shipping Labels with Purposer"
              name="reducedShippingLabelsWithPurposer"
              onChange={(e) =>
                handleFormValues(
                  "reducedShippingLabelsWithPurposer",
                  e.target.value
                )
              }
              value={formValues.reducedShippingLabelsWithPurposer}
            >
              <FormControlLabel
                value="Always"
                control={
                  <Radio
                    style={{
                      color: "var(--mintGreen)",
                      backgroundColor: "transparent",
                    }}
                  />
                }
                label="Always"
              />
              <FormControlLabel
                value="Sometimes"
                control={
                  <Radio
                    style={{
                      color: "var(--mintGreen)",
                      backgroundColor: "transparent",
                    }}
                  />
                }
                label="Sometimes"
              />
              <FormControlLabel
                value="Not yet"
                control={
                  <Radio
                    style={{
                      color: "var(--mintGreen)",
                      backgroundColor: "transparent",
                    }}
                  />
                }
                label="Not yet"
              />
            </RadioGroup>
          </FormControl>
        </InputRow> */}
        <Typography className={classes.sectionHeader}>{`Returns`}</Typography>
        <InputRow
          //label="Return policy"
          classes={classes}
          description="Choose a Return Policy. You can always change this later."
        >
          <Typography>
            {`Your return policy will apply to your entire store and every product listing.`}
          </Typography>
          <br></br>
          <Autocomplete
            disableClearable
            options={RETURN_POLICY_LIST}
            getOptionLabel={(option) => option.policy || ""}
            value={formValues.returnPolicy ? formValues.returnPolicy : []}
            filterSelectedOptions
            onChange={(e, values) => {handleFormValues("returnPolicy", values)}}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            size="small"
            className={classes.inputOutline}
            fullWidth
          />
          {formValues?.returnPolicy?.policy == RETURN_POLICY_LIST[1].policy && 
          <>
            <Typography variant="h6" sx={{ marginTop: 2 }}>Return Address</Typography>
            <Typography sx={{mb: 2}}>This is the address that you would like shoppers to send their returned items to. This option implies that buyers are in charge of boxing the product and paying for shipping labels.</Typography>
            <TextField 
              label="Return Name / Business Name" 
              fullWidth 
              size="small" 
              sx={{mb: 1}}
              value={formValues?.returnPolicy?.addressReturnName || ""}
              onInput={(e) => {handleReturnAddress("addressReturnName", e.target.value)}}
            />
            <AddressInput 
              formValues={formValues?.returnPolicy} 
              handleFormValues={handleReturnAddress}
              addressLine1={'addressReturnLine1'}
              addressLine2={'addressReturnLine2'}
              addressCity={'addressReturnCity'}
              addressCountry={'addressReturnCountry'}
              addressState={'addressReturnState'}
              addressPostalCode={'addressReturnPostalCode'}
            />
          </>
          }
          {formValues?.returnPolicy?.policy != RETURN_POLICY_LIST[0].policy && 
          <>
            <Typography sx={{marginTop:2}}>Max amount of days after an order a user can request a return:</Typography>
            <TextField
              name="returnPolicyMaxDays"
              type="number"
              onChange={(e) =>{handleReturnMaxDays(e.target.value)} }
              value={formValues?.returnPolicy?.maxDays || ""}
              variant="outlined"
              size="small"
              width={12}
              InputProps={{
                //startAdornment: '$',
                inputProps: {
                  pattern: '[0-9]*',
                },
              }}
            />
          </>
          }
        </InputRow>
      </Grid>
    </>
  );
};

export default ShippingOpenAShop;
