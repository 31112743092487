import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import OrderReturnPopup from "./OrderReturnPopup";
import { round, shortenUUID, formatDate } from '../util/utilities'

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  noOrdersCell: {
    border: "none",
  },
  tableRows: {
    backgroundColor: "",
  },
}));

const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        size="large"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        size="large"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="large"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        size="large"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const OrderReturnsCompletedView = (props) => {
  const { orderData, filter, handleFilter, isLoading, title = "", handleRefundByReturnId } = props;
  const classes = useStyles1();
  const [orderView, setOrderView] = useState(-1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows = orderData
    ? rowsPerPage -
      Math.min(rowsPerPage, orderData.length - page * rowsPerPage)
    : 4;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {orderView !== -1 ? <OrderReturnPopup row={orderView} isLoading={isLoading} setOrderView={setOrderView} handleRefundByReturnId={handleRefundByReturnId}/> : <></>}
      <FormControl
        variant="outlined"
        size="small"
        sx={{
          minWidth: 120,
          float: "right",
          marginBottom: "10px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "7px",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "var(--mintGreen)",
              color: "var(--mintGreen)",
            },
          },
        }}
      >
        <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
        <Select
          onChange={(e) => {handleFilter(e.target.value)}}
          label="Filter"
          inputProps={{
            name: "filter",
            id: "outlined-age-native-simple",
          }}
          value={filter}
        >
          <MenuItem value="Newest First">Newest First</MenuItem>
          <MenuItem value="Oldest First">Oldest First</MenuItem>
        </Select>
      </FormControl>
      <Typography variant="h5" style={{color: "var(--coral)"}}>
        <b>{title}</b>
      </Typography>
      <TableContainer component={Paper}>
        <Table className={"string"} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography>
                  <b>Order No.</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>
                  <b>Return ID</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>
                  <b>Date</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                {title === "Completed Returns" && 
                <Typography>
                  <b>Refunded Items $</b>
                </Typography>}
                {title === "Denied Returns" && 
                <Typography>
                  <b>Total Items $</b>
                </Typography>}
                {/* <Typography>
                  <b>Refunded Items $</b>
                </Typography> */}
              </TableCell>
              <TableCell align="center">
              {title === "Completed Returns" && 
                <Typography>
                  <b>Refunded Shipping $</b>
                </Typography>}
                {title === "Denied Returns" && 
                <Typography>
                  <b>Total Shipping $</b>
                </Typography>}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderData && orderData.length > 0 ? (
              (rowsPerPage > 0
                ? orderData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : orderData
              ).map((row, index) => (
                <TableRow key={row.returnID+'rowcv'} className={classes.tableRows}>
                  <TableCell component="th" scope="row" align="center">
                    <Button
                      onClick={() => setOrderView(orderData.find(({orderID}) => orderID === row.orderID))}
                    >
                      <Typography>
                      <b>{shortenUUID(row.orderID)}</b>
                      </Typography>
                    </Button>
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <Button
                      onClick={() => setOrderView(orderData.find(({orderID}) => orderID === row.orderID))}
                    >
                      <Typography>
                      <b>{shortenUUID(row.returnID)}</b>
                      </Typography>
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      <b>{formatDate(row.requestDateTime)}</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      <b>{"$" + round(row.totalItemsAmt,2)}</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      <b>{"$" + round(row.totalShippingAmt,2)}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow className={classes.tableRows}>
                <TableCell
                  align="center"
                  colSpan={5}
                  className={classes.noOrdersCell}
                >
                  <Typography>
                    {!orderData ? <b>Loading Orders</b> : <b>No Orders</b>}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{ height: 53 * emptyRows }}
                className={classes.tableRows}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow className={classes.tableRows}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={4}
                count={orderData ? orderData.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default OrderReturnsCompletedView;
