import {
  Typography,
  Container,
  Grid,
  TextField,
  Button,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles({
  signUpDiv: {
    backgroundColor: "var(--mintGreenTransparent)",
    paddingTop: 30,
    paddingBottom: 30,
  },
  signUpShopText: {
    marginBottom: 20,
  },
  signUpDiscountText: {
    marginBottom: 40,
  },
  signUpTextField: {
    "& label": {
      color: "black",
      fontSize: "1.375rem",
      display: "block",
    },
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiOutlinedInput-root": {
      minHeight: 60,
      borderRadius: 15,
      backgroundColor: "white",
      color: "#FFFFFF",
      borderColor: "var(--mintGreen)",
      "& fieldset": {
        borderColor: "var(--mintGreen)",
      },
      "&:hover fieldset": {
        borderColor: "var(--mintGreen)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--mintGreen)",
      },
    },
  },
  signUpButton: {
    backgroundColor: "var(--mintGreenSolid)",
    borderRadius: 10,
    minHeight: 60,
    width: "200px",
    "&:hover": {
      backgroundColor: "var(--mintGreenSolid)",
    },
    color: "#FFFFFF",
  },
  signUpButtonText: {
    fontSize: "1.375rem",
  },
});

function SignUp() {
  const classes = useStyles();

  const [email, setEmail] = React.useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div className={classes.signUpDiv}>
      <Container maxWidth="xl">
        <Typography
          align="center"
          display="block"
          className={classes.signUpShopText}
          sx={{ fontSize: { xs: "1.7rem", sm: "2rem", md: "2.3rem" } }}
        >
          <b>Stay Updated & Shop Purposefully</b>
        </Typography>
        <Container maxWidth={"md"}>
          <Typography
            align="center"
            display="block"
            className={classes.signUpDiscountText}
          >
            Enter your email in the field below to get 10% OFF you first
            purchase! Also be the first to know about new products, special
            pricing, and all the good work our brands are doing to help the
            world!
          </Typography>
        </Container>
        <Container maxWidth={"lg"}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            columnSpacing={3}
            rowSpacing={3}
          >
            <Grid item xs>
              <TextField
                fullWidth
                label={email === "" ? "Email Address" : ""}
                onChange={handleEmailChange}
                variant="outlined"
                InputLabelProps={{
                  shrink: false,
                }}
                className={classes.signUpTextField}
                sx={{ fontSize: { xs: "1.2rem", sm: "1.4rem", md: "1.6rem" } }}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button variant="contained" className={classes.signUpButton}>
                  <Typography className={classes.signUpButtonText}>
                    <b>SIGN UP</b>
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </div>
  );
}

export default SignUp;
