import { ButtonBase, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Carousel from "react-multi-carousel";
import clsx from "clsx";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";
import greenBulb from "../img/green_bulb.png";
import { styled } from "@mui/styles";

const useStyles = makeStyles({
  navButtons: {
    position: "absolute",
    top: "20%",
    height: 45,
    width: 17,
    border: "1px solid var(--mintGreen)",
    borderRadius: 20,
    backgroundColor: "#FFFFFF",
  },
  prevButton: {
    left: 20,
  },
  nextButton: {
    right: 20,
  },
  container: {
    position: "relative",
    borderStyle: "solid",
    borderColor: "var(--mintGreen)",
    borderWidth: 1,
    textAlign: "center",
    paddingTop: 15,
    paddingBottom: 10,
  },
  arrows: {
    color: "var(--mintGreen)",
    fontSize: 30,
  },
  img: {
    maxHeight: 45,
    marginBottom: 5,
  },
});

const responsive = {
  break2: {
    breakpoint: { max: 4000, min: 650 },
    items: 2,
  },
  break3: {
    breakpoint: { max: 650, min: 0 },
    items: 1,
  },
};

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  const classes = useStyles();
  return (
    <>
      <ButtonBase
        className={clsx(classes.navButtons, classes.prevButton)}
        onClick={previous}
      >
        <ArrowBack className={classes.arrows} />
      </ButtonBase>
      <ButtonBase
        className={clsx(classes.navButtons, classes.nextButton)}
        onClick={next}
      >
        <ArrowForward className={classes.arrows} />
      </ButtonBase>
    </>
  );
};

function PurposeCarousel(props) {
  const classes = useStyles();
  const { purposeCarouselData } = props;

  return (
    <div className={classes.container}>
      {purposeCarouselData && purposeCarouselData.length > 0 ? (
        <Carousel
          responsive={responsive}
          infinite={false}
          showDots={false}
          arrows={false}
          renderButtonGroupOutside
          slidesToSlide={1}
          customButtonGroup={<CustomButtonGroupAsArrows />}
        >
          {purposeCarouselData.map((purpose, index) => {
            return (
              <div key={index}>
                <img
                  src={
                    index % 3 === 0
                      ? "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Seller+Store+Page/lightbulb+Icon+Black-01.png"
                      : index % 2 === 0
                      ? greenBulb
                      : "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Seller+Store+Page/Coral+lightbulb+Icon-14-01.png"
                  }
                  alt=""
                  className={classes.img}
                />
                <Typography
                  align="center"
                  varaint="h6"
                  className={"roboto-medium"}
                >
                  {typeof purpose === "object" ? purpose.name || purpose.purposeName : purpose}
                  {/* {purpose.name || purpose.purposeName} */}
                </Typography>
              </div>
            );
          })}
        </Carousel>
      ) : (
        <Typography align="center" varaint="h6" className={"roboto-medium"}>
          <b>No purposes to display</b>
        </Typography>
      )}
    </div>
  );
}

export default PurposeCarousel;
