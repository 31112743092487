import { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  MenuItem,
  Checkbox,
  Divider,
  FormControl,
  Select,
  checkboxClasses,
  menuItemClasses,
  outlinedInputClasses,
  svgIconClasses,
  selectClasses,
  useMediaQuery,
  Popover,
  Box,
  ButtonBase,
  CircularProgress,
  Modal,
} from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import makeStyles from "@mui/styles/makeStyles";
import SellerCarouselBanner from "../../components/SellerCarouselBanner";
import { PurposeCategoryMasterLayout } from "../../layouts";
import SellerHeader from "../../components/SellerHeader";
import CategoryMenu from "../../components/CategoryMenu";
import sellerMenuData from "../../json/seller_menu.json";
import GalleryView from "../../components/GalleryView";
import FeaturedProductsAndSpecials from "../../components/FeaturedProductsAndSpecials";
import InAction from "../../components/InAction";
import ExpandableQuestionsView from "../../components/ExpandableQuestionsView";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import {
  ExpandMore,
  Search,
  ImageOutlined,
  PhoneAndroid,
  Edit,
} from "@mui/icons-material";
import CarouselProductView from "../../components/CarouselProductView";
import Blog from "../../components/Blog";
import Reviews from "../../components/Reviews";
import clsx from "clsx";
import React from "react";
import purposerStoreData from "../../json/purposer_store.json";
import goodVibesData from "../../json/good_vibes_store.json";
import { styled, useTheme } from "@mui/styles";
import { getSellerById } from "../../util/sellers";
import { getListOfProductCategoriesById } from "../../util/productCategories";
import { getListOfProductsById } from "../../util/getProductsById";
import useGalleryPages from "../../hooks/useGalleryPages";
import { getListingsBySeller } from "../../util/listingFunctions";
import { getUserIdFromAuth, isPurposerUserAdmin } from "../../util/utilities";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useDocumentDescription from "../../hooks/useDocumentDescription";
import { handleSortFilter } from "../../util/filterProducts";

const useStyles = makeStyles({
  sellerHeaderDiv: {
    marginTop: 40,
    marginBottom: 10,
  },
  categoryMenu: {
    marginTop: 20,
    marginLeft: 10,
  },
  galleryGridItem: {
    margin: "auto",
  },
  galleryViewDiv: {
    marginBottom: 50,
  },
  featuredProductsAndSpecialsDiv: {
    marginBottom: 100,
  },
  faqDivider: {
    marginTop: 30,
    marginBottom: 40,
    backgroundColor: "#000000",
  },
  faqHeader: {
    textDecoration: "underline",
  },
  moreDiv: {
    paddingLeft: 150,
    marginTop: 15,
  },
  moreArrow: {
    marginLeft: 5,
  },
  cartBackground: {
    backgroundColor: "var(--lightMintGreen)",
    paddingTop: 15,
    paddingBottom: 15,
  },
  cartIcon: {
    marginRight: 20,
  },
  displayFlex: {
    display: "flex",
  },
  cartDiv: {
    alignItems: "center",
    margin: "auto",
    padding: "24px"
  },
  recentlyViewedDiv: {
    backgroundColor: "var(--pastelGreen)",
    paddingTop: 20,
    paddingBottom: 60,
  },
  recentlyViewedHeader: {
    marginBottom: 35,
  },
  reviewDiv: {
    backgroundColor: "#FAF5F2",
    marginTop: 50,
  },
  adminPanelDiv: {
    backgroundColor: "#E5D2C4",
    minHeight: 45,
    display: "flex",
    alignItems: "center",
  },
  adminButton: {
    color: "#000000",
    marginLeft: 5,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  adminIcon: {
    marginRight: 5,
  },
  faqEditButtonDiv: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    bottom: 20,
  },
  faqEditButton: {
    color: "#FFFFFF",
    backgroundColor: "var(--coral)",
    textTransform: "none",
    paddingLeft: 15,
    paddingRight: 15,
    "&:hover": {
      backgroundColor: "var(--coral)",
    },
  },
  faqEditButtonText: {
    marginLeft: 10,
  },
});

const DropdownButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
});

const StyledFormControl = styled(FormControl)({
  // maxWidth: "100%",
  [`& .${outlinedInputClasses.root}`]: {
    marginRight: 10,
    height: 40,
    borderRadius: 25,
    border: 'none',
    backgroundColor: "#F7F7F7",
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      border: 'none',
    },
    [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
      border: 'none',
    },
    [`& .${selectClasses.select}`]: {
      paddingRight: 0,
      paddingLeft: 27,
    },
  },
  [`& .${svgIconClasses.root}`]: {
    paddingRight: -0,
  },
});

const StyledMenuItem = styled(MenuItem)({
  backgroundColor: "#FFFFFF",
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
});

const AdminPanel = (props) => {
  const { id, classes } = props;
  return (
    <div className={classes.adminPanelDiv}>
      <Container maxWidth="xl">
        <Button
          disableRipple
          className={classes.adminButton}
          href={"/seller/" + id + "/orders"}
        >
          <Search className={classes.adminIcon} />
          <Typography>
            <b>Dashboard</b>
          </Typography>
        </Button>
        <Button disableRipple className={classes.adminButton}>
          <ImageOutlined className={classes.adminIcon} />
          <Typography>
            <b>Shop Gallery</b>
          </Typography>
        </Button>
        <Button
          disableRipple
          href={`/shop/seller/${id}`}
          className={classes.adminButton}
        >
          <PhoneAndroid className={classes.adminIcon} />
          <Typography>
            <b>Public View</b>
          </Typography>
        </Button>
      </Container>
    </div>
  );
};

const DropdownButton = (props) => {
  // Structure of options is [{label: "", value: ""}, {label: "", value: ""}]
  const { label, options, selected, handleClick } = props;

  return (
    <StyledFormControl>
      <Select
        value={selected}
        onChange={handleClick}
        renderValue={() => (
          <Typography
            align="center"
            variant="h6"
            className={"roboto-thin"}
            sx={{ fontSize: { xs: "1.05rem", md: "1.25rem" } }}
          >
            {label}
          </Typography>
        )}
        displayEmpty
        IconComponent={ExpandMore}
        sx={{
          ".MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input":
          {
            pl: 2,
            pr: 4.5,
          },
        }}
      >
        {options &&
          options.map((option, index) => {
            return (
              <StyledMenuItem key={index} value={option.value}>
                <Typography className={"roboto-regular"}>
                  {option.label}
                </Typography>
              </StyledMenuItem>
            );
          })}
      </Select>
    </StyledFormControl>
  );
};

function SellerStoreLayout() {
  const classes = useStyles();
  const id = useParams();
  const DEFAULT_ID = "22963625-1ae2-41d1-9041-97a8111c74a0";
  // Data will need to be based on the seller id in the future
  // Will also probably be some authentication check for admin page
  const location = useLocation();
  const routes = location.pathname.split("/");
  const admin = false; //TODO: BUILD THIS OUT IF WE WANT IT
  //   routes && routes.length > 0 && routes[routes.length - 1] === "admin";

  // Will need to check if the current user is the seller for this store
  // and is logged in. For now defaulting to true because Joline wants the
  // seller and admin functions visible for the demo site (see comments of
  // PUR-243)
  const [isStoreOwner, setIsStoreOwner] = useState(false);

  const [sellerData, setSellerData] = useState({});
  const [currentProductList, setCurrentProductList] = useState([]);
  const [currentProductGallery, setProductGallery] = useState([[]]);
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [isRequesting, setIsRequesting] = useState(true);
  const [requestFailed, setRequestFailed] = useState(false);
  const [isLoadingProductList, setIsLoadingProductList] = useState(true);
  const [sortBySelected, setSortBySelected] = useState("");
  const history = useHistory();

  const [currUserId, setCurrUserId] = useState(null);
  // console.log(currentProductList)
  useEffect(() => {
    if (currUserId == null) {
      getUserIdFromAuth((userId) => {
        if (userId) {
          setCurrUserId(userId);
        }
      })
    }
  }, []);

  useEffect(() => {
    // Hardcoded Purposer shop data
    if (id.id === "purposer") {
      const purposerData = {
        banner_images: purposerStoreData.carouselBanner,
        seller_name: purposerStoreData.header.seller_name,
        profile_pic: purposerStoreData.header.profile_pic,
        location: purposerStoreData.header.location,
        bio: purposerStoreData.header.bio,
        purposes: purposerStoreData.header.purposes,
        inAction: purposerStoreData.inAction,
        FAQ: purposerStoreData.faq,
        blog: purposerStoreData.blogPosts,
      };
      setSellerData(purposerData);
      setIsRequesting(false);
      return;
    }
    getSellerById(id.id, (data) => {
      if (data) {
        if (data.active == false) {
          history.push(`/`);
          return;
        }
        setSellerData(data);
        setIsRequesting(false);
        getUserIdFromAuth((userId) => {
          if (data.adminUserID.toLowerCase() == userId?.toLowerCase()) {
            setIsStoreOwner(true);
          }
        })
      } else {
        // Fetch default
        // getSellerById(DEFAULT_ID, (data) => {
        //   if (data) {
        //     setSellerData(data);
        //   }
        // });
        setRequestFailed(true);
      }
    });
  }, [id]);

  useDocumentTitle(`Discover and Shop for unique products from ${sellerData?.seller_name}`);
  useDocumentDescription(`Discover unique and ethical products from ${sellerData?.seller_name}. Shop and support brands that place purpose over profit.`);

  const [menu, setMenu] = useState([
    {
      name: "None",
      // val: "none"
    },
    {
      name: "Price Low to High",
      // val: "ltoh"
    },
    {
      name: "Price High to Low",
      // val: "htol"
    }
  ]);

  useEffect(() => {
    // Construct category menu
    // Will be based on seller data in future
    if (id.id === "purposer") return;
    getListOfProductCategoriesById(sellerMenuData.categories, (data) => {
      if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
        const category = {
          id: 0,
          name: "All Products",
          img: "",
          active: true,
          subcategories: data,
          legacyLayoutData: {},
        };
        setMenu([category]);
      }
    });
  }, []);

  useEffect(() => {
    if (id.id === "purposer") return;
    getListingsBySeller(id.id, false, (data) => {
      setCurrentProductList(data);
      setIsLoadingProductList(false);
    });
    getListOfProductsById(sellerData.recentlyViewed, (data) => {
      if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
        setRecentlyViewed(data);
      }
    });
  }, [sellerData]);

  // Update gallery pages whenever a filter causes the product list to update
  useGalleryPages(currentProductList, setProductGallery, 32);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <PurposeCategoryMasterLayout>
      <Divider />
      {!isRequesting ? (
        <>
          <SellerCarouselBanner
            sellerCarouselBannerData={
              sellerData.banner_images ? sellerData.banner_images : []
            }
            // Show the seller buttons if not at the admin view but the store
            // owner is logged in and on the store page
            showSellerButtons={(!admin && isStoreOwner) || isPurposerUserAdmin(currUserId)}
          />
          {admin && <AdminPanel id={id.id} classes={classes} />}
          <div className={classes.sellerHeaderDiv}>
            <SellerHeader sellerHeaderData={sellerData} admin={admin} />
          </div>
          {!isMobile ? (
            <>
              <Container maxWidth="xl">
                <DropdownButtonContainer>
                  <DropdownButton
                    label={"Sort By"}
                    options={[
                      // { label: "Most Popular", value: "popular" },
                      { label: "Price High to Low", value: "highToLow" },
                      { label: "Price Low to High", value: "lowToHigh" },
                      // { label: "Sale", value: "sale" },
                    ]}
                    selected={sortBySelected}
                    handleClick={(event) => {
                      const newValue = event.target.value;
                      setSortBySelected(newValue);
                      handleSortFilter(
                        newValue,
                        currentProductList,
                        setCurrentProductList
                      );
                    }}
                  />
                </DropdownButtonContainer>
              </Container>
              <Grid container direction="row">
                {/* <Grid item xs={2} className={classes.categoryMenu}>
                    <CategoryMenu
                      categoryData={menu}
                      expandedCategory={0}
                      admin={admin}
                    />
                  </Grid> */}
                <Grid item xs={9} className={classes.galleryGridItem}>
                  <div className={classes.galleryViewDiv}>
                    <GalleryView
                      productPages={currentProductGallery}
                      admin={admin}
                      showLoadingIcon={isLoadingProductList}
                    />
                  </div>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <DropdownButtonContainer>
                <DropdownButton
                  label={"Sort By\t\t"}
                  options={[
                    // { label: "Most Popular", value: "popular" },
                    { label: "Price High to Low", value: "highToLow" },
                    { label: "Price Low to High", value: "lowToHigh" },
                    // { label: "Sale", value: "sale" },
                  ]}
                  selected={sortBySelected}
                  handleClick={(event) => {
                    const newValue = event.target.value;
                    setSortBySelected(newValue);
                    handleSortFilter(
                      newValue,
                      currentProductList,
                      setCurrentProductList
                    );
                  }}
                />
              </DropdownButtonContainer>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Box sx={{ m: 1, p: 1 }}>
                  <CategoryMenu
                    categoryData={menu}
                    expandedCategory={"all_products"}
                    admin={admin}
                  />
                </Box>
              </Popover>
              <div className={classes.galleryViewDiv}>
                <GalleryView
                  productPages={currentProductGallery}
                  admin={admin}
                  showLoadingIcon={isLoadingProductList}
                  mobile
                />
              </div>
            </>
          )}
          {/* <div className={classes.featuredProductsAndSpecialsDiv}>
            <FeaturedProductsAndSpecials
              featuredProductsAndSpecialsData={
                sellerData.featured ? sellerData.featured : []
              }
              admin={admin}
            />
          </div> */}
          <div id={"inAction"}>
            <InAction
              inActionData={sellerData.inAction ? sellerData.inAction : {}}
              sellerData={sellerData ? sellerData : {}}
              admin={admin}
            />
          </div>
          {/* <Container maxWidth="xl">
            <div
              style={{
                marginBottom: admin ? -21 : 15,
                marginTop: 50,
              }}
            >
              <Typography
                display="inline"
                variant="h4"
                className={clsx(classes.faqHeader, "roboto-thin")}
              >
                {sellerData.sellerName}
              </Typography>
              <Typography
                display="inline"
                variant="h4"
                className={"roboto-medium"}
              >
                {" "}
                FAQ
              </Typography>
            </div>
            {admin && (
              <div className={classes.faqEditButtonDiv}>
                <Button className={classes.faqEditButton}>
                  <Edit />
                  <Typography className={classes.faqEditButtonText}>
                    <b>Edit FAQs</b>
                  </Typography>
                </Button>
              </div>
            )}
          </Container>
          <ExpandableQuestionsView
            questions={sellerData.FAQ ? sellerData.FAQ : []}
          /> */}
          <Blog
            blogPosts={sellerData.blog ? sellerData.blog : {}}
            admin={admin}
          />
          {/* <div className={classes.reviewDiv}>
            <Reviews
              reviews={sellerData.reviews ? sellerData.reviews : {}}
              admin={admin}
            />
          </div> */}
          <div className={classes.cartBackground}>
            <a href="/cart">
              <Container maxWidth="xl" className={classes.displayFlex}>
                <div className={clsx(classes.displayFlex, classes.cartDiv)}>
                  <ShoppingCartOutlinedIcon
                    fontSize="large"
                    className={classes.cartIcon}
                  />
                  <Typography variant="h6" className={"roboto-medium"}>
                    You've got great taste. Go to your cart!
                  </Typography>
                </div>
              </Container>
            </a>
          </div>
          {/* <div className={classes.recentlyViewedDiv}>
            <Container maxWidth="xl">
              <Typography
                align="center"
                variant="h4"
                className={clsx(classes.recentlyViewedHeader, "roboto-medium")}
              >
                Best Sellers
              </Typography>
              <CarouselProductView data={recentlyViewed} />
            </Container>
          </div> */}
        </>
      ) : (
        <></>
      )}
      <Modal open={isRequesting} sx={{ overflow: "scroll" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 2,
            bgcolor: "background.paper",
            p: 4,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!requestFailed ? (
            <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography>Failed retrieving Seller Information</Typography>
              <Button component={Link} to={"/"} color="error">
                go back Home
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </PurposeCategoryMasterLayout>
  );
}

export default SellerStoreLayout;
