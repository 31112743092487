import {
  Typography,
  ButtonBase,
  Grid,
  Container,
  Divider,
  Rating,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Stack,
  CircularProgress,
  Modal,
  Box,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import React from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { deleteProductReview, addAdminReviewToProduct } from "../util/products";
import toast, { Toaster } from 'react-hot-toast';
import { MAX_FILE_SIZE, FILETYPES, MAX_NUM_FILES, MIN_REVIEW_LENGTH, MAX_REVIEW_LENGTH } from "../util/constants"
import FileUploadV2 from "./FileUploadV2";
import { uploadImageArray } from "../util/images";

const InputRow = (props) => {
  const { label, description, classes } = props;

  return (
    <Grid container item direction="row" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography>
          <b>{label}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {props.children}
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.descriptionText}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};


const useStyles = makeStyles({
  reviewsDiv: {
    backgroundColor: "inherit",
    overflow: "hidden",
  },
  reviewsCarousel: {
    position: "relative",
    width: "93%",
    paddingBottom: 100,
    margin: "auto",
    overflow: "hidden",
  },
  reviewsNavButtons: {
    position: "absolute",
    top: "29%",
    border: "1px solid black",
    borderRadius: 20,
    backgroundColor: "#FFFFFF",
  },
  reviewsPrevButton: {
    left: -10,
  },
  reviewsNextButton: {
    right: -10,
  },
  reviewsExpandButton: {
    color: "#000000",
    paddingBottom: 20,
  },
  noReviewsText: {
    fontSize: "1.1rem",
    paddingBottom: 40,
  },
  divContainer: {
    position: "relative",
    width: "100%",
    margin: "auto",
  },
  reviewStar: {
    color: "#ee907b",
  },
  divider: {
    backgroundColor: "black",
    margin: "3px auto 10px auto",
    height: 1,
    maxWidth: 320,
  },
  gridContainer: {
    margin: "20px auto",
  },
  writeAReviewButton: {
    backgroundColor: "white",
    border: "2px solid var(--mintGreen)",
    padding: "5px 15px",
    borderRadius: 8,
  },
  deleteAReviewButton: {
    backgroundColor: "white",
    color: "red",
    padding: "5px 15px",
    borderRadius: 8,
  },
  galleryButton: {
    width: "35px",
    height: "35px",
    borderRadius: "25px",
    backgroundColor: "white",
    border: "1px solid black",
    margin: "7px 7px 20px 7px",
  },
  img: {
    //maxWidth: "400px"
    // maxHeight: "200px"
    height: "200px",
    width: "200px",
    objectFit: "cover"
  },
  uploadIconDiv: {
    width: "100px",
    height: "100px",
    backgroundColor: "lightgray",
    borderRadius: "8px",
    opacity: "0.4",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  uploadIconDivVer2: {
    width: "200px",
    height: "200px",
    backgroundColor: "lightgray",
    borderRadius: "8px",
    opacity: "0.4",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  photoError: {
    color: "#d32f2f"
  },
  minCharCount: {
    fontSize: "0.75rem"
  }
});

const ReviewDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  }
}));

const ReviewDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
};

function notUUIDPatternMatch(stringtoCheck) {
  //This expects a UUID, a hexadecimal string of this configuration: XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
  stringtoCheck = String(stringtoCheck);
  const uuidPatternMatch = new RegExp('^[0-9A-f]{8}-[0-9A-f]{4}-[0-9A-f]{4}-[0-9A-f]{4}-[0-9A-f]{12}$', 'g');

  return !uuidPatternMatch.test(stringtoCheck);
}

function AdminInsertReview(props) {

  const classes = useStyles();

  const [formValues, setFormValues] = useState({
    images: []
  });

  const handleFormValues = (name, value) => {
    setFormValues((preValues) => {
      return { ...preValues, [name]: value };
    });
  };

  const [loading, setLoading] = useState(false);
  const [loadingSubmitReview, setLoadingSubmitReview] = useState(false);

  const [reviewExists, setReviewExists] = React.useState(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleClickDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const uploadImagesAndUpdateFormData = async (imageArray, formDataValueName, handleFormData) => {
    const imgUrlArray = await uploadImageArray(imageArray);
    handleFormData(formDataValueName, imgUrlArray);
    return imgUrlArray;
  }

  const handleReviewSubmit = async () => {
    setLoading(true)
    setLoadingSubmitReview(true)
    var swearjar = require('swearjar');
    if (swearjar.profane(reviewText)) {
      setLoading(false)
      setLoadingSubmitReview(false)
      const errorText = "Check your typed content. You are using a word or phrase from a Prohibited List!";
      toast((t) => (
        <span>
          {errorText}
          {'\n'}
          <Grid container justifyContent="center">
            <Button onClick={() => toast.dismiss(t.id)}>
              Dismiss
            </Button>
          </Grid>
        </span>
      ));
    }
    else {
      const processedImages = await uploadImagesAndUpdateFormData(formValues.images, "images", handleFormValues);
      const insertedDatePlus12Hours = new Date(reviewDate);
      insertedDatePlus12Hours.setHours(insertedDatePlus12Hours.getHours() + 12);
      const insertedDatePlus12HoursString = insertedDatePlus12Hours.toISOString();
      addAdminReviewToProduct(productID, reviewText, processedImages, parseFloat(stars), insertedName, insertedDatePlus12HoursString, (data) => {
        setLoading(false)
        setLoadingSubmitReview(false)
        setDialogOpen(false);
        window.location.reload(false);
      })
    }
  };

  const handleReviewDelete = () => {
    setLoading(true)
    deleteProductReview(reviewID, (data) => {
      setLoading(false)
      setDialogOpen(false);
      window.location.reload(false);
    })
  }

  const [reviewText, setReviewValue] = React.useState("");
  const [productID, setProductID] = React.useState("");
  const [reviewStarted, setReviewStartedValue] = React.useState(false);
  const [stars, setStarValue] = React.useState(0);
  const [reviewID, setReviewIDValue] = React.useState(-1);
  const [reviewDate, setReviewDate] = React.useState("");
  const [insertedName, setInsertedName] = React.useState("");

  return (
    <div className={classes.reviewsDiv}>
      <Container>
        <Toaster
          toastOptions={{
            className: '',
            style: {
              border: '1px solid #713200',
              padding: '16px',
              color: '#713200',
            },
          }}
        />
        <Modal open={loading} sx={{ overflow: "scroll" }}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: 2,
              bgcolor: "background.paper",
              p: 4,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
          </Box>
        </Modal>
        <Grid
          container
          //direction="row"
          justifyContent="space-around"
          alignItems="center"
          align="center"
          className={classes.gridContainer}
        >
          <Grid item xs={6} sm order={{ xs: 2, sm: 1 }}>
            <ButtonBase className={classes.writeAReviewButton}
              onClick={handleClickDialogOpen}>
              <Typography className={"roboto-medium"}>
                {"Write a Review"}
              </Typography>
            </ButtonBase>
            <ReviewDialog maxWidth="md" fullWidth onClose={handleDialogClose} open={dialogOpen}>
              <ReviewDialogTitle onClose={handleDialogClose}>
                <Typography>
                  <b>Overall Rating</b>
                </Typography>
              </ReviewDialogTitle>
              <DialogContent dividers>
                <Rating
                  sx={{ color: "var(--coral)" }}
                  onChange={(e) => setStarValue(e.target.value)}
                  value={stars.toString()}
                  precision={0.5}
                />
                <Typography className={classes.minCharCount}>
                  {!stars || stars < 0.5 ? `Must select a star rating!` : ""}
                </Typography>
                <br />
              </DialogContent>
              <DialogContent dividers>
                <InputRow label="Reviewed Date" classes={classes} description="">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      key={'datePicker'}
                      label="Select a date"
                      disableFuture
                      value={reviewDate}
                      onChange={(value => {
                        const formattedDate = `${new String(value.$M + 1).padStart(2, '0')}/${new String(value.$D).padStart(2, '0')}/${new String(value.$y).padStart(4, '0')}`;
                        setReviewDate(formattedDate);
                      })}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </InputRow>
                <InputRow label="Product ID" classes={classes}>
                  <TextField
                    name="productID"
                    error={notUUIDPatternMatch(productID)}
                    onChange={(e) => {
                      setProductID(e.target.value)
                    }}
                    placeholder="What is the product ID for this review?"
                    multiline
                    defaultValue={productID}
                    variant="outlined"
                    size="small"
                    className={classes.inputOutline}
                    fullWidth
                  />
                </InputRow>
                <InputRow label="Reviewer Name" classes={classes}>
                  <TextField
                    name="insertedName"
                    // error={productID )}
                    onChange={(e) => {
                      setInsertedName(e.target.value)
                      // setReviewStartedValue(true)
                    }}
                    placeholder="What is the reviwers name for this review?"
                    multiline
                    defaultValue={insertedName}
                    // helperText={`${reviewText.length}/550`}
                    variant="outlined"
                    size="small"
                    className={classes.inputOutline}
                    fullWidth
                  // inputProps={{ maxLength: MAX_REVIEW_LENGTH }}
                  />
                </InputRow>
                <InputRow label="Write a Review" classes={classes}>
                  <TextField
                    name="review"
                    error={reviewText.length < MIN_REVIEW_LENGTH && (reviewText.length > 0 || reviewStarted)}
                    onChange={(e) => {
                      setReviewValue(e.target.value)
                      setReviewStartedValue(true)
                    }}
                    placeholder="What did you like or dislike? What did you use this product for?"
                    multiline
                    defaultValue={reviewText}
                    helperText={`${reviewText.length}/550`}
                    variant="outlined"
                    size="small"
                    className={classes.inputOutline}
                    fullWidth
                    inputProps={{ maxLength: MAX_REVIEW_LENGTH }}
                  />
                </InputRow>
                {/* small typography that lets them know the minimum character count */}
                <Typography className={classes.minCharCount}>
                  {reviewText.length < MIN_REVIEW_LENGTH && (reviewText.length > 0 || reviewStarted) ? `Minimum ${MIN_REVIEW_LENGTH} characters` : ""}
                </Typography>
                <br />
                <Grid item xs={12} md={12}>
                  <Typography className={classes.headers}><b>Product Photos</b></Typography>
                  <Typography>
                    You may only upload up to four photos.
                  </Typography>
                  <FileUploadV2
                    formValueName={"images"}
                    formValueArray={formValues.images}
                    maxFiles={4}
                    columns={4}
                    handleFormValues={handleFormValues}
                    buttonText={'upload: jpg, png'}
                  />
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={handleReviewSubmit}
                  className={classes.writeAReviewButton}
                  disabled={reviewText.length < MIN_REVIEW_LENGTH || !stars || stars < 0.5 || loadingSubmitReview}
                >
                  Submit
                </Button>
                {reviewExists ? (<Button
                  autoFocus
                  onClick={handleReviewDelete}
                  className={classes.deleteAReviewButton}
                >
                  Delete
                </Button>) : ""}

                <Button
                  autoFocus
                  onClick={handleDialogClose}
                  className={classes.closeButton}
                >
                  Close
                </Button>
              </DialogActions>
            </ReviewDialog>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default AdminInsertReview;
