import { useEffect } from "react";

const MAX_PRODUCTS_PER_GALLERY_PAGE = 32;

function useGalleryPages(
  currentProductList,
  setProductGallery,
  maxPerPage = MAX_PRODUCTS_PER_GALLERY_PAGE
) {
  useEffect(() => {
    // Updates gallery pages based on the filtered product list
    let newProductGallery = [];
    const numPages = Math.ceil(currentProductList.length / maxPerPage);
    for (let i = 0; i < numPages; i++) {
      const start = i * maxPerPage;
      newProductGallery.push(
        currentProductList.slice(start, start + maxPerPage)
      );
    }
    setProductGallery(newProductGallery);
  }, [currentProductList, setProductGallery, maxPerPage]);
}

export default useGalleryPages;
