import { getSessionJwt, getJsonCache, setJsonCache } from "./utilities";

const {
  NODE_API_ENDPOINT
} = require("./constants");

async function createOrder(cart, coupon, selectedShippingInfo, callback) {
  const jwtToken = await getSessionJwt();
  const reqBody = {
    cart: cart,
    couponCode: coupon,
    selectedShippingInfo: selectedShippingInfo,
  }
  fetch(NODE_API_ENDPOINT + "/create-order", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function getOrderById(id, callback) {
  const jwtToken = await getSessionJwt();

  let requestURL = NODE_API_ENDPOINT + "/get-order-by-id?id=" + id;

  fetch(requestURL, {
    method: "GET",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function getOrdersByUserId(id, callback) {
  const jwtToken = await getSessionJwt();

  let requestURL = NODE_API_ENDPOINT + "/get-order-by-user-id?id=" + id;

  fetch(requestURL, {
    method: "GET",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function getOrderPurposesByUserId(id, callback) {
  const jwtToken = await getSessionJwt();

  let requestURL = NODE_API_ENDPOINT + "/get-order-purposes-by-user-id?id=" + id;

  fetch(requestURL, {
    method: "GET",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function getOrdersBySellerId(sellerID, callback) {
  const jwtToken = await getSessionJwt();

  let requestURL = NODE_API_ENDPOINT + "/get-order-by-seller-id?sellerID=" + sellerID;

  fetch(requestURL, {
    method: "GET",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function getOrdersByEmail(email, callback) {
  const jwtToken = await getSessionJwt();

  let requestURL = NODE_API_ENDPOINT + "/get-orders-by-email?email=" + email;

  fetch(requestURL, {
    method: "GET",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function getOrdersByUsername(username, callback) {
  const jwtToken = await getSessionJwt();

  let requestURL = NODE_API_ENDPOINT + "/get-orders-by-username?username=" + username;

  fetch(requestURL, {
    method: "GET",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function getOrdersByOrderIdShortened(orderIdShortened, callback) {
  const jwtToken = await getSessionJwt();

  let requestURL = NODE_API_ENDPOINT + "/get-orders-by-order-id-shortened?orderIdShortened=" + orderIdShortened;

  fetch(requestURL, {
    method: "GET",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function getOrderPlacedData(id, callback) {
  let requestURL = NODE_API_ENDPOINT + "/get-order-placed-data?id=" + id;

  fetch(requestURL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function markOrderAsCompleted(sellerID, orderID, callback) {
  const jwtToken = await getSessionJwt();
  const reqBody = {
    sellerID, 
    orderID: orderID
  }
  fetch(NODE_API_ENDPOINT + "/mark-order-as-completed", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function markOrderAsSubmitted(sellerID, orderID, callback) {
  const jwtToken = await getSessionJwt();
  const reqBody = {
    sellerID, 
    orderID: orderID
  }
  fetch(NODE_API_ENDPOINT + "/mark-order-as-submitted", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function addOrderShippingDetails(sellerID, orderID, shippingTrackingCarrier, shippingTrackingNumber, callback) {
  const jwtToken = await getSessionJwt();
  const reqBody = {
    sellerID, 
    orderID: orderID,
    shippingTrackingCarrier: shippingTrackingCarrier,
    shippingTrackingNumber: shippingTrackingNumber,
  }
  fetch(NODE_API_ENDPOINT + "/add-order-shipping", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

function isToday(date) {
  const currentDate = new Date();

  // Check if the year, month, and day of the passed in date/time are the same as the current date/time
  return date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getDate() === currentDate.getDate();
}

function isYesterday(date) {
  const currentDate = new Date();

  // Get the date/time for yesterday
  const yesterdayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);

  // Check if the year, month, and day of the passed in date/time are the same as yesterday's date/time
  return date.getFullYear() === yesterdayDate.getFullYear() &&
    date.getMonth() === yesterdayDate.getMonth() &&
    date.getDate() === yesterdayDate.getDate();
}

function isThisYear(date) {
  const currentYear = new Date();
  
  return date.getFullYear() === currentYear.getFullYear();
}

function isLastYear (date) {
  const currentDate = new Date();
  const lastYear = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate());
  
  return date.getFullYear() === lastYear.getFullYear();
}

function isLastMonth (date) {
  const currentDate = new Date();
  const lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
  
  return date.getFullYear() === lastMonth.getFullYear() &&
  date.getMonth() === lastMonth.getMonth();
}

function isThisMonth (date) {
  const currentDate = new Date();
  const currentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

  return date.getFullYear() === currentMonth.getFullYear() &&
  date.getMonth() === currentMonth.getMonth();
}

function isWithinLast7Days(date) {
  // Get the current date/time
  const currentDate = new Date();

  // Calculate the difference between the current date/time and the passed in date/time, in milliseconds
  const diff = currentDate - date;

  // Convert the difference from milliseconds to days
  const diffInDays = diff / 1000 / 60 / 60 / 24;

  // Return true if the difference is less than or equal to 7 days, false otherwise
  return diffInDays <= 7;
}

function isWithinLast30Days(date) {
  const currentDate = new Date();

  // Calculate the difference between the current date/time and the passed in date/time, in milliseconds
  const diff = currentDate - date;

  // Convert the difference from milliseconds to days
  const diffInDays = diff / 1000 / 60 / 60 / 24;

  // Return true if the difference is less than or equal to 30 days, false otherwise
  return diffInDays <= 30;
}

function calcDateDifference(date) {
  const currentDate = new Date();

  currentDate.setHours(0,0,0,0)
  date.setHours(0,0,0,0)

  // Calculate the difference between the current date/time and the passed in date/time, in milliseconds
  const diff = currentDate - date;

  // Convert the difference from milliseconds to days
  const diffInDays = Math.floor(diff / 1000 / 60 / 60 / 24);

  return diffInDays;
}

function getNumDaysInMonth(date) {
  const year = date.getFullYear()
  const month = date.getMonth()

  const nextMonth = new Date(year, month + 1, 1);

  // Get the number of days in the current month by subtracting the first day of the next month from the first day of the current month
  const numDays = nextMonth - new Date(year, month, 1);

  // Return the number of days, converted to an integer
  return Math.floor(numDays / (1000 * 60 * 60 * 24));
}

function getDayLabels(date) {
  const dayLabels = [];

  const year = date.getFullYear()
  const month = date.getMonth()

  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Add labels for each day of the month to the array
  for (let i = 1; i <= 31; i++) {
    // Create a new Date object for the current day
    const date = new Date(year, month, i);

    // Check if the date is valid (i.e., within the current month)
    if (date.getMonth() === month) {
      // Get the day of the week for the current day (0 = Sunday, 1 = Monday, etc.)
      const dayOfWeek = date.getDay();

      // Get the abbreviated day name and month name
      const dayName = dayNames[dayOfWeek];
      const monthName = monthNames[month];

      // Get the day number, padded with a leading zero if necessary
      const dayNumber = i.toString().padStart(2, '0');

      // Create the label for the current day
      const label = `${dayName} ${monthName} ${dayNumber}`;

      // Add the label to the array
      dayLabels.push(label);
    }
  }

  return dayLabels;
}

function getDayLabelsForLast30Days() {
  const dayLabels = [];

  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  // Get the abbreviated day names
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  // Get the abbreviated month names
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Add labels for each day of the last 30 days to the array
  for (let i = 0; i < 30; i++) {
    // Calculate the year and month for the current day
    let year = currentYear;
    let month = currentMonth;
    if (i > currentDate.getDate()-1) {
      month--;
      if (month < 0) {
        month = 11;
        year--;
      }
    }

    // Create a new Date object for the current day
    const date = new Date(year, month+1, currentDate.getDate() - i);
    // Get the day of the week for the current day (0 = Sunday, 1 = Monday, etc.)
    const dayOfWeek = date.getDay();

    // Get the abbreviated day name and month name
    const dayName = dayNames[dayOfWeek];
    const monthName = monthNames[month];

    // Get the day number, padded with a leading zero if necessary
    const dayNumber = date.getDate().toString().padStart(2, '0');

    // Create the label for the current day
    const label = `${dayName} ${monthName} ${dayNumber}`;

    dayLabels.push(label);
  }
  dayLabels.reverse();
  return dayLabels;
}

function getDayLabelsForLast7Days() {
  const dayLabels = [];
  const currentDate = new Date();

  // Get the current year and month
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  // Get the abbreviated day names
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  // Get the abbreviated month names
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Add labels for each day of the last 30 days to the array
  for (let i = 0; i < 7; i++) {
    // Calculate the year and month for the current day
    let year = currentYear;
    let month = currentMonth;
    if (i > currentDate.getDate()-1) {
      month--;
      if (month < 0) {
        month = 11;
        year--;
      }
    }

    // Create a new Date object for the current day
    const date = new Date(year, month+1, currentDate.getDate() - i);
    // Get the day of the week for the current day (0 = Sunday, 1 = Monday, etc.)
    const dayOfWeek = date.getDay();

    // Get the abbreviated day name and month name
    const dayName = dayNames[dayOfWeek];
    const monthName = monthNames[month];

    // Get the day number, padded with a leading zero if necessary
    const dayNumber = date.getDate().toString().padStart(2, '0');

    // Create the label for the current day
    const label = `${dayName} ${monthName} ${dayNumber}`;

    // Add the label to the array
    dayLabels.push(label);
  }
  dayLabels.reverse();
  return dayLabels;
}

function getRangeLabel(startDate, endDate) {

  const startYearNumber = startDate.getFullYear();
  const startMonthNumber = (startDate.getMonth() + 1).toString().padStart(2, '0');
  const startDayNumber = startDate.getDate().toString().padStart(2, '0');

  const endYearNumber = endDate.getFullYear();
  const endMonthNumber = (endDate.getMonth() + 1).toString().padStart(2, '0');
  const endDayNumber = endDate.getDate().toString().padStart(2, '0');

  // Create the label for the range of dates
  const label = `${startMonthNumber}/${startDayNumber}/${startYearNumber} - ${endMonthNumber}/${endDayNumber}/${endYearNumber}`;

  // Return the label
  return label;
}

export { createOrder, getOrderById, getOrdersByUserId, getOrderPurposesByUserId, getOrdersBySellerId, getOrdersByEmail, getOrdersByUsername, getOrdersByOrderIdShortened, getOrderPlacedData, markOrderAsSubmitted, markOrderAsCompleted, addOrderShippingDetails, isToday, isYesterday, getRangeLabel,
   isLastYear, isThisYear, isLastMonth, isThisMonth, isWithinLast7Days, isWithinLast30Days, getNumDaysInMonth, getDayLabels, getDayLabelsForLast7Days, getDayLabelsForLast30Days, calcDateDifference};
