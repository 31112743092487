import { ButtonBase, Container, Typography, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";
import clsx from "clsx";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import teamMemberData from "../json/team_members.json";

const responsive = {
  break1: {
    breakpoint: { max: 4000, min: 1150 },
    items: 3,
  },
  break2: {
    breakpoint: { max: 1150, min: 650 },
    items: 2,
  },
  break3: {
    breakpoint: { max: 650, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles({
  navButtons: {
    position: "absolute",
    top: "39%",
    height: 75,
    border: "1px solid teal",
    borderRadius: 20,
    backgroundColor: "#FFFFFF",
  },
  prevButton: {
    left: -40,
  },
  nextButton: {
    right: -40,
  },
  divContainer: {
    position: "relative",
    width: "100%",
    margin: "auto",
  },
  arrows: {
    color: "teal",
    fontSize: 30,
  },
  carouselContainer: {
    width: "100%",
  },
  // profileImage: {
  //   objectFit: "cover",
  //   width: "265px",
  //   height: "265px",
  //   borderRadius: "50%"
  // },
  profileImage: {
    width: "100%",
    borderRadius: "50%",
    aspectRatio: 1/1,
    objectFit: "cover",
  },
});

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  const classes = useStyles();
  return (
    <>
      <ButtonBase
        className={clsx(classes.navButtons, classes.prevButton)}
        onClick={previous}
      >
        <ArrowBack className={classes.arrows} />
      </ButtonBase>
      <ButtonBase
        className={clsx(classes.navButtons, classes.nextButton)}
        onClick={next}
      >
        <ArrowForward className={classes.arrows} />
      </ButtonBase>
    </>
  );
};

const TeamMember = (props) => {
  const { item } = props;

  return (
    <div
      style={{
        width: "100%",
        position: "relative"
      }}
    >
      <img
        style={{
          objectFit: "cover",
          borderRadius: 16,
          width: "100%",
          height: 295,
        }}
        src={item.picture}
        alt={item.name}
      />
      <div
        style={{
          width: "80%",
          backgroundColor: "rgb(255, 255, 255, 0.5)",
          color: "black",
          borderRadius: 5,
          paddingBottom: 2,
          position: "absolute",
          bottom: "8px",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        <Typography align="center" variant="h6" className={"roboto-medium"}>
          {item.name}
        </Typography>
        <Typography align="center" className={"roboto-medium-italic"}>
          {item.position}
        </Typography>
      </div>
    </div>
  );
};

const TeamCarousel = (props) => {
  const { data, classes } = props;

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      {data &&
        data.map((item, i) => {
          return (
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TeamMember item={item} key={i} />
            </Grid>
          );
        })}
    </Grid>
  );
};

const MeetTheTeam = (props) => {
  const { data = teamMemberData } = props;
  const classes = useStyles();
  return (
    <Container maxWidth="xl">
      <Typography
        align="center"
        variant="h3"
        style={{ marginBottom: "var(--dividerHeight)", color: "var(--coral)" }}
        className={clsx("roboto-light")}
      >
        MEET THE TEAM
      </Typography>
      <div className={classes.divContainer}>
        {/* <TeamCarousel data={data.teamMembers2022} classes={classes} /> */}
        <Container maxWidth="lg">
          <Grid container spacing={4} justifyContent="center" >
            {data.teamMembers2024.map((item, index) => {
              return (
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <img
                    src={item.picture}
                    alt={item.name}
                    className={classes.profileImage}
                  />
                  <div style={{ height: "16px" }} />
                  <Typography className={clsx("roboto-bold")} variant="h3" style={{fontSize: "28px"}} align="center">{item.name}</Typography>
                  <Typography className={clsx("roboto-light")} variant="h5" align="center">{item.position}</Typography>

                </Grid>
              )
            })}
          </Grid>
        </Container>
      </div>
      <div style={{ height: "var(--dividerHeight" }} />

    </Container>
  );
};

export default MeetTheTeam;
