import { useEffect } from "react";
import {
  Button,
  Container,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Box,
  Modal,
  Typography,
  CircularProgress,
  stepConnectorClasses,
  Grid,
  Link
} from "@mui/material";
import { useState, useRef } from "react";
import AboutYourShop from "./AboutYourShop";
import TermsOpenAShop from "./TermsOpenAShop";
import ShippingOpenAShop from "./ShippingOpenAShop";
import OpenAShopMedia from "./OpenAShopMedia";
import OpenAShopPayments from "./OpenAShopPayments";
import { styled } from "@mui/styles";
import { createNewSeller, getSellerByUserId } from "../util/sellers";
import { getAllPurposes } from "../util/purposeCategories";
import countriesCoords from "../json/countries_coordinates.json";
import toast, { Toaster } from 'react-hot-toast';
import { Auth } from "aws-amplify";
import { BUSINESS_TYPE, COMPANY_TYPE, RETURN_POLICY_LIST } from "../util/constants";
 import { useLocation } from "react-router-dom";
import { getSizeInMegaBytes, isValidEmail } from "../util/utilities";
import { uploadImageArray } from "../util/images";
import publicIp from '../util/publicIp';

import axios from "axios";

const Instructions = styled(Container)({
  paddingTop: 80,
  paddingBottom: 80,
});

const StyledContainer = styled(Container)({
  paddingTop: 50,
  paddingBottom: 50,
});

const StyledStepConnector = styled(StepConnector)({
  [`&.${stepConnectorClasses.root}`]: {
    top: 29,
    left: "-51%",
    right: "49%",
    zIndex: -1,
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: "var(--beige)",
  },
});

const StyledButton = styled(Button)({
  backgroundColor: "var(--mintGreenSolid)",
  color: "#FFFFFF",
  textTransform: "none",
  paddingLeft: 30,
  paddingRight: 30,
  "&:hover": {
    backgroundColor: "var(--mintGreenSolid)",
  },
});

const NavButtons = (props) => {
  const { activeStep, handleBack, handleNext, steps } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "85%",
        margin: "auto",
      }}
    >
      {activeStep !== 0 ? (
        <StyledButton disabled={activeStep === 0} onClick={handleBack}>
          <Typography className={"roboto-medium"}>Back</Typography>
        </StyledButton>
      ) : (
        <div></div>
      )}
      <StyledButton variant="contained" color="primary" onClick={handleNext}>
        {activeStep === steps.length - 1 ? (
          <Typography className={"roboto-medium"}>Submit</Typography>
        ) : (
          <Typography className={"roboto-medium"}>Next</Typography>
        )}
      </StyledButton>
    </div>
  );
};

const BulbStepIconImg = styled("img")({
  height: 40,
  width: "auto",
});

function BulbStepIcon(props) {
  const { active, completed } = props;

  return (
    <BulbStepIconImg
      src={
        active || completed
          ? "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/logos/Coral+lightbulb+Icon-14-01.png"
          : "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/logos/green_bulb_translucent.png"
      }
      alt=""
    />
  );
}

async function getPublicIPAddress() {

  try {
  const response = await fetch('https://api.ipify.org/?format=json');
  const data = await response.json();
  return data.ip;
  } catch (error) {
    console.log(error);
  }

  try {
    const response2 = await axios.get('https://geolocation-db.com/json/')
    return response2.data.IPv4;
  } catch (error) {
    console.log(error);
  }
  return null;
}

const OpenAShopStepper = () => {
  const [pendingSubmit, setPendingSubmit] = useState(false);
  const [loggedinStatus, setLoggedinStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingGetSeller, setLoadingGetSeller] = useState(true);
  const [loadingCreateSeller, setLoadingCreateSeller] = useState(false);

  const [hasAStore, setHasAStore] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    "About your shop",
    "Media",
    "Shipping & Returns",
    "Verification",
    "Purposer Terms",
  ];
  const [productPurposes, setProductPurposes] = useState([]);
  const [formPurposes, setFormPurposes] = useState({ mainPurposes: [{}], subPurposes: [] });

  useEffect(() => {
    getAllPurposes((data) => {
      if (data) {
        setProductPurposes(data);
        //setIsRequesting(false);
      } else {
        //setRequestFailed(true);
        console.log("Couldn't get purposes!")
      }
    });
  }, []);

  useEffect(() => {
    if (loadingGetSeller) {
      setLoadingGetSeller(false);
      Auth.currentAuthenticatedUser().then((user) => {
        getSellerByUserId(user.attributes.sub, (data) => {
          if (data) {
            setHasAStore(data && data.sellerID ? data.sellerID : false);
            setLoading(false);
            //setIsRequesting(false);
          } else {
            //setRequestFailed(true);
            console.log("Couldn't get results on if this user has a store!")
            setLoading(false);
          }
        });
      }, 
      (error) => {
        setHasAStore(false);
        setLoading(false);
      },
      {});
    }



  });

  useEffect(() => {
    if (!formValues.tosAcceptanceIP) {
      setTimeout(function() { getIp(); }, 1000 * 5)
    }
  });

  const getIp = () => {
    publicIp.v4()
      .then((result) => {
        if(result) { 
          handleFormValues('tosAcceptanceIP', result);
        }
      }, (error) => {
        console.log(error);
      });
  }

  // const purposes = [
  //   ...formData.selectedPurposes.mainPurposes.filter(p => p.purposeName),
  //   ...formData.selectedPurposes.subPurposes.flat().filter(p => p.purposeName)
  // ];

  const [formValues, setFormValues] = useState({
    storeName: "",
    foundedDate: "",
    purposes: [],
    countriesImpacted: [],
    shortBio: "",
    businessLogo: null,
    banner_images: [],
    profile_pic: [],
    verificationFront: null,
    verificationBack: null,
    paymentMethod: "",
    shippingPolicies:  [
      { id: 0, shipping: "", shippingCost: "" },
      { id: 1, shipping: "", shippingCost: "" },
      { id: 2, shipping: "", shippingCost: "" }
    ],
    shippingToCounties: [],
    duplicateStoreName: false
  });

  const containerRef = useRef(null);



  const createToastFromArray = (wordArray) => {
    const allText = wordArray.join('\n');
    toast((t) => (
      <span>
        {allText}
        {'\n'}
        <Grid container justifyContent="center">
          <Button onClick={() => toast.dismiss(t.id)}>
            Dismiss
          </Button>
        </Grid>
      </span>
    ));
  }

  const checkValuesAboutYourShop = (formValues, formPurposes, errorsInSubmission) => {
    const countriesImpacted = formValues.countriesImpacted.length > 0 && formValues.countriesImpacted.map((country) => countriesCoords.find(c => c.code == country.code));
    const businessMCC = formValues.businessMCC?.value;

    //check to make sure each main purpose has a subpurpose
    const mainPurposes = formPurposes.mainPurposes.filter(p => p.productPurposeID);
    const subPurposes = formPurposes.subPurposes.flat().filter(p => p.productPurposeID);
    const mainPurposesWithSubPurposes = mainPurposes.filter(p => subPurposes.find(s => s.higherPurposeID == p.productPurposeID));
    if (mainPurposes.length > 0 && mainPurposes.length != mainPurposesWithSubPurposes.length) errorsInSubmission.push("Must select a subpurpose for each main purpose!");

    if (!formValues.storeName) errorsInSubmission.push("Store Name can't be empty!");
    if (formValues.storeName && formValues.storeName.length < 5 || formValues.storeName.length > 30) errorsInSubmission.push("Store Name must be between 5 and 50 characters!");
    if (formValues.duplicateStoreName) errorsInSubmission.push("Store Name is already is use!");
    if (!formValues.foundedDate || formValues.foundedDate.length < 1) errorsInSubmission.push("Must have a founded date!");
    if (!formPurposes.mainPurposes || formPurposes.mainPurposes?.filter(p => p.productPurposeID)?.length < 1) errorsInSubmission.push("Must select at least one purpose!");
    if (!countriesImpacted) errorsInSubmission.push("Must list which countries are impacted!");
    if (!businessMCC) errorsInSubmission.push("Must select a Merchant code that fits your store!");
    if (!formValues.shortBio) errorsInSubmission.push("Bio can't be empty!");
    if (formValues.shortBio?.length < 350) errorsInSubmission.push("Bio must be longer than 350 characters!");

    return errorsInSubmission;
  }

  const checkValuesOpenAShopMedia = (formValues, errorsInSubmission) => {
    const banner_images = formValues.banner_images;
    const profile_pic = formValues.profile_pic;

    if (!banner_images || banner_images.length < 1) errorsInSubmission.push("Must have banner images!");
    if (!profile_pic || profile_pic.length < 1) errorsInSubmission.push("Must have a store logo!");
  }

  const checkValuesShippingOpenAShop = (formValues, errorsInSubmission) => {
    // const shippingPolicies = formValues.shippingPolicies.length > 0 && formValues.shippingPolicies.map((policy) => policy.shipping && policy.shippingCost && ({
    //   shipping: policy.shipping,
    //   shippingOverFreeAmt: policy.shippingCost
    // }));
    // const shippingToCountries = formValues.shippingToCounties.length > 0 && formValues.shippingToCounties.map((country) => ({
    //   code: country.code,
    //   country: country.label,
    //   ...countriesCoords.find(c => c.code == country.code)
    // }));
    const returnPolicy = formValues.returnPolicy && formValues.returnPolicy.policy;
    //const returnPolicyFee = formValues.returnPolicy && formValues.returnPolicy.feeIncluded ? formValues.returnPolicy.fee != null : true;
    
    if (formValues?.returnPolicy?.policy == RETURN_POLICY_LIST[1].policy) {
      formValues.returnPolicy.addressReturnCountry = 'US';
      if (!formValues.returnPolicy.addressReturnName) errorsInSubmission.push("Must have a return address name");
      if (!formValues.returnPolicy.addressReturnLine1) errorsInSubmission.push("Must have a return address line");
      if (!formValues.returnPolicy.addressReturnCity) errorsInSubmission.push("Must have a return address city");
      if (!formValues.returnPolicy.addressReturnCountry || formValues.returnPolicy.addressReturnCountry.length != 2) errorsInSubmission.push("Must have a return address country code that is 2 letters!");
      if (!formValues.returnPolicy.addressReturnState || formValues.returnPolicy.addressReturnState.length != 2) errorsInSubmission.push("Must have a return address state that is 2 letters!");
      if (!formValues.returnPolicy.addressReturnPostalCode || formValues.returnPolicy.addressReturnPostalCode?.length != 5) errorsInSubmission.push("Must have a 5 digit return address postal code");
    }
    // if (!shippingPolicies) errorsInSubmission.push("Must select a shipping policy!");
    // if (!shippingToCountries) errorsInSubmission.push("Must select which countries you ship to!");
    if (!returnPolicy) errorsInSubmission.push("Must select your return policy!");
  }

  function getNumbersInParentheses(str) {
    const matches = str.match(/\((\d+)\)/);
    if (matches && matches.length > 1) {
      return matches[1];
    }
    return null;
  }

  const checkValuesOpenAShopPayments = (formValues, errorsInSubmission) => {
    const accountHolderName = formValues.storeName;
    const businessType = formValues.businessType;
    const companyType = formValues.companyType;
    const last4SSN = formValues.last4SSN;
    const fullSSN = formValues.fullSSN;
    const companyTaxId = formValues.companyTaxId;
    const percentageOwned = formValues.percentageOwned;
    const ownerPhoneNumber = formValues.ownerPhoneNumber;
    // const addressOwnerLine1 = formValues.addressOwnerLine1;
    // const addressOwnerLine2 = formValues.addressOwnerLine2; //Not required
    // const addressOwnerCity = formValues.addressOwnerCity;
    // formValues.addressOwnerCountry = 'US';
    // const addressOwnerCountry = formValues.addressOwnerCountry;
    // const addressOwnerState = formValues.addressOwnerState;
    // const addressOwnerPostalCode = formValues.addressOwnerPostalCode;
    const idVerificationImageFront = formValues.verificationFront && formValues.verificationFront.length > 0 && formValues.verificationFront[0];
    const idVerificationImageBack = formValues.verificationBack && formValues.verificationBack.length > 0 && formValues.verificationBack[0];
    const idVerificationCompanyImageFront = formValues.verificationCompanyFront && formValues.verificationCompanyFront.length > 0 && formValues.verificationCompanyFront[0];
    const idVerificationCompanyImageBack = formValues.verificationCompanyBack && formValues.verificationCompanyBack.length > 0 && formValues.verificationCompanyBack[0];

    formValues.addressCountry = 'US';


    if (!formValues.firstName) errorsInSubmission.push("First Name can't be empty!");
    if (!formValues.lastName) errorsInSubmission.push("Last Name can't be empty!");
    if (!formValues.email || !isValidEmail(formValues.email)) errorsInSubmission.push("Email must be valid!");
    if (!formValues.phoneNumber || formValues.phoneNumber?.length < 17) errorsInSubmission.push("Must have a full phone number!");
    if (formValues.phoneNumber && formValues.phoneNumber?.length >= 17) {
      const currAreaCode = getNumbersInParentheses(formValues.phoneNumber);
      const currAreaCodeAsNumber = parseInt(currAreaCode);
      if (currAreaCodeAsNumber < 201 || currAreaCodeAsNumber > 989) errorsInSubmission.push("Must have a valid area code!");
    }
    
    if (!formValues.dobDay || !formValues.dobMonth || !formValues.dobYear) errorsInSubmission.push("Must have full date of birth selected.");
    if (formValues.dobDay && formValues.dobMonth && formValues.dobYear) {
      //check if they are over 18
      const eighteenYearsAgo = new Date();
      eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
      new Date(formValues.dobYear, formValues.dobMonth, formValues.dobDay) > eighteenYearsAgo && errorsInSubmission.push("Must be 18 years or older to create a store!");
    }
    if (!formValues.addressLine1) errorsInSubmission.push("Must have an address line");
    if (formValues.addressLine1) {
      const poBoxRegex = /P\.?O\.?\s?Box\s?\d+/i;
      const addressWithoutSpacesOrPeriods = formValues.addressLine1.replace(/\.|\s/g, '').toLowerCase();
      if (poBoxRegex.test(addressWithoutSpacesOrPeriods)) errorsInSubmission.push("Must have an address line that is not a PO Box!");
    }
    if (!formValues.addressCity) errorsInSubmission.push("Must have a city");
    if (!formValues.addressCountry || formValues.addressCountry.length != 2) errorsInSubmission.push("Must have a country code that is 2 letters!");
    if (!formValues.addressState || formValues.addressState.length != 2) errorsInSubmission.push("Must have a state that is 2 letters!");
    if (!formValues.addressPostalCode || formValues.addressPostalCode?.length != 5) errorsInSubmission.push("Must have a 5 digit postal code");


    if (!accountHolderName) errorsInSubmission.push("Store Name can't be empty as it is used for the bank account addition!");
    if (!businessType) errorsInSubmission.push("Must select your business type!");
    // if (businessType == BUSINESS_TYPE.INDIVIDUAL) {
    if (!fullSSN || fullSSN?.length != 11) errorsInSubmission.push("Must add a full SSN!");
    // }
    //TODO: if we don't need these, remove references to them
    // if (!idVerificationImageFront || !idVerificationImageBack) errorsInSubmission.push("Must attach front and back of ID verification!");
    if (businessType == BUSINESS_TYPE.COMPANY) {
      if (!companyType) errorsInSubmission.push("Must select your company type!");
      // if (!last4SSN || last4SSN?.length != 4) errorsInSubmission.push("Must have the last 4 of your SSN!");
      if (!companyTaxId || companyTaxId?.length != 10) errorsInSubmission.push("Must add a full company tax ID!");
      if (companyType && (companyType === COMPANY_TYPE.MULTI_MEMBER_LLC || companyType === COMPANY_TYPE.PRIVATE_CORPORATION)) {
        if (!formValues.companyPhoneNumber || formValues.companyPhoneNumber?.length < 17) errorsInSubmission.push("Must have a full company phone number!");
        if (formValues.companyPhoneNumber && formValues.companyPhoneNumber?.length >= 17) {
          const currAreaCode = getNumbersInParentheses(formValues.companyPhoneNumber);
          const currAreaCodeAsNumber = parseInt(currAreaCode);
          if (currAreaCodeAsNumber < 201 || currAreaCodeAsNumber > 989) errorsInSubmission.push("Must have a valid company phone area code!");
        }
        
        if (percentageOwned == null || percentageOwned == '' || percentageOwned?.length > 5 || Number(percentageOwned) > 100 || Number(percentageOwned) < 0) errorsInSubmission.push("Must add a percentage of company owned between 0 and 100");
        let totalPercentage = 0;
        if (percentageOwned) totalPercentage += Number(percentageOwned);
        if (formValues.owners) {
          for (const owner of formValues.owners) {
            if (owner.percentageOwned == null || owner.percentageOwned == '' || owner.percentageOwned?.length > 5 || Number(owner.percentageOwned) > 100 || Number(owner.percentageOwned) < 0) errorsInSubmission.push("Must add a percentage of company owned between 0 and 100");
            if (owner.percentageOwned) totalPercentage += Number(owner.percentageOwned);
            if (!owner.firstName) errorsInSubmission.push("Owner First Name can't be empty!");
            if (!owner.lastName) errorsInSubmission.push("Owner Last Name can't be empty!");
            if (!owner.email || !isValidEmail(owner.email)) errorsInSubmission.push("Owner Email must be valid!");
          }
        }
        if (totalPercentage > 100) errorsInSubmission.push("Total percentage of ownership can't be over 100!");
      }
      // if (!ownerPhoneNumber || ownerPhoneNumber?.length < 17) errorsInSubmission.push("Must have a full owner phone number!");
      if (!formValues.position) errorsInSubmission.push("Relationship with company can't be empty!");
      if (!formValues.title) errorsInSubmission.push("Job Title can't be empty!");
      
      formValues.addressCompanyCountry = 'US';

      if (!formValues.addressCompanyLine1) errorsInSubmission.push("Must have a company address line");
      if (formValues.addressCompanyLine1) {
        const poBoxRegex = /P\.?O\.?\s?Box\s?\d+/i;
        const addressWithoutSpacesOrPeriods = formValues.addressCompanyLine1.replace(/\.|\s/g, '').toLowerCase();
        if (poBoxRegex.test(addressWithoutSpacesOrPeriods)) errorsInSubmission.push("Must have a company address line that is not a PO Box!");
      }
      if (!formValues.addressCompanyCity) errorsInSubmission.push("Must have a company city");
      if (!formValues.addressCompanyCountry || formValues.addressCompanyCountry.length != 2) errorsInSubmission.push("Must have a company country code that is 2 letters!");
      if (!formValues.addressCompanyState || formValues.addressCompanyState.length != 2) errorsInSubmission.push("Must have a company state that is 2 letters!");
      if (!formValues.addressCompanyPostalCode || formValues.addressCompanyPostalCode?.length != 5) errorsInSubmission.push("Must have a 5 digit company postal code");

      
      // if (!addressOwnerLine1) errorsInSubmission.push("Must have an owners address line");
      // if (!addressOwnerCity) errorsInSubmission.push("Must have an owners city");
      // if (!addressOwnerCountry || addressOwnerCountry.length != 2) errorsInSubmission.push("Must have an owners country code that is 2 letters");
      // if (!addressOwnerState || addressOwnerState.length != 2) errorsInSubmission.push("Must have an owners state that is 2 letters");
      // if (!addressOwnerPostalCode) errorsInSubmission.push("Must have an owners postal code");
      //TODO: if we don't need these, remove references to them
      // if (!idVerificationCompanyImageFront || !idVerificationCompanyImageBack) errorsInSubmission.push("Must attach front and back of company verification!");
    }
  }

  // const checkValuesTermsOpenAShop = (formValues, errorsInSubmission) => {
  //   const tosAcceptanceIP = formValues.tosAcceptanceIP;
  //   if (!tosAcceptanceIP) errorsInSubmission.push("Must have your IP connected to your terms of service acceptance!");
  // }

  const handleFormValues = (name, value) => {
    setFormValues((preValues) => {
      return { ...preValues, [name]: value };
    });
  };

  const getStepOfPage = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const stepperNumberUrl = urlParams.get('step')
    const stepperNumber = stepperNumberUrl != null ? parseInt(stepperNumberUrl) : null;
    return stepperNumber;
  }

  const pageStateChange = 'pageStateChange';
  const checkValues = (changeStepValue) => {
    let currActiveStep = getStepOfPage();
    if (currActiveStep == null) {
      window.history.pushState(pageStateChange, 'step ' + '0', '/seller/signup?step=' + '0');
      currActiveStep = 0;
    }
    let nextStep = currActiveStep + changeStepValue;
    if (nextStep < 0) {
      nextStep = 0;
    }
    let shouldntPassStep = 5;

    let numberToCheck;
    if (changeStepValue < 0) {
      numberToCheck = nextStep;
    } else {
      numberToCheck = currActiveStep;
    }
    // console.log('numberToCheck', numberToCheck)
    // console.log('currActiveStep', currActiveStep)
    // console.log('nextStep', nextStep)
    let errorsInSubmission = [];
    if (numberToCheck >= 3) {
      const beforeErrors = errorsInSubmission.length;
      checkValuesOpenAShopPayments(formValues, errorsInSubmission);
      if (beforeErrors < errorsInSubmission.length) {
        shouldntPassStep = 3;
      }
    }
    if (numberToCheck >= 2) {
      const beforeErrors = errorsInSubmission.length;
      checkValuesShippingOpenAShop(formValues, errorsInSubmission);
      if (beforeErrors < errorsInSubmission.length) {
        shouldntPassStep = 2;
      }
    }
    if (numberToCheck >= 1) {
      const beforeErrors = errorsInSubmission.length;
      checkValuesOpenAShopMedia(formValues, errorsInSubmission);
      if (beforeErrors < errorsInSubmission.length) {
        shouldntPassStep = 1;
      }
    }
    if (numberToCheck >= 0) {
      const beforeErrors = errorsInSubmission.length;
      checkValuesAboutYourShop(formValues, formPurposes, errorsInSubmission);
      if (beforeErrors < errorsInSubmission.length) {
        shouldntPassStep = 0;
      }
    }
    if(errorsInSubmission.length == 0) {
      if (shouldntPassStep < nextStep) {
        nextStep = shouldntPassStep;
      }
      setActiveStep(nextStep);
      window.history.pushState(pageStateChange, 'step ' + nextStep, '/seller/signup?step=' + nextStep);

      containerRef &&
        containerRef.current &&
        containerRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      if (shouldntPassStep < nextStep) {
        nextStep = shouldntPassStep;
      }
      createToastFromArray(errorsInSubmission);
      setActiveStep(nextStep);
      window.history.pushState(pageStateChange, 'step ' + nextStep, '/seller/signup?step=' + nextStep);
    }
  }

  useEffect(() => {
    if (getStepOfPage() != null) {
      checkValues(0);
    }

  }, []);


  //useeffect to detect when url changes
  useEffect(() => {
    const handleUrlChange = (e) => {
      if (e.state == pageStateChange) {
        checkValues(0);
      }
    }
    window.addEventListener('popstate', handleUrlChange);
    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    }
  }, []);

  const handleNext = async () => {
    if (activeStep + 1 === steps.length) {
      if(loadingCreateSeller) return;
      setLoadingCreateSeller(true);
      // Submit data and redirect

      const userUuid = loggedinStatus;
      //Website info
      const sellerName = formValues.storeName;
      const purposes = [
        ...formPurposes.mainPurposes.filter(p => p.purposeName),
        ...formPurposes.subPurposes.flat().filter(p => p.purposeName)
      ];
      const bio = formValues.shortBio;
      const inActionAchievements = [];
      const inActionMapData = formValues.countriesImpacted.length > 0 && formValues.countriesImpacted.map((country) => countriesCoords.find(c => c.code == country.code));
      const inActionText = [formValues.shortBio];
      const businessMCC = formValues.businessMCC?.value;
      // const ownerPhoneNumber = formValues?.ownerPhoneNumber?.replace(/[()-\s]/g, '');
      const companyPhoneNumber = formValues?.companyPhoneNumber?.replace(/[()-\s]/g, '');
      const phoneNumber = formValues?.phoneNumber?.replace(/[()-\s]/g, '');
      const fullSSN = formValues?.fullSSN?.replace(/-/g, '');;
      const companyTaxId = formValues?.companyTaxId?.replace(/-/g, '');
      const tosAcceptanceDate = Math.round(new Date().getTime() / 1000);
      let tosAcceptanceIP = formValues?.tosAcceptanceIP;
      if (!tosAcceptanceIP) {
        const publicIP = await getPublicIPAddress();
        tosAcceptanceIP = publicIP;
      }
      //Shipping Info
      // const shippingPolicies = formValues.shippingPolicies.length > 0 && formValues.shippingPolicies.map((policy) => policy.shipping && policy.shippingCost && ({
      //   shipping: policy.shipping,
      //   shippingOverFreeAmt: policy.shippingCost
      // }));
      // const shippingToCountries = formValues.shippingToCounties.length > 0 && formValues.shippingToCounties.map((country) => ({
      //   code: country.code,
      //   country: country.label,
      //   ...countriesCoords.find(c => c.code == country.code)
      // }));
      //TODO: get rid of these completely
      const shippingPolicies = [];
      const shippingToCountries = [];
      const returnPolicy = formValues.returnPolicy;

      const idVerificationImageFront = formValues.verificationFront && formValues.verificationFront.length > 0 && formValues.verificationFront[0];
      const idVerificationImageBack = formValues.verificationBack && formValues.verificationBack.length > 0 && formValues.verificationBack[0];
      const idVerificationCompanyImageFront = formValues.verificationCompanyFront && formValues.verificationCompanyFront.length > 0 && formValues.verificationCompanyFront[0];
      const idVerificationCompanyImageBack = formValues.verificationCompanyBack && formValues.verificationCompanyBack.length > 0 && formValues.verificationCompanyBack[0];
      let errorsInSubmission = [];
      checkValuesAboutYourShop(formValues, formPurposes, errorsInSubmission);
      checkValuesOpenAShopMedia(formValues, errorsInSubmission);
      checkValuesShippingOpenAShop(formValues, errorsInSubmission);
      checkValuesOpenAShopPayments(formValues, errorsInSubmission);
      if (!tosAcceptanceIP) errorsInSubmission.push("Must have your IP connected to your terms of service acceptance!");

      // checkValuesTermsOpenAShop(formValues, errorsInSubmission);
      if (!userUuid) errorsInSubmission.push("Must be logged in!");

      if (errorsInSubmission.length > 0) {
        createToastFromArray(errorsInSubmission);
        setLoadingCreateSeller(false);
        return;
      }
      else {
        let formValuesCopy = JSON.parse(JSON.stringify(formValues));

        let processed_banner_images = [];
        let processed_profile_pic = [];
        try {
          processed_banner_images = await uploadImagesAndUpdateFormData(formValues.banner_images, "banner_images", handleFormValues);
        } catch (e) {
          console.log("failed to upload image(s)!")
        }
        if (processed_banner_images.length < 1) {
          createToastFromArray(["Failed to upload banner images!"]);
          setLoadingCreateSeller(false);
          return;
        }
        try {
          processed_profile_pic = await uploadImagesAndUpdateFormData(formValues.profile_pic, "profile_pic", handleFormValues);
        } catch (e) {
          console.log("failed to upload image(s)!")
        }


        if (processed_profile_pic.length < 1) {
          createToastFromArray(["Failed to upload profile picture!"]);
          setLoadingCreateSeller(false);
          return;
        }

        if (formValuesCopy.verificationFront) delete formValuesCopy.verificationFront
        if (formValuesCopy.verificationBack) delete formValuesCopy.verificationBack
        if (formValuesCopy.verificationCompanyFront) delete formValuesCopy.verificationCompanyFront
        if (formValuesCopy.verificationCompanyBack) delete formValuesCopy.verificationCompanyBack

        const apiReqBody = {
          ...formValuesCopy,
          adminUserId: userUuid,
          sellerName: sellerName,
          bio: bio,
          purposes: purposes,
          banner_images: processed_banner_images,
          profile_pic: processed_profile_pic,
          shippingPolicy: shippingPolicies,
          areaSupport: shippingToCountries,
          returnPolicy: returnPolicy,
          impact:"Impact Test",
          inAction:{
            header: "TestHeader",
            mapData: inActionMapData,
            achievements: inActionAchievements,
            text: inActionText,
            imgs:[],
            shopPolicies:[]
          },
          FAQ:[],
          recentlyViewed:[],
          featured:[],
          reviews:[],
          blog:[],
          idVerificationImageFront: idVerificationImageFront,
          idVerificationImageBack: idVerificationImageBack,
          idVerificationCompanyImageFront: idVerificationCompanyImageFront,
          idVerificationCompanyImageBack: idVerificationCompanyImageBack,
          businessMCC: businessMCC,
          companyPhoneNumber: companyPhoneNumber,
          phoneNumber: phoneNumber,
          fullSSN: fullSSN,
          companyTaxId: companyTaxId,
          tosAcceptanceDate: tosAcceptanceDate,
          tosAcceptanceIP: tosAcceptanceIP,
        }

        if (getSizeInMegaBytes(apiReqBody) > 5) {
          const errorMsg = [`Total size of images exceeds 5MB! [${getSizeInMegaBytes(apiReqBody)}] Try uploading smaller images. (or downscaling and re-uploading the ones you have).`];
          createToastFromArray(errorMsg);
          setLoadingCreateSeller(false);
          return;
        }
        try {
          const createNewSellerPromise = createNewSeller(apiReqBody, (response) => {
            if (
              !response.hasOwnProperty("error") &&
              !response.hasOwnProperty("message") &&
              response.hasOwnProperty("results") &&
              response.results.success && 
              response.results.hasOwnProperty("sellerId")
            ) {
              console.log(`Created a shop! Redirecting...`)
              toast.success('Created a shop! Redirecting...')
              window.location.href = "/seller/" + response.results.sellerId + "/shopInformation";

            } else {
              if (response.hasOwnProperty("error") && response?.status === 413 || response?.error?.response?.status === 413) {
                toast.error("Images in the form are too large. Please resize them to a total of less than 5MB and try again.");
              } else {
                console.log(`Didn't have all the values to reroute page!`)
                toast.error("Error when creating shop.")
              }
            }
            setLoadingCreateSeller(false);
            return;
          })
        } catch (e) {
          console.log(`Failed to create seller!`)
          const errorMsg = [`Failed to create seller!`];
          createToastFromArray(errorMsg);
          setLoadingCreateSeller(false);
          return;
        }
      }
    } else {
      checkValues(1);
    }
  };

  const uploadImagesAndUpdateFormData = async (imageArray, formDataValueName, handleFormData) => {
    const imgUrlArray = await uploadImageArray(imageArray);
    handleFormData(formDataValueName, imgUrlArray);
    return imgUrlArray;
  }

  const handleBack = () => {
    checkValues(-1);

    containerRef &&
      containerRef.current &&
      containerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <AboutYourShop
            formValues={formValues}
            handleFormValues={handleFormValues}
            productPurposes={productPurposes}
            formPurposes={formPurposes}
            setFormPurposes={setFormPurposes}
          />
          // <OpenAShopMedia
          //   formValues={formValues}
          //   handleFormValues={handleFormValues}
          // />
        );
      case 1:
        return (
          <OpenAShopMedia
            formValues={formValues}
            handleFormValues={handleFormValues}
          />
        );
      case 2:
        return (
          <ShippingOpenAShop
            formValues={formValues}
            handleFormValues={handleFormValues}
          />
        );
      case 3:
        return (
          <OpenAShopPayments
            formValues={formValues}
            handleFormValues={handleFormValues}
            setFormValues={setFormValues}
          />
        );
      case 4:
        return (
          <TermsOpenAShop
            formValues={formValues}
            handleFormValues={handleFormValues}
          />
        );
      default:
        return "Something Broke, Oops....";
    }
  };
  Auth.currentAuthenticatedUser().then((user) => {
    setLoggedinStatus(user.attributes.sub);
  }, 
  (error) => {
    setLoggedinStatus(false);
  },
  {});

  return (
    <StyledContainer maxWidth="xl" ref={containerRef}>
      <Toaster 
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <Modal open={loadingCreateSeller} sx={{ overflow: "scroll" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 2,
            bgcolor: "background.paper",
            p: 4,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography 
            variant="h6" 
            gutterBottom
            sx={{ textAlign: "center" }}
          >
            {`We're creating your store! So exciting! This may take 2-3 minutes.`}
            <br/>
            {`Please DO NOT refresh or leave this page. Thank you.`}
          </Typography>
          <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
        </Box>
      </Modal>
      {loggedinStatus && !loading && !hasAStore &&
      <div>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<StyledStepConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={BulbStepIcon}>
                <Typography>{label}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {/* <br/>
        <br/>
        <br/>
        <div style={{ textAlign: "right" }} >
          <Typography variant="body1" gutterBottom>
            {`Total Request Size: ${getSizeInMegaBytes(formValues).toFixed(2)}MB`}
          </Typography>
          {getSizeInMegaBytes(formValues) > 9.95 && (
            <Typography variant="body1" style={{color: "red"}} gutterBottom>
              <b>{`Total Request MUST be less than 5MB to submit!`}</b>
            </Typography>
          )}
        </div> */}
        <Instructions maxWidth={"md"}>{getStepContent(activeStep)}</Instructions>
        <NavButtons
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          steps={steps}
        />
      </div>
      }
      {!loggedinStatus || hasAStore && 
      <Grid
          container
          justifyContent="center"
          spacing={12}
        >
          <Grid item xs={12} sm={12} md={8}>
            <div style={{backgroundColor: "var(--coral)", padding: "64px 32px", borderRadius: "32px"}}>
              <Typography style={{textAlign: "center", color: "white", fontSize: "3.5rem"}}>Looks like you're trying to <br/> <b>Sell on Purposer!</b></Typography>
            </div>
          </Grid>
      </Grid>
      }
      <div style={{height: "var(--dividerHeight)"}}/>
      {!loggedinStatus && 
      <div>
        <Grid
          container
          justifyContent="center"
          spacing={12}
        >
          <Grid item xs={12} sm={12} md={8}>
            <div style={{ padding: "32px 32px", borderRadius: "32px", border: "solid black 2px"}}>
              <Grid container spacing={12}>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography style={{textAlign: "center", color: "black", fontSize: "3.25rem"}}><b>Step 1:</b></Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Link href="/login?redirectToCreateShop=true"><Typography style={{textAlign: "center", color: "black", fontSize: "3.25rem", textDecoration: "underline"}}>Sign into your account</Typography></Link>
                  <Typography style={{textAlign: "center", color: "black", fontSize: "2rem", padding: "16px 0"}}>or</Typography>
                  <Link href="/signup?redirectToCreateShop=true"><Typography style={{textAlign: "center", color: "black", fontSize: "3.25rem", textDecoration: "underline"}}>Make a new account!</Typography></Link>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      }
      <div style={{height: "var(--dividerHeight)"}}/>
      {hasAStore && 
      <div>
      <Grid
        container
        justifyContent="center"
        spacing={12}
      >
        <Grid item xs={12} sm={12} md={8}>
          <div style={{padding: "32px 32px", borderRadius: "32px", border: "solid black 2px"}}>
            <Grid container spacing={12}>
              <Grid item xs={12} sm={12} md={4}>
                <Typography style={{textAlign: "center", color: "black", fontSize: "3.25rem"}}><b>Step 1:</b></Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Button href={`/shop/seller/${hasAStore}`} style={{textTransform: 'none', color: "black", fontSize: "3.25rem"}}>You already have a store made! Click here to visit your Store Page.</Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
      }
    </StyledContainer>
  );
};

export default OpenAShopStepper;
