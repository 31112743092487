import { AccordionDetails, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import { ExpandLess, ExpandMore, Edit } from "@mui/icons-material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/styles";

const useStyles = makeStyles({
  subcategoryLink: {
    textDecoration: "none",
    color: "black",
  },
  editButton: {
    color: "#FFFFFF",
    backgroundColor: "var(--coral)",
    textTransform: "none",
    paddingLeft: 15,
    paddingRight: 15,
    width: "100%",
    marginTop: 25,
    "&:hover": {
      backgroundColor: "var(--coral)",
    },
  },
  editButtonText: {
    marginLeft: 10,
  },
});

const Accordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    paddingLeft: 0,
    marginBottom: -1,
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const SubpurposeAccordionSummary = withStyles({
  root: {
    paddingLeft: 0,
    marginBottom: -1,
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    "&$expanded": {
      margin: "0px 0",
    },
    margin: 0,
  },
  expanded: {},
})(MuiAccordionSummary);

const StyledAccordianDetails = styled(AccordionDetails)({
  paddingBottom: 8,
  paddingLeft: 0,
});

const BrowseByText = styled(Typography)({
  minHeight: 40,
});

const VerticallyCenterDiv = styled("div")({
  display: "flex",
  alignItems: "center",
});

const Subpurpose = (props) => {
  const { category, subpurpose, margin, classes } = props;
  const [subexpanded, setSubexpanded] = React.useState(true);

  const handleChange = (category) => (event, newExpanded) => {
    setSubexpanded(newExpanded ? category : false);
  };

  return !subpurpose.hasOwnProperty("subpurposes") ? (
    <StyledAccordianDetails style={{ marginLeft: margin }}>
      <a
        href={"/shop/purpose/" + category.id + "/" + subpurpose.id}
        className={classes.subcategoryLink}
      >
        <Typography variant="h7" className={"roboto-regular"}>
          {subpurpose.name}
        </Typography>
      </a>
    </StyledAccordianDetails>
  ) : (
    <Accordion
      square
      expanded={subexpanded}
      onChange={handleChange(subpurpose.id)}
      className={classes.accordion}
      style={{ marginLeft: margin }}
    >
      <SubpurposeAccordionSummary>
        <VerticallyCenterDiv>
          <Typography variant="h7" className={"roboto-regular"}>
            {subpurpose.name}
          </Typography>
          {subexpanded ? (
            <ExpandLess fontSize={"large"} />
          ) : (
            <ExpandMore fontSize={"large"} />
          )}
        </VerticallyCenterDiv>
      </SubpurposeAccordionSummary>
      {subpurpose.subpurposes.map((otherSubpurpose, index) => {
        return (
          <Subpurpose
            category={category}
            subpurpose={otherSubpurpose}
            margin={margin + 15}
            classes={classes}
            key={index}
          />
        );
      })}
    </Accordion>
  );
};

function ReligiousCategoryMenu() {
  let classes = useStyles();

  const [expanded, setExpanded] = React.useState(Number(7));

  const religiousCategoryData = [
    {
      id: 7,
      name: "Religious",
      subpurposes: [
        {
          id: 52,
          name: "Catholicism",
          subpurposes: [
            { id: 52, name: "Religious Orders" },
            { id: 52, name: "Catholic Charities" },
            { id: 52, name: "Evangelization" },
            { id: 52, name: "Sacraments" },
          ],
        },
        { id: 51, name: "Christianity" },
        { id: 58, name: "Judaism" },
        { id: 55, name: "Islam" },
        { id: 53, name: "Sikhism" },
        { id: 54, name: "Hinduism" },
        { id: 56, name: "Buddhism" },
        { id: 57, name: "Faith Life" },
      ],
    },
  ];

  const handleChange = (category) => (event, newExpanded) => {
    setExpanded(newExpanded ? category : false);
  };

  return (
    <menu>
      <BrowseByText variant="h5" className={"roboto-black"}>
        Browse By
      </BrowseByText>
      {religiousCategoryData.map((category, index) => {
        return (
          <Accordion
            square
            expanded={expanded === category.id}
            onChange={handleChange(category.id)}
            key={index}
            className={classes.accordion}
          >
            <AccordionSummary>
              <VerticallyCenterDiv>
                <Typography variant="h6" className={"roboto-bold"}>
                  {category.name}
                </Typography>
                {expanded === category.id ? (
                  <ExpandLess fontSize={"large"} />
                ) : (
                  <ExpandMore fontSize={"large"} />
                )}
              </VerticallyCenterDiv>
            </AccordionSummary>
            {category.hasOwnProperty("subpurposes") ? (
              category.subpurposes.map((subpurpose, index) => {
                return (
                  <Subpurpose
                    category={category}
                    subpurpose={subpurpose}
                    margin={0}
                    classes={classes}
                    key={index}
                  />
                );
              })
            ) : (
              <></>
            )}
          </Accordion>
        );
      })}
    </menu>
  );
}

export default ReligiousCategoryMenu;
