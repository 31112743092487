import { Avatar, Container, Grid, Typography, Button, Dialog, DialogTitle, } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ExpandMore, Edit } from "@mui/icons-material";
import React, { useRef } from "react";
import PurposeCarousel from "./PurposeCarousel";
import { styled } from "@mui/styles";
import clsx from "clsx";
import useElementWidth from "../hooks/useElementWidth";
import { getSellerById } from "../util/sellers";
import { getUserIdFromAuth, isPurposerUserAdmin } from "../util/utilities";
import { useHistory, useParams } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    // paddingRight: 95,
  },
  avatar: {
    margin: "auto",
  },
  avatarDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "solid",
    borderColor: "#000000",
    borderRadius: "50%",
    borderWidth: 1,
    margin: "auto",
  },
  contactSellerDiv: {
    textAlign: "center",
  },
  contactSellerImg: {
    margin: "auto",
    width: 74.391,
    height: 73.594,
    cursor: "pointer",
  },
  bioText: {
    marginTop: 35,
  },
  rightGridItem: {
    paddingLeft: 10,
  },
  moreButtonGrid: {
    marginTop: 10,
  },
  moreButtonCursor: {
    cursor: "pointer",
  },
  editButtonDiv: {
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    bottom: 45,
  },
  editButton: {
    color: "#FFFFFF",
    backgroundColor: "var(--coral)",
    textTransform: "none",
    paddingLeft: 15,
    paddingRight: 15,
    "&:hover": {
      backgroundColor: "var(--coral)",
    },
  },
  editButtonText: {
    marginLeft: 10,
  },
});

const StoreName = styled(Typography)({
  color: "var(--mintGreen)",
});

function scrollToInAction() {
  const element = document.getElementById("inAction");
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

function SellerHeader(props) {
  const classes = useStyles();
  const { sellerHeaderData, admin = false } = props;
  const { id: sellerIDparam } = useParams();
  const DEFAULT_ID = "22963625-1ae2-41d1-9041-97a8111c74a0";
  const circleGridRef = useRef(null);
  const circleGridWidth = useElementWidth(circleGridRef);
  const [headerData, setHeaderData] = React.useState({});
  const [contactSellerOpen, setContactSellerOpen] = React.useState(false);
  const history = useHistory();

  const handleContactSellerOpen = () => {
    setContactSellerOpen(false);
  };

  function getSubstringBeforeFourthPeriod(str) {
    if (!str) return "";
    const parts = str.split('.');
    const firstFour = parts.slice(0, 4).join('.').trim();
    return firstFour.endsWith('.') ? firstFour : firstFour + '.';
  }

  React.useEffect(() => {
    if (!sellerHeaderData) {
      getSellerById(sellerIDparam, (data) => {
        if (data && !data.hasOwnProperty("error")) {
          getUserIdFromAuth((userId) => {
            if (!data?.adminUserID?.toLowerCase() || !userId?.toLowerCase()) return;
            if (data.adminUserID.toLowerCase() == userId?.toLowerCase() || isPurposerUserAdmin(userId)) {
              //Valid user on page
            } else {
              history.push(`/`);
              return;
            }
          })
          setHeaderData({
            profile_pic: data.profile_pic,
            seller_name: data.seller_name,
            location: data.location,
            bio: data.bio,
            purposes: data.purposes,
            contactEmail: data.contactEmail,
            contactPhone: data.contactPhone,
          });
        } else {
          // Fetch default
          // getSellerById(DEFAULT_ID, (data) => {
          //   if (data && !data.hasOwnProperty("error")) {
          //     setHeaderData({
          //       profile_pic: data.profile_pic,
          //       seller_name: data.seller_name,
          //       location: data.location,
          //       bio: data.bio,
          //       purposes: data.purposes,
          //     });
          //   }
          // });
        }
      });
    } else {
      //Bio field is the InAction text field's first 350 characters, then the rest of it (if it is longer) is in the InAction Window
      const bioUnformatted = sellerHeaderData.inAction && sellerHeaderData.inAction.text.length > 0 && sellerHeaderData.inAction.text[0];
      const bioFormatted = getSubstringBeforeFourthPeriod(bioUnformatted);
      setHeaderData({
        profile_pic: sellerHeaderData.profile_pic,
        seller_name: sellerHeaderData.seller_name,
        location: sellerHeaderData.location,
        bio: bioFormatted,
        purposes: sellerHeaderData.purposes,
        contactEmail: sellerHeaderData.contactEmail,
        contactPhone: sellerHeaderData.contactPhone,
      });
    }
  }, [sellerIDparam, sellerHeaderData]);

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container direction="row" alignItems={"center"} rowSpacing={3}>
        <Grid item xs={5} md={2} ref={circleGridRef}>
          <div
            className={classes.avatarDiv}
            style={{
              width: 0.8 * circleGridWidth,
              height: 0.8 * circleGridWidth,
            }}
          >
            <Avatar
              src={headerData.profile_pic}
              className={classes.avatar}
              style={{
                width: 0.6 * circleGridWidth,
                height: 0.6 * circleGridWidth,
              }}
            />
          </div>
        </Grid>
        <Dialog
          onClose={handleContactSellerOpen}
          aria-labelledby="customized-dialog-title"
          open={contactSellerOpen}
          maxWidth="md"
          fullWidth

        >
          <DialogTitle id="customized-dialog-title" onClose={handleContactSellerOpen}>
            <Typography>
              <b>Contact Seller Info</b>
            </Typography>
            <Typography>
              <b>Seller Contact Email: </b>
              {headerData?.contactEmail || "N/A"}
            </Typography>
            <Typography>
              <b>Seller Contact Phone Number: </b>
              {headerData?.contactPhone || "N/A"}
            </Typography>
          </DialogTitle>
        </Dialog>
        <Grid item xs={7} md={4}>
          <StoreName
            sx={{ fontSize: { xs: "2rem", sm: "3rem" } }}
            className={"roboto-bold"}
            variant="h1"
          >
            {headerData.seller_name}
          </StoreName>
          <Typography
            sx={{ typography: { xs: "body1", sm: "h6" } }}
            className={"roboto-regular"}
          >
            {headerData.location}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <PurposeCarousel purposeCarouselData={headerData.purposes} />
        </Grid>
        <Grid item xs={12} md={2}>
          <div className={classes.contactSellerDiv}>
            <a>
              <img
                src={
                  "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Seller+Store+Page/Contact+Us+Icon-01.png"
                }
                alt=""
                className={classes.contactSellerImg}
                onClick={() => { setContactSellerOpen(true) }}
              />
              <Typography
                align="center"
                variant="subtitle1"
                className={"roboto-regular"}
                onClick={() => { setContactSellerOpen(true) }}
                sx={{cursor: "pointer"}}
              >
                Contact Seller
              </Typography>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography 
            className={clsx("roboto-regular")}
            sx={{ fontSize: { xs: "1.05rem" , lineHeight: "1.5"} }}
            variant="h2"
          >
            {headerData.bio}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.moreButtonGrid}
          >
            <Grid item>
              <div
                onClick={scrollToInAction}
                className={classes.moreButtonCursor}
              >
                <Typography>
                  <i>More.</i>
                </Typography>
              </div>
            </Grid>
            <Grid item>
              <div
                onClick={scrollToInAction}
                className={classes.moreButtonCursor}
              >
                <ExpandMore />
              </div>
            </Grid>
          </Grid>
          {admin && (
            <div className={classes.editButtonDiv}>
              <Button className={classes.editButton}>
                <Edit />
                <Typography className={classes.editButtonText}>
                  <b>Edit Bio</b>
                </Typography>
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default SellerHeader;
