import React, { useState, useRef } from "react";
import { Typography, Grid, Modal, TextField, Button, FormGroup, FormControlLabel, Checkbox, Select, MenuItem, FormControl, Input, InputLabel, Divider, IconButton, Menu } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getOrderById } from "../util/orders";
import { useReactToPrint } from 'react-to-print';
import { round, shortenUUID, formatDate, outputViewProductVariationSize } from "../util/utilities";
import ReturnSellerPopup from "./ReturnSellerPopup";
import { useParams } from "react-router-dom";
import MuiPhoneNumber from "material-ui-phone-number";

const useStyles = makeStyles({
    subheader: {
        color: "black",
        //fontSize: "1.25rem",
    },
    tabelLabel: {
        color: "black",
        fontWeight: "500",
        //fontSize: "1.25rem",
    },
    orderLabel: {
        color: "var(--coral)",
        fontSize: "16px"
    }
});

const style = {
    position: 'absolute',
    // top: '50%',
    //left: '50%',
    borderRadius: '16px',
    backgroundColor: "white",
    //transform: 'translate(-50%, -50%)',
    // display: "block",
    minWidth: "40%",
    maxHeight: "90vh",
    overflow: "auto",
    // height: "auto",
    border: "2px solid",
    zIndex: "2",
    //padding: "32px",
    boxShadow: 24,
};

const modalCenter = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

const MoreActions = (props) => {
    const { orderID, handlePrint, setReturnView } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { id: sellerIDparam } = useParams();

    const ITEM_HEIGHT = 48;
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // const handleFullRefund = async (e) => {
    //     if (!isLoading && orderID) {
    //         e.preventDefault();
    //         setIsLoading(true);
    //         refundFullOrderByOrderId(sellerIDparam, orderID, (data) => {
    //             if (data.error || data?.results == false || data?.results?.success == false) {
    //                 console.log('Error handling full Refund!')
    //                 setIsLoading(false);
    //                 setAnchorEl(null);
    //             } else {
    //                 console.log('Success handling full Refund!')
    //                 setIsLoading(false);
    //                 setAnchorEl(null);
    //                 window.location.reload()
    //             }
    //         });
    //     }
    // }

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                <MenuItem key={"Print"} onClick={handlePrint}>
                    Print
                </MenuItem>
                <MenuItem key={"RefundPartial"} onClick={(e) => { setReturnView(0); handleClose(); }}>
                    Manage Return
                </MenuItem>
                {/* <MenuItem key={"MarkAs"} onClick={(e) => {handleFullRefund(e); handleClose();}}>
                    Refund Full Order
                </MenuItem> */}
            </Menu>
        </div>
    );
}

// Type can either be: points or cash rewards
const TrackingandShippingPopup = (props) => {
    const { row, setOrderView, handleMarkOrderAsSubmitted, handleMarkOrderAsCompleted } = props;

    const classes = useStyles();
    const [moreAction, setMoreAction] = React.useState('');
    const [orderDetails, setOrderDetails] = React.useState(null);
    const [productDetails, setProductDetails] = React.useState(null);
    const [returnView, setReturnView] = useState(-1);
    const [isLoadingOrder, setIsLoadingOrder] = useState(false);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({ content: () => componentRef.current, });

    React.useEffect(() => {
        if (!productDetails && row && !isLoadingOrder) {
            setIsLoadingOrder(true);
            getOrderById(row.orderID, (data) => {
                if (data) {
                    setOrderDetails(data);
                    setProductDetails(data.products);
                } else {
                    console.log("Can't find products!")
                }
            });
        }
    }, []);

    return (
        <Modal open={true} onClose={() => setOrderView(-1)} sx={modalCenter}>
            <Box sx={style}>
                <div style={{ padding: "32px" }} ref={componentRef}>
                    <Grid container justifyContent='space-between' style={{ marginBottom: "20px" }}>
                        {returnView !== -1 ? <ReturnSellerPopup orderDetails={orderDetails} setReturnView={setReturnView} /> : <></>}
                        <CloseIcon onClick={() => setOrderView(-1)} />
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {handleMarkOrderAsCompleted != null || handleMarkOrderAsSubmitted != null ?
                                <Button onClick={(e) => {
                                    if (handleMarkOrderAsSubmitted != null) {
                                        handleMarkOrderAsSubmitted(row.orderID)
                                    } else if (handleMarkOrderAsCompleted != null) {
                                        handleMarkOrderAsCompleted(row.orderID)
                                    }
                                    setOrderView(-1)
                                }} variant="contained" style={{ marginRight: "8px", backgroundColor: "var(--mintGreenSolid)" }}
                                >
                                    <b>{handleMarkOrderAsCompleted != null ? `Mark as Completed` : `Mark as Uncompleted`}</b>
                                </Button>
                                : <Button disabled>
                                    <b>{`Completed`}</b>
                                </Button>
                            }
                            <MoreActions setReturnView={setReturnView} orderID={row.orderID} handlePrint={handlePrint} />
                        </div>
                    </Grid>
                    <Typography variant="h6" className={classes.orderLabel}>
                        ORDER NO.
                    </Typography>
                    <Typography variant="h6" component="h2" >
                        {shortenUUID(row.orderID)}
                    </Typography>
                    <Typography className={classes.subheader}>
                        {formatDate(row.orderDateTime)}
                    </Typography>
                    <Divider style={{ margin: "16px 0px" }} />
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className={classes.tabelLabel}>Items in Order</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.tabelLabel}>Price</Typography>
                        </Grid>
                        <Grid item xs={2} >
                            <Typography className={classes.tabelLabel}>Qty</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.tabelLabel}>Total</Typography>
                        </Grid>
                    </Grid>
                    <Divider style={{ margin: "16px 0px" }} />
                    {productDetails && productDetails.map((productDetail, index) =>
                        <div key={productDetail.productID + row.orderID}>
                            <Grid container >
                                <Grid item xs={6}>
                                    <Grid container direction="row" justifyContent="flex-start" columnSpacing={2}>
                                        <Grid item>
                                            <img src={productDetail.product.productImageURL[0]} style={{ maxWidth: "100px", height: "auto" }} />
                                        </Grid>
                                        <Grid item>
                                            <Typography><b>{productDetail.product.productName}</b></Typography>
                                            <Typography>Size/Style: {outputViewProductVariationSize(productDetail.product.option.size)}</Typography>
                                            <Typography>Color: {productDetail.product.option.color}</Typography>
                                            {productDetail.customizations?.length > 0 &&
                                                productDetail.customizations.map((custom, index) => {
                                                    return (
                                                        <Typography className={classes.tabelLabel}>
                                                            {`Customization #${index + 1}: ${custom}`}
                                                        </Typography>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={2} >
                                    {(round(row.products[index].price, 2) == round(row.products[index].originalPrice, 2)) ?
                                        (<Typography>
                                            ${round(row.products[index].price, 2)}
                                        </Typography>)
                                        :
                                        (<>
                                            <Typography style={{ color: "red" }}>
                                                <b>
                                                    ${round(row.products[index].price, 2)} {`($${round(round(row.products[index].originalPrice, 2) - round(row.products[index].price, 2), 2)} OFF!)`}
                                                </b>
                                            </Typography>
                                            <strike>
                                                <Typography>
                                                    ${round(row.products[index].originalPrice, 2)}
                                                </Typography>
                                            </strike>
                                        </>)
                                    }
                                </Grid>
                                <Grid item xs={2} >
                                    <Typography>{row.products[index].quantity}</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <Typography>${round((row.products[index].price * parseInt(row.products[index].quantity)), 2)}</Typography>
                                </Grid>
                            </Grid>
                            <Divider style={{ margin: "16px 0px" }} />
                        </div>
                    )}
                    <Grid container>
                        <Grid item xs={6} sm={6}></Grid>
                        <Grid item xs={6}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Typography><b>Items</b></Typography>
                                <Typography>${round(row.totalItemsOriginalAmt, 2)}</Typography>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Typography><b>Discounts</b></Typography>
                                <Typography>- ${round(row?.totalDiscountAmt, 2)}</Typography>
                            </div>
                            {row?.purposerCreatedCouponApplied === true &&
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <Typography><i>* Purposer Created Coupon Applied</i></Typography>
                                </div>
                            }
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Typography><b>Shipping</b></Typography>
                                <Typography>${round(row.totalShippingAmt, 2)}</Typography>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Typography><b>Refunds</b></Typography>
                                <Typography>- ${round(row.totalRefundedAmt, 2)}</Typography>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Typography><b>Tax</b></Typography>
                                <Typography>${round(row.totalTaxAmt, 2)}</Typography>
                            </div>
                            <Divider style={{ margin: "8px 0px" }} />
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Typography><b>GRAND TOTAL</b></Typography>
                                <Typography>${round((row.netTotalAmt), 2)}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Divider style={{ margin: "32px 0px" }} />
                    <Typography variant="h6" component="h2" style={{ color: "var(--coral)", marginBottom: "12px" }}>
                        Shipping Information
                    </Typography>
                    {/* PLACEHOLDER BUYER DATA */}
                    <Grid container rowSpacing={2} style={{ marginBottom: "12px" }}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography><b>Ship to:</b></Typography>
                            <Typography>{`${orderDetails?.shipping?.name}`}</Typography>
                            <Typography>{orderDetails?.shipping?.address?.line1}</Typography>
                            <Typography>{orderDetails?.shipping?.address?.line2}</Typography>
                            <Typography>{`${orderDetails?.shipping?.address?.city} ${orderDetails?.shipping?.address?.state} ${orderDetails?.shipping?.address?.postal_code}`}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography><b>Shipping Speed</b></Typography>
                            <Typography>{orderDetails?.shippingName}</Typography>
                            <Typography>{orderDetails?.shippingService}</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="h6" component="h2" style={{ color: "var(--coral)", marginBottom: "12px" }}>
                        Buyer Details
                    </Typography>
                    <Typography>Name: {`${orderDetails?.buyer?.userFirstName ?? ''} ${orderDetails?.buyer?.userLastName ?? ''}`}</Typography>
                    <Typography>Email: {orderDetails?.buyer?.userEmail ?? ''}</Typography>
                    {orderDetails?.buyer?.userPhoneNumber &&
                        <Grid container>
                            <Grid item xs={1}>
                                <Typography>
                                    {`Phone:`}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <MuiPhoneNumber disableAreaCodes fullWidth defaultCountry={'us'} disabled={true}
                                    value={orderDetails?.buyer?.userPhoneNumber}
                                />
                            </Grid>
                        </Grid>
                    }
                </div>
            </Box>
        </Modal>
    );
};

export default TrackingandShippingPopup;
