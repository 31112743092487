import { Container, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PurposeCategoryMasterLayout } from "../../layouts";
import { useState, useEffect } from "react";
import PressArticles from "../../components/PressArticles";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useDocumentDescription from "../../hooks/useDocumentDescription";
import { getPressReleases } from "../../util/admin";

const useStyles = makeStyles({
  headerContainer: {
    height: 500,
    position: "relative",
  },
  headerImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
  },
  textContainer: {
    padding: "40px 0px",
  },
  containerMargins: {
    margin: "auto 15px",
    fontSize: "2rem",
  },
  headerText: {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: '3.75rem',
  },
});

const PressLayout = () => {
  const classes = useStyles();

  useEffect(() => {
    getPressReleases((data) => {
      if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
        setPressArticles(data.results);
        console.log(data)
      }
    })}, []);

  const [pressArticles, setPressArticles] = useState([]);

  useDocumentTitle("Stay up to date with all the good work our ethical brands are doing on Purposer!");
  useDocumentDescription("Read our Press Releases and stay updated with all the good work our purposeful brands are doing to make the world a better place. Purpose over profit, everyday, only on Purposer.com");
  return (
    <PurposeCategoryMasterLayout>
      <Divider />
      <Container maxWidth="xl" className={classes.headerContainer}>
        <img
          src="https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Press+Page/Banner+1.jpg"
          alt="Purposer Press"
          className={classes.headerImage}
        />
      </Container>
      <Container maxWidth="lg" className={classes.textContainer}>
        <Typography align="center" variant="body1" sx={{ fontSize: "1.3rem" }}>
          At Purposer, we use press opportunities to further the conversation of
          the importance of purpose driven brands. We want to help promote and
          support our seller's and those part of our community that are making a
          positive impact on the world. Here you can read our latest interviews.
        </Typography>
        <Typography
          align="center"
          variant="body1"
          sx={{ fontSize: "1.4rem", marginTop: "var(--dividerHeight)" }}
        >
          <b>For Press Inquires Please Contact</b>
        </Typography>
        <Typography align="center" variant="body1" sx={{ fontSize: "1.4rem" }}>
          <b>info@purposer.com</b>
        </Typography>
      </Container>
      <Container maxWidth="xl">
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Divider
            sx={{
              height: 4,
              backgroundColor: "black",
              width: "40%",
            }}
          />
          <Typography
            variant="h2"
            align="center"
            className={classes.containerMargins}
          >
            <b>Press Releases</b>
          </Typography>
          <Divider
            sx={{
              height: 4,
              backgroundColor: "black",
              width: "40%",
            }}
          />
        </Container>
      </Container>

      <PressArticles pressArticles={pressArticles} />
    </PurposeCategoryMasterLayout>
  );
};

export default PressLayout;
