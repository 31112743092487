import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState, useEffect } from "react";

//Hook returns true if screen width is below "sm" small: 600px
//Can be used as an toggle for features on mobile devices or smaller screens
const useMobileMediaQuery = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobile, setMobile] = useState();

  useEffect(() => {
    setMobile(matches);
  }, [matches]);

  return mobile;
};

export default useMobileMediaQuery;
