import { TextField, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles({
  labels: {
    marginLeft: 10,
    marginBottom: 15,
  },
  textField: {
    marginBottom: 30,
    "& .MuiOutlinedInput-root": {
      borderRadius: 20,
      "& fieldset": {
        borderColor: "var(--mintGreenSolid)",
        borderWidth: "2px",
      },
      "&:hover fieldset": {
        borderColor: "var(--mintGreenSolid)",
        borderWidth: "2px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--mintGreenSolid)",
        borderWidth: "2px",
      },
    },
    "& .MuiOutlinedInput-input": {
      paddingTop: 11.5,
      paddingBottom: 10,
    },
  },
  button: {
    backgroundColor: "var(--mintGreenSolid)",
    borderRadius: 10,
    textTransform: "none",
    minWidth: 120,
    marginBottom: 30,
    "&:hover": {
      backgroundColor: "var(--mintGreenSolid)",
    },
  },
  buttonText: {
    color: "#FFFFFF",
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
});

function SignIn(props) {
  const { handleFormValues, handleSignIn } = props;
  const classes = useStyles();

  return (
    <>
      <Typography
        variant="h6"
        className={clsx(classes.labels, "roboto-medium")}
      >
        Username
      </Typography>
      <TextField
        variant="outlined"
        name="username"
        type="text"
        className={classes.textField}
        onInput={handleFormValues}
        fullWidth
      />
      <Typography
        variant="h6"
        className={clsx(classes.labels, "roboto-medium")}
      >
        Password
      </Typography>
      <TextField
        variant="outlined"
        name="password"
        type="password"
        className={classes.textField}
        onInput={handleFormValues}
        fullWidth
      />
      <br />
      <Button
        variant="contained"
        onClick={handleSignIn}
        disableElevation
        className={classes.button}
      >
        <Typography
          variant="h6"
          className={clsx(classes.buttonText, "roboto-medium")}
        >
          Sign In!
        </Typography>
      </Button>
      <a href="/login/reset" className={classes.link}>
        <Typography variant="h6" className={"roboto-light-italic"}>
          Forgot Password or Username?
        </Typography>
      </a>
    </>
  );
}

export default SignIn;
