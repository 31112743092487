import { ButtonBase, Grid, Typography, Link } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  descriptionText: {
    fontSize: "0.9rem",
  },
  sectionHeader: {
    color: "var(--mintGreen)",
    fontSize: "1.4rem",
  },
  buttons: {
    border: "1px solid lightGray",
    borderRadius: 5,
    padding: "2px 10px",
  },
  feesDiv: {
    padding: "20px 30px",
    border: "1px solid gray",
    borderRadius: 20,
  },
});

// function IpGrab(props) {
//   const { handleFormValues, formValues } = props;
//   //creating IP state
//   const [ip, setIP] = useState('');

//   //creating function to load ip address from the API
//   const getData = async () => {
//     try {
//       let res = await axios.get('https://api.ipify.org/?format=json');
//       // console.log(res.data);
//       setIP(res.data.ip)
//       handleFormValues("tosAcceptanceIP", res.data.ip)
//     } catch (e) {
//       if (window.location.href.includes('localhost')) {
//         console.log('Running locally, setting example IP.')
//         const exampleIP = '8.8.8.8';
//         setIP(exampleIP)
//         handleFormValues("tosAcceptanceIP", exampleIP)
//       }
//     }
//   }
  
//   useEffect( () => {
//     //passing getData method to the lifecycle method
//     getData()

//   }, [])

//   return (
//     <>
//     </>
//   );
// }

//Josh component
const InputRow = (props) => {
  const { label, description, classes } = props;

  return (
    <Grid
      container
      item
      direction="row"
      alignItems="center"
      sx={{ marginTop: 2 }}
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography>
          <b>{label}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {props.children}
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.descriptionText}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

const TermsOpenAShop = (props) => {
  const { handleFormValues, formValues } = props;
  const classes = useStyles();

  return (
    <>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Typography className={classes.sectionHeader}>
          Purposer Terms & Conditions
        </Typography>
        <InputRow
          label="Fees"
          classes={classes}
        >
          <div className={classes.feesDiv}>
            {/* <Typography gutterBottom>
              <b>$0.20 Cent Listing Fee:</b> This is credited back to sellers
              when their first product SKU is purchased.
            </Typography> */}
            <Typography gutterBottom>
              <b>20% Commission Fee:</b> When an item sells, we charge a 20% all-inclusive commission fee. This fee includes the commission, credit card processing fees, and marketing costs. This fee is taken off the total amount of the order, including shipping and taxes. 
            </Typography>
            <Typography gutterBottom>
              <b>Marketing Fees:</b> We never charge marketing fees. Your product listings will be promoted, free of cost, by our team through various mediums including our website, social media, email campaigns, google search engines and more. We know that we’re only successful if you are too, so we will do our best to always support you and bring awareness to your mission!
            </Typography>
            <Typography>
              <b>Other Fees:</b> {`There are no other fees, ever! No monthly fees either :)`}
            </Typography>
            <Typography>
              <b>Special Pricing:</b> At times we may extend a reduced commission fee to a seller for various reasons. This will be reflected in your seller dashboard. Furthermore, if you are ever experiencing financial difficulties, please contact our team at <u>hello@purposer.com</u> and let us know. We will do our best to find a solution for you! We believe in supporting our sellers! Purpose over profit!
            </Typography>
          </div>
        </InputRow>
        <InputRow label="Purposer Terms" classes={classes} description="">
        <Typography gutterBottom>
          {`Once you press submit, our team will begin reviewing your seller store application to make sure it meets the vision, mission and values of Purposer. While awaiting approval, please continue filling out information in your seller dashboard including the About Us section. When we fully approve your store, then you’ll be able to make product listings! 

Purposer is a marketplace platform that gives a voice to purpose-driven brands. Purpose-driven brands have a business model that goes beyond profit and gives back to the world through good deeds, charitable contributions, a buy one-give-one model and more! Our mission is to help entrepreneurs with a purpose build a flourishing e-commerce business and connect them with loyal shoppers that want to support brands that place purpose over profit. Our company values are steeped in the Franciscan traditions of Christianity, most especially devotion to the poor, dignity of the human person and care for creation. Together, on Purposer, we will build a better community for our brothers and sisters in need, protect our planet for future generations and change the world, one purposeful purchase at a time.`}
        </Typography>
        </InputRow>
        <InputRow label="Full Terms of Use and Privacy Policy" classes={classes} description="">
        <Link target="_blank" rel="noopener noreferrer" href='/termsofuse' underline={"always"} className={"roboto-bold"}>
         <ButtonBase className={classes.buttons}>
            Click here to read Full Terms of Use
          </ButtonBase>
        </Link>
          
          <br></br>
          <br></br>
          <Link target="_blank" rel="noopener noreferrer" href='/privacypolicy' underline={"always"} className={"roboto-bold"}>
         <ButtonBase className={classes.buttons}>
            Click here to read Privacy Policy
          </ButtonBase>
        </Link>
        </InputRow>

        <InputRow label="Stripe Terms of Service" classes={classes} description="">
          <Typography gutterBottom>
            {'*Purposer uses a company called Stripe, to manage payouts and other financing activities. This company is very secure and helps us ensure that all your private information stays safe. '}
            <br></br>
            <br></br>
            {"Payment processing services for sellers on Purposer, Inc. are provided by Stripe and are subject to the "}
            <Link target="_blank" rel="noopener noreferrer" href='https://stripe.com/legal/connect-account' underline={"always"} className={"roboto-bold"}>Stripe Connected Account Agreement</Link>
            {`, which includes the `}
            <Link target="_blank" rel="noopener noreferrer" href='https://stripe.com/legal/ssa' underline={"always"} className={"roboto-bold"}>Stripe Terms of Service</Link> 
              {" (collectively, the “Stripe Services Agreement”). "}By agreeing to these terms
              or continuing to operate as a seller on Purposer, Inc., you agree to be bound 
              by the Stripe Services Agreement, as the same may be modified by Stripe from 
              time to time. As a condition of Purposer, Inc. enabling payment processing 
              services through Stripe, you agree to provide Purposer, Inc. accurate and 
              complete information about you and your business, and you authorize Purposer, Inc. 
              to share it and transaction information related to your use of the payment 
              processing services provided by Stripe.
          </Typography>
        </InputRow>
        {/* <IpGrab 
          formValues={formValues}
          handleFormValues={handleFormValues} 
        /> */}

      </Grid>
    </>
  );
};

export default TermsOpenAShop;
