import {
  Container,
  Grid,
  TextField,
  Typography,
  Avatar,
  InputAdornment,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SearchLogo from "../img/bulb4.png";
import React from "react";
import { getListOfProductsById } from "../util/getProductsById";

//Default Prop Values
import mockDataDefault from "../json/mockAPIdata.json";

const useStyles = makeStyles({
  backgroundDiv: {
    backgroundColor: "var(--mintGreen)",
    paddingTop: 50,
    paddingBottom: 20,
  },
  header: {
    fontSize: "1.2rem",
    marginTop: 10,
  },
  leftGridItem: {
    paddingRight: 35,
  },
  rightGridItem: {
    paddingLeft: 35,
  },
  avatarGridItem: {
    marginBottom: 20,
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  imageAvatar: {
    width: "100%",
    height: "100%",
  },
  avatarContainer: {
    width: "85%",
    margin: "auto",
  },
  bubbleText: {
    textAlign: "center",
    fontSize: "0.85rem",
    marginTop: 10,
    width: "95%",
    margin: "auto",
  },
  textFieldDiv: {
    display: "flex",
    alignItems: "center",
    height: "90%",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 20,
      backgroundColor: "#FFFFFF",
      borderColor: "#FFFFFF",
      height: 35,
      "& fieldset": {
        borderColor: "#FFFFFF",
      },
      "&:hover fieldset": {
        borderColor: "#FFFFFF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FFFFFF",
      },
    },
  },
  searchLogo: {
    paddingTop: 5,
    height: 25,
  },
  keepExploringText: {
    marginTop: 10,
  },
});

const Bubble = (props) => {
  const { classes, item } = props;

  return (
    <Grid item xs className={classes.avatarGridItem}>
      <a href="/shop/category" className={classes.link}>
        <div className={classes.avatarContainer}>
          <Avatar
            className={classes.imageAvatar}
            src={item.productImageURL}
            alt={item.productName}
          />
        </div>
        <Typography className={classes.bubbleText}>
          {item.productName}
        </Typography>
      </a>
    </Grid>
  );
};

const FavoritesSearchedRow = (props) => {
  const { rowData, classes } = props;

  return (
    <Grid container item direction="row">
      {rowData &&
        rowData.map((favorite, index) => {
          return <Bubble classes={classes} item={favorite} key={index} />;
        })}
    </Grid>
  );
};

function FavoritesSearched(props) {
  const classes = useStyles();

  const { favoritesSearchedData } = props;
  const [favoritesSearched, setFavoritesSearched] = React.useState([]);
  React.useEffect(() => {
    if (!favoritesSearchedData) {
      getListOfProductsById(mockDataDefault, (data) => {
        if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
          setFavoritesSearched(data);
        }
      });
    } else {
      setFavoritesSearched(favoritesSearchedData);
    }
  }, [favoritesSearchedData]);

  return (
    <div className={classes.backgroundDiv}>
      <Container maxWidth="xl">
        <Grid container direction="row">
          <Grid item xs={4} className={classes.leftGridItem}>
            <Typography align="center" className={classes.header}>
              <b>Your Favorite Searches so Far</b>
            </Typography>
            <div className={classes.textFieldDiv}>
              <Grid container direction="column">
                <Grid item>
                  <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <a href="/">
                            <img
                              src={SearchLogo}
                              alt=""
                              className={classes.searchLogo}
                            />
                          </a>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    align="center"
                    className={classes.keepExploringText}
                  >
                    keep exploring...
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            container
            item
            xs={8}
            direction="column"
            className={classes.rightGridItem}
          >
            <FavoritesSearchedRow
              rowData={favoritesSearched.slice(0, 5)}
              classes={classes}
            />
            <FavoritesSearchedRow
              rowData={favoritesSearched.slice(5, 10)}
              classes={classes}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default FavoritesSearched;
