import { getJsonCache, setJsonCache } from "./utilities";
const {
  NODE_API_ENDPOINT
} = require("./constants");

async function getAllPurposes(callback) {
  const dataName = 'getAllPurposes';
  const cachedData = getJsonCache(dataName);
  if (cachedData) callback(cachedData);

  fetch(NODE_API_ENDPOINT + "/get-product-purposes", {
    method: "GET"
  })
    .then((response) => response.json())
    .then(
      (data) => {
        setJsonCache(dataName, data);
        callback(data)
      },
      (error) => console.log(error)
    );
}

async function getAllCategories(callback) {
  const dataName = 'getAllCategories';
  const cachedData = getJsonCache(dataName);
  if (cachedData) callback(cachedData);

  fetch(NODE_API_ENDPOINT + "/get-product-categories", {
    method: "GET"
  })
    .then((response) => response.json())
    .then(
      (data) => {
        setJsonCache(dataName, data);
        callback(data)
      },
      (error) => console.log(error)
    );
}

async function getPurposesBySellerId(id, callback) {
  fetch(NODE_API_ENDPOINT + "/get-purposes-by-seller-id?id=" + id)
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function getListOfPurposeCategoriesById(
  idList,
  callback
) {
  if (idList && idList.length > 0) {
    let requestURL = NODE_API_ENDPOINT + "/get-purposes-by-id";
    for (let i = 0; i < idList.length; i++) {
      if (i === 0) {
        requestURL += "?id=" + idList[i];
      } else {
        requestURL += "&id=" + idList[i];
      }
    }

    fetch(requestURL)
      .then((response) => response.json())
      .then(
        (data) => callback(data),
        (error) => console.log(error)
      );
  }
}

async function getPurposeCategoryById(id, callback) {
  let requestURL = NODE_API_ENDPOINT + "/get-products-by-purpose-id?id=" + id;

  fetch(requestURL)
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function getPurposeById(id, callback) {
  fetch(NODE_API_ENDPOINT + "/get-purpose-by-id?id=" + id)
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

export {
  //createPurposeCategory,
  getListOfPurposeCategoriesById,
  getPurposeCategoryById,
  // updatePurposeCategory,
  // deletePurposeCategory,
  getAllPurposes,
  getAllCategories,
  getPurposesBySellerId,
  getPurposeById,
};
