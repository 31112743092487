import React, { useState } from "react";
import { Typography, AccordionDetails, Container, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { Remove as RemoveIcon, Add as AddIcon } from "@mui/icons-material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { getSellerById } from "../util/sellers";

import LoyaltyProgramPointsData from "../json/loyalty_program_points_data.json";

const useStyles = makeStyles({
  box: {
    width: "100%",
    height: "300px",
    borderStyle: "solid",
    borderColor: "var(--coral)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  text: {
    fontSize: "2rem",
    textAlign: "center",
  },
  number: {
    fontSize: "5rem",
    textAlign: "center",
    marginRight: "16px",
  },
  pointsBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  }
});



const LoyaltyProgramWaysToEarnPoints = () => {
  const classes = useStyles();
  const { loyalty_program_points } = LoyaltyProgramPointsData;
  const [loyaltyData, setLoyaltyData] = useState(loyalty_program_points);

  return (
    <Grid container alignItems="flex-start" spacing={12} columnSpacing={{ md: 3 }} rowSpacing={{  xs: 2, sm: 2, md: 3 }} center>
        {loyaltyData && loyaltyData.map((item, index) => {
            return (
                <Grid item xs={12} sm={12} md={4}>
                    <div className={classes.box}>
                        <Typography className={clsx("roboto-light", classes.text)} >
                            {item.activity}
                        </Typography>
                        <div className={classes.pointsBox}>
                            <Typography className={clsx("roboto-light", classes.number)} >
                                {item.points}
                            </Typography>
                            <Typography className={clsx("roboto-light", classes.text)} >
                                PTS
                            </Typography>
                        </div>
                    </div>
                </Grid>
            )
        })}
    </Grid>
  );
};

export default LoyaltyProgramWaysToEarnPoints;
