import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ButtonBase, CircularProgress, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarOutline from '@mui/icons-material/StarOutline';
import Star from '@mui/icons-material/Star';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { getFavorites, deleteFavorite, insertFavorite } from "../util/favorites";
import { useParams } from "react-router-dom";
import { isPurposerUserAdmin } from "../util/utilities";
import { Auth } from "aws-amplify";
import { addBestSeller, isProductBestSeller, removeBestSeller } from "../util/productBestSellers";

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 1,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 200,
    },
    items: 1,
  },
};

const useStyles = makeStyles({
  divContainer: {
    //maxWidth: 800,
    position: "relative",
    // display: "flex",
    // flexDirection: "row"
  },
  image: {
    width: "100%",
    height: "100%",
    maxWidth: "1500px", 
    maxHeight: "1125px",
    // maxWidth: "1000px", 
    // maxHeight: "750px",
    margin: "auto",
    objectFit: "contain",
    borderRadius: 20,
  },
  customDot: {
    width: 115,
    height: 75,
    border: "none",
    outline: "none",
    margin: 10,
    borderRadius: 5,
  },
  customDotActive: {
    transform: "scale(1.05)",
    border: "2px solid black",
  },
  carouselContainer: {
    paddingBottom: 100,
    position: "relative",
    display: "flex",
    borderRadius: 20,
    margin: "auto",
  },
  favoriteButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    width: "48px",
    height: "48px",
    backgroundColor: "white",
    borderRadius: "50%",
    border: "1px solid gray",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "var(--coral)",
      border: "1px solid var(--coral)",
    }
  },
  bestSellerButton: {
    position: "absolute",
    top: "8px",
    left: "8px",
    width: "48px",
    height: "48px",
    backgroundColor: "white",
    borderRadius: "50%",
    border: "1px solid gray",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "var(--coral)",
      border: "1px solid var(--coral)",
    }
  },
  // favoriteButton:hover {
  //   backgroundColor: "var()"
  // },
  favoriteBeforeIcon: {
    color: "var(--mintGreenSolid)",
    fontSize: "32px",
    "&:hover": {
      color: "white"
    }
  },
  favoriteAfterIcon: {
    color: "var(--coral)",
    fontSize: "32px",
    "&:hover": {
      color: "white"
    }
  }
});

const ProductGalleryPreview = ({
  data = [],
}) => {
  const classes = useStyles();
  let imagesArray = data.length > 0 ? data : [];
  const { id } = useParams();
  const [favorites, setFavorites] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadingBestSellers, setLoadingBestSellers] = useState(false);
  const [loadedBestSellers, setLoadedBestSellers] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  const images = imagesArray.length > 0 ? imagesArray.map((image, index) => (
    <div
      key={index}
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        maxWidth: "75px",
        maxHeight: "75px",
        width: "100%",
        height: "100%",
        borderRadius: 2,
      }}
    />
  )) : [];

  useEffect(() => {
    if (!isLoaded && favorites.length < 1)
    {
      getFavorites((data) => {
        if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message") && data.results) {
          setFavorites(data.results);
          const productIDs = data.results.map(x => x.productID);

          if (productIDs.includes(id))
            setIsFavorited(true);

          setIsLoaded(true);

        }
      });
    }

    if (!loadingBestSellers && !loadedBestSellers) {
      setLoadingBestSellers(true);
      Auth.currentAuthenticatedUser().then((user) => {
        if (isPurposerUserAdmin(user.attributes.sub)) {
          setIsUserAdmin(true);
          isProductBestSeller(id, (data) => {
            if (data?.results?.isBestSeller) {
              setIsBestSeller(true);
            }
            setLoadedBestSellers(true)
            setLoadingBestSellers(false);
          });
        } else {
          setLoadedBestSellers(true)
          setLoadingBestSellers(false);
        }
      })
    }


  }, []);

  const CustomDot = ({ index, onClick, active, lastIndex, activeIndex, matches }) => {
    let show = false;
    if (activeIndex === 0) {
      if (index === activeIndex || index === activeIndex + 1 || index === activeIndex + 2) {
        show = true;
      }
    } else if (activeIndex === lastIndex) {
      if (index === activeIndex || index === activeIndex - 1 || index === activeIndex - 2) {
        show = true;
      }
    }
    else if (index === activeIndex || index === activeIndex - 1 || index === activeIndex + 1) {
      show = true;
    }

    if (show || !matches) {
      return (
        <ButtonBase
          onClick={(e) => {
            onClick();
            e.preventDefault();
          }}
          className={clsx(classes.customDot, [active && classes.customDotActive])}
        >
          {React.Children.toArray(images)[index]}
        </ButtonBase>
      );
    } else {
      return null;
    }
  };
  

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [isFavorited, setIsFavorited] = useState(false);
  const [isBestSeller, setIsBestSeller] = useState(false);
  const [carouselActiveSlide, setCarouselActiveSlide] = useState(0);

  
  const handleFavorite = () => {
    insertFavorite(id, (data) => {
      setIsFavorited(true);
    })
  }

  
  const handleUnfavorite = () => {
    deleteFavorite(id, (data) => {
      setIsFavorited(false);
    })
  }

  const handleAddBestSeller = () => {
    if (!loadingBestSellers) {
      setLoadingBestSellers(true);
      addBestSeller(id, (data) => {
        if (data?.results?.success) {
          setIsBestSeller(true);
        }
        setLoadingBestSellers(false);
      })
    }
  }

  
  const handleRemoveBestSeller = () => {
    if (!loadingBestSellers) {
      setLoadingBestSellers(true);
      removeBestSeller(id, (data) => {
        if (data?.results?.success) {
          setIsBestSeller(false);
        }
        setLoadingBestSellers(false);
      })
    }
  }

  return (
    <div className={classes.divContainer}>
      <Carousel
        responsive={responsive}
        infinite={false}
        showDots={true}
        containerClass={classes.carouselContainer}
        slidesToSlide={1}
        beforeChange={(state) => setCarouselActiveSlide(state)}
        customDot={<CustomDot activeIndex={carouselActiveSlide} matches={matches} lastIndex={data?.length - 1} />}
      >
        {imagesArray && imagesArray.map((url, i) => {
          return (
            <img
              draggable={false}
              src={url}
              alt=""
              className={classes.image}
              key={i}
            />
          );
        })}
      </Carousel>
      {isLoaded ? isFavorited ?
        <div className={classes.favoriteButton} onClick={() => handleUnfavorite()}>
          <FavoriteIcon className={classes.favoriteAfterIcon} />
        </div> :
        <div className={classes.favoriteButton} onClick={() => handleFavorite()}>
          <FavoriteBorderIcon className={classes.favoriteBeforeIcon} />
        </div> : ""
      }
      {isUserAdmin && loadingBestSellers ?
        <div className={classes.bestSellerButton} onClick={() => handleRemoveBestSeller()}>
          <CircularProgress />
        </div> 
        : isUserAdmin && isBestSeller ?
        <div className={classes.bestSellerButton} onClick={() => handleRemoveBestSeller()}>
          <Star className={classes.favoriteAfterIcon} />
        </div> 
        : isUserAdmin && !isBestSeller ?
        <div className={classes.bestSellerButton} onClick={() => handleAddBestSeller()}>
          <StarOutline className={classes.favoriteBeforeIcon} />
        </div> : ""
      }
    </div>
  );
};

export default ProductGalleryPreview;
