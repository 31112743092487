import {
  Container,
  Typography,
  Divider,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Chip,
  ClickAwayListener,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PurposeCategoryMasterLayout } from "../../layouts";
import blogBanner from "../../img/blog_banner.png";
import blogLayoutJsonData from "../../json/blog_layout.json";
import React, { useRef } from "react";
import clsx from "clsx";
import BlogPost from "../../components/BlogPost";
import useElementWidth from "../../hooks/useElementWidth";
import { getBlogs } from "../../util/blogs";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useDocumentDescription from "../../hooks/useDocumentDescription";

const useStyles = makeStyles({
  bannerDiv: {
    textAlign: "center",
    backgroundImage: `url(${blogBanner})`,
    backgroundSize: "cover",
    width: "100%",
    minHeight: 516,
  },
  headerText: {
    fontSize: "3.6rem",
    paddingTop: 15,
  },
  subheaderText: {
    fontSize: "1.4rem",
    maxWidth: "85%",
    margin: "auto",
    marginTop: 30,
  },
  descriptionText: {
    paddingTop: 30,
    paddingBottom: 70,
    fontSize: "1.5rem",
    width: "90%",
    margin: "auto",
    lineHeight: 1.5,
  },
  recentBlogsHeader: {
    fontSize: "1.9rem",
  },
  divider: {
    width: "90%",
    margin: "auto",
    height: 2,
    backgroundColor: "#000000",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  recentBlogsSearchDiv: {
    marginTop: 30,
    marginBottom: 50,
  },
  recentBlogsSearch: {
    width: "75%",
    textAlign: "center",
    "& .MuiOutlinedInput-root": {
      height: 60,
      borderRadius: 30,
      "& fieldset": {
        borderStyle: "none",
      },
    },
    "& .MuiSelect-root": {
      paddingTop: 14,
      paddingBottom: 14,
    },
  },
  recentBlogsSearchSelect: {
    backgroundColor: "#d4dcd8",
  },
  searchText: {
    fontSize: "1.2rem",
  },
  chip: {
    backgroundColor: "var(--pastelGreen)",
    "&:hover": {
      backgroundColor: "var(--pastelGreen)",
    },
  },
  buttons: {
    textTransform: "none",
  },
  viewMoreButton: {
    marginTop: 40,
    marginBottom: 40,
    minWidth: 185,
    minHeight: 55,
    color: "#000000",
    borderColor: "#000000",
    "&:hover": {
      backgroundColor: "inherit",
      borderColor: "#000000",
    },
  },
  viewMoreButtonText: {
    fontSize: "1.3rem",
  },
  featureOuterDiv: {
    marginBottom: 60,
  },
  featureImg: {
    maxWidth: "100%",
  },
  featureDiv: {
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 40,
    borderStyle: "solid",
    borderWidth: 3,
    borderColor: "var(--mintGreen)",
    textAlign: "center",
  },
  featureInnerDiv: {
    position: "absolute",
    backgroundColor: "#FFFFFF",
    top: "8%",
    left: "8%",
    maxWidth: "26%",
    maxHeight: "70%",
    overflow: "hidden",
  },
  featureMobileDiv: {
    width: "65%",
    margin: "auto",
    marginTop: 20,
  },
  featureHeader: {
    fontSize: "1.4rem",
    width: "60%",
    margin: "auto",
    marginBottom: 20,
  },
  featureText: {
    textOverflow: "ellipsis",
  },
  featureReadMoreButton: {
    marginTop: 50,
    backgroundColor: "var(--lightGray)",
    width: "50%",
    "&:hover": {
      backgroundColor: "var(--lightGray)",
    },
  },
  featureReadMoreButtonText: {
    fontSize: "1.1rem",
    color: "#000000",
  },
});

const SearchByCategory = (props) => {
  const {
    categories,
    categoryData,
    handleCategoryChange,
    open,
    setOpen,
    handleRemoveCategory,
    classes,
  } = props;

  return (
    <div className={clsx(classes.center, classes.recentBlogsSearchDiv)}>
      <FormControl
        variant="outlined"
        className={classes.recentBlogsSearch}
        onClick={() => setOpen(true)}
      >
        <ClickAwayListener
          disableReactTree
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <Select
            multiple
            displayEmpty
            value={categories}
            onChange={handleCategoryChange}
            onClose={() => {
              setOpen(false);
            }}
            open={open}
            className={classes.recentBlogsSearchSelect}
            MenuProps={{
              PaperProps: {
                style: {
                  borderRadius: 15,
                  borderColor: "var(--pastelGreen)",
                  borderStyle: "solid",
                  borderWidth: 2,
                },
              },
            }}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <Typography className={classes.searchText}>
                    Search Blogs By Category
                  </Typography>
                );
              }

              return selected.map((category) => {
                return (
                  <Chip
                    key={category}
                    label={category}
                    onClick={() => setOpen(true)}
                    onDelete={() => handleRemoveCategory(category)}
                    className={clsx(classes.searchText, classes.chip)}
                  />
                );
              });
            }}
          >
            {categoryData.map((category, index) => {
              return (
                <MenuItem
                  key={index}
                  value={category}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Typography>{category}</Typography>
                </MenuItem>
              );
            })}
          </Select>
        </ClickAwayListener>
      </FormControl>
    </div>
  );
};

const BlogFeature = (props) => {
  const { blogData, containerWidth, classes } = props;

  return (
    <>
      <Typography align="center" className={classes.featureHeader}>
        Feature Diary Entry of the Month
      </Typography>
      <Typography
        align="center"
        className={clsx(classes.featureText, "roboto-medium")}
        style={{ fontSize: 0.055 * containerWidth }}
      >
        {blogData?.results[0]?.body}
      </Typography>
      <Button
        disableRipple
        className={clsx(classes.buttons, classes.featureReadMoreButton)}
      >
        <Typography className={classes.featureReadMoreButtonText}>
          Read More
        </Typography>
      </Button>
    </>
  );
};


function BlogLayout() {
  const classes = useStyles();
  const blogLayoutData = blogLayoutJsonData;

  const [blogData, setBlogData] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const divRef = useRef(null);
  const width = useElementWidth(divRef);
  const [loadedBlogs, setLoadedBlogs] = React.useState(false);
  const [loadingBlog, setLoadingBlog] = React.useState(false);

  useDocumentTitle("Selling and Shopping on Purposer is easy and meaningful. Read our Blog!");
  useDocumentDescription("Do you care where your money goes? Support Brands that are making a positive impact on the world. Discover purposeful, ethical brands on Purposer. Check out our Blog!");

  React.useEffect(() => {
    if (!loadedBlogs && !loadingBlog) {
      setLoadingBlog(true)
      getBlogs((data) => {
        const hardCodedBlog = {
          blogID: "1FAB10D7-C9CD-4472-9090-007411A50574",
          overrideLink: '/blog/fathers-day',
          body: "Our fathers are such special men because they dedicate their lives for us and sacrifice so much for those they love. This year, let’s express our gratitude to them with prayers, quality time and special gifts!",
          category: "Seller Support",
          createDate: "2023-02-21T19:41:11.553Z",
          editDate: "2023-02-21T19:56:24.103Z",
          pictures: ["https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Blog/fathersday.jpg"],
          title: "Father’s Day Gifts"
        }
        data?.results?.sort ((a, b) => {
          return new Date(b.createDate) - new Date(a.createDate);
        });
        setBlogData([...data.results, hardCodedBlog]);
        // setBlogData([hardCodedBlog]);
        setLoadedBlogs(true);
      })
    }
  }, [blogData]);

  function handleCategoryChange(event) {
    const {
      target: { value },
    } = event;
    setCategories(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    filterBlogs(value)
  }

  function handleRemoveCategory(category) {
    const index = categories.indexOf(category);
    if (index > -1) {
      let newCategories = [...categories];
      newCategories.splice(index, 1);
      setCategories(newCategories);
      filterBlogs(newCategories);
    }
  }

  function filterBlogs(categories) {
    if (categories.length > 0) {
      setBlogData(blogData.filter(x => categories.includes(x.category)));
    }
    else {
      getBlogs((data) => {
        data?.results?.sort ((a, b) => {
          return new Date(b.createDate) - new Date(a.createDate);
        });
        setBlogData(data.results);
        setLoadedBlogs(true);
      })
    }
  }

  return (
    <PurposeCategoryMasterLayout>
      <Container maxWidth="xl">
        <div className={classes.bannerDiv}>
          <Typography align="center" variant="h1" className={classes.headerText}>
            <b>Purposer Blog</b>
          </Typography>
        </div>
        <Typography align="center" className={classes.subheaderText}>
          <i>
            Be a part of our community by keeping up to date with blogs
            regarding all thing Purposer!
          </i>
        </Typography>
        <Typography
          align="center"
          variant="h2"
          className={classes.descriptionText}
        >
          At Purposer, we use our blogs to further conversations about
          important of purpose-driven brands. We want to
          support our sellers and those part of our community that are making a
          positive impact on the world.
        </Typography>
        <Typography align="center" className={classes.recentBlogsHeader}>
          <b>Recent Blogs</b>
        </Typography>
        <Divider className={classes.divider} />
        <SearchByCategory
          categories={categories}
          categoryData={blogLayoutData.categories}
          handleCategoryChange={handleCategoryChange}
          open={open}
          setOpen={setOpen}
          handleRemoveCategory={handleRemoveCategory}
          classes={classes}
        />
        <BlogPostsPreview
          posts={blogData}
          classes={classes}
        />
        {/* <div
          className={classes.featureOuterDiv}
          style={{ position: mobile ? "static" : "relative" }}
        >
          <img
            src={blogLayoutData.feature.img}
            alt=""
            className={classes.featureImg}
          />
          <div
            className={clsx(
              mobile ? classes.featureMobileDiv : classes.featureInnerDiv,
              classes.featureDiv
            )}
            ref={divRef}
          >
            <BlogFeature
              blogLayoutData={blogData}
              containerWidth={width}
              classes={classes}
            />
          </div>
        </div> */}
      </Container>
    </PurposeCategoryMasterLayout>
  );
}

const BlogPostsPreview = (props) => {
  const { posts, classes } = props;
  return (
    <>
      <Grid container direction="row" spacing={8}>
        {posts && posts.length > 0 &&
          posts.map((post, index) => {
            return <BlogPost blogPost={post} key={index} />;
          })}
      </Grid>
      <div className={classes.center}>
        <Button
          variant="outlined"
          className={clsx(classes.buttons, classes.viewMoreButton)}
        >
          <Typography align="center" className={classes.viewMoreButtonText}>
            View More
          </Typography>
        </Button>
      </div>
    </>
  );
};

export default BlogLayout;
