import { Grid, Avatar, Typography, Box, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";

const useStyles = makeStyles((theme) => ({
  imageAvatar: {
    width: "100%",
    height: "100%",
  },
  avatar: {
    position: 'relative', // Add this
    display: 'flex',
    width: "10em",
    height: "10em",
    borderRadius: "10em",
    marginBottom: "10px",
    zIndex: 1,
  },

  avatarMobile: {
    position: 'relative', // Add this
    // display: 'flex',
    width: "4.5em",
    height: "4.5em",
    borderRadius: "1em",
    marginBottom: "1rem",
    borderRadius: "10em",
    zIndex: 1,
  },
  text: {
    position: 'absolute', // Add this
    // fontWeight: "bold",
    textAlign: "center",
    fontSize: "1.3rem",
    width: "9.7em",
    // marginTop: theme.spacing(-5.5),
    zIndex: 2,
  },
  mobileText: {
    position: 'absolute', // Add this
    // fontWeight: "bold",
    textAlign: "center",
    fontSize: "1rem",
    width: "6em",
    marginTop: theme.spacing(-1),
    zIndex: 2,
    // textShadow: "2px 3px 5px rgba(0,0,0,0.2);",
  },
}));


const CategoryBox = ({ classes, item, parentCategoryI, mobile }) => {
  const link = "/shop/purpose/" + item.id + "/" + item.name.replace(/\s/g, '-');

  return (

    <Grid item xs={mobile ? 4 : 2} sm={mobile ? 4 : 2} md={mobile ? 4 : 2} lg={mobile ? 4 : 2}
      sx={{
        display: 'flex', // Centering content
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <a href={link}>
        <Avatar 
          // variant="rounded"
          //circle image
          variant={mobile ? "circular" : "rounded"}
          src={item.img} 
          alt={item.name} 
          className={mobile ? classes.avatarMobile : classes.avatar}
        />
        <Typography className={mobile ? classes.mobileText : classes.text}>{mobile ? (item.mobileName || item.name) : item.name}</Typography>
      </a>
    </Grid>
  );
};

const PURPOSE_DATA = [
  {
      "id": 60,
      "higherPurposeID": -1,
      "name": "Sustainability",
      "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Eco-Friendly.jpg",
      "description": "Reduce waste, shop sustainably",
  },
  {
      "id": 70,
      "higherPurposeID": -1,
      "name": "End Poverty",
      "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/end-poverty-new.png",
      "description": null,
  },
  {
      "id": 80,
      "higherPurposeID": -1,
      "name": "Healthy Living",
      "mobileName": "Health",
      "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Healthy+Living.jpg",
      "description": null,
  },
  {
      "id": 100,
      "higherPurposeID": -1,
      "name": "Community Support",
      "mobileName": "Community",
      "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Community+Support.jpg",
      "description": null,
  },
  {
      "id": 110,
      "higherPurposeID": -1,
      "name": "Faith Life",
      "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Faith+Life.jpg",
      "description": null,
  },
  {
    "id": 90,
    "higherPurposeID": -1,
    "name": "Animals and Wildlife",
    "mobileName": "Wildlife",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Animals+%26+Wildlife.jpg",
    "description": null,
},
];

const PurposeHomepageView = () => {
  const classes = useStyles();
  const mobile = useMobileMediaQuery();

  return (
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <Grid container spacing={mobile ? 2 : 0}>
        {PURPOSE_DATA.map((item, i) => {
          return (
            <CategoryBox
              key={i}
              classes={classes}
              mobile={mobile}
              item={item}
              parentCategoryId={-1}
            ></CategoryBox>
          );
        })}
      </Grid>
    </Container>
  );
};

export default PurposeHomepageView;
