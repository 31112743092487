import { Container, Typography, ButtonBase, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Carousel from "react-multi-carousel";
import clsx from "clsx";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
  Edit,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import React from "react";
import { getSellerById } from "../util/sellers";

const useStyles = makeStyles({
  container: {
    position: "relative",
  },
  navButtons: {
    position: "absolute",
    top: "42%",
    height: 75,
    width: 30,
    border: "1px solid var(--mintGreen)",
    borderRadius: 20,
    backgroundColor: "#FFFFFF",
  },
  prevButton: {
    left: 20,
  },
  nextButton: {
    right: 20,
  },
  arrows: {
    color: "var(--mintGreen)",
    fontSize: 40,
  },
  productDiv: {
    textAlign: "center",
  },
  productImage: {
    height: 220,
    width: 175,
    objectFit: "cover",
    borderRadius: 20,
  },
  descriptionText: {
    maxWidth: 175,
    margin: "auto",
    marginTop: 25,
  },
  editButtonDiv: {
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    bottom: 45,
  },
  editButton: {
    color: "#FFFFFF",
    backgroundColor: "var(--coral)",
    textTransform: "none",
    paddingLeft: 15,
    paddingRight: 15,
    "&:hover": {
      backgroundColor: "var(--coral)",
    },
  },
  editButtonText: {
    marginLeft: 10,
  },
});

const responsive = {
  break1: {
    breakpoint: { max: 4000, min: 1020 },
    items: 4,
  },
  break2: {
    breakpoint: { max: 1020, min: 790 },
    items: 3,
  },
  break3: {
    breakpoint: { max: 790, min: 550 },
    items: 2,
  },
  break4: {
    breakpoint: { max: 550, min: 0 },
    items: 1,
  },
};

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  const classes = useStyles();
  return (
    <>
      <ButtonBase
        className={clsx(classes.navButtons, classes.prevButton)}
        onClick={previous}
      >
        <ArrowBack className={classes.arrows} />
      </ButtonBase>
      <ButtonBase
        className={clsx(classes.navButtons, classes.nextButton)}
        onClick={next}
      >
        <ArrowForward className={classes.arrows} />
      </ButtonBase>
    </>
  );
};

function FeaturedProductsAndSpecials(props) {
  const classes = useStyles();
  const { featuredProductsAndSpecialsData, admin = false } = props;
  const { id } = useParams();
  const DEFAULT_ID = "22963625-1ae2-41d1-9041-97a8111c74a0";

  const [featuredData, setFeaturedData] = React.useState([]);

  React.useEffect(() => {
    if (!featuredProductsAndSpecialsData) {
      getSellerById(id, (data) => {
        if (
          data &&
          !data.hasOwnProperty("error") &&
          data.hasOwnProperty("legacyLayoutData")
        ) {
          setFeaturedData(data.legacyLayoutData.featured);
        } else {
          // Fetch default
          getSellerById(DEFAULT_ID, (data) => {
            if (
              data &&
              !data.hasOwnProperty("error") &&
              data.hasOwnProperty("legacyLayoutData")
            ) {
              setFeaturedData(data.legacyLayoutData.featured);
            }
          });
        }
      });
    } else {
      setFeaturedData(featuredProductsAndSpecialsData);
    }
  }, [id, featuredProductsAndSpecialsData]);

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Typography
        align="center"
        variant="h4"
        className={"roboto-medium"}
        style={{ marginBottom: admin ? 9 : 45 }}
      >
        Featured Products and Specials
      </Typography>
      {admin && (
        <div className={classes.editButtonDiv}>
          <Button className={classes.editButton}>
            <Edit />
            <Typography className={classes.editButtonText}>
              <b>Edit Specials</b>
            </Typography>
          </Button>
        </div>
      )}
      {featuredData && featuredData.length > 0 ? (
        <Carousel
          responsive={responsive}
          infinite={false}
          showDots={false}
          arrows={false}
          renderButtonGroupOutside
          customButtonGroup={<CustomButtonGroupAsArrows />}
          containerClass={classes.carouselContainer}
        >
          {featuredData.map((product, index) => {
            return (
              <div className={classes.productDiv} key={index}>
                <a href={product.url}>
                  <img
                    src={product.img}
                    className={classes.productImage}
                    alt=""
                  />

                  <Typography
                    align="center"
                    variant="h5"
                    className={clsx(classes.descriptionText, "roboto-regular")}
                  >
                    {product.description}
                  </Typography>
                </a>
              </div>
            );
          })}
        </Carousel>
      ) : (
        <Typography align="center" className={"roboto-medium"}>
          No featured products
        </Typography>
      )}
    </Container>
  );
}

export default FeaturedProductsAndSpecials;
