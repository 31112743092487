import Carousel from "react-multi-carousel";
import {
  ButtonBase,
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";
import clsx from "clsx";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { getSellerById } from "../util/sellers";
import { useParams } from "react-router-dom";

const responsive = {
  break1: {
    breakpoint: { max: 3000, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles({
  navButtons: {
    position: "absolute",
    top: "45%",
    height: 75,
    width: 27,
    border: "1px solid var(--mintGreen)",
    borderRadius: 20,
    backgroundColor: "#FFFFFF",
  },
  prevButton: {
    left: 45,
  },
  nextButton: {
    right: 45,
  },
  container: {
    position: "relative",
  },
  carouselContainer: {
    maxHeight: 515,
  },
  arrows: {
    color: "var(--mintGreen)",
    fontSize: 40,
  },
  image: {
    width: "100%",
    height: "100%",
    margin: "auto",
    objectFit: "cover",
  },
  dot: {
    width: "15px",
    height: "15px",
    borderRadius: "25px",
    border: "none",
    margin: 7,
    borderStyle: "solid",
    borderColor: "#FFFFFF",
    marginBottom: 10,
  },
  whiteDot: {
    backgroundColor: "#FFFFFF",
  },
  dotListClass: {
    justifyContent: "flex-start",
  },
});

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  const classes = useStyles();
  return (
    <>
      <ButtonBase
        className={clsx(classes.navButtons, classes.prevButton)}
        onClick={previous}
      >
        <ArrowBack className={classes.arrows} />
      </ButtonBase>
      <ButtonBase
        className={clsx(classes.navButtons, classes.nextButton)}
        onClick={next}
      >
        <ArrowForward className={classes.arrows} />
      </ButtonBase>
    </>
  );
};

const CustomDots = ({ index, onClick, carouselState: { currentSlide } }) => {
  const classes = useStyles();
  return (
    <ButtonBase
      onClick={(e) => {
        onClick();
        e.preventDefault();
      }}
      className={clsx(classes.dot, index === currentSlide && classes.whiteDot)}
    ></ButtonBase>
  );
};

const SellerButtonsDiv = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  position: "absolute",
  bottom: 0,
});

const SellerButtonsContainer = styled(Stack)({
  backgroundColor: "var(--beige)",
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
});

const SellerButton = styled(Button)({
  textTransform: "none",
  color: "#000000",
  borderRadius: 0,
  "&:hover": {
    backgroundColor: "inherit",
  },
});

const SellerButtonIcon = styled("img")({
  width: 25,
  height: 25,
  marginRight: 10,
});

function SellerCarouselBanner(props) {
  const classes = useStyles();
  const { sellerCarouselBannerData, showSellerButtons = true } = props;
  const id = useParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const DEFAULT_ID = "22963625-1ae2-41d1-9041-97a8111c74a0";

  const [bannerData, setBannerData] = React.useState([]);

  React.useEffect(() => {
    if (!sellerCarouselBannerData) {
      getSellerById(id, (data) => {
        if (data && !data.hasOwnProperty("error")) {
          setBannerData(data.banner_images);
        } else {
          // Fetch default
          getSellerById(DEFAULT_ID, (data) => {
            if (data && !data.hasOwnProperty("error")) {
              setBannerData(data.banner_images);
            }
          });
        }
      });
    } else {
      setBannerData(sellerCarouselBannerData);
    }
  }, [sellerCarouselBannerData, id]);

  return (
    <Box className={classes.container}>
      <Carousel
        responsive={responsive}
        infinite={false}
        showDots={true}
        arrows={false}
        renderButtonGroupOutside
        slidesToSlide={1}
        containerClass={classes.carouselContainer}
        customButtonGroup={<CustomButtonGroupAsArrows />}
        customDot={<CustomDots />}
        dotListClass={matches && classes.dotListClass}
      >
        {bannerData &&
          bannerData.map((item, index) => {
            return (
              <img
                draggable={false}
                src={typeof item === "object" ? item.S : item}
                alt={""}
                className={classes.image}
                key={index}
              />
            );
          })}
      </Carousel>
      {showSellerButtons && (
        <SellerButtonsDiv sx={{ right: { xs: 0, sm: 96 } }}>
          <SellerButtonsContainer direction="row" columnGap={1}>
            <SellerButton
              disableRipple
              href={"/seller/" + id.id + "/orders"}
            >
              <SellerButtonIcon
                src={
                  "https://d2eudfgh8kpk8j.cloudfront.net/common/thumbs/1e151c3b-28fd-4635-bacf-4373e049f09d_thumb.png"
                }
                alt="dashboard_icon"
              />
              <Typography variant="body1" className={"roboto-medium"}>
                Dashboard
              </Typography>
            </SellerButton>
            {/* <SellerButton disableRipple href={"/seller/" + id.id + "/admin"}>
              <SellerButtonIcon
                src={
                  "https://d2eudfgh8kpk8j.cloudfront.net/common/thumbs/daae27fe-dc5f-409c-a54c-f96056f4f80a_thumb.png"
                }
                alt="admin_icon"
              />
              <Typography variant="body1" className={"roboto-medium"}>
                Admin View
              </Typography>
            </SellerButton> */}
          </SellerButtonsContainer>
        </SellerButtonsDiv>
      )}
    </Box>
  );
}

export default SellerCarouselBanner;
