import { useEffect, useState } from "react";
import DropDownButton from "./DropDownButton";
import {
  Button,
  Container,
  Divider,
  TextField,
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Autocomplete,
  autocompleteClasses,
  ButtonBase,
  Badge
} from "@mui/material";
import { Link } from "react-router-dom";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import menuData from "../json/category_menu.json";
import StatementHeader from "./StatementHeader";
import { useSelector } from "react-redux";
import signOut from "../util/signOut";
import { Auth } from "aws-amplify";
import {
  getHeaderData,
} from "../util/productCategories";
import {
  getAllProducts, preloadGetProductsBySearch,
} from "../util/products";
import {
  selectCartItems,
} from "../app/features/cart/cartDataSlice";
import { getAllPurposes, getAllCategories } from "../util/purposeCategories";
import { getSellerIdByUserId } from "../util/sellers";
import shoppingCartIcon from "../img/shopping_cart_slim_icon.png";
import sellerIcon from "../img/seller_dashboard_slim_icon.png";
import { PRESET_SEARCHES, SEARCH_ITEM_TYPES } from "../util/constants";
import { isPurposerUserAdmin } from "../util/utilities";
import signedInIcon from "../img/person_icon.png";
import magnifyingGlass from "../img/magnifying_glass.png";
import MobileMenu from "./MobileMenu";
import CategoryMobileSlider from "./CategoryMobileSlider";

const StyledRoot = styled("div")({
  display: "flex",
  justifyContent: "space-evenly",
});

const SellButton = styled(Button)({
  textTransform: "none",
  borderRadius: 10,
  color: "#FFFFFF",
  paddingLeft: 15,
  paddingRight: 15,
  paddingTop: 4,
  paddingBottom: 4,
  background: "var(--coral)",
  "&:hover": {
    background: "var(--coral)",
  },
  "&:visited": {
    color: "#FFFFFF",
  },
});

const Logo = styled("img")({
  width: "100%",
  maxHeight: 100,
  paddingTop: 10,
  paddingBottom: 5,
});

const StyledAutoComplete = styled(Autocomplete)({
  width: "95%",
  [`& .${outlinedInputClasses.root}`]: {
    height: 50,

  },
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderStyle: "none",
    backgroundColor: "#80A598",//"var(--pastelGreen)",
    opacity: "0.3",
    borderRadius: 30,
    // color: "#333333", // Adjust this to change the text color
  },
});

const SignOutText = styled(Typography)({
  "&:hover": {
    textDecoration: "underline",
    cursor: "pointer",
  },
});

const ShoppingCart = styled("img")({
  width: 35,
  height: 35,
});

const AdminBox = styled("img")({
  width: 35,
  height: 35,
});

const StyledSignedInIcon = styled("img")({
  width: 35,
  height: 35,
});

export default function SiteHeader() {

  const userAttributes = useSelector((state) => state.user.user);
  const [categoryMenuData, setCategoryMenuData] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [productPurposes, setProductPurposes] = useState([]);
  const [productCategories, setProductCategories] = useState([]);

  const [loadingPurposes, setLoadingPurposes] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const [hasAStore, setHasAStore] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [loadingHasAStore, setLoadingHasAStore] = useState(false);
  const [sellerID, setSellerID] = useState(null);

  const [searchText, setSearchText] = useState('');
  const reduxCartItems = useSelector(selectCartItems) ?? [];


  useEffect(() => {
    if (productPurposes.length < 1 && !loadingPurposes) {
      setLoadingPurposes(true);
      getAllPurposes((data) => {
        if (data && Array.isArray(data)) {
          let searchableItems = [];
          data.forEach(element => {
            if (!searchableItems.find(i => i.name.includes(element.purposeName))) {
              const currId = element.higherPurposeID == -1 ? element.productPurposeID : element.higherPurposeID + '/' + element.productPurposeID;
              searchableItems.push({ id: currId, name: `'${element.purposeName}' in Purposes`, type: SEARCH_ITEM_TYPES.PURPOSE });
            }
          });
          setProductPurposes(searchableItems);
        } else {
          console.log("Couldn't get purposes!")
        }
      });
      setLoadingPurposes(false);
    }
  }, []);

  useEffect(() => {
    if (productCategories.length < 1 && !loadingCategories) {
      setLoadingCategories(true);
      getAllCategories((data) => {
        if (data && Array.isArray(data)) {
          let searchableItems = [];
          data.forEach(element => {
            if (!searchableItems.find(i => i.name.includes(element.categoryName))) {
              const currId = element.higherCategoryID == -1 ? element.productCategoryID : element.higherCategoryID + '/' + element.productCategoryID;
              searchableItems.push({ id: currId, name: `'${element.categoryName}' in Categories`, type: SEARCH_ITEM_TYPES.CATEGORY });
            }
          });
          setProductCategories(searchableItems);
        } else {
          console.log("Couldn't get categories!")
        }
      });
      setLoadingCategories(false);
    }
  }, []);

  useEffect(() => {
    if (!isMobile && categoryMenuData.length === 0) {
      getHeaderData((data) => {
        if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
          data.sort((category1, category2) => {
            return category1.id - category2.id;
          });
          //Swap the last Category (Gifts) into 2nd place
          const last = data.pop();
          data.splice(1, 0, last);
          //Custom ordering of this category in particular was requested
          const clothingAndShoesDropdown = data.find(category => category.name == "Clothing & Shoes");
          if (clothingAndShoesDropdown?.subcategories) {
            const newOrderedArray = ["Women", "Men", "Graphic Tees", "Short Sleeve", "Long Sleeve", "Tank Tops", "Swimwear", "Activewear", "Sweatshirts & Hoodies", "Sweater", "Outerwear", "Sleepwear & Leisurewear", "Bottoms", "Jeans", "Dresses & Skirts", "Baby Clothes"];
            const subCats = [...clothingAndShoesDropdown.subcategories];
            delete clothingAndShoesDropdown.subcategories;
            clothingAndShoesDropdown.subcategories = [];
            const newSubCategories = clothingAndShoesDropdown.subcategories;
            for (const item of newOrderedArray) {
              const itemFind = subCats.find(c => c.name == item);
              if (itemFind) {
                newSubCategories.push(itemFind);
              }
            }
          }
          for (const category of data) {
            category.subcategories = category.subcategories.filter(sc => sc.productCount > 0);
          }

          setCategoryMenuData(data);
        }
      });
    }
  }, [categoryMenuData.length, isMobile]);

  useEffect(() => {
    if (allProducts.length < 1 && !loading) {
      setLoading(true);
      getAllProducts((data) => {
        if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message") && Array.isArray(data) && data.length > 0) {
          let searchableItems = [];
          data.forEach(product => {
            if (!searchableItems.find(i => i.name == product.productName)) {
              searchableItems.push({ id: product.productID, name: product.productName, type: SEARCH_ITEM_TYPES.PRODUCT });
            }
          });
          setAllProducts(searchableItems);
        }
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (!loadingHasAStore) {
      setLoadingHasAStore(true);
      Auth.currentAuthenticatedUser().then((user) => {
        if (isPurposerUserAdmin(user.attributes.sub)) {
          setIsUserAdmin(true);
        }
        getSellerIdByUserId(user.attributes.sub, (data) => {
          if (data?.sellerID) {
            setHasAStore(true);
            setSellerID(data.sellerID)
          } else {
            setHasAStore(false);
          }
          setLoadingHasAStore(false);
        });
      })
    }
  }, []);

  const handleSearchPreload = (stringToSearch) => {
    if (stringToSearch.length > 1 && !loadingSearch) {
      setLoadingSearch(true);
      preloadGetProductsBySearch(stringToSearch, (data) => {
        if (data.success) {
          window.location.href = ('/search?search=' + encodeURI(stringToSearch));
        }
      });
    }
  }

  const MagnifyingGlassIcon = () => {

    return (
      <Logo
        src={magnifyingGlass}
        alt=""
        sx={{
          width: isMobile ? 20 : 25, 
          height: isMobile ? 20 : 25,
          //center the icon
          position: "absolute",
          transform: 'rotate(0deg) !important',
          //move to the left
          right: "0.75rem",
        }}
      />
    )
  }

  return (
    <>
      <StatementHeader
        userAttributes={userAttributes}
        menuData={menuData}
        signOut={signOut}
      />
      <>
      <Container maxWidth={isMobile ? 'false' : "xl"} sx={{ my: 1 }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          columnSpacing={1}
        >
          {!isMobile &&
            <Grid item xs={1} sm={1} align="center" sx={{ minWidth: 175, marginLeft: "2rem" }}>
              <Link to="/">
                <Logo
                  src={
                    "https://d2eudfgh8kpk8j.cloudfront.net/common/purposer_logo_large_clear.png"
                  }
                  alt=""
                />
              </Link>
            </Grid>}
          {/* <Grid align="center" item sm={2} sx={{ minWidth: 160 }}>
            <Link to={sellerID ? "/shop/seller/" + sellerID : "/seller/signup"}>
              <SellButton >
                <Typography className={"roboto-medium"}>
                  {loadingHasAStore ? `Sell on Purposer` : `Sell on Purposer`}
                </Typography>
              </SellButton>
            </Link>
          </Grid> */}
          {isMobile &&
            <Grid item xs={1} sm>
              <MobileMenu
                userAttributes={userAttributes}
                menuData={menuData}
                signOut={signOut}
              />
            </Grid>}
          <Grid item align="center" xs={isMobile ? 9 : 9} sm>
            <StyledAutoComplete
              variant="outlined"
              disablePortal
              id="search-bar"
              options={searchText.length > 0 ? [...productPurposes, ...productCategories, ...allProducts] : PRESET_SEARCHES}
              getOptionLabel={(option) => option.name}
              fullWidth
              selectOnFocus
              sx={{
              [`& .${outlinedInputClasses.root}`]: {
                height: isMobile ? 35 : 50
              },
              [`& .${autocompleteClasses.popupIndicator}`]: {
                transform: "none"
              }}}
              popupIcon={<MagnifyingGlassIcon />}
              // hasPopupIcon={false}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label=""
                    placeholder={isMobile ? "Search" : "Search for products or purposes"}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && e.target.value?.length > 1) {
                        handleSearchPreload(e.target.value);
                      }
                    }}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        padding: isMobile && '0px 0 8px 12px',  // Adjust the padding only for mobile
                        transform: 'rotate(0deg) !important'
                      }
                    }}
                  />)
              }}
              onChange={(e, value) => {
                if (value.type == SEARCH_ITEM_TYPES.PRODUCT) {
                  window.location.href = ('/shop/product/' + value.id);
                } else if (value.type == SEARCH_ITEM_TYPES.PURPOSE) {
                  window.location.href = ('/shop/purpose/' + value.id);
                } else if (value.type == SEARCH_ITEM_TYPES.CATEGORY) {
                  window.location.href = ('/shop/category/' + value.id);
                } else if (value.type == SEARCH_ITEM_TYPES.SEARCH) {
                  handleSearchPreload(value.id);
                }
              }}
            />
          </Grid>
          <Grid
            container
            item
            xs={isMobile ? 2 : 3}
            direction="row"
            spacing={isMobile ? 1 : 0}
          >
            <Grid
              item
              sm={4}
            >
              {userAttributes ?
                <Link to="/acct">
                  <StyledSignedInIcon src={signedInIcon} sx={{
                      width: isMobile ? '20px' : '35px',
                      height: isMobile ? '20px' : '35px',
                    }} />
                </Link>
                :
                <Link to="/login">
                  {isMobile ?
                  (<StyledSignedInIcon src={signedInIcon} sx={{
                    width: isMobile ? '20px' : '35px',
                    height: isMobile ? '20px' : '35px',
                  }} />)
                  :
                  (<Typography className={"roboto-regular"} sx={{ mt: 1, fontSize: "1.2rem" }}>
                    Sign in
                  </Typography>)
                  }
                </Link>
              }
            </Grid>
            {!isMobile &&
            <Grid
              item
              sm={4}
            >
              {hasAStore ?
              <Link to={sellerID ? "/seller/" + sellerID + "/orders" : "/seller/signup"}>
                <ButtonBase>
                  <img style={{ 
                    width: isMobile ? '20px' : '35px',
                    height: isMobile ? '20px' : '35px',
                   }} src={sellerIcon} />
                </ButtonBase>
              </Link>
              : isUserAdmin ?
              <Link to={"/adminportal/storeapproval"}>
                <ButtonBase>
                  <img style={{ width: "32px" }} src={"https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/header/admin_box.png"} />
                </ButtonBase>
              </Link>
              :
              <></>
              }
            </Grid>}
            <Grid
              item
              sm={4}
            >
              <Link to="/cart">
                  <ShoppingCart
                    src={shoppingCartIcon}
                    alt=""
                    sx={{
                      width: isMobile ? '20px' : '35px',
                      height: isMobile ? '20px' : '35px',
                    }}
                  />
                    <Badge badgeContent={reduxCartItems.length} sx={{
                      "& .MuiBadge-badge": {
                        color: "white",
                        backgroundColor: "var(--mintGreenSolid)",
                        width: isMobile ? '15px' : '35px',
                        height: isMobile ? '15px' : '35px',
                        borderRadius: "100%",
                        fontSize: isMobile ? '0.5rem' : '1rem',
                        marginRight: isMobile ? '0.25rem' : '0rem',
                        marginTop: isMobile ? '0.05rem' : '0rem',
                      }
                    }}>
                </Badge>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      </>
      <Box sx={{ display: { xs: "none", md: "flex", marginTop: "20px" } }}>
        <Container maxWidth="xl">
          {populateHeaderCategories(categoryMenuData)}
        </Container>
      </Box>
      {isMobile && <CategoryMobileSlider categoryMenuData={categoryMenuData}/>}
    </>
  );
}

function populateHeaderCategories(categoryMenuData) {
  const items = [];

  for (const [index, value] of categoryMenuData.entries()) {
    items.push(<DropDownButton key={index} categoryData={value} />);
  }

  return <StyledRoot>{items}</StyledRoot>;
}
