// APP COMPONENT
// Upon rendering of App component, make a request to create and
// obtain a link token to be used in the Link component
import React, { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
//import { createLinkToken, createStripeBankObject } from '../util/plaid'
import { getSessionJwt } from "../util/utilities";
import { styled } from "@mui/material/styles";
import {
  Button,
  Typography
} from "@mui/material";

const {
  NODE_API_ENDPOINT
} = require("../util/constants");

const CoralButton = styled(Button)({
  textTransform: "none",
  borderRadius: 15,
  color: "#FFFFFF",
  paddingLeft: 50,
  paddingRight: 50,
  paddingTop: 25,
  paddingBottom: 25,
  background: "var(--coral)",
  "&:hover": {
    background: "var(--coral)",
  },
  "&:visited": {
    color: "#FFFFFF",
  },
});

interface PlaidAppProps {
  formValues: any | null;
  handleFormValues: any | null;
  sellerID: string | null;
}
const PlaidApp = (props: PlaidAppProps) => {
  const { formValues, handleFormValues, sellerID } = props;
  const [linkToken, setLinkToken] = useState(null);
  const generateToken = async () => {
    const jwtToken = await getSessionJwt();
    const response = await fetch(NODE_API_ENDPOINT + '/api/create_link_token', {
      method: 'POST',
      headers: {
        "Authorization": 'Bearer ' + jwtToken,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    setLinkToken(data.link_token);
  };
  useEffect(() => {
    generateToken();
  }, []);
  return linkToken != null ? <Link formValues={formValues} handleFormValues={handleFormValues} linkToken={linkToken} sellerID={sellerID} /> : <></>;
};
// LINK COMPONENT
// Use Plaid Link and pass link token and onSuccess function
// in configuration to initialize Plaid Link
interface LinkProps {
  linkToken: string | null;
  formValues: any | null;
  handleFormValues: any | null;
  sellerID: string | null;
}
const Link: React.FC<LinkProps> = (props: LinkProps) => {
  const { formValues, handleFormValues, sellerID } = props;
  const onSuccess = React.useCallback(async (public_token, metadata) => {
    const jwtToken = await getSessionJwt();
    const bankAcctId = metadata?.accounts[0]?.id;
    const response = await fetch(NODE_API_ENDPOINT + '/api/create-stripe-token', {
      method: 'POST',
      headers: {
        "Authorization": 'Bearer ' + jwtToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ public_token: public_token, account_id : bankAcctId }),

    });
    const data = await response.json();
    const bankAcctIdStripe = data?.stripe_bank_account_token;
    handleFormValues('bankAcctId', bankAcctIdStripe);

    const responseUpdateBank = await fetch(NODE_API_ENDPOINT + '/change-seller-bank-by-seller-id', {
      method: 'POST',
      headers: {
        "Authorization": 'Bearer ' + jwtToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sellerID,
        bankAcctId: bankAcctIdStripe,
      }),
    });
    const dataUpdateBank = await responseUpdateBank.json();
    if (dataUpdateBank.success === true) { 
      console.log('Successfully updated bank!');
      handleFormValues('updatedBank', true);
    }


  }, []);
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: props.linkToken!,
    // receivedRedirectUri: window.location.href,
    onSuccess,
  };
  const { open } = usePlaidLink(config);
  return (
    <CoralButton onClick={() => open()} disabled={formValues?.bankAcctId || formValues?.bankAcctId?.includes('btok_')}>
      <Typography sx={{ margin: "8px auto", fontSize: 28 }} className={"roboto-medium"}>
        {!formValues?.bankAcctId || !formValues?.bankAcctId?.includes('btok_') ? 'Link New Bank Account' : 'Bank Account Linked!'}
      </Typography>
    </CoralButton>
  );
};
export default PlaidApp;