import { useEffect, useState } from "react";
import { AdminPortalManagementMaster } from "..";
import {
  Container,
  Divider,
  Grid,
  Typography,
  Button,
  Avatar,
  TextField,
  InputAdornment,
} from "@mui/material";
import StoresApprovalTables from "../../components/StoresApprovalTables";
import { getAllSellersApprovals, updateSellerApprovalStatus } from "../../util/admin";
import { APPROVAL_STATUS } from "../../util/constants";
import { getUserIdFromAuth, getUserIdFromAuthAsync, isPurposerUserAdmin } from "../../util/utilities";


const ContentRow = ({ title, children }) => {
  return (
    <Grid container style={{ padding: "32px 32px 32px 32px" }}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ color: "var(--coral)" }}>
          <b>{title}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

function StoreApproval() {
  const [filterAwaiting, setFilterAwaiting] = useState('Newest First');
  const [filterApproved, setFilterApproved] = useState('Newest First');
  const [filterDisapproved, setFilterDisapproved] = useState('Newest First');

  const [storesAwaiting, setStoresAwaiting] = useState(null);
  const [storesApproved, setStoresApproved] = useState(null);
  const [storesDisapproved, setStoresDisapproved] = useState(null);

  const [loadingGetStores, setLoadingGetStores] = useState(false);
  const [loadingChangeApproval, setLoadingChangeApproval] = useState(false);

  useEffect(() => {
    if (!loadingGetStores && !storesAwaiting && !storesApproved) {
      setLoadingGetStores(true)
      getAllSellersApprovals((data) => {
        const stores = data?.results
        if (stores) {
          sortStores(stores);
        } else {
          console.log("Can't find stores!")
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!storesAwaiting || !storesApproved || !storesDisapproved) return;
    sortStores([...storesAwaiting, ...storesApproved, ...storesDisapproved]);
  }, [filterAwaiting, filterApproved, filterDisapproved]);

  function sortArrayWithFilter(stores, filter) {
    if (filter == 'Newest First') {
      stores.sort((a, b) => { return new Date(b.approvalDate) - new Date(a.approvalDate) });
    } else if (filter == 'Oldest First') {
      stores.sort((b, a) => { return new Date(b.approvalDate) - new Date(a.approvalDate) });
    } else if (filter == 'AZ') {
      stores.sort((a, b) => {
        if (a.sellerName.toLowerCase() < b.sellerName.toLowerCase()) {
          return -1;
        }
        if (a.sellerName.toLowerCase() > b.sellerName.toLowerCase()) {
          return 1;
        }
        return 0;
      })
    } else if (filter == 'ZA') {
      stores.sort((b, a) => {
        if (a.sellerName.toLowerCase() < b.sellerName.toLowerCase()) {
          return -1;
        }
        if (a.sellerName.toLowerCase() > b.sellerName.toLowerCase()) {
          return 1;
        }
        return 0;
      })
    }
  }

  function sortStores(stores) {
    const currPendingStores = stores.filter(o => o.approvalStatus == APPROVAL_STATUS.PENDING);
    const currApprovedStores = stores.filter(o => o.approvalStatus == APPROVAL_STATUS.APPROVED);
    const currDeniedStores = stores.filter(o => o.approvalStatus == APPROVAL_STATUS.DENIED);
    sortArrayWithFilter(currPendingStores, filterAwaiting);
    sortArrayWithFilter(currApprovedStores, filterApproved);
    sortArrayWithFilter(currDeniedStores, filterDisapproved);
    setStoresAwaiting(currPendingStores);
    setStoresApproved(currApprovedStores);
    setStoresDisapproved(currDeniedStores);
  }

  const handleSubmit = async (e, sellerID, approvalStatus) => {
    if (!loadingChangeApproval && sellerID) {
      e.preventDefault();
      setLoadingChangeApproval(true);
      updateSellerApprovalStatus(sellerID, approvalStatus, (data) => {
        if (data.error || !data?.results) {
          console.log('Error changing store approval status!')
          setLoadingChangeApproval(false);
        } else {
          console.log('Success changing store approval status!')
          let newStores = [...storesAwaiting, ...storesApproved, ...storesDisapproved];
          const changedStore = newStores.find(o => o.sellerID.toLowerCase() == sellerID.toLowerCase())
          changedStore.approvalStatus = approvalStatus;
          changedStore.approvalDate = new Date();
          sortStores(newStores);
          setLoadingChangeApproval(false);
        }
      });
    }
  }

  return (
    <AdminPortalManagementMaster title={"Store Approval"}>
      <Container maxWidth="xl">
        <ContentRow>
          <StoresApprovalTables
            title="Stores Awaiting Approval"
            storeData={storesAwaiting}
            setStoreData={setStoresAwaiting}
            filter={filterAwaiting}
            setFilter={setFilterAwaiting}
            handleSubmit={handleSubmit}
          />
        </ContentRow>
        <ContentRow>
          <StoresApprovalTables
            title="Approved Stores"
            storeData={storesApproved}
            setStoreData={setStoresApproved}
            filter={filterApproved}
            setFilter={setFilterApproved}
            handleSubmit={handleSubmit}
          />
        </ContentRow>
        <ContentRow>
          <StoresApprovalTables
            title="Disapproved Stores"
            storeData={storesDisapproved}
            setStoreData={setStoresDisapproved}
            filter={filterDisapproved}
            setFilter={setFilterDisapproved}
            handleSubmit={handleSubmit}
          />
        </ContentRow>
      </Container>

    </AdminPortalManagementMaster>
  )
}

export default StoreApproval;