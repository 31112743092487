import { Container, Typography, Grid, Button, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ExpandMore } from "@mui/icons-material";
import clsx from "clsx";
import { round } from "../util/utilities";

//Default Prop Values
import addedToCartDataDefault from "../json/added_to_cart.json";

const useStyles = makeStyles({
  outline: {
    borderStyle: "solid",
    borderWidth: 1,
  },
  grid: {
    marginTop: 50,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 30,
  },
  img: {
    maxWidth: "100%",
  },
  descriptionGridItem: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
  },
  textSpacing: {
    marginBottom: 5,
  },
  shopText: {
    textDecoration: "underline",
  },
  checkoutGridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    textTransform: "none",
    backgroundColor: "var(--coral)",
    color: "#FFFFFF",
    borderRadius: 10,
    width: "85%",
    height: 45,
    "&:hover": {
      backgroundColor: "var(--coral)",
    },
  },
  text: {
    fontSize: "1.1rem",
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
});

function AddedToCart(props) {
  const classes = useStyles();

  const { addedToCartData = addedToCartDataDefault } = props;

  return (
    <Container maxWidth="xl">
      <Typography align="center" className={classes.text}>
        <b>Added to Cart!</b>
      </Typography>
      <div className={classes.outline}>
        <Grid container direction="row" className={classes.grid}>
          <Grid item xs={4}>
            <img src={addedToCartData.img} alt="" className={classes.img} />
          </Grid>
          <Grid item xs={4} className={classes.descriptionGridItem}>
            <div>
              <Typography className={clsx(classes.textSpacing, classes.text)}>
                {addedToCartData.productName}
              </Typography>
              <a href={`/shop/category/${addedToCartData.category}`}>
                <Typography
                  className={clsx(
                    classes.shopText,
                    classes.textSpacing,
                    classes.text
                  )}
                >
                  SHOP: {addedToCartData.category}
                </Typography>
              </a>
              <Typography className={classes.text}>
                <b>${round(addedToCartData.price, 2)}</b>
              </Typography>
              <Typography className={classes.text}>
                QTY: {addedToCartData.quantity}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4} className={classes.checkoutGridItem}>
            <Button className={classes.button}>
              <Typography className={classes.text}>
                <b>Checkout Now</b>
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography>
              <Link href="/" color="inherit" className={classes.link}>
                <b>All items in your Cart</b>
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <a href="/">
              <ExpandMore fontSize="large" />
            </a>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default AddedToCart;
