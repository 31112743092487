import { Typography, Grid, Button, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import clsx from "clsx";
import otherInterestsData from "../json/other_interests.json";

const useStyles = makeStyles({
  interestTextOffset: {
    marginLeft: 20,
  },
  interestHeader: {
    marginBottom: 30,
  },
  interestImage: {
    maxWidth: "100%",
    objectFit: "cover",
    borderRadius: 36,
    aspectRatio: 403 / 274,
  },
  interestLink: {
    textDecoration: "none",
    color: "#000000",
  },
  interestSubcategoryText: {
    marginTop: 5,
  },
  interestMoreButton: {
    textTransform: "none",
    textDecoration: "underline",
    backgroundColor: "var(--lightGrayTransparent)",
    borderRadius: 10,
    minHeight: 60,
    minWidth: 200,
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "var(--lightGrayTransparent)",
    },
  },
  interestMoreButtonText: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
  },
});

function Subcategory(picture, classes, name, url) {
  return (
    <Grid item xs={8} sm={8} md={4}>
      <a href={url} className={classes.interestLink}>
        <img src={picture} alt="" className={classes.interestImage} />
        <Typography
          align="left"
          variant="h6"
          className={clsx(
            classes.interestTextOffset,
            classes.interestSubcategoryText,
            "roboto-medium"
          )}
        >
          {name} &gt;
        </Typography>
      </a>
    </Grid>
  );
}

function OtherInterests(props) {
  let classes = useStyles();

  //let { otherInterests = otherInterestsData } = props;
  let otherInterests = otherInterestsData;

  return (
    <Container maxWidth="xl">
      <Typography
        variant="h4"
        className={clsx(
          classes.interestHeader,
          classes.interestTextOffset,
          "roboto-medium"
        )}
      >
        YOU MIGHT ALSO BE INTERESTED
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        align="center"
        columns={16}
        columnSpacing={3}
        rowSpacing={8}
      >
        {otherInterests.map((category, index) => {
          return (
            <React.Fragment key={index}>
              {Subcategory(
                category.subcategories[0].picture,
                classes,
                category.subcategories[0].name,
                category.subcategories[0].subcategory_url
              )}
              {Subcategory(
                category.subcategories[1].picture,
                classes,
                category.subcategories[1].name,
                category.subcategories[1].subcategory_url
              )}
              {/* <Grid item xs={16} md={4}>
                <Button
                  href={category.url}
                  className={classes.interestMoreButton}
                >
                  <Typography
                    variant="h6"
                    className={clsx(
                      classes.interestMoreButtonText,
                      "roboto-medium"
                    )}
                  >
                    More in {category.name}
                  </Typography>
                </Button>
              </Grid> */}
            </React.Fragment>
          );
        })}
      </Grid>
    </Container>
  );
}

export default OtherInterests;
