import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import SupportedPurposesMap from "./SupportedPurposesMap";

const useStyles = makeStyles({
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 15,
  },
  signUpTextField: {
    "& label": {
      color: "black",
      fontSize: "1.375rem",
      display: "block",
    },
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiOutlinedInput-root": {
      minHeight: 60,
      borderRadius: 15,
      backgroundColor: "white",
      color: "#FFFFFF",
      borderColor: "var(--mintGreen)",
      "& fieldset": {
        borderColor: "var(--mintGreen)",
      },
      "&:hover fieldset": {
        borderColor: "var(--mintGreen)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--mintGreen)",
      },
    },
  },
  signUpButton: {
    backgroundColor: "var(--mintGreenSolid)",
    borderRadius: 10,
    minHeight: 60,
    width: "200px",
    "&:hover": {
      backgroundColor: "var(--mintGreenSolid)",
    },
    color: "#FFFFFF",
  },
  signUpButtonText: {
    fontSize: "1.375rem",
  },
});

const InfoCard = ({ title, img_url, purchase_count }) => {
  const classes = useStyles();
  return (
    <Grid item xs={6} md={4}>
      <Box sx={{ width: 265, height: 200 }}>
        <img className={classes.img} src={img_url} alt={title} />
      </Box>
      <Typography sx={{ mt: 1 }}>{title}</Typography>
      <Stack direction={"row"} justifyContent="center" spacing={1.5}>
        <Typography>
          <b>Purchases </b>
        </Typography>
        <Box
          sx={{
            border: "var(--mintGreen) solid 1px",
            px: 2,
            borderRadius: 5,
          }}
        >
          <Typography>{purchase_count}</Typography>
        </Box>
      </Stack>
    </Grid>
  );
};

const SupportedPurposesOther = () => {
  const classes = useStyles();

  const [email, setEmail] = React.useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: "var(--coral)",
          py: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ mb: 3 }}>
          Want A Summary of Your Impact So Far?
        </Typography>
        <Container maxWidth="md">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            columnSpacing={2}
            rowSpacing={2}
          >
            <Grid item xs>
              <TextField
                fullWidth
                // label={email === "" ? "Email Address" : ""}
                onChange={handleEmailChange}
                variant="outlined"
                InputLabelProps={{
                  shrink: false,
                }}
                className={classes.signUpTextField}
                sx={{ fontSize: { xs: "1.2rem", sm: "1.4rem", md: "1.6rem" } }}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button variant="contained" className={classes.signUpButton}>
                  {/* <Typography className={classes.signUpButtonText}>
                    <b>SIGN UP</b>
                  </Typography> */}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="xl">
        <Typography sx={{ mt: 5 }} align="center" variant="h5">
          <b>All the Countries You've Supported</b>
        </Typography>
        <Box sx={{ my: 6 }}>
          <SupportedPurposesMap />
        </Box>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={5}
          align="center"
        >
          {secondSection.map((item, index) => {
            return (
              <InfoCard
                key={index}
                title={item.title}
                img_url={item.img_url}
                purchase_count={item.purchase_count}
              />
            );
          })}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <ButtonBase
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 1,
              p: 1,
            }}
          >
            <Typography>
              <i>More.</i>
            </Typography>
            <ExpandMore />
          </ButtonBase>
        </Box>
      </Container>
    </>
  );
};

const secondSection = [
  {
    title: "India",
    img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/common/7d7fcf4b-b68a-485f-ba33-4ded2aece2d0.jpeg",
    purchase_count: 1,
  },
  {
    title: "Haiti",
    img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/common/15594a8b-92b3-4364-965e-87e91b7f4e86.jpeg",
    purchase_count: 1,
  },
  {
    title: "Brazil",
    img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/common/2041d032-830b-4e2b-8f8c-cc93e8ef153b.jpeg",
    purchase_count: 1,
  },
  {
    title: "China",
    img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/common/19c08d5f-4ea5-41f0-83b9-4fa206a9aff5.jpeg",
    purchase_count: 1,
  },
  {
    title: "Guatemala",
    img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/common/6aea3938-93af-4c4e-87d8-d56f42475ad4.jpeg",
    purchase_count: 1,
  },
  {
    title: "Africa",
    img_url:
      "https://d2eudfgh8kpk8j.cloudfront.net/common/9ec2d1b9-42bc-4945-be21-9326ccfdb368.jpeg",
    purchase_count: 1,
  },
];

export default SupportedPurposesOther;
