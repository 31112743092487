import { ButtonBase, Container, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";
import { addEmailToMarketingList } from "../util/klaviyo";
import { isValidEmail } from "../util/utilities";
import toast, { Toaster } from 'react-hot-toast';

const useStyles = makeStyles({
  inputOutline: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 5,
      "&:hover fieldset": {
        borderColor: "var(--coral)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--coral)",
      },
    },
  },
  textFieldDiv: {
    display: "block",
    marginTop: 30,
  },
  signUpButton: {
    backgroundColor: "var(--coral)",
    color: "white",
    padding: "12px 30px",
    marginLeft: 10,
    borderRadius: 5,
  },
});

const NewsletterSignUp = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const mobile = useMobileMediaQuery();
  const [isLoading, setIsLoading] = useState(false);

  const handleFormValues = (e) => {
    setEmail(e.target.value);
  };

  const handleClickSignUpEmail = (event) => {
    if (!isLoading && isValidEmail(email)) {
      setIsLoading(true);
      addEmailToMarketingList(email, (data) => {
        if (data && data.success === true) {
          toast.success('Success!')
          setIsLoading(false);
        } else {
          toast.error(`Error! ${data?.note ? data?.note : ''}`);
          setIsLoading(false);
          return;
        }
      })
    } else if (!isValidEmail(email)) {
      toast.error('Invalid email address!');
    }
  };

  return (
    <Container maxWidth="xl" align="center">
      <Toaster 
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <Typography variant="h4" gutterBottom>
        Become A Part of Our Community!
      </Typography>
      <Typography variant="body1" gutterBottom>
        <b>
          <i>Sign up for our weekly newsletter for specials and more!</i>
        </b>
      </Typography>
      <div className={classes.textFieldDiv}>
        <TextField
          name="email"
          onChange={handleFormValues}
          defaultValue={email}
          variant="outlined"
          size="small"
          className={classes.inputOutline}
          placeholder="Enter your email"
          style={{
            width: mobile ? "100%" : "40%",
          }}
        />
        <ButtonBase
          className={classes.signUpButton}
          style={{ marginTop: mobile ? 30 : 0 }}
          onClick={handleClickSignUpEmail}
          disabled={isLoading}
        >
          SIGN UP
        </ButtonBase>
      </div>
    </Container>
  );
};

export default NewsletterSignUp;
