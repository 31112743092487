import { Container, Typography, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import photoGuidelineData from "../json/product_photo_guideline.json";
import clsx from "clsx";
import { useRef } from "react";
import useElementWidth from "../hooks/useElementWidth";

const useStyles = makeStyles({
  backgroundDiv: {
    backgroundColor: "inherit",
    paddingTop: 25,
    paddingBottom: 75,
  },
  header: {
    marginBottom: 40,
  },
  guidelineHeader: {
    marginBottom: 20,
  },
  img: {
    width: "100%",
    borderRadius: 15,
    objectFit: "cover",
  },
});

const Guideline = (props) => {
  const { guideline, gridItemRef, gridItemWidth, classes } = props;

  return (
    <Grid item sm={12} md={6}>
      <Typography
        align="center"
        variant="h5"
        className={clsx(classes.guidelineHeader, "roboto-light-italic")}
      >
        {guideline.header}
      </Typography>
      <Grid container direction="row" columnSpacing={5}>
        <Grid item xs={6} align="center" ref={gridItemRef}>
          <img
            src={guideline.do_img}
            alt=""
            className={classes.img}
            style={{ height: 1.1 * gridItemWidth }}
          />
          <Typography align="center" className={"roboto-regular"}>
            Do's
          </Typography>
        </Grid>
        <Grid item xs={6} align="center">
          <img
            src={guideline.dont_img}
            alt=""
            className={classes.img}
            style={{ height: 1.1 * gridItemWidth }}
          />
          <Typography align="center" className={"roboto-regular"}>
            Don'ts
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

function ProductPhotoGuideline(props) {
  const classes = useStyles();
  const { productPhotoGuidelineData = photoGuidelineData } = props;
  const gridItemRef = useRef(null);
  const gridItemWidth = useElementWidth(gridItemRef);

  return (
    <div className={classes.backgroundDiv}>
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant="h3"
          className={clsx(classes.header, "roboto-medium-italic")}
        >
          Product Photo Guideline
        </Typography>
        <Grid container direction="row" rowSpacing={2} columnSpacing={16}>
          {productPhotoGuidelineData &&
            productPhotoGuidelineData.map((guideline, index) => {
              return (
                <Guideline
                  guideline={guideline}
                  classes={classes}
                  key={index}
                  gridItemRef={gridItemRef}
                  gridItemWidth={gridItemWidth}
                />
              );
            })}
        </Grid>
      </Container>
    </div>
  );
}

export default ProductPhotoGuideline;
