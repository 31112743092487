import { Box, Container, Divider, Stack, TextField, Typography } from "@mui/material";
import ReactMarkdown from 'react-markdown';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  markdown: {
    '& a': {
      color: 'blue',
    },
    '& a:hover': {
      color: 'darkblue',
    },
    '& a:visited': {
      color: 'purple',
    },
    '& img': {
      maxWidth: '600px',
      maxHeight: '600px',
      display: 'block', // This will center the images
      margin: '0 auto', // This will center the images
    },
  },
});

function BlogArticle(props) {
  const classes = useStyles();

  const { blogData } = props;

  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ my: 5 }}>
            <Typography
              align="center"
              // variant="h3"
              sx={{ fontSize: "3rem" }}
            >
              {blogData?.title}
            </Typography>
            <Typography
              align="center"
              variant="h1"
              sx={{ fontSize: "2rem" }}
            >
              {blogData?.subtitle}
            </Typography>
            <Container
              maxWidth="xl"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <Divider
                sx={{
                  height: 1.5,
                  backgroundColor: "black",
                  width: "50%",
                }}
              />
              <Typography align="center" sx={{ margin: "auto 15px" }}>
                {"Written: " + new Date(blogData?.createDate).toLocaleDateString() + (blogData?.editDate ? "\nEdited: " + new Date(blogData?.editDate).toLocaleDateString() : "")}
              </Typography>
              <Divider
                sx={{
                  height: 1.5,
                  backgroundColor: "black",
                  width: "50%",
                }}
              />
            </Container>
          </Box>
        </Box>
      </Container>
      <Box sx={{ height: 400 }}>
        {blogData?.pictures && blogData.pictures.length > 0 && <img
          src={
            blogData?.pictures[0]
          }
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
          alt=""
        />}
      </Box>
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Container
            maxWidth="md"
            sx={{ display: "flex", flexDirection: "column", gap: 3, mb: 5 }}
          >

            <Typography component="div" style={{ color: 'inherit', fontSize: '1.2rem' }}>
              <ReactMarkdown children={blogData.body} linkTarget="_blank" className={classes.markdown} />
            </Typography>
            <Typography component="div" style={{ color: 'inherit', fontSize: '1.2rem', lineHeight: "1.5" }} variant="h3">
              <ReactMarkdown children={blogData.summary} linkTarget="_blank" className={classes.markdown} />
            </Typography>
            {/* <TextField
            multiline
            fullWidth
            rows={25}
            value={blogData?.body}/> */}
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
              spacing={3}
            >
              <Box sx={{}}>
                {blogData?.pictures && blogData.pictures.length > 0 && <img
                  src={
                    blogData?.pictures[1]
                  }
                  style={{
                    width: 900,
                    height: 600,
                    objectFit: "cover",
                  }}
                  alt=""
                />}
              </Box>

              <Box sx={{}}>
                {blogData?.pictures && blogData.pictures.length > 0 && <img
                  src={
                    blogData?.pictures[2]
                  }
                  style={{
                    width: 900,
                    height: 600,
                    objectFit: "cover",
                  }}
                  alt=""
                />}
              </Box>
            </Stack>
          </Container>
        </Box>
      </Container>
    </>
  );
};

export default BlogArticle;
