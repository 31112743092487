
import STRIPE_MCC_CODES from "../json/stripe_mcc_codes.json";
import STRIPE_TAX_CATEGORIES from "../json/stripe_tax_categories.json";
import { isProd } from "./utilities";

const NODE_API_ENDPOINT_REMOTE = isProd() ? "https://6m3y7r112b.execute-api.us-west-1.amazonaws.com/prod" : "https://smmuxqlq2b.execute-api.us-west-1.amazonaws.com/preprod";
const NODE_API_ENDPOINT_LOCAL = "http://localhost:4242";
const TEST_LOCALLY = false;   // set to true when testing locally
const NODE_API_ENDPOINT = TEST_LOCALLY ? NODE_API_ENDPOINT_LOCAL : NODE_API_ENDPOINT_REMOTE;

const MAX_FILE_SIZE = 4500000;
const FILETYPES = [".jpg", ".jpeg", ".png"];
const MAX_NUM_FILES = 4;
const MIN_REVIEW_LENGTH = 90;
const MAX_REVIEW_LENGTH = 550;

const SHIPPING_CHARGE_TYPES = [
  'Fixed Price',
  'Free Shipping',
];

const SHIPPING_UPGRADE_TYPES = [
  'Economy',
  'Express',
  '1 Day'
];

const SHIPPING_SERVICES_USPS = [
  'USPS Priority Mail (1-3 business days)',
  'USPS First-Class Mail (1-5 business days)',
  'USPS Media Mail (2-8 business days)',
  'USPS Parcel Post (2-8 business days)',
  'USPS Parcel Select Ground (2-8 business days)',
  'USPS Priority Mail Express (1-2 business days)',
];

const SHIPPING_SERVICES_FEDEX = [
  'FedEx Home Delivery® (1-7 business days)',
  'FedEx Express Saver® (3 business days)',
  'FedEx First Overnight® (1 business day)',
  'FedEx Ground® (1-5 business days)',
  'FedEx Priority Overnight® (1 business day)',
  'FedEx SmartPost® (2-7 business days)',
  'FedEx Standard Overnight® (1 business day)',
  'FedEx 2Day® (2 business days)',
  'FedEx 2Day® AM (2 business days)',
];

const SHIPPING_SERVICES_UPS = [
  'UPS Express (1 business day)',
  'UPS Ground (1-5 business days)',
  'UPS Next Day Air (1 business day)',
  'UPS Next Day Air Saver (1 business day)',
  'UPS 2nd Day Air AM (2 business days)',
  'UPS Three Day Select (3 business days)',
  'UPS Two Day (2 business days)'
];

const SHIPPING_SERVICES_LOCAL = [
  'Local Pick Up'
];

const SHIPPING_SERVICES = [
  ...SHIPPING_SERVICES_USPS,
  ...SHIPPING_SERVICES_FEDEX,
  ...SHIPPING_SERVICES_UPS,
  ...SHIPPING_SERVICES_LOCAL,
  "Other"
];

const RETURN_STATUS = {
  PENDING: 'Pending',
  ERROR: 'Error',
  DENIED: 'Denied',
  PROCESSING: 'Processing',
  COMPLETED: 'Completed',
}

const COUPON_TYPES = {
  PROMO_CODE: 'PromoCode',
  SALE: 'Sale',
}

const BUSINESS_TYPE = {
  INDIVIDUAL: "individual",
  COMPANY: "company",
};

const COMPANY_TYPE = {
  SOLE_PROPRIETORSHIP: "sole_proprietorship",
  SINGLE_MEMBER_LLC: "single_member_llc",
  MULTI_MEMBER_LLC: "multi_member_llc",
  PRIVATE_CORPORATION: "private_corporation",
};

const RETURN_DENY_REASON = {
  NOT_ACCEPTABLE_CONDITION: 'Product is not in acceptable condition',
  NEVER_RECEIVED: 'Product was never received',
  OTHER: 'Other reason; product request is denied',
}

const RETURN_POLICY_LIST = [
  { policy: "No Returns Allowed", maxDays: 0, customerText: "No Returns Allowed" },
  //  TODO: fix wording for below
  { policy: "Customer must send to a return address", maxDays: 30, customerText: "Your refund request was submitted to the seller. You will receive an email shortly. Please ship your package to the address below. Include a note with your First and Last Name, and order number." },
  { policy: "Free Returns, label emailed", maxDays: 30, customerText: "Free Returns, label emailed" },
  //{ policy: "Shipping Fee, label emailed", customerText: "Shipping Fee, label emailed", feeIncluded: true, fee: 0 },
];

const RETURN_BUYER_REASONS = [
  { label: "Wrong item", value: "Wrong item" },
  { label: "Wrong size", value: "Wrong size" },
  { label: "Bought by mistake", value: "Bought by mistake" },
  { label: "No longer needed", value: "No longer needed" },
  { label: "Missing or broken parts", value: "Missing or broken parts" },
  { label: "Inaccurate website description", value: "Inaccurate website description" },
  { label: "Other", value: "Other" }
]

const RETURN_SELLER_REASONS = [
  { label: "Cancel Order", value: "Cancel Order" },
  { label: "Out of Stock", value: "Out of Stock" },
  { label: "Production Problem", value: "Production Problem" },
  { label: "Customer Bought By Mistake", value: "Customer Bought By Mistake" },
  { label: "Other", value: "Other" }
]

const PRODUCT_SIZE_TYPE = {
  STANDARD: 'Standard',
  DIMENSIONS_L_W_H: 'Dimensions (length x width x height/depth)',
  DIMENSIONS_L_W: 'Dimensions (length x width)',
  LENGTH_OR_WIDTH: 'Length or Width',
  WEIGHT: 'Weight',
  SET: 'Set',
  CUSTOM: 'Custom',
}

const PURPOSER_ADMIN_USER_IDS = ['15ff129c-30ab-4450-865d-6a2b0db22276'];


const PRODUCT_COLORS = [
  {
    name: "N/A",
    color: "N/A"
  },
  {
    name: "multi",
    color: "multi"
  },
  {
    name: "beige",
    color: "var(--beige)",
  },
  {
    name: "black",
    color: "black",
  },
  {
    name: "blue",
    color: "#2C3691",
  },
  {
    name: "brown",
    color: "#603918",
  },
  {
    name: "clear",
    color: "lightgray"
  },
  {
    name: "gold",
    color: "gold"
  },
  {
    name: "gray",
    color: "darkgray",
  },
  
  {
    name: "green",
    color: "#14A751",
  },
  {
    name: "orange",
    color: "orange"
  },
  {
    name: "pink",
    color: "pink",
  },
  {
    name: "purple",
    color: "purple",
  },
  {
    name: "red",
    color: "red",
  },
  {
    name: "rose gold",
    color: "#b76e79"
  },
  {
    name: "silver",
    color: "linear-gradient(to bottom, #bcc6cc, #eeeee, #bcc6cc)"
  },
  {
    name: "white",
    color: "white",
  },
  {
    name: "yellow",
    color: "#D6Df27",
  },
  {
    name: "mint green",
    color: "var(--mintGreen)",
  },
  {
    name: "metallic pink",
    color: "#eda6c4"
  },
  {
    name: "metallic green",
    color: "#7c9d8e"
  },
  {
    name: "metallic blue",
    color: "#32527b"
  },
  {
    name: "metallic red",
    color: "#871614"
  },
  {
    name: "metallic silver",
    color: "#99a1ac"
  },
  {
    name: "navy",
    color: "#152238"
  },
  {
    name: "taupe",
    color: "#b4a599"
  },
  {
    name: "tan",
    color: "#d2b48c"
  },
  {
    name: "burgundy",
    color: "#662c33"
  },
  {
    name: "fuschia",
    color: "#ae2865"
  },
  {
    name: "mustard",
    color: "#eab41e"
  },
  {
    name: "dark green",
    color: "#023020"
  },
  {
    name: "bronze",
    color: "#9c5221"
  }
];

const PRODUCT_SIZES = [
  "Standard",
  "Tiny",
  "Mini",
  "Extra Small",
  "Small",
  "Medium",
  "Large",
  "Extra Large",
  "XXS",
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "2XL",
  "3XL",
  "4XL",
  "5XL",
  "0",
  "2",
  "4",
  "6",
  "8",
  "10",
  "12",
  "14",
  "16"
]

const BLOG_CATEGORIES = [
  "Purposer Team Insights",
  "Seller Support",
  "Seller Stories",
  "Gift Guides",
  "Shopping Guides",
  "Inspiration",
  "Featured Shops",
  "Ethical Production",
  "Animal Conservation",
  "Mental Health",
  "Physical Health",
  "Religious and Spiritual",
  "Global Insights",
  "Purposer Impact",
  "Purposeful Purchases"
]

const PRODUCT_MATERIALS = [
  "paper",
  "plastic",
  "aluminum",
  "recycled plastic",
  "recycled paper",
  "cotton",
  "wool",
  "denim",
  "polyester",
  "linen",
  "rayon",
  "silk",
  "metals",
  "brass",
  "gold",
  "white gold",
  "rose gold",
  "silver",
  "gluten",
  "gluten-free",
  "soy-free",
  "vegan",
  "organic",
  "non-GMO",
  "dairy-free",
  "oil-free",
  "paraben-free"
]

const PRODUCT_LENGTH_UNITS = [
  "Inches",
  "Centimeters",
  "Feet"
]

const PRODUCT_WEIGHT_UNITS = [
  "lb",
  "oz"
]

const PRODUCT_TYPE = {
  PHYSICAL: 'Physical',
  DIGITAL: 'Digital',
  SERVICE: 'Service',
}

const APPROVAL_STATUS = {
  PENDING: 'Pending',
  DENIED: 'Denied',
  APPROVED: 'Approved',
}

const ORDER_STATUS = {
  PENDING: 'Pending',
  ERROR: 'Error',
  PROCESSING: 'Processing',
  SUBMITTED: 'Submitted',
  COMPLETED: 'Completed',
}

const SEARCH_ITEM_TYPES = {
  PRODUCT: 'Product',
  PURPOSE: 'Purpose',
  CATEGORY: 'Category',
  SEARCH: 'Search',
}

const PRESET_SEARCHES = [
  {
    id: 'stuffed animal',
    name: 'Inspirational Stuffed Animals',
    type: SEARCH_ITEM_TYPES.SEARCH
  },
  {
    id: 'candle',
    name: 'Sustainable Candles',
    type: SEARCH_ITEM_TYPES.SEARCH
  },
  {
    id: 'home goods',
    name: 'Home Goods & Art',
    type: SEARCH_ITEM_TYPES.SEARCH
  },
  {
    id: 'Soaps',
    name: 'Organic Soap Bars',
    type: SEARCH_ITEM_TYPES.SEARCH
  },
  {
    id: 'poverty',
    name: 'Products that Support Poverty Solutions',
    type: SEARCH_ITEM_TYPES.SEARCH
  },
  {
    id: 'animals',
    name: 'Products that Support Animals & Wildlife',
    type: SEARCH_ITEM_TYPES.SEARCH
  },
  {
    id: 'sustainability',
    name: 'Products that Plant Trees with each Purchase',
    type: SEARCH_ITEM_TYPES.SEARCH
  },
  {
    id: 'ocean',
    name: 'Products that Clean Our Oceans',
    type: SEARCH_ITEM_TYPES.SEARCH
  },
];

export {
  NODE_API_ENDPOINT,
  PRODUCT_SIZES, 
  PRODUCT_COLORS,
  PRODUCT_MATERIALS,
  PRODUCT_LENGTH_UNITS,
  PRODUCT_WEIGHT_UNITS,
  STRIPE_MCC_CODES,
  STRIPE_TAX_CATEGORIES,
  SHIPPING_UPGRADE_TYPES,
  SHIPPING_SERVICES,
  SHIPPING_CHARGE_TYPES,
  RETURN_STATUS,
  RETURN_POLICY_LIST,
  RETURN_BUYER_REASONS,
  RETURN_SELLER_REASONS,
  BUSINESS_TYPE,
  COMPANY_TYPE,
  COUPON_TYPES,
  PURPOSER_ADMIN_USER_IDS,
  APPROVAL_STATUS,
  ORDER_STATUS,
  BLOG_CATEGORIES,
  RETURN_DENY_REASON,
  PRODUCT_SIZE_TYPE,
  PRODUCT_TYPE,
  MAX_FILE_SIZE,
  MAX_NUM_FILES,
  MIN_REVIEW_LENGTH,
  MAX_REVIEW_LENGTH,
  FILETYPES,
  PRESET_SEARCHES,
  SEARCH_ITEM_TYPES,
  SHIPPING_SERVICES_LOCAL,
};
