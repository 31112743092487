import React, { useState } from "react";
import {
    ButtonGroup,
    Typography,
    Button,
    Stack,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { styled } from "@mui/material/styles";


const QuantityButtonContainer = styled(Stack)({
    height: 30,
    paddingTop: 5,
    paddingBottom: 20,
});

const GreyLabel = styled(Button)({
    border: "none",
    color: "#000000",
    borderRadius: 12,
    backgroundColor: "var(--lightGrayTransparent)",
    marginRight: 25,
    height: "100%",
    textTransform: "none",
    "&:hover": {
        border: "none",
        backgroundColor: "var(--lightGrayTransparent)",
    },
});

const QuantityCounterButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "position",
})(({ position }) => ({
    border: "none",
    color: "#000000",
    paddingLeft: 0,
    paddingRight: 0,
    borderTopLeftRadius: position === "left" ? 12 : 0,
    borderBottomLeftRadius: position === "left" ? 12 : 0,
    borderTopRightRadius: position === "right" ? 12 : 0,
    borderBottomRightRadius: position === "right" ? 12 : 0,
    backgroundColor: "var(--lightGrayTransparent)",
    "&:hover": {
        border: "none",
        backgroundColor: "var(--lightGrayTransparent)",
    },
}));

const QuantityButtonGroup = styled(ButtonGroup)({
    height: "100%",
});



const QuantityCounter = (props) => {
    const { quantityCount, setQuantityCount, numOrdered, skuID } = props;
    return (
        <QuantityButtonGroup variant="outlined">
            <QuantityCounterButton
                position={"left"}
                onClick={() => {
                    if (quantityCount > 1) {
                        setQuantityCount(skuID, quantityCount - 1);
                    }
                }}
            >
                <Remove fontSize="small" />
            </QuantityCounterButton>
            <QuantityCounterButton
                disableFocusRipple
                disableRipple
                disableTouchRipple
            >
                <Typography>QTY: {quantityCount}</Typography>
            </QuantityCounterButton>
            <QuantityCounterButton
                position={"right"}
                onClick={() => {
                    if (quantityCount < numOrdered) {
                        setQuantityCount(skuID, quantityCount + 1);
                    }
                }}
            >
                <Add fontSize="small" />
            </QuantityCounterButton>
        </QuantityButtonGroup>
    );
};

const QuantityButton = (props) => {
    const { numOrdered, quantityCount, setQuantityCount, skuID } = props;
    return (
        <QuantityButtonContainer direction="row">
            <QuantityCounter
                quantityCount={quantityCount}
                setQuantityCount={setQuantityCount}
                numOrdered={numOrdered}
                skuID={skuID}
            />
        </QuantityButtonContainer>
    );
};

export default QuantityButton;
