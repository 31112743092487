import {
  Box,
  ButtonBase,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import CarouselProductView from "../../components/CarouselProductView";
import MasterLayout from "../MasterLayouts/MasterLayout";
import { getOrderPlacedData } from "../../util/orders"
import { round, shortenUUID, formatDate, shuffle, splitArray } from "../../util/utilities"
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../../app/features/cart/cartDataSlice";
import { getRandomProducts } from "../../util/getProductsById";
import GalleryView from "../../components/GalleryView";

function useQuery() {
  const { search } = useLocation();
  let urlItems = `${search}`.split("?");
  urlItems.splice(0, 1);
  const itemObject = {}
  for (const item of urlItems) {
    itemObject[item.split('=')[0]] = item.split('=')[1];
  }
  return itemObject;
}

const OrderPlacedLayout = () => {

  const dispatch = useDispatch();
  const [sessionId, setSessionId] = useState('');
  const [orderPlacedData, setOrderPlacedData] = useState(null);
  let query = useQuery();

  useEffect(() => {
    if (query["session_id"] && !sessionId) {
      setSessionId(query["session_id"]);
      getOrderPlacedData(query["session_id"], (data) => {
        if (data) {
          setOrderPlacedData(data);
          dispatch(clearCart());
        } else {
          console.log("Can't find order placed data!")
        }
      });
    }
  }, [query]);


  const [randomProducts, setRandomProducts] = useState([[]]);
  const [loadingRandomProducts, setLoadingRandomProducts] = useState(true);

  useEffect(() => {
    getRandomProducts((data) => {
      if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
        let mainProducts = shuffle(data.mainProducts);
        let extraProducts = shuffle(data.extraProducts);
        const resultingProducts = [...mainProducts, ...extraProducts];
        setRandomProducts(splitArray(resultingProducts));
        setLoadingRandomProducts(false);
      }
    });
  }, []);

  return (
    <MasterLayout>
      <Box
        sx={{
          background:
            "linear-gradient(90deg, var(--beige) 15%, var(--lightMintGreen) 100%)",
        }}
      >
        <Container maxWidth="xl">
          <Typography variant="h4" align="center" sx={{ py: 5 }}>
            <b>THANK YOU!</b>
          </Typography>
          <Container maxWidth="sm">
            <Typography
              variant="subtitle1"
              align="center"
              sx={{ pb: 5, fontSize: 24 }}
            >
              <i>
                You just made a positive impact on the planet! Every order helps
                make the world a better place.
              </i>
            </Typography>
          </Container>
        </Container>
      </Box>
      <Box sx={{ height: "var(--dividerHeight)" }} />
      <Box sx={{ bgcolor: "var(--lightPastelGreen)" }}>
        <Container maxWidth="md">
          <Grid container>
            {sessionId && orderPlacedData &&
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: 22, mt: 2 }}>
                  Confirmation e-mail sent to:
                </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: 22 }}>
                  <b>{orderPlacedData?.email}</b>
                </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: 22, marginTop: 2 }}>
                  {`Your Order${orderPlacedData?.orderIDs?.length > 0 ? 's' : ''}:`}
                </Typography>
                {orderPlacedData?.orderIDs && orderPlacedData?.orderIDs?.length > 0 &&
                  <Grid item>
                    {orderPlacedData?.orderIDs.map((orderID, i) => {
                      return (<Typography variant="subtitle1" sx={{ fontSize: 16 }}><b>{shortenUUID(orderID)}</b></Typography>)
                    })
                    }
                  </Grid>
                }
                <Typography variant="subtitle1" sx={{ fontSize: 22, my: 2 }}>
                  Order Date: <b>{formatDate(orderPlacedData?.orderDateTime)}</b>
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={7}
                  sx={{ mb: 3 }}
                >
                  <Box sx={{ border: "lightGray 1px solid", py: 0.7, px: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontSize: 22 }}>
                      <b>{`${orderPlacedData?.totalNumItems} `}</b>{`Item${orderPlacedData?.totalNumItems > 1 ? 's' : ''}`}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1" sx={{ fontSize: 22 }}>
                      <b>${round((orderPlacedData?.checkoutAmountTotal / 100), 2)}</b>
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            }
            {!orderPlacedData &&
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: 22, mt: 2 }}>
                  Loading...
                </Typography>
              </Grid>
            }
            <Grid item xs={12} sm={6} md={6} sx={{ py: 8 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Stack spacing={2.5}>
                  <Typography variant="h5" align="center">
                    <b>Have Questions?</b>
                  </Typography>
                  <ButtonBase
                    sx={{
                      borderRadius: 2,
                      border: "var(--coral) solid 1px",
                      py: 1,
                      px: 3,
                    }}
                    component={Link}
                    to={"/faq"}
                  >
                    <Typography variant="subtitle1" sx={{ fontSize: 20 }}>
                      See Our FAQ Page
                    </Typography>
                  </ButtonBase>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{ height: "var(--dividerHeight)" }} />

      <Container maxWidth="xl">
        <Typography variant="h5" gutterBottom className={"roboto-medium"}>
          Checkout Other Products
        </Typography>
      </Container>
      <GalleryView
        productPages={randomProducts}
        itemsPerRow={6}
        extraPadding={true}
        showLoadingIcon={loadingRandomProducts}
      />
      <Box sx={{ height: "var(--dividerHeight)" }} />
    </MasterLayout>
  );
};

export default OrderPlacedLayout;
