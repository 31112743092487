import {
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { getListOfProductsById } from "../util/getProductsById";
import { getFavorites } from "../util/favorites";

//Default Prop Values
import GalleryView from "./GalleryView";
import useGalleryPages from "../hooks/useGalleryPages";
import FavoriteIcon from "@mui/icons-material/Favorite";

const useStyles = makeStyles({
  header: {
    fontSize: "1.3rem",
    marginBottom: 25,
  },
  carouselDiv: {
    marginBottom: 20,
  },
});

function Favorites() {
  const classes = useStyles();

  const [currentProductList, setCurrentProductList] = React.useState([]);
  const [currentProductGallery, setProductGallery] = React.useState([[]]);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);


  React.useEffect(() => {
    if (!isLoaded && currentProductList.length < 1) {
      setIsLoaded(true)
      getFavorites((data) => {
        if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message") && data.results) {
          getListOfProductsById(
            data.results.map(x => x.productID),
            (transformedProductGallery) => {
              if (
                !transformedProductGallery.hasOwnProperty("error") &&
                !transformedProductGallery.hasOwnProperty("message")
              ) {
                setCurrentProductList(transformedProductGallery);
                setIsLoading(false);
              }
            }
          );
          if (data?.results?.length < 1) {
            setIsLoading(false);
          }
        }
      });
    }
  }, []);

  useGalleryPages(currentProductList, setProductGallery, 32);

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>My Favorites</h1>
      {/* <Typography
        align="center"
        variant="h3"
        sx={{ color: "var(--mintGreen)" }}
      >
        My Favorites
      </Typography> */}
      <div className={classes.galleryViewDiv}>
        <GalleryView productPages={currentProductGallery} showLoadingIcon={isLoading} />
      </div>
    </div>
  );
}

export default Favorites;
