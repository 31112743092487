import {
    Box,
    Container,
    Divider,
    Grid,
    Typography,
    Button
  } from "@mui/material";
  import makeStyles from "@mui/styles/makeStyles";
  import { PurposeCategoryMasterLayout } from "../../layouts";
  import clsx from "clsx";
  import footerJsonData from "../../json/footer.json";
  import JobsData from "../../json/jobs.json";
  import TeamData from "../../json/team_members.json";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useDocumentDescription from "../../hooks/useDocumentDescription";
  
  const useStyles = makeStyles({
    headerDiv: {
      backgroundColor: "var(--coral)",
      color: "black",
      overflow: "hidden",
      padding: 50,
    },
    headerImage: {
        width: "100%",
    },
    profileImage: {
        width: "100%",
        borderRadius: "50%",
        aspectRatio: 1/1,
        objectFit: 'cover'
    },
    testimonyImage: {
        width: "70%",
        borderRadius: "50%"
    },
    cartButton: {
        textTransform: "none",
        marginTop: 40,
        borderRadius: 10,
        minWidth: 170,
        minHeight: 55,
        padding: "2px 20px",
        borderColor: "var(--coral)",
        borderWidth: "4px",
        "&:hover": {
          backgroundColor: "var(--coral)",
          borderWidth: "4px",
          borderColor: "var(--coral)",
        },
      },
      cartButtonText: {
        fontSize: "1.35rem",
        color: "#000000",
        textAlign: "center"
      },
      cartButtonCenter: {
        display: "flex",
        justifyContent: "center",
      },  
      bodyText: {
        align: "center",
      },
      footerSocialMediaGridItem: {
        paddingRight: 15,
      },
      footerSocialMediaButtonGridItem: {
        padding: "0px 16px",
        display: "flex",
        alignItems: "center",
      },
      footerSocialMediaButtonIcon: {
        width: "64px",
        height: "64px"
      },
  });

  const SocialMediaButton = (props) => {
    const { link, icon, classes } = props;
    return (
      <Grid item className={classes.footerSocialMediaButtonGridItem}>
        <a href={link}>
          <img
            src={icon}
            alt=""
            className={classes.footerSocialMediaButtonIcon}
          />
        </a>
      </Grid>
    );
  };
  
  const CareersLayout = () => {
    const classes = useStyles();
    const footerData = footerJsonData.columns;
    const jobs = JobsData;
    const { teamMembers2022, testimonies2022 } = TeamData;
  
    useDocumentTitle("Join the Purposer Team. Unique career opportunities. Contact us today!");
    useDocumentDescription("When you work with our team at Purposer, you will not only love your job but you will also be making a positive impact on the planet and communities across the world. Check out our career opportunities.");
    
    return (
      <PurposeCategoryMasterLayout>
        <Divider />
        <Typography className={classes.headerDiv} style={{fontSize: "68px"}} align="center">
            Careers
        </Typography>
        <div style={{ height: "var(--dividerHeight)" }} />
        <Container maxWidth="xl">
            <Grid container spacing={12} justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={12} md={6} >
                    <Typography className={clsx("roboto-bold")} style={{fontSize: "60px"}} variant="h1" align="center">
                        Join our team!
                    </Typography>
                    <Typography className={clsx("roboto-light")} style={{fontSize: "30px", lineHeight: "1.5"}} variant="h2" align="center">
                    Help make a positive impact in the world, one purchase at a time. We
                    are looking for passionate, hardworking individuals who are interested 
                    in gaining experience in e-commerce, marketing, and start-ups.
                    </Typography>
                    <div className={classes.cartButtonCenter}>
                        <Button
                        variant="outlined"
                        href="/"  
                        className={classes.cartButton}
                        >
                        <Typography className={classes.cartButtonText}>
                            <b>Learn More</b>
                        </Typography>
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <img
                    src="https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/About+Us/1.png"
                    alt="Purposer Team Picture"
                    className={classes.headerImage}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={12} justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={12} md={6}>
                    <img
                    src="https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/About+Us/2.png"
                    alt="Purposer Team Picture"
                    className={classes.headerImage}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={clsx("roboto-bold")} variant="h2" align="center">
                        Available Positions
                    </Typography>
                    <Typography className={clsx("roboto-light")} variant="h4" align="center">
                    We couldn't achieve our mission without our employees and the work
                    that they do everyday. We are a small and collaborative team that 
                    strives to support each other in every way possible. If you are interested in
                    joining the team, check out the positions we have available below.
                    </Typography>
                </Grid>
            </Grid>
            <div style={{ height: "var(--dividerHeight)" }} />
            <Grid container spacing={12}  alignItems="center" rowSpacing={2} columnSpacing={2}>
                {jobs.map((item, index) => {
                    return (
                        <Grid item xs={12} sm={12} md={12} lg={6} key={index}>
                            <div style={{backgroundColor: "#EEEEEE", minHeight: "480px", padding: "64px"}}>
                                <Typography className={clsx("roboto-light")} variant="h4" align="center">
                                    {item.type}
                                </Typography>
                                <Typography className={clsx("roboto-bold")} variant="h3" align="center">
                                    {item.position}
                                </Typography>
                                <Typography className={clsx("roboto-light")} variant="h5" align="center">
                                    {item.desc}
                                </Typography>
                                <div className={classes.cartButtonCenter}>
                                    <Button
                                    variant="outlined"
                                    href={"mailto:info@purposer.com?subject=Application for " + item.position} 
                                    className={classes.cartButton}
                                    >
                                    <Typography className={classes.cartButtonText}>
                                        <b>Apply By Sending Your <br/> Resume To Info@purposer.com</b>
                                    </Typography>
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    )
                })}
            </Grid>
            <div style={{ height: "var(--dividerHeight)" }} />
            <div style={{ height: "var(--dividerHeight)" }} />
            <Typography className={clsx("roboto-bold")} variant="h2" align="center">
                MEET THE TEAM
            </Typography>
            <div style={{ height: "var(--dividerHeight)" }} />
            <Container maxWidth="lg">
            <Grid container spacing={4} justifyContent="center" >
                {teamMembers2022.map((item, index) => {
                    return (
                        <Grid item xs={6} sm={6} md={4} lg={3} justifyContent="center">
                            <img
                            src={item.picture}
                            alt={item.name}
                            className={classes.profileImage}
                            />
                            <div style={{ height: "16px" }} />
                            <Typography className={clsx("roboto-bold")} variant="h5" align="center">{item.name}</Typography>
                            <Typography className={clsx("roboto-light")} variant="h5" align="center">{item.position}</Typography>

                        </Grid>         
                    )
                })}
            </Grid>
            </Container>
            <div style={{ height: "var(--dividerHeight)" }} />
        </Container>
        <div style={{ height: "var(--dividerHeight)" }} />

        {/* <div style={{backgroundColor: "var(--mintGreen)", height:"80px", marginBottom: "32px"}}/> */}
        <Container maxWidth="xl">
            
            <Grid container spacing={12} justifyContent="center" alignItems="center">
                {testimonies2022.map((item, index) => {
                    return (
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <div style={{backgroundColor: "rgb(130,175,154, 0.23)", padding: "48px 32px", borderRadius: "32px", minHeight: "620px"}}>
                            <div className={classes.cartButtonCenter}>
                                <img
                                src={item.picture}
                                alt={item.name}
                                className={classes.testimonyImage}
                                />
                            </div>
                            <div style={{ height: "16px" }} />
                            <Typography className={clsx("roboto-bold")} variant="h5" align="center">{item.name}</Typography>
                            <div style={{ height: "16px" }} />
                            <Typography className={clsx("roboto-light")} variant="h5" align="center">“{item.quote}”</Typography>
                            </div>
                        </Grid>         
                    )
                })}
            </Grid>
        </Container>
        {/* <div style={{backgroundColor: "var(--mintGreen)", height:"80px", marginTop: "32px"}}/> */}
        <div style={{ height: "var(--dividerHeight)" }} />

        <Container maxWidth="xl">
            <div style={{ height: "var(--dividerHeight)" }} />
            <Typography className={clsx("roboto-bold")} variant="h2" align="center">
                Keep In Touch
            </Typography>
            <Typography className={clsx("roboto-light")} variant="h4" align="center">
                Follow us for future opportunities!
            </Typography>
            <div style={{ height: "16px" }} />
            <Grid container direction="row" justifyContent="center" rowSpacing={2}>
                {footerJsonData.socialMediaButtons &&
                  footerJsonData.socialMediaButtons.length > 0 &&
                  footerJsonData.socialMediaButtons.map((button, index) => {
                    return (
                      <SocialMediaButton
                        key={index}
                        link={button.link}
                        icon={button.icon}
                        classes={classes}
                      />
                    );
                  })}
             </Grid>
        </Container>
        <div style={{ height: "var(--dividerHeight)" }} />
        <div style={{ height: "var(--dividerHeight)" }} />

      </PurposeCategoryMasterLayout>
    );
  };
  
  export default CareersLayout;
  