import { Typography, Container, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AccountDetailView from "../../components/AccountDetailView";
import KeepUp from "../../components/KeepUp";
import React from "react";
import { getListOfProductsById } from "../../util/getProductsById";

//JSON filler ----------------
import mockBlogData from "../../json/blog_posts.json";
import mockData from "../../json/mockAPIdata.json";
import trackShipData from "../../json/shipping_data.json";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { PurposeCategoryMasterLayout, MasterLayout} from "../../layouts";
import { useSelector } from "react-redux";
//---------------------------------

const useStyles = makeStyles({
  title: {
    color: "var(--mintGreen)",
    fontFamily: "Times New Roman",
    textAlign: "center",
    margin: [[40, 0]],
  },
  divMarginBottom: { height: "var(--dividerHeight)" },
});

const MyAccountLayout = () => {
  const { given_name: userGivenName, username } = useSelector(
    (state) => state.user.user
  );
  const classes = useStyles();
  useDocumentTitle(userGivenName + "'s Account");

  const [favorites, setFavorites] = React.useState([]);
  const [favoritesSearched, setFavoritesSearched] = React.useState([]);
  React.useEffect(() => {
    getListOfProductsById(mockData, (data) => {
      if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
        setFavorites([data, data]);
        setFavoritesSearched(data);
      }
    });
  }, []);
  return (
    <MasterLayout>
    <Container maxWidth="xl">
    <Divider />
      <Container maxWidth="xl">
        <Typography variant="h1" className={classes.title}>
        </Typography>
      </Container>
      <AccountDetailView
        userGivenName={userGivenName}
        favoritesData={{
          favorites: favorites,
          favoritesSearched: favoritesSearched,
        }}
        trackShipData={trackShipData}
        username={username}
      />
      <div className={classes.divMarginBottom} />
      {/* <KeepUp blogPosts={mockBlogData} /> */}
      <div className={classes.divMarginBottom} />
    </Container>
  </MasterLayout>


  );
};

export default MyAccountLayout;
