import { Container, Grid, Typography } from "@mui/material/";
import { styled } from "@mui/material/styles";
import React from "react";
import {
  getPurposeCategoryById,
} from "../util/purposeCategories";

const BackgroundDiv = styled("div")({
  backgroundImage:
    "url(https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Explore+shops/leaves+background-01.png)",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  paddingTop: 40,
  paddingBottom: 70,
});

const HeaderDiv = styled("div")({
  marginBottom: 40,
});

const StyledGridItem = styled(Grid)({
  display: "flex",
  flexDirection: "column",
});

const StyledATag = styled("a")({
  height: "100%",
  display: "inherit",
  flexDirection: "inherit",
  "&:hover": {
    textDecorationColor: "#FFFFFF",
  },
});

const StyledImg = styled("img")({
  maxWidth: "80%",
  borderRadius: 35,
  margin: "auto",
});

const HeaderContainer = styled("div")({
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: 10,
  paddingRight: 10,
});

const HeaderText = styled(Typography)({
  color: "black",
  paddingTop: 6,
  paddingBottom: 15,
});

function ExploreShops(props) {
  const { data } = props;
  const [exploreShops, setExploreShops] = React.useState({});
  const DEFAULT_PURPOSE_CATEGORY_ID = 1;

  React.useEffect(() => {
    if (!data || Object.keys(data).length === 0) {
      getPurposeCategoryById(
        DEFAULT_PURPOSE_CATEGORY_ID,
        (retrievedData) => {
          if (
            !retrievedData.hasOwnProperty("error") &&
            !retrievedData.hasOwnProperty("message")
          ) {
            setExploreShops(retrievedData);
          }
        },
        true
      );
    } else {
      setExploreShops(data);
    }
  }, [data]);

  return (
    <BackgroundDiv>
      <Container maxWidth="xl">
        <HeaderDiv>
          {/* <Typography variant="h4" className={"roboto-medium"} display="inline">
            
            Explore Shops that support similar{" "}
            {exploreShops.hasOwnProperty("name")
              ? exploreShops.name.toLowerCase()
              : ""}{" "}
          </Typography>
          <Typography
            variant="h4"
            className={"roboto-medium-italic"}
            display="inline"
          >
            PURPOSES.
          </Typography> */}
        </HeaderDiv>
        <Grid container direction="row" rowSpacing={7}>
          {exploreShops.hasOwnProperty("subpurposes") &&
            exploreShops.subpurposes.length > 0 &&
            exploreShops.subpurposes.map((purpose, index) => {
              return (
                <StyledGridItem item xs={6} sm={4} md={3} key={index}>
                  <StyledATag href={"/shop/purpose/" + purpose.id + "/" + purpose.name.replace(/\s/g, '-')}>
                    <StyledImg src={purpose.img} alt="" />
                    <HeaderContainer>
                      <HeaderText variant="h6" className={"roboto-regular"}>
                        {purpose.name}
                      </HeaderText>
                    </HeaderContainer>
                  </StyledATag>
                </StyledGridItem>
              );
            })}
        </Grid>
      </Container>
    </BackgroundDiv>
  );
}

export default ExploreShops;
