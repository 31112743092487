import { Grid, Avatar, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import React from "react";

//Default Prop Values
import mockDataDefault from "../json/category_view.json";
import { getListOfProductCategoriesById } from "../util/productCategories";

const useStyles = makeStyles({
  imageAvatar: {
    width: "100%",
    height: "100%",
  },
  container: {
    width: "65%",
    margin: "auto",
    marginTop: 20,
  },
  text: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "1.2rem",
    marginTop: 10,
  },
});

const Bubble = ({ classes, item, parentCategoryId }) => {
  const link = "/shop/category/" + parentCategoryId + "/" + item.id;

  return (
    <Grid item xs={4}>
      <a href={link}>
        <div className={classes.container}>
          <Avatar
            className={classes.imageAvatar}
            src={item.img}
            alt={item.name}
          />
        </div>
      </a>
      <a href={link}>
        <Typography className={classes.text}>{item.name}</Typography>
      </a>
    </Grid>
  );
};

const CategoryView = ({ data, parentCategoryId = -1 }) => {
  const classes = useStyles();
  const [categoryViewData, setCategoryViewData] = React.useState(data);

  React.useEffect(() => {
    if (!data || data.length === 0) {
      getListOfProductCategoriesById(mockDataDefault, (data) => {
        if (!data.hasOwnProperty("error")) {
          setCategoryViewData(data);
        }
      });
    } else {
      setCategoryViewData(data);
    }
  }, [data]);

  return (
    <Grid container>
      <Grid container direction="row" item>
        {categoryViewData &&
          categoryViewData.slice(0, 3).map((item, i) => {
            return (
              <Bubble
                key={i}
                classes={classes}
                item={item}
                parentCategoryId={parentCategoryId}
              ></Bubble>
            );
          })}
      </Grid>
      <Grid container direction="row" item>
        {categoryViewData &&
          categoryViewData.slice(3, 6).map((item, i) => {
            return (
              <Bubble
                key={i}
                classes={classes}
                item={item}
                parentCategoryId={parentCategoryId}
              ></Bubble>
            );
          })}
      </Grid>
    </Grid>
  );
};

export default CategoryView;
