import ManagementMasterLayout from "../MasterLayouts/ManagementMasterLayout";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Avatar,
  Stack,
  FormControl,
  Box,
  Container,
  ButtonBase,
  Autocomplete,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  CircularProgress
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useElementWidth from "../../hooks/useElementWidth";
import { useParams } from "react-router-dom";
import sellerDashboardJsonData from "../../json/seller_dashboard.json";
import React from "react";
import SalesCreate from "../../components/SalesCreate";
import PromosCreate from "../../components/PromosCreate";
import SalesView from "../../components/SalesView";
import PromosView from "../../components/PromosView";
import { getCouponsBySellerId } from "../../util/coupons";
import { getListingsBySeller } from "../../util/listingFunctions";
import { COUPON_TYPES } from "../../util/constants";
import { formatDate, outputViewProductVariationSize } from "../../util/utilities";
import toast, { Toaster } from 'react-hot-toast';

const useStyles = makeStyles({
  rightSectionWidth: {
    paddingLeft: 65,
    paddingRight: 65,
  },
  bubbleStatContainer: {
    paddingTop: 40,
    paddingBottom: 35,
  },
  bubbleStatFilter: {
    marginBottom: 30,
    fontSize: "1.25rem",
    "& .MuiInput-underline:after": {
      border: "2px solid #000000",
    },
  },
  bubbleAvatar: {
    width: "75%",
    margin: "0 auto",
    backgroundColor: "#F0F6F4",
    color: "#000000",
  },
});

const SalesTable = (props) => {
  const { id } = useParams();
  const { saleView, setSaleView, createSaleView, setCreateSaleView, setRow, sales, productList } = props;

  return (
    <>
      <Toaster
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h4" sx={{ ml: 0 }} style={{ color: "#85b2a2", margin: "16px 0" }}>
          <b>Sales</b>
        </Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: "var(--coral)" }}
          onClick={() => {
            if (productList.length > 0) {
              setCreateSaleView(true)
            } else {
              toast.error('You must have at least one product (that is not already on sale) to create a sale!', {
                duration: 4000,
                position: 'top-center',
              })
            }
          }
          }
        >
          <b>+ Create a sale</b>
        </Button>
      </div>
      <Grid container >
        {/* <Grid xs={3} item sx={{ border: "gray solid 1px", py: 1 }}>
            <Typography variant="h5" align="center">
              <b>Current Sales</b>
            </Typography>
          </Grid>
          <Grid xs={9} item sx={{ border: "gray solid 1px" }}></Grid> */}
        <Grid
          xs={2}
          item
          sx={{
            borderLeft: "gray solid 1px",
            borderRight: "gray solid 1px",
            borderTop: "gray solid 1px",
            py: 1,
            bgcolor: "var(--lightGray)",
          }}
        >
          <Typography variant="h6" align="center">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Status</b>
            </Box>
          </Typography>
        </Grid>
        <Grid
          xs={3}
          item
          sx={{
            borderRight: "gray solid 1px",
            borderLeft: "gray solid 1px",
            borderTop: "gray solid 1px",
            py: 1,
            bgcolor: "var(--lightGray)",
          }}
        >
          <Typography variant="h6" align="center">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Name</b>
            </Box>
          </Typography>
        </Grid>
        <Grid
          xs={7}
          item
          sx={{ borderTop: "gray solid 1px", borderRight: "gray solid 1px", borderLeft: "gray solid 1px", bgcolor: "var(--lightGray)" }}

        >
          <Grid container >
            <Grid item xs={3} justifyContent="center">
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "8px" }}>
                <Typography variant="h6" style={{ textAlign: 'center' }}>
                  <b>Terms</b>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "8px" }}>
                <Typography variant="h6">
                  <b>Duration</b>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "8px" }}>
                <Typography variant="h6">
                  <b>Uses</b>
                </Typography>
              </Box>
            </Grid>
            {/* <Grid item xs={3}>
              <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "8px"}}>
                <Typography variant="h6">
                  <b>Revenue</b>
                </Typography>
                </Box>
              </Grid> */}
          </Grid>
        </Grid>

        {sales.map((sale, index) => {
          return (
            <>
              <Grid xs={2} item sx={{ border: "gray solid 1px", py: 1 }} key={index + sale.name + "STATUS"}
                onClick={() => { setRow(sale); setSaleView(true) }}
                component={ButtonBase}
              >
                <Stack spacing={3} sx={{ my: 2 }}>
                  <Typography align="center">
                    <b>{sale.active ? `ACTIVE` : `INACTIVE`}</b>
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={3} item sx={{ border: "gray solid 1px", py: 1 }} key={index + sale.name + "NAME"} onClick={() => { setRow(sale); setSaleView(true) }} component={ButtonBase}>
                <Stack spacing={3} sx={{ my: 2 }}>
                  <Typography align="center">
                    <b>{sale.couponCode}</b>
                  </Typography>
                  {/* <Typography variant="caption" align="center">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Manage
                  <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
                </Box>
              </Typography> */}
                </Stack>
              </Grid>
              <Grid item xs={7} sx={{ border: "gray solid 1px" }} justifyContent="center" alignItems="center" onClick={() => { setRow(sale); setSaleView(true) }} component={ButtonBase}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={3}>
                    <Stack spacing={3} sx={{ my: 3 }}>
                      <Typography align="center">
                        {sale.amountOff && !isNaN(sale.amountOff) && Number(sale.amountOff) > 0 ? `$${sale.amountOff} OFF` : `${sale.percentOff}% OFF`}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={3} sx={{ my: 3 }}>
                      <Typography align="center">
                        {`${formatDate(sale.startDateTime)} - ${formatDate(sale.expirationDateTime) || 'No End Date'}`}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack spacing={3} sx={{ my: 3 }}>
                      <Typography align="center">
                        {sale.redemptions}
                      </Typography>
                    </Stack>
                  </Grid>
                  {/* <Grid item xs={3}>
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "8px"}}>
              <Typography variant="h6">
                <b>{sale.revenue}</b>
              </Typography>
              </Box>
            </Grid> */}
                </Grid>
              </Grid>
            </>
          )
        })}


      </Grid>
    </>
  )
}

const PromoTable = (props) => {
  const { promoView, setPromoView, setRow, createPromoView, setCreatePromoView, promos, productList } = props;
  return (
    <>
      <Toaster
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h4" sx={{ ml: 0 }} style={{ color: "#85b2a2", margin: "16px 0" }}>
          <b>Promo Codes</b>
        </Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: "var(--coral)" }}
          onClick={() => {
            if (productList.length > 0) {
              setCreatePromoView(true)
            } else {
              toast.error('You must have at least one product to create a promo code!', {
                duration: 4000,
                position: 'top-center',
              })
            }
          }}
        >
          <b>+ Create a promo code</b>
        </Button>


      </div>
      <Grid container >
        {/* <Grid xs={3} item sx={{ border: "gray solid 1px", py: 1 }}>
            <Typography variant="h5" align="center">
              <b>Current Sales</b>
            </Typography>
          </Grid>
          <Grid xs={9} item sx={{ border: "gray solid 1px" }}></Grid> */}
        <Grid
          xs={2}
          item
          sx={{
            borderLeft: "gray solid 1px",
            borderRight: "gray solid 1px",
            borderTop: "gray solid 1px",
            py: 1,
            bgcolor: "var(--lightGray)",
          }}
        >
          <Typography variant="h6" align="center">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Status</b>
            </Box>
          </Typography>
        </Grid>
        <Grid
          xs={3}
          item
          sx={{
            borderRight: "gray solid 1px",
            borderLeft: "gray solid 1px",
            borderTop: "gray solid 1px",
            py: 1,
            bgcolor: "var(--lightGray)",
          }}
        >
          <Typography variant="h6" align="center">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Name</b>
            </Box>
          </Typography>
        </Grid>
        <Grid
          xs={7}
          item
          sx={{ borderTop: "gray solid 1px", borderRight: "gray solid 1px", borderLeft: "gray solid 1px", bgcolor: "var(--lightGray)" }}

        >
          <Grid container >
            <Grid item xs={3} justifyContent="center">
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "8px" }}>
                <Typography variant="h6" style={{ textAlign: 'center' }}>
                  <b>Terms</b>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "8px" }}>
                <Typography variant="h6">
                  <b>Duration</b>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "8px" }}>
                <Typography variant="h6">
                  <b>Uses</b>
                </Typography>
              </Box>
            </Grid>
            {/* <Grid item xs={3}>
              <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "8px"}}>
                <Typography variant="h6">
                  <b>Revenue</b>
                </Typography>
                </Box>
              </Grid> */}
          </Grid>
        </Grid>

        {promos.map((promo, index) => {
          return (
            <>
              <Grid xs={2} item sx={{ border: "gray solid 1px", py: 1 }} key={index + promo.code + "STATUS"}
                onClick={() => { setRow(promo); setPromoView(true) }}
                component={ButtonBase}
              >
                <Stack spacing={3} sx={{ my: 2 }}>
                  <Typography align="center">
                    <b>{promo.active ? `ACTIVE` : `INACTIVE`}</b>
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={3} item sx={{ border: "gray solid 1px", py: 1 }} key={index + promo.code + "CODE"} onClick={() => { setRow(promo); setPromoView(true) }} component={ButtonBase}>
                <Stack spacing={3} sx={{ my: 2 }}>
                  <Typography align="center">
                    <b>{promo.couponCode}</b>
                  </Typography>
                  {/* <Typography variant="caption" align="center">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Manage
                  <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
                </Box>
              </Typography> */}
                </Stack>
              </Grid>
              <Grid item xs={7} sx={{ border: "gray solid 1px" }} justifyContent="center" alignItems="center" onClick={() => { setRow(promo); setPromoView(true) }} component={ButtonBase}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={3}>
                    <Stack spacing={3} sx={{ my: 3 }}>
                      <Typography align="center">
                        {promo.amountOff && !isNaN(promo.amountOff) && Number(promo.amountOff) > 0 ? `$${promo.amountOff} OFF` : `${promo.percentOff}% OFF`}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={3} sx={{ my: 3 }}>
                      <Typography align="center">
                        {`${formatDate(promo.startDateTime)} - ${formatDate(promo.expirationDateTime) || 'No End Date'}`}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack spacing={3} sx={{ my: 3 }}>
                      <Typography align="center">
                        {promo.redemptions}
                      </Typography>
                    </Stack>
                  </Grid>
                  {/* <Grid item xs={3}>
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "8px"}}>
              <Typography variant="h6">
                <b>{sale.revenue}</b>
              </Typography>
              </Box>
            </Grid> */}
                </Grid>
              </Grid>
            </>
          )
        })}


      </Grid>
    </>
  )
}

function SalesAndCouponsLayout(props) {
  const classes = useStyles();
  const sellerDashboardData = sellerDashboardJsonData;
  const bubbleRef = React.useRef(null);
  const [bubbleStatFilter, setBubbleStatFiler] = React.useState("This Month");
  const bubbleWidth = useElementWidth(bubbleRef);
  const { id } = useParams();
  const [row, setRow] = React.useState({});
  const [saleView, setSaleView] = React.useState(false);
  const [promoView, setPromoView] = React.useState(false);
  const [createSaleView, setCreateSaleView] = React.useState(false);
  const [createPromoView, setCreatePromoView] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    percentage: "",
    discount: "",
  });
  const [productList, setProductList] = React.useState([]);

  const handleFormValues = (name, value) => {
    setFormValues((preValues) => {
      return { ...preValues, [name]: value };
    });
  };

  const handleBubbleStatFilterChange = (event) => {
    setBubbleStatFiler(event.target.value);
  };

  const [promos, setPromos] = React.useState([]);
  const [sales, setSales] = React.useState([]);
  const [loadingProductList, setLoadingProductList] = React.useState(true);
  const [loadingCoupons, setLoadingCoupons] = React.useState(true);

  React.useEffect(() => {
    getCouponsBySellerId(id, (data) => {
      data.sort((a, b) => { return new Date(b.startDateTime) - new Date(a.startDateTime) });
      setPromos(data.filter(c => c.couponType == COUPON_TYPES.PROMO_CODE));
      setSales(data.filter(c => c.couponType == COUPON_TYPES.SALE));
      setLoadingCoupons(false);
    });
  }, [id]);

  React.useEffect(() => {
    getListingsBySeller(id, true, (data) => {
      const itemArray = [];
      for (const product of data) {
        for (const option of product.options) {
          const currOption = {
            skuID: option.skuID,
            productID: product.productID,
            name: `${product.productName} - ${option.color} - ${outputViewProductVariationSize(option.size)}`,
            saleApplied: option.couponCode ? true : false,
          }
          if (currOption.saleApplied) {
            console.log(`${currOption.name} skuID ${currOption.skuID} already has a sale on it!`);
          }
          itemArray.push(currOption);
        }
      }
      setProductList(itemArray);
      setLoadingProductList(false);
    });
  }, [id]);

  return (
    <ManagementMasterLayout
      title={"Sales & Coupons"}
      sellerName={sellerDashboardData.name}
      sellerPicture={sellerDashboardData.pic}
    >
      {/* <div
        className={clsx(classes.bubbleStatContainer, classes.rightSectionWidth)}
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h6">
            <b>Overall Performance</b>
          </Typography>
          <FormControl size="small">
            <Select
              value={bubbleStatFilter}
              onChange={handleBubbleStatFilterChange}
              className={classes.bubbleStatFilter}
            >
              <MenuItem value={"This Month"}>
                <b>This Month</b>
              </MenuItem>
              <MenuItem value={"Last Month"}>
                <b>Last Month</b>
              </MenuItem>
              <MenuItem value={"Last Year"}>
                <b>Last Year</b>
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Grid container direction="row">
          {bubbleStats &&
            bubbleStats.map((bubbleStat, index) => {
              return (
                <Grid
                  item
                  xs={4}
                  key={index}
                  align={
                    index === 0 ? "left" : index === 1 ? "center" : "right"
                  }
                >
                  <Bubble
                    statBubbleData={bubbleStat}
                    avatarRef={bubbleRef}
                    avatarWidth={bubbleWidth}
                    classes={classes}
                  />
                </Grid>
              );
            })}
        </Grid>
      </div> */}
      <>
        {loadingProductList || loadingCoupons ?
          <Grid
            sx={{
              mt: 3,
              mb: 3,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
          </Grid>
          :
          <>
            <SalesCreate productList={productList.filter(p => p.saleApplied == false)} createSaleView={createSaleView} setCreateSaleView={setCreateSaleView} />
            <PromosCreate productList={productList} createPromoView={createPromoView} setCreatePromoView={setCreatePromoView} />
            <SalesView productList={productList} saleView={saleView} setSaleView={setSaleView} sale={row} />
            <PromosView productList={productList} promoView={promoView} setPromoView={setPromoView} promo={row} />
            <Container sx={{ my: 3, mx: 1 }} >
              <SalesTable productList={productList.filter(p => p.saleApplied == false)} sales={sales} saleView={saleView} setSaleView={setSaleView} createSaleView={createSaleView} setCreateSaleView={setCreateSaleView} setRow={setRow} />
              {/* <SaleForm /> */}
              <div style={{ height: "32px" }} />
              <PromoTable productList={productList} promos={promos} promoView={promoView} setPromoView={setPromoView} setRow={setRow} createPromoView={createPromoView} setCreatePromoView={setCreatePromoView} />
              {/* <CouponForm /> */}
            </Container>
          </>
        }
      </>
    </ManagementMasterLayout>
  );
}

export default SalesAndCouponsLayout;
