import {
  Grid,
  TextField,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  inputOutline: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 5,
      "&:hover fieldset": {
        borderColor: "var(--mintGreen)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--mintGreen)",
      },
    },
  }
});

const AddressInput = (props) => {
  const { 
    formValues, 
    handleFormValues,
    addressLine1,
    addressLine2,
    addressCity,
    addressCityAlternate,
    addressCountry,
    addressState,
    addressPostalCode,
   } = props;

  const classes = useStyles();
  return (
    <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
      <Grid item xs={12}>
        <TextField
          label={"Address"}
          name="address"
          onInput={(e) => {handleFormValues(addressLine1, e.target.value);}} 
          variant="outlined"
          size="small"
          fullWidth
          value={formValues[addressLine1]}
          className={classes.inputOutline}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={"Apt, Suite, etc."}
          name="apartment"
          onInput={(e) => {handleFormValues(addressLine2, e.target.value);}} 
          variant="outlined"
          size="small"
          fullWidth
          value={formValues[addressLine2]}
          className={classes.inputOutline}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={"City"}
          name="city"
          onInput={(e) => {
            handleFormValues(addressCity, e.target.value);
            if (addressCityAlternate) {
              handleFormValues(addressCityAlternate, e.target.value);
            }
          }} 
          variant="outlined"
          size="small"
          fullWidth
          value={formValues[addressCity]}
          className={classes.inputOutline}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <TextField
          label={"Country"}
          disabled={true}
          name="country"
          onInput={(e) => {handleFormValues(addressCountry, e.target.value);}} 
          variant="outlined"
          size="small"
          fullWidth
          // defaultValue={formValues[addressCountry]}
          value={'United States'}
          className={classes.inputOutline}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <TextField
          label={"State (2 letters)"}
          name="state"
          onInput={(e) => {handleFormValues(addressState, e.target.value);}} 
          variant="outlined"
          size="small"
          fullWidth
          value={formValues[addressState]}
          className={classes.inputOutline}         
        />
      </Grid>
      <Grid item xs={8} sm={4}>
        <TextField
          label={"ZIP Code"}
          name="postcode"
          onInput={(e) => {handleFormValues(addressPostalCode, e.target.value);}} 
          variant="outlined"
          size="small"
          fullWidth
          value={formValues[addressPostalCode]}
          className={classes.inputOutline}
        />
      </Grid>
    </Grid>
  );
};

export default AddressInput;
