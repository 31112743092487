
import { getSessionJwt } from "./utilities";
const {
  NODE_API_ENDPOINT
} = require("./constants");

async function isProductBestSeller(productID, callback) {
  const jwtToken = await getSessionJwt();

  fetch(NODE_API_ENDPOINT + "/is-product-best-seller?productID=" + productID, {
    method: "GET",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    }
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function addBestSeller(productID, callback) {
  const jwtToken = await getSessionJwt();

  const reqBody = {
    productID: productID
  }
  fetch(NODE_API_ENDPOINT + "/add-to-best-sellers", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return null;
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}


async function removeBestSeller(productID, callback) {
  const jwtToken = await getSessionJwt();

  const reqBody = {
    productID: productID
  }
  fetch(NODE_API_ENDPOINT + "/remove-from-best-sellers", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return null;
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

export {
  isProductBestSeller,
  addBestSeller,
  removeBestSeller,
}