import { Avatar, Container, Grid, Typography } from "@mui/material";
import React from "react";
import weThinkYoullLoveTheseData from "../json/we_think_youll_love_these.json";
import { styled } from "@mui/material/styles";

const ImageAvatar = styled(Avatar)({
  width: "100%",
  height: "100%",
});

const Header = styled(Typography)({
  textAlign: "center",
  marginTop: 10,
});

const DivContainer = styled("div")({
  width: "85%",
  margin: "auto",
  marginTop: 20,
});

const GridItem = styled(Grid)({
  marginRight: 20,
});

const Bubble = ({ item }) => (
  <Grid item xs={4} sm={4} md={2}>
    <a href={item.link}>
      <DivContainer>
        <ImageAvatar src={item.img} alt={item.header} />
      </DivContainer>
      <Header variant="subtitle1" className={"roboto-medium"}>
        {item.header}
      </Header>
    </a>
  </Grid>
);

function WeThinkYoullLoveThese(props) {
  const { data = weThinkYoullLoveTheseData } = props;

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" className={"roboto-medium"}>
        We think you'll love these
      </Typography>
      <Grid container direction="row">
        {data.map((item, index) => {
          return (
            // <GridItem item key={index} >
              <Bubble item={item} key={index}/>
            // </GridItem>
          );
        })}
      </Grid>
    </Container>
  );
}

export default WeThinkYoullLoveThese;
