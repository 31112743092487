import React from "react";
import { ManagementMasterLayout } from "../../layouts";
import sellerDashboardJsonData from "../../json/seller_dashboard.json";
import {
  Box,
  ButtonBase,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import search_logo from "../../img/bulb4.png";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

const SearchLogo = styled("img")({
  paddingTop: 5,
  height: 25,
});

const StyledTextField = styled(TextField)({
  width: "100%",
  [`& .${outlinedInputClasses.root}`]: {
    height: 35,
  },
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderStyle: "none",
    backgroundColor: "var(--pastelGreen)",
    borderRadius: 30,
  },
});

const OldMessagesLayout = () => {
  const sellerDashboardData = sellerDashboardJsonData;

  return (
    <ManagementMasterLayout
      title={"Messages"}
      sellerName={sellerDashboardData.name}
      sellerPicture={sellerDashboardData.pic}
    >
      <Grid
        container
        sx={{
          bgcolor: "var(--beige)",
          mx: 2,
          my: 2,
          border: "lightGray solid 1px",
        }}
      >
        <Grid item xs={2} sx={{ border: "1px solid lightGray" }}>
          <Stack sx={{ alignItems: "center", my: 3 }} spacing={5}>
            <Box>
              <ButtonBase
                sx={{
                  py: 0.5,
                  px: 0.7,
                  border: "1px solid gray",
                  borderRadius: 2,
                }}
              >
                <Typography>Compose</Typography>
              </ButtonBase>
            </Box>
            <Box>
              <Stack spacing={2.5}>
                <ButtonBase>
                  <Typography>
                    <b>Inbox</b>
                  </Typography>
                </ButtonBase>
                <ButtonBase>
                  <Typography>
                    <b>Sent</b>
                  </Typography>
                </ButtonBase>
                <ButtonBase>
                  <Typography>
                    <b>All</b>
                  </Typography>
                </ButtonBase>
                <ButtonBase>
                  <Typography>
                    <b>Unread</b>
                  </Typography>
                </ButtonBase>
                <ButtonBase>
                  <Typography>
                    <b>Trash</b>
                  </Typography>
                </ButtonBase>
              </Stack>
            </Box>
            <Box>
              <ButtonBase
                sx={{ border: "1px solid black", borderRadius: 2, p: 1 }}
              >
                <Stack sx={{ alignItems: "center" }} spacing={2}>
                  <Typography>
                    <b>Live chat</b>
                  </Typography>
                  <img
                    src={
                      "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/reviews/Contact+Us+Icon+Mint+Green.png"
                    }
                    alt="Contact Seller"
                    style={{ maxWidth: 50 }}
                  />
                  <Typography>
                    <i>{2} unread</i>
                  </Typography>
                </Stack>
              </ButtonBase>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={10}>
          <Box sx={{ p: 1.5, border: "1px solid lightGray" }}>
            <StyledTextField
              variant="outlined"
              placeholder="Search all conversations"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Link to="https://www.purposer.com">
                      <SearchLogo src={search_logo} alt="" />
                    </Link>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ m: 1.5, bgcolor: "white", height: "84%" }}>
            <Grid container>
              <Grid item xs={5}>
                <Typography sx={{ py: 2, pl: 1.7 }}>
                  <b>Customer Name</b>
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography sx={{ py: 2 }}>
                  <b>Subject Line</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {tempData.map((item) => {
                return (
                  <>
                    <Grid item xs={5}>
                      <Typography sx={{ py: 1, pl: 1.7 }}>
                        {item.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography sx={{ py: 1 }}>{item.subject}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ManagementMasterLayout>
  );
};

const tempData = [
  { name: "Sarah Smith", subject: "Do you have another color?" },
  { name: "Carole Cass", subject: "What's your purpose?" },
  { name: "Greg Paul", subject: "My Product" },
  { name: "Kassy Linder", subject: "Fastest shipping option" },
  { name: "Zach Ortiz", subject: "Fastest shipping option" },
  { name: "Alexis Stamos", subject: "Mental Health initiative" },
  { name: "Jake Hill", subject: "Environment purpose" },
];

const MessagesLayout = () => {
  const sellerDashboardData = sellerDashboardJsonData;

  return (
    <ManagementMasterLayout
      title={"Messages"}
      sellerName={sellerDashboardData.name}
      sellerPicture={sellerDashboardData.pic}
    >
      <div style={{padding: "36px"}}>
        <Typography variant="h3" style={{textAlign: "center", color: "var(--coral)", fontWeight: "bold"}}>Messaging between Shop Owners and Buyers is COMING SOON!</Typography>
        <Typography variant="h6" style={{fontWeight: "400", textAlign: "center"}}>For now, please email your customers directly from your business email. Also, your business email, if you provided one, will be made available to buyers so they know how to contact you. Please let us know what questions you may have or how we can support you. We&#39;re here for you!</Typography>
      </div>
    </ManagementMasterLayout>
  )
}

export default MessagesLayout;
