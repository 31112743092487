import {
  Typography,
  Container,
  TextField,
  outlinedInputClasses,
  Dialog,
  dialogContentClasses,
  dialogActionsClasses,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Modal,
  Box,
  CircularProgress,
} from "@mui/material";
import ManagementMasterLayout from "../MasterLayouts/ManagementMasterLayout";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import ImageUploader from "../../components/ImageUploader";
import PurposeCarousel from "../../components/PurposeCarousel";
import FeaturedProductsAndSpecials from "../../components/FeaturedProductsAndSpecials";
import ExpandableQuetionsView from "../../components/ExpandableQuestionsView";
import { MapContainer, TileLayer } from "react-leaflet";
import makeStyles from "@mui/styles/makeStyles";
import { Markers, AchievementCarousel } from "../../components/InAction";
// import { createSeller } from "../../util/sellers";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  map: {
    maxWidth: 1024,
    height: 400,
    margin: "auto",
  },
});

const EditSection = styled("section")({
  marginTop: 30,
});

const SectionHeader = styled("header")({
  display: "flex",
  alignItems: "center",
  marginBottom: 10,
});

const BorderContainer = styled("div")({
  borderStyle: "solid",
  borderWidth: 1,
  borderRadius: 7,
});

const ContentContainer = styled(BorderContainer)({
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 10,
  paddingBottom: 10,
});

const StyledTextField = styled(TextField)({
  [`& .${outlinedInputClasses.root}`]: {
    padding: 0,
  },
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderStyle: "none",
  },
});

const EditDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogContentClasses.root}`]: {
    padding: theme.spacing(2),
  },
  [`& .${dialogActionsClasses.root}`]: {
    padding: theme.spacing(1),
  },
}));

const EditTextField = styled(TextField)({
  [`& .${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
    {
      borderColor: "var(--mintGreen)",
    },
});

const EditButton = styled(Button)({
  textTransform: "none",
  color: "#000000",
});

const ButtonGridContainer = styled(Grid)({
  width: "100%",
  marginTop: 50,
});

const ButtonGridItem = styled(Grid)({
  display: "flex",
  justifyContent: "center",
});

const SytledButton = styled(Button)({
  textTransform: "none",
  width: "90%",
  margin: "auto",
});

const OutlinedButton = styled(SytledButton)({
  color: "#000000",
  borderColor: "#000000",
  borderRadius: 10,
  "&:hover": {
    backgroundColor: "inherit",
    borderColor: "#000000",
  },
});

const PublishButton = styled(SytledButton)({
  backgroundColor: "var(--coral)",
  color: "#FFFFFF",
  borderRadius: 10,
  "&:hover": {
    backgroundColor: "var(--coral)",
  },
});

const styles = {
  coverPhotoUploadContainer: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "var(--lightGray)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 350,
    borderRadius: 15,
    backgroundColor: "#f4f2ed",
    cursor: "pointer",
  },
  storeLogoUploadContainer: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "var(--lightGray)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 300,
    height: 300,
    borderRadius: 15,
    backgroundColor: "#f4f2ed",
    cursor: "pointer",
  },
  storeLogoImg: {
    width: "100%",
    height: "inherit",
    objectFit: "cover",
    margin: "auto",
    borderRadius: 15,
  },
};

const FormEditDialog = (props) => {
  const {
    formData,
    updateFormData,
    propertyName,
    dialogOpen,
    setDialogOpen,
    title,
  } = props;
  const [dialogData, setDialogData] = useState(formData);

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { dialogData, setDialogData });
    }
    return child;
  });

  return (
    <EditDialog
      onClose={() => setDialogOpen(false)}
      open={dialogOpen}
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle onClose={() => setDialogOpen(false)}>{title}</DialogTitle>
      <DialogContent>{childrenWithProps}</DialogContent>
      <DialogActions>
        <EditButton autoFocus onClick={() => setDialogOpen(false)}>
          <Typography className={"roboto-medium"}>Close</Typography>
        </EditButton>
        <EditButton
          autoFocus
          onClick={() => {
            updateFormData(propertyName, dialogData);
            setDialogOpen(false);
          }}
        >
          <Typography className={"roboto-medium"}>Save</Typography>
        </EditButton>
      </DialogActions>
    </EditDialog>
  );
};

const StoreFactsEditDialog = (props) => {
  const { dialogData, setDialogData } = props;

  return (
    <>
      {dialogData.map((fact, index) => {
        return (
          <div key={index} style={{ marginBottom: 15 }}>
            <Typography className={"roboto-regular"}>
              Fact {index + 1}:
            </Typography>
            <EditTextField
              fullWidth
              multiline
              defaultValue={fact}
              onChange={(e) => {
                let newValues = dialogData;
                newValues[index] = e.target.value;
                setDialogData(newValues);
              }}
            />
          </div>
        );
      })}
    </>
  );
};

const FAQEditDialog = (props) => {
  const { dialogData, setDialogData } = props;

  return (
    <>
      {dialogData.map((faq, index) => {
        return (
          <div key={index} style={{ marginBottom: 30 }}>
            <Typography className={"roboto-regular"}>Question:</Typography>
            <EditTextField
              fullWidth
              multiline
              defaultValue={faq.question}
              onChange={(e) => {
                let newValues = dialogData;
                newValues[index].question = e.target.value;
                setDialogData(newValues);
              }}
              style={{ marginBottom: 10 }}
            />
            <Typography className={"roboto-regular"}>Answer:</Typography>
            <EditTextField
              fullWidth
              multiline
              defaultValue={faq.answer}
              onChange={(e) => {
                let newValues = dialogData;
                newValues[index].answer = e.target.value;
                setDialogData(newValues);
              }}
            />
          </div>
        );
      })}
    </>
  );
};

function SellerAddShopLayout() {
  const classes = useStyles();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    // Default form values are hardcoded right now
    // In the future we will fetch these using the seller id
    coverPhotos: [],
    storeLogo: "",
    storeName: "",
    storeLocation: "",
    storeBio: "",
    storeFacts: [],
    specials: [],
    inAction: [],
    longBio: "",
    shopPolicies: "",
    footerPhotos: [],
    areasWeSupport: [],
    storeFAQ: [],
    shippingPolicies:[]
  });
  const [storeFactsDialogOpen, setStoreFactsDialogOpen] = useState(false);
  const [faqDialogOpen, setFaqDialogOpen] = useState(false);
  const [creating, setCreating] = useState(false);

  const updateFormData = (name, value) => {
    setFormData((preValues) => {
      return { ...preValues, [name]: value };
    });
  };

  const createShop = () => {
    //setCreating(true);
    const sellerData = {
      seller_name: formData.storeName,
      purpose: formData.storeFacts.length > 0 ? formData.storeFacts[0] : "",
      location: formData.storeLocation,
      bio: formData.storeBio,
      profile_pic: "",
      purposes: formData.storeFacts,
      banner_images: [],
    };
    //If we want to use this page later on we can update it to use our new APIs
    // createSeller(sellerData, (response) => {
    //   setCreating(false);
    //   if (
    //     !response.hasOwnProperty("error") &&
    //     !response.hasOwnProperty("message") &&
    //     response.hasOwnProperty("id")
    //   ) {
    //     window.location.href = "/seller/" + response.id + "/shopInformation";
    //   }
    // });
  };

  return (
    <ManagementMasterLayout>
      <Container sx={{ paddingBottom: 20 }}>
        <EditSection>
          <SectionHeader>
            <Typography
              display="inline"
              variant="h5"
              className={"roboto-medium"}
            >
              Banner Photo
            </Typography>
          </SectionHeader>
          <ImageUploader
            containerStyle={styles.coverPhotoUploadContainer}
            uploadIcon={<AddIcon fontSize="large" />}
            multiple
            formValues={formData}
            propertyName={"coverPhotos"}
            setFormValues={setFormData}
          />
          <Typography variant="h6" className={"roboto-light-italic"}>
            add up to 6 photos or videos
          </Typography>
        </EditSection>
        <EditSection>
          <SectionHeader>
            <Typography
              display="inline"
              variant="h5"
              className={"roboto-medium"}
            >
              Store Logo
            </Typography>
          </SectionHeader>
          <ImageUploader
            containerStyle={styles.storeLogoUploadContainer}
            imgStyle={styles.storeLogoImg}
            uploadIcon={<AddIcon fontSize="large" />}
            formValues={formData}
            propertyName={"storeLogo"}
            setFormValues={setFormData}
          />
        </EditSection>
        <EditSection>
          <SectionHeader>
            <Typography
              display="inline"
              variant="h5"
              className={"roboto-medium"}
            >
              {`Location`}
            </Typography>
          </SectionHeader>
          {/* <ContentContainer>
            <StyledTextField
              id={"storeNameTextField"}
              fullWidth
              defaultValue={formData.storeName}
              InputProps={{
                inputProps: {
                  style: {
                    color: "var(--mintGreenSolid)",
                    fontSize: 48,
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                  className: "roboto-medium",
                },
              }}
              onChange={(e) => updateFormData("storeName", e.target.value)}
            />
          </ContentContainer> */}
        </EditSection>
        <EditSection>
          <SectionHeader>
            <Typography
              display="inline"
              variant="h5"
              className={"roboto-medium"}
            >
              Location
            </Typography>
          </SectionHeader>
          <ContentContainer>
            <StyledTextField
              fullWidth
              defaultValue={formData.storeLocation}
              InputProps={{
                inputProps: {
                  style: { fontSize: 20, paddingTop: 0, paddingBottom: 0 },
                  className: "roboto-regular",
                },
              }}
              onChange={(e) => updateFormData("storeLocation", e.target.value)}
            />
          </ContentContainer>
        </EditSection>
        <EditSection>
          <SectionHeader>
            <Typography
              display="inline"
              variant="h5"
              className={"roboto-medium"}
            >
              Store Bio
            </Typography>
          </SectionHeader>
          <ContentContainer>
            <StyledTextField
              id={"storeBioTextField"}
              fullWidth
              multiline
              defaultValue={formData.storeBio}
              onChange={(e) => updateFormData("storeBio", e.target.value)}
            />
          </ContentContainer>
        </EditSection>
        <EditSection>
          <SectionHeader>
            <Typography
              display="inline"
              variant="h5"
              className={"roboto-medium"}
            >
              Store Facts
            </Typography>
          </SectionHeader>
          <PurposeCarousel purposeCarouselData={formData.storeFacts} />
          <FormEditDialog
            formData={formData.storeFacts}
            updateFormData={updateFormData}
            propertyName={"storeFacts"}
            dialogOpen={storeFactsDialogOpen}
            setDialogOpen={setStoreFactsDialogOpen}
            title={"Edit Store Facts"}
          >
            <StoreFactsEditDialog />
          </FormEditDialog>
        </EditSection>
        <EditSection>
          <SectionHeader>
            <Typography
              display="inline"
              variant="h5"
              className={"roboto-medium"}
            >
              Specials
            </Typography>
          </SectionHeader>
          <ContentContainer>
            <FeaturedProductsAndSpecials
              featuredProductsAndSpecialsData={formData.specials}
            />
          </ContentContainer>
        </EditSection>
        <EditSection>
          <SectionHeader>
            <Typography
              display="inline"
              variant="h5"
              className={"roboto-medium"}
            >
              In Action
            </Typography>
          </SectionHeader>
          <ContentContainer sx={{ textAlign: "center" }}>
            <div>
              <Typography
                display="inline"
                variant="h4"
                className={"roboto-light-italic"}
                sx={{ textDecoration: "underline" }}
              >
                {formData.storeName}
              </Typography>
              <Typography
                display="inline"
                variant="h4"
                className="roboto-medium"
                sx={{ textDecoration: "underline" }}
              >
                {" "}
                in Action!
              </Typography>
            </div>
            <AchievementCarousel achievements={formData.inAction} />
          </ContentContainer>
        </EditSection>
        <EditSection>
          <SectionHeader>
            <Typography
              display="inline"
              variant="h5"
              className={"roboto-medium"}
            >
              Long Bio
            </Typography>
          </SectionHeader>
          <ContentContainer>
            <StyledTextField
              id={"longBioTextField"}
              fullWidth
              multiline
              defaultValue={formData.longBio}
              onChange={(e) => updateFormData("longBio", e.target.value)}
            />
          </ContentContainer>
        </EditSection>
        <EditSection>
          <SectionHeader>
            <Typography
              display="inline"
              variant="h5"
              className={"roboto-medium"}
            >
              Shop Policies
            </Typography>
          </SectionHeader>
          <ContentContainer>
            <StyledTextField
              id={"shopPoliciesTextField"}
              fullWidth
              multiline
              defaultValue={formData.shopPolicies}
              onChange={(e) => updateFormData("shopPolicies", e.target.value)}
            />
          </ContentContainer>
        </EditSection>
        <EditSection>
          <SectionHeader>
            <Typography
              display="inline"
              variant="h5"
              className={"roboto-medium"}
            >
              Footer Photo
            </Typography>
          </SectionHeader>
          <ImageUploader
            containerStyle={styles.coverPhotoUploadContainer}
            uploadIcon={<AddIcon fontSize="large" />}
            multiple
            formValues={formData}
            propertyName={"footerPhotos"}
            setFormValues={setFormData}
          />
          <Typography variant="h6" className={"roboto-light-italic"}>
            add up to 6 photos or videos
          </Typography>
        </EditSection>
        <EditSection>
          <SectionHeader>
            <Typography
              display="inline"
              variant="h5"
              className={"roboto-medium"}
            >
              Areas we support
            </Typography>
          </SectionHeader>
          <BorderContainer>
            <MapContainer
              tap={false}
              center={[10, 0]}
              zoom={2}
              scrollWheelZoom={true}
              maxBounds={[
                [-90, -180],
                [90, 180],
              ]}
              className={classes.map}
              minZoom={2}
            >
              <TileLayer
                attribution='&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.thunderforest.com/mobile-atlas/{z}/{x}/{y}.png?apikey={apikey}"
                ext="png"
                noWrap="true"
                apikey="b4253345883a4819ae18fbf3ca4daef3"
              />
              {formData.areasWeSupport
                ? formData.areasWeSupport.map((item, index) => {
                    return <Markers data={item} key={index} />;
                  })
                : null}
            </MapContainer>
          </BorderContainer>
        </EditSection>
        <EditSection>
          <SectionHeader>
            <Typography
              display="inline"
              variant="h5"
              className={"roboto-medium"}
            >
              Store FAQ
            </Typography>
          </SectionHeader>
          <ContentContainer>
            <Typography
              display="inline"
              variant="h4"
              className={"roboto-light"}
              sx={{ textDecoration: "underline" }}
            >
              {formData.storeName}
            </Typography>
            <Typography
              display="inline"
              variant="h4"
              className="roboto-medium"
              sx={{ textDecoration: "underline" }}
            >
              {" "}
              FAQ
            </Typography>
            <ExpandableQuetionsView questions={formData.storeFAQ} />
          </ContentContainer>
          <FormEditDialog
            formData={formData.storeFAQ}
            updateFormData={updateFormData}
            propertyName={"storeFAQ"}
            dialogOpen={faqDialogOpen}
            setDialogOpen={setFaqDialogOpen}
            title={"Edit Store FAQ"}
          >
            <FAQEditDialog />
          </FormEditDialog>
        </EditSection>
        <ButtonGridContainer container columns={3}>
          <ButtonGridItem item xs={1}>
            <OutlinedButton variant="outlined">
              <Typography variant="h6" className={"roboto-medium"}>
                Save Draft
              </Typography>
            </OutlinedButton>
          </ButtonGridItem>
          <ButtonGridItem item xs={1}>
            <OutlinedButton variant="outlined">
              <Typography variant="h6" className={"roboto-medium"}>
                Preview Page
              </Typography>
            </OutlinedButton>
          </ButtonGridItem>
          <ButtonGridItem item xs={1}>
            <PublishButton onClick={createShop}>
              <Typography variant="h6" className={"roboto-medium"}>
                Create Shop
              </Typography>
            </PublishButton>
          </ButtonGridItem>
        </ButtonGridContainer>
        <Modal open={creating} sx={{ overflow: "scroll" }}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "max(75%, 300px)",
              bgcolor: "background.paper",
              border: "1px solid #000",
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h3" sx={{ mb: 5 }}>
              Shop is being created....
            </Typography>
            <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
            <Typography variant="h4" sx={{ mt: 5 }}>
              Chill out for a sec....
            </Typography>
            <Typography variant="h4" align="center">
              {"You'll be redirected soon if nothing goes wrong :)"}
            </Typography>
          </Box>
        </Modal>
      </Container>
    </ManagementMasterLayout>
  );
}

export default SellerAddShopLayout;
