import React, { useState } from "react";
import { Avatar, ButtonBase, Container, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";
import clsx from "clsx";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//Default Prop Values
import featuredProductsDefault from "../json/loyalty_program_redeem_products_data.json";

const useStyles = makeStyles({
  rootBackground: {
    backgroundColor: "white",
    padding: "40px 0",
  },
  imagePortrait: {
    height: 360,
    width: "auto",
    marginBottom: 15,
    margin: "auto",
  },
  gutterBottom: { marginBottom: 30 },
  navButtons: {
    position: "absolute",
    top: "39%",
    height: 75,
    border: "1px solid var(--mintArrow)",
    borderRadius: 15,
    backgroundColor: "#FFFFFF",
  },
  prevButton: {
    left: -33,
  },
  nextButton: {
    right: -33,
  },
  divContainer: {
    position: "relative",
    width: "90%",
    margin: "auto",
  },
  arrows: {
    color: "var(--mintArrow)",
    fontSize: 30,
  },
  carouselContainer: {
    width: "100%",
  },
  checkoutButton: {
    textTransform: "none",
    marginTop: 40,
    borderRadius: 10,
    // minWidth: 170,
    // minHeight: 55,
    backgroundColor: "var(--coral)",
    borderColor: "#000000",
    "&:hover": {
      borderColor: "#000000",
      backgroundColor: "inherit",
    },
  },
  checkoutButtonText: {
    // fontSize: "1.35rem",
    color: "white",
  },
  cartButtonCenter: {
    display: "flex",
    justifyContent: "center",
  },  
});

const responsive = {
  break1: {
    breakpoint: { max: 4000, min: 800 },
    items: 3,
  },
  break2: {
    breakpoint: { max: 800, min: 520 },
    items: 2,
  },
  break3: {
    breakpoint: { max: 520, min: 0 },
    items: 1,
  },
};

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  const classes = useStyles();
  return (
    <>
      <ButtonBase
        className={clsx(classes.navButtons, classes.prevButton)}
        onClick={previous}
      >
        <ArrowBack className={classes.arrows} />
      </ButtonBase>
      <ButtonBase
        className={clsx(classes.navButtons, classes.nextButton)}
        onClick={next}
      >
        <ArrowForward className={classes.arrows} />
      </ButtonBase>
    </>
  );
};

const ProductPortrait = ({ classes, item, user }) => (
  <>
    <Avatar
      src={item.image_url}
      alt={item.product}
      className={classes.imagePortrait}
      variant="square"
    />
    <Typography align="center" variant="h5">
      <b>{item.product}</b>
    </Typography>
    <Typography align="center" variant="body1" component="p">
      <a href={item.sellerURL}>Shop: {item.seller}</a>
    </Typography>
    <Typography align="center" variant="body1" component="p">
      <i>
        <a href={item.purposeCategoryURL}>Purpose: {item.purpose}</a>
      </i>
    </Typography>
    {user >= item.points ? 
        <Typography align="center" variant="h6" component="p" style={{color: "var(--coral)"}}>
            <b><span style={{display: "inline-block", paddingLeft: "16px", paddingRight: "16px",  borderBottom: "2px solid var(--coral)"}}>{user}</span> out of {item.points} Points</b>
        </Typography>
    : 
        <Typography align="center" variant="h6" component="p" style={{color: "#83b2ad"}}>
            <b><span style={{display: "inline-block", paddingLeft: "16px", paddingRight: "16px",  borderBottom: "2px solid #83b2ad"}}>{user}</span> out of {item.points} Points</b>
        </Typography>}
    
    <div className={classes.cartButtonCenter}>
        <Button
        variant="outlined"
        href="/cart"
        disableRipple
        className={classes.checkoutButton}
        >
        <Typography className={classes.checkoutButtonText}>
            <b>Add to Cart</b>
        </Typography>
        </Button>
    </div>   
  </>
);

const MyRewardsRedeemProducts = ({ featuredProducts = featuredProductsDefault }) => {
  const classes = useStyles();
  const [userPoints, setUserPoints] = useState(250);


  return (
    <div className={classes.rootBackground}>
      <Container maxWidth="xl">
        <div className={classes.divContainer}>
          {featuredProducts && featuredProducts.length > 0 && (
            <Carousel
              responsive={responsive}
              infinite={false}
              showDots={false}
              arrows={false}
              renderButtonGroupOutside
              customButtonGroup={<CustomButtonGroupAsArrows />}
              containerClass={classes.carouselContainer}
            >
              {featuredProducts.map((item, i) => {
                return <ProductPortrait key={i} classes={classes} item={item} user={userPoints}/>;
              })}
            </Carousel>
          )}
        </div>
      </Container>
    </div>
  );
};

export default MyRewardsRedeemProducts;
