import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import { Fragment, useEffect, useState, useRef } from "react";
import 'react-image-crop/dist/ReactCrop.css'
import { Button, Divider, Grid, Typography } from '@mui/material';
import { getBase64FileName, round } from '../util/utilities';
import { maxHeight } from '@mui/system';

const getCroppedImg = async (src, crop, imageRef) => {
  const image = new Image();
  image.src = src;
  await new Promise((resolve) => {
    image.onload = resolve;
  });

  const canvas = document.createElement('canvas');
  const displayedWidth = imageRef.current.getBoundingClientRect().width;
  const displayedHeight = imageRef.current.getBoundingClientRect().height;
  const scaleX = image.naturalWidth / displayedWidth;
  const scaleY = image.naturalHeight / displayedHeight;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return canvas.toDataURL('image/jpeg');
};



function ImageCropTool({ src, aspectRatio = null, handleUpdate = () => { }, handleCancel = () => { } }) {

  const [crop, setCrop] = useState({
    unit: 'px', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 200 * (aspectRatio ? aspectRatio : 1),
    height: 200,
    aspect: aspectRatio ? aspectRatio : undefined
  });

  const imageRef = useRef(null);
  const [imageStyle, setImageStyle] = useState({});

  const handleCancelImage = () => {
    handleCancel(src);
  }

  const handleImageLoad = (e) => {
    const image = e.target;
    const containerWidth = 0.4 * window.innerWidth; // 40% of the window width
    const containerHeight = 0.4 * window.innerHeight; // 40% of the window height
  
    const imageAspectRatio = image.width / image.height;
    const containerAspectRatio = containerWidth / containerHeight;
  
    let newWidth = image.width;
    let newHeight = image.height;
  
    if (imageAspectRatio > containerAspectRatio) {
      // Image is wider, fit based on the width
      newWidth = containerWidth;
      newHeight = newWidth / imageAspectRatio;
    } else {
      // Image is taller, fit based on the height
      newHeight = containerHeight;
      newWidth = newHeight * imageAspectRatio;
    }
  
    setImageStyle({
      width: newWidth,
      height: newHeight,
    });
  };

  const handleSubmit = async () => {
    if (src && crop.width && crop.height) {
      const croppedImageBase64 = await getCroppedImg(src, crop, imageRef);
      const fileName = `cropped-` + getBase64FileName(src);
      let croppedImageWithName = "" + croppedImageBase64;
      croppedImageWithName = croppedImageWithName.replace(";base64", `;name=${fileName};base64`);
      handleUpdate(croppedImageWithName);
    }
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        maxWidth: '40vw',
        maxHeight: '40vw',
      }}
    >
      <Grid item xs={12} justifyContent="center" style={{ display: 'flex' }}>
        <Typography
        
          style={{
            backgroundColor: "rgba(255, 255, 255, 1)", //mint green transparent
          
            //round corners
            borderRadius: "5px",
            padding: "6px 6px 6px 6px",
          }}
        >
          Click to Drag and expand Image
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ReactCrop
          crop={crop}
          onChange={(newCrop) => setCrop(newCrop)}
          // onComplete={onCropComplete}
          aspect={aspectRatio ? aspectRatio : undefined}
        >
          <div
            style={{
              position: "relative",
              display: "inline-block",
            }}
          >
            <img
              ref={imageRef}
              src={src}
              onLoad={handleImageLoad}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                ...imageStyle,
              }}
            />
          </div>
        </ReactCrop>
      </Grid>
      <Grid item xs={12} justifyContent="center" style={{ display: 'flex' }}>
        <Button
          onClick={handleCancelImage}
          style={{
            backgroundColor: "rgba(255, 0, 0, 0.8)", //red transparent
            padding: "24px 24px 24px 24px",
            color: "white",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          style={{
            backgroundColor: "rgba(199, 239, 229, 0.8)", //mint green transparent
            padding: "24px 24px 24px 24px",
            color: "white",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Submit Cropped Image
        </Button>
      </Grid>
      <Grid item xs={12} justifyContent="center" style={{ display: 'flex' }}>
        <Divider/>
      </Grid>
    </Grid>
  );

}

export default ImageCropTool;