import React from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  Divider,
  Box,
  TextField,
  FormControlLabel,
  Radio,
  Modal,
  CircularProgress,
  outlinedInputClasses,
  radioClasses,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { PurposeCategoryMasterLayout } from ".";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import MuiPhoneNumber from 'material-ui-phone-number';
import { isValidEmail, isValidPhoneNumber, formatPhoneNumber, isProd } from "../util/utilities";
import { updateFirstTimeUserAsync } from "../util/users";
import toast, { Toaster } from 'react-hot-toast';

const Header = styled(Typography)({
  color: "var(--mintGreenSolid)",
  marginTop: 10,
  marginBottom: 10,
});

const BoxForm = styled(Box)({
  width: "100%",
  marginTop: 20,
  marginBottom: 20,
});

const InputLabel = styled(Typography)({
  marginBottom: 10,
});

const InputField = styled(TextField)({
  [`& .${outlinedInputClasses.root}`]: {
    borderRadius: 7,
    "& fieldset": {
      borderColor: "#000000",
    },
    "&:hover fieldset": {
      borderColor: "var(--mintGreenSolid)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--mintGreenSolid)",
    },
  },
  [`& .${outlinedInputClasses.input}`]: {
    paddingTop: 11.5,
    paddingBottom: 10,
  },
});

const FormControlLabelContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
});

const StyledFormControlLabel = styled(FormControlLabel)({
  width: "70%",
  margin: 0,
});

const StyledRadio = styled(Radio)({
  color: "var(--mintGreenSolid)",
  [`&.${radioClasses.checked}`]: {
    color: "var(--mintGreenSolid)",
  },
});

const RightAlignContainer = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
});

const StyledButton = styled(Button)({
  textTransform: "none",
  backgroundColor: "var(--mintGreenSolid)",
  width: 190,
  "&:hover": {
    backgroundColor: "var(--mintGreenSolid)",
  },
});

const HaveAnAccountHeader = styled(Typography)({
  color: "var(--mintGreenSolid)",
  marginBottom: 30,
});

function SignUpLayout() {
  useDocumentTitle("Sign Up");
  const history = useHistory();
  const [fromSellerSignOn, setFromSellerSignOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [requestFailed, setRequestFailed] = useState(false);
  let query = useQuery();

  function useQuery() {
    const { search } = useLocation();
    let urlItems = `${search}`.split(/[?&]+/)
    urlItems.splice(0,1);
    const itemObject = {}
    for (const item of urlItems) {
      itemObject[item.split('=')[0]] = JSON.parse(item.split('=')[1].toLowerCase());
    }
    return itemObject;
  }

  React.useEffect(() => {
    if (query["redirectToCreateShop"]) {
      setFromSellerSignOn(true);
    }
  }, [query]);

  const createToastFromArray = (wordArray) => {
    const allText = wordArray.join('\n');
    toast((t) => (
      <span>
        {allText}
        {'\n'}
        <Grid container justifyContent="center">
          <Button onClick={() => toast.dismiss(t.id)}>
            Dismiss
          </Button>
        </Grid>
      </span>
    ));
  }

  const handleSignUp = async (event) => {
    if (!loading) {
      setLoading(true)
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const firstName = data.get("firstName");
      const lastName = data.get("lastName");
      const phoneNumber = formatPhoneNumber(phone);
      const email = data.get("email");
      const username = data.get("username");
      const password = data.get("password");
      const password2 = data.get("password2");
      let errorsInSubmission = []

      if (password !== password2) errorsInSubmission.push("Passwords do not match!");
      if (!isValidEmail(email)) errorsInSubmission.push("Email is invalid!");
      if (!isValidPhoneNumber(phone)) errorsInSubmission.push("Must have a full phone number!");
      if (!username) errorsInSubmission.push("Must have a Username!");
      if (!password) errorsInSubmission.push("Must have a password!");
      if (!password2) errorsInSubmission.push("Must confirm the password!");
      if (!firstName) errorsInSubmission.push("Must have a first name!");
      if (!lastName) errorsInSubmission.push("Must have a last name!");

      if (errorsInSubmission.length > 0) {
        createToastFromArray(errorsInSubmission);
        setLoading(false);
        return;
      }

      try {
        const { user } = await Auth.signUp({
          username: username,
          password: password,
          attributes: {
            email: email,
            given_name: firstName,
            family_name: lastName,
            phone_number: phoneNumber,
            'custom:branch': isProd() ? 'prod' : 'preprod',
          },
          autoSignIn: { // optional - enables auto sign in after user is confirmed
            enabled: true,
          },
        });
        if (fromSellerSignOn) {
          history.push("/seller/signup");
        } else {
          history.push("/");
        }
        // if (fromSellerSignOn) {
        //   history.push("/login?creation=true?redirectToCreateShop=true");
        // } else {
        //   history.push("/login?creation=true");
        // }
      } catch (error) {
        toast.error(`ERROR! ${error}`);
        setLoading(false)
        return;
      }
      setLoading(false)
    }
  };

  const handleSignIn = async (event) => {
    if (!loading) {
      setLoading(true)
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      try {
        await Auth.signIn(data.get("username"), data.get("password")).then(() => {
          if (fromSellerSignOn) {
            history.push("/seller/signup");
          } else {
            history.push("/");
          }
        }
        );
      } catch (error) {
        console.log("error signing in", error);
        window.alert(`${error}`);
      }
      setLoading(false)
    }
  };

  return (
    <PurposeCategoryMasterLayout>
      <Modal open={loading} sx={{ overflow: "scroll" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 2,
            bgcolor: "background.paper",
            p: 4,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
        </Box>
      </Modal>
      <Toaster 
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <Divider />
      <Container maxWidth="xl">
        <Header align="center" variant="h3" className={"roboto-bold"}>
          Create An Account
        </Header>
      </Container>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <BoxForm component="form" noValidate onSubmit={handleSignUp}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <InputLabel className={"roboto-medium"}>First Name</InputLabel>
                <InputField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel className={"roboto-medium"}>Last Name</InputLabel>
                <InputField
                  autoComplete="family-name"
                  name="lastName"
                  required
                  fullWidth
                  id="lastName"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel className={"roboto-medium"}>Email</InputLabel>
                <InputField
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel className={"roboto-medium"}>Phone</InputLabel>
                <MuiPhoneNumber disableAreaCodes fullWidth defaultCountry={'us'} 
                  onChange={(value) => {
                    setPhone(value)
                  }
                  }
                  value={phone}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel className={"roboto-medium"}>Username</InputLabel>
                <InputField
                  required
                  fullWidth
                  id="username"
                  name="username"
                  autoComplete="username"
                />
                <Typography sx={{typography: 'body2'}} className={"roboto-regular"}>
                  Username must include at least: 8 characters
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={"roboto-medium"}>Password</InputLabel>
                <InputField
                  required
                  fullWidth
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
                <Typography sx={{typography: 'body2'}} className={"roboto-regular"}>
                  Password must include at least: 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 special character
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={"roboto-medium"}>Confirm Password</InputLabel>
                <InputField
                  required
                  fullWidth
                  name="password2"
                  type="password"
                  id="password2"
                  autoComplete="new-password2"
                />
                <Typography sx={{typography: 'body2'}} className={"roboto-regular"}>
                  Please Re-type same Password as above
                </Typography>
              </Grid>
              <Grid item xs={25} spacing={25} my={1}>
                <Typography sx={{typography: 'body2'}} className={"roboto-regular"}>
                  <b>By signing up for an account you accept our <a href="/termsofuse" target="_blank" style={{textDecoration: "underline"}}>Terms of Use</a> &amp; <a href="/privacypolicy" target="_blank" style={{textDecoration: "underline"}}>Privacy Policy</a>. By submitting your information, you agree to receive emails and text marketing notifications from us. You may opt out at any time. Consent to receive messages is not required as a condition of any purchase. For SMS, reply STOP to cancel. Message and data rates may apply. If you receive a new account discount code, the discount code will expire after 3 months and may only be used once.</b>
                </Typography>
                {/* <FormControlLabelContainer>
                  <StyledFormControlLabel
                    control={<StyledRadio value="allowExtraEmails" />}
                    label="Sign up to receive all of our emails. Be the first to know about new arrivals, sales &amp; promos. You can opt out at any time."
                  />
                </FormControlLabelContainer> */}
              </Grid>
            </Grid>
            <RightAlignContainer>
              <StyledButton
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                <Typography className={"roboto-regular"}>
                  Create Account
                </Typography>
              </StyledButton>
            </RightAlignContainer>
          </BoxForm>
          <BoxForm component="form" noValidate onSubmit={handleSignIn}>
            <HaveAnAccountHeader variant="h6" className={"roboto-medium"}>
              Already Have an Account? Sign in!
            </HaveAnAccountHeader>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <InputLabel className={"roboto-medium"}>Username</InputLabel>
                <InputField
                  required
                  fullWidth
                  id="username"
                  name="username"
                  autoComplete="username"
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={"roboto-medium"}>Password</InputLabel>
                <InputField
                  required
                  fullWidth
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <RightAlignContainer>
              <StyledButton
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                <Typography className={"roboto-regular"}>Sign in</Typography>
              </StyledButton>
            </RightAlignContainer>
          </BoxForm>
        </Box>
      </Container>
    </PurposeCategoryMasterLayout>
  );
}

export default SignUpLayout;
