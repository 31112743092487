import { Auth } from "aws-amplify";

const signOut = async () => {
  try {
    await Auth.signOut();
    window.location.href = "/"
  } catch (error) {
    console.log("error singing out:", error);
  }
};

export default signOut;
