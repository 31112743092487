import {
  Typography,
  Grid,
  Button,
  TextField,
  outlinedInputClasses,
  InputAdornment,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles({
  questionText: {
    marginBottom: 15,
  },
  answerText: {
    marginBottom: 10,
    flexGrow: 1,
  },
  button: {
    textTransform: "none",
    backgroundColor: "#000000",
    width: 135,
    height: 29,
    borderRadius: 10,
    "&:hover": {
      backgroundColor: "#000000",
    },
  },
  buttonText: {
    color: "#FFFFFF",
  },
  noDiscussionsText: {
    fontSize: "1.1rem",
  },
});

const FlexGridItem = styled(Grid)({
  display: "flex",
});

const RightAlignDiv = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
});

const SearchDiv = styled(RightAlignDiv)({
  paddingTop: 20,
  paddingBottom: 25,
});

const SearchLogo = styled("img")({
  paddingTop: 5,
  height: 25,
});

const SearchTextField = styled(TextField)({
  width: "50%",
  [`& .${outlinedInputClasses.root}`]: {
    height: 35,
  },
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderStyle: "none",
    backgroundColor: "var(--greyTextfield)",
    borderRadius: 30,
  },
});

const DiscussionDiv = styled("div")({
  padding: 15,
  backgroundColor: "var(--discussionPost)",
  display: "flex",
  flexFlow: "column",
});

const DateDiv = styled(RightAlignDiv)({
  minHeight: 39,
});

const ViewMoreDiv = styled(RightAlignDiv)({
  paddingTop: 25,
});

const Discussion = (props) => {
  let { discussionData, classes } = props;

  return (
    <FlexGridItem item sm={6} md={4}>
      <DiscussionDiv>
        <DateDiv>
          <div>
            <Typography
              display="block"
              variant="caption"
              className={"roboto-regular"}
            >
              {discussionData.date}
            </Typography>
            <Typography variant="caption">{discussionData.time}</Typography>
          </div>
        </DateDiv>
        <Typography className={"roboto-medium"}>
          {discussionData.questioner}
        </Typography>
        <Typography className={classes.questionText}>
          Question: {discussionData.question}
        </Typography>
        <Typography className={"roboto-medium"}>
          {discussionData.answerer}
        </Typography>
        <Typography className={classes.answerText}>
          Answer: {discussionData.answer}
        </Typography>
      </DiscussionDiv>
    </FlexGridItem>
  );
};

function PopularDiscussions(props) {
  const classes = useStyles();

  let popularDiscussionsData = props.popularDiscussionsData;

  return (
    <>
      <Typography variant="h5" className={"roboto-medium"}>
        Popular Discussions
      </Typography>
      <SearchDiv>
        <SearchTextField
          variant="outlined"
          placeholder="Search topics"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <a href="#">
                  <SearchLogo
                    src={
                      "https://d2eudfgh8kpk8j.cloudfront.net/common/a7590ef5-d283-4656-980a-c0d3c9434520.png"
                    }
                    alt=""
                  />
                </a>
              </InputAdornment>
            ),
          }}
        />
      </SearchDiv>
      {popularDiscussionsData ? (
        <>
          <Grid
            container
            direction="row"
            alignItems="stretch"
            columnSpacing={4}
            rowSpacing={4}
          >
            {popularDiscussionsData &&
              popularDiscussionsData.map((discussionData, index) => {
                return (
                  <Discussion
                    discussionData={discussionData}
                    classes={classes}
                    index={index}
                    key={index}
                  />
                );
              })}
          </Grid>
          <ViewMoreDiv>
            <Button href="#" className={classes.button}>
              <Typography className={classes.buttonText}>View More</Typography>
            </Button>
          </ViewMoreDiv>
        </>
      ) : (
        <Typography align="center" className={classes.noDiscussionsText}>
          No Discussions
        </Typography>
      )}
    </>
  );
}

export default PopularDiscussions;
