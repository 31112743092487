import { getJsonCache, setJsonCache } from "./utilities";
const {
  NODE_API_ENDPOINT
} = require("./constants");

async function getListOfProductCategoriesById(
  idList,
  callback,
) {
  if (idList && idList.length > 0) {
    let requestURL = NODE_API_ENDPOINT + "/get-categories-by-id";
    for (let i = 0; i < idList.length; i++) {
      if (i === 0) {
        requestURL += "?id=" + idList[i];
      } else {
        requestURL += "&id=" + idList[i];
      }
    }

    fetch(requestURL)
      .then((response) => response.json())
      .then(
        (data) => callback(data),
        (error) => console.log(error)
      );
  }
}

async function getHeaderDataOld(callback) {
  fetch("https://wtdkep3b57.execute-api.us-west-1.amazonaws.com/dev/header")
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function getHeaderData(callback) {
  const dataName = 'getHeaderData';
  const cachedData = getJsonCache(dataName, 75);
  if (cachedData) callback(cachedData);
  
  fetch(NODE_API_ENDPOINT + "/get-header-data")
  .then((response) => response.json())
  .then(
    (data) => {
      setJsonCache(dataName, data);
      callback(data)
    },
    (error) => console.log(error)
  );
}

async function getProductCategoryById(id, callback) {
  fetch(NODE_API_ENDPOINT + "/get-products-by-category-id?id=" + id)
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function getCategoryById(id, callback) {
  fetch(NODE_API_ENDPOINT + "/get-category-by-id?id=" + id)
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function getProductsLessThanPrice(lessThanPrice, callback) {
  fetch(NODE_API_ENDPOINT + "/get-products-less-than-price?lessThanPrice=" + lessThanPrice)
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

export {
  getListOfProductCategoriesById,
  getProductCategoryById,
  getHeaderData,
  getCategoryById,
  getProductsLessThanPrice,
};
