import { Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";

const useStyles = makeStyles({
  image: {
    width: 170,
    height: 230,
    objectFit: "cover",
    display: "block",
    margin: "auto",
    borderRadius: 20,
  },
  container: {
    width: "100%",
    margin: "auto",
    marginTop: 20,
  },
  text: {
    textAlign: "center",
    marginTop: 10,
  },
  gridMargin: { marginBottom: 10 },
});

const Title = styled(Typography)({
  fontSize: 37.43,
});

const PortraitText = styled(Typography)({
  fontSize: 29,
});

const Portrait = ({ classes, item }) => (
  <Grid item xs={6} sm={4} md={2}>
    <a href={"/shop/categories/" + item.categoryIds?.join('+') + "/" + encodeURI(item.title)}>
      <div className={classes.container}>
        <img className={classes.image} src={item.img} alt={item.title} />
      </div>
      <PortraitText className={clsx(classes.text, "roboto-bold")}>
        {item.title}
      </PortraitText>
    </a>
  </Grid>
);

const ShopByCategoryView = ({ data, title }) => {
  const classes = useStyles();
  const mobile = useMobileMediaQuery();

  return (
    <Container maxWidth="xl">
      <Title className={"roboto-medium"} align={mobile ? "center" : "left"}>
        {title ? title : "Shop By Category"}
      </Title>
      <Grid container>
        <Grid container item direction="row" className={classes.gridMargin}>
          {SHOP_BY_CATEGORY_DATA.slice(0, 6).map((item, i) => {
            return <Portrait key={i} classes={classes} item={item} />;
          })}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ShopByCategoryView;

const SHOP_BY_CATEGORY_DATA = [
  {
    title: "Women",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/common/specific-section/women_pic.png",
    categoryIds: [28, 101, 106, 3, 67, 125, 32, 43, 45, 35],
  },
  {
    title: "Men",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/common/specific-section/men_pic.png",
    categoryIds: [34, 27, 102, 107, 115],
  },
  {
    title: "Kids",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/common/Kids.jpeg",
    categoryIds: [29, 103],
  },
  {
    title: "Home",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/common/specific-section/home_pic.png",
    categoryIds: [4],
  },
  {
    title: "Kitchen",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/common/specific-section/kitchen_pic.png",
    categoryIds: [69],
  },
  {
    title: "Pets",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/common/specific-section/pets_pic.png",
    categoryIds: [6, 104],
  },
];
