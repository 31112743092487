import React from "react";
import {
  AccordionDetails,
  Box,
  Container,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import menuJsonData from "../json/category_menu.json";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import {
  getListOfProductCategoriesById,
  getHeaderData
} from "../util/productCategories";
import signedInIcon from "../img/signed_in_icon.png";

const BackgroundDiv = styled("div")({
  backgroundColor: "var(--coral)",
});

const SpacingContainer = styled("div")({
  justifyContent: "space-around",
  display: "flex",
  // height: 65,
  alignItems: "center",
  //margin bottom 5px
  marginBottom: '15px',
});

const Links = styled("a")({
  color: "#FFFFFF",
  "&:hover": {
    color: "#FFFFFF",
  },
  "&:visited": {
    color: "#FFFFFF",
  },
});

const Accordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    paddingLeft: 0,
    marginBottom: -1,
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const StyledAccordianDetails = styled(AccordionDetails)({
  paddingBottom: 8,
  paddingLeft: 0,
});

const VerticallyCenterDiv = styled("div")({
  display: "flex",
  alignItems: "center",
});

const StyledSignedInIcon = styled("img")({
  width: 35,
  height: 35,
});

const Logo = styled("img")({
  width: "180%",
  maxHeight: "5rem",
});

function StatementHeader({ userAttributes, menuData, signOut }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [categoryMenuData, setCategoryMenuData] = React.useState([]);
  const theme = useTheme();
  const showHamburger = useMediaQuery(theme.breakpoints.down("md"));

  React.useEffect(() => {
    if (
      showHamburger &&
      categoryMenuData.length === 0 &&
      menuData &&
      menuData.hasOwnProperty("categories")
    ) {
      // const categories = menuData.categories;
      // const categoryIds =
      //   !categories || categories.length === 0
      //     ? menuJsonData.categories
      //     : categories;

      // getListOfProductCategoriesById(categoryIds, (data) => {
      //   if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
      //     data.sort((category1, category2) => {
      //       return category1.id - category2.id;
      //     });
      //     console.log('here')
      //     console.log(data);
      //     for (const category of data) {
      //       console.log(category.subcategories)
      //       // category.subcategories = category.subcategories.filter(sc => sc.productCount > 0);
      //     }
      //     setCategoryMenuData(data);
      //   }
      // }, true);
      getHeaderData((data) => {
        if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
          data.sort((category1, category2) => {
            return category1.id - category2.id;
          });
          //Custom ordering of this category in particular was requested
          const clothingAndShoesDropdown = data.find(category => category.name == "Clothing & Shoes");
          if (clothingAndShoesDropdown?.subcategories) {
            const newOrderedArray = ["Women", "Men", "Graphic Tees", "Short Sleeve", "Long Sleeve", "Tank Tops", "Swimwear", "Activewear", "Sweatshirts & Hoodies", "Sweater", "Outerwear", "Sleepwear & Leisurewear", "Bottoms", "Jeans", "Dresses & Skirts", "Baby Clothes"];
            const subCats = [...clothingAndShoesDropdown.subcategories];
            delete clothingAndShoesDropdown.subcategories;
            clothingAndShoesDropdown.subcategories = [];
            const newSubCategories = clothingAndShoesDropdown.subcategories;
            for (const item of newOrderedArray) {
              const itemFind = subCats.find(c => c.name == item);
              if (itemFind) {
                newSubCategories.push(itemFind);
              }
            }
          }
          for (const category of data) {
            category.subcategories = category.subcategories.filter(sc => sc.productCount > 0);
          }

          setCategoryMenuData(data);
        }
      });
    }
  }, [showHamburger, categoryMenuData, menuData]);

  const handleChange = (category) => (event, newExpanded) => {
    setExpanded(newExpanded ? category : false);
  };

  const open = Boolean(anchorElNav);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <BackgroundDiv>
      <Container maxWidth="xl">
        <nav>
          {/* Container wrapper */}
          <SpacingContainer sx={{height: showHamburger ? 55 : 45}}>
            {/* Collapsible wrapper */}
            {/* <Links href="/blog">
              <Typography className={"roboto-regular"}>#BeAPurposer</Typography>
            </Links>
            <Links href="/press">
              <Typography className={"roboto-regular"}>
                #Purposer
              </Typography>
            </Links>
            <Links
              sx={{
                display: { xs: "none", md: "flex" },
              }}
              href="/blog"
            >
              <Typography className={"roboto-regular"}>
                Shop Purposely
              </Typography>
            </Links>
            <Links
              sx={{
                display: { xs: "none", md: "flex" },
              }}
              href="/press"
            >
              <Typography className={"roboto-regular"}>
                Purpose over Profit
              </Typography>
            </Links> */}
            { showHamburger ? (
            <Grid container item direction="row"> 
              <Grid item xs={2} >
                <Link to="/">
                  <Logo
                    src={
                      "https://d2eudfgh8kpk8j.cloudfront.net/common/purposer_logo_large_white.png"
                    }
                    alt=""
                  />
                </Link>
              </Grid>
              <Grid item xs={10} align="center">
                <Typography className={"roboto-light"} sx={{ fontSize: "82.5%", color: "white", marginTop: "0.4rem", marginLeft: "4.5rem" }}>
                  {'ECO-FRIENDLY. CHARITABLE'}
                </Typography>
              </Grid>
            </Grid>) :
            <Typography className={"roboto-light"} sx={{ fontSize: "115%", color: "white" }}>
              {'ECO-FRIENDLY. FAIR TRADE. CHARITABLE'}
            </Typography>
            }
          </SpacingContainer>
          {/* Container wrapper */}
        </nav>
      </Container>
    </BackgroundDiv>
  );
}

export default StatementHeader;
