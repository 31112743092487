import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { shortenUUID, formatDate } from '../util/utilities'

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  noOrdersCell: {
    border: "none",
  },
  tableRows: {
    backgroundColor: "",
  },
}));

const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        size="large"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        size="large"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="large"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        size="large"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const AccountPurchasesView = (props) => {
  const { purchasesData, handleSelectedOrderData } = props;
  const classes = useStyles1();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows = purchasesData
    ? rowsPerPage -
      Math.min(rowsPerPage, purchasesData.length - page * rowsPerPage)
    : 4;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSelectedOrder = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
    <h1 style={{textAlign: "center"}}>Your Orders</h1>
      <TableContainer component={Paper}>
        <Table className={"string"} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography>
                  <b>Order No.</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>
                  <b>Date</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>
                  <b>Status</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>
                  <b>Total $</b>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchasesData && purchasesData.length > 0 ? (
              (rowsPerPage > 0
                ? purchasesData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : purchasesData
              ).map((row) => (
                <TableRow key={'purchaseview' + row.orderID} className={classes.tableRows}>
                  <TableCell component="th" scope="row" align="center">
                    <Button>
                      <Typography 
                        onClick={(e) => handleSelectedOrderData(row.orderID)}
                      >
                        <b>{shortenUUID(row.orderID)}</b>
                      </Typography>
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      <b>{formatDate(row.orderDateTime)}</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      <b>{row.returnStatus ? row.returnStatus : row.status}</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      <b>${row.paymentAmount}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow className={classes.tableRows}>
                <TableCell
                  align="center"
                  colSpan={4}
                  className={classes.noOrdersCell}
                >
                  <Typography>
                    {!purchasesData ? <b>Loading Orders</b> : <b>No Orders</b>}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{ height: 53 * emptyRows }}
                className={classes.tableRows}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow className={classes.tableRows}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={4}
                count={purchasesData ? purchasesData.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default AccountPurchasesView;
