import FileUpload, {
  triggerUpload,
  onDragOverHandler,
  onDropHandler,
  UPLOAD_ERROR,
} from "./FileUpload";
import { useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import AddIcon from "@mui/icons-material/Add";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { ButtonBase, IconButton, Typography } from "@mui/material";
import { MAX_FILE_SIZE, FILETYPES } from "../util/constants"

const styles = {
  container: {
    width: "100%",
    height: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
  },
  uploadButton: {
    borderStyle: "solid",
    borderWidth: 2,
  },
  img: {
    width: "100%",
    height: "inherit",
    objectFit: "cover",
    margin: "auto",
  },
};

const NavButton = styled(ButtonBase)({
  position: "absolute",
  height: 75,
  borderRadius: 20,
  backgroundColor: "teal",
  opacity: 0.5,
});

const PrevButton = styled(NavButton)({
  left: 20,
});

const NextButton = styled(NavButton)({
  right: 20,
});

const StyledBackArrow = styled(ArrowBack)({
  color: "white",
  fontSize: 30,
});

const StyledForwardArrow = styled(ArrowForward)({
  color: "white",
  fontSize: 30,
});

const useStyles = makeStyles({
  carouselContainer: {
    width: "100%",
    maxHeight: "inherit",
    height: "inherit",
    borderRadius: "inherit",
  },
  carouselItem: {
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
});

const responsive = {
  break1: {
    breakpoint: { max: 3000, min: 0 },
    items: 1,
  },
};

function ImageUploader(props) {
  const classes = useStyles();
  const {
    containerStyle = styles.container,
    uploadIcon,
    uploadButtonStyle = styles.uploadButton,
    multiple = false,
    maxFiles = 6,
    filetypes = FILETYPES,
    maxFileSize = MAX_FILE_SIZE,
    formValues = {},
    propertyName = "",
    setFormValues = () => {},
    imgStyle = styles.img,
    setRef = () => {},
    autoplay = false,
    imgIndex,
    tempBanner,
  } = props;
  let uploadRef = useRef(null);
  const [error, setError] = useState(null);

  const handleFormValues = (name, value) => {
    setFormValues((preValues) => {
      return { ...preValues, [name]: value };
    });
  };

  const CustomButtonGroupAsArrows = ({
    next,
    previous,
    carouselState: { currentSlide },
  }) => {
    if (imgIndex) {
      imgIndex(currentSlide - 2);
    }
    return (
      <>
        <PrevButton
          onClick={(e) => {
            e.stopPropagation();
            previous();
          }}
        >
          <StyledBackArrow />
        </PrevButton>
        <NextButton
          onClick={(e) => {
            e.stopPropagation();
            next();
          }}
        >
          <StyledForwardArrow />
        </NextButton>
      </>
    );
  };

  return (
    <>
      <div
        style={containerStyle}
        onClick={() => {
          triggerUpload(uploadRef);
        }}
        onDrop={(e) =>
          onDropHandler(
            e,
            setError,
            tempBanner ? tempBanner : propertyName,
            maxFiles,
            filetypes,
            maxFileSize,
            handleFormValues
          )
        }
        onDragOver={onDragOverHandler}
      >
        {formValues.hasOwnProperty(propertyName) &&
        formValues[propertyName] &&
        formValues[propertyName].length > 0 ? (
          multiple ? (
            <Carousel
              responsive={responsive}
              draggable={false}
              infinite={true}
              showDots={true}
              arrows={false}
              containerClass={classes.carouselContainer}
              itemClass={classes.carouselItem}
              slidesToSlide={1}
              autoPlay={autoplay}
              autoPlaySpeed={8000}
              customButtonGroup={<CustomButtonGroupAsArrows />}
            >
              {formValues[propertyName].map((coverPhoto, index) => {
                return (
                  <img
                    src={
                      typeof coverPhoto === "object" ? coverPhoto.S : coverPhoto
                    }
                    alt=""
                    key={index}
                    style={imgStyle}
                  />
                );
              })}
            </Carousel>
          ) : (
            <img src={formValues[propertyName]} alt="" style={imgStyle} />
          )
        ) : (
          <IconButton
            style={uploadButtonStyle}
            onClick={(e) => {
              e.stopPropagation();
              triggerUpload(uploadRef);
            }}
          >
            {uploadIcon ? uploadIcon : <AddIcon />}
          </IconButton>
        )}
        <FileUpload
          setRef={(inputRef) => {
            uploadRef = inputRef;
            setRef(inputRef);
          }}
          multiple={multiple}
          setError={setError}
          propertyName={tempBanner ? tempBanner : propertyName}
          maxFiles={maxFiles}
          accept={"image/*"}
          filetypes={filetypes}
          maxFileSize={maxFileSize}
          updateState={handleFormValues}
        />
      </div>
      {error && (
        <Typography className={"roboto-regular"}>
          {error.type === UPLOAD_ERROR.FILESIZE_TOO_LARGE
            ? "Max file size exceeded"
            : "Illegal file type"}
        </Typography>
      )}
    </>
  );
}

export default ImageUploader;
