import { useState, useEffect } from "react";

// Custom hook to enforce relative sizing and aspect ratios because the
// aspect ratio css property is not supported on Safari
function useElementWidth(ref, includeBorder = true) {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    function updateWidth() {
      if (ref && ref.current) {
        const newValue = includeBorder
          ? ref.current.offsetWidth
          : ref.current.clientWidth;
        setWidth(newValue);
      }
    }

    window.addEventListener("resize", updateWidth);
    updateWidth();
    return () => window.removeEventListener("resize", updateWidth);
  }, [ref, includeBorder]);
  return width;
}

export default useElementWidth;
