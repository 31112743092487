import { Box, ButtonBase, Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import mockData from "../json/support_your_view.json";
import clsx from "clsx";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";
import Carousel from "react-multi-carousel";
import { useState, useEffect } from "react";
import { getListOfPurposeCategoriesById } from "../util/purposeCategories";

const useStyles = makeStyles({
  containerDiv: {
    backgroundImage:
      "url(https://d2eudfgh8kpk8j.cloudfront.net/common/91fc8eb5-8f1a-4550-9db4-4006b1aed982.png)",
    overflow: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  container: { marginBottom: 60 },
  title: {
    margin: [[30, 0, 30, 0]],
  },
  image: {
    width: "100%",
    height: "150px",
    objectFit: "cover",
    display: "block",
    borderRadius: "10px",
  },
  navButtons: {
    position: "absolute",
    top: "30%",
    height: 75,
    border: "1px solid var(--mintArrow)",
    borderRadius: 15,
    backgroundColor: "#FFFFFF",
  },
  mobileNavButtons: {
    position: "absolute",
    top: "35%",
    height: 50,
    width: 25,
    border: "1px solid var(--mintArrow)",
    borderRadius: 15,
    backgroundColor: "#FFFFFF",
  },
  prevButton: {
    left: -33,
  },
  nextButton: {
    right: -33,
  },
  divContainer: {
    position: "relative",
    width: "90%",
    margin: "auto",
    padding: "15px 0",
  },
  arrows: {
    color: "var(--mintArrow)",
    fontSize: 30,
  },
  carouselContainer: {
    width: "100%",
  },
});

const responsive = {
  break1: {
    breakpoint: { max: 4000, min: 1297 },
    items: 5,
  },
  break2: {
    breakpoint: { max: 1297, min: 900 },
    items: 4,
  },
  break3: {
    breakpoint: { max: 900, min: 0 },
    items: 3,
  },
};

const SupportYourView = ({
  data,
  title = "Love the animal kingdom? Explore shops that support your favorite animal!",
}) => {
  const classes = useStyles();
  const mobile = useMobileMediaQuery();
  const [purposes, setPurposes] = useState([]);

  useEffect(() => {
    if (!data || data.length === 0) {
      getListOfPurposeCategoriesById(mockData, (data) => {
        if (!data.hasOwnProperty("error")) {
          data[3].id = 97;
          data[5].id = 97;
          setPurposes(data);
        }
      });
    } else {
      setPurposes(data)
    }
  }, [data]);


  const Item = ({ item }) => (
    <Box sx={{ width: "95%", margin: "auto" }}>
      <a href={"/shop/purpose/" + item.higherPurposeID + "/" + item.id}>
        <div>
          <img className={classes.image} src={item.img} alt={item.name} />
          <Typography align="center" variant="subtitle1">
            <b>{item.name}</b>
          </Typography>
        </div>
      </a>
    </Box>
  );

  const CarouselRow = ({ data }) => (
    <div className={classes.divContainer}>
      <Carousel
        responsive={responsive}
        infinite={false}
        arrows={false}
        renderButtonGroupOutside={true}
        customButtonGroup={<CustomButtonGroupAsArrows />}
        containerClass={classes.carouselContainer}
      >
        {data.map((item, i) => {
          return <Item key={i} item={item} />;
        })}
      </Carousel>
    </div>
  );

  const CustomButtonGroupAsArrows = ({ next, previous }) => {
    return (
      <>
        <ButtonBase
          className={clsx(
            mobile ? classes.mobileNavButtons : classes.navButtons,
            classes.prevButton
          )}
          onClick={previous}
        >
          <ArrowBack className={classes.arrows} />
        </ButtonBase>
        <ButtonBase
          className={clsx(
            mobile ? classes.mobileNavButtons : classes.navButtons,
            classes.nextButton
          )}
          onClick={next}
        >
          <ArrowForward className={classes.arrows} />
        </ButtonBase>
      </>
    );
  };

  return (
    <div className={classes.containerDiv}>
      <Container maxWidth="xl" className={classes.container}>
        <Typography
          variant="h4"
          className={clsx(classes.title, "roboto-medium")}
          align="center"
        >
          {title}
        </Typography>
        <CarouselRow data={purposes?.length > 0 ? purposes : []} />
      </Container>
    </div>
  );
};

export default SupportYourView;
