import {
    Grid,
    Typography,
    Select,
    MenuItem,
    Avatar,
    Stack,
    FormControl,
    Modal,
    Box,
    Container,
    ButtonBase,
    Autocomplete,
    TextField,
    FormControlLabel,
    Radio,
    RadioGroup,
    IconButton,
    InputLabel,
    OutlinedInput,
    Chip,
    useTheme,
    Button
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CloseIcon from '@mui/icons-material/Close';
import { createCoupon } from "../util/coupons";
import { COUPON_TYPES } from "../util/constants";
import toast, { Toaster } from 'react-hot-toast';

const style = {
    position: 'absolute',
    borderRadius: '16px',
    backgroundColor: "white",
    minWidth: "40%",
    maxHeight: "90vh",
    overflow: "auto",
    border: "2px solid",
    zIndex: "2",
    paddingTop: "32px",
    paddingBottom: "32px",
    boxShadow: 24,
};

const modalCenter = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const SelectProducts = (props) => {
    const { productList, formValues, handleFormValues } = props;
    const theme = useTheme();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        handleFormValues(
            "specifiedProductsArr",
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <div>
            <FormControl sx={{ width: 300 }}>
                <InputLabel id="label">Select Products</InputLabel>
                <Select
                    labelId="label"
                    id="multiple-select"
                    multiple
                    value={formValues.specifiedProductsArr}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {productList.map((product) => (
                        <MenuItem
                            key={product.name}
                            value={product.name}
                            style={getStyles(product.name, formValues.specifiedProductsArr, theme)}
                        >
                            {product.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )

}

const SalesCreate = (props) => {
    const { createSaleView, setCreateSaleView, productList } = props;
    const { id: sellerIDparam } = useParams();

    const [formValues, setFormValues] = React.useState({
        percentage: "",
        discount: "",
        type: "Percent",
        startDate: "",
        endDate: "",
        endTime: "",
        status: "",
        value: "",
        specificProducts: "false",
        specifiedProductsArr: [],
        limitBool: "false",
        limitVal: "",
    });

    const [isLoading, setIsLoading] = React.useState(false);

    const handleStartDateChange = (newValue) => {
        setFormValues((preValues) => {
            return { ...preValues, "startDate": newValue };
        });
    };

    const handleEndDateChange = (newValue) => {
        setFormValues((preValues) => {
            return { ...preValues, "endDate": newValue };
        });
    };

    const handleEndTimeChange = (newValue) => {
        setFormValues((preValues) => {
            return { ...preValues, "endTime": newValue };
        });
    };

    const handleFormValues = (name, value) => {
        setFormValues((preValues) => {
            return { ...preValues, [name]: value };
        });
    };

    const handleSubmit = async (e) => {
        if (!isLoading) {
            e.preventDefault();
            setIsLoading(true);

            let percentOff;
            let amountOff;
            if (formValues.type == 'Dollars Off') {
                amountOff = formValues.value;
                if (!amountOff || amountOff > 1000 || amountOff < 0) {
                    toast.error('Amount off must be between 0 and $1,000');
                    setIsLoading(false);
                    return;
                }
            } else if (formValues.type == 'Percent') {
                percentOff = formValues.value;
                if (!percentOff || percentOff > 100 || percentOff < 0) {
                    toast.error('Percent off must be between 0 and 100');
                    setIsLoading(false);
                    return;
                }
            }

            let maxRedemptions;
            if (formValues.limitBool == 'true') {
                maxRedemptions = formValues.limitVal
            }
            const endDate = new Date(formValues.endDate);
            const endTime = new Date(formValues.endTime);

            let newDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endTime.getHours(), endTime.getMinutes(), endTime.getSeconds());

            const expirationDate = newDate.getTime();
            const skuIDs = [];
            if (formValues.specificProducts === "true" && formValues.specifiedProductsArr?.length > 0) {
                for (const product of formValues.specifiedProductsArr) {
                    const currSkuID = productList.find(p => p.name == product)?.skuID;
                    if (currSkuID) skuIDs.push(currSkuID);
                }
            } else if (formValues.specificProducts === "false") {
                if (productList.length == 0) {
                    toast.error('Must have at least one product to create a sale!')
                    setIsLoading(false);
                    return;
                }
                for (const product of productList) {
                    const currSkuID = product?.skuID;
                    if (currSkuID) skuIDs.push(currSkuID);
                }
            }

            if (skuIDs.length == 0 || !expirationDate || !formValues.name || (!amountOff && !percentOff)) {
                toast.error('Must fill out all required fields!')
                setIsLoading(false);
            } else {
                //Good to create coupon
                createCoupon(sellerIDparam, skuIDs, maxRedemptions, COUPON_TYPES.SALE, percentOff, amountOff, formValues.name, true, expirationDate, (data) => {
                    if (data.success) {
                        toast.success('Created sale!')
                        //setCreateSaleView(false);
                        window.location.reload();
                    } else {
                        toast.error('Failed to create sale!')
                    }
                    setIsLoading(false);
                });
            }
        }
    }

    return (
        <Modal open={createSaleView} onClose={() => setCreateSaleView(false)} sx={modalCenter}>
            <>
                <Toaster
                    toastOptions={{
                        className: '',
                        style: {
                            border: '1px solid #713200',
                            padding: '16px',
                            color: '#713200',
                        },
                    }}
                />
                <Box sx={style}>
                    <Container>
                        <Grid container justifyContent='space-between'>
                            <Typography variant="h5" style={{ color: "var(--coral)" }}>
                                <b>CREATE A SALE</b>
                            </Typography>
                            <IconButton
                                onClick={() => setCreateSaleView(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <Box sx={{ pt: 2 }}>
                            <Typography variant="h6">Name of Sale</Typography>
                            <TextField
                                name="name"
                                onChange={(e) => handleFormValues("name", e.target.value)}
                                defaultValue={formValues.name}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                            <Typography>This will appear on customers' receipts.</Typography>
                        </Box>
                        <Box sx={{ pt: 2 }}>
                            <Typography variant="h6">Terms of Sale</Typography>
                            <Stack direction={"row"} spacing={3}>
                                <TextField label="Enter a number value" onChange={(e) => handleFormValues("value", e.target.value)}></TextField>
                                <FormControl component="fieldset" sx={{ mb: 3 }}>
                                    <RadioGroup
                                        row={true}
                                        name="type"
                                        onChange={(e) => handleFormValues("type", e.target.value)}
                                        value={formValues.type}
                                    >

                                        <FormControlLabel
                                            value="Percent"
                                            control={
                                                <Radio
                                                    style={{
                                                        color: "var(--coral)",
                                                        backgroundColor: "transparent",
                                                    }}
                                                />
                                            }
                                            label="Percent"
                                        />
                                        {/* <FormControlLabel
                                            value="Dollars Off"
                                            control={
                                                <Radio
                                                    style={{
                                                        color: "var(--coral)",
                                                        backgroundColor: "transparent",
                                                    }}
                                                />
                                            }
                                            label="Dollars Off"
                                        /> */}
                                    </RadioGroup>
                                </FormControl>
                            </Stack>
                        </Box>
                        <Box sx={{ pt: 2 }}>
                            <Typography variant="h6">Duration</Typography>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack
                                    direction={"row"}
                                    spacing={2}
                                >
                                    <DatePicker
                                        label="Start Date"
                                        value={new Date()}
                                        disabled={true}
                                        onChange={handleStartDateChange}
                                        renderInput={(params) => <TextField {...params} fullWidth />}


                                    />
                                    <Typography style={{ display: "flex", alignItems: "center" }}>until</Typography>
                                    <DatePicker
                                        label="End Date"
                                        value={formValues.endDate ? formValues.endDate : null}
                                        onChange={handleEndDateChange}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                    <div>
                                        <TimePicker
                                            label="Time"
                                            value={formValues.endTime ? formValues.endTime : null}
                                            onChange={handleEndTimeChange}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                        <Typography variant="caption">Tip: click the clock icon to pick a time</Typography>
                                    </div>
                                </Stack>
                            </LocalizationProvider>
                        </Box>
                        <Box sx={{ pt: 2 }}>
                            <Typography variant="h6">Apply to specific products</Typography>
                            <FormControl component="fieldset" sx={{ mb: 1 }}>
                                <RadioGroup row={true} name="specificProducts" onChange={(e) => handleFormValues("specificProducts", e.target.value)} value={formValues.specificProducts}>
                                    <FormControlLabel value="true"
                                        control={<Radio style={{ color: "var(--coral)", backgroundColor: "transparent", }} />}
                                        label="YES"
                                    />
                                    <FormControlLabel value="false"
                                        control={<Radio style={{ color: "var(--coral)", backgroundColor: "transparent", }} />}
                                        label="NO"
                                    />
                                </RadioGroup>
                            </FormControl>
                            {formValues.specificProducts === "true" ? <SelectProducts productList={productList} formValues={formValues} handleFormValues={handleFormValues} /> :
                                <Typography>Selecting 'NO' will apply the sale to all listings.</Typography>}
                        </Box>
                        <Box sx={{ pt: 2, mb: 2 }}>
                            <Typography variant="h6">Limit the total number of times this Sale can be used.</Typography>
                            <FormControl component="fieldset" sx={{ mb: 1 }}>
                                <RadioGroup row={true} name="limitBool" onChange={(e) => handleFormValues("limitBool", e.target.value)} value={formValues.limitBool}>
                                    <FormControlLabel value="true"
                                        control={<Radio style={{ color: "var(--coral)", backgroundColor: "transparent", }} />}
                                        label="YES"
                                    />
                                    <FormControlLabel value="false"
                                        control={<Radio style={{ color: "var(--coral)", backgroundColor: "transparent", }} />}
                                        label="NO"
                                    />
                                </RadioGroup>
                            </FormControl>
                            {formValues.limitBool === "true" ?
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", columnGap: 16 }}>
                                    <TextField name="name"
                                        label="Enter a number value"
                                        onChange={(e) => handleFormValues("limitVal", e.target.value)}
                                        defaultValue={formValues.limitVal}
                                        variant="outlined"
                                    /> <Typography>time&#40;s&#41;</Typography></div> :
                                <Typography></Typography>}
                        </Box>
                        <br/>
                        <br/>
                        <br/>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", columnGap: 2 }}>
                            <Button variant="contained" sx={{ backgroundColor: "darkgray" }} size="large"
                                onClick={(e) => {
                                    setCreateSaleView(false)
                                }}
                            >
                                <Typography>
                                    <b>Cancel</b>
                                </Typography>
                            </Button>
                            <Button variant="contained" sx={{ backgroundColor: "var(--coral)" }} size="large"
                                onClick={(e) => {
                                    handleSubmit(e);
                                }}
                                disabled={isLoading}
                            >
                                <Typography>
                                    <b>{isLoading ? `Loading` : `Publish`}</b>
                                </Typography>
                            </Button>
                        </Box>
                    </Container>
                </Box>
            </>
        </Modal>
    );
};

export default SalesCreate;