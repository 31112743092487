import React from "react";
import {
  AccordionDetails,
  Box,
  Container,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  ButtonBase,
  Avatar,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import menuJsonData from "../json/category_menu.json";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import {
  getListOfProductCategoriesById,
  getHeaderData
} from "../util/productCategories";
import menuSlimIcon from "../img/menu_slim_icon.png";

const BackgroundDiv = styled("div")({
  backgroundColor: "var(--coral)",
});

const SpacingContainer = styled("div")({
  justifyContent: "space-around",
  display: "flex",
  height: 45,
  alignItems: "center",
  //margin bottom 5px
  marginBottom: '25px',
});

const Links = styled("a")({
  color: "#FFFFFF",
  "&:hover": {
    color: "#FFFFFF",
  },
  "&:visited": {
    color: "#FFFFFF",
  },
});

const Accordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    paddingLeft: 0,
    marginBottom: -1,
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const StyledAccordianDetails = styled(AccordionDetails)({
  paddingBottom: 8,
  paddingLeft: 0,
});

const VerticallyCenterDiv = styled("div")({
  display: "flex",
  alignItems: "center",
});

const StyledIcon = styled("img")({
  width: 35,
  height: 35,
});

function MobileMenu({ userAttributes, menuData, signOut }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [categoryMenuData, setCategoryMenuData] = React.useState([]);
  const theme = useTheme();
  const showHamburger = useMediaQuery(theme.breakpoints.down("md"));

  React.useEffect(() => {
    if (
      showHamburger &&
      categoryMenuData.length === 0
    ) {

      getHeaderData((data) => {
        if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
          data.sort((category1, category2) => {
            return category1.id - category2.id;
          });
          //Custom ordering of this category in particular was requested
          const clothingAndShoesDropdown = data.find(category => category.name == "Clothing & Shoes");
          if (clothingAndShoesDropdown?.subcategories) {
            const newOrderedArray = ["Women", "Men", "Graphic Tees", "Short Sleeve", "Long Sleeve", "Tank Tops", "Swimwear", "Activewear", "Sweatshirts & Hoodies", "Sweater", "Outerwear", "Sleepwear & Leisurewear", "Bottoms", "Jeans", "Dresses & Skirts", "Baby Clothes"];
            const subCats = [...clothingAndShoesDropdown.subcategories];
            delete clothingAndShoesDropdown.subcategories;
            clothingAndShoesDropdown.subcategories = [];
            const newSubCategories = clothingAndShoesDropdown.subcategories;
            for (const item of newOrderedArray) {
              const itemFind = subCats.find(c => c.name == item);
              if (itemFind) {
                newSubCategories.push(itemFind);
              }
            }
          }
          for (const category of data) {
            category.subcategories = category.subcategories.filter(sc => sc.productCount > 0);
          }

          setCategoryMenuData(data);
        }
      });
    }
  }, [showHamburger, categoryMenuData, menuData]);

  const handleChange = (category) => (event, newExpanded) => {
    setExpanded(newExpanded ? category : false);
  };

  const open = Boolean(anchorElNav);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <div>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <IconButton
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <StyledIcon 
              src={menuSlimIcon}
              sx={{ 
                width: "25px", 
                height: "15px" 
              }}
            />
          </IconButton>
          <Popover
            open={open}
            anchorEl={anchorElNav}
            onClose={handleCloseNavMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box sx={{ m: 1, p: 1 }}>
              {userAttributes ? (
                <MenuItem
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to="/acct"
                  sx={{ px: 0 }}
                >
                  <Typography
                          variant="h6"
                          className={"roboto-medium"}
                          align="left"
                        >
                          <b>Hi, {userAttributes.given_name}</b>
                        </Typography>
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to="/login"
                  sx={{ px: 0 }}
                >
                  <Typography variant="h6" align="left">
                    <b>Sign in</b>
                  </Typography>
                </MenuItem>
              )}
              <Divider
                sx={{
                  margin: 0,
                  backgroundColor: "var(--mintGreenSolid)",
                }}
              />
              {categoryMenuData &&
                categoryMenuData.map((category, index) => {
                  return (
                    <div key={index}>
                      <Accordion
                        square
                        expanded={expanded === index}
                        onChange={handleChange(index)}
                      >
                        <AccordionSummary>
                          <VerticallyCenterDiv>
                            <Typography
                              variant="h6"
                              className={"roboto-bold"}
                            >
                              {category.name}
                            </Typography>
                            {expanded === index ? (
                              <ExpandLess fontSize={"large"} />
                            ) : (
                              <ExpandMore fontSize={"large"} />
                            )}
                          </VerticallyCenterDiv>
                        </AccordionSummary>
                        <StyledAccordianDetails>
                          <a
                            href={"/shop/category/" + category.id + "/" + category.name}
                            style={{
                              textDecoration: "none",
                              color: "black",
                            }}
                          >
                            <Typography
                              variant="h7"
                              className={"roboto-regular"}
                            >
                              {/* {category.description} */}
                              View All
                            </Typography>
                          </a>
                        </StyledAccordianDetails>
                        {category &&
                          category.hasOwnProperty("subcategories") &&
                          category.subcategories &&
                          category.subcategories.map(
                            (subcategory, index) => {
                              return (
                                <StyledAccordianDetails key={index}>
                                  <a
                                    href={
                                      "/shop/category/" +
                                      category.id +
                                      "/" +
                                      category.name +
                                      "/" +
                                      subcategory.id +
                                      "/" +
                                      subcategory.name
                                    }
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                  >

                                    <Typography
                                      variant="h7"
                                      className={"roboto-regular"}
                                    >
                                      {subcategory.name}
                                    </Typography>
                                  </a>
                                </StyledAccordianDetails>
                              );
                            }
                          )}
                      </Accordion>
                      <Divider
                        sx={{
                          margin: 0,
                          backgroundColor: "var(--mintGreenSolid)",
                        }}
                      />
                    </div>
                  );
                })}
              {userAttributes && (
                <MenuItem
                  onClick={() => {
                    handleCloseNavMenu();
                    signOut();
                  }}
                  sx={{ px: 0 }}
                >
                  <Typography
                    variant="h6"
                    className={"roboto-medium"}
                    align="left"
                  >
                    <b>Sign Out</b>
                  </Typography>
                </MenuItem>
              )}
            </Box>
          </Popover>
        </Box>
      </Box>
    </div>
  );
}

export default MobileMenu;
