import { getSessionJwt, getJsonCache, setJsonCache } from "./utilities";
const {
  NODE_API_ENDPOINT
} = require("./constants");

async function getCouponsByCouponCode(id, callback) {
  let requestURL = NODE_API_ENDPOINT + "/get-coupons-by-coupon-code?id=" + id;

  fetch(requestURL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function getCouponsByCouponCodeAwait(id) {
  let requestURL = NODE_API_ENDPOINT + "/get-coupons-by-coupon-code?id=" + id;

  const response = await fetch(requestURL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
}

async function getCouponsBySellerId(id, callback) {
  let requestURL = NODE_API_ENDPOINT + "/get-coupons-by-seller-id?id=" + id;

  fetch(requestURL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function getSitewideCoupons(callback) {
  const jwtToken = await getSessionJwt();
  
  let requestURL = NODE_API_ENDPOINT + "/get-sitewide-coupons";

  fetch(requestURL, {
    method: "GET",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function createCoupon(sellerID, skuIDs, maxRedemptions, couponType, percentOff, amountOff, couponCode, sellerCreated, expirationDateUnixTimestamp, callback) {
  const jwtToken = await getSessionJwt();
  const reqBody = {
    sellerID,
    skuIDs: skuIDs,
    maxRedemptions: maxRedemptions,
    couponType: couponType,
    percentOff: percentOff,
    amountOff: amountOff,
    couponCode: couponCode,
    sellerCreated: sellerCreated,
    expirationDateUnixTimestamp: expirationDateUnixTimestamp,
  }
  fetch(NODE_API_ENDPOINT + "/create-coupon-by-sku-ids", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
  .then((response) => response.json())
  .then(
    (data) => callback(data),
    (error) => {
      callback("Failed");
      console.log(error);
    }
  );
}

async function createSitewideCoupon(maxRedemptions, couponType, percentOff, amountOff, couponCode, expirationDateUnixTimestamp, callback) {
  const jwtToken = await getSessionJwt();
  const reqBody = {
    maxRedemptions: maxRedemptions,
    couponType: couponType,
    percentOff: percentOff,
    amountOff: amountOff,
    couponCode: couponCode,
    expirationDateUnixTimestamp: expirationDateUnixTimestamp,
  }
  fetch(NODE_API_ENDPOINT + "/create-sitewide-coupon", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
  .then((response) => response.json())
  .then(
    (data) => callback(data),
    (error) => {
      callback("Failed");
      console.log(error);
    }
  );
}

async function updateCoupon(sellerID, couponID, couponName, callback) {
  const jwtToken = await getSessionJwt();
  const reqBody = {
    sellerID, 
    couponID: couponID,
    couponName: couponName,
  }
  fetch(NODE_API_ENDPOINT + "/update-coupon", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
  .then((response) => response.json())
  .then(
    (data) => callback(data),
    (error) => {
      callback("Failed");
      console.log(error);
    }
  );
}

async function deleteCoupon(sellerID, couponID, callback) {
  const jwtToken = await getSessionJwt();
  const reqBody = {
    sellerID, 
    couponID: couponID,
  }
  fetch(NODE_API_ENDPOINT + "/delete-coupon", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
  .then((response) => response.json())
  .then(
    (data) => callback(data),
    (error) => {
      callback("Failed");
      console.log(error);
    }
  );
}

async function deleteSitewideCoupon(couponID, callback) {
  const jwtToken = await getSessionJwt();
  const reqBody = {
    couponID: couponID,
  }
  fetch(NODE_API_ENDPOINT + "/delete-sitewide-coupon", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
  .then((response) => response.json())
  .then(
    (data) => callback(data),
    (error) => {
      callback("Failed");
      console.log(error);
    }
  );
}


export { 
  getCouponsByCouponCode, 
  getCouponsByCouponCodeAwait, 
  getCouponsBySellerId, 
  getSitewideCoupons,
  createCoupon, 
  updateCoupon, 
  deleteCoupon,
  deleteSitewideCoupon,
  createSitewideCoupon,
};
