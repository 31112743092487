import * as React from 'react';
import { Container, Typography, Button, Checkbox, FormGroup, FormControl, FormLabel, Radio, RadioGroup, FormControlLabel, TextField, ButtonBase, Grid, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import "react-multi-carousel/lib/styles.css";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useHistory, useParams } from "react-router-dom";
import { getUserByID, updateUser } from '../util/users';
import { Auth } from "aws-amplify";
import MuiPhoneNumber from 'material-ui-phone-number';
import toast, { Toaster } from 'react-hot-toast';
import DeactivateConfirmPopup from './DeactivateConfirmPopup';

const useStyles = makeStyles({
  rightItemMargin: {
    margin: "auto 50px",
  },
  rightGridItem: {
    paddingLeft: 50,
  },
  gridItems: {
    marginTop: "var(--dividerHeight)",
  },
  borderSection: {
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 5,
    padding: "24px"
  },
  sectionColor: {
    color: "#EE907B"
  },
  bodyText: {
    fontSize: "1.1rem",
    marginBottom: "4px"
  },
  editText: {
    textDecoration: "underline",
    fontSize: "1.1rem"
  },
  cartButtonCenter: {
    display: "flex",
    justifyContent: "center",
  },
  cartButton: {
    textTransform: "none",
    borderRadius: 10,
    // minWidth: "120px",
    padding: "8px 32px",
    borderColor: "var(--coral)",
    backgroundColor: "var(--coral)",
    color: "white",
    "&:hover": {
      borderColor: "#000000",
      backgroundColor: "var(--coral)",
      color: "black"
    },
  },
  cartButtonText: {
    // fontSize: "1.15rem",
  },
  cancelButton: {
    textTransform: "none",
    borderRadius: 10,
    // minWidth: "120px",
    padding: "8px 32px",
    border: "solid 1px",
    borderColor: "lightGray",
    color: "var(--coral)",
    "&:hover": {
      borderColor: "lightGray",
      backgroundColor: "lightGray",
      color: "black"
    },
  },
  cancelButtonText: {
    // fontSize: "1.15rem",

  },
});


const MySettings = (props) => {
  const classes = useStyles();

  const [userData, setUserData] = React.useState(false);

  // Toggles the edit forms
  const [editName, setEditName] = React.useState(false);
  const [editFirstName, setEditFirstName] = React.useState(false);
  const [editLastName, setEditLastName] = React.useState(false);
  const [editEmail, setEditEmail] = React.useState(false);
  const [editPhone, setEditPhone] = React.useState(false);
  const [editBirthday, setEditBirthday] = React.useState(false);
  const [isAddingAddr, setIsAddingAddr] = React.useState(false);
  const [editGender, setEditGender] = React.useState(false);
  const [deactivatePopup, setDeactivatePopup] = React.useState(false);

  // Form data user input
  const [newName, setNewName] = React.useState(userData?.username);
  const [newFirstName, setNewFirstName] = React.useState('');
  const [newLastName, setNewLastName] = React.useState(userData?.userLastName);
  const [newEmail, setNewEmail] = React.useState(userData?.userEmail);
  const [newPhone, setNewPhone] = React.useState(userData?.userPhoneNumber);
  const [newBirthday, setNewBirthday] = React.useState("");
  const [newAddress, setNewAddress] = React.useState({
    "firstName": "",
    "lastName": "",
    "address1": "",
    "address2": "",
    "city": "",
    "state": "",
    "zipCode": "",
    "countryCode": "",
    "default": false,
  });
  const [addresses, setAddresses] = React.useState([]);
  const [newGender, setNewGender] = React.useState("");

  // Password stuff - not yet implemented
  const [editPassword, setEditPassword] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [newPassword2, setNewPassword2] = React.useState("");
  const [updating, setUpdating] = React.useState(false);
  //const [updateFailed, setUpdateFailed] = React.useState(false);


  // Address form
  const handleAddressInput = (field, value) => {
    const temp = newAddress;
    temp[field] = value;
    setNewAddress(temp);
  };

  function formatDateToReadable(input) {
    if (!input) return null;
    input = input.replace(/\-/g, '/');
    var datePart = input.match(/\d+/g);
    const year = datePart[0]; // get only two digits
    const month = datePart[1];
    const day = datePart[2];
    return day + '/' + month + '/' + year;
  }

  function formatDateToSql(input) {
    if (!input) return null;
    input = input.replace(/\//g, '-');
    return input.split("-").reverse().join("-");
  }

  // const AddressForm = () => {
  //   return (
  //     <>
  //     <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
  //       <Grid item xs={12} sm={6}>
  //         <TextField
  //           label={"First Name"}
  //           variant="filled" 
  //           onChange={(e) => {handleAddressInput("firstName", e.target.value);}} 
  //           fullWidth
  //         />
  //       </Grid>
  //       <Grid item xs={12} sm={6}>
  //         <TextField
  //           label={"Last Name"}
  //           variant="filled" 
  //           onChange={(e) => {handleAddressInput("lastName", e.target.value);}} 
  //           fullWidth
  //         />
  //       </Grid>
  //       <Grid item xs={12}>
  //         <TextField
  //           label={"Address"}
  //           variant="filled" 
  //           onChange={(e) => {handleAddressInput("address1", e.target.value);}} 
  //           fullWidth
  //         />
  //       </Grid>
  //       <Grid item xs={6}>
  //         <TextField
  //           label={"Apt, Suite, etc."}
  //           variant="filled" 
  //           onChange={(e) => {handleAddressInput("address2", e.target.value);}} 
  //           fullWidth
  //         />
  //       </Grid>
  //       <Grid item xs={6}>
  //         <TextField
  //           label={"City"}
  //           variant="filled" 
  //           onChange={(e) => {handleAddressInput("city", e.target.value);}} 
  //           fullWidth
  //         />
  //       </Grid>
  //       <Grid item xs={6} sm={4}>
  //         <TextField
  //           label={"Country/Region"}
  //           variant="filled" 
  //           onChange={(e) => {handleAddressInput("countryCode", e.target.value);}} 
  //           fullWidth
  //         />
  //       </Grid>
  //       <Grid item xs={6} sm={4}>
  //         <TextField
  //           label={"State"}
  //           variant="filled" 
  //           onChange={(e) => {handleAddressInput("state", e.target.value);}} 
  //           fullWidth         
  //          />
  //       </Grid>
  //       <Grid item xs={8} sm={4}>
  //         <TextField
  //           label={"ZIP Code"}
  //           variant="filled" 
  //           onChange={(e) => {handleAddressInput("zipCode", e.target.value);}} 
  //           fullWidth
  //         />
  //       </Grid>
  //       <Grid item xs={1} sm={1}>
  //         <FormGroup>
  //           <FormControlLabel 
  //             control={
  //               <Checkbox onChange={e => {
  //                 handleAddressInput("default", e.target.value == 'on' ? true : false);
  //               }}
  //               />
  //             } label="Default Address" 
  //           />
  //         </FormGroup>
  //       </Grid>
  //     </Grid>
  //     <div style={{marginTop: "16px", display: "flex", flexDirection: "row"}}>
  //       <Button
  //           variant="filled"
  //           onClick={() => setIsAddingAddr(!isAddingAddr)}
  //           disableRipple
  //           className={classes.cancelButton}
  //       >
  //         <Typography className={classes.cancelButtonText}>
  //             <b>Cancel</b>
  //         </Typography>
  //       </Button>
  //       <div style={{width: "8px"}}></div>
  //       <Button 
  //           variant="outlined"
  //           onClick={() => {setIsAddingAddr(!isAddingAddr); addShippingAddress(newAddress);}}
  //           disableRipple
  //           className={classes.cartButton}
  //       >
  //         <Typography className={classes.cartButtonText}>
  //              <b>Save</b>
  //          </Typography>
  //        </Button>
  //      </div>
  //     </>
  //   );
  // }

  function handleUpdateUserApi(apiReqBody) {
    updateUser(apiReqBody, (data) => {
      if (data?.results?.success) {
        setUpdating(false);
        toast.success('Successfully updated user info!')
      } else {
        return;
      }
    });
  }

  //Update user details
  const updateUserInfo = (name, value) => {
    setUpdating(true);

    const newUserData = { ...userData };
    newUserData[name] = value;
    setUserData(newUserData);
    const apiReqBody = { ...newUserData };
    apiReqBody['userBirthday'] = apiReqBody['userBirthday'] ? formatDateToSql(apiReqBody['userBirthday']) : null;
    //check and see that first and last name are each atleast 2 characters
    if (apiReqBody['userFirstName']?.length < 2 || apiReqBody['userLastName']?.length < 2) {
      toast.error('First and last name must be at least 2 characters long');
      setUpdating(false);
      return;
    }
    handleUpdateUserApi(apiReqBody)
  }

  // const addShippingAddress = (value) => {
  //   const newUserShippingAddresses = [...userData['userShippingAddresses']];
  //   if (value.default == true) {
  //     newUserShippingAddresses.forEach(function(a) {
  //       a.default = false;
  //     });
  //   }
  //   newUserShippingAddresses.push(value);
  //   updateUserInfo('userShippingAddresses', newUserShippingAddresses);
  // }

  // Get User details by ID for display on account settings
  React.useEffect(() => {
    if (!userData) {
      Auth.currentAuthenticatedUser().then((user) => {
        getUserByID(user.attributes.sub, (data) => {
          if (data) {
            data['userBirthday'] = data['userBirthday'] ? formatDateToReadable(data['userBirthday'].split('T')[0]) : null;
            setUserData(data);
          } else {
            console.log("Can't find user!")
          }
        });
      }
      );
    } else {
      console.log('Failed to load user');
    }
  }, []);

  //TODO: ADD FUNCTIONALITY
  // const handleBuyerChangeActivation = async () => {
  //   if (!isLoading && isStoreActive !== null) {
  //     setIsLoading(true);
  //     if (isStoreActive === true) {
  //       deactivateSeller(sellerIDparam, (data) => {
  //         if (!(data === "Failed")) {
  //           history.go(0);
  //           setIsLoading(false);
  //         } else {
  //           alert("Failed to deactivate!")
  //           setIsLoading(false);
  //         }
  //       });
  //     } else if (isStoreActive === false) {
  //       //TODO: add re-activation
  //       // activateSeller(sellerIDparam, (data) => {
  //       //   if (!(data === "Failed")) {
  //       //     history.go(0);
  //       //     setIsLoading(false);
  //       //   } else {
  //       //     alert("Failed to activate!")
  //       //     setIsLoading(false);
  //       //   }
  //       // });
  //       setIsLoading(false);
  //     }
  //   }
  // };


  return (
    <Container maxWidth="xl">
      <DeactivateConfirmPopup deactivatePopupView={deactivatePopup} setDeactivatePopup={setDeactivatePopup}/>
      <Toaster
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <h1 style={{ textAlign: "center" }}>Account Settings</h1>
      <h2 className={classes.sectionColor}>Sign In Details</h2>
      <div className={classes.borderSection}>
        <Typography className={clsx("roboto-bold")} variant="h6">User</Typography>
        {editName
          ? <>
            <div>
              <TextField defaultValue={userData?.username} variant="filled" onChange={(e) => { setNewName(e.target.value); }} fullWidth label="Username"></TextField>
            </div>
            <div style={{ marginTop: "16px", display: "flex", flexDirection: "row" }}>
              <Button
                variant="filled"
                onClick={() => setEditName(!editName)}
                disableRipple
                className={classes.cancelButton}
              >
                <Typography className={classes.cancelButtonText}>
                  <b>Cancel</b>
                </Typography>
              </Button>
              <div style={{ width: "8px" }}></div>
              <Button
                variant="outlined"
                onClick={() => { setEditName(!editName); updateUserInfo("username", newName) }}
                disableRipple
                className={classes.cartButton}
              >
                <Typography className={classes.cartButtonText}>
                  <b>Save</b>
                </Typography>
              </Button>
            </div>
          </>
          :
          <>
            <Typography className={clsx("roboto", classes.bodyText)} >{userData?.username}</Typography>
            {/* Users cannot edit their username */}
            {/* <Typography className={clsx("roboto", classes.bodyText)} >{userData?.username}</Typography>
        <ButtonBase onClick={() => setEditName(!editName)}>
            <Typography className={clsx("roboto", classes.editText)} >edit</Typography>
        </ButtonBase> */}
          </>
        }
      </div>
      <br></br>
      <div className={classes.borderSection}>
        <Typography className={clsx("roboto-bold")} variant="h6">Password</Typography>


        {/*EDIT PASSWORD*/}
        {/* {editPassword ? 
        <>
        <div>
          <TextField variant="filled" onChange={setOldPassword} fullWidth label={"Enter old password"}></TextField>
          <div style={{height: "16px"}}/>
          <TextField variant="filled" onChange={setNewPassword} fullWidth label={"Enter new password"}></TextField>
          <div style={{height: "16px"}}/>
          <TextField variant="filled" onChange={setNewPassword2} fullWidth label={"Confirm new password"}></TextField>
        </div>
        <div style={{marginTop: "16px", display: "flex", flexDirection: "row"}}>
        <Button
          variant="filled"
          onClick={() => setEditPassword(!editPassword)}
          disableRipple
          className={classes.cancelButton}
          >
          <Typography className={classes.cancelButtonText}>
              <b>Cancel</b>
          </Typography>
          </Button>
        <div style={{width: "8px"}}></div>
          <Button
          variant="outlined"
          onClick={() => setEditPassword(!editPassword)}
          disableRipple
          className={classes.cartButton}
          >
          <Typography className={classes.cartButtonText}>
              <b>Save</b>
          </Typography>
          </Button>
          </div>
        </>
        :
        <>
        <TextField 
        type="password" 
        defaultValue={userData?.password || ""} 
        variant="standard"
        InputProps={{
            readOnly: true,
            disableUnderline: true
        }} />
        <div> */}
        <ButtonBase>
          <Link href="/login/reset" color="inherit">
            Change Password
          </Link>
        </ButtonBase>
        {/* </div>       
        </>
        } */}
      </div>
      <br></br>
      <div className={classes.borderSection}>
        <Typography className={clsx("roboto-bold")} variant="h6">Name</Typography>
        {/*EDIT FIRST NAME*/}
        {editFirstName ?
          <>
            <div>
              {/* <TextField variant="filled" onChange={handleNewFirstName} fullWidth label={"First Name"}></TextField> */}
              <TextField defaultValue={userData?.userFirstName} variant="filled" onChange={(e => { setNewFirstName(e.target.value); })} fullWidth label={"First Name"}></TextField>
            </div>
            <div style={{ marginTop: "16px", display: "flex", flexDirection: "row" }}>
              <Button
                variant="filled"
                onClick={() => setEditFirstName(!editFirstName)}
                disableRipple
                className={classes.cancelButton}
              >
                <Typography className={classes.cancelButtonText}>
                  <b>Cancel</b>
                </Typography>
              </Button>
              <div style={{ width: "8px" }}></div>
              <Button
                id="firstname"
                variant="outlined"
                onClick={() => { updateUserInfo("userFirstName", newFirstName); setEditFirstName(!editFirstName) }}
                disableRipple
                className={classes.cartButton}
              >
                <Typography className={classes.cartButtonText}>
                  <b>Save</b>
                </Typography>
              </Button>
            </div>
          </>
          :
          <><Typography className={clsx("roboto", classes.bodyText)} >First: {userData.userFirstName || 'Empty'}</Typography>
            <ButtonBase onClick={() => setEditFirstName(!editFirstName)}>
              <Typography className={clsx("roboto", classes.editText)} >edit</Typography>
            </ButtonBase>
          </>}

        <div style={{ height: "16px" }} />
        {/*EDIT LAST NAME*/}
        {editLastName ?
          <>
            <div>
              <TextField defaultValue={userData?.userLastName} variant="filled" onChange={(e) => { setNewLastName(e.target.value) }} fullWidth label={"Last Name"}></TextField>
            </div>
            <div style={{ marginTop: "16px", display: "flex", flexDirection: "row" }}>
              <Button
                variant="filled"
                onClick={() => setEditLastName(!editLastName)}
                disableRipple
                className={classes.cancelButton}
              >
                <Typography className={classes.cancelButtonText}>
                  <b>Cancel</b>
                </Typography>
              </Button>
              <div style={{ width: "8px" }}></div>
              <Button
                variant="outlined"
                onClick={() => { updateUserInfo("userLastName", newLastName); setEditLastName(!editLastName) }}
                disableRipple
                className={classes.cartButton}
              >
                <Typography className={classes.cartButtonText}>
                  <b>Save</b>
                </Typography>
              </Button>
            </div>
          </>
          :
          <>
            <Typography className={clsx("roboto", classes.bodyText)} >Last: {userData?.userLastName || 'Empty'}</Typography>
            <ButtonBase onClick={() => setEditLastName(!editLastName)}>
              <Typography className={clsx("roboto", classes.editText)} >edit</Typography>
            </ButtonBase>
          </>
        }

      </div>
      <br></br>
      <h2 className={classes.sectionColor}>My Profile</h2>
      <div className={classes.borderSection}>
        <Typography className={clsx("roboto-bold")} variant="h6">Email</Typography>
        {/*EDIT EMAIL*/}
        {editEmail ?
          <>
            <div>
              <TextField defaultValue={userData?.userEmail} variant="filled" onChange={(e) => { setNewEmail(e.target.value) }} fullWidth label={"Email"}></TextField>
            </div>
            <div style={{ marginTop: "16px", display: "flex", flexDirection: "row" }}>
              <Button
                variant="filled"
                onClick={() => setEditEmail(!editEmail)}
                disableRipple
                className={classes.cancelButton}
              >
                <Typography className={classes.cancelButtonText}>
                  <b>Cancel</b>
                </Typography>
              </Button>
              <div style={{ width: "8px" }}></div>
              <Button
                variant="outlined"
                onClick={() => { updateUserInfo("userEmail", newEmail); setEditEmail(!editEmail); }}
                disableRipple
                className={classes.cartButton}
              >
                <Typography className={classes.cartButtonText}>
                  <b>Save</b>
                </Typography>
              </Button>
            </div>
          </>
          :
          <>
            <Typography className={clsx("roboto", classes.bodyText)} >{userData?.userEmail || 'Empty'}</Typography>
            {/* <ButtonBase disabled={true} onClick={() => setEditEmail(!editEmail)}>
              <Typography className={clsx("roboto", classes.editText)} >edit</Typography>
            </ButtonBase> */}
          </>
        }
      </div>
      <br></br>
      <div className={classes.borderSection}>
        <Typography className={clsx("roboto-bold")} variant="h6">Phone Number</Typography>

        {/*EDIT PHONE*/}
        {editPhone ?
          <>
            <div>
              <MuiPhoneNumber defaultCountry={'us'} onChange={(value) => { setNewPhone(value) }} />
            </div>
            <div style={{ marginTop: "16px", display: "flex", flexDirection: "row" }}>
              <Button
                variant="filled"
                onClick={() => setEditPhone(!editPhone)}
                disableRipple
                className={classes.cancelButton}
              >
                <Typography className={classes.cancelButtonText}>
                  <b>Cancel</b>
                </Typography>
              </Button>
              <div style={{ width: "8px" }}></div>
              <Button
                variant="outlined"
                onClick={() => {
                  // Phone input only takes in +###########, but the value is +1 (###) ###-#### 
                  const formattedDate = newPhone.replace(/\(|\)/g, '').replace('-', '').replace(/ /g, '');
                  updateUserInfo("userPhoneNumber", formattedDate);
                  setEditPhone(!editPhone)
                }}
                disableRipple
                className={classes.cartButton}
              >
                <Typography className={classes.cartButtonText}>
                  <b>Save</b>
                </Typography>
              </Button>
            </div>
          </>
          :
          <>
            <MuiPhoneNumber disableAreaCodes fullWidth defaultCountry={'us'} disabled={true}
              value={userData?.userPhoneNumber}
            />
            {/* <div style={{ display: "flex", columnGap: "32px", alignItems: "center" }}>
              <ButtonBase onClick={() => setEditPhone(!editPhone)}>
                <Typography className={clsx("roboto", classes.editText)} >edit</Typography>
              </ButtonBase>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox onChange={e => {
                      updateUserInfo("optInTextMsgs", !userData?.optInTextMsgs);
                    }}
                      checked={userData?.optInTextMsgs || false}
                    />
                  } label="Sign up for text alerts!" />
              </FormGroup>
            </div> */}

          </>
        }

      </div>
      <br></br>
      <div className={classes.borderSection}>
        <Typography className={clsx("roboto-bold")} variant="h6">Birthday</Typography>
        {editBirthday ?
          <div style={{ display: "flex", columnGap: "32px", alignItems: "center" }}>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select a date"
                  disableFuture
                  value={newBirthday}
                  onChange={(value => {
                    const formattedDate = `${new String(value.$M + 1).padStart(2, '0')}/${new String(value.$D).padStart(2, '0')}/${new String(value.$y).padStart(4, '0')}`;
                    setNewBirthday(formattedDate);
                  })}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className={classes.cartButtonCenter}>
              <Button
                variant="outlined"
                disableRipple
                className={classes.cartButton}
              >
                <div style={{ width: "100%", height: "100%" }}>
                  <Typography className={classes.cartButtonText} onClick={() => { updateUserInfo("userBirthday", newBirthday); setEditBirthday(!editBirthday) }}>
                    <b>Save</b>
                  </Typography>
                </div>
              </Button>
            </div>
          </div>
          :
          <>
            <Typography className={clsx("roboto", classes.bodyText)} >{userData?.userBirthday ? userData.userBirthday : "Add now!"}</Typography>
            <ButtonBase onClick={() => setEditBirthday(!editBirthday)}>
              <Typography className={clsx("roboto", classes.editText)} >edit</Typography>
            </ButtonBase>
          </>}

        <div className={classes.sectionColor} style={{ marginTop: 5, marginBottom: 3 }}>
          <b>*enter to get your birthday reward points!</b>
        </div>
      </div>
      <br></br>
      <div className={classes.borderSection}>
        <Typography className={clsx("roboto-bold")} variant="h6">Gender</Typography>
        {editGender ?
          <>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={userData?.userGender || 'female'}
                onChange={(e) => { setNewGender(e.target.value) }}
                name="radio-buttons-group"
                row
              >
                <FormControlLabel value="Female" control={<Radio />} label="Female" />
                <FormControlLabel value="Male" control={<Radio />} label="Male" />
                <FormControlLabel value="Prefer not to say" control={<Radio />} label="Prefer not to say" />
              </RadioGroup>
            </FormControl>
            <div style={{ marginTop: "16px", display: "flex", flexDirection: "row" }}>
              <Button
                variant="filled"
                onClick={() => setEditGender(!editGender)}
                disableRipple
                className={classes.cancelButton}
              >
                <Typography className={classes.cancelButtonText}>
                  <b>Cancel</b>
                </Typography>
              </Button>
              <div style={{ width: "8px" }}></div>
              <Button
                variant="outlined"
                onClick={() => { updateUserInfo("userGender", newGender); setEditGender(!editGender) }}
                disableRipple
                className={classes.cartButton}
              >
                <Typography className={classes.cartButtonText}>
                  <b>Save</b>
                </Typography>
              </Button>
            </div>
          </>
          :
          <>
            <Typography className={clsx("roboto", classes.bodyText)} >{userData?.userGender || "Add now!"}</Typography>
            <ButtonBase onClick={() => setEditGender(!editGender)}>
              <Typography className={clsx("roboto", classes.editText)} >edit</Typography>
            </ButtonBase>
          </>
        }

      </div>
      <br></br>
      <div className={classes.borderSection}>
        <Typography className={clsx("roboto-bold")} variant="h6" sx={{color: "#d32f2f"}}>Deactivate Account</Typography>
        <Typography>If you would like to deactivate your account, you can click the deactivate button. When you are ready to join again, simply sign in using your previously created username and password.</Typography>
        <br></br>
        <Button variant='outlined' color='error' onClick={() => setDeactivatePopup(!deactivatePopup)}>Deactivate Account</Button>
      </div>
      {/* <div className={classes.borderSection}>
        <Typography className={clsx("roboto-bold")} variant="h6">Shipping Address</Typography>
        {isAddingAddr ? <AddressForm/> : 
        <Grid container spacing={12} rowSpacing={4}>
        {Array.isArray(userData.userShippingAddresses) && userData.userShippingAddresses.length > 0 ? (userData?.userShippingAddresses?.map((item, index) => {
            return (
                <Grid item key={index} xs={12} sm={12} md={6} lg={4}>
                    <Typography className={clsx("roboto", classes.bodyText)}>{item.firstName} {item.lastName}</Typography>
                    <Typography className={clsx("roboto", classes.bodyText)}>{item.address1}</Typography>
                    <Typography className={clsx("roboto", classes.bodyText)}>{item.address2}</Typography>
                    <Typography className={clsx("roboto", classes.bodyText)}>{item.city}, {item.state} {item.zipCode}</Typography>        
                    <Typography className={clsx("roboto", classes.bodyText)}>{item.countryCode}</Typography>                                        
                    <div style={{display: "flex", columnGap: "32px", alignItems: "center"}}>
                    <ButtonBase onClick={() => {
                      const newUserShippingAddresses = [...userData.userShippingAddresses]
                      newUserShippingAddresses.splice(index, 1);
                      if (newUserShippingAddresses.length > 0 && !newUserShippingAddresses.find(a => a.default)) {
                        newUserShippingAddresses[0].default = true;
                      }
                      updateUserInfo("userShippingAddresses", newUserShippingAddresses)
                      }}>
                      <Typography className={clsx("roboto", classes.editText)} >remove</Typography>
                    </ButtonBase>    
                    <FormGroup>
                      <FormControlLabel label="Default" control={<Checkbox checked={item.default} label="Default" onChange={e => {
                        const newUserShippingAddresses = [...userData.userShippingAddresses]
                        newUserShippingAddresses.forEach(function(a) {
                          a.default = false;
                        });
                        newUserShippingAddresses[index].default = true;
                        updateUserInfo("userShippingAddresses", newUserShippingAddresses)
                      }} />} />
                    </FormGroup>
                    </div> 
                </Grid>
            )
        })) : <div></div>}
        <Grid item xs={12} sm={12} md={6} lg={4} >
        <div style={{display: "flex", alignItems: "flex-end", height: "100%"}}>
          <ButtonBase onClick={() => {
            setNewAddress({
              "firstName": "",
              "lastName": "",
              "address1": "",
              "address2": "",
              "city": "",
              "state": "",
              "zipCode" : "",
              "countryCode": "",
              "default": false,
            })
            setIsAddingAddr(!isAddingAddr)
            }}>
            <Typography className={clsx("roboto", classes.editText)} >add new</Typography>
          </ButtonBase>    
        </div>
        </Grid>
       </Grid>
        }
        
      </div> 
      <br></br>
      <div className={classes.borderSection}>
        <Typography className={clsx("roboto-bold")} variant="h6">Payment Methods</Typography>
        <div style={{height: "16px"}}/>
        <Typography className={clsx("roboto", classes.bodyText)} ><b>VISA</b> ending in </Typography>
        <div style={{height: "16px"}}/>
        <Typography className={clsx("roboto", classes.bodyText)} ><i>Billing Address</i></Typography>
        <Typography className={clsx("roboto", classes.bodyText)} >{'Empty'}</Typography>
        <Typography className={clsx("roboto", classes.bodyText)} >{'Empty'}</Typography>
        <div style={{height: "16px"}}/>

        <div style={{display: "flex", columnGap: "32px", alignItems: "center"}}>
        <Typography className={clsx("roboto", classes.editText)} >edit</Typography>
        <Typography className={clsx("roboto", classes.editText)} >remove</Typography>
        </div>      
      </div>
      <br></br> */}
      {/* <Button
        variant="outlined"
        onClick={() => updateUserInfo()}
        disableRipple
        className={classes.cartButton}>
        Save Changes
      </Button> */}
    </Container>
  );
};

// const defaultUser = {
//   username: "test",
//   password: "password",
//   userFirstName: "Jane",
//   userLastName: "Doe",
//   userEmail: "myemail@someemail.org",
//   userPhoneNumber: "123-456-7890",
//   userBirthday: "October 16, 2022",
//   userShippingAddresses: [
//       {
//           name: "Joline Mann",
//           firstLine: "7634 Camino Armee",
//           secondLine: "San Diego, CA 98556",
//           country: "United States",
//           default: true
//       },
//       {
//           name: "Charles Marsh",
//           firstLine: "5564 Cheery Creek",
//           secondLine: "Omaha, NE 78885",
//           country: "United States",
//           default: false
//       }
//   ],
//   payment: {
//       number: "3411",
//       billingPerson: "Joline F. Smith",
//       billingAddress: "444 Milagrosa Drive, San Diego CA 92037",
//   },
//   gender: "female"
// }
// const [userData, setUserData] = React.useState({});

export default MySettings;
