import React, { useState } from "react";
import {
  Typography,
  Container,
  Divider,
  Grid,
  Radio,
  ButtonBase,
  TextField,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { PurposeCategoryMasterLayout } from "../../layouts";
import useDocumentDescription from "../../hooks/useDocumentDescription";

const useStyles = makeStyles({
  headerImage: {
    display: "block",
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  cardOptionsImages: {
    objectFit: "cover",
    width: 265,
    height: 175,
    borderRadius: 10,
  },
  cardOptionsButtons: {
    padding: "10px 0",
    width: 125,
    backgroundColor: "var(--mintGreen)",
    color: "white",
    borderRadius: 10,
  },
  divMarginBottom: {
    height: "var(--dividerHeight)",
  },
  subTextDiv: {
    backgroundColor: "var(--mintGreenSolid)",
    padding: "36px 0",
  },
  sectionHeader: {
    color: "var(--mintGreen)",
    fontSize: "1.4rem",
    marginBottom: 25,
  },
  descriptionText: {
    fontSize: "0.9rem",
  },
  inputOutline: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 5,
      "&:hover fieldset": {
        borderColor: "var(--mintGreen)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--mintGreen)",
      },
    },
  },
  customInput: {
    "& .MuiInputBase-input": {
      color: "white",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#fff8", // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "#fff", // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff", // Solid underline on focus
    },
  },
  customValueDiv: {
    padding: "10px 5px",
    width: 250,
    backgroundColor: "var(--mintGreen)",
    color: "white",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
  },
});

//Josh component
const InputRow = (props) => {
  const { label, description, classes } = props;

  return (
    <Grid container item direction="row" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography>
          <b>{label}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {props.children}
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.descriptionText}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

const giftCardValues = [
  { value: 25 },
  { value: 50 },
  { value: 75 },
  { value: 100 },
  { value: 125 },
  { value: 150 },
  { value: 200 },
  { value: 250 },
  { value: 300 },
  { value: 500 },
];

const PurposerGiftCardsLayout = () => {
  const [formValues, setFormValues] = useState({
    giftCardMethod: "E-Gift-Card",
    fname: "",
    lname: "",
    email: "",
    deliveryDate: "",
    message: "",
    senderFname: "",
    senderLname: "",
    senderEmail: "",
    quantity: 0,
    giftCardValue: 0,
  });

  const classes = useStyles();
  useDocumentTitle("Buy Gift Cards, eGift Card and more for your friends, family, coworkers and more!");
  useDocumentDescription("Buy Gift Cards for your special someone. They will love our selection of high quality products that have a mission to make the world a better place.");

  const handleFormValues = (name, value) => {
    setFormValues((preValues) => {
      return { ...preValues, [name]: value };
    });
  };

  return (
    <PurposeCategoryMasterLayout>
      <Divider />

      <img src="https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/gift_cards.jpg" alt="" className={classes.headerImage} />

      <div className={classes.subTextDiv}>
        <Container>
          {/* <Typography
            style={{ marginBottom: 20 }}
            variant="body2"
            align="center"
          >
            When that special time comes around, a Purposer gift card is the
            perfect present! You and the recipient will know that you're making
            a difference with every purchase.
          </Typography>
          <Typography align="center" variant="body1">
            <b>
              Give the gift of Purposeful shopping to your family and friends.
            </b>
          </Typography> */}
          <Typography variant="h1" align="center" style={{color: "white", fontWeight: "bold", fontSize: "56px"}}>Purposer Gift Cards Coming Soon</Typography>
          <br/>
          <br/>
          <Container maxWidth="sm">
          <Typography variant="h2" align="center" style={{color: "white", fontWeight: "500", fontSize: "36px"}}>Stay tuned for Purposer gift cards so you can soon give the gift of Purposeful shopping to your family and friends!</Typography>
          </Container>
        </Container>
      </div>
      {/* <Container maxWidth="md">
        <div className={classes.divMarginBottom} />
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          align="center"
          spacing={3}
        >
          <Grid item xs>
            <div>
              <img
                src={
                  "https://d2eudfgh8kpk8j.cloudfront.net/common/thumbs/59f06316-b33b-4d97-baab-d826050c390c_thumb.jpeg"
                }
                alt="E-Gift Card"
                className={classes.cardOptionsImages}
              />
            </div>
            <div>
              <Radio
                checked={formValues.giftCardMethod === "E-Gift-Card"}
                onChange={(e) =>
                  handleFormValues("giftCardMethod", e.target.value)
                }
                value="E-Gift-Card"
                style={{
                  color: "var(--mintGreen)",
                  backgroundColor: "transparent",
                }}
              />
            </div>
            <div>
              <ButtonBase
                className={classes.cardOptionsButtons}
                onClick={(e) =>
                  handleFormValues("giftCardMethod", e.target.value)
                }
                value="E-Gift-Card"
              >
                E-Gift Card
              </ButtonBase>
            </div>
          </Grid>
          <Grid item xs>
            <div>
              <img
                src={
                  "https://d2eudfgh8kpk8j.cloudfront.net/common/thumbs/385a5ded-284d-448d-93e9-2c47f498a921_thumb.jpeg"
                }
                alt="Print at home"
                className={classes.cardOptionsImages}
              />
            </div>
            <div>
              <Radio
                checked={formValues.giftCardMethod === "Print-at-Home"}
                onChange={(e) =>
                  handleFormValues("giftCardMethod", e.target.value)
                }
                value="Print-at-Home"
                style={{
                  color: "var(--mintGreen)",
                  backgroundColor: "transparent",
                }}
              />
            </div>
            <div>
              <ButtonBase
                className={classes.cardOptionsButtons}
                onClick={(e) =>
                  handleFormValues("giftCardMethod", e.target.value)
                }
                value="Print-at-Home"
              >
                Print at Home
              </ButtonBase>
            </div>
          </Grid>
          <Grid item xs>
            <div>
              <img
                src={
                  "https://d2eudfgh8kpk8j.cloudfront.net/common/thumbs/3262df23-229e-49f7-a2ac-cecb2228b125_thumb.jpeg"
                }
                alt="Mail"
                className={classes.cardOptionsImages}
              />
            </div>
            <div>
              <Radio
                checked={formValues.giftCardMethod === "Mail"}
                onChange={(e) =>
                  handleFormValues("giftCardMethod", e.target.value)
                }
                value="Mail"
                style={{
                  color: "var(--mintGreen)",
                  backgroundColor: "transparent",
                }}
              />
            </div>
            <div>
              <ButtonBase
                className={classes.cardOptionsButtons}
                onClick={(e) =>
                  handleFormValues("giftCardMethod", e.target.value)
                }
                value="Mail"
              >
                Mail
              </ButtonBase>
            </div>
          </Grid>
        </Grid>
        <div className={classes.divMarginBottom} />
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Typography className={classes.sectionHeader}>
            Recipient Information
          </Typography>
          <InputRow label="Name" classes={classes} description="">
            <Grid container direction="row" spacing={4}>
              <Grid item xs={6}>
                <TextField
                  name="fname"
                  onChange={(e) => handleFormValues("fname", e.target.value)}
                  placeholder="First"
                  defaultValue={formValues.fname}
                  variant="outlined"
                  size="small"
                  className={classes.inputOutline}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="lname"
                  onChange={(e) => handleFormValues("lname", e.target.value)}
                  placeholder="Last"
                  defaultValue={formValues.lname}
                  variant="outlined"
                  size="small"
                  className={classes.inputOutline}
                  fullWidth
                />
              </Grid>
            </Grid>
          </InputRow>
          <InputRow
            label="Email / Mailing Address"
            classes={classes}
            description=""
          >
            <TextField
              name="email"
              onChange={(e) => handleFormValues("email", e.target.value)}
              defaultValue={formValues.email}
              variant="outlined"
              size="small"
              fullWidth
              className={classes.inputOutline}
            />
          </InputRow>
          <InputRow label="Delivery Date" classes={classes} description="">
            <TextField
              name="deliveryDate"
              type="date"
              onChange={(e) => handleFormValues("deliveryDate", e.target.value)}
              defaultValue={formValues.deliveryDate}
              variant="outlined"
              size="small"
              className={classes.inputOutline}
              fullWidth
            />
          </InputRow>
          <InputRow
            label="Message"
            classes={classes}
            description="Max 250 words. This will be printed with your gift certificate, or sent digitally on your e-card."
          >
            <TextField
              multiline
              rows={7}
              onChange={(e) => handleFormValues("message", e.target.value)}
              defaultValue={formValues.message}
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 1250 }}
              className={classes.inputOutline}
            />
          </InputRow>
          <Typography className={classes.sectionHeader}>
            Sender Information
          </Typography>
          <InputRow label="Name" classes={classes} description="">
            <Grid container direction="row" spacing={4}>
              <Grid item xs={6}>
                <TextField
                  name="senderFname"
                  onChange={(e) =>
                    handleFormValues("senderFname", e.target.value)
                  }
                  placeholder="First"
                  defaultValue={formValues.senderFname}
                  variant="outlined"
                  size="small"
                  className={classes.inputOutline}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="senderLname"
                  onChange={(e) =>
                    handleFormValues("senderLname", e.target.value)
                  }
                  placeholder="Last"
                  defaultValue={formValues.senderLname}
                  variant="outlined"
                  size="small"
                  className={classes.inputOutline}
                  fullWidth
                />
              </Grid>
            </Grid>
          </InputRow>
          <InputRow label="Email" classes={classes} description="">
            <TextField
              name="senderEmail"
              onChange={(e) => handleFormValues("senderEmail", e.target.value)}
              defaultValue={formValues.senderEmail}
              variant="outlined"
              size="small"
              fullWidth
              className={classes.inputOutline}
            />
          </InputRow>
          <InputRow label="Quantity" classes={classes} description="">
            <TextField
              name="quantity"
              type="number"
              inputProps={{ max: 20, min: 0 }}
              onChange={(e) => handleFormValues("quantity", e.target.value)}
              defaultValue={formValues.quantity}
              variant="outlined"
              size="small"
              className={classes.inputOutline}
            />
          </InputRow>
          <InputRow label="Value" classes={classes} description="">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              align="center"
              spacing={2}
            >
              {giftCardValues.map((value, index) => {
                return (
                  <Grid item xs={6}>
                    <ButtonBase
                      className={classes.cardOptionsButtons}
                      onClick={(e) =>
                        handleFormValues("giftCardValue", e.target.value)
                      }
                      value={value.value}
                    >
                      ${value.value}
                    </ButtonBase>
                  </Grid>
                );
              })}
              <Grid item xs={12}>
                <div className={classes.customValueDiv}>
                  <Typography>Custom Value $</Typography>
                  <TextField
                    name="giftCardValue"
                    onChange={(e) =>
                      handleFormValues("giftCardValue", e.target.value)
                    }
                    defaultValue={formValues.giftCardValue}
                    //Remove this once done testing
                    value={formValues.giftCardValue}
                    //------
                    size="small"
                    className={classes.customInput}
                    style={{ width: 90 }}
                  />
                </div>
              </Grid>
            </Grid>
          </InputRow>
        </Grid>
        <div className={classes.divMarginBottom} />
      </Container>
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            border: "1px solid black",
            p: 2,
            borderRadius: "15px",
            maxWidth: 600,
          }}
        >
          <Typography style={{ marginBottom: 20 }} variant="body1" align="left">
            <b>Purposer Gift Card Terms & Conditions</b>
          </Typography>
          <Typography align="left">
            Our gift cards are valid for up to 1 year upon arrival to the
            recipient. They may be used for any product on{" "}
            <a href="/">www.purposer.com</a>. They cannot be redeemed for cash
            value. No returns or refunds on gift cards. The gift cards may be
            used with other special offers, coupons, codes, etc.
          </Typography>
        </Box>
        <ButtonBase
          sx={{
            bgcolor: "var(--coral)",
            py: 1,
            px: 3,
            color: "white",
            borderRadius: 2.5,
            mt: 5,
          }}
        >
          <Typography>
            <b>ADD TO CART</b>
          </Typography>
        </ButtonBase>
      </Container> */}
      {/* <div className={classes.divMarginBottom} /> */}
    </PurposeCategoryMasterLayout>
  );
};

export default PurposerGiftCardsLayout;
