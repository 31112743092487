import { useEffect, useState } from "react";
import ManagementMasterLayout from "../MasterLayouts/ManagementMasterLayout";
import useGalleryPages from "../../hooks/useGalleryPages";
import GalleryView from "../../components/GalleryView";
import { useParams } from "react-router-dom";
import { getListingsBySeller, updateProductSequence } from "../../util/listingFunctions";
import { styled } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
} from "@mui/material";
const GalleryContainer = styled("div")({
  paddingBottom: 100,
});

function CurrentListingsLayout() {
  const { id } = useParams();
  const [productList, setProductList] = useState([]);
  const [productGallery, setProductGallery] = useState([[]]);
  const [loading, setLoading] = useState(false);
  const [updatedSequence, setUpdatedSequence] = useState(false);

  useEffect(() => {
    setLoading(true);
    getListingsBySeller(id, true, (data) => {
      let nextSequenceNumber = 0;
      for (const product of data) {
        if (!isNaN(product.sequence) && product.sequence >= nextSequenceNumber) {
          nextSequenceNumber = Number(product.sequence) + 1;
        }
        if (product.sequence == null) {
          product.sequence = Number(nextSequenceNumber);
          nextSequenceNumber++;
        }
      }
      setProductList(data);
      setLoading(false);
    });
  }, [id]);

  // Move an item in the array to the left
  function moveLeft(index) {
    if (index === 0) {
      console.warn("Can't move the first image to the left");
      return false;
    }
    const newArray = [...productList];
    const temp = newArray[index - 1];
    newArray[index - 1] = newArray[index];
    newArray[index] = temp;
    setProductList(newArray);
    setUpdatedSequence(true);
    return true;
  }

  // Move an item in the array to the right
  function moveRight(index) {
    if (index === productList.length - 1) {
      console.warn("Can't move the last image to the right");
      return false;
    }
    const newArray = [...productList];
    const temp = newArray[index + 1];
    newArray[index + 1] = newArray[index];
    newArray[index] = temp;
    setProductList(newArray);
    setUpdatedSequence(true);
    return true;
  }


  const handleUpdate = () => {
    setLoading(true);
    setUpdatedSequence(false);
    let index = 0;

    const arrayOfproductIDAndSequence = productList.map((product) => {
      index++;
      return {
        productID: product.productID,
        sequence: index,
      }
    });
    updateProductSequence(arrayOfproductIDAndSequence, id, () => {
      getListingsBySeller(id, true, (data) => {
        setProductList(data);
        setLoading(false);
      });
    });
  }


  const UpdateSequenceButton = (props) => {
    const { updatedSequence } = props;
    if (!updatedSequence) {
      return null;
    }
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          my: 3,
        }}
      >
        <ButtonBase
          onClick={handleUpdate}
          sx={{
            bgcolor: "var(--coral)",
            py: 1,
            px: 2.5,
            borderRadius: 2.5,
            color: "white",
          }}
        >
          <b>Update Product Sequence</b>
        </ButtonBase>
      </Box>
    )
  }
  // Update gallery pages whenever a filter causes the product list to update
  useGalleryPages(productList, setProductGallery, 32);

  return (
    <ManagementMasterLayout title="Current Listings">
      <GalleryContainer>
        <UpdateSequenceButton updatedSequence={updatedSequence} />
        <GalleryView
          productPages={productGallery}
          admin={true}
          showLoadingIcon={loading}
          moveLeft={moveLeft}
          moveRight={moveRight}
        />
        <UpdateSequenceButton updatedSequence={updatedSequence} />
      </GalleryContainer>
    </ManagementMasterLayout>
  );
}

export default CurrentListingsLayout;
