import { AccordionDetails, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import {
  ExpandLess,
  ExpandMore,
  Storefront,
  LocalShippingOutlined,
  ShoppingBasketOutlined,
  AnnouncementOutlined,
  EmailOutlined,
  BarChartOutlined,
  AccountBalanceOutlined,
  ListAltOutlined,
  Settings,
  Star
} from "@mui/icons-material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import boxIcon from "../img/box.png"
import starIcon from "../img/star_icon.png"

//Default Prop Values
import managementMenuJsonData from "../json/management_menu.json";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  backgroundDiv: {
    backgroundColor: "inherit",
  },
  accordionDetails: {
    paddingBottom: 5,
  },
  menuDiv: {
    display: "flex",
    alignItems: "center",
  },
  menuText: {
    fontSize: "1.3rem",
    paddingLeft: 10,
    paddingRight: 5,
  },
  submenuText: {
    paddingLeft: 45,
  },
});

const Accordion = withStyles({
  root: {
    backgroundColor: "inherit",
    border: "none",
    boxShadow: "none",
    paddingBottom: 15,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const icons = {
  "Shop Editor": <Storefront fontSize="large" />,
  Listings: <ShoppingBasketOutlined fontSize="large" />,
  "Orders & Shipping": <LocalShippingOutlined fontSize="large" />,
  "Returns": <img src={boxIcon} style={{width: "32px"}}/>,
  Inventory: <ListAltOutlined fontSize="large" />,
  Promotions: <AnnouncementOutlined fontSize="large" />,
  Marketing: <BarChartOutlined fontSize="large" />,
  Finances: <AccountBalanceOutlined fontSize="large" />,
  "Reviews": <Star fontSize="large"/>,
  "Store Settings": <Settings fontSize="large"/>
};

function ManagementMenu(props) {
  const classes = useStyles();
  const { managementMenuData = managementMenuJsonData } = props;
  const { id } = useParams();

  let initialState = managementMenuData.menuItems.map((menu) => {
    return menu.submenu && menu.submenu.length > 0;
  });
  const [expanded, setExpanded] = React.useState(initialState);

  const handleChange = (index, newExpanded) => {
    let newState = [...expanded];
    newState[index] = newExpanded;
    setExpanded(newState);
  };

  return (
    <div className={classes.backgroundDiv}>
      {managementMenuData.menuItems.map((menu, index) => {
        return (
          <Accordion
            square
            expanded={expanded[index]}
            onChange={(event, newExpanded) => {
              if (menu.submenu && menu.submenu.length > 0) {
                handleChange(index, newExpanded);
              }
            }}
            onClick={() => {
              if (menu.submenu && menu.submenu.length === 0) {
                // Redirect to link
                const linkprefix = menu.hasOwnProperty("linkPrefix")
                  ? menu.linkPrefix
                  : "";
                const newLink = linkprefix + "/seller/" + id + menu.link;
                window.location.href = newLink;
              }
            }}
            key={index}
            className={classes.accordion}
          >
            <AccordionSummary>
              <div className={classes.menuDiv}>
                {icons[menu.header]}
                <Typography className={classes.menuText}>
                  <b>{menu.header}</b>
                </Typography>
                {menu.submenu && menu.submenu.length > 0 ? (
                  expanded[index] ? (
                    <ExpandLess fontSize="large" />
                  ) : (
                    <ExpandMore fontSize="large" />
                  )
                ) : (
                  <></>
                )}
              </div>
            </AccordionSummary>
            {menu.submenu.map((submenu, index) => {
              const linkprefix = submenu.hasOwnProperty("linkPrefix")
                ? submenu.linkPrefix
                : "";
              const newLink = linkprefix + "/seller/" + id + submenu.link;
              return (
                <AccordionDetails
                  key={index}
                  className={classes.accordionDetails}
                >
                  <a href={newLink}>
                    <Typography className={classes.submenuText}>
                      {submenu.subheader}
                    </Typography>
                  </a>
                </AccordionDetails>
              );
            })}
          </Accordion>
        );
      })}
    </div>
  );
}

export default ManagementMenu;
