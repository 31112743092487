import { Typography, Container, Divider, Button, Grid } from "@mui/material";
import ToolsAndTips from "../../components/ToolsAndTips";
import SupportInfo from "../../components/SupportInfo";
import MarketingAndSales from "../../components/MarketingAndSales";
import ProductPhotoGuideline from "../../components/ProductPhotoGuideline";
import SellersFees from "../../components/SellersFees";
import Forum from "../../components/Forum";
import Blog from "../../components/Blog";
import { styled } from "@mui/material/styles";
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';

//JSON filler ----------------
import toolsAndTipsData from "../../json/tools_and_tips.json";
import addingNewProductsData from "../../json/adding_new_products_data.json";
import shippingToCustomersData from "../../json/shipping_to_customers.json";
import marketingAndSalesData from "../../json/marketing_and_sales.json";
import productPhotoGuidelineData from "../../json/product_photo_guideline.json";
import forumData from "../../json/forum.json";
import mockBlogData from "../../json/blog_posts.json";

import useDocumentTitle from "../../hooks/useDocumentTitle";
import { PurposeCategoryMasterLayout } from "../../layouts";
import useDocumentDescription from "../../hooks/useDocumentDescription";
import SellerDealBanner from "../../components/SellerDealBanner";

//---------------------------------

const HeaderDiv = styled("div")({
  backgroundColor: "var(--backgroundMintGreen)",
  // paddingTop: 60,
  paddingBottom: 60,
});

const AddingNewProductsDiv = styled("div")({
  backgroundColor: "var(--addingNewProductBackground)",
});

const ShippingToCustomersDiv = styled("div")({
  backgroundColor: "var(--shippingToCustomersBackground)",
});

const MarketingAndSalesDiv = styled("div")({
  backgroundColor: "var(--marketingBackground)",
  paddingTop: 32,
  paddingBottom: 32
});

const ForumDiv = styled("div")({
  marginTop: 40,
});

const BlogDiv = styled("div")({
  marginTop: 40,
  marginBottom: 30,
});

function SellerSupportLayout() {
  useDocumentTitle("Sell your clothes, jewelry, home goods, pet care and more on Purposer.");
  useDocumentDescription("Our team is dedicated to serving our vendors and the unique missions behind their brands. We are here to support vendors with a purposeful marketplace!");

  return (
    <PurposeCategoryMasterLayout>
      <Divider />
      <HeaderDiv>
      <SellerDealBanner/>
        <Container maxWidth="lg">
          <Typography
            align="center"
            variant="h1"
            gutterBottom
            className={"roboto-bold"}
            style={{fontSize: "62px"}}
          >
            Seller Support
          </Typography>
          <Typography
            align="center"
            variant="h2"
            className={"roboto-regular-italic"}
            style={{fontSize: "20px"}}
          >
            When you sell on Purposer, you are always protected and supported.
            Our Seller Support team is focused on addressing any concerns you
            may have or problems you may encounter. We are dedicated to
            uplifiting our sellers and their chosen causes. We can't wait to
            work with you to better the world around us.
          </Typography>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "24px" }}>
            <Button variant="contained" sx={{
              background: "var(--coral)",
              "&:hover": {
                background: "var(--coral)",
              },
            }} href="https://drive.google.com/file/d/1sveHkFEZ5cGl3Z3yMl7BUFamS9e-Ii5S/view?usp=share_link" target="_blank">Seller Handbook</Button>
          </div>
        </Container>
      </HeaderDiv>
      <ToolsAndTips toolsAndTipsData={toolsAndTipsData} />
      <AddingNewProductsDiv id="addingNewProducts">
        <SupportInfo
          header={"Adding New Products"}
          supportInfoData={addingNewProductsData}
          boldTitles
        />
      </AddingNewProductsDiv>
      <ShippingToCustomersDiv id="shippingToCustomers">
        <SupportInfo
          header={"Shipping To Customers"}
          supportInfoData={shippingToCustomersData}
        />
      </ShippingToCustomersDiv>
      <MarketingAndSalesDiv id="marketingAndSales">
        <MarketingAndSales marketingAndSalesData={marketingAndSalesData} />
      </MarketingAndSalesDiv>
      <div id="productPhotos" style={{ marginTop: 32, paddingBottom: 32 }}>
        <ProductPhotoGuideline
          productPhotoGuidelineData={productPhotoGuidelineData}
        />
      </div>
      <div id="sellersFees" >
        <SellersFees />
      </div>
      {/* <div style={{ paddingTop: 60, paddingBottom: 60, backgroundColor: "var(--shippingToCustomersBackground)", width: "100%" }}>
        <Typography align="center" variant="h3" className={"roboto-medium"}>
          Contact Us
        </Typography>
        <Typography
          align="center"
          variant="h5"
          className={"roboto-regular"}
          style={{ marginBottom: "16px" }}
        >
          Have any questions? Give us a call or an email!
        </Typography>
        <Typography
          align="center"
          variant="h5"
          className={"roboto-regular"}
          style={{ display: "flex", alignItems: "center", justifyContent: "center", columnGap: "8px" }}
        >
          <CallIcon /> 805-324-6661
        </Typography>
        <Typography
          align="center"
          variant="h5"
          className={"roboto-regular"}
          style={{ display: "flex", alignItems: "center", justifyContent: "center", columnGap: "8px" }}
        >
          <EmailIcon /> info@purposer.com
        </Typography>
      </div> */}
      <Grid container direction="row" columnSpacing={0} alignItems="center" justifyContent='center'>
        <Grid item sm={12} md={6} sx={{padding: "48px"}}>
          <Typography align="center" variant="h3" className={"roboto-medium"}>
            Contact Us
          </Typography>
          <Typography
            align="center"
            variant="h5"
            className={"roboto-regular"}
            style={{ marginBottom: "16px" }}
          >
            Have any questions? Contact us below.
          </Typography>
          <Typography
            align="center"
            variant="h5"
            className={"roboto-regular"}
            style={{ display: "flex", alignItems: "center", justifyContent: "center", columnGap: "8px" }}
          >
            <CallIcon /> 805-324-6661
          </Typography>
          <Typography
            align="center"
            variant="h5"
            className={"roboto-regular"}
            style={{ display: "flex", alignItems: "center", justifyContent: "center", columnGap: "8px" }}
          >
            <EmailIcon /> info@purposer.com
          </Typography>
        </Grid>
        <Grid item sm={12} md={6} >
          <img
            src={forumData.img}
            alt=""
            // className={classes.img}
            // ref={imgRef}
            style={{ height: "100%", width: "100%" }}
          />
        </Grid>
      </Grid>
      {/* <ForumDiv>
        <Forum forumData={forumData} />
      </ForumDiv> */}
      {/* <BlogDiv>
        <Blog blogPosts={mockBlogData} />
      </BlogDiv> */}
    </PurposeCategoryMasterLayout>
  );
}

export default SellerSupportLayout;
