import {
  ButtonBase,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import logo from "../img/bulb.png";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";

const useStyles = makeStyles({
  base: {
    color: "white",
  },
  background: {
    backgroundColor: "var(--coral)",
    overflow: "hidden",
  },
  bodyFontSize: { fontSize: "1.3rem" },
  paper: {
    padding: 15,
    maxWidth: 450,
    backgroundColor: "var(--lightMintGreen)",
    marginTop: 40,
  },
  button: {
    backgroundColor: "white",
    padding: 8,
    borderRadius: 8,
    color: "var(--coral)",
    margin: "20px auto",
    //display: "flex",
  },
  icon: { marginLeft: 20 },
});

const ThePurposer = () => {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const mobile = useMobileMediaQuery();

  return (
    <div className={classes.background}>
      <Container maxWidth="xl" sx={{ py: 2 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: "20px auto",
          }}
        >
          <img src={logo} alt="Purposer Logo" />
          <Typography
            variant={mobile ? "h4" : "h3"}
            className={classes.base}
            sx={{ marginLeft: 2, fontFamily: "Varela Round" }}
          >
            <b>The PURPOSER promise</b>
          </Typography>
        </div>
        <Grid container direction="row" columnSpacing={13} rowSpacing={3}>
          <Grid item md={6} sm={12}>
            <Typography
              variant="body1"
              className={clsx(classes.base, classes.bodyFontSize)}
            >
              <i>
                Every brand and product on our site is crafted by the most
                forward thinking, passionate and purposeful entrepreneurs around
                the globe whose life mission is to further their unique cause
                and help make the world a better place.
              </i>
            </Typography>
            <Typography
              variant="body1"
              className={clsx(classes.base, classes.bodyFontSize)}
              sx={{ marginTop: 2 }}
            >
              <i>
                Our promise to you is to shed light on each brand and further
                their purpose.
              </i>
            </Typography>
            <ButtonBase className={classes.button} href="/seller/signup">
              <Typography>SELL ON PURPOSER!</Typography>
            </ButtonBase>
          </Grid>
          <Grid item md={6} sm={12}>
            <div style={{ color: "white" }}>
              <Typography
                style={{ fontFamily: "Varela Round" }}
                variant="h4"
                align="center"
                gutterBottom
              >
                <b>What does Purposer mean?</b>
              </Typography>
              <Paper sx={{ p: 2, backgroundColor: "var(--beige)" }}>
                <Typography variant="subtitle1">
                  <b>
                    <u>Definition:</u>
                  </b>{" "}
                </Typography>
                <div style={{ display: "flex", marginBottom: 8 }}>
                  <Typography>
                    [pur{bull}puhs{bull}er]
                  </Typography>
                  <RecordVoiceOverOutlinedIcon className={classes.icon} />
                </div>
                <Divider sx={{ width: 300, backgroundColor: "black" }} />
                <Typography variant="subtitle2" gutterBottom>
                  noun
                </Typography>
                <div>
                  <ol type="1">
                    <li>
                      <ol type="a" style={{ paddingInlineStart: 20 }}>
                        <li>
                          <Typography>
                            someone who acts with a purpose oriented towards the
                            greater good in life.
                          </Typography>
                        </li>
                        <li>
                          <Typography>entrepreneur with purpose</Typography>
                        </li>
                        <li>
                          <Typography>
                            someone who brings light to others
                          </Typography>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ThePurposer;
