import {useEffect, useState} from 'react';

import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm'
import {loadStripe} from '@stripe/stripe-js';
import { createStripePaymentIntent, createStripeCheckoutSession } from "../../util/stripe"

function StripeLayout(props) {
  const { cart, coupon, checkoutInfo, taxRate, classes, cartData, selectedShippingInfo } = props;

  let subtotal = 0;
  cart.forEach(
    (cartItem) =>
      (subtotal += cartItem.quantity * cartItem.productData.productPrice)
  );
  const taxAndFees = subtotal * taxRate;

  const [ clientSecret, setClientSecret ] = useState('');
  const [ paymentIntentId, setPaymentIntentId ] = useState('');
  const [ invoiceId, setInvoiceId ] = useState('');
  const [ paymentIntent, setPaymentIntent ] = useState(null);
  const [ stripePromise, setStripePromise ] = useState(null);
  const [ loadingCheckoutSession, setLoadingCheckoutSession ] = useState(false);

  
  // useEffect(() => {
  //   createStripePaymentIntent(cart, (data) => {
  //     if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message") && data?.success == true) {
  //       setClientSecret(data.paymentIntent.client_secret) 
  //       setPaymentIntentId(data.id);
  //       console.log("Set payment intent Id to: ", data.id)
  //       console.log("Set client secret to: ", data.paymentIntent.client_secret)
  //       setPaymentIntent(data.paymentIntent);
  //       setInvoiceId(data.invoiceId);
  //     } else {
  //       console.log("Failed to create stripe payment intent!")
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   fetch("https://q2ffms2wed.execute-api.us-west-1.amazonaws.com/dev/config").then(async (r) => {
  //     const { publishableKey } = await r.json();
  //     setStripePromise(loadStripe(publishableKey));
  //   }).then(console.log("loaded pk: " + stripePromise));
  // }, []);

  // useEffect(() => {
  //   if (!loadingCheckoutSession) {
  //     setLoadingCheckoutSession(true);
  //     // Create PaymentIntent as soon as the page loads
  //     createStripeCheckoutSession(cart, (data) => {
  //       if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message") && data?.success == true) {
  //         console.log(data)
  //         window.location.href = data.url;
  //       } else {
  //         console.log("Failed to create stripe checkout session!")
  //       }
  //     });
  //   }
  // }, []);


  return (
    <>
      {/* {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret, }}> */}
          <CheckoutForm
              cart={cart}
              coupon={coupon}
              checkoutInfo={checkoutInfo}
              taxRate={taxRate}
              classes={classes} 
              cartData={cartData}
              paymentIntentId={paymentIntentId}
              invoiceId={invoiceId}
              paymentIntent={paymentIntent}
              selectedShippingInfo={selectedShippingInfo}
          />
        {/* </Elements>
      )} */}
    </>
  );
}

export default StripeLayout;