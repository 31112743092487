import { getSessionJwt, getJsonCache, setJsonCache } from "./utilities";

const {
  NODE_API_ENDPOINT
} = require("./constants");

async function getAllProducts(callback) {
  const dataName = 'getAllProducts';
  const cachedData = getJsonCache(dataName);
  if (cachedData) callback(cachedData);

  fetch(NODE_API_ENDPOINT + "/get-all-products")
    .then((response) => response.json())
    .then(
      (data) => {
        setJsonCache(dataName, data);
        callback(data)
      },
      (error) => console.log(error)
    );
}

async function getProductsBySearch(searchQuery, callback) {
  fetch(NODE_API_ENDPOINT + "/get-products-by-search?search=" + searchQuery)
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function preloadGetProductsBySearch(searchQuery, callback) {
  fetch(NODE_API_ENDPOINT + "/preload-get-products-by-search?search=" + searchQuery)
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function getReviewsByProduct(productID, callback) {
  fetch(NODE_API_ENDPOINT + "/get-product-reviews?id=" + productID)
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function getReviewsBySeller(sellerID, callback) {
  fetch(NODE_API_ENDPOINT + "/get-seller-products-reviews?id=" + sellerID)
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function getReviewsByUser(callback) {
  const jwtToken = await getSessionJwt();

  fetch(NODE_API_ENDPOINT + "/get-user-products-reviews", {
    method: "GET",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    }
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function getTodayReviews(callback) {
  const jwtToken = await getSessionJwt();

  fetch(NODE_API_ENDPOINT + "/get-today-products-reviews", {
    method: "GET",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    }
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function getThisWeekReviews(callback) {
  const jwtToken = await getSessionJwt();

  fetch(NODE_API_ENDPOINT + "/get-this-week-products-reviews", {
    method: "GET",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    }
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}


async function getCustomTimeReviews(fromDate, toDate, callback) {
  const jwtToken = await getSessionJwt();

  const reqBody = {
    fromDate: fromDate,
    toDate: toDate
  }
  fetch(NODE_API_ENDPOINT + "/get-custom-time-products-reviews", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return null;
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}


async function getDeletedTodayReviews(callback) {
  const jwtToken = await getSessionJwt();

  fetch(NODE_API_ENDPOINT + "/get-deleted-today-products-reviews", {
    method: "GET",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    }
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function getDeletedThisWeekReviews(callback) {
  const jwtToken = await getSessionJwt();

  fetch(NODE_API_ENDPOINT + "/get-deleted-this-week-products-reviews", {
    method: "GET",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    }
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}


async function getDeletedCustomTimeReviews(fromDate, toDate, callback) {
  const jwtToken = await getSessionJwt();

  const reqBody = {
    fromDate: fromDate,
    toDate: toDate
  }
  fetch(NODE_API_ENDPOINT + "/get-deleted-custom-time-products-reviews", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return null;
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function addReviewToProduct(productID, review, pictures, rating, callback) {
  const jwtToken = await getSessionJwt();

  const reqBody = {
    productID: productID,
    review: review,
    pictures: pictures,
    rating: rating
  }
  fetch(NODE_API_ENDPOINT + "/insert-product-review", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return null;
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function addAdminReviewToProduct(productID, review, pictures, rating, insertedName, reviewDate, callback) {
  const jwtToken = await getSessionJwt();

  const reqBody = {
    productID: productID,
    review: review,
    pictures: pictures,
    rating: rating,
    insertedName: insertedName,
    reviewDate: reviewDate
  }
  fetch(NODE_API_ENDPOINT + "/admin-insert-product-review", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return null;
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}


async function updateProductReview(reviewID, review, pictures, rating, callback) {
  const jwtToken = await getSessionJwt();
  const reqBody = {
    reviewID: reviewID,
    review: review,
    pictures: pictures,
    rating: rating
  }
  fetch(NODE_API_ENDPOINT + "/update-product-review", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return null;
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}


async function deleteProductReview(reviewID, callback) {
  const jwtToken = await getSessionJwt();

  const reqBody = {
    reviewID: reviewID
  }
  fetch(NODE_API_ENDPOINT + "/delete-product-review", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return null;
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function updateSellerResponseProductReview(sellerID, reviewID, sellerResponse, callback) {
  const jwtToken = await getSessionJwt();

  const reqBody = {
    sellerID, 
    reviewID: reviewID,
    sellerResponse: sellerResponse
  }

  fetch(NODE_API_ENDPOINT + "/update-seller-response-product-review", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return null;
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function changeInventory(sellerID, productID, changedOptionsArray, callback) {
  const jwtToken = await getSessionJwt();

  const reqBody = {
    sellerID, 
    productID: productID,
    changedOptionsArray: changedOptionsArray
  }
  fetch(NODE_API_ENDPOINT + "/change-product-inventory", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return null;
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

export { 
  getAllProducts, 
  changeInventory, 
  getProductsBySearch, 
  preloadGetProductsBySearch,
  getReviewsByProduct, 
  getReviewsByUser, 
  getReviewsBySeller, 
  getCustomTimeReviews, 
  getTodayReviews,
  getThisWeekReviews, 
  getDeletedTodayReviews, 
  getDeletedThisWeekReviews, 
  getDeletedCustomTimeReviews, 
  addReviewToProduct, 
  addAdminReviewToProduct,
  updateProductReview, 
  deleteProductReview, 
  updateSellerResponseProductReview,
};
