import React from "react";
import { ManagementMasterLayout } from "..";
import {
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  Avatar,
  Divider,
  TextField,
} from "@mui/material";
import NotificationTable from "../../components/NotificationTable";
import OrderNumberTable from "../../components/OrderNumberTable";
import SellerStatsChart from "../../components/SellerStatsChart";
import { makeStyles } from "@mui/styles";
import useElementWidth from "../../hooks/useElementWidth";
import sellerDashboardJsonData from "../../json/seller_dashboard.json";
import {
  getOrdersBySellerId, isToday, isYesterday, isLastYear, isThisYear, isThisMonth, isLastMonth, isWithinLast7Days, getDayLabelsForLast7Days,
  isWithinLast30Days, getNumDaysInMonth, getDayLabels, getDayLabelsForLast30Days, calcDateDifference, getRangeLabel
} from "../../util/orders";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Edit } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles({
  title: {
    fontSize: "2.5rem",
    // marginBottom: 20,
    color: "var(--coral)"
  },
  horizontalCenter: {
    display: "flex",
    justifyContent: "center",
  },
  verticallyCenter: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    textTransform: "none",
    borderRadius: 13,
    marginRight: 15,
    minHeight: 34,
  },

  rightSectionWidth: {
    paddingLeft: 65,
    paddingRight: 65,
  },
  rightSectionWidth2: {
    float: "right",
    paddingRight: 24,
  },
  rightSectionWidth3: {
    position: "static",
    top: "64px",
    float: "right",
    marginRight: "auto"
  },
  bubbleStatContainer: {
    paddingTop: 40,
    // paddingBottom: 35,
  },
  bubbleStatFilter: {
    marginBottom: 30,
    fontSize: "1.25rem",
    "& .MuiInput-underline:after": {
      border: "2px solid #000000",
    },
  },
  bubbleAvatar: {
    width: "75%",
    backgroundColor: "var(--pastelBeige)",
    color: "#000000",
  },
  rightLowerContainer: {
    // paddingTop: 40,
    marginBottom: 40,
  },
  tableSpacing: {
    marginTop: 80,
    marginBottom: 20,
  },
  bottomButtonGroup: {
    marginTop: 200,
    marginBottom: 200,
  },
});

const Bubble = (props) => {
  const { statBubbleData, avatarRef, avatarWidth, classes } = props;

  // Used to adjust text size to make sure it doesn't overflow if it is
  // too long by default
  const nameRatio = 8;
  const statRatio = 5;

  return (
    <Avatar
      className={classes.bubbleAvatar}
      ref={avatarRef}
      style={{ height: avatarWidth }}
    >
      <div>
        <Typography
          align="center"
          style={{ fontSize: avatarWidth / nameRatio }}
        >
          <i>{statBubbleData.name}</i>
        </Typography>
        <Typography
          align="center"
          style={{ fontSize: avatarWidth / statRatio }}
        >
          <b>{statBubbleData.stat}</b>
        </Typography>
      </div>
    </Avatar>
  );
};

const ShopStatsLayout = () => {
  const sellerDashboardData = sellerDashboardJsonData;
  const classes = useStyles();
  const bubbleRef = React.useRef(null);
  const [bubbleStatFilter, setBubbleStatFilter] = React.useState("week");
  const bubbleWidth = useElementWidth(bubbleRef);
  const { id: sellerIDparam } = useParams();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [orderData, setOrderData] = React.useState([]);
  const [orderFilters, setOrderFilters] = React.useState(["Today", "Yesterday", "Last 7 Days", "Last 30 Days", "This Month", "Last Month", "This Year", "Last Year", "All Time", "Custom"]);
  const [salesFilters, setSalesFilters] = React.useState(["Today", "Yesterday", "Last 7 Days", "Last 30 Days", "This Month", "Last Month", "This Year", "Last Year", "All Time", "Custom"]);

  const [selectedOrderStatsData, setSelectedOrderStatsData] = React.useState({});
  const [selectedOrderFilter, setSelectedOrderFilter] = React.useState('This Year');
  const [orderFromDate, setOrderFromDate] = React.useState({});
  const [orderToDate, setOrderToDate] = React.useState({});
  const [orderCount, setOrderCount] = React.useState(0);


  const [selectedSalesStatsData, setSelectedSalesStatsData] = React.useState({});
  const [selectedSalesFilter, setSelectedSalesFilter] = React.useState('This Year');
  const [salesFromDate, setSalesFromDate] = React.useState({});
  const [salesToDate, setSalesToDate] = React.useState({});
  const [salesAmount, setSalesAmount] = React.useState(0);

  const yearLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const dayLabels = ["12 AM", "1 AM", "2 AM", "3 AM", "4 AM", "5 AM", "6 AM", "7 AM", "8 AM", "9 AM", "10 AM", "11 AM", "12 PM", "1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM", "9 PM", "10 PM", "11 PM"];


  function assembleOrdersGraph(data, labels) {
    const structuredDataForBarGraph = {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: ["rgba(128, 170, 158, 1)"],
        },
      ]
    }
    setSelectedOrderStatsData(structuredDataForBarGraph);
  }

  function assembleSalesGraph(data, labels) {
    const structuredDataForBarGraph = {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: ["rgba(128, 170, 158, 1)"],
        },
      ]
    }
    setSelectedSalesStatsData(structuredDataForBarGraph);
  }

  // Initial data load, defaults filter to This Year
  React.useEffect(() => {
    if (!isLoaded) {
      getOrdersBySellerId(sellerIDparam, (data) => {
        let orderCountByThisYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let salesAmountByThisYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let tempOrderFromDate = new Date(data[0].orderDateTime);
        let tempOrderToDate = new Date(data[0].orderDateTime);
        let tempOrderCount = 0;
        let tempSalesAmount = 0;
        for (const order of data) {
          const orderDate = new Date(order.orderDateTime);
          if (orderDate < tempOrderFromDate) {
            tempOrderFromDate = orderDate;
          }

          if (orderDate > tempOrderToDate) {
            tempOrderToDate = orderDate;
          }

          if (isThisYear(orderDate)) {
            const currMonth = orderDate.getMonth()
            orderCountByThisYear[currMonth]++;
            salesAmountByThisYear[currMonth] += order.paymentAmount;
            tempOrderCount++;
            tempSalesAmount += order.paymentAmount;
          }
        }
        setSelectedOrderFilter("This Year");
        setSelectedSalesFilter("This Year");
        assembleOrdersGraph(orderCountByThisYear, yearLabels);
        assembleSalesGraph(salesAmountByThisYear, yearLabels);
        setOrderFromDate(tempOrderFromDate);
        setOrderToDate(tempOrderToDate);
        setSalesFromDate(tempOrderFromDate);
        setSalesToDate(tempOrderToDate);
        setOrderCount(tempOrderCount);
        tempSalesAmount = Math.round((tempSalesAmount + Number.EPSILON) * 100) / 100
        setSalesAmount(tempSalesAmount);
        setOrderData(data);
        setIsLoaded(true);
      })
    }
  }, []);



  React.useEffect(() => {
    const today = new Date();
    let tempOrderCount = 0;
    switch (selectedOrderFilter) {
      case "Today":
        let orderCountByToday = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (const order of orderData) {

          if (isToday(new Date(order.orderDateTime))) {
            const currHour = new Date(order.orderDateTime).getHours()
            orderCountByToday[currHour]++;
            tempOrderCount++;
          }
        }
        setOrderCount(tempOrderCount);
        assembleOrdersGraph(orderCountByToday, dayLabels);
        break;
      case "Yesterday":
        let orderCountByYesterday = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (const order of orderData) {

          if (isYesterday(new Date(order.orderDateTime))) {
            const currHour = new Date(order.orderDateTime).getHours()
            orderCountByYesterday[currHour]++;
            tempOrderCount++;
          }
        }
        setOrderCount(tempOrderCount);
        assembleOrdersGraph(orderCountByYesterday, dayLabels);
        break;
      case "Last 7 Days":
        let orderCountBy7Days = [0, 0, 0, 0, 0, 0, 0];

        const sevenDayLabels = getDayLabelsForLast7Days();
        for (const order of orderData) {

          if (isWithinLast7Days(new Date(order.orderDateTime))) {
            const currDay = 6 - calcDateDifference(new Date(order.orderDateTime))
            orderCountBy7Days[currDay]++;
            tempOrderCount++;
          }
        }
        setOrderCount(tempOrderCount);
        assembleOrdersGraph(orderCountBy7Days, sevenDayLabels);
        break;
      case "Last 30 Days":
        let orderCountBy30Days = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        const thirtyDayLabels = getDayLabelsForLast30Days();
        for (const order of orderData) {

          if (isWithinLast30Days(new Date(order.orderDateTime))) {
            const currDay = 29 - calcDateDifference(new Date(order.orderDateTime))
            tempOrderCount++;
            orderCountBy30Days[currDay]++;
          }
        }
        setOrderCount(tempOrderCount);
        assembleOrdersGraph(orderCountBy30Days, thirtyDayLabels);
        break;
      case "This Month":
        let orderCountByThisMonth = [];
        const numDays = getNumDaysInMonth(today);
        for (var i = 0; i < numDays; i++) {
          orderCountByThisMonth.push(0);
        }

        const monthLabels = getDayLabels(today);
        for (const order of orderData) {

          if (isThisMonth(new Date(order.orderDateTime))) {
            const currDay = new Date(order.orderDateTime).getDate() - 1
            orderCountByThisMonth[currDay]++;
            tempOrderCount++;
          }
        }
        setOrderCount(tempOrderCount);
        assembleOrdersGraph(orderCountByThisMonth, monthLabels);
        break;
      case "Last Month":
        let orderCountByLastMonth = [];
        const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        const numDaysInLastMonth = getNumDaysInMonth(lastMonth);

        for (var i = 0; i < numDaysInLastMonth; i++) {
          orderCountByLastMonth.push(0);
        }

        const lastMonthLabels = getDayLabels(lastMonth);
        for (const order of orderData) {

          if (isLastMonth(new Date(order.orderDateTime))) {
            const currDay = new Date(order.orderDateTime).getDate() - 1;
            tempOrderCount++;
            orderCountByLastMonth[currDay]++;
          }
        }
        setOrderCount(tempOrderCount);
        assembleOrdersGraph(orderCountByLastMonth, lastMonthLabels);
        break;
      case "This Year":
        let orderCountByThisYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (const order of orderData) {
          if (isThisYear(new Date(order.orderDateTime))) {
            const currMonth = new Date(order.orderDateTime).getMonth();
            tempOrderCount++;
            orderCountByThisYear[currMonth]++;
          }
        }
        setOrderCount(tempOrderCount);
        assembleOrdersGraph(orderCountByThisYear, yearLabels);
        break;
      case "Last Year":
        let orderCountByLastYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (const order of orderData) {
          if (isLastYear(new Date(order.orderDateTime))) {
            const month = new Date(order.orderDateTime).getMonth()
            tempOrderCount++;
            orderCountByLastYear[month]++;
          }
        }
        setOrderCount(tempOrderCount);
        assembleOrdersGraph(orderCountByLastYear, yearLabels);
        break;
      case "All Time":
        let orderCountAllTime = [];
        const orderYearFilters = [];
        for (const order of orderData) {
          const currYear = new Date(order.orderDateTime).getFullYear()
          if (!orderYearFilters.includes(currYear.toString())) {
            orderYearFilters.push(currYear.toString());
            orderCountAllTime.push(0);
          }
          const yearIndex = orderYearFilters.findIndex(year => year == currYear.toString())
          orderCountAllTime[yearIndex]++;
          tempOrderCount++;
        }
        setOrderCount(tempOrderCount);
        assembleOrdersGraph(orderCountAllTime.reverse(), orderYearFilters.reverse());
        break;
      case "Custom":
        let orderCount = [0];
        const tempOrderFromDate = new Date(orderFromDate);
        const tempOrderToDate = new Date(orderToDate);
        const timeFrame = [getRangeLabel(tempOrderFromDate, tempOrderToDate)];
        for (const order of orderData) {
          const orderDate = new Date(order.orderDateTime);
          if (orderDate >= tempOrderFromDate && orderDate <= tempOrderToDate) {
            orderCount[0]++;
          }
        }
        setOrderCount(orderCount[0]);
        assembleOrdersGraph(orderCount, timeFrame);
        break;
      default:
        break;
    }

  }, [selectedOrderFilter]);

  React.useEffect(() => {
    switch (selectedOrderFilter) {
      case "Custom":
        let orderCount = [0];
        const tempOrderFromDate = new Date(orderFromDate);
        const tempOrderToDate = new Date(orderToDate);
        const timeFrame = [getRangeLabel(tempOrderFromDate, tempOrderToDate)];
        for (const order of orderData) {
          const orderDate = new Date(order.orderDateTime);
          if (orderDate >= tempOrderFromDate && orderDate <= tempOrderToDate) {
            orderCount[0]++;
          }
        }
        setOrderCount(orderCount[0]);
        assembleOrdersGraph(orderCount, timeFrame);
        break;
      default:
        break;
    }

  }, [orderFromDate, orderToDate]);



  React.useEffect(() => {
    const today = new Date();
    let tempSalesAmount = 0;
    switch (selectedSalesFilter) {
      case "Today":
        let salesAmountByToday = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (const order of orderData) {

          if (isToday(new Date(order.orderDateTime))) {
            const currHour = new Date(order.orderDateTime).getHours()
            salesAmountByToday[currHour] += order.paymentAmount;
            tempSalesAmount += order.paymentAmount;
          }
        }
        tempSalesAmount = Math.round((tempSalesAmount + Number.EPSILON) * 100) / 100;
        setSalesAmount(tempSalesAmount);
        assembleSalesGraph(salesAmountByToday, dayLabels);
        break;
      case "Yesterday":
        let salesAmountByYesterday = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (const order of orderData) {

          if (isYesterday(new Date(order.orderDateTime))) {
            const currHour = new Date(order.orderDateTime).getHours()
            salesAmountByYesterday[currHour] += order.paymentAmount;
            tempSalesAmount += order.paymentAmount;
          }
        }
        tempSalesAmount = Math.round((tempSalesAmount + Number.EPSILON) * 100) / 100;
        setSalesAmount(tempSalesAmount);
        assembleSalesGraph(salesAmountByYesterday, dayLabels);
        break;
      case "Last 7 Days":
        let salesAmountBy7Days = [0, 0, 0, 0, 0, 0, 0];

        const sevenDayLabels = getDayLabelsForLast7Days();
        for (const order of orderData) {

          if (isWithinLast7Days(new Date(order.orderDateTime))) {
            const currDay = 6 - calcDateDifference(new Date(order.orderDateTime))
            salesAmountBy7Days[currDay] += order.paymentAmount;
            tempSalesAmount += order.paymentAmount;
          }
        }
        tempSalesAmount = Math.round((tempSalesAmount + Number.EPSILON) * 100) / 100;
        setSalesAmount(tempSalesAmount);
        assembleSalesGraph(salesAmountBy7Days, sevenDayLabels);
        break;
      case "Last 30 Days":
        let salesAmountBy30Days = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        const thirtyDayLabels = getDayLabelsForLast30Days();
        for (const order of orderData) {

          if (isWithinLast30Days(new Date(order.orderDateTime))) {
            const currDay = 29 - calcDateDifference(new Date(order.orderDateTime))
            tempSalesAmount += order.paymentAmount;
            salesAmountBy30Days[currDay] += order.paymentAmount;
          }
        }
        tempSalesAmount = Math.round((tempSalesAmount + Number.EPSILON) * 100) / 100;
        setSalesAmount(tempSalesAmount);
        assembleSalesGraph(salesAmountBy30Days, thirtyDayLabels);
        break;
      case "This Month":
        let salesAmountByThisMonth = [];
        const numDays = getNumDaysInMonth(today);
        for (var i = 0; i < numDays; i++) {
          salesAmountByThisMonth.push(0);
        }

        const monthLabels = getDayLabels(today);
        for (const order of orderData) {

          if (isThisMonth(new Date(order.orderDateTime))) {
            const currDay = new Date(order.orderDateTime).getDate() - 1
            salesAmountByThisMonth[currDay] += order.paymentAmount;
            tempSalesAmount += order.paymentAmount;
          }
        }
        tempSalesAmount = Math.round((tempSalesAmount + Number.EPSILON) * 100) / 100;
        setSalesAmount(tempSalesAmount);
        assembleSalesGraph(salesAmountByThisMonth, monthLabels);
        break;
      case "Last Month":
        let salesAmountByLastMonth = [];
        const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        const numDaysInLastMonth = getNumDaysInMonth(lastMonth);

        for (var i = 0; i < numDaysInLastMonth; i++) {
          salesAmountByLastMonth.push(0);
        }

        const lastMonthLabels = getDayLabels(lastMonth);
        for (const order of orderData) {

          if (isLastMonth(new Date(order.orderDateTime))) {
            const currDay = new Date(order.orderDateTime).getDate() - 1;
            tempSalesAmount += order.paymentAmount;
            salesAmountByLastMonth[currDay] += order.paymentAmount;
          }
        }
        tempSalesAmount = Math.round((tempSalesAmount + Number.EPSILON) * 100) / 100;
        setSalesAmount(tempSalesAmount);
        assembleSalesGraph(salesAmountByLastMonth, lastMonthLabels);
        break;
      case "This Year":
        let salesAmountByThisYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (const order of orderData) {
          if (isThisYear(new Date(order.orderDateTime))) {
            const currMonth = new Date(order.orderDateTime).getMonth();
            tempSalesAmount += order.paymentAmount;
            salesAmountByThisYear[currMonth] += order.paymentAmount;
          }
        }
        tempSalesAmount = Math.round((tempSalesAmount + Number.EPSILON) * 100) / 100;
        setSalesAmount(tempSalesAmount);
        assembleSalesGraph(salesAmountByThisYear, yearLabels);
        break;
      case "Last Year":
        let salesAmountByLastYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (const order of orderData) {
          if (isLastYear(new Date(order.orderDateTime))) {
            const month = new Date(order.orderDateTime).getMonth()
            tempSalesAmount += order.paymentAmount;
            salesAmountByLastYear[month] += order.paymentAmount;
          }
        }
        tempSalesAmount = Math.round((tempSalesAmount + Number.EPSILON) * 100) / 100;
        setSalesAmount(tempSalesAmount);
        assembleSalesGraph(salesAmountByLastYear, yearLabels);
        break;
      case "All Time":
        let salesAmountAllTime = [];
        const orderYearFilters = [];
        for (const order of orderData) {
          const currYear = new Date(order.orderDateTime).getFullYear()
          if (!orderYearFilters.includes(currYear.toString())) {
            orderYearFilters.push(currYear.toString());
            salesAmountAllTime.push(0);
          }
          const yearIndex = orderYearFilters.findIndex(year => year == currYear.toString())
          salesAmountAllTime[yearIndex] += order.paymentAmount;
          tempSalesAmount += order.paymentAmount;
        }
        tempSalesAmount = Math.round((tempSalesAmount + Number.EPSILON) * 100) / 100;
        setSalesAmount(tempSalesAmount);
        assembleSalesGraph(salesAmountAllTime.reverse(), orderYearFilters.reverse());
        break;
      case "Custom":
        let salesAmount = [0];
        const tempSalesFromDate = new Date(salesFromDate);
        const tempSalesToDate = new Date(salesToDate);
        const timeFrame = [getRangeLabel(tempSalesFromDate, tempSalesToDate)];
        for (const order of orderData) {
          const orderDate = new Date(order.orderDateTime);
          if (orderDate >= tempSalesFromDate && orderDate <= tempSalesToDate) {
            salesAmount[0] += order.paymentAmount;
          }
        }
        salesAmount[0] = Math.round((salesAmount[0] + Number.EPSILON) * 100) / 100;
        setSalesAmount(salesAmount[0]);
        assembleSalesGraph(salesAmount, timeFrame);
        break;
      default:
        break;
    }

  }, [selectedSalesFilter]);

  React.useEffect(() => {
    switch (selectedSalesFilter) {
      case "Custom":
        let salesAmount = [0];
        const tempSalesFromDate = new Date(salesFromDate);
        const tempSalesToDate = new Date(salesToDate);
        const timeFrame = [getRangeLabel(tempSalesFromDate, tempSalesToDate)];
        for (const order of orderData) {
          const orderDate = new Date(order.orderDateTime);
          if (orderDate >= tempSalesFromDate && orderDate <= tempSalesToDate) {
            salesAmount[0] += order.paymentAmount;
          }
        }
        salesAmount[0] = Math.round((salesAmount[0] + Number.EPSILON) * 100) / 100;
        setSalesAmount(salesAmount[0]);
        assembleSalesGraph(salesAmount, timeFrame);
        break;
      default:
        break;
    }

  }, [salesFromDate, salesToDate]);



  const handleBubbleStatOrderFilterChange = (event) => {
    setSelectedOrderFilter(event.target.value);
  };

  const handleBubbleStatSalesFilterChange = (event) => {
    setSelectedSalesFilter(event.target.value);
  };

  return (
    <ManagementMasterLayout
      title="Shop Stats"
      sellerName={sellerDashboardData.name}
      sellerPicture={sellerDashboardData.pic}
    >
      {/* The Sales Revenue Graph */}
      <Grid container justifyContent="space-between" direction="row" className={clsx(classes.bubbleStatContainer, classes.rightSectionWidth)}>
        <Grid item>
          <Typography className={classes.title}>
            <b>Sales Revenue</b>
          </Typography>
        </Grid>
        <Grid item>
          <Select
            value={selectedSalesFilter || ''}
            onChange={handleBubbleStatSalesFilterChange}
            className={clsx(classes.bubbleStatFilter)}
          >
            {salesFilters.map((option, i) => {
              return (
                <MenuItem key={option + 'menuitem'} value={option}>
                  <b>{option}</b>
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </Grid>
      {selectedSalesFilter == "Custom" ?
        <div className={clsx(classes.bubbleStatFilter, classes.rightSectionWidth2)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From Date"
              disableFuture
              value={salesFromDate}
              onChange={(value => {
                if (value.$d <= new Date(salesToDate)) {
                  const formattedDate = `${new String(value.$M + 1).padStart(2, '0')}/${new String(value.$D).padStart(2, '0')}/${new String(value.$y).padStart(4, '0')}`;
                  setSalesFromDate(formattedDate);
                }
              })}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To Date"
              disableFuture
              value={salesToDate}
              onChange={(value => {
                if (value.$d >= new Date(salesFromDate)) {
                  const formattedDate = `${new String(value.$M + 1).padStart(2, '0')}/${new String(value.$D).padStart(2, '0')}/${new String(value.$y).padStart(4, '0')}`;
                  setSalesToDate(formattedDate);
                }
              })}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div> : ""}
      <div
        className={clsx(classes.rightSectionWidth, classes.rightLowerContainer)}
      >
        {selectedSalesStatsData?.labels?.length > 0 && <SellerStatsChart salesData={selectedSalesStatsData} selectedSalesFilter={selectedSalesFilter == "Custom" ? getRangeLabel(new Date(salesFromDate), new Date(salesToDate)) : selectedSalesFilter} salesAmount={salesAmount} />}
      </div>


      {/* The Orders Graph */}

      <Grid container justifyContent="space-between" direction="row" className={clsx(classes.bubbleStatContainer, classes.rightSectionWidth)}>
        <Grid item>
          <Typography className={classes.title}>
            <b>Orders</b>
          </Typography>
        </Grid>
        <Grid item>
          <Select
            value={selectedOrderFilter || ''}
            onChange={handleBubbleStatOrderFilterChange}
            className={clsx(classes.bubbleStatFilter)}
          >
            {orderFilters.map((option, i) => {
              return (
                <MenuItem key={option + 'menuitem'} value={option}>
                  <b>{option}</b>
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </Grid>


      {selectedOrderFilter == "Custom" ?
        <div className={clsx(classes.bubbleStatFilter, classes.rightSectionWidth2)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From Date"
              disableFuture
              value={orderFromDate}
              onChange={(value => {
                if (value.$d <= new Date(orderToDate)) {
                  const formattedDate = `${new String(value.$M + 1).padStart(2, '0')}/${new String(value.$D).padStart(2, '0')}/${new String(value.$y).padStart(4, '0')}`;
                  setOrderFromDate(formattedDate);
                }
              })}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To Date"
              disableFuture
              value={orderToDate}
              onChange={(value => {
                if (value.$d >= new Date(orderFromDate)) {
                  const formattedDate = `${new String(value.$M + 1).padStart(2, '0')}/${new String(value.$D).padStart(2, '0')}/${new String(value.$y).padStart(4, '0')}`;
                  setOrderToDate(formattedDate);
                }
              })}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div> : ""}

      <div
        className={clsx(classes.rightSectionWidth, classes.rightLowerContainer)}
      >
        {selectedOrderStatsData?.labels?.length > 0 && <SellerStatsChart orderData={selectedOrderStatsData} selectedOrderFilter={selectedOrderFilter == "Custom" ? getRangeLabel(new Date(orderFromDate), new Date(orderToDate)) : selectedOrderFilter} orderCount={orderCount} />}
      </div>
    </ManagementMasterLayout>
  );
};

export default ShopStatsLayout;
