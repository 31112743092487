import React, { useState } from "react";
import { Typography, Grid, Modal, TextField, Button, FormGroup, FormControlLabel, Checkbox, Select, MenuItem, FormControl, Input, InputLabel, Divider, IconButton, Menu } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { requestRefund } from "../util/returns";
import { ExpandMore } from "@mui/icons-material";
import { outputViewProductVariationSize, round, shortenUUID } from '../util/utilities'
import { RETURN_POLICY_LIST, RETURN_BUYER_REASONS } from '../util/constants'
import OrderReturnRequestConfirmPopup from "./OrderReturnRequestConfirmPopup";

import QuantityButton from "./QuantityButton";

const useStyles = makeStyles({
    subheader: {
        color: "black",
        //fontSize: "1.25rem",
    },
    tabelLabel: {
        color: "black",
        fontWeight: "500",
        //fontSize: "1.25rem",
    },
    orderLabel: {
        color: "var(--coral)",
        fontSize: "16px"
    },
    buttonDiv: {
        display: "flex",
        justifyContent: "center"
    },
    buttonStyle: {
        backgroundColor: "var(--mintGreenSolid)",
        fontSize: "16px",
        marginTop: "8px"
    }
});

const style = {
    position: 'absolute',
    // top: '50%',
    //left: '50%',
    borderRadius: '16px',
    backgroundColor: "white",
    //transform: 'translate(-50%, -50%)',
    // display: "block",
    minWidth: "40%",
    maxHeight: "90vh",
    overflow: "auto",
    // height: "auto",
    border: "2px solid",
    zIndex: "2",
    padding: "32px",
    boxShadow: 24,
};

const styleSuccess = {
    position: 'absolute',
    // marginLeft: "auto",
    // marginRight: "auto",
    // left: "0px",
    // right: "0px",
    // borderRadius: '16px',
    // top: "25%",
    // left: { xs: "5%", sm: "5%", md: "30%" },
    backgroundColor: "white",
    //transform: 'translate(-50%, -50%)',
    // display: "block",
    maxWidth: { xs: "75%", sm: "75%", md: "40%" },
    // height: "auto",
    border: "2px solid",
    zIndex: "2",
    padding: "32px",
    boxShadow: 24,
};

const modalCenter = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

const DropdownButton = (props) => {
    // Structure of options is [{label: "", value: ""}, {label: "", value: ""}]
    const { label, options, selected, handleClick } = props;

    return (
        <FormControl>
            <Select
                value={selected || ''}
                onChange={handleClick}
                IconComponent={ExpandMore}
                sx={{
                    ".MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input":
                    {
                        pl: 2,
                        pr: 4.5,
                    },
                }}
            >
                {options &&
                    options.map((option, index) => {
                        return (
                            <MenuItem key={index} value={option.value}>
                                <Typography className={"roboto-regular"}>
                                    {option.label}
                                </Typography>
                            </MenuItem>
                        );
                    })}
            </Select>
        </FormControl>
    );
};

const ReturnSuccess = (props) => {
    const { successView, setSuccessView, setReturnView, orderDetails } = props;
    const classes = useStyles();
    const handleClose = () => { setSuccessView(false); setReturnView(-1); window.location.href = "/acct?tab=purchases" }

    return (
        <Modal open={successView} onClose={handleClose} sx={modalCenter}>
            <Box sx={styleSuccess}>
                <Grid container justifyContent='space-between' style={{ marginBottom: "20px" }}>
                    <Typography variant="h5" style={{ color: "var(--coral)", fontWeight: 'bold' }} >
                        Return Request Successfully Submitted!
                    </Typography>
                    <CloseIcon 
                        onClick={() => {
                            handleClose()
                        }
                    } />
                </Grid>
                {orderDetails?.seller?.returnPolicy?.policy != RETURN_POLICY_LIST[1].policy && 
                    <>
                        <Typography variant="h6" style={{ fontWeight: "400" }}>Your refund request was submitted to the seller. You will receive an email shortly with your Shipping Label.</Typography>
                        <div style={{ height: "16px" }} />
                    </>
                    
                }
                {orderDetails?.seller?.returnPolicy?.policy == RETURN_POLICY_LIST[1].policy && 
                    <>
                        <Typography variant="h6" style={{ fontWeight: "400" }}>Your refund request was submitted to the seller. You will receive an email shortly. Please ship your package to the address below. Include a note with your First and Last Name, and order number.</Typography>
                        <div style={{ height: "16px" }} />
                        <Typography variant="h6" style={{ textAlign: "center", fontStyle: "italic" }}>{`${orderDetails?.seller?.returnPolicy?.addressReturnName ?? ''}`}</Typography>
                        <Typography variant="h6" style={{ textAlign: "center", fontStyle: "italic" }}>{`${orderDetails?.seller?.returnPolicy?.addressReturnLine1 ?? ''} ${orderDetails?.seller?.returnPolicy?.addressReturnLine2 ?? ''}`}</Typography>
                        <Typography variant="h6" style={{ textAlign: "center", fontStyle: "italic" }}>{`${orderDetails?.seller?.returnPolicy?.addressReturnCity ?? ''}, ${orderDetails?.seller?.returnPolicy?.addressReturnState ?? ''} ${orderDetails?.seller?.returnPolicy?.addressReturnPostalCode ?? ''}`}</Typography>
                    </>
                }
            </Box>
        </Modal>
    )
}


// Type can either be: points or cash rewards
const ReturnRequestPopup = (props) => {
    const { orderDetails, setReturnView } = props;
    const classes = useStyles();
    const [ordersToReturn, setOrdersToReturn] = React.useState({});
    //const [shipping, setShipping] = React.useState({});
    const [qtyReturnArr, setQtyReturnArr] = React.useState({});
    const [returnReasonArr, setReturnReasonArr] = React.useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [confirmPopupView, setConfirmPopupView] = React.useState(false);

    const [successView, setSuccessView] = React.useState(false);



    React.useEffect(() => {
        if (orderDetails) {
            //let shippingTmp = {};
            let retReasonTmp = {};
            let ordersTmp = {};
            let qtyReturnTmp = {};
            orderDetails.products.map((productDetail, index) => {
                //shippingTmp[productDetail.skuID] = undefined;
                retReasonTmp[productDetail.skuID] = undefined;
                ordersTmp[productDetail.skuID] = undefined;
                qtyReturnTmp[productDetail.skuID] = productDetail.availableToRequestRefundQuantity;
            })
            //setShipping(shippingTmp);
            setReturnReasonArr(retReasonTmp);
            setOrdersToReturn(ordersTmp);
            setQtyReturnArr(qtyReturnTmp);
        }
    }, []);

    function setQuantityCount(skuID, qty) {
        setQtyReturnArr((preValues) => {
            return { ...preValues, [skuID]: qty };
        });
    }
    function checkIfAnyOrdersSelected() {
        for (const currProduct of orderDetails.products) {
            if (ordersToReturn[currProduct.skuID] && qtyReturnArr[currProduct.skuID]) {
                return true;
            }
        }
        return false;
    }
    const handleSubmit = async (e) => {
        if (!isLoading) {
            e.preventDefault();
            setIsLoading(true);
            let itemsToReturn = [];
            for (const currProduct of orderDetails.products) {
                if (ordersToReturn[currProduct.skuID] && qtyReturnArr[currProduct.skuID]) {
                    const currItemToReturn = {
                        skuID: currProduct.skuID,
                        quantity: qtyReturnArr[currProduct.skuID],
                        returnReason: returnReasonArr[currProduct.skuID],
                        returnShippingMethod: orderDetails?.seller?.returnPolicy?.policy,
                    }
                    itemsToReturn.push(currItemToReturn);
                }
            }
            if (itemsToReturn.length > 0) {
                requestRefund(orderDetails.orderID, itemsToReturn, (data) => {
                    if (data.error || data?.results == false) {
                        console.log('Error Requesting Refund!')
                        setIsLoading(false);
                        setReturnView(-1)
                    } else {
                        console.log('Success Requesting Refund!')
                        setIsLoading(false);
                        //setReturnView(-1)
                        setSuccessView(true);
                        //window.location.reload()
                    }
                });
            } else {
                console.log('No items found to Request Refund for!')
                setIsLoading(false);
                setReturnView(-1)
            }
        }
    }



    return (
        <>
        {confirmPopupView ? <OrderReturnRequestConfirmPopup isLoading={isLoading} confirmPopupView={confirmPopupView} setConfirmPopupView={setConfirmPopupView} handleSubmit={handleSubmit}/> : <></>}
        successView === true ? <ReturnSuccess orderDetails={orderDetails} successView={successView} setSuccessView={setSuccessView} setReturnView={setReturnView} /> :
            <Modal open={true} onClose={() => setReturnView(-1)} sx={modalCenter}>
            <Box sx={style}>
                <Grid container justifyContent='space-between' style={{ marginBottom: "20px" }}>
                    <Typography variant="h4"  >
                        Return Items
                    </Typography>
                    <CloseIcon onClick={() => setReturnView(-1)} />
                </Grid>
                <Typography variant="h6" className={classes.orderLabel}>
                    ORDER NO.
                </Typography>
                <Typography variant="h6" component="h2"  >
                    {shortenUUID(orderDetails.orderID)}
                </Typography>
                <Typography style={{ margin: "16px 0px" }}>
                    Choose items to request a return on.
                    Items will be refunded to your original payment method.
                </Typography>
                {orderDetails && orderDetails.products.map((productDetail, index) =>
                    <div key={productDetail.skuID + orderDetails.orderID}>
                        <Grid container >
                            <Grid item xs={1}>
                                {productDetail.availableToRequestRefundQuantity > 0 &&
                                    <Checkbox
                                        checked={ordersToReturn[productDetail.skuID] || false}
                                        onChange={(event) => {
                                            setOrdersToReturn((preValues) => {
                                                return { ...preValues, [productDetail.skuID]: event.target.checked };
                                            });
                                        }}
                                        sx={{
                                            color: "var(--coral)",
                                            '&.Mui-checked': {
                                                color: "var(--coral)",
                                            },
                                        }}
                                    />
                                }
                            </Grid>

                            <Grid item xs={6}>
                                <Grid container direction="row" justifyContent="flex-start" columnSpacing={2}>
                                    <Grid item>
                                        <a>
                                            <img src={productDetail.product.productImageURL[0]} style={{ maxWidth: "100px", height: "auto" }} />
                                        </a>
                                    </Grid>
                                    <Grid item>
                                        <Typography><b>{productDetail.product.productName}</b></Typography>
                                        <Typography><b>${round(productDetail.price,2)}</b></Typography>
                                        <Typography>Size/Style: {outputViewProductVariationSize(productDetail.product.option.size)}</Typography>
                                        <Typography>Color: {productDetail.product.option.color}</Typography>
                                        <Typography>
                                            Shop: <a>{productDetail.seller.sellerName}</a>
                                        </Typography>
                                        <Typography>Quantity: {productDetail.quantity} item&#40;s&#41;</Typography>
                                        {productDetail.refundedQuantity > 0 && <Typography>Quantity Refunded: {productDetail.refundedQuantity} item&#40;s&#41;</Typography>}
                                        {productDetail.refundRequestedQuantity > 0 && <Typography>Quantity Pending Refund: {productDetail.refundRequestedQuantity} item&#40;s&#41;</Typography>}
                                        {productDetail.availableToRequestRefundQuantity != null && <Typography>Quantity Available to Request a Refund: {productDetail.availableToRequestRefundQuantity} item&#40;s&#41;</Typography>}

                                    </Grid>
                                </Grid>
                            </Grid>
                            {ordersToReturn[productDetail.skuID] && productDetail.availableToRequestRefundQuantity > 0 ?
                                <Grid item xs={5}>
                                    <QuantityButton
                                        numOrdered={productDetail.availableToRequestRefundQuantity}
                                        quantityCount={qtyReturnArr[productDetail.skuID]}
                                        setQuantityCount={setQuantityCount}
                                        skuID={productDetail.skuID}
                                    />
                                    <Typography>Why would you like to return this item?</Typography>
                                    <DropdownButton
                                        label={"Return"}
                                        options={RETURN_BUYER_REASONS}
                                        selected={returnReasonArr[productDetail.skuID]}
                                        handleClick={(event) => {
                                            setReturnReasonArr((preValues) => {
                                                return { ...preValues, [productDetail.skuID]: event.target.value };
                                            });
                                        }}
                                    />
                                    <div style={{ margin: "16px 0px" }} />
                                    {/* <Typography>How will you mail your return?</Typography>
                                    <DropdownButton
                                        label={"Shipping"}
                                        options={ShippingLabels}
                                        selected={shipping[productDetail.skuID]}
                                        handleClick={(event) => {
                                            setShipping((preValues) => {
                                                return { ...preValues, [productDetail.skuID]: event.target.value };
                                            });
                                        }}
                                    /> */}
                                    <Typography>Return Method:</Typography>
                                    <Typography>{productDetail?.seller?.returnPolicy?.policy}</Typography>
                                    {/* <DropdownButton
                                        label={"Shipping"}
                                        options={ShippingLabels}
                                        selected={shipping[productDetail.skuID]}
                                        handleClick={(event) => {
                                            setShipping((preValues) => {
                                                return { ...preValues, [productDetail.skuID]: event.target.value };
                                            });
                                        }}
                                    /> */}
                                </Grid>
                                : <></>}
                        </Grid>
                        <Divider style={{ margin: "16px 0px" }} />
                    </div>
                )}
                {orderDetails?.seller?.returnPolicy?.policy == RETURN_POLICY_LIST[1].policy && 
                    <>
                        <Typography style={{ fontWeight: "bold" }}>Ship package to the address below. Please include a note with your First and Last Name, and order number.</Typography>
                        <Typography style={{ textAlign: "center" }}>{`${orderDetails?.seller?.returnPolicy?.addressReturnName ?? ''}`}</Typography>
                        <Typography style={{ textAlign: "center" }}>{`${orderDetails?.seller?.returnPolicy?.addressReturnLine1 ?? ''} ${orderDetails?.seller?.returnPolicy?.addressReturnLine2 ?? ''}`}</Typography>
                        <Typography style={{ textAlign: "center" }}>{`${orderDetails?.seller?.returnPolicy?.addressReturnCity ?? ''}, ${orderDetails?.seller?.returnPolicy?.addressReturnState ?? ''} ${orderDetails?.seller?.returnPolicy?.addressReturnPostalCode ?? ''}`}</Typography>
                        <Divider style={{ margin: "16px 0px" }} />
                    </>
                }
                
                <div className={classes.buttonDiv}>
                    <Button
                        onClick={(e) => {
                            setConfirmPopupView(true);
                        }}
                        variant="contained" 
                        className={classes.buttonStyle}
                        disabled={!checkIfAnyOrdersSelected()}
                    >
                        <b>{`Submit Return Request`}</b>

                    </Button>
                </div>
            </Box>
            </Modal>
        </>
    );
};

export default ReturnRequestPopup;
