import { useRef } from "react";
import { Container, Typography, Divider, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandableQuestionsView from "../../components/ExpandableQuestionsView";
import { PurposeCategoryMasterLayout } from "../../layouts";

import shipReturnBoxes from "../../img/shippingAndReturnsBoxes.png";
import TrackOrderShipment from "../../components/TrackOrderShipment";

import clsx from "clsx";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useDocumentDescription from "../../hooks/useDocumentDescription";

const useStyles = makeStyles({
  headerImg: {
    width: "100%",
    maxHeight: "100%",
  },
  headerImgDiv: {
    width: 225,
    maxHeight: "100%",
  },
  bodyText1: {
    fontSize: "1.5rem",
  },
  header: {
    width: "100%",
    height: "auto",
    backgroundColor: "var(--beige)",
    padding: "64px 0px",
  },
  marginBottomBody: {
    marginBottom: "12px",
  },
  link: {
    color: "blue",
    textDecoration: "underline",
  },
});

const Title = (props) => {
  const { title } = props;
  return (
    <div style={{ marginBottom: "24px" }}>
      <Typography variant="h3" gutterBottom style={{ fontSize: "2.1rem" }}>
        <b>{title}</b>
      </Typography>
      <div>{props.children}</div>
    </div>
  );
};

const PrivacyLayout = () => {
  const classes = useStyles();

  useDocumentTitle("Privacy Policy");
  useDocumentDescription(
    "Shop for ethically produced gifts, holiday gifts, skincare, toys, food, and more."
  );

  return (
    <PurposeCategoryMasterLayout>
      <Divider />

      <div className={classes.header}>
        <Container maxWidth="xl" align="center">
          <Typography variant="h1" style={{ fontSize: "4rem" }}>
            <b>Purposer Privacy Policy</b>
          </Typography>
          <Typography
            variant="h2"
            style={{ fontSize: "1.75rem", marginTop: "12px" }}
          >
            <i>Last modified: January, 18, 2023.</i>
          </Typography>
        </Container>
      </div>

      <div style={{ height: "var(--dividerHeight)" }} />
      <Container maxWidth="md">
        <Title title="Introduction">
          <Typography className={classes.marginBottomBody}>
            Purposer ("<b>Company</b>" or "<b>We</b>") respect your privacy and
            are committed to protecting it through our compliance with this
            policy.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            This policy describes the types of information we may collect from
            you or that you may provide when you visit Purposer,
            www.purposer.com, ("<b>Purposer</b>") and our practices for
            collecting, using, maintaining, protecting, and disclosing that
            information.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            This policy applies to information we collect:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>On Purposer.</li>
              <li>
                In email, text, and other electronic messages between you and
                Purposer.
              </li>
            </ul>
          </Typography>
          <Typography className={classes.marginBottomBody}>
            It does not apply to information collected by:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>
                Us offline or through any other means, including on any other
                website operated by Company or any third party; or
              </li>
              <li>
                Any third party, including through any application or content
                (including advertising) that may link to or be accessible from
                or on Purposer.
              </li>
            </ul>
          </Typography>
          <Typography className={classes.marginBottomBody}>
            Please read this policy carefully to understand our policies and
            practices regarding your information and how we will treat it. If
            you do not agree with our policies and practices, your choice is not
            to use Purposer. By accessing or using Purposer, you agree to this
            privacy policy. This policy may change from time to time (see
            Changes to Our Privacy Policy). Your continued use of Purposer after
            we make changes is deemed to be acceptance of those changes, so
            please check the policy periodically for updates.
          </Typography>
        </Title>
        <Title title="Children Under the Age of 18">
          <Typography className={classes.marginBottomBody}>
            Purposer is not intended for children under 18 years of age. No one
            under age 18 may provide any information to or on Purposer. We do
            not knowingly collect personal information from children under 18.
            If you are under 18, do not register on Purposer, make any purchases
            through Purposer, use any of the interactive or public comment
            features of Purposer or provide any information about yourself to
            us, including your name, address, telephone number, email address,
            or any screen name or user name you may use. If we learn we have
            collected or received personal information from a child under 18
            without verification of parental consent, we will delete that
            information. If you believe we might have any information from or
            about a child under 18, please contact us at:{" "}
            <a href="mailto:info@purposer.com" className={classes.link}>
              info@purposer.com
            </a>
          </Typography>
          <Typography className={classes.marginBottomBody}>
            California residents under 16 years of age may have additional
            rights regarding the collection and sale of their personal
            information. Please see Your California Privacy Rights for more
            information.
          </Typography>
        </Title>
        <Title title="Information We Collect About You and How We Collect It">
          <Typography className={classes.marginBottomBody}>
            We collect several types of information from and about users of
            Purposer, including information:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>
                By which you may be personally identified, such as name, postal
                address, e-mail address, telephone number, or any other
                identifier by which you may be contacted online or offline ("
                <b>personal information</b>");
              </li>
              <li>
                That is about you but individually does not identify you; and/or
              </li>
              <li>
                About your internet connection, the equipment you use to access
                Purposer, and usage details.
              </li>
            </ul>
          </Typography>
          <Typography className={classes.marginBottomBody}>
            We collect this information:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>Directly from you when you provide it to us.</li>
              <li>
                Automatically as you navigate through the site. Information
                collected automatically may include usage details, IP addresses,
                and information collected through cookies, web beacons, and
                other tracking technologies.
              </li>
            </ul>
          </Typography>
        </Title>
        <Title title="Information You Provide to Us.">
          <Typography className={classes.marginBottomBody}>
            The information we collect on or through Purposer may include:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>
                Information that you provide by filling in forms on Purposer.
                This includes information provided at the time of registering to
                use Purposer, posting material, or requesting further services.
                We may also ask you for information when you enter a contest or
                promotion sponsored by us, and when you report a problem with
                Purposer.
              </li>
              <li>
                Records and copies of your correspondence (including email
                addresses), if you contact us.
              </li>
              <li>
                Your responses to surveys that we might ask you to complete for
                research purposes.
              </li>
              <li>
                Details of transactions you carry out through Purposer and of
                the fulfillment of your orders. You may be required to provide
                financial information before placing an order through Purposer.
              </li>
            </ul>
          </Typography>
          <Typography className={classes.marginBottomBody}>
            You also may provide information to be published or displayed
            (hereinafter, "<b>posted</b>") on public areas of Purposer, or
            transmitted to other users of Purposer or third parties
            (collectively, "<b>User Contributions</b>"). Your User Contributions
            are posted on and transmitted to others at your own risk. Although
            we limit access to certain pages, please be aware that no security
            measures are perfect or impenetrable. Additionally, we cannot
            control the actions of other users of Purposer with whom you may
            choose to share your User Contributions. Therefore, we cannot and do
            not guarantee that your User Contributions will not be viewed by
            unauthorized persons.
          </Typography>
        </Title>
        <Title title="Information We Collect Through Automatic Data Collection Technologies.">
          <Typography className={classes.marginBottomBody}>
            As you navigate through and interact with Purposer, we may use
            automatic data collection technologies to collect certain
            information about your equipment, browsing actions, and patterns,
            including:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>
                Details of your visits to Purposer, including traffic data,
                location data, logs, and other communication data and the
                resources that you access and use on Purposer.
              </li>
              <li>
                Information about your computer and internet connection,
                including your IP address, operating system, and browser type.
              </li>
            </ul>
          </Typography>
          <Typography className={classes.marginBottomBody}>
            The information we collect automatically is only statistical data
            and does not include personal information, but we may maintain it or
            associate it with personal information we collect in other ways or
            receive from third parties. It helps us to improve Purposer and to
            deliver a better and more personalized service, including by
            enabling us to:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>Estimate our audience size and usage patterns.</li>
              <li>
                Store information about your preferences, allowing us to
                customize Purposer according to your individual interests.
              </li>
              <li>Speed up your searches.</li>
              <li>Recognize you when you return to Purposer.</li>
            </ul>
          </Typography>
          <Typography className={classes.marginBottomBody}>
            The technologies we use for this automatic data collection may
            include:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>
                <b>Cookies (or browser cookies)</b>. A cookie is a small file
                placed on the hard drive of your computer. You may refuse to
                accept browser cookies by activating the appropriate setting on
                your browser. However, if you select this setting you may be
                unable to access certain parts of Purposer. Unless you have
                adjusted your browser setting so that it will refuse cookies,
                our system will issue cookies when you direct your browser to
                Purposer.
              </li>
              <li>
                <b>Flash Cookies.</b> Certain features of Purposer may use local
                stored objects (or Flash cookies) to collect and store
                information about your preferences and navigation to, from, and
                on Purposer. Flash cookies are not managed by the same browser
                settings as are used for browser cookies. For information about
                managing your privacy and security settings for Flash cookies,
                see Choices About How We Use and Disclose Your Information.
              </li>
              <li>
                <b>Web Beacons.</b> Pages of Purposer and our emails may contain
                small electronic files known as web beacons (also referred to as
                clear gifs, pixel tags, and single-pixel gifs) that permit the
                Company, for example, to count users who have visited those
                pages or opened an email and for other related website
                statistics (for example, recording the popularity of certain
                website content and verifying system and server integrity).
              </li>
            </ul>
          </Typography>
          <Typography className={classes.marginBottomBody}>
            We do not collect personal information automatically, but we may tie
            this information to personal information about you that we collect
            from other sources or you provide to us.
          </Typography>
        </Title>
        <Title title="Third-Party Use of Cookies and Other Tracking Technologies">
          <Typography className={classes.marginBottomBody}>
            Some content or applications, including advertisements, on Purposer
            are served by third-parties, including advertisers, ad networks and
            servers, content providers, and application providers. These third
            parties may use cookies alone or in conjunction with web beacons or
            other tracking technologies to collect information about you when
            you use Purposer. The information they collect may be associated
            with your personal information or they may collect information,
            including personal information, about your online activities over
            time and across different websites and other online services. They
            may use this information to provide you with interest-based
            (behavioral) advertising or other targeted content.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            We do not control these third parties' tracking technologies or how
            they may be used. If you have any questions about an advertisement
            or other targeted content, you should contact the responsible
            provider directly. For information about how you can opt out of
            receiving targeted advertising from many providers, see Choices
            About How We Use and Disclose Your Information.
          </Typography>
        </Title>
        <Title title="How We Use Your Information">
          <Typography className={classes.marginBottomBody}>
            We use information that we collect about you or that you provide to
            us, including any personal information:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>To present Purposer and its contents to you.</li>
              <li>
                To provide you with information, products, or services that you
                request from us.
              </li>
              <li>To fulfill any other purpose for which you provide it.</li>
              <li>
                To provide you with notices about your account, including
                expiration and renewal notices.
              </li>
              <li>
                To carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including for
                billing and collection.
              </li>
              <li>
                To notify you about changes to Purposer or any products or
                services we offer or provide though it.
              </li>
              <li>
                To allow you to participate in interactive features on Purposer.
              </li>
              <li>
                In any other way we may describe when you provide the
                information.
              </li>
              <li>For any other purpose with your consent.</li>
            </ul>
          </Typography>
        </Title>
        <Title title="Disclosure of Your Information">
          <Typography className={classes.marginBottomBody}>
            We may disclose aggregated information about our users without
            restriction.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            We may disclose personal information that we collect or you provide
            as described in this privacy policy:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>To our subsidiaries and affiliates.</li>
              <li>
                To contractors, service providers, and other third parties we
                use to support our business and who are bound by contractual
                obligations to keep personal information confidential and use it
                only for the purposes for which we disclose it to them.
              </li>
              <li>
                To a buyer or other successor in the event of a merger,
                divestiture, restructuring, reorganization, dissolution, or
                other sale or transfer of some or all of Purposer's assets,
                whether as a going concern or as part of bankruptcy,
                liquidation, or similar proceeding, in which personal
                information held by Purposer about Purposer users is among the
                assets transferred.
              </li>
              <li>To fulfill the purpose for which you provide it.</li>
              <li>
                For any other purpose disclosed by us when you provide the
                information.
              </li>
              <li>With your consent.</li>
            </ul>
          </Typography>
          <Typography className={classes.marginBottomBody}>
            We may also disclose your personal information:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>
                To comply with any court order, law, or legal process, including
                to respond to any government or regulatory request.
              </li>
              <li>
                and other agreements, including for billing and collection
                purposes.
              </li>
              <li>
                If we believe disclosure is necessary or appropriate to protect
                the rights, property, or safety of Purposer, our customers, or
                others.
              </li>
            </ul>
          </Typography>
        </Title>
        <Title title="Choices About How We Use and Disclose Your Information">
          <Typography className={classes.marginBottomBody}>
            We strive to provide you with choices regarding the personal
            information you provide to us. We have created mechanisms to provide
            you with the following control over your information:
          </Typography>
          <Typography className={classes.marginBottomBody}>
            <ul>
              <li>
                <b>Tracking Technologies and Advertising.</b> You can set your
                browser to refuse all or some browser cookies, or to alert you
                when cookies are being sent. If you disable or refuse cookies,
                please note that some parts of this site may then be
                inaccessible or not function properly.
              </li>
              <li>
                <b>
                  Disclosure of Your Information for Third-Party Advertising.
                </b>{" "}
                If you do not want us to share your personal information with
                unaffiliated or non-agent third parties for promotional
                purposes, you can opt out by sending us an email stating your
                request to info@purposer.com.
              </li>
            </ul>
          </Typography>
          <Typography className={classes.marginBottomBody}>
            We do not control third parties' collection or use of your
            information to serve interest-based advertising. However these third
            parties may provide you with ways to choose not to have your
            information collected or used in this way. You can opt out of
            receiving targeted ads from members of the Network Advertising
            Initiative ("<b>NAI</b>") on the{" "}
            <a
              href="www.networkadvertising.org/managing/opt_out.asp"
              className={classes.link}
            >
              NAI's website
            </a>
            .
          </Typography>
          <Typography className={classes.marginBottomBody}>
            California residents may have additional personal information rights
            and choices. Please see Your California Privacy Rights for more
            information.
          </Typography>
        </Title>
        <Title title="Accessing and Correcting Your Information">
          <Typography className={classes.marginBottomBody}>
            You can review and change your personal information by logging into
            Purposer and visiting your account profile page.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            California residents may have additional personal information rights
            and choices. Please see Your California Privacy Rights for more
            information.
          </Typography>
        </Title>
        <Title title="Your California Privacy Rights">
          <Typography className={classes.marginBottomBody}>
            If you are a California resident, California law may provide you
            with additional rights regarding our use of your personal
            information. To learn more about your California privacy rights,
            visit https://oag.ca.gov/privacy/ccpa.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            California's "shine the Light" law (Civil Code Section § 1798.83)
            permits users of our App that are California residents to request
            certain information regarding our disclosure of personal information
            to third parties for their direct marketing purposes. To make such a
            request, please send an email to info@purposer.com or write us at:
            7634 Girard Avenue, La Jolla, CA 92037.
          </Typography>
        </Title>
        <Title title="Data Security">
          <Typography className={classes.marginBottomBody}>
            We have implemented measures designed to secure your personal
            information from accidental loss and from unauthorized access, use,
            alteration, and disclosure.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            The safety and security of your information also depends on you.
            Where we have given you (or where you have chosen) a password for
            access to certain parts of Purposer, you are responsible for keeping
            this password confidential. We ask you not to share your password
            with anyone. We urge you to be careful about giving out information
            in public areas of Purposer like message boards. The information you
            share in public areas may be viewed by any user of Purposer.
          </Typography>
          <Typography className={classes.marginBottomBody}>
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we do our best to protect your
            personal information, we cannot guarantee the security of your
            personal information transmitted to Purposer. Any transmission of
            personal information is at your own risk. We are not responsible for
            circumvention of any privacy settings or security measures contained
            on Purposer.
          </Typography>
        </Title>
        <Title title="Changes to Our Privacy Policy">
          <Typography className={classes.marginBottomBody}>
            It is our policy to post any changes we make to our privacy policy
            on this page. If we make material changes to how we treat our users'
            personal information, we will notify you through a notice on
            Purposer home page. The date the privacy policy was last revised is
            identified at the top of the page. You are responsible for ensuring
            we have an up-to-date active and deliverable email address for you,
            and for periodically visiting Purposer and this privacy policy to
            check for any changes.
          </Typography>
        </Title>
        <Title title="Contact Information">
          <Typography className={classes.marginBottomBody}>
            To ask questions or comment about this privacy policy and our
            privacy practices, contact us at:{" "}
            <a href="mailto:info@purposer.com" className={classes.link}>
              info@purposer.com
            </a>
          </Typography>
        </Title>
        <Title title="User Data Deletion">
          <Typography className={classes.marginBottomBody}>
            It is your right to completely delete any data we have collected on
            you. Please send an email to{" "}
            <a href="mailto:info@purposer.com" className={classes.link}>
              info@purposer.com
            </a>{" "}
            to request deletion of your data.
          </Typography>
        </Title>
      </Container>

      <div style={{ height: "var(--dividerHeight)" }} />
      <div style={{ height: "var(--dividerHeight)" }} />
    </PurposeCategoryMasterLayout>
  );
};

export default PrivacyLayout;
