import { Grid, Typography, Divider, Button, ButtonBase } from "@mui/material";
import { ArrowRight } from "@mui/icons-material";
import { useRef } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import useElementWidth from "../hooks/useElementWidth";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";
import { getUserIdFromAuthAsync, isPurposerUserAdmin } from "../util/utilities";

const PostGridItem = styled(Grid)({
  display: "flex",
  flexFlow: "column",
});

const PostImg = styled("img")({
  maxWidth: "100%",
  objectFit: "cover",
  borderRadius: 15,
});

const PostTextDiv = styled("div")({
  borderRadius: 15,
  backgroundColor: "var(--cream)",
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 3,
  flexGrow: 1,
  display: "flex",
  flexFlow: "column",
});

const PostDivider = styled(Divider)({
  backgroundColor: "#000000",
  width: "70%",
  marginTop: 10,
  marginBottom: 10,
});

const PostTitle = styled(Typography)({
  marginTop: 25,
});

const PostText = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
});

const ReadMoreButtonDiv = styled("div")({
  display: "flex",
  flexGrow: 1,
  justifyContent: "flex-end",
  alignItems: "flex-end",
});

const ReadMoreButton = styled(Button)({
  marginBottom: 5,
  textTransform: "none",
  "&:hover": {
    backgroundColor: "var(--cream)",
  },
});

const style = {
  editButton: {
    backgroundColor: "white",
    border: "2px solid var(--mintGreen)",
    padding: "5px 15px",
    borderRadius: 8,
  },
};

function BlogPost(props) {
  const { blogPost } = props;
  const imgRef = useRef(null);
  const imgWidth = useElementWidth(imgRef);
  const mobile = useMobileMediaQuery();
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  useEffect(() => {
    getUserIdFromAuthAsync().then((userId) => {
      if (isPurposerUserAdmin(userId)) {
        setIsUserAdmin(true);
      }
    })
  }, []);

  return (
    <PostGridItem item xs={12} sm={6} md={4}>
      {!mobile ? (
        <>
          {blogPost.pictures && blogPost.pictures.length > 0 &&
            <PostImg
              src={blogPost?.pictures[0]}
              alt=""
              style={{ height: 0.94 * imgWidth }}
              ref={imgRef}
            />
          }
          <PostTextDiv>
            <Typography
              align="right"
              variant="h6"
              className={"roboto-regular-italic"}
            >
              {blogPost?.category}
            </Typography>
            <PostTitle
              variant="h2"
              className={"roboto-medium"}
              style={{ fontSize: "26px" }}
            >
              {blogPost?.title}
            </PostTitle>
            <PostDivider />
            <PostText className={"roboto-regular"}>{blogPost?.body}</PostText>
            <ReadMoreButtonDiv>
              <ReadMoreButton
                component={Link}
                to={blogPost.overrideLink ? blogPost.overrideLink : "/blog/" + blogPost?.blogID + "/" + blogPost?.title?.trim()?.replace(/\W+/g, "-")}
                variant="filled"
                disableRipple
              >
                <ArrowRight fontSize="large" />
                <Typography variant="h6" className={"roboto-regular"}>
                  Read Now
                </Typography>
              </ReadMoreButton>
            </ReadMoreButtonDiv>
          </PostTextDiv>
          {isUserAdmin &&
          <ButtonBase
            sx={style.editButton}
            component={Link}
            to={"/adminportal/editBlog/" + blogPost?.blogID}>
            <Typography className={"roboto-medium"}>
              {"Edit Blog"}
            </Typography>
          </ButtonBase>}
        </>
      ) : (
        <Grid container direction="row" columnSpacing={1}>
          <Grid item xs={5}>
            {blogPost.pictures && blogPost.pictures.length > 0 &&
              <img
                src={blogPost?.pictures[0]}
                alt=""
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "15px",
                }}
              />
            }
          </Grid>
          <Grid item xs={7}>
            <PostTextDiv>
              <Typography align="right" className={"roboto-regular-italic"}>
                {blogPost?.category}
              </Typography>
              <Typography variant="h6" className={"roboto-medium"}>
                {blogPost?.title}
              </Typography>
              <Divider
                sx={{
                  backgroundColor: "#000000",
                  width: "70%",
                  margin: "3px 0",
                }}
              />
              <PostText className={"roboto-regular"}>{blogPost?.body}</PostText>
              <ReadMoreButtonDiv>
                <ReadMoreButton
                  variant="filled"
                  disableRipple
                  component={Link}
                  to={blogPost.overrideLink ? blogPost.overrideLink : "/blog/" + blogPost?.blogID + "/" + blogPost?.title?.trim()?.replace(/\W+/g, "-")}
                  >
                  <ArrowRight fontSize="large" />
                  <Typography className={"roboto-regular"}>Read Now</Typography>
                </ReadMoreButton>
              </ReadMoreButtonDiv>
            </PostTextDiv>
            {isUserAdmin &&
            <ButtonBase
              sx={style.editButton}
              component={Link}
              to={"/seller/1/editBlog/" + blogPost?.blogID}>
              <Typography className={"roboto-medium"}>
                {"Edit Blog"}
              </Typography>
            </ButtonBase>}
          </Grid>
        </Grid>
      )}
    </PostGridItem>
  );
}

export default BlogPost;
