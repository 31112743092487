import React from "react";
import {
    AccordionDetails,
    Box,
    Container,
    Divider,
    IconButton,
    MenuItem,
    Popover,
    Typography,
    useTheme,
    useMediaQuery,
    Button
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import managementMenuJsonData from "../json/management_menu.json";
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from "react-router-dom";
import {
    ExpandLess,
    ExpandMore,
    Storefront,
    LocalShippingOutlined,
    ShoppingBasketOutlined,
    AnnouncementOutlined,
    EmailOutlined,
    BarChartOutlined,
    AccountBalanceOutlined,
    ListAltOutlined,
    RateReviewOutlined,
    BookOutlined,
    SellOutlined,
    InsertPhotoOutlined,
    NewspaperOutlined
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { withStyles } from "@mui/styles";
import boxIcon from "../img/box.png";
import admin_portal_menu from "../json/admin_portal_menu.json";



const useStyles = makeStyles({
    backgroundDiv: {
        backgroundColor: "inherit",
    },
    accordionDetails: {
        paddingBottom: 5,
    },
    menuDiv: {
        display: "flex",
        alignItems: "center",
    },
    menuText: {
        fontSize: "1.3rem",
        paddingLeft: 10,
        paddingRight: 5,
    },
    submenuText: {
        paddingLeft: 45,
    },
});

const BackgroundDiv = styled("div")({
    width: "100vw",
    backgroundColor: "#F0F6F4",
});

const SpacingContainer = styled("div")({
    justifyContent: "space-around",
    display: "flex",
    height: 60,
    alignItems: "center",
});

const Links = styled("a")({
    color: "#FFFFFF",
    "&:hover": {
        color: "#FFFFFF",
    },
    "&:visited": {
        color: "#FFFFFF",
    },
});

const Accordion = withStyles({
    root: {
        border: "none",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        paddingLeft: 0,
        marginBottom: -1,
        minHeight: 40,
        "&$expanded": {
            minHeight: 40,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const StyledAccordianDetails = styled(AccordionDetails)({
    paddingBottom: 8,
    paddingLeft: 0,
});

const VerticallyCenterDiv = styled("div")({
    display: "flex",
    alignItems: "center",
});

const icons = {
  "Store Approval": <Storefront fontSize="large" />,
  Blogs: <BookOutlined fontSize="large" />,
  // "Returns": <img src={boxIcon} style={{width: "32px"}}/>,
  "Customer Orders": <SellOutlined fontSize="large"/>,
  Reviews: <RateReviewOutlined fontSize="large" />,
  "Storewide Coupons": <BarChartOutlined fontSize="large" />,
  "Cover Photo Updater": <InsertPhotoOutlined fontSize="large"/>,
  "Press Release Updater": <NewspaperOutlined fontSize="large"/>
};

function AdminPortalMenuMobile(props) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [categoryMenuData, setCategoryMenuData] = React.useState([]);
    const theme = useTheme();
    const showHamburger = useMediaQuery(theme.breakpoints.down("md"));

    const classes = useStyles();
    const { managementMenuData = admin_portal_menu } = props;
    const { id } = useParams();

    let initialState = managementMenuData.menuItems.map((menu) => {
        return menu.submenu && menu.submenu.length > 0;
    });
    const [expanded, setExpanded] = React.useState(initialState);

    const handleChange = (index, newExpanded) => {
        let newState = [...expanded];
        newState[index] = newExpanded;
        setExpanded(newState);
    };

    const open = Boolean(anchorElNav);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <BackgroundDiv>
            <Container maxWidth="xl">
                <nav>
                    {/* Container wrapper */}
                    <SpacingContainer>
                        {/* Collapsible wrapper */}
                        <Box
                            sx={{
                                display: { xs: "flex", md: "none" },
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Box>
                                {/* <IconButton
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                    sx={{ width: "100%", color: "white" }}
                                >
                                    <MenuIcon />
                                </IconButton> */}
                                <Button onClick={handleOpenNavMenu} sx={{ width: "100%", color: "black", fontSize: "24px" }}><MenuIcon sx={{marginRight: "8px", fontSize: "24px"}}/> Navigate to</Button>
                                <Popover
                                    open={open}
                                    anchorEl={anchorElNav}
                                    onClose={handleCloseNavMenu}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                >
                                    <Box sx={{ m: 1, p: 1 }}>
                                        {managementMenuData &&
                                            managementMenuData.menuItems.map((menu, index) => {
                                                return (
                                                    <div key={index}>
                                                        <Accordion
                                                            square
                                                            expanded={expanded[index]}
                                                            onChange={(event, newExpanded) => {
                                                                if (menu.submenu && menu.submenu.length > 0) {
                                                                    handleChange(index, newExpanded);
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                if (menu.submenu && menu.submenu.length === 0) {
                                                                    // Redirect to link
                                                                    const linkprefix = menu.hasOwnProperty("linkPrefix")
                                                                        ? menu.linkPrefix
                                                                        : "";
                                                                    const newLink = linkprefix + "/adminportal"  + menu.link;
                                                                    window.location.href = newLink;
                                                                }
                                                            }}
                                                            key={index}
                                                            className={classes.accordion}
                                                        >
                                                            <AccordionSummary>
                                                                <div className={classes.menuDiv}>
                                                                    {icons[menu.header]}
                                                                    <Typography className={classes.menuText}>
                                                                        <b>{menu.header}</b>
                                                                    </Typography>
                                                                    {menu.submenu && menu.submenu.length > 0 ? (
                                                                        expanded[index] ? (
                                                                            <ExpandLess fontSize="large" />
                                                                        ) : (
                                                                            <ExpandMore fontSize="large" />
                                                                        )
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </div>
                                                            </AccordionSummary>
                                                            {menu.submenu.map((submenu, index) => {
                                                                const linkprefix = submenu.hasOwnProperty("linkPrefix")
                                                                    ? submenu.linkPrefix
                                                                    : "";
                                                                const newLink = linkprefix + "/adminportal"  + submenu.link;
                                                                return (
                                                                    <AccordionDetails
                                                                        key={index}
                                                                        className={classes.accordionDetails}
                                                                    >
                                                                        <a href={newLink}>
                                                                            <Typography className={classes.submenuText}>
                                                                                {submenu.subheader}
                                                                            </Typography>
                                                                        </a>
                                                                    </AccordionDetails>
                                                                );
                                                            })}
                                                        </Accordion>
                                                        <Divider
                                                            sx={{
                                                                margin: 0,
                                                                backgroundColor: "var(--mintGreenSolid)",
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })}
                                    </Box>
                                </Popover>
                            </Box>
                        </Box>
                    </SpacingContainer>
                    {/* Container wrapper */}
                </nav>
            </Container>
        </BackgroundDiv>
    );
}

export default AdminPortalMenuMobile;
