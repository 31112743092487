import { Avatar, ButtonBase, Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";
import clsx from "clsx";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//Default Prop Values
import featuredProductsDefault from "../json/loyalty_program_redeem_products_data.json";

const useStyles = makeStyles({
  rootBackground: {
    backgroundColor: "white",
    padding: "40px 0",
  },
  imagePortrait: {
    height: 360,
    width: "auto",
    marginBottom: 15,
    margin: "auto",
  },
  gutterBottom: { marginBottom: 30 },
  navButtons: {
    position: "absolute",
    top: "39%",
    height: 75,
    border: "1px solid var(--mintArrow)",
    borderRadius: 15,
    backgroundColor: "#FFFFFF",
  },
  prevButton: {
    left: -33,
  },
  nextButton: {
    right: -33,
  },
  divContainer: {
    position: "relative",
    width: "90%",
    margin: "auto",
  },
  arrows: {
    color: "var(--mintArrow)",
    fontSize: 30,
  },
  carouselContainer: {
    width: "100%",
  },
});

const responsive = {
  break1: {
    breakpoint: { max: 4000, min: 800 },
    items: 3,
  },
  break2: {
    breakpoint: { max: 800, min: 520 },
    items: 2,
  },
  break3: {
    breakpoint: { max: 520, min: 0 },
    items: 1,
  },
};

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  const classes = useStyles();
  return (
    <>
      <ButtonBase
        className={clsx(classes.navButtons, classes.prevButton)}
        onClick={previous}
      >
        <ArrowBack className={classes.arrows} />
      </ButtonBase>
      <ButtonBase
        className={clsx(classes.navButtons, classes.nextButton)}
        onClick={next}
      >
        <ArrowForward className={classes.arrows} />
      </ButtonBase>
    </>
  );
};

const ProductPortrait = ({ classes, item }) => (
  <>
    <Avatar
      src={item.image_url}
      alt={item.product}
      className={classes.imagePortrait}
      variant="square"
    />
    <Typography align="center" variant="h5">
      <b>{item.product}</b>
    </Typography>
    <Typography align="center" variant="body1" component="p">
      <a href={item.sellerURL}>Shop: {item.seller}</a>
    </Typography>
    <Typography align="center" variant="body1" component="p">
      <i>
        <a href={item.purposeCategoryURL}>Purpose: {item.purpose}</a>
      </i>
    </Typography>
    <Typography align="center" variant="h6" component="p">
      <b>{item.points} Points</b>
    </Typography>
  </>
);

const LoyaltyProgramRedeemProducts = ({ featuredProducts = featuredProductsDefault }) => {
  const classes = useStyles();
  

  return (
    <div className={classes.rootBackground}>
      <Container maxWidth="xl">
        <div className={classes.divContainer}>
          {featuredProducts && featuredProducts.length > 0 && (
            <Carousel
              responsive={responsive}
              infinite={false}
              showDots={false}
              arrows={false}
              renderButtonGroupOutside
              customButtonGroup={<CustomButtonGroupAsArrows />}
              containerClass={classes.carouselContainer}
            >
              {featuredProducts.map((item, i) => {
                return <ProductPortrait key={i} classes={classes} item={item} />;
              })}
            </Carousel>
          )}
        </div>
      </Container>
    </div>
  );
};

export default LoyaltyProgramRedeemProducts;
