import {
  Container,
  Typography,
  Grid,
  Button,
  Divider,
  Stack,
  Box,
} from "@mui/material";
import HelpSubmission from "../../components/HelpSubmission";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { PurposeCategoryMasterLayout } from "../../layouts";
import { styled } from "@mui/material/styles";
import useDocumentDescription from "../../hooks/useDocumentDescription";

const ContactInfoContainer = styled(Grid)({
  marginBottom: "16px",
  // overflow: "hidden",
  backgroundColor: "var(--cream)", 
  width: "100%",
  
  
  padding: "64px"
});

const HelpContainer = styled(Container)({
  marginBottom: "16px",
  overflow: "hidden",
});

const Header = styled(Typography)({
  color: "var(--mintGreenSolid)",
  margin: "40px 0",
});

const HelpButtonGridItem = styled(Grid)({
  textAlign: "center",
});

const HelpButton = styled(Button)({
  color: "#000000",
  width: "100%",
  minHeight: 120,
  // minHeight: 65,
  minWidth: 150,
  borderRadius: 10,
  borderWidth: 3,
  borderColor: "var(--beige)",
  "&:hover": {
    backgroundColor: "#FFFFFF",
    borderColor: "var(--beige)",
    borderWidth: 3,
  },
  padding: "10px 0px",
  textTransform: "none",
});

const SubmitButton = styled(Button)({
  color: "#FFFFFF",
  backgroundColor: "var(--mintGreenSolid)",
  minWidth: 240,
  borderRadius: 5,
  marginTop: 30,
  "&:hover": {
    backgroundColor: "var(--mintGreenSolid)",
  },
});

const SendMessageText = styled(Typography)({
  marginTop: 70,
  marginBottom: 20,
});

const PhoneContactImg = styled("img")({
  maxWidth: 50,
  marginRight: 10,
});

const HelpButtons = () => {
  const buttons = [
    { label: "FAQs", link: "/faq" },
    { label: "My Account", link: "/login" },
    { label: "Seller Support", link: "/support/seller" },
    { label: "Live Chat with a Real Person from the Purposer Team", openTidio: true },
  ];
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      rowSpacing={2}
      columnSpacing={2}
    >
      {buttons.map((button, index) => {
        return (
          <HelpButtonGridItem item xs={6} md={3} key={index}>
            <HelpButton 
              variant="outlined" 
              href={button.link ? button.link : '#'}
              onClick={(e) => {
                if (button.openTidio) {
                  window.tidioChatApi.open();
                }
              }}
            >
              <Typography variant="h2" className={"roboto-medium"} style={{padding: "8px", fontSize: "1.25rem"}}>
                {button.label}
              </Typography>
            </HelpButton>
          </HelpButtonGridItem>
        );
      })}
    </Grid>
  );
};

function ContactLayout() {
  
  useDocumentTitle("Call the Purposer Support Team Today! US based customer service.");
  useDocumentDescription("Contact our team through phone call, email or direct chat! We will help answer all your questions about accounts, seller stores, purchases, shipping and more!");
  
  return (
    <PurposeCategoryMasterLayout>
      <Divider />
      <HelpContainer maxWidth="xl">
        <Header
          align="center"
          sx={{ typography: { xs: "h4", sm: "h3", md: "h2" }, fontSize: "3rem" }}
          className={"roboto-regular"}
          variant="h1"
        >
          How Can We Help?
        </Header>
        <HelpButtons />
        {/* <Grid style={{paddingTop: "25px", paddingBottom: "10px"}}>
          <div id="cr-form-FORaafce26ba5214a3dbea41853fd6abbd4"></div>
        </Grid> */}
        {/* uncomment above to get contact us form back */}
        {/* <SendMessageText variant="h5" className={"roboto-medium"}>
          SEND US A MESSAGE
        </SendMessageText>
        <Grid container direction="row" rowSpacing={4} columnSpacing={4}>
          <Grid item xs={12} md={6} lg={8}>
            <HelpSubmission />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <SubmitButton>
                <Typography variant="h5" className={"roboto-medium"}>
                  SUBMIT
                </Typography>
              </SubmitButton>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Stack
              justifyContent="space-around"
              alignItems="center"
              sx={{
                height: "100%",
                minHeight: 400,
                backgroundColor: "var(--halfBeige)",
                borderRadius: 10,
                px: 2,
              }}
            >
              <Typography
                variant="h5"
                align="center"
                className={"roboto-medium-italic"}
              >
                Do you prefer talking over the phone?
              </Typography>
              <Box>
                <Typography
                  variant="h5"
                  align="center"
                  className={"roboto-regular"}
                >
                  Call us
                </Typography>
                <Typography
                  variant="h5"
                  align="center"
                  className={"roboto-regular"}
                >
                  Monday through Friday
                </Typography>
                <Typography
                  variant="h5"
                  align="center"
                  className={"roboto-regular"}
                >
                  8am - 5pm PST
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PhoneContactImg
                  src={
                    "https://d2eudfgh8kpk8j.cloudfront.net/common%2Fthumbs%2F87724327_thumb.png"
                  }
                  alt="contact"
                />
                <Typography variant="h5" className={"roboto-regular"}>
                  1-800-555-2555
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid> */}
      </HelpContainer>
      <div style={{backgroundColor: "var(--cream)", width: "100%", paddingTop: "64px", paddingBottom: "64px"}}>
        <Typography variant="h3" textAlign="center" style={{fontWeight: 500, fontSize: "1.75rem"}}><i>Still need help? Reach out to our support team!</i></Typography>
        <br/>
        <Grid container columnGap={12} justifyContent="center" direction="row">
          <Grid item justifyContent="center" direction="column">
            <Typography variant="h6" style={{fontWeight: 400}} item textAlign="center">Call us Monday through Friday</Typography>
            <Typography variant="h6" style={{fontWeight: 400}} item textAlign="center">8am - 5pm PST</Typography>
          </Grid>
          <Grid item justifyContent="center" direction="column">
            <Grid container direction="row" columnGap={2}>
              <img src="https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Contact+Page/phone_icon.png" style={{width: "32px", height: "32px"}}></img>
              <Typography variant="h6" style={{fontWeight: 400}}>(805) 303-7728</Typography>
            </Grid>
            <br/>
            <Grid container direction="row" columnGap={2}>
              <img src="https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Contact+Page/email_icon.png" style={{width: "32px", height: "32px", objectFit: 'contain'}}></img>
              <Typography variant="h6" style={{fontWeight: 400}}>hello@purposer.com</Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </PurposeCategoryMasterLayout>
  );
}

export default ContactLayout;
