import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  img: {
    width: "100%",
    maxWidth: "200px",
    height: "auto",
    objectFit: "cover",
    borderRadius: "50%",
  },
});

const InfoCard = ({ title, img_url, purchase_count }) => {
  const classes = useStyles();
  return (
    <Grid item xs={6} sm={6} md={4}>
      <Box>
        <img className={classes.img} src={img_url} alt={title} />
      </Box>
      <Typography sx={{ mt: 1 }}>{title}</Typography>
      <Stack direction={"row"} justifyContent="center" spacing={1.5}>
        <Typography>
          <b>Purchases </b>
        </Typography>
        <Box
          sx={{
            border: "var(--mintGreen) solid 1px",
            px: 2,
            borderRadius: 5,
          }}
        >
          <Typography>{purchase_count}</Typography>
        </Box>
      </Stack>
    </Grid>
  );
};

const SupportedPurposes = (props) => {
  const { userOrderPurposeData, isLoaded } = props;
  return (
    <Container>
      <h1 style={{ textAlign: "center" }}>
        All the Purposes You've Supported
      </h1>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        rowSpacing={5}
        align="center"
        columnSpacing={2}
      >
        {!isLoaded &&
          <Typography variant="h5" style={{ marginTop: "32px" }}>Loading...</Typography>
        }
        {isLoaded && userOrderPurposeData.length == 0 &&
          <Typography variant="h5" style={{ marginTop: "32px" }}>Nothing here yet!</Typography>
        }
        {userOrderPurposeData && userOrderPurposeData.length > 0 &&
          userOrderPurposeData.map((item, index) => {
            return (
              <InfoCard
                key={index}
                title={item.purposeName}
                img_url={item.img}
                purchase_count={item.quantity}
              />
            );
          })
        }
      </Grid>
    </Container>
  );
};

export default SupportedPurposes;
