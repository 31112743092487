import { ButtonBase, Container, Typography } from "@mui/material";
import mockData from "../json/mockAPIdata.json";
import { styled } from "@mui/material/styles";
import React from "react";
import { getListOfProductsById } from "../util/getProductsById";

const StyledButton = styled(ButtonBase)({
  color: "white",
  paddingTop: 3,
  paddingBottom: 3,
  paddingLeft: 15,
  paddingRight: 15,
  margin: "10px 15px 10px 0px",
  backgroundColor: "var(--coral)",
  borderRadius: 7,
  "&:visited": {
    color: "white",
  },
});

const RelatedCategoriesAndSearches = ({ data }) => {
  const [related, setRelated] = React.useState([]);
  React.useEffect(() => {
    if (!data) {
      getListOfProductsById(mockData, (retrievedData) => {
        if (
          !retrievedData.hasOwnProperty("error") &&
          !retrievedData.hasOwnProperty("message")
        ) {
          setRelated(retrievedData);
        }
      });
    } else {
      setRelated(data);
    }
  }, [data]);

  return (
    <Container maxWidth="xl">
      <Typography gutterBottom className={"roboto-medium"} variant="h4">
        Related Categories &amp; Searches
      </Typography>
      {SHOP_BY_RELATED_CATEGORIES_DATA.map((item, i) => {
        return (
          <StyledButton
            href={item.link}
            key={i}
          >
            <Typography variant="subtitle1" className={"roboto-medium"}>
              {item.searchString}
            </Typography>
          </StyledButton>
        );
      })}
    </Container>
  );
};

export default RelatedCategoriesAndSearches;

const SHOP_BY_RELATED_CATEGORIES_DATA = [
  {
    "searchString": "Kitchen accessories",
    "link": "/shop/category/4/69"
  },
  {
    "searchString": "Lip Balms & Treatments",
    "link": "/shop/category/2/43"
  },
  {
    "searchString": "Bath Bombs",
    "link": "/shop/category/2/32"
  },
  {
    "searchString": "Reusable Straws and Utensils",
    "link": "/shop/category/7/94"
  },
  {
    "searchString": "Cute Dainty Necklaces",
    "link": "/shop/category/3/47"
  },
  {
    "searchString": "Women's Rings",
    "link": "/shop/category/3/46"
  },
  {
    "searchString": "Affirmation Cards",
    "link": "/shop/category/4/72"
  },
  {
    "searchString": "Meaninful Artwork",
    "link": "/shop/category/4/64"
  },
  {
    "searchString": "Drink coasters",
    "link": "/shop/category/4/69"
  }
];