import { getSessionJwt, getJsonCache, setJsonCache } from "./utilities";


const {
    NODE_API_ENDPOINT
  } = require("./constants");

async function getBlogs(callback) {
    fetch(NODE_API_ENDPOINT + "/get-blogs")
        .then((response) => {
            if (response.status === 200) return response.json();
            return "failed";
        })
        .then(
            (data) => callback(data),
            (error) => console.log(error)
        );
}


async function getBlog(blogID, callback) {
    fetch(NODE_API_ENDPOINT + "/get-blog?id="+blogID)
        .then((response) => {
            if (response.status === 200) return response.json();
            return "failed";
        })
        .then(
            (data) => callback(data),
            (error) => console.log(error)
        );
}


async function insertBlog(title, subtitle,category, body, summary, pictures, callback) {
    const jwtToken = await getSessionJwt();

    const reqBody = {
        title: title,
        subtitle: subtitle,
        category: category,
        body: body,
        summary: summary,
        pictures: pictures
    }
    fetch(NODE_API_ENDPOINT + "/insert-blog", {
        method: "POST",
        headers: {
            "Authorization": 'Bearer ' + jwtToken,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
    })
        .then((response) => {
            if (response.status === 200) return response.json();
            return null;
        })
        .then(
            (data) => callback(data),
            (error) => console.log(error)
        );
}

async function updateBlog(blogID, title, subtitle, category, body, summary, pictures, callback) {
    const jwtToken = await getSessionJwt();
    const reqBody = {
        blogID: blogID,
        title: title,
        subtitle: subtitle,
        category: category,
        body: body,
        summary: summary,
        pictures: pictures
    }
    fetch(NODE_API_ENDPOINT + "/update-blog", {
        method: "POST",
        headers: {
            "Authorization": 'Bearer ' + jwtToken,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
    })
        .then((response) => {
            if (response.status === 200) return response.json();
            return null;
        })
        .then(
            (data) => callback(data),
            (error) => console.log(error)
        );
}

async function deleteBlog(blogID, callback) {
    const jwtToken = await getSessionJwt();

    const reqBody = {
        blogID: blogID
    }
    fetch(NODE_API_ENDPOINT + "/delete-blog", {
        method: "POST",
        headers: {
            "Authorization": 'Bearer ' + jwtToken,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
    })
        .then((response) => {
            if (response.status === 200) return response.json();
            return null;
        })
        .then(
            (data) => callback(data),
            (error) => console.log(error)
        );
}


export {
    getBlogs, deleteBlog, insertBlog, updateBlog, getBlog
};