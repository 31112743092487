import {
  Container,
  Typography,
  Button,
  Grid,
  TextField,
  MenuItem,
  Radio,
  Divider,
  ButtonGroup,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  Stack,
  ButtonBase,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PurposeCategoryMasterLayout } from "../../layouts";
import { useEffect, useState, Fragment } from "react";
import clsx from "clsx";
import cartJsonData from "../../json/cart.json";
import CarouselProductView from "../../components/CarouselProductView";
import LoyaltyProgramPointsLinear from "../../components/LoyaltyProgramPointsLinear";
import LoyaltyProgramCartRedeemProducts from "../../components/LoyaltyProgramCartRedeemProducts";
import LoyaltyProgramPointsCircle from "../../components/LoyaltyProgramPointsCircle";
import { Add, Remove } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementItemQuantity,
  decrementItemQuantity,
  removeItemFromCart,
  clearCart,
  selectCartItems,
} from "../../app/features/cart/cartDataSlice";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { getListOfProductsById, getRandomProducts } from "../../util/getProductsById";
import { getCouponsByCouponCode } from "../../util/coupons";
import { outputViewProductVariationSize, round } from "../../util/utilities";
import { selectUser } from "../../app/features/auth/userDataSlice";
import StripeLayout from './StripeLayout'
import {
  PRODUCT_SIZES,
  PRODUCT_COLORS,
  PRODUCT_MATERIALS,
  SHIPPING_UPGRADE_TYPES,
  SHIPPING_SERVICES,
  SHIPPING_CHARGE_TYPES,
  STRIPE_TAX_CATEGORIES,
  COUPON_TYPES,
  SHIPPING_SERVICES_LOCAL,
} from "../../util/constants";
import { shuffle, splitArray } from "../../util/utilities";
import toast, { Toaster } from 'react-hot-toast';
import GalleryView from "../../components/GalleryView";

const useStyles = makeStyles({
  verticallyCenter: {
    display: "flex",
    alignItems: "center",
  },
  horizontalCenter: {
    display: "flex",
    justifyContent: "center",
  },
  maxWidth: {
    width: "100%",
  },
  header: {
    color: "var(--mintGreenSolid)",
    // fontSize: "3.5rem",
    fontWeight: "bold",
    marginTop: 30,
    marginBottom: 5,
  },
  greyBorder: {
    borderColor: "rgba(0, 0, 0, 0.12)",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 5,
  },
  cartHeaderGrid: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 30,
    paddingRight: 30,
  },
  cartGrid: {
    backgroundColor: "#F9F4F0",
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 15,
  },
  cartText: {
    fontSize: "1.3rem",
  },
  cartItemGrid: {
    marginTop: 80,
  },
  cartItemImg: {
    maxWidth: "100%",
    borderRadius: 5,
  },
  cartItemText: {
    paddingLeft: 25,
  },
  cartNoItems: {
    paddingTop: 15,
  },
  cartItemQuantityButton: {
    backgroundColor: "#FFFFFF",
    color: "#000000",
    borderColor: "#000000",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      borderColor: "#000000",
    },
  },
  cartButton: {
    textTransform: "none",
    marginTop: 40,
    borderRadius: 10,
    minWidth: 170,
    minHeight: 55,
    borderColor: "#000000",
    "&:hover": {
      borderColor: "#000000",
      backgroundColor: "inherit",
    },
  },
  cartButtonText: {
    fontSize: "1.35rem",
    color: "#000000",
  },
  sectionSpacing: {
    marginTop: 40,
  },
  avatar: {
    backgroundColor: "#FFFFFF",
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 100,
    width: 65,
    height: 65,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatarNumber: {
    color: "#000000",
    fontSize: "2.5rem",
  },
  sectionHeaderText: {
    fontSize: "1.35rem",
  },
  sectionHeaderTextPink: {
    fontSize: "1.75rem",
    color: "var(--coral)",
  },
  inputFieldSpacing: {
    paddingTop: 40,
  },
  inputLabelText: {
    fontSize: "1.15rem",
    paddingLeft: 15,
  },
  inputField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: 17,
      maxHeight: 45,
      "&:hover fieldset": {
        borderColor: "var(--mintGreen)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--mintGreen)",
      },
    },
  },
  leftHalfGridItem: {
    paddingRight: 20,
  },
  rightHalfGridItem: {
    paddingLeft: 20,
  },
  leftThirdGridItem: {
    paddingRight: 25,
  },
  middleThirdGridItem: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  rightThirdGridItem: {
    paddingLeft: 25,
  },
  shippingMethodDiv: {
    backgroundColor: "#F9F4F0",
    marginTop: 30,
  },
  shippingMethodGridItem: {
    paddingTop: 25,
    paddingBottom: 25,
  },
  shippingMethodText: {
    fontSize: "1.15rem",
  },
  radio: {
    width: 15,
    height: 15,
    color: "#00000000",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "var(--mintGreen)",
    "&.Mui-checked": {
      color: "#00000000",
      backgroundColor: "var(--mintGreen)",
      "&:hover": {
        backgroundColor: "var(--mintGreen)",
      },
    },
    "&:hover": {
      backgroundColor: "rgba(133, 175, 154, 0.04)",
    },
  },
  paymentMethodSpacing: {
    marginTop: 20,
  },
  paymentMethodImg: {
    maxHeight: 50,
    marginLeft: 20,
  },
  creditCardImg: {
    height: "90%",
  },
  reviewOrderDiv: {
    backgroundColor: "var(--pastelGreen)",
    marginTop: 40,
  },
  reviewOrderText: {
    fontSize: "1.15rem",
  },
  reviewOrderTextWidth: {
    width: "100%",
  },
  reviewOrderTextLeftSpacing: {
    paddingLeft: 20,
  },
  reviewOrderTextRightSpacing: {
    marginRight: 40,
  },
  reviewOrderRowSpacing: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  reviewOrderDiscountCode: {
    width: "25%",
    marginLeft: 25,
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
      maxHeight: 30,
      "&:hover fieldset": {
        borderColor: "var(--mintGreen)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--mintGreen)",
      },
    },
  },
  placeOrderButton: {
    backgroundColor: "var(--coral)",
    color: "#FFFFFF",
    borderRadius: 10,
    marginTop: 35,
    marginBottom: 60,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
    "&:hover": {
      backgroundColor: "var(--coral)",
    },
  },
  placeOrderButtonText: {
    fontSize: "1.3rem",
  },
  recentlyViewedDiv: {
    backgroundColor: "var(--pastelGreen)",
    paddingTop: 20,
    paddingBottom: 70,
  },
  recentlyViewedHeader: {
    fontSize: "1.5rem",
    paddingBottom: 25,
  },
  cartButtonCenter: {
    display: "flex",
    justifyContent: "center",
  },
  marginTopGridItem: {
    marginTop: 35,
  },
});

function findHighestPriceItemInArray(currObjectArray) {
  if (Array.isArray(currObjectArray)) {
    const highest = currObjectArray.reduce((previous, current) => {
      return current.oneItemPrice > previous.oneItemPrice ? current : previous;
    });
    // const lowest = currObjectArray.reduce((previous, current) => {
    //   return current.price < previous.price ? current : previous;
    // });
    return highest;
  }
  return null;
}

function calculateShippingOption(cartItems, currShippingOption, allShippingOptions) {
  const shippingName = currShippingOption.shippingName;
  
  const allShippingsOfThisType = allShippingOptions.filter(s => s.shippingName == shippingName);
  const greatestOneItemShipping = findHighestPriceItemInArray(allShippingsOfThisType);
  let shippingTotalCost = greatestOneItemShipping.oneItemPrice;
  let selectedShippingService = greatestOneItemShipping.service;
  const sellerID = cartItems.find(c => c.productData?.seller?.sellerID)?.productData?.seller?.sellerID;
  for (const cartItem of cartItems) {
    const currProductID = cartItem.productData.productID;
    const currShipping = allShippingOptions.find(s => s.shippingName == shippingName && s.productID == currProductID) ? allShippingOptions.find(s => s.shippingName == shippingName && s.productID == currProductID) : greatestOneItemShipping;
    if (currProductID == greatestOneItemShipping.productID) {
      shippingTotalCost += (currShipping.addlItemPrice * (cartItem.quantity - 1));
      //console.log(`Added ${greatestOneItemShipping.oneItemPrice} as it was the highest price, then added ${currShipping.addlItemPrice} * ${(cartItem.quantity - 1)} totalling ${(currShipping.addlItemPrice * (cartItem.quantity - 1))} for ${currProductID} of type ${shippingName}`)
    } else {
      shippingTotalCost += (currShipping.addlItemPrice * cartItem.quantity);
      //console.log(`Added ${currShipping.addlItemPrice} * ${cartItem.quantity} totalling ${currShipping.addlItemPrice * cartItem.quantity} for ${currProductID} of type ${shippingName}`)
    }
  }
  const newShippingOption = {
    shippingName: shippingName,
    service: selectedShippingService,
    shippingTotalCost: shippingTotalCost,
    sellerID: sellerID,
  }
  return newShippingOption;
}

const Cart = (props) => {
  const { cart, dispatch, classes, selectedShippingInfo, setSelectedShippingInfo, cartPriceTotals, setCartPriceTotals } = props;
  const [cartItemsBySeller, setCartItemsBySeller] = useState([]);

  useEffect(() => {
    let itemsBySeller = [];
    let standardShippingInfoAllSellers = [];

    for (const cartItem of cart) {
      const currSellerID = cartItem.productData.seller.sellerID;
      if (!itemsBySeller.find(s => s.sellerID == currSellerID)) {
        //If seller is not in the item array, add the current cart item to their new array
        const currSellerItems = {
          sellerID: currSellerID,
          totalNumberOfItems: 0,
          cartItems: [],
          selectableShippingOptions: [],
          allShippingOptions: [],
        }
        itemsBySeller.push(currSellerItems);
      }

      const currItemBySellerID = itemsBySeller.find(s => s.sellerID == currSellerID)
      currItemBySellerID.totalNumberOfItems += cartItem.quantity;
      currItemBySellerID?.cartItems?.push(cartItem);
      const currStandardShipping = {
        productID: cartItem.productData.productID,
        shippingName: "Standard",
        service: cartItem.productData.shippingService,
        oneItemPrice: cartItem.productData.shippingOneItemPrice,
        addlItemPrice: cartItem.productData.shippingAddlItemPrice,
      }

      let standardInArray = currItemBySellerID.selectableShippingOptions.find(s => s.shippingName == currStandardShipping.shippingName);
      if (!standardInArray) {
        currItemBySellerID.selectableShippingOptions.push(currStandardShipping)
        standardInArray = currItemBySellerID.selectableShippingOptions.find(s => s.shippingName == currStandardShipping.shippingName);
        standardInArray.cost = 0;
      }
      currItemBySellerID.allShippingOptions.push(currStandardShipping)
      for (const newShippingUpgrade of cartItem.productData.shippingUpgrades) {
        let upgradeInArray = currItemBySellerID.selectableShippingOptions.find(s => s.shippingName == newShippingUpgrade.shippingName);
        if (!upgradeInArray) {
          currItemBySellerID.selectableShippingOptions.push(newShippingUpgrade)
          upgradeInArray = currItemBySellerID.selectableShippingOptions.find(s => s.shippingName == newShippingUpgrade.shippingName);
          upgradeInArray.cost = 0;
        }
        currItemBySellerID.allShippingOptions.push(newShippingUpgrade)
      }
    }
    for (const sellerItems of itemsBySeller) {
      sellerItems.shippingOptions = [];
      const standardShippingOptions = sellerItems.selectableShippingOptions.filter(s => s.shippingName == 'Standard');
      const upgradeShippingOptions = sellerItems.selectableShippingOptions.filter(s => s.shippingName != 'Standard');

      for (const currShippingOption of standardShippingOptions) {
        const newShippingOption = calculateShippingOption(sellerItems.cartItems, currShippingOption, sellerItems.allShippingOptions);
        
        sellerItems.shippingOptions.push(newShippingOption);
        let sellerInShippingArray = standardShippingInfoAllSellers.find(s => s.sellerID == sellerItems.sellerID);
        if (!sellerInShippingArray) {
          standardShippingInfoAllSellers.push(newShippingOption)
          sellerInShippingArray = standardShippingInfoAllSellers.find(s => s.sellerID == sellerItems.sellerID);
        }
      }
      for (const currShippingOption of upgradeShippingOptions) {
        const newShippingOption = calculateShippingOption(sellerItems.cartItems, currShippingOption, sellerItems.allShippingOptions);
        let sellerInShippingArray = standardShippingInfoAllSellers.find(s => s.sellerID == sellerItems.sellerID);
        //console.log(`Adding standard price of ${sellerInShippingArray.shippingTotalCost} to upgrade total ${newShippingOption.shippingTotalCost} for a total of: ${newShippingOption.shippingTotalCost + sellerInShippingArray.shippingTotalCost}`);
        newShippingOption.shippingTotalCost += sellerInShippingArray.shippingTotalCost;
        sellerItems.shippingOptions.push(newShippingOption);
      }
    }

    setSelectedShippingInfo(standardShippingInfoAllSellers);
    setCartItemsBySeller(itemsBySeller);
  }, [cart]);

  function handleSelectedShippingInfo(sellerID, currShippingInfo) {
    const newSelectedShippingInfo = JSON.parse(JSON.stringify(selectedShippingInfo));
    let sellerInShippingArray = newSelectedShippingInfo.find(s => s.sellerID == sellerID);
    if (!sellerInShippingArray) {
      newSelectedShippingInfo.push(currShippingInfo)
    }
    sellerInShippingArray.shippingName = currShippingInfo.shippingName;
    sellerInShippingArray.service = currShippingInfo.service;
    sellerInShippingArray.shippingTotalCost = currShippingInfo.shippingTotalCost;
    setSelectedShippingInfo(newSelectedShippingInfo);
  }
  // console.log('cart', cart)
  // console.log('cartItemsBySeller', cartItemsBySeller)
  // console.log('selectedShippingInfo', selectedShippingInfo)

  useEffect(() => {

    let itemTotal = 0;
    let discountTotal = 0;
    let shippingTotal = 0;
    let subtotal = 0;

    for (const seller of cartItemsBySeller) {
      for (const sellerItem of seller?.cartItems) {
        itemTotal += (sellerItem.quantity * sellerItem.optionData.originalPrice);
        discountTotal += (sellerItem.quantity * (sellerItem.optionData.originalPrice - sellerItem.optionData.price));
      }
    }

    for (const shippingInfo of selectedShippingInfo) {
      shippingTotal += shippingInfo.shippingTotalCost;
    }
    subtotal += (itemTotal - discountTotal + shippingTotal)

    setCartPriceTotals({
      itemTotal,
      discountTotal,
      shippingTotal,
      subtotal,
    })

  }, [selectedShippingInfo, cartItemsBySeller]);
  return (
    <>
      <Grid
        container
        direction="row"
        className={clsx(classes.greyBorder, classes.cartHeaderGrid)}
        sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
      >
        <Grid item md={7} />
        <Grid item md={1} align="center">
          <Typography className={classes.cartText}>Price</Typography>
        </Grid>
        <Grid item md={3} align="center">
          <Typography className={classes.cartText}>Qty</Typography>
        </Grid>
        <Grid item md={1} align="center">
          <Typography className={classes.cartText}>Total</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        className={clsx(classes.greyBorder, classes.cartGrid)}
      >
        {cartItemsBySeller.length > 0 && (
          cartItemsBySeller.map((sellerCartItems, sellerIndex) => {
            return (
              sellerCartItems?.cartItems?.map((cartItem, index) => {
                return (
                  <>
                    {index == 0 && (
                      <Grid
                        container
                        direction="row"
                        className={clsx(classes.greyBorder, classes.cartHeaderGrid, classes.marginTopGridItem)}
                      >
                        <Typography className={classes.cartText}>{`Seller: ${cartItem.productData.seller.sellerName}`}</Typography>
                      </Grid>
                    )}
                    <Grid
                      container
                      item
                      xs={12}
                      key={index}
                      className={classes.cartItemGrid}
                    >
                      <Grid item xs={3}>
                        <img
                          src={cartItem.productData.productImageURL ? cartItem?.productData?.productImageURL[0] : null}
                          alt=""
                          className={classes.cartItemImg}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <a href={"/shop/product/" + cartItem.productData.productID + "/" + cartItem.productData.productName?.replace(/\W+/g, "-")}>
                          <Typography className={classes.cartItemText}>
                            {cartItem.productData.productName}
                          </Typography>
                        </a>
                        <a href={"/shop/seller/" + cartItem.productData.seller.sellerID}>
                          <Typography className={classes.cartItemText}>
                            SIZE/STYLE: {outputViewProductVariationSize(cartItem.optionData.size)}
                          </Typography>
                        </a>
                        <a href={"/shop/seller/" + cartItem.productData.seller.sellerID}>
                          <Typography className={classes.cartItemText}>
                            COLOR: {cartItem.optionData.color}
                          </Typography>
                        </a>
                        <a href={"/shop/seller/" + cartItem.productData.seller.sellerID}>
                          <Typography className={classes.cartItemText}>
                            SHOP: {cartItem.productData.seller.sellerName}
                          </Typography>
                        </a>
                        {cartItem.customizations?.length > 0 &&
                          cartItem.customizations.map((custom, index) => {
                            return (
                              <Typography className={classes.cartItemText}>
                                {`Customization #${index + 1}: ${custom}`}
                              </Typography>
                            )
                          })
                        }
                      </Grid>
                      <Grid item xs={1} align="center" sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                        {cartItem.optionData?.saleText ?
                          <>
                            <Typography className={classes.cartText} style={{ color: "red" }}>
                              ${round(cartItem.optionData.price, 2)}
                            </Typography>
                            <div className="top-element-formatting" />
                            <strike>
                              ${cartItem.optionData?.originalPrice}
                            </strike>
                            <br></br>
                            {cartItem.optionData?.saleText}
                          </>
                          :
                          <Typography className={classes.cartText}>
                            ${round(cartItem.optionData.price, 2)}
                          </Typography>
                        }
                      </Grid>
                      <Grid item xs={5} align="center" sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
                        {cartItem.optionData?.saleText ?
                          <>
                            <Typography className={classes.cartText} style={{ color: "red" }}>
                              ${round(cartItem.optionData.price, 2)}
                            </Typography>
                            <div className="top-element-formatting" />
                            <strike>
                              ${cartItem.optionData?.originalPrice}
                            </strike>
                            <br></br>
                            {cartItem.optionData?.saleText}
                          </>
                          :
                          <Typography className={classes.cartText}>
                            ${round(cartItem.optionData.price, 2)}
                          </Typography>
                        }
                      </Grid>
                      <Grid item xs={3} align="center" sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                        <ButtonGroup variant="outlined">
                          <Button
                            onClick={() => {
                              dispatch(decrementItemQuantity({ productId: cartItem.id, skuId: cartItem.skuID }));
                              //window.location.reload()
                            }}
                            className={classes.cartItemQuantityButton}
                          >
                            {cartItem.quantity > 1 ? (
                              <Remove fontSize="small" />
                            ) : (
                              <DeleteOutlineIcon fontSize="small" />
                            )}
                          </Button>
                          <Button
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            className={classes.cartItemQuantityButton}
                          >
                            <Typography className={classes.cartText}>
                              QTY: {cartItem.quantity}
                            </Typography>
                          </Button>
                          <Button
                            onClick={() => {
                              dispatch(incrementItemQuantity({ productId: cartItem.id, skuId: cartItem.skuID }));
                              //window.location.reload();
                            }}
                            className={classes.cartItemQuantityButton}
                          >
                            <Add fontSize="small" />
                          </Button>
                        </ButtonGroup>
                      </Grid>
                      <Grid item xs={1} align="center" sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                        <Typography className={classes.cartText}>
                          ${round((cartItem.optionData.price * cartItem.quantity), 2)}
                        </Typography>
                      </Grid>

                    </Grid>
                    <Grid item xs={7} sx={{ display: { xs: "block", sm: "block", md: "none" }, mt: 2 }}>
                      <ButtonGroup variant="outlined">
                        <Button
                          onClick={() => {
                            dispatch(decrementItemQuantity({ productId: cartItem.id, skuId: cartItem.skuID }));
                            //window.location.reload()
                          }}
                          className={classes.cartItemQuantityButton}
                        >
                          {cartItem.quantity > 1 ? (
                            <Remove fontSize="small" />
                          ) : (
                            <DeleteOutlineIcon fontSize="small" />
                          )}
                        </Button>
                        <Button
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          className={classes.cartItemQuantityButton}
                        >
                          <Typography style={{ fontSize: "16px" }}>
                            QTY: {cartItem.quantity}
                          </Typography>
                        </Button>
                        <Button
                          onClick={() => {
                            dispatch(incrementItemQuantity({ productId: cartItem.id, skuId: cartItem.skuID }));
                            //window.location.reload();
                          }}
                          className={classes.cartItemQuantityButton}
                        >
                          <Add fontSize="small" />
                        </Button>
                      </ButtonGroup>
                    </Grid>
                    {/* {cartItem.quantity > 1 &&
                      <Grid item xs={5} align="center" sx={{display: {xs: "block", sm: "block", md: "block"}}}>
                        <Typography variant="caption">Subtotal for this item</Typography>
                        <Typography className={classes.cartText}>
                          ${round((cartItem.optionData.price * cartItem.quantity),2)}
                        </Typography>
                      </Grid>} */}
                    {index == sellerCartItems.cartItems.length - 1 && (
                      <Grid
                        container
                        direction="column"
                        className={clsx(classes.cartHeaderGrid, classes.marginTopGridItem)}
                        justify="flex-end"
                      >
                        <Stack direction="row" justifyContent="end">
                          <Typography variant="contained" className={classes.cartText}>
                            {`Shipping for [${sellerCartItems.totalNumberOfItems}] item${sellerCartItems.totalNumberOfItems > 1 ? 's' : ''} from ${cartItem.productData.seller.sellerName}`}
                          </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="end">
                          <Autocomplete
                            variant="contained"
                            key={"selectShipping" + sellerIndex + '-' + index}
                            size="small"
                            fullWidth
                            options={sellerCartItems.shippingOptions}
                            value={selectedShippingInfo.find(s => s.sellerID == cartItem.productData.seller.sellerID)}
                            disableClearable
                            // getOptionLabel={(option) => `$${round(option.shippingTotalCost, 2)} (${option.shippingName} Shipping)`}
                            getOptionLabel={(option) => (SHIPPING_SERVICES_LOCAL.includes(option.service) ? `$${round(option.shippingTotalCost, 2)} (${option.service})` : `$${round(option.shippingTotalCost, 2)} (${option.shippingName} Shipping)`)}
                            onChange={(e, value) => {
                              handleSelectedShippingInfo(sellerCartItems.sellerID, value);
                            }}
                            sx={{ maxWidth: 375, my: 2 }}
                            label="Shipping Service"
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </Grid>
                    )}
                  </>
                );
              })
            )
          })
        )}
        {cartItemsBySeller.length == 0 && (
          <Grid item xs={12} className={classes.cartNoItems}>
            <Typography align="center" className={classes.cartText}>
              <b>No items in your cart</b>
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export const NumberBubble = (props) => {
  const { number, classes } = props;
  return (
    <Box className={classes.avatar}>
      <Typography className={classes.avatarNumber}>
        <b>{number}</b>
      </Typography>
    </Box>
  );
};

const InputField = (props) => {
  const {
    label,
    checkoutInfo,
    setCheckoutInfo,
    fieldName,
    defaultValue = "",
    classes,
  } = props;

  return (
    <div className={clsx(label && classes.inputFieldSpacing)}>
      <Typography className={classes.inputLabelText}>{label}</Typography>
      <TextField
        variant="outlined"
        className={classes.inputField}
        defaultValue={defaultValue}
        onChange={(event) => {
          setCheckoutInfo({ ...checkoutInfo, [fieldName]: event.target.value });
        }}
      />
    </div>
  );
};

const DropdownField = (props) => {
  const { label, checkoutInfo, setCheckoutInfo, options, fieldName, classes } =
    props;

  return (
    <>
      <Typography>
        <b>{label}</b>
      </Typography>
      <TextField
        variant="outlined"
        select
        value={checkoutInfo[fieldName]}
        className={classes.inputField}
        onChange={(event) => {
          setCheckoutInfo({ ...checkoutInfo, [fieldName]: event.target.value });
        }}
      >
        {options &&
          options.map((option, index) => {
            return (
              <MenuItem key={index} value={option}>
                <Typography>{option}</Typography>
              </MenuItem>
            );
          })}
      </TextField>
    </>
  );
};

const YourInfo = (props) => {
  const { checkoutInfo, setCheckoutInfo, classes } = props;
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <NumberBubble number={1} classes={classes} />
        <Typography className={classes.sectionHeaderText}>
          <b>Your Information</b>
        </Typography>
      </Box>
      <Grid container direction="row" columnSpacing={4}>
        <Grid item xs={12} sm={6}>
          <InputField
            label={"First Name"}
            checkoutInfo={checkoutInfo}
            setCheckoutInfo={setCheckoutInfo}
            fieldName={"firstName"}
            defaultValue={checkoutInfo.firstName}
            classes={classes}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            label={"Last Name"}
            checkoutInfo={checkoutInfo}
            setCheckoutInfo={setCheckoutInfo}
            fieldName={"lastName"}
            defaultValue={checkoutInfo.lastName}
            classes={classes}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            label={"Address"}
            checkoutInfo={checkoutInfo}
            setCheckoutInfo={setCheckoutInfo}
            fieldName={"address"}
            classes={classes}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            label={"Apt, Suite, etc."}
            checkoutInfo={checkoutInfo}
            setCheckoutInfo={setCheckoutInfo}
            fieldName={"apt"}
            classes={classes}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            label={"City"}
            checkoutInfo={checkoutInfo}
            setCheckoutInfo={setCheckoutInfo}
            fieldName={"city"}
            classes={classes}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <InputField
            label={"Country/Region"}
            checkoutInfo={checkoutInfo}
            setCheckoutInfo={setCheckoutInfo}
            fieldName={"country"}
            classes={classes}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <InputField
            label={"State"}
            checkoutInfo={checkoutInfo}
            setCheckoutInfo={setCheckoutInfo}
            fieldName={"state"}
            classes={classes}
          />
        </Grid>
        <Grid item xs={8} sm={4}>
          <InputField
            label={"ZIP Code"}
            checkoutInfo={checkoutInfo}
            setCheckoutInfo={setCheckoutInfo}
            fieldName={"zipCode"}
            classes={classes}
          />
        </Grid>
      </Grid>
    </>
  );
};

const Shipping = (props) => {
  const {
    checkoutInfo,
    setCheckoutInfo,
    signedIn,
    shippingAddresses,
    shippingMethods,
    classes,
  } = props;

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <NumberBubble number={2} classes={classes} />
        <Typography className={classes.sectionHeaderText}>
          <b>Shipping + Delivery Method</b>
        </Typography>
      </Box>
      <Grid container direction="row">
        <Grid item xs={signedIn ? 6 : 12}>
          <Typography
            gutterBottom
            className={clsx(classes.inputFieldSpacing, classes.inputLabelText)}
          >
            Shipping Address
          </Typography>
        </Grid>
        {signedIn && (
          <Grid item xs={6}>
            <Typography
              align="right"
              gutterBottom
              className={clsx(
                classes.inputFieldSpacing,
                classes.inputLabelText
              )}
            >
              Choose a Different Shipping Address
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          {signedIn ? (
            <DropdownField
              checkoutInfo={checkoutInfo}
              setCheckoutInfo={setCheckoutInfo}
              options={shippingAddresses}
              fieldName={"shippingAddress"}
              classes={classes}
            />
          ) : (
            <InputField
              checkoutInfo={checkoutInfo}
              setCheckoutInfo={setCheckoutInfo}
              fieldName={"shippingAddress"}
              classes={classes}
            />
          )}
        </Grid>
      </Grid>
      <div
        className={clsx(
          classes.maxWidth,
          classes.shippingMethodDiv,
          classes.greyBorder
        )}
      >
        {shippingMethods.map((method, index) => {
          return (
            <Fragment key={index}>
              {index > 0 && <Divider className={classes.maxWidth} />}
              <Grid container direction="row">
                <Grid
                  item
                  xs={1}
                  align="center"
                  className={clsx(classes.shippingMethodGridItem)}
                >
                  <Radio
                    value={index}
                    checked={checkoutInfo.shippingMethod === index}
                    onChange={(event) => {
                      setCheckoutInfo({
                        ...checkoutInfo,
                        shippingMethod: index,
                        shippingPrice: method.price,
                      });
                    }}
                    className={classes.radio}
                  />
                </Grid>
                <Grid
                  item
                  xs={9}
                  className={clsx(
                    classes.verticallyCenter,
                    classes.shippingMethodGridItem
                  )}
                >
                  <Typography className={classes.shippingMethodText}>
                    {method.name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={clsx(
                    classes.verticallyCenter,
                    classes.shippingMethodGridItem
                  )}
                >
                  <Typography className={classes.shippingMethodText}>
                    {method.price === 0 ? "FREE" : "$" + round(method.price, 2)}
                  </Typography>
                </Grid>
              </Grid>
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

const LoyaltyProgramCart = (props) => {
  const { classes } = props;
  return (
    <>
      <LoyaltyProgramPointsCircle type={"points"} />
      <div className={classes.sectionSpacing}></div>
      <Typography className={classes.sectionHeaderTextPink}>
        <b>Redeem For Products</b>
      </Typography>
      <LoyaltyProgramCartRedeemProducts />
      <div className={classes.cartButtonCenter}>
        <Button
          variant="outlined"
          href="/"
          disableRipple
          className={classes.cartButton}
        >
          <Typography className={classes.cartButtonText}>
            <b>Apply To Checkout</b>
          </Typography>
        </Button>
      </div>
      <div className={classes.sectionSpacing}></div>
      <Typography className={classes.sectionHeaderTextPink}>
        <b>Redeem For Cash Reward</b>
      </Typography>
      <div className={classes.sectionSpacing}></div>
      <LoyaltyProgramPointsLinear />
      <div className={classes.cartButtonCenter}>
        <Button
          variant="outlined"
          href="/"
          disableRipple
          className={classes.cartButton}
        >
          <Typography className={classes.cartButtonText}>
            <b>Apply To Checkout</b>
          </Typography>
        </Button>
      </div>
    </>
  );
};


// Pre-Stripe Payment Element
// const Payment = (props) => {
//   const {
//     checkoutInfo,
//     setCheckoutInfo,
//     signedIn,
//     cardNumbers,
//     paymentMethods,
//     classes,
//   } = props;

//   return (
//     <>
//       <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
//         <NumberBubble number={3} classes={classes} />
//         <Typography className={classes.sectionHeaderText}>
//           <b>Payment</b>
//         </Typography>
//         <img
//           src="https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/My+Cart+Page/Credit+Card+Icons.jpg"
//           alt=""
//           className={classes.creditCardImg}
//         />
//       </Box>
//       <Grid container direction="row" columnSpacing={4}>
//         <Grid item xs={signedIn ? 6 : 12}>
//           <Typography
//             gutterBottom
//             className={clsx(classes.inputFieldSpacing, classes.inputLabelText)}
//           >
//             Card Number
//           </Typography>
//         </Grid>
//         {signedIn && (
//           <Grid item xs={6}>
//             <Typography
//               align="right"
//               gutterBottom
//               className={clsx(
//                 classes.inputFieldSpacing,
//                 classes.inputLabelText
//               )}
//             >
//               Choose a Different Card Number
//             </Typography>
//           </Grid>
//         )}
//         <Grid item xs={12}>
//           {signedIn ? (
//             <DropdownField
//               checkoutInfo={checkoutInfo}
//               setCheckoutInfo={setCheckoutInfo}
//               options={cardNumbers}
//               fieldName={"cardNumber"}
//               classes={classes}
//             />
//           ) : (
//             <InputField
//               checkoutInfo={checkoutInfo}
//               setCheckoutInfo={setCheckoutInfo}
//               fieldName={"cardNumber"}
//               classes={classes}
//             />
//           )}
//         </Grid>
//         <Grid item xs={12}>
//           <InputField
//             label={"Name on Card"}
//             checkoutInfo={checkoutInfo}
//             setCheckoutInfo={setCheckoutInfo}
//             fieldName={"nameOnCard"}
//             classes={classes}
//           />
//         </Grid>
//         <Grid item xs={10} sm={6}>
//           <InputField
//             label={"Expiration (MM / YY)"}
//             checkoutInfo={checkoutInfo}
//             setCheckoutInfo={setCheckoutInfo}
//             fieldName={"cardExpiration"}
//             classes={classes}
//           />
//         </Grid>
//         <Grid item xs={10} sm={6}>
//           <InputField
//             label={"Security Code"}
//             checkoutInfo={checkoutInfo}
//             setCheckoutInfo={setCheckoutInfo}
//             fieldName={"cardSecurityCode"}
//             classes={classes}
//           />
//         </Grid>
//         <Grid
//           container
//           item
//           xs={12}
//           direction="row"
//           className={classes.inputFieldSpacing}
//         >
//           <Grid item xs={12}>
//             <Typography className={classes.sectionHeaderText}>
//               <b>Choose a Different Payment Method</b>
//             </Typography>
//           </Grid>
//           {paymentMethods.map((method, index) => {
//             return (
//               <Grid
//                 item
//                 xs={12}
//                 key={index}
//                 className={clsx(
//                   classes.verticallyCenter,
//                   classes.paymentMethodSpacing
//                 )}
//               >
//                 <Radio
//                   value={method.name}
//                   checked={checkoutInfo.paymentMethod === method.name}
//                   onChange={(event) => {
//                     setCheckoutInfo({
//                       ...checkoutInfo,
//                       paymentMethod: event.target.value,
//                     });
//                   }}
//                   className={classes.radio}
//                 />
//                 <img
//                   src={method.img}
//                   alt=""
//                   className={classes.paymentMethodImg}
//                 />
//               </Grid>
//             );
//           })}
//         </Grid>
//       </Grid>
//     </>
//   );
// };

// export const ReviewOrder = (props) => {
//   const { cart, checkoutInfo, taxRate, classes, paymentIntent } = props;

//   let subtotal = 0;
//   cart.forEach(
//     (cartItem) =>
//       (subtotal += cartItem.quantity * cartItem.productData.productPrice)
//   );
//   const taxAndFees = subtotal * taxRate;

//   return (
//     <>
//       <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
//         <NumberBubble number={4} classes={classes} />
//         <Typography className={classes.sectionHeaderText}>
//           <b>Review and Place Your Order</b>
//         </Typography>
//       </Box>
//       <div className={clsx(classes.reviewOrderDiv, classes.greyBorder)}>
//         <Grid
//           container
//           direction="row"
//           className={classes.reviewOrderRowSpacing}
//         >
//           <Grid item xs className={classes.verticallyCenter}>
//             <Typography
//               className={clsx(
//                 classes.reviewOrderText,
//                 classes.reviewOrderTextWidth,
//                 classes.reviewOrderTextLeftSpacing
//               )}
//             >
//               Subtotal
//             </Typography>
//           </Grid>
//           <Grid item xs={2} align="right" className={classes.verticallyCenter}>
//             <Typography
//               className={clsx(
//                 classes.reviewOrderText,
//                 classes.reviewOrderTextWidth,
//                 classes.reviewOrderTextRightSpacing
//               )}
//             >
//               ${round(subtotal,2)}
//             </Typography>
//           </Grid>
//         </Grid>
//         <Divider />
//         <Grid
//           container
//           direction="row"
//           className={classes.reviewOrderRowSpacing}
//         >
//           <Grid item xs className={classes.verticallyCenter}>
//             <Typography
//               className={clsx(
//                 classes.reviewOrderText,
//                 classes.reviewOrderTextWidth,
//                 classes.reviewOrderTextLeftSpacing
//               )}
//             >
//               Taxes &amp; Fees
//             </Typography>
//           </Grid>
//           <Grid item xs={2} align="right" className={classes.verticallyCenter}>
//             <Typography
//               className={clsx(
//                 classes.reviewOrderText,
//                 classes.reviewOrderTextWidth,
//                 classes.reviewOrderTextRightSpacing
//               )}
//             >
//               ${round(taxAndFees,2)}
//             </Typography>
//           </Grid>
//         </Grid>
//         <Divider />
//         <Grid
//           container
//           direction="row"
//           className={classes.reviewOrderRowSpacing}
//         >
//           <Grid item xs={10} className={classes.verticallyCenter}>
//             <Typography
//               className={clsx(
//                 classes.reviewOrderText,
//                 classes.reviewOrderTextWidth,
//                 classes.reviewOrderTextLeftSpacing
//               )}
//             >
//               Shipping
//             </Typography>
//           </Grid>
//           <Grid item xs={2} align="right" className={classes.verticallyCenter}>
//             <Typography
//               className={clsx(
//                 classes.reviewOrderText,
//                 classes.reviewOrderTextWidth,
//                 classes.reviewOrderTextRightSpacing
//               )}
//             >
//               {checkoutInfo.shippingPrice === 0
//                 ? "FREE"
//                 : "$" + checkoutInfo.shippingPrice}
//             </Typography>
//           </Grid>
//         </Grid>
//         <Divider />
//         <Grid
//           container
//           direction="row"
//           className={classes.reviewOrderRowSpacing}
//         >
//           <Grid item xs={10} className={classes.verticallyCenter}>
//             <Typography
//               display="inline"
//               className={clsx(
//                 classes.reviewOrderText,
//                 classes.reviewOrderTextLeftSpacing
//               )}
//             >
//               Discount Code
//             </Typography>
//             <TextField
//               variant="outlined"
//               className={classes.reviewOrderDiscountCode}
//             />
//           </Grid>
//           <Grid item xs={2} />
//         </Grid>
//         <Divider />
//         <Grid
//           container
//           direction="row"
//           className={classes.reviewOrderRowSpacing}
//         >
//           <Grid item xs={10} className={classes.verticallyCenter}>
//             <Typography
//               className={clsx(
//                 classes.reviewOrderText,
//                 classes.reviewOrderTextWidth,
//                 classes.reviewOrderTextLeftSpacing
//               )}
//             >
//               <b>Grand Total</b>
//             </Typography>
//           </Grid>
//           <Grid item xs={2} align="right" className={classes.verticallyCenter}>
//             <Typography
//               className={clsx(
//                 classes.reviewOrderText,
//                 classes.reviewOrderTextWidth,
//                 classes.reviewOrderTextRightSpacing
//               )}
//             >
//               <b>USD</b> $
//               {round((paymentIntent?.amount / 100),2)}
//             </Typography>
//           </Grid>
//         </Grid>
//       </div>
//     </>
//   );
// };

function CartLayout() {
  const classes = useStyles();
  const cartData = cartJsonData;
  const userData = useSelector(selectUser);
  const [checkoutInfo, setCheckoutInfo] = useState({
    firstName:
      userData && userData.hasOwnProperty("given_name")
        ? userData.given_name
        : "",
    lastName:
      userData && userData.hasOwnProperty("family_name")
        ? userData.family_name
        : "",
    address: "",
    apt: "",
    city: "",
    country: "",
    state: "",
    zipCode: "",
    shippingAddress: "",
    shippingMethod: 0,
    shippingPrice: 0,
    cardNumber: "",
    nameOnCard: "",
    cardExpiration: "",
    cardSecurityCode: -1,
    paymentMethod: "card",
  });
  const dispatch = useDispatch();
  const reduxCartItems = useSelector(selectCartItems) ?? [];
  const [selectedShippingInfo, setSelectedShippingInfo] = useState([]);
  const [cartPriceTotals, setCartPriceTotals] = useState({
    itemTotal: 0,
    discountTotal: 0,
    shippingTotal: 0,
    subtotal: 0,
  });
  const [cart, setCart] = useState([]);
  const [coupon, setCoupon] = useState(null);
  const [fullCouponData, setFullCouponData] = useState(null);
  const [couponToTry, setCouponToTry] = useState('');
  const [signInDialogOpen, setSignInDialogOpen] = useState(false);
  const [isLoadingCart, setIsLoadingCart] = useState(false);
  const [isLoadingCoupon, setIsLoadingCoupon] = useState(false);
  const [resetCoupon, setResetCoupon] = useState(0);

  // useEffect(() => {
  //   //dispatch(clearCart());
  //   let productIds = [];
  //   let skuIds = [];

  //   //Items in cart are stored in 'productID:skuID' format
  //   for (const id of Object.keys(cartQuantities)) {
  //     const productIdSkuIdSplit = id.split(":");
  //     if (productIdSkuIdSplit.length < 2 || productIdSkuIdSplit[0] == 'undefined' || productIdSkuIdSplit[1] == 'undefined') {
  //       console.log('Removing invalid cart item: ', id);
  //       dispatch(removeItemFromCartByKey(id));
  //       continue;
  //     }
  //     const productId = productIdSkuIdSplit[0];
  //     const skuId = productIdSkuIdSplit[1];
  //     if (!productIds.includes(productId)) productIds.push(productId);
  //     if (!skuIds.includes(skuId)) skuIds.push(skuId);
  //   }

  //   if (productIds.length > 0 && !isLoadingCart) {
  //     setIsLoadingCart(true);
  //     getListOfProductsById(productIds, (data) => {
  //       if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
  //         let newCart = [];
  //         let tmpCoupon;
  //         for (const product of data) {
  //           if (product && product?.productID) {
  //             product.options.forEach(option => {
  //               const productIDSkuIDStr = `${product.productID}:${option.skuID}`;
  //               if (skuIds.includes(option.skuID) && cartQuantities[productIDSkuIDStr]) {
  //                 if (!tmpCoupon && option.couponCode) {
  //                   tmpCoupon = option.couponCode;
  //                   setCoupon(option.couponCode)
  //                 } else if (tmpCoupon && option.couponCode && tmpCoupon != option.couponCode) {
  //                   const ERROR_STR = `Another coupon already in cart! Removing product ${product.productName}!`;
  //                   console.log(ERROR_STR);
  //                   toast.error(ERROR_STR)
  //                   dispatch(removeItemFromCartByKey(productIDSkuIDStr));
  //                   return;
  //                 }
  //                 newCart.push({
  //                   id: product.productID,
  //                   productID: product.productID,
  //                   skuID: option.skuID,
  //                   productData: product,
  //                   optionData: option,
  //                   quantity: cartQuantities[`${product.productID}:${option.skuID}`],
  //                 });
  //               }
  //             });
  //           }
  //         }
  //         applyCoupon(tmpCoupon, newCart)
  //       }
  //     });
  //   } else {
  //     setCart([]);
  //   }
  // }, [cartQuantities]);

  const [randomProducts, setRandomProducts] = useState([[]]);
  const [loadingRandomProducts, setLoadingRandomProducts] = useState(true);

  useEffect(() => {
    getRandomProducts((data) => {
      if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
        let mainProducts = shuffle(data.mainProducts);
        let extraProducts = shuffle(data.extraProducts);
        const resultingProducts = [...mainProducts, ...extraProducts];
        setRandomProducts(splitArray(resultingProducts));
        setLoadingRandomProducts(false);
      }
    });

  }, []);


  useEffect(() => {
    //dispatch(clearCart());
    let productIds = [];
    let skuIds = [];

    for (const cartItem of reduxCartItems) {
      if (!productIds.includes(cartItem.productId)) {
        productIds.push(cartItem.productId);
      }
      if (!skuIds.includes(cartItem.skuId)) {
        skuIds.push(cartItem.skuId);
      }
    }

    if (productIds.length > 0 && !isLoadingCart) {
      setIsLoadingCart(true);
      getListOfProductsById(productIds, (data) => {
        if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message")) {
          let newCart = [];
          let tmpCoupon;
          for (const product of data) {
            if (product && product?.productID) {
              product.options.forEach(option => {
                const reduxCartItem = reduxCartItems.find(i => i.productId == product.productID && i.skuId == option.skuID);
                if (skuIds.includes(option.skuID) && reduxCartItem) {
                  if (!tmpCoupon && option.couponCode) {
                    tmpCoupon = option.couponCode;
                    setCoupon(option.couponCode)
                  } else if (tmpCoupon && option.couponCode && tmpCoupon != option.couponCode) {
                    const ERROR_STR = `Another coupon already in cart! Removing product ${product.productName}!`;
                    console.log(ERROR_STR);
                    toast.error(ERROR_STR)
                    dispatch(removeItemFromCart({ productId: product.productID, skuId: option.skuID }));
                    return;
                  }
                  newCart.push({
                    id: product.productID,
                    productID: product.productID,
                    skuID: option.skuID,
                    productData: product,
                    optionData: option,
                    quantity: reduxCartItem.quantity,
                    customizations: reduxCartItem.customizations,
                  });
                }
              });
            }
          }
          if (tmpCoupon) {
            applyCoupon(tmpCoupon, newCart)
          } else if (couponToTry) {
            applyCoupon(couponToTry, newCart)
          } else {
            applyCoupon(tmpCoupon, newCart)
          }
        }
      });
    } else {
      setCart([]);
    }
  }, [reduxCartItems, resetCoupon]);

  const applyCoupon = (couponToApply, cartToApplyTo) => {
    if (fullCouponData?.find(c => c.couponType == COUPON_TYPES.SALE)) {
      toast.error(`Can't combine promo code with sale already in cart!`)
      console.log(`Can't combine promo code with sale already in cart!`);
      return;
    }
    if (couponToApply && !isLoadingCoupon) {
      setIsLoadingCoupon(true);
      getCouponsByCouponCode(couponToApply, (data) => {
        if (!data || data.length == 0) {
          setCart(cartToApplyTo);
          setIsLoadingCoupon(false);
          setIsLoadingCart(false);
          toast.error(`Coupon [${couponToApply}] does not apply to any products in cart!`)
          console.log(`Coupon [${couponToApply}] does not apply to any products in cart!`);
          return;
        }
        if (!data.find(c => c.active === true)) {
          setCart(cartToApplyTo);
          setIsLoadingCoupon(false);
          setIsLoadingCart(false);
          toast.error(`Coupon [${couponToApply}] is expired or has been used up!`)
          console.log(`Coupon [${couponToApply}] is expired or has been used up!`);
          return;
        }
        let newCart = [...cartToApplyTo];
        let itemsAppliedTo = 0;
        for (const cartItem of newCart) {
          const currSkuID = cartItem.skuID;
          const couponObject = data?.find(i => i?.skuIDs?.includes(currSkuID));
          if (!couponObject) {
            if (data?.[0].sellerCreated === false) {
              couponObject = data?.[0];
            }
          }
          if (couponObject && cartItem?.optionData?.price != null) {
            if (cartItem?.optionData?.originalPrice != null) {
              cartItem.optionData.price = cartItem.optionData.originalPrice;
            }
            if (cartItem?.optionData?.saleCode != null) {
              delete cartItem.optionData.saleCode;
            }
            const currPrice = cartItem.optionData.price;

            if (couponObject?.percentOff != null && couponObject?.percentOff != '') {
              cartItem.optionData.originalPrice = currPrice;
              cartItem.optionData.price = (currPrice * (100 - couponObject.percentOff)) / 100;
              cartItem.optionData.saleText = `(${new Number(couponObject.percentOff)}% OFF!)`;
              cartItem.optionData.couponCode = couponObject.couponCode;
              itemsAppliedTo++;
            } else if (couponObject?.amountOff != null && couponObject?.amountOff != '') {
              cartItem.optionData.originalPrice = currPrice;
              cartItem.optionData.price = currPrice - couponObject.amountOff;
              cartItem.optionData.saleText = `($${couponObject.amountOff} OFF!)`;
              cartItem.optionData.couponCode = couponObject.couponCode;
              itemsAppliedTo++;
            }
          }
        }
        if (itemsAppliedTo > 0) {
          setCoupon(couponToApply);
          setCart(newCart);
          setFullCouponData(data);
          if (data?.find(c => c.couponType == COUPON_TYPES.PROMO_CODE)) {
            toast.success(`Applied coupon ${couponToApply} to [${itemsAppliedTo}] item(s)!`)
          }
          console.log(`Applied coupon ${couponToApply} to [${itemsAppliedTo}] item(s)!`)
        } else {
          toast.error(`Coupon [${couponToApply}] does not apply to any products in cart!`)
          console.log(`Coupon [${couponToApply}] does not apply to any products in cart!`);
        }
        setIsLoadingCoupon(false);
        setIsLoadingCart(false);
      });
    } else {
      setCart(cartToApplyTo);
      setIsLoadingCart(false);
    }
  };

  const removeCoupon = () => {
    if (!isLoadingCoupon) {
      setIsLoadingCoupon(true);
      getCouponsByCouponCode(coupon, (data) => {
        if (!data || data.length == 0) {
          setIsLoadingCoupon(false);
          return;
        }
        if (data.find(p => p.couponType == COUPON_TYPES.PROMO_CODE)) {
          setCoupon(null);
          setFullCouponData(null);
          setResetCoupon(p => p + 1)
        }
        setIsLoadingCoupon(false);
      });
    }
  };

  // const placeOrder = () => {
  //   // Sign in check
  //   if (!userData) {
  //     setSignInDialogOpen(true);
  //     return;
  //   }

  //   if (Object.keys(cartQuantities).length > 0) {
  //     let orderData = {};
  //     orderData.date = new Date();
  //     let totalPrice = 0;
  //     cart.forEach(
  //       (cartItem) =>
  //         (totalPrice += cartItem.quantity * cartItem.productData.productPrice)
  //     );
  //     orderData.totalPrice = totalPrice;
  //     orderData.shippingStatus = "Order Placed";
  //     orderData.completed = false;

  //     let products = [];
  //     for (const [id, quantity] of Object.entries(cartQuantities)) {
  //       products.push({ id: id, quantity: quantity });
  //     }
  //     orderData.products = products;
  //     orderData.userId =
  //       userData && userData.hasOwnProperty("username")
  //         ? userData.username
  //         : "";

  //     createOrder(orderData, () => {
  //       Clear cart and redirect
  //       dispatch(clearCart());
  //       window.location.href = "/";
  //     });
  //   }
  // };

  // Atm default to not signed in; will need to replace with actual signed in
  // check
  const signedIn = userData !== null && userData !== undefined;

  return (
    <PurposeCategoryMasterLayout>
      <Toaster
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <Divider />
      <Container maxWidth="xl">
        <Typography
          align="center"
          sx={{ fontSize: { xs: "2.1rem", sm: "3rem", md: "3.5rem" } }}
          className={classes.header}
        >
          Your Cart &amp; Checkout!
        </Typography>
        <Cart
          selectedShippingInfo={selectedShippingInfo}
          setSelectedShippingInfo={setSelectedShippingInfo}
          cart={cart}
          coupon={coupon}
          dispatch={dispatch}
          classes={classes}
          cartPriceTotals={cartPriceTotals}
          setCartPriceTotals={setCartPriceTotals}
        />
        {/* <div style={{height: "36px"}}/> */}
        {(!coupon || !isLoadingCoupon) && !isLoadingCart && cart.length > 0 &&
          <div className={classes.root} style={{ marginTop: "36px", marginBottom: "16px" }}>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item className={classes.horizontalCenter}>
                <TextField
                  name="saleCode"
                  onChange={(e) => {
                    setCouponToTry(e.target.value)
                  }}
                  value={couponToTry}
                  variant="outlined"
                  size="large"
                  placeholder="Promo Code"
                  className={classes.inputOutline}
                />
              </Grid>
              <Grid item className={classes.horizontalCenter}>
                <Button
                  onClick={(e) => { applyCoupon(couponToTry, cart) }}
                  sx={{
                    py: 1,
                    px: 2.5,
                    backgroundColor: "var(--mintGreenSolid)",
                    color: "white",
                    fontWeight: "bold"
                  }}>{`Apply`}
                </Button>
              </Grid>
            </Grid>
          </div>
        }
        {coupon && !isLoadingCart && cart.length > 0 &&
          <Grid container spacing={1} justifyContent="flex-end" direction="column" alignItems="flex-end">
            <Grid item>
              <Typography
                align="right"
                // sx={{ fontSize: { xs: "1.1rem", sm: "1rem", md: "1.5rem" } }}
                //className={classes.header}
                variant="h6"
                style={{ fontWeight: "400" }}
              >
                {`Applied sale code: [${coupon}]`}
              </Typography>
            </Grid>
            {fullCouponData?.find(c => c.couponType == COUPON_TYPES.PROMO_CODE) &&
              <Grid item>
                <ButtonBase
                  onClick={removeCoupon}
                  style={{
                    // py: 1,
                    // px: 2.5,
                    // backgroundColor: "var(--coral)",
                    marginTop: "-24px",
                    color: "red",
                    fontWeight: "500"
                  }}>{`Remove Promo Code`}
                </ButtonBase>
              </Grid>
            }
          </Grid>
        }
        <div style={{ height: "24px" }} />
        {!isLoadingCart &&
          <Grid container sx={{ paddingRight: "8px", paddingLeft: "8px" }}>
            <Grid item xs={0} md={4}></Grid>
            <Grid item xs={0} md={4}></Grid>
            <Grid item xs={12} md={4}>
              <div style={{ display: "flex", direction: "row", justifyContent: "space-between" }}>
                <Typography variant="h6" style={{ fontWeight: "500" }}>Items</Typography>
                <Typography variant="h6">{`$${round(cartPriceTotals.itemTotal, 2)}`}</Typography>
              </div>
              <div style={{ display: "flex", direction: "row", justifyContent: "space-between" }}>
                <Typography variant="h6" style={{ fontWeight: "500" }}>Discounts</Typography>
                <Typography variant="h6">{`- $${round(cartPriceTotals.discountTotal, 2)}`}</Typography>
              </div>
              <div style={{ display: "flex", direction: "row", justifyContent: "space-between" }}>
                <Typography variant="h6" style={{ fontWeight: "500" }}>Shipping</Typography>
                <Typography variant="h6">{`$${round(cartPriceTotals.shippingTotal, 2)}`}</Typography>
              </div>
              <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
              <div style={{ display: "flex", direction: "row", justifyContent: "space-between" }}>
                <Typography variant="h5" style={{ fontWeight: "bold" }}>Sub Total</Typography>
                <Typography variant="h5">{`$${round(cartPriceTotals.subtotal, 2)}`}</Typography>
              </div>
              <Typography><i>&#40;Tax calculated at next step.&#41;</i></Typography>
              <br></br>
              <Typography><i>By placing this order, you agree to our Privacy Policy and Terms &amp; Conditions.</i></Typography>

            </Grid>
          </Grid>}
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "start" },
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          {!userData ? (
            <Button
              variant="outlined"
              href="/login"
              disableRipple
              className={classes.cartButton}
            >
              <Typography className={classes.cartButtonText}>
                <b>Sign in</b>
              </Typography>
            </Button>
          ) : (
            <Button
              variant="outlined"
              disableRipple
              className={classes.cartButton}
            >
              <Typography className={classes.cartButtonText}>
                <b>Guest Checkout</b>
              </Typography>
            </Button>
          )}
        </Box>
        <div className={classes.sectionSpacing}></div>
        <LoyaltyProgramCart classes={classes}/>
        <div className={classes.sectionSpacing}>
          <YourInfo
            checkoutInfo={checkoutInfo}
            setCheckoutInfo={setCheckoutInfo}
            classes={classes}
          />
        </div>
        <div className={classes.sectionSpacing}>
          <Shipping
            checkoutInfo={checkoutInfo}
            setCheckoutInfo={setCheckoutInfo}
            signedIn={signedIn}
            shippingAddresses={cartData.shippingAdresses}
            shippingMethods={cartData.shippingMethods}
            classes={classes}
          />
        </div> */}
        {cart?.length > 0 &&
          <div className={classes.sectionSpacing}>
            <StripeLayout
              cart={cart}
              coupon={coupon}
              checkoutInfo={checkoutInfo}
              taxRate={cartData.taxRate}
              classes={classes}
              cartData={cartData}
              selectedShippingInfo={selectedShippingInfo}
            />
          </div>
        }
      </Container>
      <Dialog
        onClose={() => setSignInDialogOpen(false)}
        open={signInDialogOpen}
      >
        <DialogTitle onClose={() => setSignInDialogOpen(false)}>
          <Typography>
            <b>Sign In</b>
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          You must be signed in in order to place an order. Please sign in
          before attempting to place an order.
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setSignInDialogOpen(false)}
            style={{ color: "#000000" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.recentlyViewedDiv}>
        <Container maxWidth="xl">
          <Typography align="center" className={classes.recentlyViewedHeader}>
            TRENDING
          </Typography>
        </Container>
        <GalleryView
          productPages={randomProducts}
          itemsPerRow={6}
          extraPadding={true}
          showLoadingIcon={loadingRandomProducts}
        />
      </div>
    </PurposeCategoryMasterLayout>
  );
}

export default CartLayout;
