import { Container, Typography, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import BlogPost from "./BlogPost";
import clsx from "clsx";

//Default Prop Values
import mockBlogData from "../json/blog_posts.json";
//---------------------

const numBlogPosts = 3;

const useStyles = makeStyles({
  header: {
    marginBottom: 5,
  },
  subheader: {
    marginBottom: 20,
  },
});

function KeepUp(props) {
  const classes = useStyles();

  const { blogPosts = mockBlogData } = props;

  return (
    <Container maxWidth="xl">
      <Typography
        align="center"
        variant="h5"
        className={clsx(classes.header, "roboto-medium")}
      >
        KEEP UP WITH PURPOSER
      </Typography>
      <Typography
        align="center"
        variant="subtitle1"
        className={clsx(classes.subheader, "roboto-regular")}
      >
        Read our monthly blog posts about our purposers, the impacts we make and
        important company updates.
      </Typography>
      <Grid
        container
        spacing={10}
        direction="row"
        justifyContent="space-between"
      >
        {blogPosts.posts.map((blogPost, index) => {
          if (index < numBlogPosts) {
            return <BlogPost key={index} blogPost={blogPost} />;
          }
          return <></>;
        })}
      </Grid>
    </Container>
  );
}

export default KeepUp;
