import { Container } from "aws-amplify-react";
import React, { useRef, useState, forwardRef } from "react";
import { AdminPortalManagementMaster } from "..";
import { Grid, Typography, ButtonBase, FormControl, InputLabel, Select, MenuItem, Modal, Box, Button, TextField } from "@mui/material";
import Carousel from "react-multi-carousel";

import useGalleryPages from "../../hooks/useGalleryPages";

import makeStyles from "@mui/styles/makeStyles";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getTodayReviews, getThisWeekReviews, getCustomTimeReviews, getDeletedTodayReviews, getDeletedThisWeekReviews, getDeletedCustomTimeReviews } from "../../util/products";
import Review from "../../components/Review";
import clsx from "clsx";
import AdminInsertReview from "../../components/AdminInsertReview";

const maxNumPages = 5;

const responsive = {
  break1: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles({
  galleryDiv: {
    paddingBottom: 10,
    overflowX: "hidden",
    display: "relative",
  },
  galleryContainer: {
    display: "relative",
    //marginBottom: "-16px"
  },
  galleryGrid: {
    padding: 40,
    // margin: "auto",
    marginBottom: "32px"
  },
  galleryItem: {
    paddingTop: 15,
    paddingBottom: 15,
    maxWidth: "100%",
  },
  galleryButton: {
    width: "35px",
    height: "35px",
    borderRadius: "25px",
    border: "none",
    backgroundColor: "#EAF1EF",
    margin: 7,
  },
  
  rightItemMargin: {
    margin: "auto 50px",
  },
  rightGridItem: {
    paddingLeft: 50,
  },
  gridItems: {
    marginTop: "var(--dividerHeight)",
  },
  reviewsCarousel: {
    position: "relative",
    width: "93%",
    paddingBottom: 100,
    marginTop: 10,
    margin: "auto",
    overflow: "hidden",
  },
  reviewsNavButtons: {
    position: "absolute",
    top: "29%",
    border: "1px solid black",
    borderRadius: 20,
    backgroundColor: "#FFFFFF",
  },
  reviewsPrevButton: {
    left: -10,
    margin: 10,
  },
  reviewsNextButton: {
    right: -10,
    margin: 10,
  },
  borderSection: {
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius:  5,
    padding: 5
  },
  sectionColor: {
    color: "#EE907B"
  },
  buttonStyling: {
    backgroundColor: "#EE907B",
    border: "none",
    color: "white",
    padding: "5px 35px",
    borderRadius: "3px",
    marginLeft: "20px"
  },
  birthdayMargins: {
    margin: "auto 20px"
  },
});

const CustomDots = ({ index, onClick }) => {
  const classes = useStyles();

  if (index < maxNumPages) {
    const NumberButton = (
      <ButtonBase
        onClick={(e) => {
          onClick();
          e.preventDefault();
        }}
        className={classes.galleryButton}
      >
        <Typography>{index + 1}</Typography>
      </ButtonBase>
    );

    if (index === maxNumPages - 1) {
      return (
        <>
          {NumberButton}
          <ButtonBase className={classes.galleryButton}>
            <Typography>...</Typography>
          </ButtonBase>
        </>
      );
    } else {
      return <>{NumberButton}</>;
    }
  } else {
    return <></>;
  }
};

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  return (
    <>
      <ButtonBase
        sx={{
          position: "absolute",
          width: "35px",
          height: "35px",
          borderRadius: "25px",
          border: "none",
          left: { xs: "2%", sm: "18%", md: "23%", lg: "30%", xl: "30%" },
          bottom: 7,
          backgroundColor: "#EAF1EF",
          zIndex: 50,
        }}
        onClick={previous}
      >
        <ArrowBack sx={{}} />
      </ButtonBase>
      <ButtonBase
        sx={{
          position: "absolute",
          width: "35px",
          height: "35px",
          borderRadius: "25px",
          border: "none",
          right: { xs: "2%", sm: "18%", md: "23%", lg: "30%", xl: "30%" },
          bottom: 7,
          zIndex: 50,
          backgroundColor: "#EAF1EF",
        }}
        onClick={next}
      >
        <ArrowForward sx={{}} />
      </ButtonBase>
    </>
  );
};



function ReviewsLayout() {
  let admin = true;
  let mobile = false; // for now
  const [activeReviewData, setActiveReviewData] = useState([]);
  const [deletedReviewData, setDeletedReviewData] = useState([]);
  const [loadedBlogs, setLoadedBlogs] = useState(false);
  const [loadingBlog, setLoadingBlog] = useState(false);
  const [activeReviewGallery, setActiveReviewGallery] = useState([[]]);
  const [deletedReviewGallery, setDeletedReviewGallery] = useState([[]]);
  const [activeFilter, setActiveFilter] = useState('Last 7 Days');
  const [deletedFilter, setDeletedFilter] = useState('Last 7 Days');
  
  const [activeFilters, setOrderFilters] = React.useState(["Today", "Last 7 Days", "Custom"]);
  const [deletedFilters, setSalesFilters] = React.useState(["Today", "Last 7 Days", "Custom"]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  
  const [activeFromDate, setActiveFromDate] = React.useState({});
  const [activeToDate, setActiveToDate] = React.useState({});
  const [deletedFromDate, setDeletedFromDate] = React.useState({});
  const [deletedToDate, setDeletedToDate] = React.useState({});
  const classes = useStyles();

  
  const handleActiveFilterChange = (event) => {
    setActiveFilter(event.target.value);
  };

  const handleDeletedFilterChange = (event) => {
    setDeletedFilter(event.target.value);
  };

  useGalleryPages(activeReviewData, setActiveReviewGallery, 3);
  useGalleryPages(deletedReviewData, setDeletedReviewGallery, 3);

  React.useEffect(() => {
    const aWeekAgo = new Date();
    aWeekAgo.setDate(aWeekAgo.getDate() - 7);
    setActiveFromDate(aWeekAgo)
    setActiveToDate(new Date())
    setDeletedFromDate(aWeekAgo)
    setDeletedToDate(new Date())
    getThisWeekReviews((data) => {
      setActiveReviewData(data.results);
    })
    getDeletedThisWeekReviews((data) => {
      setDeletedReviewData(data.results);
    })
    
  }, []);

  React.useEffect(() => {
    switch (activeFilter) {
      case "Today":
      getTodayReviews((data) => {
        setActiveReviewData(data.results)
      })
        break;
      case "Last 7 Days":
      getThisWeekReviews((data) => {
        setActiveReviewData(data.results)
      })
        break;
      case "Custom":
      getCustomTimeReviews(activeFromDate, activeToDate, (data) => {
        setActiveReviewData(data.results)
      })
        break;
      default:
        break;
    }
  }, [activeFilter]);

  React.useEffect(() => {
    switch (activeFilter) {
      case "Custom":
        getCustomTimeReviews(new Date(activeFromDate).toLocaleDateString(), new Date(activeToDate).toLocaleDateString(), (data) => {
          setActiveReviewData(data.results)
        })
        break;
      default:
        break;
    }

  }, [activeFromDate, activeToDate]);

  
  React.useEffect(() => {
    switch (deletedFilter) {
      case "Today":
      getDeletedTodayReviews((data) => {
        setDeletedReviewData(data.results)
      })
        break;
      case "Last 7 Days":
      getDeletedThisWeekReviews((data) => {
        setDeletedReviewData(data.results)
      })
        break;
      case "Custom":
      getDeletedCustomTimeReviews(deletedFromDate, deletedToDate, (data) => {
        setDeletedReviewData(data.results)
      })
        break;
      default:
        break;
    }
  }, [deletedFilter]);

  React.useEffect(() => {
    switch (activeFilter) {
      case "Custom":
        getDeletedCustomTimeReviews(new Date(deletedFromDate).toLocaleDateString(), new Date(deletedToDate).toLocaleDateString(), (data) => {
          setDeletedReviewData(data.results)
        })
        break;
      default:
        break;
    }

  }, [deletedFromDate, deletedToDate]);

function ProductPages(pages, admin, classes, mobile = false, deleted ) {
  let productPages = [];
  if (pages.length == 0)
  {
    return(
    <Typography style={{padding: "50px"}} align="center" className={clsx(classes.noReviewsText, "roboto-regular")}>
      No Reviews
    </Typography>)
  }
  for (let i = 0; i < pages.length && i < maxNumPages; i++) {
    productPages.push(
      <Grid
        container
        direction="column"
        //justifyContent="center"
        className={classes.galleryGrid}
        key={i}
      >
        {pages[i] != 'failed' && pages[i].map((review, index) => {
          return (
            <div style={{ height: "100%", width: "97%", border: "black solid 1px", padding: "32px", marginBottom: "16px", borderRadius: "8px" }}>
              <Review review={review} key={index} admin={true} deleted={deleted} onAdminScreen={true} setOpenConfirmDelete={setOpenConfirmDelete}/>
            </div>
          );
        })}
      </Grid>
    );
  }
  return productPages;
}

  const ConfirmDeleteModal = (props) => {
    //const {openConfirmDelete, setOpenConfirmDelete } = props;
  
    const deleteBox = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
  
    return (
      <Modal open={openConfirmDelete} handleClose={() => setOpenConfirmDelete(false)}>
        <Box sx={deleteBox}>
          <Typography variant="h5" style={{color: "var(--coral)", fontWeight: "bold"}} textAlign="center">CAREFUL</Typography>
          <div style={{height: "8px"}}/>
          <Typography  textAlign="center">You are about to delete this review. Are you sure you want to do this?</Typography>
          <div style={{height: "16px"}}/>
          <Grid container justifyContent="center" columnGap={2}>
            <Grid item>
              <Button variant="outlined" sx={{borderColor: "black", color: "black"}} onClick={() => setOpenConfirmDelete(false)}>
                Go Back
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" sx={{bgcolor: "#D8000C", color: "white", '&:hover': {
                backgroundColor: 'red',
              },}} onClick={() => setOpenConfirmDelete(false)}>
                Confirm and Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    )
  }

  return (
    <AdminPortalManagementMaster>
      <AdminInsertReview/>
      <ConfirmDeleteModal/>
      <Grid container justifyContent="space-between" style={{ padding: "40px 40px 0px 40px" }}>
        <Typography variant="h5" style={{ color: "var(--coral)", fontWeight: "bold" }}>Active Reviews</Typography>
        <Select
            value={activeFilter || ''}
            onChange={handleActiveFilterChange}
            className={clsx(classes.bubbleStatFilter)}
          >
            {activeFilters.map((option, i) => {
              return (
                <MenuItem key={option + 'menuitem'} value={option}>
                  <b>{option}</b>
                </MenuItem>
              );
            })}
          </Select>
          {activeFilter == "Custom" ?
        <div className={clsx(classes.bubbleStatFilter, classes.rightSectionWidth2)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From Date"
              disableFuture
              value={activeFromDate}
              onChange={(value => {
                if (value.$d <= activeToDate) {
                  const formattedDate = `${new String(value.$M + 1).padStart(2, '0')}/${new String(value.$D).padStart(2, '0')}/${new String(value.$y).padStart(4, '0')}`;
                  setActiveFromDate(formattedDate);
                }
              })}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To Date"
              disableFuture
              value={activeToDate}
              onChange={(value => {
                if (value.$d >= activeFromDate) {
                  const formattedDate = `${new String(value.$M + 1).padStart(2, '0')}/${new String(value.$D).padStart(2, '0')}/${new String(value.$y).padStart(4, '0')}`;
                  setActiveToDate(formattedDate);
                }
              })}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div> : ""}
      </Grid>
      <Carousel
        responsive={responsive}
        infinite={false}
        showDots={true}
        arrows={false}
        // renderButtonGroupOutside={true}
        customDot={<CustomDots />}
        customButtonGroup={<CustomButtonGroupAsArrows />}
        className={classes.galleryContainer}
      >
        {ProductPages(activeReviewGallery, admin, classes, mobile, false)}
      </Carousel>
      <div style={{ height: "var(--dividerHeight)" }} />
      <Grid container justifyContent="space-between" style={{ padding: "40px 40px 0px 40px" }}>
        <Typography variant="h5" style={{ color: "var(--coral)", fontWeight: "bold" }}>Deleted Reviews</Typography>
        <Select
            value={deletedFilter || ''}
            onChange={handleDeletedFilterChange}
            className={clsx(classes.bubbleStatFilter)}
          >
            {deletedFilters.map((option, i) => {
              return (
                <MenuItem key={option + 'menuitem'} value={option}>
                  <b>{option}</b>
                </MenuItem>
              );
            })}
          </Select>
          {deletedFilter == "Custom" ?
        <div className={clsx(classes.bubbleStatFilter, classes.rightSectionWidth2)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From Date"
              disableFuture
              value={deletedFromDate}
              onChange={(value => {
                if (value.$d <= deletedToDate) {
                  const formattedDate = `${new String(value.$M + 1).padStart(2, '0')}/${new String(value.$D).padStart(2, '0')}/${new String(value.$y).padStart(4, '0')}`;
                  setDeletedFromDate(formattedDate);
                }
              })}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To Date"
              disableFuture
              value={deletedToDate}
              onChange={(value => {
                if (value.$d >= deletedFromDate) {
                  const formattedDate = `${new String(value.$M + 1).padStart(2, '0')}/${new String(value.$D).padStart(2, '0')}/${new String(value.$y).padStart(4, '0')}`;
                  setDeletedToDate(formattedDate);
                }
              })}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div> : ""}
      </Grid>
      <Carousel
        responsive={responsive}
        infinite={false}
        showDots={true}
        arrows={false}
        // renderButtonGroupOutside={true}
        customDot={<CustomDots />}
        customButtonGroup={<CustomButtonGroupAsArrows />}
        className={classes.galleryContainer}
      >
        {ProductPages(deletedReviewGallery, admin, classes, mobile, true)}
      </Carousel>
      <div style={{ height: "var(--dividerHeight)" }} />
    </AdminPortalManagementMaster>
  )
}

//createDate

export default ReviewsLayout;