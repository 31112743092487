import { useState, useEffect } from "react";
import CarouselView from "../../components/CarouselView";
import GalleryView from "../../components/GalleryView";
import CarouselProductView from "../../components/CarouselProductView";
import CategoryMenu from "../../components/CategoryMenu";
import SupportYourView from "../../components/SupportYourView";
import {
  Container,
  Divider,
  Grid,
  Typography,
  FormControl,
  outlinedInputClasses,
  selectClasses,
  svgIconClasses,
  Select,
  MenuItem,
  menuItemClasses,
  useTheme,
  useMediaQuery,
  Box,
  ButtonBase,
  Popover,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { styled } from "@mui/styles";
import { ExpandMore } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";

import useDocumentTitle from "../../hooks/useDocumentTitle";
import { PurposeCategoryMasterLayout } from "../../layouts";
import ShopByCategoryView from "../../components/ShopByCategoryView";
import { getListOfProductsById } from "../../util/getProductsById";
import {
  getListOfPurposeCategoriesById,
  getPurposeById,
  getPurposeCategoryById,
} from "../../util/purposeCategories";
import { getListOfProductCategoriesById } from "../../util/productCategories";
import {
  handleCategoryFilter,
  handleSortFilter,
} from "../../util/filterProducts";
import useGalleryPages from "../../hooks/useGalleryPages";
import ListIcon from "@mui/icons-material/List";

//JSON filler ----------------
// Will be replaced with api call soon
import categoryData from "../../json/category_menu.json";
import ReligiousCategoryMenu from "../../components/ReligiousCategoryMenu";
import BrandSupportsHeader from "../../components/BrandSupportsHeader";
import SocialMedia from "../../components/SocialMedia";
import useDocumentDescription from "../../hooks/useDocumentDescription";
import { shuffle } from "../../util/utilities";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//---------------------------------

const useStyles = makeStyles({
  titleText: {
    fontSize: "5rem",
    color: "var(--mintGreen)",
  },
  subtitleText: {
    fontSize: "1.563rem",
    color: "var(--mintGreen)",
    marginBottom: 50,
  },
  categoryMenu: {
    marginTop: 20,
    marginLeft: 10,
  },
  categoryViewGalleryGridItem: {
    margin: "0 auto",
  },
  browseByText: {
    fontSize: "1.25rem",
  },
  categoryViewDiv: {
    marginBottom: 70,
    margin: "auto",
  },
  carouselHeader: {
    marginTop: 50,
    marginBottom: 30,
  },
  socialMedia: {
    marginTop: 50,
    marginBottom: 90,
  },
  galleryViewDiv: {
    marginBottom: 50,
    // marginRight: -50,
  },
  galleryViewMobileDiv: {
    marginBottom: 50,
    marginRight: 0,
  },
});

const TitleContainer = styled(Box)({
  textAlign: "center",
  color: "var(--mintGreenSolid)",
  margin: [[25, 0, 25, 0]],
});

const MarginBottomDiv = styled("div")({
  height: "var(--dividerHeight)",
});

const CarouselViewDiv = styled("div", {
  shouldForwardProp: (prop) => prop !== "mobile",
})(({ mobile }) => ({
  width: mobile ? "100%" : "70%",
  margin: "auto",
  height: mobile ? 330 : 550,
}));

const ButtonGrid = styled(Grid)({
  marginTop: 30,
  marginBottom: 10,
});

const BrowseByGridItem = styled(Grid)({
  display: "flex",
  alignItems: "center",
});

const DropdownButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginRight: 50,
});

const DropdownButtonMobileContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginRight: 0,
});

const StyledFormControl = styled(FormControl)({
  // maxWidth: "100%",
  [`& .${outlinedInputClasses.root}`]: {
    marginRight: 10,
    height: 40,
    borderRadius: 25,
    border: 'none',
    backgroundColor: "#F7F7F7",
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      border: 'none',
    },
    [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
      border: 'none',
    },
    [`& .${selectClasses.select}`]: {
      paddingRight: 0,
      paddingLeft: 27,
    },
  },
  [`& .${svgIconClasses.root}`]: {
    paddingRight: -0,
  },
});

const StyledMenuItem = styled(MenuItem)({
  backgroundColor: "#FFFFFF",
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
});

const DropdownButton = (props) => {
  // Structure of options is [{label: "", value: ""}, {label: "", value: ""}]
  const { label, options, selected, handleClick, isMobile } = props;

  return (
    <StyledFormControl>
      <Select
        value={selected}
        onChange={handleClick}
        renderValue={() => (
          <Typography
            align="center"
            variant="h6"
            className={"roboto-thin"}
            sx={{ fontSize: isMobile && "1rem" }}
          >
            {label}
          </Typography>
        )}
        displayEmpty
        IconComponent={ExpandMore}
        sx={{
          ".MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input":
          {
            pl: 2,
            pr: isMobile ? 4 : 4.5,
          },
          [`& .${outlinedInputClasses.root}`]: {
            height: isMobile ? 30 : 40,
          }
        }}
      >
        {options &&
          options.map((option, index) => {
            return (
              <StyledMenuItem key={index} value={option.value}>
                <Typography className={"roboto-regular"}>
                  {option.label}
                </Typography>
              </StyledMenuItem>
            );
          })}
      </Select>
    </StyledFormControl>
  );
};

function splitArray(array, chunkSize) {
  const numOfChunks = Math.ceil(array.length / chunkSize);
  const result = [];

  for (let i = 0; i < numOfChunks; i++) {
    result.push(array.slice(i * chunkSize, (i + 1) * chunkSize));
  }

  return result;
}

const PurposeLayout = () => {
  const { id, purposeName, subpurposeId, subpurposeName } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const DEFAULT_ID = 0;

  const classes = useStyles();

  const [purposeCategoryData, setPurposeCategoryData] = useState({});
  const [categories, setCategories] = useState([]);
  const [menu, setMenu] = useState([]);
  const [originalProductList, setOriginalProductList] = useState([]);
  const [currentProductList, setCurrentProductList] = useState([]);
  const [currentProductGallery, setProductGallery] = useState([[]]);
  const [categorySelected, setSelectedCategory] = useState("");
  const [sortBySelected, setSortBySelected] = useState("");
  const [supportYourPurposes, setSupportYourPurposes] = useState([]);
  const [supportCarousel1Products, setSupportCarousel1Products] =
    useState([]);
  const [supportCarousel2Products, setSupportCarousel2Products] =
    useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);

  const [sustainabilityProducts, setSustainabilityProducts] = useState([[]]);
  const [endPovertyProducts, setEndPovertyProducts] = useState([[]]);
  const [healthProducts, setHealthProducts] = useState([[]]);
  const [loadingSustainabilityProducts, setLoadingSustainabilityProducts] = useState(true);
  const [loadingEndPovertyProducts, setLoadingEndPovertyProducts] = useState(true);
  const [loadingHealthProducts, setLoadingHealthProducts] = useState(true);

  useEffect(() => {
    // Create purpose list on first render
    getPurposeById(id, (data) => {
      setPurposeCategoryData(data);
    });

    //Sustainability Products
    getPurposeCategoryById('60', (data) => {
      setSustainabilityProducts(splitArray(data, 12));
      setLoadingSustainabilityProducts(false);
    });

    //End Poverty Products
    getPurposeCategoryById('70', (data) => {
      setEndPovertyProducts(splitArray(data, 12));
      setLoadingEndPovertyProducts(false);
    });

    //Health Products
    getPurposeCategoryById('80', (data) => {
      setHealthProducts(splitArray(data, 12));
      setLoadingHealthProducts(false);
    });

  }, []);

  useEffect(() => {
    const isSubpurpose = subpurposeId !== undefined;
    getPurposeCategoryById(isSubpurpose ? subpurposeId : id, (data) => {
      setProductGallery([data]);
      const filtCategoriesArray = [];
      data.forEach(o => {
        o.categories.forEach(category => {
          if (category.higherCategoryID == -1) {
            if (!filtCategoriesArray.some(c => c.label === category.categoryName)) {
              filtCategoriesArray.push({ label: category.categoryName, value: category.productCategoryID });
            }
          }
        })
      });
      const products = shuffle(data);
      setCategories(filtCategoriesArray);
      setOriginalProductList(products);
      setCurrentProductList(products);

      setLoading(false);
    });
  }, [id, subpurposeId]);

  useDocumentTitle(
    purposeCategoryData.hasOwnProperty("name")
      ? purposeCategoryData.name
      : "Purposer"
  );

  let newDesc = "Shop for products that support " + purposeCategoryData.name + " from Purposer. Free Shipping options!";
  if (subpurposeId && purposeCategoryData) {
    let s = purposeCategoryData?.subpurposes?.filter(c => c.id?.toString() === subpurposeId)?.[0]?.name;
    newDesc = "Shop for " + purposeCategoryData.name + " - " + s + " products from Purposer. Free Shipping options!"
  }
  useDocumentDescription(newDesc)

  // Update gallery pages whenever a filter causes the product list to update
  useGalleryPages(currentProductList, setProductGallery);

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const preMainTitle = purposeCategoryData?.name ? purposeCategoryData?.name + (purposeCategoryData?.subpurposes?.filter(c => c.id?.toString() === subpurposeId)?.[0]?.name ? ' |' : '') : ""
  const mainTitle = preMainTitle?.replaceAll(' and', ' &');
  const preSubTitle = subpurposeId && purposeCategoryData && purposeCategoryData?.subpurposes?.filter(c => c.id?.toString() === subpurposeId)?.[0]?.name
  const subTitle = preSubTitle?.replaceAll(' and', ' &');


  return (
    <PurposeCategoryMasterLayout>
      {/* <Divider /> */}
      <br />
      {!isMobile && <br />}
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box display="flex" alignItems="center">
          <Typography
            // align="center"
            // display="block"
            component="span"
            //className={classes.titleText}
            variant="h1"
            className={"roboto-medium"}
            sx={{
              color: "var(--mintGreenSolid)",
              fontSize: isMobile ? "1.5rem" : "2.5rem",
              // marginTop: 4
            }}
          >
            {mainTitle}
          </Typography>
          <Typography
            variant="h2"
            component="span"
            // display="block"
            className={"roboto-regular"}
            //className={classes.subtitleText}
            sx={{
              color: "var(--mintGreenSolid)",
              fontSize: isMobile ? "1.2rem" : "1.4rem",
              marginLeft: isMobile ? 1 : 2,
              marginTop: isMobile ? 0.5 : 1
            }}
          >
            {subTitle}
          </Typography>
        </Box>
      </Box>
      {isMobile && <br />}
      {!isMobile ? (
        <Grid container direction="row">
          <Grid item xs={0} sm={0} md={2} className={classes.categoryMenu}>
            <CategoryMenu
              categoryData={[purposeCategoryData]}
              expandedCategory={subpurposeId ? subpurposeId : id}
            />
          </Grid>
          <Grid item
            xs={12}
            sm={12}
            md={9}
            className={classes.categoryViewGalleryGridItem}>
            <div className={classes.galleryViewDiv}>
              <Container maxWidth="xl">
                <DropdownButtonContainer>
                  <DropdownButton
                    isMobile={isMobile}
                    label={"Categories"}
                    options={categories}
                    selected={categorySelected}
                    handleClick={(event) => {
                      const newCategory = event.target.value;
                      setSelectedCategory(newCategory);
                      handleCategoryFilter(
                        newCategory,
                        originalProductList,
                        setCurrentProductList
                      );
                    }}
                  />
                  <div style={{ width: 35 }} />
                  <DropdownButton
                    isMobile={isMobile}
                    label={"Sort By"}
                    options={[
                      // { label: "Most Popular", value: "popular" },
                      { label: "Price High to Low", value: "highToLow" },
                      { label: "Price Low to High", value: "lowToHigh" },
                      // { label: "Sale", value: "sale" },
                    ]}
                    selected={sortBySelected}
                    handleClick={(event) => {
                      const newValue = event.target.value;
                      setSortBySelected(newValue);
                      handleSortFilter(
                        newValue,
                        currentProductList,
                        setCurrentProductList
                      );
                    }}
                  />
                </DropdownButtonContainer>
              </Container>
              <GalleryView productPages={currentProductGallery} showLoadingIcon={loading} />
            </div>
          </Grid>
        </Grid>
      ) : (
        <>
          <div className={isMobile ? classes.galleryViewMobileDiv : classes.galleryViewDiv}>
            <DropdownButtonMobileContainer
              sx={{
                marginRight: "0px",
              }}
            >
              <ButtonBase
                onClick={handleClickPopover}
                sx={{
                  marginRight: "auto",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 25,
                  backgroundColor: "#F7F7F7",
                  marginLeft: 2,
                }}
              >
                <Typography
                  align="center"
                  className={"roboto-thin"}
                  sx={{ fontSize: 16, textJustify: 'center' }}
                  variant="h6"
                  ml={2}
                >
                  {"Browse"}
                </Typography>
                <KeyboardArrowDownIcon
                  sx={{
                    fontSize: 30,
                    ml: 1,
                    color: "#A9A9A9"
                  }}
                />
              </ButtonBase>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Box sx={{ m: 1, p: 1 }}>
                  <CategoryMenu
                    categoryData={[purposeCategoryData]}
                    expandedCategory={subpurposeId ? subpurposeId : id}
                  />
                </Box>
              </Popover>
              <DropdownButton
                isMobile={isMobile}
                label={"Categories"}
                options={categories}
                selected={categorySelected}
                handleClick={(event) => {
                  const newCategory = event.target.value;
                  setSelectedCategory(newCategory);
                  handleCategoryFilter(
                    newCategory,
                    originalProductList,
                    setCurrentProductList
                  );
                }}
              />
              <div style={{ width: 5 }} />
              <DropdownButton
                isMobile={isMobile}
                label={"Sort"}
                options={[
                  // { label: "Most Popular", value: "popular" },
                  { label: "Price High to Low", value: "highToLow" },
                  { label: "Price Low to High", value: "lowToHigh" },
                  // { label: "Sale", value: "sale" },
                ]}
                selected={sortBySelected}
                handleClick={(event) => {
                  const newValue = event.target.value;
                  setSortBySelected(newValue);
                  handleSortFilter(
                    newValue,
                    currentProductList,
                    setCurrentProductList
                  );
                }}
              />
            </DropdownButtonMobileContainer>
            <GalleryView mobile productPages={currentProductGallery} showLoadingIcon={loading} />
          </div>
        </>
      )}
      {/* </Container> */}
      <MarginBottomDiv />
      <BrandSupportsHeader
        title={'Our Common Home, Planet, Trees, and Oceans'}
        title_url={'/shop/purpose/60'}
      />
      <GalleryView
        productPages={sustainabilityProducts}
        itemsPerRow={6}
        extraPadding={true}
        showLoadingIcon={loadingSustainabilityProducts}
      />
      <BrandSupportsHeader
        title={'Mental and Physical Health Initiatives'}
        title_url={'/shop/purpose/80'}
      />
      <GalleryView
        productPages={healthProducts}
        itemsPerRow={6}
        extraPadding={true}
        showLoadingIcon={loadingHealthProducts}
      />
      <BrandSupportsHeader
        title={'Poverty Solutions, Ethical Production and The Dignity of Every Person'}
        title_url={'/shop/purpose/70'}
      />
      <GalleryView
        productPages={endPovertyProducts}
        itemsPerRow={6}
        extraPadding={true}
        showLoadingIcon={loadingEndPovertyProducts}
      />
      <MarginBottomDiv />
      <div style={{ backgroundColor: "var(--mintGreenTransparent)", padding: "24px" }}>
        <ShopByCategoryView title={"Shop for your favorite people, places, and pets"} />
      </div>
      <MarginBottomDiv />
      <SocialMedia />
      <MarginBottomDiv />
      <MarginBottomDiv />
    </PurposeCategoryMasterLayout>
  );
};

export default PurposeLayout;
