import { Typography, Grid, Link, Tooltip, Container, Box } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import clsx from "clsx";
import axios from "axios";
import footerJsonData from "../json/footer.json";
import makeStyles from "@mui/styles/makeStyles";


// Token for dummy IG account until actual one is generated
const IG_TOKEN =
  "IGQWRQQmlrendtVGpiNVVjT2IzZA3pIRmprTVRwS3JPOTR3M3J2cndjQnBINENzM09kX1BKd0RhdlJJTkM0RERFYjBydFJBdk9jdmcxc0hLaV9jRmhHUEtTM2V1bUVjVkVBNUJ3ZAnJDSk9mNEU4S0NuaGxscWZAaQVkZD";
const NUM_POSTS = 8;

const styles = {
  socialMediaInstagramLink: {
    paddingBottom: 10,
    marginTop: 30,
  },
  socialMediaInstagramText: {
    color: "var(--coral)",
  },
  socialMediaInstagramImage: {
    width: "360px",
    height: "360px",
    objectFit: "cover"
  },
};

const useStyles = makeStyles({
  
    bodyText: {
      align: "center",
    },
    footerSocialMediaGridItem: {
      paddingRight: 15,
    },
    footerSocialMediaButtonGridItem: {
      padding: "0px 16px",
      display: "flex",
      alignItems: "center",
    },
    footerSocialMediaButtonIcon: {
      // width: "100%",
      // height: "auto",
      // maxHeight: "64px",
      width: "64px",
      height: "64px"
    },
});

function InstagramGallery(isLoaded, error, posts, styleClass) {
  if (error || !isLoaded) {
    return <></>;
  } else {
    return (
      posts &&
      posts.slice(0,8).map((post, index) => (
        post.media_type !== "VIDEO" ? 
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <a href={post.permalink}>
            <img src={post.media_url} alt={index} className={styleClass} />
          </a>
        </Grid>
        :
        <Grid item xs={12} sm={6} md={4} lg={3} key={index} >
          <a href={post.permalink}>
            <img src={post.thumbnail_url} alt={index} className={styleClass} />
          </a>
        </Grid>
      ))
    );
  }
}

const SocialMediaButton = (props) => {
  const { link, icon, classes } = props;
  return (
    <Grid item className={classes.footerSocialMediaButtonGridItem}>
      {link.length > 0 ? 
      (
        <a href={link} target="_blank">
          <img
            src={icon}
            alt=""
            className={classes.footerSocialMediaButtonIcon}
          />
        </a>
      )
      :
      (
      <Tooltip title="Coming soon!" placement="top" arrow>
        <a>
          <img
            src={icon}
            alt=""
            className={classes.footerSocialMediaButtonIcon}
          />
        </a>
      </Tooltip>
      )}
  </Grid>
  );
};

const SocialMediaIconsRow = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="xl">
      <div style={{ height: "var(--dividerHeight)" }} />
      <Typography className={clsx("roboto-light")} variant="h4" align="center">
        CONNECT WITH <i>PURPOSER!</i>
      </Typography>
      <div style={{ height: "16px" }} />
      <Grid container direction="row" justifyContent="center"
        // sx={{
        //  display: "flex",
        //  flexDirection: "row",
        // justifyContent: "center",
        // }}
      >
        {footerJsonData.socialMediaButtons &&
        footerJsonData.socialMediaButtons.length > 0 &&
        footerJsonData.socialMediaButtons.map((button, index) => {
          return (
            <SocialMediaButton
              key={index}
              link={button.link}
              icon={button.icon}
              classes={classes}
            />
          );
        })}
      </Grid>
      </Container>
  )
}

const SocialMedia = (props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [posts, setPosts] = React.useState(null);

  const { classes } = props;

  React.useEffect(() => {
    if (posts === null) {
      let x = axios.get('https://graph.instagram.com/me/media?fields=id,caption,media_url,permalink,media_type,thumbnail_url&access_token=' + IG_TOKEN);
      x.then((res) => {
        setPosts(res.data.data);
        setIsLoaded(true);
      }).catch((error) => {
        console.log('error loading instagram posts');
      });
    } else {
      console.log('Failed to load user');
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <SocialMediaIconsRow/>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.socialMediaInstagramLink}
      >
        <a href="https://www.instagram.com/beapurposer">
          <img
            src="https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/PUR-145_attachments/Instagram+Icon-01.png"
            alt="text"
            style={{ maxWidth: "100%", height: 40 }}
          />
        </a>
        <Typography
          display="inline"
          className={clsx(classes.socialMediaInstagramText, "roboto-light")}
          variant="h5"
        >
          <Link
            href="https://www.instagram.com/beapurposer"
            color="inherit"
            underline="none"
            target="_blank"
          >
            BeAPurposer
          </Link>
        </Typography>
      </Grid>
      <Grid container direction="row"  spacing={2}>
        {InstagramGallery(
          isLoaded,
          error,
          posts,
          classes.socialMediaInstagramImage
        )}
      </Grid>
    </Container>
  );
};

export default withStyles(styles)(SocialMedia);
