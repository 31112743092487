import { ButtonBase, Container, TextField, Typography, Grid, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import clsx from "clsx";

import MyRewardsWaysToEarnPoints from "./MyRewardsWaysToEarnPoints";
import MyRewardsRedeemProducts from "./MyRewardsRedeemProducts";
import LoyaltyProgramPointsLinear from "./LoyaltyProgramPointsLinear";
import LoyaltyProgramPointsCircle from "./LoyaltyProgramPointsCircle";

const useStyles = makeStyles({
  bodyHeaderText: {
    fontSize: "3rem",
    textDecoration: "underline",
    padding: "0px",
  },
  headerText: {
    fontSize: "3.5rem",
    fontStyle: "bold",
    padding: "0px",
  },
  box: {
    width: "100%",
    borderStyle: "solid",
    borderColor: "black",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "64px 8px 64px 8px",
    borderRadius: "36px",
  },
  cartButtonCenter: {
    display: "flex",
    justifyContent: "center",
  },
  cartButton: {
    textTransform: "none",
    marginTop: 40,
    borderRadius: 10,
    // minWidth: 170,
    // minHeight: 55,
    borderColor: "#000000",
    "&:hover": {
      borderColor: "#000000",
      backgroundColor: "inherit",
    },
  },
  checkoutButton: {
    textTransform: "none",
    marginTop: 40,
    borderRadius: 10,
    // minWidth: 170,
    // minHeight: 55,
    backgroundColor: "var(--coral)",
    borderColor: "#000000",
    "&:hover": {
      borderColor: "#000000",
      backgroundColor: "inherit",
    },
  },
  cartButtonText: {
    // fontSize: "1.35rem",
    color: "#000000",
  },
  checkoutButtonText: {
    // fontSize: "1.35rem",
    color: "white",
  },
  divMarginBottom: { height: "var(--dividerHeight)" },
});

const MyRewards = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl" center>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <h1 style={{ textAlign: "center" }}>Loyalty Program</h1>
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <h4 style={{ textAlign: "center" }}>Coming Soon</h4>
      </div>
      <Grid container alignItems="flex-start" spacing={12} >
        <Grid item xs={12} sm={12} md={6}>
          <div className={classes.box} center>
            <LoyaltyProgramPointsCircle type={"points"} />
            <div className={classes.cartButtonCenter}>
              <Button
                disabled={true}
                variant="outlined"
                disableRipple
                className={classes.cartButton}
              >
                <Typography className={classes.cartButtonText}>
                  <b>Learn More</b>
                </Typography>
              </Button>
            </div>
          </div>

        </Grid>
        <Grid item xs={12} sm={12} md={6} center>
          <div className={classes.box}>
            <LoyaltyProgramPointsCircle type={"cash"} />
            <div className={classes.cartButtonCenter}>
              <Button
                variant="outlined"
                disabled={true}
                disableRipple
                className={classes.cartButton}
              >
                <Typography className={classes.cartButtonText}>
                  <b>Learn More</b>
                </Typography>
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={classes.divMarginBottom} />
      {/* <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography className={classes.bodyHeaderText}>Redeem For Products</Typography>
      </div>
      <MyRewardsRedeemProducts />
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography className={classes.bodyHeaderText}>Redeem For Cash Discounts</Typography>
      </div>
      <div className={classes.divMarginBottom} />
      <LoyaltyProgramPointsLinear />
      <div className={classes.cartButtonCenter}>
        <Button
          variant="outlined"
          href="/cart"
          disableRipple
          className={classes.checkoutButton}
        >
          <Typography className={classes.checkoutButtonText}>
            <b>Apply to Checkout</b>
          </Typography>
        </Button>
      </div>
      <div className={classes.divMarginBottom} />
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography className={classes.bodyHeaderText}>Ways To Earn Points</Typography>
      </div>
      <div className={classes.divMarginBottom} />
      <MyRewardsWaysToEarnPoints /> */}
    </Container>
  );
};

export default MyRewards;
