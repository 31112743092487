import { Button, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles({
  wrapperDiv: {
    textAlign: "center",
    backgroundColor: "inherit",
    height: "100%",
    display: "flex",
    flexFlow: "column",
  },
  imageContainer: {
    height: 200,
    display: "flex",
    alignItems: "center",
  },
  image: {
    width: "30%",
    margin: "auto",
  },
  textContainer: {
    flexGrow: 1,
    paddingBottom: 35,
  },
  descriptionText: {
    lineHeight: 1.8,
    width: "70%",
    margin: "auto",
  },
  moreInfoButton: {
    backgroundColor: "#000000",
    borderRadius: 15,
    width: "50%",
    maxWidth: 186,
    height: 35,
    margin: "auto",
    "&:hover": {
      backgroundColor: "#000000",
    },
  },
  moreInfoButtonText: {
    color: "#FFFFFF",
  },
});

function SupportInfo(props) {
  const classes = useStyles();

  const { infoSection, boldTitles = false } = props;
  const { header, image, description, url } = infoSection;

  return header && image && description ? (
    <div className={classes.wrapperDiv}>
      <Typography
        align="center"
        variant="h6"
        className={boldTitles ? "roboto-bold" : "roboto-regular"}
      >
        {header}
      </Typography>
      <div className={classes.imageContainer}>
        <img src={image} alt="" className={classes.image} />
      </div>
      <div className={classes.textContainer}>
        <Typography
          align="center"
          variant="subtitle1"
          className={clsx(classes.descriptionText, "roboto-regular")}
        >
          {description}
        </Typography>
      </div>
      {/* <Button href={url} disableRipple className={classes.moreInfoButton}>
        <Typography className={classes.moreInfoButtonText}>
          MORE INFO
        </Typography>
      </Button> */}
    </div>
  ) : (
    <></>
  );
}

export default SupportInfo;
