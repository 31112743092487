import { getSessionJwt } from "./utilities";
const {
  LEGACY_REST_API_ENDPOINT, 
  NODE_API_ENDPOINT
} = require("./constants");

const getToken = (username) => {
  return localStorage.getItem(
    `CognitoIdentityServiceProvider.59j1dr8daanknc9nil1g10gef9.${username}.idToken`
  );
};

async function createProductListing(listing, sellerID, callback) {
  const jwtToken = await getSessionJwt();
  listing.sellerID = sellerID;
  fetch(NODE_API_ENDPOINT + "/create-product", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(listing),
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      return null;
    })
    .then(
      (data) => callback(data),
      (error) => console.log(error)
    );
}

async function getListingsBySeller(id, showAll = false, callback) {
  const url = NODE_API_ENDPOINT + "/get-products-by-seller-id?id=" + id + (showAll ? "&showAll=true" : "")
  fetch(url)
    .then((response) => {
      if (response.status === 200) return response.json();
      return "failed";
    })
    .then(
      (data) => {
        callback(data)
      },
      (error) => console.log(error)
    );
}

async function updateProductListing(listing, sellerID, callback) {
  listing.sellerID = sellerID;
  const jwtToken = await getSessionJwt();
  fetch(NODE_API_ENDPOINT + "/update-product", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(listing),
  })
  .then((response) => {
    if (response.status === 200) return response.json();
    return null;
  })
  .then(
    (data) => {callback(data)},
    (error) => console.log(error)
  );
}

async function updateProductSequence(arrayOfproductIDAndSequence, sellerID, callback) {
  const apiBody = {
    arrayOfproductIDAndSequence: arrayOfproductIDAndSequence,
    sellerID: sellerID
  }
  const jwtToken = await getSessionJwt();
  fetch(NODE_API_ENDPOINT + "/update-product-sequence", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(apiBody),
  })
  .then((response) => {
    if (response.status === 200) return response.json();
    return null;
  })
  .then(
    (data) => {callback(data)},
    (error) => console.log(error)
  );
}

async function deactivateProductListing(productID, sellerID, callback) {
  const apiBody = {
    productID: productID,
    sellerID: sellerID
  }
  const jwtToken = await getSessionJwt();
  fetch(NODE_API_ENDPOINT + "/deactivate-product", {
    method: "POST",
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(apiBody),
  })
  .then((response) => {
    if (response.status === 200) return response.json();
    return null;
  })
  .then(
    (data) => {callback(data)},
    (error) => console.log(error)
  );
}

export {
  createProductListing,
  updateProductListing,
  deactivateProductListing,
  getListingsBySeller,
  updateProductSequence,
};
