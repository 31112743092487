import FingerprintJS from "@fingerprintjs/fingerprintjs";
const {
  NODE_API_ENDPOINT
} = require("./constants");

async function testKlaviyoAPI(callback) {
  // Create SetupIntent as soon as the page loads
  fetch(NODE_API_ENDPOINT + "/create-empty-list", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({}),
  })
    .then((res) => res.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function addToMarketingList(email, phone, callback) {

  const fingerprint = await FingerprintJS.load();
  const fingerprintResults = await fingerprint.get();
  const visitorId = fingerprintResults.visitorId;
  const apiBody = {
    email: email ? email : undefined,
    phoneNumber: phone ? phone : undefined,
    visitorId: visitorId ? visitorId : undefined,
  }
  
  fetch(NODE_API_ENDPOINT + "/add-to-list", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(apiBody),
  })
    .then((res) => res.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function addEmailToMarketingList(email, callback) {

  const apiBody = {
    email: email,
  }
  
  fetch(NODE_API_ENDPOINT + "/add-email-to-list", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(apiBody),
  })
    .then((res) => res.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function checkFirstTimeCouponList(email, phone, callback) {

  const fingerprint = await FingerprintJS.load();
  const fingerprintResults = await fingerprint.get();
  const visitorId = fingerprintResults.visitorId;
  const apiBody = {
    email: email ? email : undefined,
    phoneNumber: phone ? phone : undefined,
    visitorId: visitorId ? visitorId : undefined,
  }
  
  fetch(NODE_API_ENDPOINT + "/check-if-added", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(apiBody),
  })
    .then((res) => res.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

export { testKlaviyoAPI, addToMarketingList, addEmailToMarketingList, checkFirstTimeCouponList };
