import { Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import lightBulbOutline from "../img/LightBulb_Outline.png";

const fillerData = [
  {
    date: "2020",
    content:
      "The idea of Purposer was born in 2020 amidst the craziness of the pandemic. We, like everyone else, experienced a rise in conscious consumerism and the desire for greater transparency in online shopping.",
  },
  {
    date: "2021",
    content:
      "After months of brainstorming, we started designing the platform in 2021.",
  },
  {
    date: "Fall 2021",
    content:
      "In Fall of 2021, our Brand Ambassadors attended  markets and events in Southern California to meet sellers and get their opinion on online marketplaces.",
  },
  {
    date: "April 2022",
    content:
      "In April of 2022 we took part in our first ever pitch competition at USD and were runners up!",
  },
  {
    date: "October 2022",
    content:
      "In October of 2022, we participated in Startup San Diego incubator program. Our founder also gave a talk at San Diego startup week on entrepreneurship.",
  },
  {
    date: "July 2022",
    content:
      "In July of 2022, we showed off our prototype and won the San Diego Founders Live pitch competition!",
  },
  {
    date: "Summer 2022",
    content:
      "In summer of 2022, our lead software engineers joined the team and started working on making this idea into a reality.",
  },
  {
    date: "Spring 2023",
    content:
      "Our site went live in the spring of 2023!",
  },
];

const useStyles = makeStyles(() => ({
  paper: {
    padding: "6px 16px",
    marginBottom: "32px",
  },
  secondaryTail: {
    backgroundColor: "var(--mintGreen)",
  },
  timelineDot: {
    borderColor: "var(--mintGreen)",
  },
  iconBulb: {
    height: "100%",
    maxWidth: "100%",
  },
  iconsDiv: {
    height: 30,
    width: 30,
    margin: "5px 5px",
    display: "flex",
    justifyContent: "center",
  },
}));

const TimeLineData = ({ classes }) => {
  return fillerData.map((filler, index) => {
    return (
      <TimelineItem key={index}>
        <TimelineOppositeContent>
          <Typography variant="body2" color="textSecondary">
            {filler.date}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot className={classes.timelineDot} variant="outlined">
            <div className={classes.iconsDiv}>
              <img
                src={lightBulbOutline}
                alt="outlined Light Bulb"
                className={classes.iconBulb}
              />
            </div>
          </TimelineDot>
          <TimelineConnector className={classes.secondaryTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography
              variant="h3"
              style={{ fontSize: "16px" }}
            >
              {filler.content}
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    );
  });
};

const PurposerHistory = () => {
  const classes = useStyles();

  return (
    <Timeline position="alternate">
      <TimeLineData classes={classes} />
    </Timeline>
  );
};

export default PurposerHistory;
