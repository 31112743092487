import { Box, Typography, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ManagementMasterLayout } from "..";
import DeactivateConfirmPopup from "../../components/DeactivateConfirmPopup";
import sellerDashboardJsonData from "../../json/seller_dashboard.json";
import { deactivateSeller, getSellerById } from "../../util/sellers";
import { getUserIdFromAuth, isPurposerUserAdmin } from "../../util/utilities";

const ShopSettingsLayout = () => {
  const sellerDashboardData = sellerDashboardJsonData;
  const [deactivatePopup, setDeactivatePopup] = useState(false);
  const [activatePopup, setActivatePopup] = useState(false);
  const [isStoreActive, setIsStoreActive] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { id: sellerIDparam } = useParams();
  const history = useHistory();

  useEffect(() => {
    getSellerById(sellerIDparam, (data) => {
      if (data === "failed") {
        return;
      } else if (data && !data.hasOwnProperty("error")) {
        getUserIdFromAuth((userId) => {
          if (!data?.adminUserID?.toLowerCase() || !userId?.toLowerCase()) return;
          if (data.adminUserID.toLowerCase() == userId?.toLowerCase() || isPurposerUserAdmin(userId)) {
            //Valid user on page
          } else {
            history.push(`/`);
            return;
          }
        })
        setIsStoreActive(data?.active);
      } else {
        console.log("Error getting seller by id");
      }
    });
  }, [sellerIDparam]);

    //TODO: ADD CALL FROM POPUP

  const handleSellerChangeActivation = async () => {
    if (!isLoading && isStoreActive !== null) {
      setIsLoading(true);
      if (isStoreActive === true) {
        deactivateSeller(sellerIDparam, (data) => {
          if (!(data === "Failed")) {
            history.go(0);
            setIsLoading(false);
          } else {
            alert("Failed to deactivate!")
            setIsLoading(false);
          }
        });
      } else if (isStoreActive === false) {
        //TODO: add re-activation
        // activateSeller(sellerIDparam, (data) => {
        //   if (!(data === "Failed")) {
        //     history.go(0);
        //     setIsLoading(false);
        //   } else {
        //     alert("Failed to activate!")
        //     setIsLoading(false);
        //   }
        // });
        setIsLoading(false);
      }
    }
  };



  return (
    <ManagementMasterLayout
      title="Store Settings"
      sellerName={sellerDashboardData.name}
      sellerPicture={sellerDashboardData.pic}
    >
      <DeactivateConfirmPopup isSeller={true} deactivatePopupView={deactivatePopup} setDeactivatePopup={setDeactivatePopup} handleSubmit={handleSellerChangeActivation} />
      <DeactivateConfirmPopup isSeller={true} isReactivating={true} deactivatePopupView={activatePopup} setDeactivatePopup={setActivatePopup} />
      <Box sx={{ padding: "36px" }}>
        {isStoreActive === true ?
          <>
            <Typography
              display="inline"
              variant="h5"
              className={"roboto-medium"}
              sx={{ color: "var(--coral)" }}
            >
              Deactivate Store
            </Typography>
            <Typography>Your store is currently <b>active</b>.</Typography>
            <div style={{ height: "8px" }} />
            <Typography>If you no longer want your store and product listings active on Purposer, you can deactivate your store. Some of your data including order information may still be available only to those sellers who have made purchases from you. When you are ready to come back, simply come back to this page to reactivate your store. Please let us know if you have any questions by emailing our team at <a href="mailto:hello@purposer.com">hello@purposer.com</a></Typography>
            <br />
            <Button variant="outlined" color="error" onClick={() => setDeactivatePopup(!deactivatePopup)}>Deactivate Store</Button>
          </>
          :
          isStoreActive === false ?
            <>
              <Typography
                display="inline"
                variant="h5"
                className={"roboto-medium"}
                sx={{ color: "var(--coral)" }}
              >
                Activate Store
              </Typography>
              <Typography>Your store is currently <b>deactivated</b>.</Typography>
              <div style={{ height: "8px" }} />
              <Typography>When you reactivate your store, your store page, products listings and other information will become available again on your seller dashboard and your store will be live to shoppers. You can always edit this information in your seller dashboard. Please let us know if you have any questions by emailing our team at <a href="mailto:hello@purposer.com">hello@purposer.com</a></Typography>
              <br />
              <Button variant="outlined" color="success" onClick={() => setActivatePopup(!activatePopup)}>Activate Store</Button>
            </>
            :
            <Typography
              display="inline"
              variant="h5"
              className={"roboto-medium"}
              sx={{ color: "var(--coral)" }}
            >
              Loading...
            </Typography>
        }
      </Box>

    </ManagementMasterLayout>
  )
}

export default ShopSettingsLayout;