import { Grid, Typography, ButtonBase, CircularProgress, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import ProductView from "./ProductView.js";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";

const maxNumPages = 5;

const responsive = {
  break1: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles({
  galleryDiv: {
    paddingBottom: 10,
    overflowX: "hidden",
    display: "relative",
  },
  // galleryContainer: {
  //   display: "relative",
  // },
  galleryGrid: {
    paddingBottom: 40,
    margin: "auto",
  },
  galleryItem: {
    paddingTop: 15,
    paddingBottom: 15,
    maxWidth: "100%",
  },
  galleryButton: {
    width: "35px",
    height: "35px",
    borderRadius: "25px",
    border: "none",
    backgroundColor: "#EAF1EF",
    margin: 7,
  },
  galleryButtonSelected: {
    width: "35px",
    height: "35px",
    borderRadius: "25px",
    border: "none",
    backgroundColor: "#cdd1d0",
    margin: 7,
  },
  ellipsesButton: {
    width: "35px",
    height: "35px",
    borderRadius: "25px",
    border: "none",
    margin: 7,
  },
});


const CustomDots = ({ index, onClick, active, mainDivName, pageLength, clickedFunction, selectedPage }) => {
  const classes = useStyles();

  const NumberButton = (
    <ButtonBase
      onClick={(e) => {
        onClick();
        document.getElementById(mainDivName).scrollIntoView({ behavior: "smooth" });
        e.preventDefault();
      }}
      className={active ? classes.galleryButtonSelected : classes.galleryButton}
    >
      <Typography>{index + 1}</Typography>
    </ButtonBase>
  );
  if (active && selectedPage != index) {
    clickedFunction(index);
  }
  let closeToOne = false;
  let closeToEnd = false;
  if (selectedPage - 1 <= 0) {
    closeToOne = true;
  }

  if (selectedPage + 2 >= pageLength) {
    closeToEnd = true;
  }

  if (pageLength <= maxNumPages) {
    return <>{NumberButton}</>;
  }
  
  if ((closeToEnd || (!closeToEnd && !closeToOne)) && (index + 1) === 1) { 
    return (
      <>
          {NumberButton}
        <ButtonBase 
          className={classes.ellipsesButton}
          disabled={true}
        >
          <Typography>...</Typography>
        </ButtonBase>
      </>
    );
  } else if ((closeToOne || (!closeToEnd && !closeToOne)) && (index + 1) === pageLength) {

    return (
      <>
        <ButtonBase 
          className={classes.ellipsesButton}
          disabled={true}
          >
          <Typography>...</Typography>
        </ButtonBase>
          {NumberButton}
      </>
    );
  } else if (Math.abs(selectedPage - index) <= 1){
    return <>{NumberButton}</>;
  } else {
    return <></>;
  }
};

// const CustomDotsOld = ({ index, onClick, mainDivName, pageLength }) => {
//   const classes = useStyles();

//   if (index < maxNumPages || (index + 1) === pageLength) {
//     const NumberButton = (
//       <ButtonBase
//         onClick={(e) => {
//           onClick();
//           document.getElementById(mainDivName).scrollIntoView({ behavior: "smooth" });
//           e.preventDefault();
//         }}
//         className={classes.galleryButton}
//       >
//         <Typography>{index + 1}</Typography>
//       </ButtonBase>
//     );

//     if ((index + 1) === pageLength && pageLength + 1 > maxNumPages) {
//       return (
//         <>
//           <ButtonBase 
//             className={classes.ellipsesButton}
//             disabled={true}
//           >
//             <Typography>...</Typography>
//           </ButtonBase>
//           {NumberButton}
//         </>
//       );
//     } else {
//       return <>{NumberButton}</>;
//     }
//   } else {
//     return <></>;
//   }
// };

function ProductPages(pages, admin, classes, mobile = false, itemsPerRow, extraPadding, moveRight, moveLeft) {
  let productPages = [];

  //convert pages from a 2d array to a 1d array
  const lengthOfProductArray = pages?.flat()?.length;
  var productIndex = 0;
  // for (let i = 0; i < pages.length && i < maxNumPages; i++) {
    for (let i = 0; i < pages.length; i++) {
    productPages.push(
      <Grid
        container
        direction="row"
        justifyContent="center"
        className={classes.galleryGrid}
        style={{
          paddingLeft: extraPadding && !mobile ? '7.5%' : undefined,
          paddingRight: extraPadding && !mobile ? '7.5%' : undefined,
        }}

        key={i}
      >
        {pages[i] != 'failed' && pages[i].map((product, index) => {
          productIndex++;
          return (
            <Grid
              item
              xs={12 / itemsPerRow}
              key={index + 'galleryview'}
              className={classes.galleryItem}
              style={{
                paddingLeft: mobile ? 7 : 20,
                paddingRight: mobile ? 7 : 20,
              }}
            >
              <ProductView
                info={product}
                admin={admin}
                mobile={mobile}
                index={productIndex - 1}
                lengthOfProductArray={lengthOfProductArray}
                moveRight={moveRight}
                moveLeft={moveLeft}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return productPages;
}

const CustomButtonGroupAsArrows = ({ next, previous, mainDivName }) => {
  return (
    <>
      <ButtonBase
        sx={{
          position: "absolute",
          width: "35px",
          height: "35px",
          borderRadius: "25px",
          border: "none",
          left: { xs: "2%", sm: "18%", md: "23%", lg: "30%", xl: "30%" },
          bottom: 7,
          backgroundColor: "#EAF1EF",
          zIndex: 50,
        }}
        onClick={(e) => {
          previous();
          document.getElementById(mainDivName).scrollIntoView({ behavior: "smooth" });
          e.preventDefault();
        }}
      >
        <ArrowBack sx={{}} />
      </ButtonBase>
      <ButtonBase
        sx={{
          position: "absolute",
          width: "35px",
          height: "35px",
          borderRadius: "25px",
          border: "none",
          right: { xs: "2%", sm: "18%", md: "23%", lg: "30%", xl: "30%" },
          bottom: 7,
          zIndex: 50,
          backgroundColor: "#EAF1EF",
        }}
        onClick={(e) => {
          next();
          document.getElementById(mainDivName).scrollIntoView({ behavior: "smooth" });
          e.preventDefault();
        }}
      >
        <ArrowForward sx={{}} />
      </ButtonBase>
    </>
  );
};

function GalleryView(props) {
  const classes = useStyles();
  const {
    productPages,
    admin = false,
    mobile = false,
    itemsPerRow = 4,
    extraPadding = false,
    showLoadingIcon = undefined,
    moveRight = () => { },
    moveLeft = () => { },
  } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = mobile || matches;

  const randomNum = Math.floor(Math.random() * 100000) + 1;
  const mainDivName = "box" + randomNum;

  const [selectedPage, setSelectedPage] = useState(0);

  const clickedFunction = (pageNumber) => {
    setSelectedPage(pageNumber);
  }

  const [productPageData, setProductPageData] = useState(null);
  useEffect(() => {
    if (productPages) {
      setProductPageData(productPages);
    }
  }, [productPages]);

  return (
    <div className={classes.galleryDiv} id={mainDivName}>
      {(showLoadingIcon !== undefined && showLoadingIcon) ?
        <Grid
          sx={{
            mt: 3,
            mb: 3,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress sx={{ color: "var(--coral)" }} size={150} />
        </Grid>
        : productPageData && productPageData.length > 0 ?
          <Carousel
            responsive={responsive}
            infinite={false}
            showDots={true}
            arrows={false}
            // renderButtonGroupOutside={true}
            customDot={<CustomDots mainDivName={mainDivName} pageLength={productPageData.length} clickedFunction={clickedFunction} selectedPage={selectedPage} />}
            customButtonGroup={<CustomButtonGroupAsArrows mainDivName={mainDivName} />}
            className={classes.galleryContainer}
          >
            {ProductPages(productPageData, admin, classes, isMobile, itemsPerRow, extraPadding, moveRight, moveLeft)}
          </Carousel>
          : ((showLoadingIcon !== undefined && !showLoadingIcon) && productPageData && productPageData.length == 0) ?
            <Typography align="center" variant="h5" className={"roboto-medium"}>
              No Listings
            </Typography>
            :
            <></>
      }
    </div>
  );
}

export default GalleryView;
