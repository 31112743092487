import { ButtonBase, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import gIcon from "../img/g-logo.png";
import fIcon from "../img/f_logo_RGB-Blue_58.png";
import aIcon from "../img/Logo-SIWA-Left-aligned-Black-Large@2x.png";
import clsx from "clsx";

const useStyles = makeStyles({
  logoDiv: {
    width: "15%",
    height: 54,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoImg: {
    width: "auto",
    maxWidth: "100%",
  },
  text: {
    paddingLeft: 8,
  },
  googleText: {
    color: "#0000008A",
  },
  facebookText: {
    color: "#1877F2",
  },
  appleText: {
    color: "#000000",
  },
  buttonBase: {
    width: "80%",
    marginBottom: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: 15,
    justifyContent: "flex-start",
    paddingLeft: 8,
    paddingRight: 8,
  },
  verticalAlign: {
    display: "flex",
    alignItems: "center",
  },
});

const FederatedButtons = (props) => {
  const classes = useStyles();
  const { variant, handler } = props;

  return (
    <ButtonBase className={classes.buttonBase} onClick={handler}>
      <div className={classes.logoDiv}>
        <img
          src={
            variant === "Google"
              ? gIcon
              : variant === "Facebook"
              ? fIcon
              : variant === "Apple"
              ? aIcon
              : "none"
          }
          className={classes.logoImg}
          style={{ maxHeight: variant === "Apple" ? "100%" : "70%" }}
          alt={variant}
        />
      </div>
      <Typography
        className={clsx(
          classes.text,
          variant === "Google" && classes.googleText,
          variant === "Facebook" && classes.facebookText,
          variant === "Apple" && classes.appleText,
          "roboto-medium"
        )}
        variant="body1"
        align="left"
      >
        {variant === "Google" ? (
          <>Sign in with Google</>
        ) : variant === "Facebook" ? (
          <>Login with Facebook</>
        ) : variant === "Apple" ? (
          <>Sign in with Apple</>
        ) : (
          "none"
        )}
      </Typography>
    </ButtonBase>
  );
};

export default FederatedButtons;
