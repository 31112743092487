import { Avatar, Grid, Typography, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState, useEffect } from "react";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";

const useStyles = makeStyles((theme) => ({
  imageAvatar: {
    width: "50%",
    height: "50%",
    margin: "auto",

  },
  imageAvatarHomepage: {
    width: "80%",
    height: "80%",
    margin: "auto",
  },
  container: {
    width: "80%",
    margin: "auto",
    marginTop: 13,
  },
  categoryText: {
    fontWeight: 400,
    textAlign: "center",
    marginTop: 10,
    fontSize: "18px"
  },
  text: {
    fontWeight: 400,
    fontSize: "22px",
    textShadow: "2px 3px 5px rgba(0,0,0,0.2)",
  },
  subtext: {
    fontWeight: 400,
    marginTop: 5,
    fontSize: "14px",
    textShadow: "2px 3px 5px rgba(0,0,0,0.2)",
  },
  mintGreenBackground: {
    backgroundColor: "#E8F0EC",
    height: "8rem",
    width: "100%",
    marginTop: theme.spacing(1),
  },
  purposeMargins: {
    marginLeft: theme.spacing(1),
  },
}));

const PurposeBubble = ({ classes, item, homePage }) => (
  <Grid item xs>
    <a href={"/shop/purpose/" + item.id + "/" + item.name.replace(/\s/g, '-')}>
      <div className={classes.container}>
        <Avatar
          className={
            homePage ? classes.imageAvatarHomepage : classes.imageAvatar
          }
          src={item.img}
          alt={item.name}
        />
      </div>
      <Typography className={classes.categoryText}>{item.name}</Typography>
    </a>
  </Grid>
);

const PURPOSE_DATA = [
  {
    "id": 60,
    "higherPurposeID": -1,
    "name": "Sustainability",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Eco-Friendly.jpg",
    "description": "Reduce waste, shop sustainably",
  },
  {
    "id": 70,
    "higherPurposeID": -1,
    "name": "End Poverty",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/end-poverty-new.png",
    "description": null,
  },
  {
    "id": 80,
    "higherPurposeID": -1,
    "name": "Healthy Living",
    "mobileName": "Health",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Healthy+Living.jpg",
    "description": null,
  },
  {
    "id": 100,
    "higherPurposeID": -1,
    "name": "Community",
    "mobileName": "Community",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Community+Support.jpg",
    "description": null,
  },
  {
    "id": 110,
    "higherPurposeID": -1,
    "name": "Faith Life",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Faith+Life.jpg",
    "description": null,
  },
  {
    "id": 90,
    "higherPurposeID": -1,
    "name": "Wildlife",
    "mobileName": "Wildlife",
    "img": "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Animals+%26+Wildlife.jpg",
    "description": null,
  },
];

const PurposeCategoryView = ({ homePage = false }) => {
  const classes = useStyles();
  const mobile = useMobileMediaQuery();

  return (
    <div className={classes.mintGreenBackground}>
      <Container maxWidth={"xl"}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          columnSpacing={1}
        >
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <Container maxWidth={"xs"}>
            <Typography className={classes.text}>
              Every brand has a purpose
            </Typography>
            <Typography className={classes.subtext}>
              Shop for products across 100+ categories.
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={9} sm={9} md={9} lg={9}>
          <Grid container>
            {PURPOSE_DATA.map((item, i) => {
              return (
                <PurposeBubble
                  key={i}
                  mobile={mobile}
                  classes={classes}
                  item={item}
                  homePage={homePage}
                />
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      </Container>
    </div>
  );
};

export default PurposeCategoryView;
