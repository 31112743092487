import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  Autocomplete,
  TextField,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import TrackingandShippingPopup from "./TrackingandShippingPopup";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { round, shortenUUID, formatDate } from '../util/utilities'
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import ExportToCSVButton from "./ExportToCSVButton";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  noOrdersCell: {
    border: "none",
  },
  tableRows: {
    backgroundColor: "",
  },
}));

const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        size="large"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        size="large"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="large"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        size="large"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TrackingandShippingCompletedView = (props) => {
  const { trackShipData, filter, setFilter, title = "", handleChangeShippingInfo, handleSaveShippingInfo, handleMarkOrderAsSubmitted } = props;
  const classes = useStyles1();
  const [orderView, setOrderView] = useState(-1);
  const [page, setPage] = useState(0);
  const [state, setState] = useState({
    filter: "",
  });
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const emptyRows = trackShipData
    ? rowsPerPage -
    Math.min(rowsPerPage, trackShipData.length - page * rowsPerPage)
    : 4;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const SHIPPING_CARRIER_OPTIONS = ['UPS', 'USPS', 'FedEx', 'DHL', 'Other'];

  const formatDataForCsvExport = () => {

    let csvData = []
    trackShipData?.forEach((row) => {
      let currOrder = {
        orderID: shortenUUID(row.orderID),
        orderDate: formatDate(row.orderDateTime),
        totalItemsAmt: "$" + round(row.totalItemsAmt, 2),
        totalShippingAmt: "$" + round(row.totalShippingAmt, 2),
        status: row.status === 'Submitted' ? 'Completed' : row.status,
        shippingTrackingCarrier: row.shippingTrackingCarrier,
        shippingTrackingNumber: row.shippingTrackingNumber,
      }
      csvData.push(currOrder);
    });

    return csvData;
  };

  return (
    <>
      {orderView !== -1 ? <TrackingandShippingPopup row={orderView} setOrderView={setOrderView} handleMarkOrderAsSubmitted={handleMarkOrderAsSubmitted} /> : <></>}
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h6">
            <b>{title}</b>
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <ExportToCSVButton
            csvData={formatDataForCsvExport()}
            fileName={'completedOrders'}
          />
        </Grid>
        <Grid item xs={1}>
          <FormControl
            variant="outlined"
            size="small"
            sx={{
              minWidth: 120,
              float: "right",
              marginBottom: "10px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "7px",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "var(--mintGreen)",
                  color: "var(--mintGreen)",
                },
              },
            }}
          >
            <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
            <Select
              onChange={(e) => {
                setFilter(e.target.value)
              }}
              label="Filter"
              inputProps={{
                name: "filter",
                id: "outlined-age-native-simple",
              }}
              value={filter}
            >
              <MenuItem value="Newest First">Newest First</MenuItem>
              <MenuItem value="Oldest First">Oldest First</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        {/* <ExportToCSVButton
          csvData={formatDataForCsvExport()}
          fileName={'completedOrders'}
        /> */}
        <Table className={"string"} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography>
                  <b>Order No.</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>
                  <b>Date</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>
                  <b>Total Items $</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>
                  <b>Total Shipping $</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>
                  <b>Status</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>
                  <b>Tracking &#40;Optional&#41;</b>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trackShipData && trackShipData.length > 0 ? (
              (rowsPerPage > 0
                ? trackShipData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : trackShipData
              ).map((row) => (
                <TableRow key={row.orderID + 'row'} className={classes.tableRows}>
                  <TableCell component="th" scope="row" align="center">
                    <Button
                      onClick={() => setOrderView(trackShipData.find(({ orderID }) => orderID === row.orderID))}
                    >
                      <Typography>
                        <b>{shortenUUID(row.orderID)}</b>
                      </Typography>
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      <b>{formatDate(row.orderDateTime)}</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      <b>{"$" + round(row.totalItemsAmt, 2)}</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      <b>{"$" + round(row.totalShippingAmt, 2)}</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      <b>{row.status}</b>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Autocomplete
                      size="small"
                      options={SHIPPING_CARRIER_OPTIONS}
                      onChange={(e, value) => {
                        handleChangeShippingInfo(row.orderID, value, row.shippingTrackingNumber)
                      }}
                      defaultValue={row.shippingTrackingCarrier}
                      getOptionLabel={(option) => option}
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Courier" />}
                    />
                    <TextField
                      sx={{ marginTop: 1 }}
                      label="Tracking #"
                      onChange={(e) => {
                        handleChangeShippingInfo(row.orderID, row.shippingTrackingCarrier, e.target.value)
                      }}
                      defaultValue={row.shippingTrackingNumber}
                      variant="outlined"
                      fullWidth
                      className={classes.inputOutline}
                    />
                    <Button disabled={!row?.unsaved} onClick={(e) => {
                      handleSaveShippingInfo(row.orderID)
                    }}
                    >
                      <b>{`Save`}</b>
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow className={classes.tableRows}>
                <TableCell
                  align="center"
                  colSpan={4}
                  className={classes.noOrdersCell}
                >
                  <Typography>
                    {!trackShipData ? <b>Loading Orders</b> : <b>No Orders</b>}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{ height: 53 * emptyRows }}
                className={classes.tableRows}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow className={classes.tableRows}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={4}
                count={trackShipData ? trackShipData.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default TrackingandShippingCompletedView;
