import { createSlice } from "@reduxjs/toolkit";
import { hasDatePassed } from "../../../util/utilities"

function countCouponAppliedSkuIDsInCart(cartItems, couponObject) {
  let couponAppliedToItemsInCart = 0;

  const hasCouponDatePassed = hasDatePassed(couponObject.expirationDateTime);
  if (hasCouponDatePassed) {
    return couponAppliedToItemsInCart;
  }
  for (const cartItem of cartItems) {
    if (couponObject.skuIDs.includes(cartItem.skuId)) {
      couponAppliedToItemsInCart++;
    }
  }
  return couponAppliedToItemsInCart;
}

export const cartDataSlice = createSlice({
  name: "cart",
  initialState: {
    // Cart state is a dictionary where the key is the product id and value is quantity
    // Initially the dictionary is empty representing an empty cart
    //cartQuantities: {},
    cartItems: [],
    coupon: null,
  },
  reducers: {
    addCouponToCart: (state, action) => {
      const { couponObject } = action.payload;

      if (state.coupon) {
        console.log('Coupon already in cart!');
        let couponAppliedToItemsInCart = countCouponAppliedSkuIDsInCart(state.cartItems, state.coupon);
        if(couponAppliedToItemsInCart == 0) {
          console.log('Coupon not applicable to any items in cart! Adding newly added coupon.');
          state.coupon = couponObject;
        }
      } else {
        state.coupon = couponObject;
      }
    },
    removeCouponFromCart: (state, action) => {
      state.coupon = null;
    },
    addItemToCart: (state, action) => {
      // Add payload is expected to be an object with the product Id and the add amount
      // Add amount is for the case where the item is already in the cart when the add button is clicked
      const { productId, skuId, addAmount, customization } = action.payload;
      // if (state.cartQuantities.hasOwnProperty(`${productId}:${skuId}`)) {
      //   state.cartQuantities[`${productId}:${skuId}`] += addAmount;
      // } else {
      //   state.cartQuantities[`${productId}:${skuId}`] = addAmount;
      // }
      if(!state.cartItems) state.cartItems = [];
      let productInCart = state.cartItems.find(i => i.productId == productId && i.skuId == skuId);
      if (!productInCart) {
        const newItem = {
          productId: productId,
          skuId: skuId,
          quantity: 0,
          customizations: []
        }
        state.cartItems.push(newItem);
        productInCart = state.cartItems.find(i => i.productId == productId && i.skuId == skuId);
      } 
      productInCart.quantity += addAmount;
      if(customization !== null && customization.length > 0) productInCart.customizations = [...productInCart.customizations, ...customization];
    },
    removeItemFromCart: (state, action) => {
      // Removes a product with the given id from the cart
      const { productId, skuId } = action.payload;
      // if (state.cartQuantities.hasOwnProperty(`${productId}:${skuId}`)) {
      //   delete state.cartQuantities[`${productId}:${skuId}`];
      // }
      let productInCart = state.cartItems.find(i => i.productId == productId && i.skuId == skuId);
      if (productInCart) {
        state.cartItems = state.cartItems.filter(i => i.skuId != skuId);
      }
    },
    // removeItemFromCartByKey: (state, action) => {
    //   // Removes a product with the given id from the cart
    //   const key = action.payload;
    //   if (state.cartQuantities.hasOwnProperty(`${key}`)) {
    //     delete state.cartQuantities[`${key}`];
    //   }
    // },
    incrementItemQuantity: (state, action) => {
      // Increments the quantity of a product by 1 if it is in the cart
      // Intended for the increment button on the cart page
      const { productId, skuId } = action.payload;
      // if (state.cartQuantities.hasOwnProperty(`${productId}:${skuId}`)) {
      //   state.cartQuantities[`${productId}:${skuId}`]++;
      // }
      let productInCart = state.cartItems.find(i => i.productId == productId && i.skuId == skuId);
      if (productInCart) {
        productInCart.quantity++;
        if (productInCart.customizations.length > 0) productInCart.customizations.push(productInCart.customizations.slice(-1)[0]);
      }
    },
    decrementItemQuantity: (state, action) => {
      // Decrements the quantity of a produce by 1 if it is in the cart
      // Intended for the decrement button on the cart page
      const { productId, skuId } = action.payload;
      // if (state.cartQuantities.hasOwnProperty(`${productId}:${skuId}`)) {
      //   const quantity = state.cartQuantities[`${productId}:${skuId}`];
      //   if (quantity > 1) {
      //     state.cartQuantities[`${productId}:${skuId}`]--;
      //   } else {
      //     delete state.cartQuantities[`${productId}:${skuId}`];
      //   }
      // }
      let productInCart = state.cartItems.find(i => i.productId == productId && i.skuId == skuId);
      if (productInCart) {
        if (productInCart.quantity > 1) {
          productInCart.quantity--;
          if (productInCart.customizations.length > 0) productInCart.customizations.splice(productInCart.customizations.length - 1, 1);;
        } else {
          state.cartItems = state.cartItems.filter(i => i.skuId != skuId);
        }
      }
    },
    clearCart: (state) => {
      // Clears all items from the cart
      // for (const productAndSkuId of Object.keys(state.cartQuantities)) {
      //   delete state.cartQuantities[productAndSkuId];
      // }
      state.cartItems = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addItemToCart,
  removeItemFromCart,
  incrementItemQuantity,
  decrementItemQuantity,
  clearCart,
  removeCouponFromCart,
  addCouponToCart,
} = cartDataSlice.actions;

export const selectCartItems = (state) => state.cart.cartItems;
export const selectCoupon = (state) => state.cart.coupon;

export default cartDataSlice.reducer;
