import { Box, Container, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import bulb from "../img/PurposerIcon.png";
import { useState } from "react";
import { getAllSellersSupportedCountries } from "../util/sellers";
import React from "react";

const useStyles = makeStyles({
  map: {
    maxWidth: 1024,
    height: 600,
    margin: "auto",
    backgroundColor: "#82aa9f",
  },
  divContainer: {
    // backgroundImage: `url(https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/Wooden+board-01.png)`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
    // backgroundSize: "cover",
    backgroundColor: "#82aa9f",
    paddingBottom: 20,
    overflow: "hidden",
  },
  titleContainer: {
    backgroundColor: "var(--beige)",
    position: "relative",
    borderRadius: 10,
    width: "70%",
    margin: "65px auto",
    color: "black",
    padding: "8px",
  },
});

const Header = styled(Typography)({
  fontSize: 27,
});

const Subheader = styled(Typography)({
  fontSize: 23,
});

const Country = styled(Typography)({
  fontSize: 17,
  paddingTop: 5,
  paddingLeft: 19,
  paddingRight: 19,
  paddingBottom: 2,
});

const StyledDivider = styled(Divider)({
  height: 1,
  borderColor: "#000000",
  backgroundColor: "#000000",
});

const Seller = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "firstSeller",
})(({ firstSeller }) => ({
  fontSize: 17,
  color: "#000000",
  paddingLeft: 15,
  paddingRight: 15,
  paddingTop: firstSeller ? 3 : 0,
}));

const bulbIcon = new L.Icon({
  iconUrl: bulb,
  iconSize: [23, 35],
});

const TitleContainer = (props) => (
  <Box sx={{ width: "90%", margin: "auto" }}>
    <div className={props.titleContainer}>
      <Header align="center" className={"roboto-medium"}>
        SHOP BY COUNTRY
      </Header>
      <Subheader align="center" className={"roboto-light-italic"}>
        <i>Every shop supports a unique region.</i>
      </Subheader>
      <Subheader align="center" className={"roboto-light-italic"}>
        <i> What country do you love?</i>
      </Subheader>
    </div>
  </Box>
);

const Markers = ({ data }) => (
  <Marker icon={bulbIcon} position={[data.lat, data.lng]}>
    <Popup>
      <Country align="center" className={"roboto-light"}>
        <a href={'/searchByCountry?country=' + encodeURI(data.country)}>
          {data.country.toUpperCase()}
        </a>
      </Country>
      <StyledDivider />
      {data.sellers.map((item, i) => {
        return (
          <React.Fragment key={i}>
            <Seller className={"roboto-thin-italic"} firstSeller={i === 0}>
              <a href={item.seller_url}>
                <i>{item.seller}</i>
              </a>
            </Seller>
          </React.Fragment>
        );
      })}
    </Popup>
  </Marker>
);

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

const CountryShopByMap = (props) => {
  const classes = useStyles();
  const [mapData, setMapData] = useState([]);
  const [loading, setLoading] = useState(false);
  React.useEffect(() => {
    if ((!mapData || mapData.length === 0) && !loading) {
      setLoading(true);
      getAllSellersSupportedCountries((data) => {
        if (!data.hasOwnProperty("error")) {
          var formattedCountriesData = [];
          data.forEach((seller) => {
            seller.mapData.forEach((area) => {
              var foundCountry = formattedCountriesData.find(element => element.lat === area["lat"] && element.lng === area["lng"]);
              if (foundCountry) {
                foundCountry["sellers"].push({ "seller": seller.sellerName, "seller_url": "/shop/seller/" + seller.sellerID + "/" + seller.sellerName?.replace(/\W+/g, "-")});
              } else {
                formattedCountriesData.push({
                  "country": area["country"],
                  "lat": area["lat"],
                  "lng": area["lng"],
                  "sellers": [
                    { "seller": seller.sellerName, "seller_url": "/shop/seller/" + seller.sellerID + "/" + seller.sellerName?.replace(/\W+/g, "-") }
                  ]
                })
              }
            })
          })
          const maxStorePerCountry = 12;
          formattedCountriesData.forEach((country) => {
            shuffle(country.sellers);
            if (country?.sellers?.length > maxStorePerCountry) {
              country.sellers = country.sellers.slice(0, maxStorePerCountry);
              country.sellers.push({
                "seller": "and more...", "seller_url": "/searchByCountry?country=" + encodeURI(country.country) 
              });
            }
          });
          setMapData(formattedCountriesData);
        }
        setLoading(false);
      });
    } else {
      console.log('Failed to load supported countries');
    }
  }, []);

  return (
    <div className={classes.divContainer}>
      <Container maxWidth="xl">
        {props.isHomePage && <TitleContainer titleContainer={classes.titleContainer} />}
        <MapContainer
          tap={false}
          center={[30, 0]}
          zoom={2}
          scrollWheelZoom={true}
          maxBounds={[
            [-90, -180],
            [90, 180],
          ]}
          className={classes.map}
          minZoom={2}
        >
          <TileLayer
            attribution='&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.thunderforest.com/mobile-atlas/{z}/{x}/{y}.png?apikey={apikey}"
            noWrap="true"
            apikey="b4253345883a4819ae18fbf3ca4daef3"
          />
          {mapData
            ? mapData.map((item, i) => {
                return <Markers data={item} key={i} />;
              })
            : null}
        </MapContainer>
      </Container>
    </div>
  );
};

export default CountryShopByMap;
