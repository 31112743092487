import { Container, Typography, Grid, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles({
  backgroundDiv: {
    backgroundColor: "#E5E5E5",
    paddingTop: 60,
    paddingBottom: 60
  },
  sellerImg: {
    maxWidth: "55%",
  },
  verticalCenterGridItem: {
    display: "flex",
    alignItems: "center",
    // padding: "32px"
  },
  listingFeeGrid: {
    marginBottom: 70,
  },
  textLineHeight: {
    lineHeight: 1.7,
  },
});

function SellersFees() {
  const classes = useStyles();

  return (
    <div className={classes.backgroundDiv}>
      <Container maxWidth="xl">
        <Typography
          align="center"
          variant="h3"
          className={"roboto-medium-italic"}
        >
          Sellers Fees
        </Typography>
        <Grid container direction="row" columnSpacing={12} alignItems="center">
          <Grid item sm={12} md={7} lg={8}>
            {/* <Grid container direction="row" className={classes.listingFeeGrid}>
              <Grid item xs={2} align="center">
                <img
                  src={
                    "https://d2eudfgh8kpk8j.cloudfront.net/common/df608586-04f6-4a1f-a0dd-6d96c4fbd620.png"
                  }
                  alt=""
                  className={classes.sellerImg}
                />
              </Grid>
              <Grid item className={classes.verticalCenterGridItem} xs>
                <Stack>
                  <Typography variant="h5" className={"roboto-medium"}>
                    $0.20 Listing Fee
                  </Typography>
                </Stack>
              </Grid>
            </Grid> */}
            <Grid container direction="row" sx={{padding: "36px"}}>
              <Grid item xs={2} align="center">
                <img
                  src={
                    "https://d2eudfgh8kpk8j.cloudfront.net/common/738598a5-1626-424c-ba8b-1e3204f9d0e1.png"
                  }
                  alt=""
                  className={classes.sellerImg}
                />
              </Grid>
              <Grid item className={classes.verticalCenterGridItem} xs>
                <Stack>
                  <Typography variant="h5" className={"roboto-medium"}>
                    20% Transaction Fee
                  </Typography>
                  <Typography
                    variant="h5"
                    className={clsx("roboto-light", classes.textLineHeight)}
                  >
                    *One Simple Fee. Includes transaction, payment processing
                    and marketing fees
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            md={5}
            lg={4}
            className={classes.verticalCenterGridItem}
          >
            <Stack>
              <Typography
                variant="h5"
                align="center"
                gutterBottom
                className={"roboto-regular"}
              >
                "We want to support our purpose-driven sellers to reach their
                full potential. Our promise is to shed light on each brand and
                further their purpose. Let us connect you to loyal shoppers that
                care about your brand ethos. Together we can make the world a
                better place, one purchase at a time."
              </Typography>
              <Typography
                variant="h6"
                align="center"
                className={"roboto-regular"}
              >
                - Joline, Founder Of Purposer
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default SellersFees;
