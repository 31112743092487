import React from "react";

import {
  Typography,
  Container,
  Divider,
  Grid,
  Button,
  Stack,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandableQuestionsView from "../../components/ExpandableQuestionsView";
import questions from "../../json/seller_faq_questions.json";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { PurposeCategoryMasterLayout } from "../../layouts";
import clsx from "clsx";
import SellerSuccessStories from "../../components/SellerSuccessStories";
import sellerSuccessStoriesData from "../../json/seller_success_stories.json";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import MonitorIcon from "@mui/icons-material/Monitor";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import useDocumentDescription from "../../hooks/useDocumentDescription";
import SellerDealBanner from "../../components/SellerDealBanner";

const useStyles = makeStyles({
  purposeCategoryDiv: {
    margin: "auto",
    paddingBottom: 20,
  },
  header: {
    fontSize: "4rem",
    display: "inline",
  },
  headerDiv: {
    // Temporary background color
    backgroundColor: "var(--coral)",
    //backgroundImage: "url()",
    textAlign: "left",
    paddingTop: 50,
    paddingBottom: 50,
  },
  headerSellerText: {
    color: "#FFFFFF",
    marginLeft: 42,
  },
  headerFAQText: {
    color: "var(--mintGreen)",
  },
  questionTopics: {
    textDecoration: "underline",
    textAlign: "center",
    fontSize: "1.7rem",
    marginTop: 50,
    marginBottom: 25,
  },
  buttonGrid: {
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 70,
    marginBottom: 70,
  },
  buttonGridItem: {
    textAlign: "center",
  },
  button: {
    backgroundColor: "var(--coral)",
    width: "97%",
    textTransform: "none",
    borderRadius: 5,
    minHeight: 43,
    "&:hover": {
      backgroundColor: "var(--coral)",
    },
  },
  buttonText: {
    color: "#FFFFFF",
  },
  moreInfoBackgroundDiv: {
    backgroundColor: "#E4EDEA",
    paddingTop: 30,
    paddingBottom: 100,
  },
  moreInfoHeader: {
    fontSize: "1.7rem",
    marginBottom: 70,
  },
  moreInfoGrid: {
    paddingLeft: 50,
    paddingRight: 50,
  },
  moreInfoButtonHeader: {
    color: "#7A665B",
  },
  moreInfoButtonText: {
    fontSize: "1.2rem",
  },
});

function SellerFAQLayout() {
  const classes = useStyles();
  const buttons = [
    { text: "Set up your shop!", url: "/seller/signup" },
    // { text: "Seller Forum", url: "/forum" },
    { text: "Contact us!", url: "/contact" },
  ];
  const moreInfoButtons = [
    {
      header: "Download",
      text: "Seller Handbook",
      url: "https://drive.google.com/file/d/1sveHkFEZ5cGl3Z3yMl7BUFamS9e-Ii5S/view?usp=share_link",
      icon: FileDownloadIcon,
    },
    {
      header: "Watch",
      text: "YouTube Tutorials",
      url: "/",
      icon: MonitorIcon,
    },
    {
      header: "Contact Us",
      text: "Virtual Chat!",
      url: "/contact",
      icon: SmsOutlinedIcon,
    },
  ];
  useDocumentTitle("Selling on Purposer is user friendly. Read our Seller FAQ.");
  useDocumentDescription("We support the vendors on our marketplace. We connect vendors with loyal shoppers so you can grow your business. Read our FAQ and learn how on Purposer!");

  return (
    <PurposeCategoryMasterLayout>
      <Divider />
      <div className={classes.headerDiv}>
        <Container maxWidth="xl">
          <Typography
            className={clsx(classes.header, classes.headerSellerText)}
            variant="h1"
          >
            Seller FAQ
          </Typography>
          {/* <Typography className={clsx(classes.header, classes.headerFAQText)}>
            FAQ
          </Typography> */}
        </Container>
      </div>
      <SellerDealBanner/>
      <Container maxWidth="xl">
        {questions.map((question_section, index) => {
          return (
            <React.Fragment key={index}>
              <Typography className={classes.questionTopics} variant="h2">
                <b>{question_section.header}</b>
              </Typography>
              <ExpandableQuestionsView questions={question_section.faqs} />
            </React.Fragment>
          );
        })}
        <Grid
          container
          direction="row"
          rowSpacing={1.5}
          className={classes.buttonGrid}
        >
          {buttons.map((button, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={4}
                key={index}
                className={classes.buttonGridItem}
              >
                <Button href={button.url} className={classes.button}>
                  <Typography className={classes.buttonText}>
                    <b>{button.text}</b>
                  </Typography>
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </Container>
      <SellerSuccessStories
        sellerSuccessStoriesData={sellerSuccessStoriesData}
      />
      <div className={classes.moreInfoBackgroundDiv}>
        <Container maxWidth="xl">
          <Typography align="center" className={classes.moreInfoHeader}>
            <b>Want even more info?</b>
          </Typography>
          <Grid container direction="row" rowSpacing={4}>
            {moreInfoButtons.map((button, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  key={index}
                  className={classes.moreInfoGrid}
                >
                  <a href={button.url} target="_blank">
                    <Stack justifyContent="center" alignItems="center">
                      <button.icon sx={{ fontSize: { xs: "4rem" } }} />
                      <Typography
                        align="center"
                        className={clsx(
                          classes.moreInfoButtonHeader,
                          classes.moreInfoButtonText
                        )}
                      >
                        <b>
                          <i>{button.header}</i>
                        </b>
                      </Typography>
                      <Typography
                        align="center"
                        className={classes.moreInfoButtonText}
                      >
                        <i>{button.text}</i>
                      </Typography>
                    </Stack>
                  </a>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </div>
    </PurposeCategoryMasterLayout>
  );
}

export default SellerFAQLayout;
