import React, { useState } from "react";
import { Typography, Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import Box from '@mui/material/Box';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
// import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";


const useStyles = makeStyles({
  box: {
    width: "100%",
    height: "300px",
    borderStyle: "solid",
    borderColor: "var(--coral)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  text: {
    fontSize: "2rem",
    textAlign: "center",
  },
  number: {
    fontSize: "5rem",
    textAlign: "center",
    marginRight: "16px",
  },
  pointsBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  labelNumber: {
    color: "black",
    marginTop: "80px",
    fontSize: "2rem"
  },
  root: {
    backgroundColor: 'var(--lightBeige)',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    borderStyle: "solid",
    borderColor: "var(--coral)",
    borderWidth: "4px"
  },
  active: {
    backgroundColor: 'var(--coral)',
    borderStyle: "solid",
    borderColor: "var(--coral)",
    borderWidth: "4px"
  },
  completed: {
    backgroundColor: 'var(--coral)',
  },
});



const LoyaltyProgramPointsLinear = () => {
  const classes = useStyles();
  const steps = [
    '$0', '$10', '$20', '$30', '$40', '$50',
    '$60', '$70', '$80', '$90', '$100'
  ];
  const [activeStep, setActiveStep] = React.useState(1.5);

  const getStepPosition = () => {
    return 0.5;
  };

  return (
    <Box sx={{ width: '100%' }}>
    <ProgressBar
          width={"100%"}
          percent={100 * (getStepPosition() / steps.length)}
          filledBackground="var(--coral)"
        >
          {steps.map((step, index, arr) => {
            return (
              <Step
                position={100 * (index / arr.length)}
                transition="scale"
                children={({ accomplished }) => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: accomplished ? 'var(--coral)' : 'var(--lightBeige)',
                            borderRadius: '50%',
                            width: '24px',
                            height: '24px',
                            borderStyle: "solid",
                            borderColor: "var(--coral)",
                            borderWidth: "4px"
                        }}
                    >
                        <Typography className={clsx("roboto-medium", classes.labelNumber)}>
                            {step}
                        </Typography>
                    </div>
                )}
              />
            );
          })}
        </ProgressBar>
        <div style={{width: "100%", height: "80px"}}></div>
    </Box>
  );
};

export default LoyaltyProgramPointsLinear;
