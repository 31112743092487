import { Avatar, Box, ButtonBase, Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import {
  NavigateBefore as ArrowBack,
  NavigateNext as ArrowForward,
} from "@mui/icons-material";

const useStyles = makeStyles({
  imageAvatar: {
    width: "100%",
    height: "100%",
    maxWidth: "214px",
    maxHeight: "214px"
  },
  container: {
    width: "80%",
    margin: "auto",
    marginTop: 20,
  },
  text: {
    textAlign: "center",
    fontSize: 25,
    marginTop: 10,
  },
  mobileText: {
    textAlign: "center",
    fontSize: 16,
  },
  title: {
    fontWeight: 600,
  },
  navButtons: {
    position: "absolute",
    top: "39%",
    height: 75,
    border: "1px var(--mintArrow)",
    borderRadius: 15,
    backgroundColor: "#FFFFFF",
  },
  mobileNavButtons: {
    position: "absolute",
    top: "25%",
    height: 35,
    width: 25,
    border: "1px solid #85af9a47",
    borderRadius: 15,
    backgroundColor: "#FFFFFF",
  },
  prevButton: {
    left: -33,
  },
  nextButton: {
    right: -33,
  },
  divContainer: {
    position: "relative",
    width: "90%",
    margin: "auto",
    padding: "15px 0",
  },
  arrows: {
    color: "var(--mintArrow)",
    fontSize: 20,
  },
  carouselContainer: {
    width: "100%",
  },
});

const responsive = {
  break1: {
    breakpoint: { max: 4000, min: 1297 },
    items: 5,
  },
  break2: {
    breakpoint: { max: 1297, min: 900 },
    items: 4,
  },
  break3: {
    breakpoint: { max: 900, min: 0 },
    items: 3,
  },
};

const CategoryMobileSlider = ({ categoryMenuData }) => {
  const classes = useStyles();
  const mobile = useMobileMediaQuery();

  const CustomButtonGroupAsArrows = ({ next, previous }) => {
    return (

      <>
        {mobile &&
          <>
            <ButtonBase
              className={clsx(
                mobile ? classes.mobileNavButtons : classes.navButtons,
                classes.prevButton
              )}
              onClick={previous}
            >
              <ArrowBack className={classes.arrows} />
            </ButtonBase>
            <ButtonBase
              className={clsx(
                mobile ? classes.mobileNavButtons : classes.navButtons,
                classes.nextButton
              )}
              onClick={next}
            >
              <ArrowForward className={classes.arrows} />
            </ButtonBase>
          </>
        }
      </>
    );
  };

  const Bubble = ({ item }) => {
    
    const itemNameWithAndReplace = item.name?.replace('and', '&');
    const words = itemNameWithAndReplace?.split(' ');
    const firstTwoWords = words?.slice(0, 2)?.join(' ');
    const restOfWords = words?.slice(2)?.join(' ');

    return (
    <Box>
      <a href={"/shop/category/" + item.id + "/" + encodeURI(item.name)}>
        <Typography
          className={clsx(
            mobile ? classes.mobileText : classes.text,
            "roboto-light"
          )}
          sx={{ marginTop: restOfWords?.length > 0 ? "0px" : "10px" }}
        >
          {firstTwoWords}
        </Typography>
        {restOfWords?.length > 0 && (<Typography
          className={clsx(
            mobile ? classes.mobileText : classes.text,
            "roboto-light"
          )}
          sx={{ marginTop: "-8px"}}
        >
          {restOfWords}
        </Typography>)}
      </a>
    </Box>
  )};

  const BubbleCarousel = ({ data }) => (
    <div className={classes.divContainer}>
      <Carousel
        responsive={responsive}
        infinite={true}
        arrows={false}
        renderButtonGroupOutside={true}
        customButtonGroup={<CustomButtonGroupAsArrows />}
        containerClass={classes.carouselContainer}
      >
        {data.map((item, i) => {
          return <Bubble key={i} item={item} />;
        })}
      </Carousel>
    </div>
  );

  //category menu array with array first element (best sellers) shifted to back
  const categoryMenuDataShifted = categoryMenuData?.slice(1)?.concat(categoryMenuData?.slice(0, 1));
  return (
    <Container maxWidth="xl">
      {categoryMenuData && categoryMenuData.length > 0 && (
        <BubbleCarousel data={categoryMenuDataShifted} />
      )}
    </Container>
  );
};

export default CategoryMobileSlider;
