import { Container, Grid, Button, Typography, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Blog from "../../components/Blog";
import clsx from "clsx";

import blogPosts from "../../json/blog_posts.json";
import aboutUsData from "../../json/about_us.json";
import howItWorksData from "../../json/how_it_works_data.json";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { PurposeCategoryMasterLayout } from "../../layouts";

const numGalleryImgs = 4;

const useStyles = makeStyles({
  galleryImgs: {
    maxWidth: "100%",
    display: "block",
  },
  leftDiv: {
    width: "75%",
  },
  textGridItem: {
    display: "flex",
    alignItems: "center",
  },
  aboutUsGrid: {
    marginTop: 50,
  },
  aboutUsHeader: {
    color: "var(--mintGreen)",
    fontSize: "1.8rem",
    marginBottom: 30,
  },
  howItWorksSectionGrid: {
    marginTop: 110,
  },
  buttonHeader: {
    backgroundColor: "#F2E9DA",
    textTransform: "none",
    minWidth: 250,
    minHeight: 70,
    marginBottom: 35,
    "&:hover": {
      backgroundColor: "#F2E9DA",
    },
  },
  buttonHeaderText: {
    fontSize: "1.5rem",
  },
  text: {
    fontSize: "1.325rem",
  },
  subheader: {
    marginBottom: 30,
  },
  description: {
    lineHeight: "1.65",
  },
  galleryLabelText: {
    width: "85%",
    backgroundColor: "var(--mintGreen)",
    margin: "auto",
    color: "white",
    fontSize: "1.1rem",
    paddingTop: 4,
    paddingBottom: 4,
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  blogDiv: {
    marginTop: 70,
    marginBottom: 35,
  },
});

const AboutUs = (props) => {
  let { classes, aboutUsData } = props;
  let { header, subheader, description, imgs } = aboutUsData;

  return (
    <Grid container direction="row" className={classes.aboutUsGrid}>
      <Grid item xs={6} className={classes.textGridItem}>
        <div className={classes.leftDiv}>
          <Typography className={classes.aboutUsHeader}>
            <b>{header}</b>
          </Typography>
          <Typography className={clsx(classes.text, classes.subheader)}>
            {subheader}
          </Typography>
          <Typography className={clsx(classes.text, classes.description)}>
            {description}
          </Typography>
        </div>
      </Grid>
      <Grid container item xs={6} spacing={2}>
        {imgs.map((img, index) => {
          if (index < numGalleryImgs) {
            return (
              <Grid item xs={6} key={index}>
                <img src={img} alt="" className={classes.galleryImgs} />
              </Grid>
            );
          } else {
            return <></>;
          }
        })}
      </Grid>
    </Grid>
  );
};

const HowItWorksSection = (props) => {
  let { classes, howItWorksData } = props;
  let { header, subheader, description, gallery, url } = howItWorksData;

  return (
    <Grid container direction="row" className={classes.howItWorksSectionGrid}>
      <Grid item xs={6} className={classes.textGridItem}>
        <div className={classes.leftDiv}>
          <Button
            variant="contained"
            disableElevation
            href={url}
            className={classes.buttonHeader}
          >
            <Typography className={classes.buttonHeaderText}>
              {header}
            </Typography>
          </Button>
          <Typography className={clsx(classes.text, classes.subheader)}>
            {subheader}
          </Typography>
          <Typography className={clsx(classes.text, classes.description)}>
            {description}
          </Typography>
        </div>
      </Grid>
      <Grid container item xs={6} spacing={2}>
        {gallery.map((galleryObj, index) => {
          return (
            <Grid item xs={6} key={index}>
              <a href={galleryObj.url} className={classes.link}>
                <img
                  src={galleryObj.img}
                  alt=""
                  className={classes.galleryImgs}
                />
                <Typography align="center" className={classes.galleryLabelText}>
                  {galleryObj.label}
                </Typography>
              </a>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

function HowItWorksLayout() {
  const classes = useStyles();
  useDocumentTitle("How It Works");

  return (
    <PurposeCategoryMasterLayout>
      <Divider />
      <Container maxWidth="xl">
        <AboutUs classes={classes} aboutUsData={aboutUsData} />
        {howItWorksData.map((data, index) => {
          return (
            <HowItWorksSection
              classes={classes}
              howItWorksData={data}
              key={index}
            />
          );
        })}
      </Container>
      <div className={classes.blogDiv}>
        <Blog blogPosts={blogPosts} />
      </div>
    </PurposeCategoryMasterLayout>
  );
}

export default HowItWorksLayout;
