import { Container, Typography, Divider, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandableQuestionsView from "../../components/ExpandableQuestionsView";
import { PurposeCategoryMasterLayout } from "../../layouts";

import shipReturnBoxes from "../../img/shippingAndReturnsBoxes.png";
import TrackOrderShipment from "../../components/TrackOrderShipment";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useDocumentDescription from "../../hooks/useDocumentDescription";

const useStyles = makeStyles({
  headerImg: {
    width: "100%",
    maxHeight: "100%",
  },
  headerImgDiv: {
    width: 225,
    maxHeight: "100%",
  },
});

const ShippingAndReturnsFAQLayout = () => {
  const classes = useStyles();

  useDocumentTitle("Free Shipping on most products on Purposer! Fast Shipping. Track your orders!");
  useDocumentDescription("Our vendors care about you and have made shipping easy and fast! Check out all the great products and rest assured that they will arrive fast and safely.");

  return (
    <PurposeCategoryMasterLayout>
      <Divider />
      <div style={{ height: "var(--dividerHeight)" }} />
      <Container maxWidth="xl" align="center">
        <Typography
          variant="h1"
          align="center"
          gutterBottom
          style={{ fontSize: "62px" }}
        >
          <b>Shipping & Returns</b>
        </Typography>
        <div className={classes.headerImgDiv}>
          <img
            src={shipReturnBoxes}
            alt="shipReturnBoxes"
            className={classes.headerImg}
          />
        </div>
        <div style={{ maxWidth: 650, margin: "auto" }}>
          <Typography
            variant="h2"
            align="center"
            style={{ fontSize: "1rem", lineHeight: "1.5rem" }}
          >
            Each business that sells on Purposer will have different shipping
            and returns policies, but we do have general guidelines. For
            information regarding a specific brand, please refer to their shop
            page or contact them directly.
          </Typography>
        </div>
        <br />
        <Button href={`/acct?tab=purchases`} sx={{ backgroundColor: "var(--mintGreenSolid)", padding: "12px 30px", borderRadius: 10, width: 550, "&:hover": { backgroundColor: "var(--coral)" } }}>
          <Typography sx={{ color: "white" }} variant="h6" className={"roboto-medium"}>
            Look up your order and tracking numbers
          </Typography>
        </Button>
      </Container>
      <div style={{ height: "var(--dividerHeight)" }} />
      {/* <TrackOrderShipment
        header={"Already Placed Your Order?"}
        subheader={"Track It Here and Manage Returns"}
      /> */}
      <div style={{ height: "var(--dividerHeight)" }} />
      <Container maxWidth="xl">
        <Typography
          gutterBottom
          variant="h3"
          align="center"
          style={{ fontSize: "2.35rem" }}
        >
          <b>
            <u>Shipping & Returns within the U.S.</u>
          </b>
        </Typography>
      </Container>
      <ExpandableQuestionsView />
      {/* <div style={{ height: "var(--dividerHeight)" }} /> */}
      <Container maxWidth="xl">
        <Typography gutterBottom variant="h4" align="center">
          International Shipping & Returns COMING SOON
        </Typography>
      </Container>
      <ExpandableQuestionsView />
      <div style={{ height: "var(--dividerHeight)" }} />
    </PurposeCategoryMasterLayout>
  );
};

export default ShippingAndReturnsFAQLayout;
