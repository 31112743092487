import { getSessionJwt, tryParseJSONObject, getJsonCache, setJsonCache } from "./utilities";
const {
  NODE_API_ENDPOINT
} = require("./constants");

async function createStripePaymentIntent(cart, callback) {
  const jwtToken = await getSessionJwt();

  // Create PaymentIntent as soon as the page loads
  fetch(NODE_API_ENDPOINT + "/create-payment-intent", {
    method: 'POST',
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      cart: cart
    }),
  })
    .then((res) => res.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function createStripeSetupIntent(callback) {
  const jwtToken = await getSessionJwt();

  // Create SetupIntent as soon as the page loads
  fetch(NODE_API_ENDPOINT + "/create-stripe-setup-intent", {
    method: 'POST',
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function createStripeCheckoutSession(cart, orderID, callback) {
  const jwtToken = await getSessionJwt();

  fetch(NODE_API_ENDPOINT + "/create-checkout-session", {
    method: 'POST',
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      cart: cart,
      orderID: orderID,
    }),
  })
    .then((res) => res.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function updateStripeConnectedAcctPayoutSchedule(sellerID, schedule, callback) {
  const jwtToken = await getSessionJwt();

  fetch(NODE_API_ENDPOINT + "/change-seller-payout-info-by-seller-id", {
    method: 'POST',
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sellerID,
      schedule,
    }),
  })
    .then((res) => res.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

async function getSellerPayoutInfo(sellerID, callback) {
  const jwtToken = await getSessionJwt();

  fetch(NODE_API_ENDPOINT + "/get-seller-payout-info-by-seller-id?sellerID=" + sellerID, {
    method: 'GET',
    headers: {
      "Authorization": 'Bearer ' + jwtToken,
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then(
      (data) => callback(data),
      (error) => {
        callback("Failed");
        console.log(error);
      }
    );
}

export { 
  createStripePaymentIntent, 
  createStripeSetupIntent, 
  createStripeCheckoutSession,
  updateStripeConnectedAcctPayoutSchedule,
  getSellerPayoutInfo,
 };
