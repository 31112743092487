import { Container, Typography, Divider, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandableQuestionsView from "../../components/ExpandableQuestionsView";
import { PurposeCategoryMasterLayout } from "..";

import shipReturnBoxes from "../../img/shippingAndReturnsBoxes.png";
import TrackOrderShipment from "../../components/TrackOrderShipment";

import clsx from "clsx"

const useStyles = makeStyles({
  headerImg: {
    width: "100%",
    maxHeight: "100%",
  },
  headerImgDiv: {
    width: 225,
    maxHeight: "100%",
  },
  bodyText1: {
    fontSize: "1.5rem"
  },
  header: {
    width: "100%",
    height: "auto",
    backgroundColor: "var(--lightGrayTransparent)",
    padding: "64px 0px"
  }
});

const InternetBasedAdsLayout = () => {
  const classes = useStyles();

  return (
    <PurposeCategoryMasterLayout>
      <Divider />
      
      <div className={classes.header}>
        <Container maxWidth="xl" >
            <Typography variant="h2">
                <b>Internet-Based Advertising</b>
            </Typography>
            <Typography variant="h4">
                <i>Technology Policies</i>
            </Typography>
        </Container>
      </div>
      <div style={{ height: "var(--dividerHeight)" }} />
      <Container maxWidth="xl">
        <div style={{ margin: "auto" }}>
          <Typography className={clsx("roboto-light", classes.bodyText1)} >
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
            euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
            minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis isl ut aliquip
            ex ea commodo consequat. Duis autem vel um iriure dolor in hendrerit in vulputate
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
            euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
            minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis isl ut aliquip
            ex ea commodo consequat. Duis autem vel um iriure dolor in hendrerit in vulputate
          </Typography>
        </div>
      </Container>
      
      <Container maxWidth="xl" align="center">
        
        <div style={{ height: "var(--dividerHeight)" }} />
        <Grid container spacing={12} alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={12} md={6}> 
                <Typography className={clsx("roboto-light", classes.bodyText1)}>
                    1. Lorem ipsum dolor sit amet <br/>
                    2. Lorem ipsum dolor sit amet <br/>
                    3. Lorem ipsum dolor sit amet <br/>
                    4. Lorem ipsum dolor sit amet <br/>
                    5. Lorem ipsum dolor sit amet <br/>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <img
                src="https://d2eudfgh8kpk8j.cloudfront.net/common/InterestBasedAds.png"
                alt="Loyalty Terms of Use Page - An illustration of person holding a clipboard."
                />
            </Grid>
        </Grid>
        <div style={{ height: "var(--dividerHeight)" }} />
      </Container>

      <Container maxWidth="xl">
        <Typography variant="h4">
            1. Lorem ipsum dolor sit amet
        </Typography>
        <Typography className={clsx("roboto-light", classes.bodyText1)}>
            Lorem insum dolor sit amet consectetuer adniscnn et sed dam nonummv nion
            puismod tincidunt ut aoreet dolore magna aliquam erat volutnat Ut wisi pnim ad
            minim veniam, guis nostrud exerci tation ullamcorner suscinit lobortie niel ut alicuir
            ex ea commodo consequat. If lobortis nisi ur alau nis ur alqu
        </Typography>
        <div style={{ height: "16px" }} />
        <Typography variant="h4">
            2. Lorem ipsum dolor sit amet
        </Typography>
        <Typography className={clsx("roboto-light", classes.bodyText1)}>
            Lorem insum dolor sit amet consectetuer adniscnn et sed dam nonummv nion
            puismod tincidunt ut aoreet dolore magna aliquam erat volutnat Ut wisi pnim ad
            minim veniam, guis nostrud exerci tation ullamcorner suscinit lobortie niel ut alicuir
            ex ea commodo consequat. If lobortis nisi ur alau nis ur alqu
        </Typography>
        <div style={{ height: "16px" }} />
        <Typography variant="h4">
            3. Lorem ipsum dolor sit amet
        </Typography>
        <Typography className={clsx("roboto-light", classes.bodyText1)}>
            Lorem insum dolor sit amet consectetuer adniscnn et sed dam nonummv nion
            puismod tincidunt ut aoreet dolore magna aliquam erat volutnat Ut wisi pnim ad
            minim veniam, guis nostrud exerci tation ullamcorner suscinit lobortie niel ut alicuir
            ex ea commodo consequat. If lobortis nisi ur alau nis ur alqu
        </Typography>
        <div style={{ height: "16px" }} />
        <Typography variant="h4">
            4. Lorem ipsum dolor sit amet
        </Typography>
        <Typography className={clsx("roboto-light", classes.bodyText1)}>
            Lorem insum dolor sit amet consectetuer adniscnn et sed dam nonummv nion
            puismod tincidunt ut aoreet dolore magna aliquam erat volutnat Ut wisi pnim ad
            minim veniam, guis nostrud exerci tation ullamcorner suscinit lobortie niel ut alicuir
            ex ea commodo consequat. If lobortis nisi ur alau nis ur alqu
        </Typography>
        <div style={{ height: "16px" }} />
        <Typography variant="h4">
            5. Lorem ipsum dolor sit amet
        </Typography>
        <Typography className={clsx("roboto-light", classes.bodyText1)}>
            Lorem insum dolor sit amet consectetuer adniscnn et sed dam nonummv nion
            puismod tincidunt ut aoreet dolore magna aliquam erat volutnat Ut wisi pnim ad
            minim veniam, guis nostrud exerci tation ullamcorner suscinit lobortie niel ut alicuir
            ex ea commodo consequat. If lobortis nisi ur alau nis ur alqu
        </Typography>
      </Container>

      <div style={{ height: "var(--dividerHeight)" }} />
      <div style={{ height: "var(--dividerHeight)" }} />
    </PurposeCategoryMasterLayout>
  );
};

export default InternetBasedAdsLayout;
