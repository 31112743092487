import {
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

function SellerDealBanner() {

  const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <div>
            <img
                src={isMobile ? "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/banners/seller_deal_banner_mobile_hd.png" : "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/banners/seller_deal_banner.png"}
                alt="Seller Deal Banner"
                style={{ width: "100%" }}
            />
        </div>
    );
}

export default SellerDealBanner;
