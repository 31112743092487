import React, { useState } from "react";

import { Typography, Container, Grid, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import ExpandableQuestionsView from "../../components/ExpandableQuestionsView";
import LoyaltyProgramWaysToEarnPoints from "../../components/LoyaltyProgramWaysToEarnPoints";
import LoyaltyProgramRedeemProducts from "../../components/LoyaltyProgramRedeemProducts";
import LoyaltyProgramPointsProgress from "../../components/LoyaltyProgramPointsProgress";


import FAQData from "../../json/loyalty_program_faq_data.json";
import LoyaltyProgramPointsData from "../../json/loyalty_program_points_data.json";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { PurposeCategoryMasterLayout } from "../../layouts";
import clsx from "clsx";

const LogInButton = styled(Button)({
  textTransform: "none",
  position: "relative",
  borderRadius: 10,
  color: "#FFFFFF",
  paddingLeft: 45,
  paddingRight: 45,
  paddingTop: 4,
  paddingBottom: 4,
  marginTop: 180,
  background: "var(--coral)",
  "&:hover": {
    background: "var(--coral)",
  },
  "&:visited": {
    color: "#FFFFFF",
  },
});

const useStyles = makeStyles({
  headerText: {
    fontSize: "4rem",
    padding: "0px",
    textAlign: "center",
  },
  headerTextMobile: {
    fontSize: "3rem",
    padding: "0px",
    textAlign: "center",
  },
  headerSubText: {
    fontSize: "2.5rem",
    padding: "0px",
    textAlign: "center",
  },
  headerSubTextMobile: {
    fontSize: "2.0rem",
    padding: "0px",
    textAlign: "center",
  },
  headerTextBlock: {
    padding: "0px",
    textAlign: "center",
    position: "absolute",
    top: "32px",
    left: "0",
    bottom: "0",
    right: "0",
    margin: "auto",
  },
  headerImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
    filter: "contrast(1.35) brightness(0.85)",
  },
  bodyHeaderText: {
    fontSize: "3rem",
    textDecoration: "underline",
    padding: "0px",
  },
  bodyHeaderTextCenter: {
    fontSize: "3rem",
    textDecoration: "underline",
    padding: "0px",
    textAlign: "center",
  },
  faqHeader: {
    fontSize: "4rem",
    padding: "0px",
  },
  faqHeaderDiv: {
    color: "white",
    backgroundColor: "var(--coral)",
    marginBottom: "8px"
  },
  questionTopics: {
    textDecoration: "underline",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "1.7rem",
  },
  bodyText: {
    fontSize: "1.7rem",
  },
  bodyTextCenter: {
    fontSize: "1.7rem",
    textAlign: "center",
  },
  bodyTextDiscount: {
    fontSize: "3rem",
    textAlign: "center",
  },
  logInButtonText: {
    fontSize: "3rem",
  },
  howItWorksTextBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "40px",
  },
  howItWorksNum: {
    fontSize: "6rem",
  },
  howItWorksText: {
    fontSize: "2.5rem",
  },
  purposerLogo: {
    width: "auto",
    height: "80px",
    marginRight: "16px"
  },
  discountFact: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  divMarginBottom: { height: "var(--dividerHeight)" },
});

const LoyaltyProgramLayout = () => {
  const { general_questions } = FAQData;
  const pointsFacts = [
    "Points never expire",
    "Use towards ANY items",
    "Use towards gift cards too",
  ];

  const classes = useStyles();
  useDocumentTitle("Loyalty Program");

  function renderPointsFacts() {
    return (
      <Grid container alignItems="flex-start" spacing={12}>
        {pointsFacts.map((item, index) => {
          return (
            <Grid item xs={12} sm={12} md={4} key={index}>
              <div className={classes.discountFact}>
                <img src="https://d2eudfgh8kpk8j.cloudfront.net/common/PUR-475_attachments/PurposerIcon-WhiteP-P486-01.png" alt="Purposer logo" className={classes.purposerLogo}></img>
                <Typography className={clsx("roboto-light")} variant="h5" center>
                  {item}
                </Typography>
              </div>
            </Grid>
          )
        })}
      </Grid>
    )
  };

  return (
    <PurposeCategoryMasterLayout>
      <Box sx={{ display: { xs: "none", md: "block" }, height: 680, position: "relative", }}>
        <img
          src="https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Loyalty/Final+loalty+program+page.jpg"
          alt="Loyalty Program Header Picture - Multiple people standing against the camera with their arms on each other."
          className={classes.headerImage}
        />
        <div className={classes.headerTextBlock}>
          <Typography className={clsx("roboto-bold", classes.headerText)} variant="h5">
            Join The Purposer Loyalty Program
          </Typography>
          <Typography
            className={clsx("roboto-light", classes.headerSubText)}
            variant="h5"
          >
            As a rewards member you <br></br> will get exclusive benefits!
          </Typography>
          {/* <LogInButton component={Link} to="/login">
            <Typography className={classes.logInButtonText} align="center">
              Sign in
            </Typography>
          </LogInButton> */}
        </div>
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" }, height: "460px"}}>
        <img
          src="https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Loyalty/Final+loalty+program+page.jpg"
          alt="Loyalty Program Header Picture - Multiple people standing against the camera with their arms on each other."
          className={classes.headerImage}
        />
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" }, paddingTop: "32px", paddingLeft: "32px", paddingRight: "32px" }}>
          <Typography className={clsx("roboto-bold", classes.headerTextMobile)}>
            Join The Purposer Loyalty Program
          </Typography>
          <Typography
            className={clsx("roboto-light", classes.headerSubTextMobile)}
            variant="h6"
          >
            As a rewards member you will get exclusive benefits!
          </Typography>
          {/* <LogInButton component={Link} to="/login">
            <Typography className={classes.logInButtonText} align="center">
              Sign in
            </Typography>
          </LogInButton> */}
      </Box>
      <div className={classes.divMarginBottom} />

      <Container maxWidth="xl">
        {/* -------- HOW IT WORKS -------- */}
        <Grid container alignItems="flex-start" spacing={12}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography className={classes.bodyHeaderText}>How It Works</Typography>
            <Typography className={clsx("roboto-light", classes.bodyText)} variant="h5">
              When you start shopping on Purposer, you will automatically start accruing points for our loyalty program.
              You get points with every purchase and for being a loyal Purposer customer. You will be able to redeem points for
              free products or for discounts! Remember, every purchase on Purposer helps make a positive impact on the planet and society!
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.howItWorksTextBlock}>
              <Typography className={clsx("roboto-light", classes.howItWorksNum)} variant="h1" >
                1
              </Typography>
              <Typography className={clsx("roboto-light", classes.howItWorksText)} variant="h5" >
                Shop on Purposer
              </Typography>
            </div>
            <div className={classes.howItWorksTextBlock}>
              <Typography className={clsx("roboto-light", classes.howItWorksNum)} variant="h1" >
                2
              </Typography>
              <Typography className={clsx("roboto-light", classes.howItWorksText)} variant="h5" >
                Earn Points
              </Typography>
            </div>
            <div className={classes.howItWorksTextBlock}>
              <Typography className={clsx("roboto-light", classes.howItWorksNum)} variant="h1" >
                3
              </Typography>
              <Typography className={clsx("roboto-light", classes.howItWorksText)} variant="h5" >
                Redeem Points
              </Typography>
            </div>
          </Grid>
        </Grid>
        <div className={classes.divMarginBottom} />

        {/* -------- WAYS TO EARN POINTS -------- */}
        <Typography className={classes.bodyHeaderText}>Ways To Earn Points</Typography>
        <LoyaltyProgramWaysToEarnPoints />
        <div className={classes.divMarginBottom} />
        <div className={classes.divMarginBottom} />
        {/* -------- HOW TO REDEEM POINTS -------- */}
        <Typography className={classes.bodyHeaderTextCenter}>How To Redeem Points</Typography>
        <Typography className={clsx("roboto-light", classes.bodyTextCenter)} variant="h5" center>
          As a rewards member, you get closer to earning exclusive rewards every time you shop.
          You can redeem points for free products in your account or for a cash discount when
          you check-out. Read below for all the ways you can redeem points.
        </Typography>
      </Container>
      <div className={classes.divMarginBottom} />
      <div className={classes.divMarginBottom} />
      <div style={{ height: 680 }}>
        <img
          src="https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Loyalty/FINAL+loyalty+program+page+.jpg"
          alt="Loyalty Program Page - A person holding their phone with one hand and credit card with the other."
          className={classes.headerImage}
        />
      </div>
      <div className={classes.divMarginBottom} />


      <Container maxWidth="xl">
        {/* -------- REDEEM FOR PRODUCTS --------
            <Typography className={classes.bodyHeaderTextCenter}>Redeem for Products</Typography>
            <Typography className={clsx("roboto-light", classes.bodyTextCenter)} variant="h5" center>
                As you accumulate points, you can redeem them for products!
            </Typography>
            <LoyaltyProgramRedeemProducts/>
            <div className={classes.divMarginBottom} /> */}
        {/* -------- REDEEM FOR CASH DISCOUNTS -------- */}
        <Typography className={classes.bodyHeaderTextCenter}>Redeem for Cash Discounts</Typography>
        <Typography className={clsx("roboto-light", classes.bodyTextCenter)} variant="h5" center>
          Reach milestones and redeem your points for a cash discount at checkout!
        </Typography>
        <div className={classes.divMarginBottom} />
        <LoyaltyProgramPointsProgress />
        <div className={classes.divMarginBottom} />
        <Typography className={clsx("roboto-bold", classes.bodyTextDiscount)} center>
          1 point = $1.00
        </Typography>
        <div className={classes.divMarginBottom} />
        {renderPointsFacts()}
      </Container>

      <div className={classes.divMarginBottom} />
      <div className={classes.faqHeaderDiv}>
        <Container maxWidth="xl">
          <Typography className={classes.faqHeader}>FAQ</Typography>
        </Container>
      </div>
      <ExpandableQuestionsView questions={general_questions} />
      <div className={classes.divMarginBottom} />
    </PurposeCategoryMasterLayout>
  );
};

export default LoyaltyProgramLayout;
