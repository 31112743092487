import React, { useState } from "react";
import { Typography, Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import Box from '@mui/material/Box';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  header: {
    color: "black",
    fontSize: "1.5rem",
    marginBottom: "20px"
  },
  subheader: {
    color: "black",
    fontSize: "1.25rem",
    marginTop: "20px",
    textAlign: "center"
  },
});


// Type can either be: points or cash rewards
const LoyaltyProgramPointsCircle = ({ type }) => {
  const classes = useStyles();
  const steps = [
    '$0', '$10', '$20', '$30', '$40', '$50',
    '$60', '$70', '$80', '$90', '$100'
  ];
  const [points, setPoints] = React.useState(0);
  const [cash, setCash] = React.useState(0);

  const getStepPosition = () => {
    return 0.5;
  };

  if (type == "points") {
    return (
        <div className={classes.container}>
            <Typography className={clsx("roboto", classes.header)}>
                Your Current Points
            </Typography>
            <div style={{ maxWidth: 280, maxHeight: 280 }}>
                <CircularProgressbar 
                    value={points} 
                    maxValue={1000} 
                    text={`${points} PTS`}
                    styles={buildStyles({
                        textColor: "black",
                        pathColor: "var(--coral)",
                        trailColor: "var(--lightBeige)"
                    })}/>
            </div>
            <Typography className={clsx("roboto-light", classes.subheader)}>
                <i>You're doing awesome! You are only {1000 - points} away from your next $20 reward!</i>
            </Typography>
        </div>
      );
  } 
  if (type == "cash") {
    return (
        <div className={classes.container}>
            <Typography className={clsx("roboto", classes.header)}>
                Your Available Cash Rewards
            </Typography>
            <div style={{ maxWidth: 280, maxHeight: 280 }}>
                <CircularProgressbar 
                    value={cash} 
                    maxValue={100} 
                    text={`${cash} USD`}
                    styles={buildStyles({
                        textColor: "black",
                        pathColor: "var(--mintGreen)",
                        trailColor: "var(--lightBeige)"
                    })}/>
            </div>
            <Typography className={clsx("roboto-light", classes.subheader)}>
                <i>Your rewards can be used for cash value that will be applied during check-out.</i>
            </Typography>
        </div>
    )
  }
};

export default LoyaltyProgramPointsCircle;
