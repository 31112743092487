import {
  Container,
  Grid,
  ButtonBase,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  articleImage: {
    maxWidth: "100%",
    height: 600,
    width: 600,
    objectFit: "cover",
    objectPosition: "top",
  },
  logoImage: {
    maxHeight: "100%",
    width: 200,
  },
  articleText: {
    padding: "20px",
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
  titleText: {
    padding: "20px",
    fontSize: "3.75rem",
    lineHeight: "3.75rem",
  },
  readMoreButton: {
    backgroundColor: "var(--beige)",
    padding: "10px 35px",
  },
  viewMoreButton: {
    marginBottom: 50,
    padding: "10px 35px",
    border: "1px solid black",
  },
  gridContainer: {
    marginBottom: 50,
    marginTop: 50,
  },
  formControl: {
    minWidth: 120,
    float: "right",
    marginBottom: 10,
  },
});

const PressArticles = (props) => {
  const { pressArticles } = props;
  const classes = useStyles();

  return (
    <Container maxWidth="xl" align="center">
      {pressArticles.map((article, index) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            align="center"
            className={classes.gridContainer}
          >
            <Grid item md={6} sm={12}>
              <img
                src={article.image}
                alt="image"
                className={classes.articleImage}
              />
            </Grid>
            <Grid item md={6} sm={12}>
            <Typography className={classes.titleText} variant="h2">
                {article.title}
              </Typography>
              <img
                src={article.logo}
                alt="logo"
                className={classes.logoImage}
              />
              <Typography className={classes.articleText} variant="h3">
                {article.press_date && "Date of Publication: " + new Date(article.press_date).toLocaleDateString('en-US', {year:'numeric',month:'long',day:'numeric'})}
              </Typography>
              <Typography className={classes.articleText} variant="h3">
                {article.description}
              </Typography>
              <ButtonBase className={classes.readMoreButton}
              onClick={() => {
                window.top.location.href = article.link
              }
              }>
                <Typography>Read More</Typography>
              </ButtonBase>
            </Grid>
          </Grid>
        );
      })}
    </Container>
  );
};

export default PressArticles;
