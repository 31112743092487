import {
  ButtonBase,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PurposeCategoryMasterLayout } from "../../layouts";

import handsIcon from "../../img/hands.png";
import waterDropIcon from "../../img/waterDrop.png";
import heartBoxIcon from "../../img/heartBox.png";
import purposerBulb from "../../img/PurposerIcon-TransparentP-P486-01.png";
import OurGlobalImpactMap from "../../components/OurGlobalImpactMap";
import CountryShopByMap from "../../components/CountryShopByMap";
// import mapData from "../../json/map_data.json";

import PurposerHistory from "../../components/PurposerHistory";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useDocumentDescription from "../../hooks/useDocumentDescription";

const useStyles = makeStyles({
  headerContainer: {
    height: 500,
  },
  headerImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
  },
  textContainer: {
    padding: "40px 40px",
  },
  highlightHeaderText: {
    margin: "20px auto",
  },
  highlightsDiv: {
    backgroundColor: "var(--pastelGreen)",
    overflow: "hidden",
    paddingTop: 40,
    paddingBottom: 40,
  },
  divider: {
    width: 3,
  },
  highlightsText: {
    width: "90%",
    marginTop: 20,
    marginBottom: 10,
  },
  highlightImages: {
    //width: "100%",
    height: 150,
  },
  purposerBulb: {
    maxHeight: "100%",
    width: 75,
  },
  impactButtons: {
    borderRadius: 8,
    padding: "2px 45px",
    border: "1px solid black",
  },
  containerMargins: {
    marginTop: "var(--dividerHeight)",
    overflow: "hidden",
  },
});

const OurImpactLayout = () => {
  const classes = useStyles();

  useDocumentTitle("The brands on Purposer are making a difference in communities across the world!");
  useDocumentDescription("When you shop on Purposer, you are making a positive impact on society and the planet. Each brand prioritizes purpose over profit. Ethical shopping made easy.");
  return (
    <PurposeCategoryMasterLayout>
      <Divider />
      <div style={{ height: 680, position: "relative" }}>
        <img
          src="https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/our+impact+page/AdobeStock_402590799.jpeg"
          alt="Our Impact"
          className={classes.headerImage}
        />
        <div style={{
        // textAlign: "center",
        // position: "absolute",
        // top: "50%",
        // left: "50%",
        // transform: "translate(-50%, 50%)"
        left: 0,
        position: "absolute",
        textAlign: "center",
        // verticalAlign: "center",
        top: "50%",
        width: "100%",
        height: "100%"
        }}>
        <Typography
          variant="h1"
          sx={{ fontWeight: "bold", color: "white", fontSize: {xs: "64px", md: "96px"}, textShadow: "2px 3px 5px rgba(0,0,0,0.2);" }}
        >
          Our Impact
        </Typography>
        </div>
      </div>
      <Container maxWidth="lg" className={classes.textContainer}> 
        {/* <div style={{height: "var(--dividerHeight)"}}/> */}
        <Typography
          align="center"
          variant="h2"
          style={{ fontSize: "calc(16px + 1vw)", lineHeight: "2" }}
        >
          Purposer provides ethical sellers a platform to connect with shoppers for the greater good. Behind each of our products and behind each purchase is a person who wants to make a positive impact. We seek to empower sellers around the world to support a cause or charity they are passionate about so each purchase can make a difference. We are committed to upholding the standards of the online shopping experience all while bringing attention to issues faced by communities across the globe. We are joining our fellow community in putting purpose over profit.
        </Typography>
      </Container>
      <div className={classes.highlightsDiv}>
        <Container maxWidth="xl">
          <Typography
            variant="h4"
            align="center"
            className={classes.highlightHeaderText}
          >
            <b>Our Impact Over the Last Year</b>
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="center"
            
            align="center"
            rowSpacing={2}
            columnSpacing={2}
          >
            <Grid item md sm={12}>
              <img
                src={handsIcon}
                alt="hands"
                className={classes.highlightImages}
              />
              <Typography
                align="center"
                variant="h5"
                className={classes.highlightsText}
              >
                <i>
                Over the last year, we have partnered with local organizations here in San Diego, CA! We have been part of many events at the University of San Diego where we educate students on the importance of conscious consumerism and about entrepreneurship. We have also sponsored yoga classes and beach volleyball groups by providing sustainable refreshments.
                </i>
              </Typography>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <Grid item md sm={12}>
              <img
                src={waterDropIcon}
                alt="waterDrop"
                className={classes.highlightImages}
              />
              <Typography
                align="center"
                variant="h5"
                className={classes.highlightsText}
              >
                <i>
                Our community of real people inspire us everyday. As a team we decided to do our part by cleaning up our local beach a couple times a month. Being a San Diego based startup, it is important to us to keep our beaches clean & safe for everyone. We invite our friends and family to tag along as well. 
                </i>
              </Typography>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <Grid item md sm={12}>
              <img
                src={heartBoxIcon}
                alt="heartBox"
                className={classes.highlightImages}
              />
              <Typography
                align="center"
                variant="h5"
                className={classes.highlightsText}
              >
                <i>
                As of 2022, we have personally supported over 30 ethical brands by shopping their products and supporting their cause. We love connecting with like minded individuals that also believe in shopping for the greater good. When you decide to sell on our site you are not only gaining a platform but also your number one supporter. 
                </i>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container maxWidth="xl" className={classes.textContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <img
            src={purposerBulb}
            alt="Purposer Bulb"
            className={classes.purposerBulb}
          />
          <Typography variant="h4">
            <b>Download Impact Reports</b>
          </Typography>
        </div>
        <Container maxWidth="md">
          <Typography align="center" variant="body1">
            Every year, we look back at all the progress our company and seller
            partners have made. We're always looking to improve and inspire
            others to do the same as well as join our #BeAPurposer mission.
          </Typography>
        </Container>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          align="center"
          style={{ marginTop: 40 }}
        >
          <Grid item>
            <ButtonBase className={classes.impactButtons}>
              <Typography style={{ fontSize: "1.3rem" }}>2022</Typography>
            </ButtonBase>
          </Grid>
          <Grid item>
            <ButtonBase className={classes.impactButtons}>
              <Typography style={{ fontSize: "1.3rem" }}>2023</Typography>
            </ButtonBase>
          </Grid>
          <Grid item>
            <ButtonBase className={classes.impactButtons}>
              <Typography style={{ fontSize: "1.3rem" }}>2024</Typography>
            </ButtonBase>
          </Grid>
        </Grid>
      </Container>
      <div style={{ backgroundColor: "#82aa9f" }}>
        <Container maxWidth="xl" style={{ overflow: "hidden" }}>
          <Container maxWidth="md">
            {/* <Typography
              variant="h4"
              align="center"
              className={classes.containerMargins}
            >
              <b>Our Global Impact</b>
            </Typography>
            <Divider
              style={{
                height: 4,
                backgroundColor: "black",
                marginTop: 5,
                marginBottom: 30,
              }}
            /> */}
          </Container>
          {/* <OurGlobalImpactMap /> */}
          <CountryShopByMap  />
        </Container>
      </div>
      <Container maxWidth="xl" style={{ overflow: "hidden" }}>
        <Container maxWidth="md">
          <Typography
            variant="h4"
            align="center"
            className={classes.containerMargins}
          >
            <b>Let's Break It Down For You</b>
          </Typography>
          <Divider
            style={{
              height: 2,
              backgroundColor: "black",
              marginTop: 5,
              marginBottom: 30,
            }}
          />
        </Container>
        <PurposerHistory />
      </Container>
    </PurposeCategoryMasterLayout>
  );
};

export default OurImpactLayout;
