import {
    Container,
    Typography,
    Button,
    Avatar,
    Grid,
    TextField,
    MenuItem,
    Radio,
    Divider,
    ButtonGroup,
    Box
  } from "@mui/material";
  import {
    PaymentElement
  } from '@stripe/react-stripe-js'
  import {useState} from 'react'
  import {useStripe, useElements} from '@stripe/react-stripe-js';
  import {ReviewOrder,NumberBubble} from './CartLayout'
  import StripeSaveCardLayout from './StripeSaveCardLayout'
  import { createOrder } from "../../util/orders";
  import { createStripeCheckoutSession } from "../../util/stripe";
  import toast, { Toaster } from 'react-hot-toast';

  const { v4: uuidv4 } = require('uuid');

  export default function CheckoutForm(props) {
    const { cart, coupon, checkoutInfo, taxRate, classes, selectedShippingInfo, cartData, invoiceId, paymentIntent } = props;
  
    // const stripe = useStripe();
  
    // const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  
    const handleSubmit = async (e) => {
      if (!isLoading) {
        e.preventDefault();
    
        // if (!stripe || !elements) {
        //   // Stripe.js has not yet loaded.
        //   // Make sure to disable form submission until Stripe.js has loaded.
        //   return;
        // }
    
        setIsLoading(true);
    
        // await fetch("https://q2ffms2wed.execute-api.us-west-1.amazonaws.com/dev/update-payment-intent", {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({
        //     id: paymentIntentId,
        //     amount: parseInt((subtotal + taxAndFees + checkoutInfo.shippingPrice) * 100)
        //   }),
        // })

        //Handle adding order to DBs
        createOrder(cart, coupon, selectedShippingInfo, (orderData) => {
          // console.log('createOrder response:')
          // console.log(orderData)
          if (orderData.error) {
            if (orderData.error?.message?.includes('out of stock')) {
              const errMessage = orderData.error.message;
              const matches = errMessage.match(/\[(.*?)\]/);
              if (matches) {
                  const oosSkuID = matches[1];
                  const oosProduct = cart?.find(i => i.skuID == oosSkuID);
                  toast.error(`${oosProduct.productData.productName} is out of stock!`)
              }
            }
          } else {
            window.location.href = orderData.results.checkout.url;
          }
          setIsLoading(false);
        });

        // createStripeCheckoutSession(cart, orderID, (data) => {
        //   if (!data.hasOwnProperty("error") && !data.hasOwnProperty("message") && data?.success == true) {
        //     console.log(data)
        //     createOrder(orderID, data.paymentIntent.id, invoiceId, cart, (orderData) => {
        //       console.log('createOrder response:')
        //       console.log(orderData)
        //       window.location.href = data.url;
        //     });
        //   } else {
        //     console.log("Failed to create stripe checkout session!")
        //   }
        // });

        // createOrder(orderID, paymentIntentId, invoiceId, cart, (data) => {
        //   console.log('createOrder response:')
        //   console.log(data)
        //   // Clear cart and redirect
        //   //dispatch(clearCart());
        // });

        // const { error } = await stripe.confirmPayment({
        //   elements,
        //   confirmParams: {
        //     // Make sure to change this to your payment completion page
        //     return_url: `${window.location.origin}/orderPlaced`,
        //   },
        // });
    
        // // This point will only be reached if there is an immediate error when
        // // confirming the payment. Otherwise, your customer will be redirected to
        // // your `return_url`. For some payment methods like iDEAL, your customer will
        // // be redirected to an intermediate site first to authorize the payment, then
        // // redirected to the `return_url`.
        // if (error.type === "card_error" || error.type === "validation_error") {
        //   setMessage(error.message);
        // } else {
        //   setMessage("An unexpected error occured.");
        // }
      }
    }
  
    return (
      <>
        {/* <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <NumberBubble number={3} classes={classes} />
          <Typography className={classes.sectionHeaderText}>
            <b>Payment</b>
          </Typography>
          <img
            src="https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/My+Cart+Page/Credit+Card+Icons.jpg"
            alt=""
            className={classes.creditCardImg}
          />
        </Box>
        <form id="payment-form">  */}
          {/*<form id="payment-form" disabled={isLoading} onSubmit={handleSubmit}> */}
          {/* <div className={classes.sectionSpacing}>
            <PaymentElement id="payment-element" />
            {message && <div id="payment-message">{message}</div>}
          </div>
          <div className={classes.sectionSpacing}>
            {message && <div id="payment-message">{message}</div>}
          </div> */}
          {/* <div className={classes.sectionSpacing}>
            <ReviewOrder
              cart={cart}
              checkoutInfo={checkoutInfo}
              taxRate={cartData.taxRate}
              classes={classes}
              paymentIntent={paymentIntent}
            />
          </div> */}
          <Toaster 
            toastOptions={{
              className: '',
              style: {
                border: '1px solid #713200',
                padding: '16px',
                color: '#713200',
              },
            }}
          />
          <div className={classes.horizontalCenter}>
            <Button className={classes.placeOrderButton} disabled={isLoading} onClick={(e) => handleSubmit(e)}> 
              <Typography className={classes.placeOrderButtonText}>
                {!isLoading ? <b>GO TO CHECKOUT</b> : <b>LOADING CHECKOUT...</b>}
              </Typography>
            </Button>
          </div>
        {/* </form> */}
      </>
    )
  }