import { Container, Typography, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PopularDiscussions from "./PopularDiscussions";
import StartADiscussion from "./StartADiscussion";
import clsx from "clsx";
import { useRef } from "react";
import useElementWidth from "../hooks/useElementWidth";
import { styled } from "@mui/material/styles";

//Default Prop Values
import forumDataDefault from "../json/forum.json";

const useStyles = makeStyles({
  subheader: {
    marginBottom: 40,
  },
  imgGridItem: {
    display: "flex",
    alignItems: "center",
  },
  img: {
    width: "100%",
    objectFit: "cover",
    borderRadius: 10,
  },
});

const PopularDiscussionsContainer = styled("div")({
  marginTop: 80,
});

function Forum(props) {
  const classes = useStyles();
  const { forumData = forumDataDefault } = props;
  const imgRef = useRef(null);
  const imgWidth = useElementWidth(imgRef);

  return (
    <Container maxWidth="xl">
      <Typography align="center" variant="h3" className={"roboto-medium"}>
        Forum
      </Typography>
      <Typography
        align="center"
        variant="h5"
        className={clsx(classes.subheader, "roboto-regular")}
      >
        Connect With Other Purposer Sellers
      </Typography>
      <Typography variant="h5" className={"roboto-medium"}>
        Start a Discussion
      </Typography>
      <Grid container direction="row" columnSpacing={10}>
        <Grid item sm={12} md={6}>
          <StartADiscussion />
        </Grid>
        <Grid item sm={12} md={6} className={classes.imgGridItem}>
          <img
            src={forumData.img}
            alt=""
            className={classes.img}
            ref={imgRef}
            style={{ height: 0.55 * imgWidth }}
          />
        </Grid>
      </Grid>
      <PopularDiscussionsContainer>
        <PopularDiscussions
          popularDiscussionsData={forumData.discussion_data}
        />
      </PopularDiscussionsContainer>
    </Container>
  );
}

export default Forum;
