import { useEffect, useState } from "react";
import { ManagementMasterLayout } from "../../layouts";
import sellerDashboardJsonData from "../../json/seller_dashboard.json";
import {
  Box,
  ButtonBase,
  Button,
  Divider,
  Grid,
  Typography,
  Container,
  Select,
  FormControl,
  selectClasses,
  svgIconClasses,
  MenuItem,
  menuItemClasses,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { ExpandMore } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RecentActivity from "../../components/RecentActivity";
import { formatDate, getFirstOfEachMonth, round, shortenUUID } from "../../util/utilities";
import { useParams } from "react-router-dom";
import {
  getOrdersBySellerId,
} from "../../util/orders";
import { RETURN_STATUS } from "../../util/constants";
import PlaidApp from "../../components/PlaidApp";
import { getSellerPayoutInfo, updateStripeConnectedAcctPayoutSchedule } from "../../util/stripe";
import ExportToCSVButton from "../../components/ExportToCSVButton";


const useStyles = makeStyles({
  sectionColor: {
    color: "#EE907B"
  },
  editText: {
    textDecoration: "underline",
    fontSize: "1.1rem"
  },
  headerBoxText: {
    fontSize: "1.5rem",
  },
  subheaderBoxText: {
    fontSize: "1.15rem",
    marginBottom: "12px",
  },
  subtotalText: {
    fontSize: "1.5rem",
    marginBottom: "8px"
  },
  subtotalText2: {
    fontSize: "1.5rem",
    marginBottom: "8px",
    textAlign: "right"
  },
  editBox: {
    border: "solid 1px",
    textAlign: "center",
    padding: "2px",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  right: {
    textAlign: "right",
  },
  totalNum: {
    color: "var(--coral)", fontSize: "1.5rem", textAlign: "right", fontWeight: "bold"
  },
  cartButton: {
    textTransform: "none",
    borderRadius: 6,
    minWidth: 170,
    minHeight: 55,
    padding: "8px 16px",
    bottom: "16px",
    borderColor: "#000000",
    backgroundColor: "var(--mintGreen)",
    "&:hover": {
      border: "solid 1px black",
      backgroundColor: "var(--mintGreen)"
    },
  },
  cartButtonText: {
    fontSize: "1.15rem",
    color: "#000000",
  },
  cartButtonCenter: {
    display: "flex",
    justifyContent: "center",
  },
});

const StyledMenuItem = styled(MenuItem)({
  backgroundColor: "#FFFFFF",
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
});

const ShoppingCart = styled("img")({
  width: 35,
  height: 35,
  marginRight: "16px"
});

const FinancesLayout = () => {
  const sellerDashboardData = sellerDashboardJsonData;
  const classes = useStyles();
  const { id: sellerIDparam } = useParams();

  const [orderData, setOrderData] = useState(null);
  const [financeData, setFinanceData] = useState(null);
  const [financeDataByMonth, setFinanceDataByMonth] = useState(null);
  const [selectedFinanceData, setSelectedFinanceData] = useState(null);
  const [netRevenue, setNetRevenue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingPayoutInfo, setLoadingPayoutInfo] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedPayoutInfo, setIsLoadedPayoutInfo] = useState(false);
  const [editingPayoutSettings, setEditingPayoutSettings] = useState(false);
  const [formValues, setFormValues] = useState({
    updatedBank: false,
    bankAcctId: "",
    schedule: {
      interval: "", // daily, weekly, monthly
      //weekly_anchor: "",  // monday, tuesday, wednesday, thursday, friday, saturday, sunday
      //monthly_anchor: "", // 1-31
    },
    last4BankAcct: "",
    balanceAvailable: 0,
  });

  const [starterFormValues, setStarterFormValues] = useState({});

  const handleFormValues = (name, value) => {
    setFormValues((preValues) => {
      return { ...preValues, [name]: value };
    });
  };

  const handleStarterFormValues = (name, value) => {
    setStarterFormValues((preValues) => {
      return { ...preValues, [name]: value };
    });
  };

  const handleScheduleChange = (name, value) => {
    const newSchedule = { ...formValues.schedule }
    newSchedule[name] = value;
    handleFormValues('schedule', newSchedule);
  };

  const handleSavePayoutSettings = (e) => {
    e.preventDefault();
    setEditingPayoutSettings(false);
    setLoadingPayoutInfo(true);
    setIsLoadedPayoutInfo(false);

    const scheduleApiBody = { 
      interval: formValues.schedule.interval
    }
    if (scheduleApiBody.interval === "weekly") {
      scheduleApiBody.weekly_anchor = formValues.schedule.weekly_anchor;
    } 
    if (scheduleApiBody.interval === "monthly") {
      scheduleApiBody.monthly_anchor = formValues.schedule.monthly_anchor;
    }


    updateStripeConnectedAcctPayoutSchedule(sellerIDparam, scheduleApiBody, (data) => {
      if (data?.success) {
        getSellerPayoutInfo(sellerIDparam, (data) => {
          handleFormValues("schedule", data.schedule);
          handleStarterFormValues("schedule", data.schedule);
          handleFormValues("last4BankAcct", data.last4BankAcct);
          handleFormValues("balanceAvailable", data.balanceAvailable);
          setLoadingPayoutInfo(false);
          setIsLoadedPayoutInfo(true);
        })
      }
    });
  }

  // Initial data load, defaults filter to This Year
  useEffect(() => {
    if (!isLoaded && !loading) {
      setLoading(true);
      getOrdersBySellerId(sellerIDparam, (data) => {
        let tempOrderFromDate = data?.length > 0 ? new Date(data[0].orderDateTime) : new Date();
        let tempOrderToDate = data?.length > 0 ? new Date(data[0].orderDateTime) : new Date();

        for (const order of data) {
          const orderDate = new Date(order.orderDateTime);
          if (orderDate < tempOrderFromDate) {
            tempOrderFromDate = orderDate;
          }
          if (orderDate > tempOrderToDate) {
            tempOrderToDate = orderDate;
          }
        }

        let dates = getFirstOfEachMonth(tempOrderFromDate, tempOrderToDate);
        const orderFinancesByMonth = [];
        for (const date of dates) {
          let currOrderFinanceObject = {
            monthAndYear: '',
            totalSalesAmt: 0,
            totalRefundsAmt: 0,
            totalSalesTaxAmt: 0,
            totalTransactionFeesAmt: 0,
          }
          currOrderFinanceObject.monthAndYear = date.toLocaleString('default', { month: 'long', year: 'numeric' });
          orderFinancesByMonth.push(currOrderFinanceObject);
        }

        const orderFinances = [];
        let netRevenueCalc = 0;
        for (const order of data) {
          netRevenueCalc += order.netTotalAfterTaxAndFeesAmt;
          const orderDate = new Date(order.orderDateTime);
          const orderDateMonthAndYear = orderDate.toLocaleString('default', { month: 'long', year: 'numeric' });
          const currOrderFinanceMonth = orderFinancesByMonth.find((orderFinanceMonth) => orderFinanceMonth.monthAndYear === orderDateMonthAndYear);
          const currOrderFinances = {
            totalSalesAmt: 0,
            totalRefundsAmt: 0,
            totalSalesTaxAmt: 0,
            totalTransactionFeesAmt: 0,
          }
          currOrderFinances.totalSalesAmt += order.paymentAmount;
          currOrderFinances.totalSalesTaxAmt += order.totalTaxAmt;
          currOrderFinances.totalTransactionFeesAmt += order.totalFeeAmt;
          currOrderFinanceMonth.totalSalesAmt += order.paymentAmount;
          currOrderFinanceMonth.totalSalesTaxAmt += order.totalTaxAmt;
          currOrderFinanceMonth.totalTransactionFeesAmt += order.totalFeeAmt;
          for (const refundRequest of order.refundRequests) {
            if (refundRequest.status === RETURN_STATUS.COMPLETED) {
              currOrderFinances.totalRefundsAmt += refundRequest.paymentAmount;
              currOrderFinanceMonth.totalRefundsAmt += refundRequest.paymentAmount;
            }
          }
          orderFinances.push(currOrderFinances);
        }
        setFinanceDataByMonth(orderFinancesByMonth);
        if (orderFinancesByMonth.length > 0) {
          setSelectedFinanceData(orderFinancesByMonth[0]);
        }
        setFinanceData(orderFinances);
        setOrderData(data);
        setNetRevenue(netRevenueCalc);
        setIsLoaded(true);
        setLoading(false);
        // setSelectedOrderFilter("This Year");
        // setSelectedSalesFilter("This Year");
        // assembleOrdersGraph(orderCountByThisYear, yearLabels);
        // assembleSalesGraph(salesAmountByThisYear, yearLabels);
        // setOrderFromDate(tempOrderFromDate);
        // setOrderToDate(tempOrderToDate);
        // setSalesFromDate(tempOrderFromDate);
        // setSalesToDate(tempOrderToDate);
        // setOrderCount(tempOrderCount);
        // tempSalesAmount = Math.round((tempSalesAmount + Number.EPSILON) * 100) / 100
        // setSalesAmount(tempSalesAmount);
        // setOrderData(data);
        // setIsLoaded(true);
      })
    }
  }, []);

  // Initial SELLER PAYOUT data load
  useEffect(() => {
    if (!loadingPayoutInfo && !isLoadedPayoutInfo) {
      setLoadingPayoutInfo(true);
      getSellerPayoutInfo(sellerIDparam, (data) => {
        handleFormValues("schedule", data.schedule);
        handleStarterFormValues("schedule", data.schedule);
        handleFormValues("last4BankAcct", data.last4BankAcct);
        handleFormValues("balanceAvailable", data.balanceAvailable);
        setLoadingPayoutInfo(false);
        setIsLoadedPayoutInfo(true);
      })
    }
  }, []);

  useEffect(() => {
    if (!loadingPayoutInfo && !isLoadedPayoutInfo && formValues.updatedBank === true) {
      setLoadingPayoutInfo(true);
      getSellerPayoutInfo(sellerIDparam, (data) => {
        handleFormValues("last4BankAcct", data.last4BankAcct);
        setLoadingPayoutInfo(false);
        setIsLoadedPayoutInfo(true);
      })
    }
  }, [formValues.updatedBank]);

  function handleChangeSelectedFinanceData(e) {
    const newSelectedFinanceData = financeDataByMonth.find((financeData) => financeData.monthAndYear === e.target.value);
    setSelectedFinanceData(newSelectedFinanceData);
  }

  function checkIfScheduleFormChanged(formValues, starterFormValues) {
    if (!formValues?.schedule?.interval) {
      return true;
    }
    if (!starterFormValues?.schedule?.interval) {
      return true;
    }

    if (starterFormValues.schedule.interval === "daily") {
      if (formValues.schedule.interval != "daily") {
        return true;
      }
    }

    if (starterFormValues.schedule.interval === "weekly") {
      if (formValues.schedule.interval != "weekly" || starterFormValues.schedule.weekly_anchor != formValues.schedule?.weekly_anchor) {
        return true;
      }
    }

    if (starterFormValues.schedule.interval === "monthly") {
      if (formValues.schedule.interval != "monthly" || starterFormValues.schedule.monthly_anchor != formValues.schedule?.monthly_anchor) {
        return true;
      }
    }

    return false;
  }

  const formatDataForCsvExport = () => {

    let csvData = []
    orderData?.forEach((row) => {
      let currOrder = {
        orderDate: formatDate(row.orderDateTime),
        orderID: shortenUUID(row.orderID),
        netTotalAmt: round(row.netTotalAmt, 2), 
        totalDiscounts: round(row.totalDiscountAmt, 2),
        totalShippingAmt: round(row.totalShippingAmt, 2),
        totalRefundedAmt: round(row.totalRefundedAmt, 2),
        totalTaxAmt: round(row.totalTaxAmt, 2),
        totalFeeAmt: round(row.totalFeeAmt, 2),
        netTotalAfterTaxAndFeesAmt: round(row.netTotalAfterTaxAndFeesAmt, 2),
      }
      csvData.push(currOrder);
    });

    return csvData;
  };

  return (
    <ManagementMasterLayout
      title={"Shop Finances"}
      sellerName={sellerDashboardData.name}
      sellerPicture={sellerDashboardData.pic}
    >
      <Container maxWidth='xl'>
        <Grid
          container
          spacing={12}
        >
          <Grid item xs={12} sm={12} md={8}>
            <h1 style={{ color: "var(--coral)" }}>Announcements:</h1>
            <Box sx={{ my: 3, py: 6, px: 3, borderRadius: 2, border: 1, width: '100%' }}>
            <Grid container spacing={12} justifyContent="space-between" alignContent="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography className={clsx("roboto-light", classes.subheaderBoxText)} >You may see coupons applied to your account even though you are not running a coupon. This may be because a
                  customer is using a "Purposer Issued Coupon". These are indicated with an asterisk (*) on your order finances. Your payout and amount owed to you will <u>not</u> be reduced even though this coupon is running. Purposer
                  will cover the expenses for this coupon, never you :)</Typography>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ my: 3, py: 6, px: 3, borderRadius: 2, border: 1, width: '100%' }}>
              <Grid container spacing={12} justifyContent="space-between" alignContent="center">
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <Typography className={clsx("roboto-medium", classes.headerBoxText)} >Available For Deposit</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                  <div className={classes.editBox}>
                    <ButtonBase
                      onClick={(e) => {
                        setEditingPayoutSettings(e => !e);
                      }}
                    >
                      <Typography className={clsx("roboto", classes.editText)} >{editingPayoutSettings ? `Close` : `Edit`}</Typography>
                    </ButtonBase>
                  </div>
                  {editingPayoutSettings && (checkIfScheduleFormChanged(formValues, starterFormValues)) &&
                  <div className={classes.editBox}>
                    <ButtonBase
                      onClick={(e) => {
                        const currEditingPayoutSettings = editingPayoutSettings;
                        if (currEditingPayoutSettings) {
                          handleSavePayoutSettings(e);
                        }
                        setEditingPayoutSettings(!currEditingPayoutSettings);
                      }}
                    >
                      <Typography className={clsx("roboto", classes.editText)} >{`Save`}</Typography>
                    </ButtonBase>
                  </div>
                  }
                </Grid>
              </Grid>
              {loadingPayoutInfo ? <CircularProgress /> : <h1>{`$${formValues?.balanceAvailable != null ? round(formValues.balanceAvailable / 100, 2) : ''}`}</h1>}
              <div style={{ height: "8px" }} />
              <Divider />
              <div style={{ height: "20px" }} />
              {loadingPayoutInfo ? <CircularProgress /> :
                <Grid container spacing={12} justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Typography className={clsx("roboto-medium", classes.subheaderBoxText)} >Payout Schedule</Typography>
                    <Grid container columnSpacing={4}>
                      <Grid item xs={12} sm={6} md={6} style={{ display: "flex", columnGap: "16px", alignItems: "center" }} >
                        <Typography>{`Every`}</Typography>
                        <FormControl
                          sx={{ width: "100%" }} size="small"
                          disabled={!editingPayoutSettings}
                        >
                          <Select
                            value={formValues?.schedule?.interval}
                            onChange={(e) => {
                              handleScheduleChange("interval", e.target.value)
                            }}
                          >
                            {depositOptions &&
                              depositOptions.map((option, index) => {
                                return (
                                  <StyledMenuItem key={index} value={option.value}>
                                    <Typography className={"roboto-regular"}>
                                      {option.label}
                                    </Typography>
                                  </StyledMenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>
                      {formValues?.schedule?.interval !== "daily" &&
                        <Grid item xs={12} sm={6} md={6} style={{ display: "flex", columnGap: "16px", alignItems: "center" }} >
                          <Typography style={{ whiteSpace: "nowrap" }}>{formValues?.schedule?.interval === "weekly" ? "on" : "on the"}</Typography>
                          <FormControl
                            sx={{ width: "100%" }} size="small"
                            disabled={!editingPayoutSettings}
                          >
                            <Select
                              value={formValues?.schedule?.interval === "weekly" ? formValues?.schedule?.weekly_anchor : formValues?.schedule?.monthly_anchor}
                              onChange={(e) => {
                                handleScheduleChange(formValues?.schedule?.interval === "weekly" ? "weekly_anchor" : "monthly_anchor", e.target.value)
                              }}
                            >
                              {formValues?.schedule?.interval === "weekly" ?
                                weeklyAnchorOptions.map((option, index) => {
                                  return (
                                    <StyledMenuItem key={index} value={option.value}>
                                      <Typography className={"roboto-regular"}>
                                        {option.label}
                                      </Typography>
                                    </StyledMenuItem>
                                  );
                                })
                                :
                                monthlyAnchorOptions.map((option, index) => {
                                  return (
                                    <StyledMenuItem key={index} value={option.value}>
                                      <Typography className={"roboto-regular"}>
                                        {option.label}
                                      </Typography>
                                    </StyledMenuItem>
                                  );
                                })
                              }
                            </Select>
                          </FormControl>
                        </Grid>
                      }
                    </Grid>

                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <div className={classes.right}>
                      <Typography className={clsx("roboto-medium", classes.subheaderBoxText)} >{`Bank Acct`}</Typography>
                      <Typography className={clsx("roboto", classes.subheaderBoxText)}>{`Ending in: ${formValues?.last4BankAcct}`}</Typography>
                    </div>
                  </Grid>
                </Grid>
              }
              {isLoadedPayoutInfo && editingPayoutSettings &&
                <div>
                  <div style={{ height: "8px" }} />
                  <Divider />
                  <div style={{ height: "20px" }} />
                  <PlaidApp formValues={formValues} handleFormValues={handleFormValues} sellerID={sellerIDparam} />
                </div>
              }
            </Box>
          </Grid>
        </Grid>
        {!formValues.last4BankAcct && isLoadedPayoutInfo &&
          <PlaidApp formValues={formValues} handleFormValues={handleFormValues} sellerID={sellerIDparam} />
        }
        <h1 style={{ color: "var(--coral)" }}>Activity Summary</h1>
        {financeDataByMonth?.length > 0 &&
          <FormControl size="small">
            <Select defaultValue={financeDataByMonth[0].monthAndYear} onChange={handleChangeSelectedFinanceData}>
              {financeDataByMonth.map((option, index) => {
                return (
                  <StyledMenuItem key={index} value={option.monthAndYear}>
                    <Typography className={"roboto-regular"}>
                      {option.monthAndYear}
                    </Typography>
                  </StyledMenuItem>
                );
              })}
            </Select>
          </FormControl>}
        <Box sx={{ my: 3, py: 6, px: 3, borderRadius: 2, border: 1, width: '100%' }}>
          <Grid container spacing={12}>
            <Grid item xs={9}>
              <div style={{ display: "flex", flexDirection: "row", alignContent: "center", marginBottom: "16px" }}>
                <ShoppingCart
                  src={
                    "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/header/Shopping+Cart-01.png"
                  }
                  alt=""
                />
                <Typography className={clsx("roboto-medium", classes.headerBoxText)} >Sales</Typography>
              </div>
              <Typography className={clsx("roboto-light", classes.subtotalText)} >Total Sales</Typography>
              <Typography className={clsx("roboto-light", classes.subtotalText)} >Refunds</Typography>
              <Typography className={clsx("roboto-light", classes.subtotalText)} >Sales Tax Paid By Buyer</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.totalNum}>${selectedFinanceData ? round((selectedFinanceData?.totalSalesAmt - selectedFinanceData?.totalRefundsAmt - selectedFinanceData?.totalSalesTaxAmt), 2) : ''}</Typography>
              <div style={{ height: "16px" }} />
              <Typography className={clsx("roboto-light", classes.subtotalText2)} >${selectedFinanceData ? round(selectedFinanceData?.totalSalesAmt, 2) : ''}</Typography>
              <Typography className={clsx("roboto-light", classes.subtotalText2)} >${selectedFinanceData ? round(selectedFinanceData?.totalRefundsAmt, 2) : ''}</Typography>
              <Typography className={clsx("roboto-light", classes.subtotalText2)} >${selectedFinanceData ? round(selectedFinanceData?.totalSalesTaxAmt, 2) : ''}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ my: 3, py: 6, px: 3, borderRadius: 2, border: 1, width: '100%' }}>
          <Grid container spacing={12}>
            <Grid item xs={9}>
              <div style={{ display: "flex", flexDirection: "row", alignContent: "center", marginBottom: "16px" }}>
                <ReceiptLongIcon fontSize="large" />
                <div style={{ width: "16px" }} />
                <Typography className={clsx("roboto-medium", classes.headerBoxText)} >Fees</Typography>
              </div>
              {/* <Typography className={clsx("roboto-light", classes.subtotalText)} >Listing Fees</Typography> */}
              <Typography className={clsx("roboto-light", classes.subtotalText)} >Transaction Fees</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.totalNum}>${selectedFinanceData ? round(selectedFinanceData?.totalTransactionFeesAmt, 2) : ''}</Typography>
              <div style={{ height: "16px" }} />
              {/* <Typography className={clsx("roboto-light", classes.subtotalText2)} >{"$1.00 -- TODO"}</Typography> */}
              <Typography className={clsx("roboto-light", classes.subtotalText2)} >${selectedFinanceData ? round(selectedFinanceData?.totalTransactionFeesAmt, 2) : ''}</Typography>
            </Grid>
          </Grid>
        </Box>


        <h1 style={{ color: "var(--coral)" }}>Recent Activity</h1>
        <div style={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "space-between" }}>
          <Typography style={{ color: "black" }} variant={"h5"}>Your Net Revenue: <b>{`$${round(netRevenue, 2)}`}</b></Typography>
          <ExportToCSVButton
            csvData={formatDataForCsvExport()}
            fileName={'recentFinanceActivity'}
          />
        </div>
        <RecentActivity trackShipData={orderData} />

        <div style={{ height: "var(--dividerHeight)" }} />

      </Container>
    </ManagementMasterLayout>
  );
};

const autoBilling = [
  { label: " ", value: "" },
  { label: "ON", value: "ON" },
  { label: "OFF", value: "OFF" },
];

const depositOptions = [
  { label: "Day", value: "daily" },
  { label: "Week", value: "weekly" },
  { label: "Month", value: "monthly" },
]

const weeklyAnchorOptions = [
  { label: "Sunday", value: "sunday" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
  { label: "Sunday", value: "sunday" },
]

const monthlyAnchorOptions = [
  { label: "1st", value: "1" },
  { label: "2nd", value: "2" },
  { label: "3rd", value: "3" },
  { label: "4th", value: "4" },
  { label: "5th", value: "5" },
  { label: "6th", value: "6" },
  { label: "7th", value: "7" },
  { label: "8th", value: "8" },
  { label: "9th", value: "9" },
  { label: "10th", value: "10" },
  { label: "11th", value: "11" },
  { label: "12th", value: "12" },
  { label: "13th", value: "13" },
  { label: "14th", value: "14" },
  { label: "15th", value: "15" },
  { label: "16th", value: "16" },
  { label: "17th", value: "17" },
  { label: "18th", value: "18" },
  { label: "19th", value: "19" },
  { label: "20th", value: "20" },
  { label: "21st", value: "21" },
  { label: "22nd", value: "22" },
  { label: "23rd", value: "23" },
  { label: "24th", value: "24" },
  { label: "25th", value: "25" },
  { label: "26th", value: "26" },
  { label: "27th", value: "27" },
  { label: "28th", value: "28" },
  { label: "29th", value: "29" },
  { label: "30th", value: "30" },
  { label: "31st", value: "31" },
]

export default FinancesLayout;
