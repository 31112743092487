import { Grid, Avatar, Typography, Box, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";

const useStyles = makeStyles((theme) => ({
  imageAvatar: {
    width: "100%",
    height: "100%",
  },
  avatar: {
    // position: 'relative', // Add this
    // display: 'flex',
    alignItems: 'center',
    width: "10em",
    height: "13em",
    // borderRadius: "1em",
    marginBottom: "10px",
    // zIndex: 1,
  },
  avatarMobile: {
    position: 'relative', // Add this
    // display: 'flex',
    width: "6em",
    height: "8em",
    borderRadius: "1em",
    marginBottom: "10px",
    zIndex: 1,
  },
  text: {
    textAlign: "center",
    fontSize: "1.5rem",
  },
  mobileText: {
    textAlign: "center",
    fontSize: "1.1rem",
  },
  avatarWrapper: {
    overflow: 'hidden',
    height: "13em",
    borderRadius: "1.5em 1.5em 0 0", // Round top corners only
  },
  avatarWrapperMobile: {
    overflow: 'hidden',
    height: "8em", 
    borderRadius: "1.5em 1.5em 0 0", // Round top corners only
  },
  textContainer: {
    backgroundColor: 'white',
    borderRadius: "0 0 1em 1em", // Round bottom corners only
    fontSize: "1.5rem",
    textAlign: "center",
    padding: theme.spacing(0.2), // Add some padding inside the container
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Optional: adds a subtle shadow
    borderTop: "none", // Removes the top border that would be visible after the image
  },
  // Adjust the mobile version accordingly
  textContainerMobile: {
    // Similar adjustments as textContainer
    backgroundColor: 'white',
    borderRadius: "0 0 1em 1em", // Round bottom corners only
    fontSize: "1.2rem",
    textAlign: "center",
    padding: theme.spacing(1), // Add some padding inside the container
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Optional: adds a subtle shadow
    borderTop: "none", // Removes the top border that would be visible after the image
  },
}));


const CategoryBox = ({ classes, item, parentCategoryId, mobile }) => {
  let link;
  if (item.categoryIds?.length > 0) {
    link = "/shop/categories/" + item.categoryIds?.join('+') + "/" + encodeURI(item.title)
  } else if (item.lessThanPrice) {
    link = "/shop/productsByPrice/" + item.lessThanPrice + "/" + encodeURI(item.title)
  }

  return (
    <Grid item xs={mobile ? 4 : 2} sm={mobile ? 4 : 2} md={mobile ? 4 : 2} lg={mobile ? 4 : 2}>
      <a href={link}>
        <div className={mobile ? classes.avatarWrapperMobile : classes.avatarWrapper}>
          <Avatar 
            variant="square"
            src={item.img} 
            alt={item.name} 
            className={classes.imageAvatar}
            href={link}
          />
        </div>
      </a>
      <Box className={mobile ? classes.textContainerMobile : classes.textContainer}>
        <Typography noWrap={true} className={mobile ? classes.mobileText : classes.text}>{mobile ? (item.mobileTitle || item.title) : item.title }</Typography>
      </Box>
    </Grid>
  );
};

const SHOP_BY_CATEGORY_DATA = [
  {
    title: "Women",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/women_category.png",
    categoryIds: [28, 101, 106, 3, 67, 125, 32, 43, 45, 35],
  },
  {
    title: "Men",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/men_category.png",
    categoryIds: [34, 27, 102, 107, 115],
  },
  {
    title: "Kids",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/kids_category.png",
    categoryIds: [29, 103],
  },
  {
    title: "Under $20",
    mobileTitle: "Under $20",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/under_20_dollars_category.png",
    lessThanPrice: 20,
  },
  {
    title: "Holidays",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/christmas_category.png",
    categoryIds: [8],
  },
  {
    title: "Gifts Under $35",
    mobileTitle: "Gift Deals",
    img: "https://d2eudfgh8kpk8j.cloudfront.net/Photos+for+Site+Pages/Homepage+Photos/under_35_dollars_category.png",
    lessThanPrice: 35,
  },
];

const CategoryHomepageView = ({ data, parentCategoryId = -1 }) => {
  const classes = useStyles();
  const mobile = useMobileMediaQuery();

  return (
    <Container maxWidth="xl" style={{ paddingLeft: 10, paddingRight: 10 }}>
      <Grid 
        container 
        spacing={mobile ? 2 : 4}
      >
        {SHOP_BY_CATEGORY_DATA.map((item, i) => {
          return (
            <CategoryBox
            key={i}
            classes={classes}
            mobile={mobile}
            item={item}
            parentCategoryId={parentCategoryId}
            ></CategoryBox>
            );
          })}
      </Grid>
    </Container>
  );
};

export default CategoryHomepageView;
